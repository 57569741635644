import moment from 'moment';
import * as Yup from 'yup';
import { validateEmail, validateTaxAllowance } from '../../../utils/ReGexs';
import { DATE_FORMAT, DEFAULT_COUNTRY } from '../../../components/common/Constants/Constants';
const toDay = new Date();

export const CreateOrUpdateEmployeeSchema = Yup.object().shape({
   information: Yup.object().shape({
      office: Yup.object().required('required'),
      department: Yup.number().required('required'),
      ssn: Yup.string().required('required'),
      employeeId: Yup.string().test('employeeId', 'required', function (value) {
         const agency = JSON.parse(localStorage.getItem('agency'));
         const autoEmployeeId = agency?.organizationEntity?.autoEmployeeId;
         if (!autoEmployeeId) {
            return Yup.string().required('required').isValidSync(value);
         }
         return true;
      }),
      hireDate: Yup.date().required('required'),
      firstName: Yup.string().required('required'),
      lastName: Yup.string().required('required'),
      birthDay: Yup.date().test('is-age-valid', 'Age must be 18 or older', function (value) {
         const birthDate = new Date(value);
         const eighteenYearsAgo = toDay;
         eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
         if (birthDate >= eighteenYearsAgo) {
            return true; // Age is 18 or older, so it's valid
         } else {
            return false; // Age is less than 18, so it's invalid
         }
      }),
      gender: Yup.number().required('required'),
   }),
   contact: Yup.object()
      .shape({
         phone: Yup.string().required('required'),
         country: Yup.number().required('required'),
         address: Yup.string().required('required'),
         city: Yup.string().required('required'),
         region: Yup.number().required('required'),
         zipCode: Yup.string().min(5, 'required').max(10, 'required').required('required'),
         email: Yup.string(),
      })
      .test('email-validation', 'Invalid email format', function (value) {
         const { createUser } = this.parent?.misc;
         if (createUser) {
            if (!value.email) {
               throw new Yup.ValidationError('Email is required', null, 'email');
            }
            if (!!value.email && !validateEmail(value.email)) {
               throw new Yup.ValidationError('Invalid email format', null, 'email');
            }
         }
         return true;
      }),
   skills: Yup.array().min(1).required('required'),
   misc: Yup.object().shape({
      jobLocation: Yup.number().required('required'),
      maritalStatus: Yup.string().required('required'),
      idExpiration: Yup.date().required('required'),
      juniorAllowances: Yup.number()
         .nullable()
         .test('Int number', 'The value must be an integer', (value) =>
            value === null || value === undefined || Number.isInteger(value) ? validateTaxAllowance(value, 'juniorAllowances') : false,
         ),
      seniorAllowances: Yup.number()
         .nullable()
         .test('Int number', 'The value must be an integer', (value) =>
            value === null || value === undefined || Number.isInteger(value) ? validateTaxAllowance(value, 'seniorAllowances') : false,
         ),
      multipleJobs: Yup.number()
         .required('required')
         .min(1, 'Value must be greater than or equal to 1')
         .max(99, 'Value must be less than or equal to 99'),
   }),
   fileUpdate: Yup.array().of(
      Yup.object().shape({
         type: Yup.string().required('required'),
         fileNames: Yup.string().required('required'),
      }),
   ),
});

export const initialValues = {
   edit: false,
   information: {
      temporalInfo: false,
      office: '',
      department: '',
      ssn: '',
      employeeId: '',
      hireDate: moment(toDay).format(DATE_FORMAT),
      firstName: '',
      middleName: '',
      lastName: '',
      gender: 2, // select
      birthDay: moment(toDay.setFullYear(toDay.getFullYear() - 18)),
   },
   skills: [],
   contact: {
      phone: '',
      email: '',
      country: DEFAULT_COUNTRY,
      address: '',
      address2: '',
      city: '',
      region: '',
      zipCode: '',
   },
   clientCodes: [],
   clientsCodesDeleted: [],
   misc: {
      jobLocation: '',
      maritalStatus: '',
      backgroundTest: 0,
      backgroundTestDate: moment(toDay).format(DATE_FORMAT),
      drugTest: 0,
      drugTestDate: moment(toDay).format(DATE_FORMAT),
      juniorAllowances: '',
      seniorAllowances: '',
      idExpiration: null,
      createUser: false,
      multipleJobs: 0,
   },
   fileUpdate: [],
   fileUpdateDeleted: [],
};
