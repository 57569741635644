import React from 'react'
import { MixBarChart } from '../Charts/MixBarChart'
import Container from '../../../components/common/Container/Container'
import { ProfileInfoCard } from '../ProfileInfoCard'
import { ReleaseNotesCard } from '../ReleaseNotesCard'
import { CardContainer } from '../Components/CardContainer'
import useMixBarChartData from '../Hooks/useMixBarChartData'
import { Col, Row } from 'react-bootstrap'
import Loader from '../../../components/common/Loader/Loader'

export const DashboardClient = ({ translate }) => {
  const { chartData, loading } = useMixBarChartData();

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <Container>
      <Row className='mx-auto'>
        <Col className='mx-auto' md={8} lg={8} sm={12}>
          <Row>
            <Col md={12} lg={12} sm={12}>
              <CardContainer height={'300px'}>
                <MixBarChart data={chartData?.result?.data} title={'com.tempedge.msg.title.personcountvs'} translate={translate} />
              </CardContainer>
            </Col>
          </Row>
        </Col>
        <Col md={4} lg={4} sm={12}>
          <ProfileInfoCard translate={translate} />
          <ReleaseNotesCard />
        </Col>
      </Row>
    </Container>
  )
}
