import Axios from "axios";
import {
  REACT_APP_BASE_URL_TEMPEDGE,
  REACT_APP_URL_GET_AUTH_TOKEN,
} from "../../components/common/Routes/URLs";
import history from "../../history.js";
import httpService from "../../utils/services/httpService/httpService";
import types from "./types";


let baseUrlTempEdge = REACT_APP_BASE_URL_TEMPEDGE;

export let doLogin = (url, data) => {
  return (dispatch) => {
    httpService
      .getAuthToken(REACT_APP_URL_GET_AUTH_TOKEN, data)
      .then((res) => {
        const token = res.data.access_token;
        const refreshToken = res.data.refresh_token;
        data.IPAddress = window.location.hostname;
        Axios({
          url: baseUrlTempEdge + url,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
          params: {
            access_token: token,
            browser: "WEB",
          },
        }).then(async (response) => {
          if (response.data.status > 400) {
            dispatch({
              type: types.LOGIN_ERROR,
              payload: response.data,
            });
          } else {
            localStorage.clear();
            localStorage.setItem("access_token", token);
            localStorage.setItem("refresh_token", refreshToken);
            localStorage.setItem(
              "lastRefresh",
              Math.floor(new Date().getTime() / 1000)
            );
            localStorage.setItem("login", JSON.stringify(response.data.result));
            localStorage.setItem("sign_in", Date.now());
            dispatch({
              type: types.TIMEOUT_MODAL,
              payload: false,
            });
            dispatch({
              type: types.LOGIN,
              payload: response.data.result,
            });
            let user = response.data.result.portalUserList;
            validateAgency(user);
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.LOGIN_ERROR,
          payload: error,
        });
      });
  };
};

export let validateAgency = (agencyList) => {
  let lang = window.location.pathname;
  lang = lang.split("/");
  if (agencyList.length < 1) {
    history.push(`/error/${lang[2]}`);
  } else if (agencyList.length === 1) {
    let org = agencyList[0];
    validateOrg(org);
  } else if (agencyList.length > 1) {
    history.push(`/organization-select/${lang[2]}`);
  }
};

export let doLogout = (lang) => {
  localStorage.clear();
  window.localStorage.setItem("logout", Date.now());
  return (dispatch) => {
    dispatch({
      type: types.LOGOUT,
      payload: {},
    });
    dispatch({
      type: types.GET_CLIENT_LIST,
      payload: []
    });

    history.push(`/auth/${lang}`);
  };
};

export let validateOrg = (user) => {
  let lang = window.location.pathname;
  lang = lang.split("/");

  if (user.status === "A" && user.organizationEntity?.status === "A") {
    let leftMenuNav = JSON.parse(JSON.stringify(user.user.roles[0].menu));
    localStorage.setItem("agency", JSON.stringify(user));
    localStorage.setItem("leftNavMenu", JSON.stringify(leftMenuNav));
    history.push(`/dashboard/${lang[2]}`);
  } else if (
    user.status === "A" &&
    (user.organizationEntity === null || user.organizationEntity.status !== "A")
  ) {
    localStorage.clear();
    history.push(`/denied/agency/${lang[2]}`);
    //history.push(`/registerAgency/${lang[2]}`);
  } else if (user.status === "P") {
    localStorage.clear();
    history.push(`/pending/user/${lang[2]}`);
  } // else if (org.status === 'P' && org.organizationEntity.status === 'P') {
  //history.push(`/pending/agency/${lang[2]}`);
  else if (user.status === "R") {
    localStorage.clear();
    history.push(`/denied/user/${lang[2]}`);
    //history.push(`/register/${lang[2]}`);
  } else if (user.status === "ERROR") {
    localStorage.clear();
    history.push(`/error/${lang[2]}`);
  } else {
    localStorage.clear();
    history.push(`/auth/${lang[2]}`);
  }
};

export const getListClientAll = (payload) => ({
  type: types.GET_CLIENT_LIST,
  payload,
});
export const getListCountryAll = (payload) => ({
  type: types.GET_COUNTRY_LIST_ALL,
  payload,
});

export const tempedgeAPI = (url, data, actionName) => {
  return async (dispatch) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await Axios({
        url: baseUrlTempEdge + url,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data,
        params: {
          access_token: token,
        },
      });
      dispatch({
        type: actionName,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: actionName,
        payload: error,
      });
    }
  };
};

export let tempedgeMultiPartApi = ({ url, data, type, callback }) => {
  return (dispatch) => {
    let token = localStorage.getItem("access_token");
    let options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: baseUrlTempEdge + url,
      method: "post",
      data: data,
      params: {
        access_token: token,
      },
    };

    Axios(options)
      .then((response) => {
        dispatch({
          type: type,
          payload: callback(response),
        });
      })
      .catch((err) => {
        dispatch({
          type: type,
          payload: err,
        });
      });
  };
};

export let clearTempedgeStoreProp = (actionProp) => {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_PROP,
      payload: actionProp,
    });
  };
};

export let clearErrorField = (actionProp) => {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_ERROR_FIELD,
      payload: {
        errorFields: [],
        lastRemoved: "",
      },
    });
  };
};

export let getList = (url, actionName) => {
  return async (dispatch) => {
    const response = await httpService.get(url);
    dispatch({
      type: actionName,
      payload: response.data.result,
    });
    return response;

  }
};

export let storeFormPageNumber = (formName, position) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_FORM_POSITION,
      payload: {
        form: formName,
        pos: position,
      },
    });
  };
};

export let getFilters = (url, data, actionName) => {
  return (dispatch) => {
    httpService.get(url).then((response) => {
      dispatch({
        type: actionName,
        payload: response.data.result,
      });
    });
  };
};

export let saveDepartmentList = (deptList) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_DEPARTMENTS_LIST,
      payload: deptList,
    });
  };
};

export let deletePositionCLient = (index) => {  
  return (dispatch) => {
    dispatch({
      type: types.DELETE_POST_CLIENT,
      payload: index,
    });
  };
};

export let savePositionsList = (positionsList) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_POSITIONS_LIST,
      payload: positionsList,
    });
  };
};

export let saveToPositionsList = (newPos) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_TO_POSITIONS_LIST,
      payload: newPos,
    });
  };
};

export let removeFromDepartmentList = (index) => {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_FROM_DEPARTMENTS_LIST,
      payload: index,
    });
  };
};

export let setErrorField = (fieldName) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_ERROR_FIELD,
      payload: fieldName,
    });
  };
};

export let removeErrorField = (fieldName) => {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_ERROR_FIELD,
      payload: fieldName,
    });
  };
};

export const uploadLogo = (type, payload) => {
  return (dispatch) => {
    dispatch({
      type,
      payload,
    });
  };
};

export const setCleanLogo = () => {
  return (dispatch) => {
    dispatch({
      type: types.CLEAN_LOGO,
    });
  };
};

export let saveBillRates = (rate, type) => {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: rate,
    });
  };
};

export const modalVisibility = (payload) => ({
  type: types.MODAL_VISIBILITY,
  payload,
});

export const pendingUser = (payload) => ({
  type: types.PENDING_USER,
  payload,
});

export const optionsTableList = (id, data) => (dispatch) => {
  let datos = {
    id,
    data,
  };

  return dispatch({
    type: types.ENTITY_ID,
    payload: datos,
  });
};

export const dispatchOtherValue = (data, id) => (dispatch) => {
  let datos = {
    id,
    data,
  };

  return dispatch({
    type: types.ENTITY_ID,
    payload: datos,
  });
};

export const updateMessage = (payload) => ({
  type: types.UPDATE_MESSAGE,
  payload,
});
