import { timeEntryTypes } from '../types/timeEntryTypes';

export const addEmployeeInTimeEntryTable = (payload) => ({
  type: timeEntryTypes.ADD_EMPLOYEE_TIME_ENTRY,
  payload
});

export const addEmployeeWeekInTimeEntryTable = (payload) => ({
  type: timeEntryTypes.ADD_EMPLOYEE_WEEK_TIME_ENTRY,
  payload
})

export const addEmployeeTimeEntryTable = (payload) => ({
  type: timeEntryTypes.ADD_EMPLOYEE_VALIDATION_ENTRY,
  payload
})


export const loadValuesToEmployeeSameTime = (valuesToLoad) => ({
  type: timeEntryTypes.LOAD_VALUES_TO_EMPLOYEE_SAMETIME,
  payload: valuesToLoad
});

export const loadValuesToEmployeeSameTimeWeek = (valuesToLoad) => ({
  type: timeEntryTypes.LOAD_VALUES_TO_EMPLOYEE_SAMETIME_WEEK,
  payload: valuesToLoad
});

export const moveEmployeeToAbsentTable = (payload) => ({
  type: timeEntryTypes.MOVE_EMPLOYEE_TO_ABSENTS_TABLE,
  payload
});

export const moveEmployeeToAbsentWeekTable = (payload) => ({
  type: timeEntryTypes.MOVE_EMPLOYEE_TO_ABSENTS_WEEK_TABLE,
  payload
});

export const totalEmployeeWeekTableExcel = (payload) => ({
  type: timeEntryTypes.TOTALS_WEEK_EXCEL,
  payload
});


export const moveEmployeeToTimeEntryTable = (payload) => ({
  type: timeEntryTypes.MOVE_EMPLOYEE_TO_TIME_ENTRY_TABLE,
  payload
});

export const changeTimeEntryValue = (payload) => ({
  type: timeEntryTypes.CHANGE_TIME_ENTRY_VALUE,
  payload
});

export const changeWeekTimeEntryValue = (payload) => ({
  type: timeEntryTypes.CHANGE_WEEK_TIME_ENTRY_VALUE,
  payload
});

export const changeWeekTimeEntryValueLocked = (payload) => ({
  type: timeEntryTypes.CHANGE_WEEK_TIME_ENTRY_VALUE_LOCKED,
  payload
});

/**
 * This function change any state of this reducer
 * @param {Object} payload
 * @param {String} payload.key key of state
 * @param {String} payload.value value of new state
 */
export const changeState = (payload) => ({
  type: timeEntryTypes.CHANGE_STATE,
  payload
});

export const weekActive=(payload)=> {
  return({
  type: timeEntryTypes.FIND_WEEK_ACTIVE,
  payload})
};

export const clearProp = (payload) => ({
  type: timeEntryTypes.CLEAR_PROP,
  payload
});

export const resetEntireState = () => ({
  type: timeEntryTypes.RESET_ENTIRE_STATE
});

export const setFindByWeek = (payload) => ({
  type: timeEntryTypes.SET_FIND_BY_WEEK,
  payload
});

export const requestDay = (payload)=> ({
  type: timeEntryTypes.REQUEST_LIST_SAVING,
  payload
})
