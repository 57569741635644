import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Translate, withLocalize } from "react-localize-redux";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import "../../assets/styles/components/Calendar.css";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import Loader from "../../components/common/Loader/Loader";
import OutcomeBar from "../../components/common/OutcomeBar";
import {
  REACT_APP_URL_FIND_PAYROLLCODE,
  REACT_APP_URL_FIND_TIMEOFF_BY_DATE,
  REACT_APP_URL_SAVE_TIMEOFF,
} from "../../components/common/Routes/URLs";
import Switch from "../../components/common/Switch/Switch";
import TempEdgeApi from "../../services/TempEdgeApi";
import { normalizeDate } from "../../utils/ReGexs";
import ItemTimeOff from "./ItemTimeOff";

const initialState = {
  selectedDates: [],
  selectTimeOff: {},
  dataGlobal: [],
  message: "",
  findTime: [],
  findDay: {
    day: "",
    selected: false,
  },
  weekendActive: false,
  timeoffConfirm: false,
};

function TimeOffs({ activeLanguage, translate }) {
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectTimeOff, setSelectTimeOff] = useState({});
  const [dataGlobal, setDataGlobal] = useState([]);
  const [message, setMessage] = useState("");
  const [findTime, setFindTime] = useState([]);
  const [findDay, setFindDay] = useState({
    day: "",
    selected: false,
  });
  const [weekendActive, setWeekendActive] = useState(false);
  const [timeoffConfirm, setTimeoffConfirm] = useState(false);
  let nameUser = `${
    JSON.parse(localStorage.getItem("agency")).user.firstName
  } ${JSON.parse(localStorage.getItem("agency")).user.lastName}`;

  const reset = () => {
    setSelectTimeOff(initialState.selectTimeOff);
    setSelectedDates(initialState.selectedDates);
    setFindTime(initialState.findTime);
    setFindDay(initialState.findDay);
    setWeekendActive(initialState.weekendActive);
    setTimeoffConfirm(initialState.timeoffConfirm);
    findWeekTimeOffCallback();
    setLoading(true)
  };

  const fetchTimeOffData = async (startDate, endDate) => {
    try {
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_FIND_TIMEOFF_BY_DATE,
        payload: {
          id: JSON.parse(localStorage.getItem("agency")).portalUserConfId,
          filterBy: {
            startDate: moment(startDate,"MM-DD-YYYY").format("MM-DD-YYYY"),
            endDate: moment(endDate,"MM-DD-YYYY").format("MM-DD-YYYY"),
          },
        },
      });
      if (res.status === 200) {
        const filteredData = res.result.filter((item) => item.status !== "D");
        setFindTime(filteredData);
      }
    } catch (error) {
      setFindTime([]);
    }finally{
      setLoading(false)
    }
  };

  const handleSubmit = async () => {
    setMessage("");
    const dateArray = [];

    //loop through the original array of dates
    selectedDates.map((date) => {
      const day = new Date(date).getDay();
      if (!weekendActive) {
        //get the day of the week (Sunday is day 0 and Saturday is day 6)
        if (day !== 6 && day !== 0) {
          // add the date to the new array if it is not Saturday or Sunday
          dateArray.push(date);
        }
      } else {
        dateArray.push(date);
      }
      return date;
    });

    //if any request fails, add to the variable
    let fixDateSave = 0;
    //Iterate through the array and send date by date
    if (dateArray.length > 0) {
      for (let index = 0; index <= dateArray.length-1; index++) {
        const request = {
          requestedDays: selectedDates.length,
          date: moment(dateArray[index],"MM-DD-YYYY").format("YYYY-MM-DD"),
          userId: JSON.parse(localStorage.getItem("agency")).portalUserConfId,
          timeOffType: {
            timeOffTypeId: selectTimeOff.timeOffTypeId,
          },
        };
        try {
          const { data } = await TempEdgeApi.post({
            url: REACT_APP_URL_SAVE_TIMEOFF,
            payload: request,
          });
          if (!data.status === 200 && !data.code !== "TE00") {
            fixDateSave++;
          }
        } catch (error) {
          fixDateSave++;
        }
      }
    }
    if (fixDateSave > 0) {
      setMessage({
        code: "TE-08",
        message: "com.tempedge.msg.warn.msg.dateError",
        status: 200,
      });
    } else {
      setMessage({
        code: "TE00",
        message: "com.tempedge.msg.info.msg.success",
        status: 200,
      });
      reset();
    }

  };

  const findWeekTimeOffCallback = useCallback(async (newMonth = moment().format('MM-DD-YYYY')) => {
    const startDate = moment(newMonth.activeStartDate).startOf("month");
    const endDate = moment(newMonth.activeStartDate).endOf("month");
    await fetchTimeOffData(startDate, endDate);
  }, []);

  useEffect(() => {
    const getCustomCodeCallback = async () => {
      try {
        const res = await TempEdgeApi.post({
          url: REACT_APP_URL_FIND_PAYROLLCODE,
          payload: { parameterKey: "com.tempedge.global.param.timeofftype" },
        });
        if (res.data.status === 200) {
          setDataGlobal(res.data?.result?.data?.content);
        }
      } catch (error) {
        setMessage(error);
      }
    };

    const fetchData = async () => {
      await Promise.all([getCustomCodeCallback(), findWeekTimeOffCallback()]);
    };

    trackPromise(fetchData());
  }, [findWeekTimeOffCallback]);

  const handleActiveDateChange = async (newMonth) => {
    var firtsDayMonth = moment(newMonth.activeStartDate).startOf("month");
    var endDayMonth = moment(newMonth.activeStartDate).endOf("month");
    try {
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_FIND_TIMEOFF_BY_DATE,
        payload: {
          id: JSON.parse(localStorage.getItem("agency")).portalUserConfId,
          filterBy: {
            startDate: moment(firtsDayMonth,"MM-DD-YYYY").format("MM-DD-YYYY"),
            endDate: moment(endDayMonth,"MM-DD-YYYY").format("MM-DD-YYYY"),
          },
        },
      });
      if (res.status === 200) {
        setFindTime(res.result);
      }
    } catch (error) {
      setFindTime([]);
    }
  };

  const btns = (
    <div className="row py-4" style={{ justifyContent: "space-evenly" }}>
      <div className="col-md-5">
        <button
          type="button"
          className="btn btn-default btn-block register-save-btn previous"
          onClick={() => reset()}
        >
          {translate("com.tempedge.msg.label.cancel")}
        </button>
      </div>
      <div className="col-md-5 ">
        <button
          type="submit"
          className="btn btn-primary btn-block register-save-btn save"
          onClick={() => trackPromise(handleSubmit())}
          disabled={timeoffConfirm && selectedDates.length > 0 && Object.keys(selectTimeOff).length !== 0 ? false : true}
          
        >
          {translate("com.tempedge.msg.label.save")}
        </button>
      </div>
    </div>
  );

  const handleSelect = (date) => {
    setMessage("");
    const normalizedDate = normalizeDate(date);
    const index = selectedDates.indexOf(normalizedDate);

    if (index !== -1) {
      setSelectedDates(selectedDates.filter((item) => item !== normalizedDate));
    } else {
      if (!findTime?.find((x) => normalizeDate(x.date) === normalizedDate)) {
        setSelectedDates([...selectedDates, normalizedDate]);
      }
    }
  };

  const sortedDates = selectedDates.sort((a, b) => {
    const dateA = new Date(
      `${a.slice(6, 10)}-${a.slice(0, 2)}-${a.slice(3, 5)}`
    );
    const dateB = new Date(
      `${b.slice(6, 10)}-${b.slice(0, 2)}-${b.slice(3, 5)}`
    );
    return dateA - dateB;
  });

  let daySelected = findTime?.filter(
    (item) => normalizeDate(item?.date) === normalizeDate(findDay?.day)
  );

  if (loading) return <Loader />;
  return promiseInProgress ? (
    <Loader />
  ) : (
    <ContainerBlue
      title={"com.tempedge.msg.menu.timeoff"}
      btns={btns}
      width={1180}
    >
      {message && <OutcomeBar response={message} />}
      <div className="form-group row">
        <div className="col-6 mt-5">
          <div className="">
            <Calendar
              onChange={handleSelect}
              values={selectedDates}
              select
              locale={activeLanguage?.code}
              calendarType={"US"}
              goToRangeStartOnSelect={false}
              onClickDay={(value) => setFindDay({ day: value, selected: true })}
              tileClassName={({ date, view }) => {
                const normalizedDate = normalizeDate(date);
                if (selectedDates?.includes(normalizedDate)) {
                  if (
                    findTime?.find(
                      (x) => normalizeDate(x.date) !== normalizedDate
                    )
                  ) {
                    return "selected_tempedge";
                  } else {
                    return "selected_tempedge";
                  }
                }
                if (
                  findTime?.find(
                    (x) => normalizeDate(x.date) === normalizedDate
                  )
                ) {
                  if (normalizeDate(findDay.day) === normalizedDate) {
                    return "selected_find_tempedge";
                  } else {
                    return "highlight";
                  }
                }
              }}
              onActiveStartDateChange={handleActiveDateChange}
            />
          </div>
        </div>
        <div className="col-6 mt-5 row">
          <div className="container d-flex flex-column justify-content-between">
            {findDay?.selected && findTime?.length > 0 && daySelected.length >0 && (
              <>
                <div className="row">
                  <label className="control-label-user ml-4">
                    <Translate id="com.tempedge.msg.label.information" />
                  </label>

                  <ItemTimeOff
                    findTime={findTime}
                    findDay={findDay}
                    trackPromise={trackPromise}
                    findWeekTimeOffCallback={findWeekTimeOffCallback}
                    setMessage={setMessage}
                  />
                </div>

                <div className="time-entry__body mt-4"></div>
              </>
            )}

            <div className="row">
              <div className="col-8">
                <label className="control-label">
                  <Translate id="com.tempedge.msg.label.timeofftype" />
                </label>
                <DropdownList
                  className="form-control"
                  name={selectTimeOff}
                  data={dataGlobal}
                  valueField={"timeOffTypeId"}
                  value={{ timeOffTypeId: selectTimeOff?.timeOffTypeId }}
                  textField={"description"}
                  onChange={(time) => {
                    setSelectTimeOff(time);
                    setMessage(null);
                  }}
                  locale={activeLanguage?.code}
                />
              </div>
              <div className="col-4">
                <label className="control-label">
                  {translate("com.tempedge.msg.label.selecteddays")}
                </label>
                <input
                  className="form-control tempEdge-input-box "
                  value={selectedDates.length}
                  readOnly
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <>
                  <label className="control-label top-label-agency-form mt-4">
                    {translate("com.tempedge.msg.label.selecteddays")}
                  </label>
                  <div className="row mt-2">
                    {sortedDates.length > 0 &&
                      sortedDates?.map((item, key) => (
                        <div key={key} className="col-4">
                          <h4>{item}</h4>
                        </div>
                      ))}
                  </div>
                </>
              </div>
            </div>

            <div className="row mt-5 ">
              <div className="col-10 ">
                <p className="ml-1">
                  {translate("com.tempedge.msg.label.clicktimeoff")}
                </p>
              </div>

              <Switch
                name="week"
                checked={weekendActive}
                onChange={(value) => {
                  setWeekendActive(value);
                }}
              />

              <div className="col-10">
                <p className="ml-1">
                  {translate("com.tempedge.msg.label.clickingconfirm1")}
                  <b>{" " + nameUser + " "}</b>
                  {translate("com.tempedge.msg.label.timeoffconfirm")}
                </p>
              </div>

              <Switch
                name="week"
                checked={timeoffConfirm}
                onChange={(value) => {
                  setTimeoffConfirm(value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ContainerBlue>
  );
}

export default withLocalize(TimeOffs);
