import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import OutcomeBar from "../../../components/common/OutcomeBar";
import { withLocalize } from "react-localize-redux";
import {
  tempedgeAPI,
  clearTempedgeStoreProp,
} from "../../../Redux/actions/tempEdgeActions";
import { timeEntryTypes } from "../../../Redux/types/timeEntryTypes";
import {
  resetEntireState,
  clearProp,
  changeState,
} from "../../../Redux/actions/timeEntryActions";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import PayrollByDay from "../PayrollByDay/PayrollByDay";
import PayrollByWeek from "../PayrollByWeek/PayrollByWeek";
import AbsentPayrollForWeek from "../PayrollByWeek/AbsentPayrollForWeek";
import PayrollWeekResult from "../PayrollByWeek/PayrollWeekResult";
import SearchForm from "../SearchForm";
import SameTimePayroll from "../SameTimePayroll";
import AbsentPayrollForDay from "../PayrollByDay/AbsentPayrollForDay";
import AbsentPayrollForDayResult from "../PayrollByDay/AbsentPayrollForDayResult";
import "../../../assets/styles/components/TimeEntry.css";
import PayrrollDayResult from "../PayrollByDay/PayrrollDayResult";
import Switch from "../../../components/common/Switch/Switch";
import ExcelManager from "../../../utils/helpers/excelManager";
import Loader from "../../../components/common/Loader/Loader";
import Modal from "../../../Modals/Modal/Modal";
import {  REACT_APP_URL_PAYROLL_SAVE_LIST, REACT_APP_URL_PAYROLL_SAVE_WEEK } from "../../../components/common/Routes/URLs";

export class TimeEntry extends Component {

  static onError(data) {
    return <OutcomeBar response={data} />;
  }

  static onSuccess(data) {
    return <OutcomeBar response={data} />;
  }


  constructor(props) {
    super(props);
    this.state = {
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      timeEntryTable: "",
      resultBar: null,
      isSameHour: false,
      download: false,
      loading: false,
      payrollHoursValidation: false,
      hourValidationOpen: false,
      clientReportConf:0
    };
  }

  componentDidMount() {
    this.props.resetEntireState();
  }

  componentDidUpdate(prevProps) {
    const { activeLanguage, history, addTranslationForLanguage } = this.props;
    const hasActiveLanguageChanged =
      prevProps.activeLanguage !== activeLanguage;
    if (hasActiveLanguageChanged) {
      history.push(`/payroll/timeentry/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
    }
  }


  reset() {
    const { resetEntireState, changeState} = this.props;
    changeState({ key: "customDate", value: null })
    resetEntireState();   
    this.setState({
      isSameHour: false,
      download: false,
      loading: false
    })
  }

  // aqui se renderiza el dia o la semana depende de el isFindByWeek y el dayresult que es un estado
  renderTimeEntryTable = () => {
    const {
      isFindByWeek,
      client,
      positionList,
      employeesTimeEntry,
      employeesTimeEntryDay,
      dateStart,
      dayResul,
      translate,
      positionSelectDay
    } = this.props;
    if (isFindByWeek && !dayResul) {
      return (
        <PayrollByWeek
          employeesTimeEntry={employeesTimeEntry}
          clientSelected={client}
          dateStart={dateStart}
          translate={translate}
          clientReportConf={this.state.clientReportConf}
        />
      );
    }
    if (!dayResul && employeesTimeEntryDay?.length > 0 && !isFindByWeek) {
      return (
        <PayrollByDay
          positionList={positionList}
          employeesTimeEntryDay={employeesTimeEntryDay}
          positionSelectDay={positionSelectDay}
        />
      );
    }
    return null;
  };

  // esta funcion es para que el boton next la ejecute y pueda renderizar el componente de resultado por dia o semana
  renderResultDay = () => {
    const { changeState, dayResul,employeesTimeEntry,employeesTimeEntryDay} = this.props;
    let hoursValidation=false;
    let hoursByDaysValidation=0;
    if(employeesTimeEntry.length>0){
      employeesTimeEntry.map((employee=>{
        if(employee.totalRegHours > 40){
          hoursValidation=true;
          this.setState({payrollHoursValidation:true,hourValidationOpen:true});
        }
        return employee;
      }))
    }else{
      if(employeesTimeEntryDay.length > 0){
        employeesTimeEntryDay.map((days)=>{
          if(days.position && days.position.departmentId === undefined){
              hoursByDaysValidation ++
          }
          if(days.position && days.position.positionId === undefined){
              hoursByDaysValidation ++
          }
          return days
        })
      }    
    }

    if(hoursByDaysValidation > 0){
      changeState({
        key:"positionSelectDay",
        value:"error"
      })
    }
    
    if(!hoursValidation && hoursByDaysValidation === 0 ){
      changeState({
        key: "dayResul",
        value: !dayResul,
      });
    }
    
  };

  setReportConfg=(item)=>{
    this.setState({clientReportConf:item})
  }

   // este es el mensaje que envia la data el cual le paso por un dispatch de change state , cambia este estado resultbarmessage
   renderResultBar() {
    const { resultBarMessage } = this.props;
    if (resultBarMessage) {
      return this.constructor.onSuccess(resultBarMessage);
      }    
    return null;
  }

  // para guardar el dia, lo que hago es: crear un array que se llama requestEmployee el cual mapeo
  //del employeesTImeEntry, que es lo que me pide el savebyday,
  // el requesyDay, es el client , el shift que se selecciono en el search y el clientId, y el dateList todo biene de el reducer
  // esto para armar el request que enviamos para guardar
  saveTimeByDay() {
    const { orgId, download } = this.state;
    const { client, employeesTimeEntryDay, requestDay, absentEmployees } = this.props;
    const requestObject = [];
    requestDay.forEach((data) => {
      requestObject.push({
        dateList: moment(data.dateList, "YYYY-MM-DD", true).format(
          "YYYY-MM-DD"
        ),
        clientId: data.clientId
      });
    });
    const requestEmployee = [];
    employeesTimeEntryDay.forEach((employee) => {
      requestEmployee.push({
        payrollId: employee.payrollId,
        dateList: requestObject[0]?.dateList ? requestObject[0]?.dateList : "",
        person: {
          personId: employee.personId,
          firstName: employee.firstName,
          lastName: employee.lastName
        },
        department: {
          departmentId: employee.position.departmentId,
          name: employee?.position.departmentName
        },
        position: {
          positionId: employee.position.positionId,
          name: employee.position.positionName
        },
        payrollState: employee.payrollState,
        billRate: Number(employee.billRate),
        payRate: Number(employee.payRate),
        otBillRate: Number(employee.otBillRate),
        attendance: employee.attendance,
        timeIn:
          (employee.timeIn !== null && employee.timeIn !== "")
            ? moment(employee.timeIn, "hh:mm A").format("HH:mm:ss")
            : null,
        timeOut:
          (employee.timeOut !== null && employee.timeOut !== "")
            ? moment(employee.timeOut, "hh:mm A").format("HH:mm:ss")
            : null,
        lunch: Number(employee.lunch),
        totalRegHour: Number(employee.totalRegHour),
        shift : Number(employee.shift)
      });
    });
    if (absentEmployees.length > 0) {
      absentEmployees.forEach((employeeAbsent) => {
        requestEmployee.push({
          payrollId: employeeAbsent.payrollId,
          dateList: requestObject[0]?.dateList ? requestObject[0]?.dateList : "",
          person: {
            personId: employeeAbsent.personId,
            firstName: employeeAbsent.firstName,
            lastName: employeeAbsent.lastName
          },
          department: {
            departmentId: employeeAbsent.position.departmentId,
            name: employeeAbsent.position.departmentName
          },
          position: {
            positionId: employeeAbsent.position.positionId,
            name: employeeAbsent.position.positionName
          },
          payrollState: employeeAbsent.payrollState,
          billRate: Number(employeeAbsent.billRate),
          payRate: Number(employeeAbsent.payRate),
          otBillRate: Number(employeeAbsent.otBillRate),
          attendance: employeeAbsent.attendance,
          timeIn: null,
          timeOut: null,
          lunch: 0,
          totalRegHour: 0,
          shift : Number(employeeAbsent.shift)
        });
      });
    }
    const officeId = [];
    employeesTimeEntryDay.forEach((officeid) =>
      officeId.push(officeid.office.officeId)
    );
    const request = {
      orgId,
      dateList: requestObject[0]?.dateList ? requestObject[0]?.dateList : "",
      downloadFile: download,
      mailCc: "",
      mailTo: "",
      clientId: client.clientId,
      officeId: officeId[0] ? officeId[0] : "",
      sendEmail: false,
      parameterKey: "com.tempedge.msg.menu.timeentry",
      payrollEntityList: requestEmployee,
    };
    this.saveData(request);
  }


  async saveTimeByWeek() {
    const { tempedgeAPI, changeState, employeesTimeEntry, absentEmployeesWeek, client, requestDay,totalWeekExcel } = this.props;

    const { orgId } = this.state;
    this.setState({
      loading: true,
    });
    const requestObject = [];
    requestDay.forEach((data) => {
      requestObject.push({
        dateList: moment(data.dateList, "YYYY-MM-DD", true).format(
          "YYYY-MM-DD"
        ),
        clientId: data.clientId
      });
    });


    let payrollResult=  employeesTimeEntry.map((payroll) => {
      for (const [key, value] of Object.entries(payroll)) {
        if (key !== null) {
          if (value) {            
            if (value.totalRegHour === 0 && value.totalOtHour === 0 ) {
              return {...payroll,value}
            }
          }
        }
      }
      return payroll
    })
  
    const dateList = requestDay[0]?.dateList
    const employeeSave = payrollResult.map((payroll) => {
      return { ...payroll, dateList }
    })

    const request = {
      orgId,
      dateList: requestObject[0]?.dateList ? requestObject[0]?.dateList : "",
      clientId: client.clientId,
      parameterKey: "com.tempedge.msg.menu.timeentry",
      payrollEntityList: employeeSave,
    };


    const response = await tempedgeAPI(
      REACT_APP_URL_PAYROLL_SAVE_WEEK,
      request,
      timeEntryTypes.TIMEWEEK_LIST_SAVING
    )
    if (response) {
      this.showSavingResponseWeek(absentEmployeesWeek,totalWeekExcel);
      this.setState({
        loading: false,
      });
    }
    
    changeState({
      key: "dayResul",
      value: false,
    });

  }


  //funcion para exportal el excel por dia , el de semana es diferente 
  exportExcelDay(employees) {
    let data = employees.map((employee) => {
      return {
        [`${this.props.translate("com.tempedge.msg.label.lastname")}`]: String(
          employee.person.lastName
        ),
        [`${this.props.translate("com.tempedge.msg.label.firstname")}`]: String(
          employee.person.firstName
        ),
        [`${this.props.translate(
          "com.tempedge.msg.label.deptpos"
        )}`]: `${employee.department.name} - ${employee.position.name}`,
        [`${this.props.translate("com.tempedge.msg.label.timein")}`]: String(
          employee.timeIn
        ),
        [`Lunch (min)`]: String(employee.lunch),
        [`${this.props.translate("com.tempedge.msg.label.timeout")}`]: String(
          employee.timeOut
        ),
        [`Total Reg`]: String(employee.totalRegHour),
      };
    });
    const excel = new ExcelManager();
    excel.export(data);
  }

  // funcion que guarda la data por dia
  async saveData(request) {
    const { tempedgeAPI, changeState } = this.props;
    this.setState({
      loading: true,
    });
    const response = await tempedgeAPI(
      REACT_APP_URL_PAYROLL_SAVE_LIST,
      request,
      timeEntryTypes.TIMEDAY_LIST_SAVING
    )
    if (response) {
      this.showSavingResponseDay(request.payrollEntityList)
      this.setState({
        loading: false,
      });
    }
    changeState({
      key: "dayResul",
      value: false,
    });

  }

  // con esta funcion hago un dispach para cambiar el state, esto me da el mensaje que responde al backend
  showSavingResponseDay(requestEmployee) {
    const { clearProp, saveTimeDayListResponse, changeState } = this.props;
    if (saveTimeDayListResponse && saveTimeDayListResponse.data) {
      const { code} = saveTimeDayListResponse.data;
      if (code === "TE00" || code === "TE-E08") {
        clearProp();
        changeState({
          key: "resultBarMessage",
          value: saveTimeDayListResponse.data,
        });
        if (this.state.download === true) {
          this.exportExcelDay(requestEmployee);
        }
        this.setState({
          isSameHour: false,
        });
        
        changeState({key:"weekday", value:''});
        changeState({key:"shift", value:''});
        changeState({key:"client", value:''});
        changeState({key:"department", value:''});
        changeState({key:"positionSelectDay", value:''});
      } else {
        changeState({
          key: "resultBarMessage",
          value: saveTimeDayListResponse.data,
        });
      }
    }else {
      this.constructor.onError(saveTimeDayListResponse);
    } 
  }

  showSavingResponseWeek(absentEmployeesWeek,totalWeekExcel) {
    const { clearProp, changeState, saveTimeWeekListResponse } = this.props;
    if (saveTimeWeekListResponse && saveTimeWeekListResponse.data) {
      const { code } = saveTimeWeekListResponse.data;
      if (code === "TE00" || code === "TE-E08") {
        clearProp();
        changeState({
          key: "resultBarMessage",
          value: saveTimeWeekListResponse.data,
        });
        if (this.state.download === true) {
          const excel = new ExcelManager();
          excel.export(absentEmployeesWeek,totalWeekExcel);
        }        
        changeState({key:"weekday", value:''});
        changeState({key:"shift", value:''});
        changeState({key:"client", value:''});
        changeState({key:"department", value:''});
        this.setState({
          isSameHour: false,
        });
      } else {
        changeState({
          key: "resultBarMessage",
          value: saveTimeWeekListResponse.data,          
        });        
      }
    }else {
      this.constructor.onError(saveTimeWeekListResponse);
    }
   
  }

  // esta funcion se activa cuando estamos en result y queremos volver a editar
  previousTable() {
    const { changeState } = this.props;
    changeState({
      key: "dayResul",
      value: false,
    });
    this.setState({ download: false, isSameHour: false });
    this.renderTimeEntryTable();
  }

  render() {
    let nameCompany = `${JSON.parse(localStorage.getItem('agency')).user.firstName} ${JSON.parse(localStorage.getItem('agency')).user.lastName}`

    const {
      isFindByWeek,
      absentEmployees,
      employeesTimeEntry,
      employeesTimeEntryDay,
      translate,
      dayResul,
      dateStart,
      tempEdge,
      weekActive,
      changeState,
    } = this.props;
    
    const btn = (
      <>
        {(employeesTimeEntry?.length > 0 || absentEmployees?.length > 0 || employeesTimeEntryDay?.length > 0) &&
          (!dayResul ? (
            <div className="row py-4">
              <div className="col-md-5 offset-md-1">
                <button
                  type="button"
                  className="btn btn-default btn-block register-save-btn previous"
                  onClick={() => this.reset()}
                >
                  {translate("com.tempedge.msg.label.cancel")}
                </button>
              </div>
              <div className="col-md-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-block register-save-btn next"
                  onClick={() => this.renderResultDay()}
                >
                  {translate("com.tempedge.msg.label.next")}
                </button>
              </div>
            </div>
          ) : !isFindByWeek ? (
            <div className="row py-4">
              <div className={weekActive?"col-md-4":"col-md-5 offset-md-1"}>
                <button
                  type="button"
                  className="btn btn-default btn-block register-save-btn previous"
                  onClick={() => this.reset()}
                >
                  {translate("com.tempedge.msg.label.cancel")}
                </button>
              </div>
              <div className={weekActive?"col-md-4":"col-md-5"}>
                <button
                  type="button"
                  className="btn btn-default btn-block register-save-btn previous"
                  onClick={() => this.previousTable()}
                >
                  {translate("com.tempedge.msg.label.previous")}
                </button>
              </div>
              {weekActive && <div className="col-md-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block register-save-btn save"
                  onClick={(e) => this.saveTimeByDay(e)}
                  disabled={!this.state.isSameHour }
                >
                  {translate("com.tempedge.msg.label.save")}
                </button>
              </div>}
            </div>
          ) : (
            <div className="row py-4">
              <div className={weekActive===true?"col-md-4":"col-md-5 offset-md-1"}>
                <button
                  type="button"
                  className="btn btn-default btn-block register-save-btn previous"
                  onClick={() => this.reset()}
                >
                  {translate("com.tempedge.msg.label.cancel")}
                </button>
              </div>
              <div className={weekActive===true?"col-md-4":"col-md-5"}>
                <button
                  type="button"
                  className="btn btn-default btn-block register-save-btn previous"
                  onClick={() => this.previousTable()}
                >
                  {translate("com.tempedge.msg.label.previous")}
                </button>
              </div>
              {weekActive && <div className="col-md-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block register-save-btn save"
                  onClick={(e) => this.saveTimeByWeek(e)}
                  disabled={!this.state.isSameHour }
                >
                  {translate("com.tempedge.msg.label.save")}
                </button>
              </div>}
            </div>
          ))}
      </>
    );

    const bottomsModal = (<>
    
      <div className="col">
        <button
          type="button"
          style={{minHeight:'0'}}
          className="btn btn-default btn-block register-save-btn previous"
          onClick={() => this.setState({ hourValidationOpen: false,payrollHoursValidation:false })}
        >
          {translate("com.tempedge.msg.label.cancel")}
        </button>
      </div>
      <div className="col">
        <button
          type="submit"
          style={{minHeight:'0'}}
          className="btn btn-primary btn-block register-save-btn save"
          onClick={() =>  {changeState({key: "dayResul",value: true,})
          this.setState({payrollHoursValidation:false,hourValidationOpen: false})}
        }          
        >
          {translate("com.tempedge.msg.label.accept")}
        </button>
      </div>
      
      </>)

    if (this.state.loading)
      return <Loader />

    return (
      <ContainerBlue title="com.tempedge.msg.menu.timeentry" btns={btn}>
        <div className="row ">
          <div className="col-12">{this.renderResultBar()}</div>
        </div>
        <div className="margin-bottom 3rem">
          <SearchForm translate={translate} tempEdge={tempEdge} setReportConfg={this.setReportConfg}/>
        </div>
        {!dayResul && (employeesTimeEntry?.length > 0 || employeesTimeEntryDay.length > 0) && (
          <>
            <div className="time-entry__header">
              <SameTimePayroll isFindByWeek={isFindByWeek} weekActive={weekActive}/>
            </div>
            <div className="time-entry__body">
              {this.renderTimeEntryTable()}
            </div>
          </>
        )}
        {!dayResul && absentEmployees?.length > 0 && (
          <div className="time-entry__body">
            <AbsentPayrollForDay />
          </div>
        )}
        {dayResul && employeesTimeEntryDay?.length > 0 && !isFindByWeek && (
          <>
            <div className="time-entry__body">
              <PayrrollDayResult employeesTimeEntryDay={employeesTimeEntryDay} />
            </div>
          </>
        )}
        {dayResul && absentEmployees?.length > 0 && !isFindByWeek && (
          <div className="time-entry__body">
            <AbsentPayrollForDayResult />
          </div>
        )}
        {dayResul && employeesTimeEntryDay?.length > 0 && !isFindByWeek && (
          <div className="row time-entry__body">
            <div className="imput col-6" >
              <p>
              {translate('com.tempedge.msg.label.clickingconfirm1')} <b>{nameCompany}</b> { translate('com.tempedge.msg.label.clickingconfirm2')}
              </p>
            </div>
            <div className="imput col-2 ">
              <Switch
                name="week"
                checked={this.state.isSameHour}
                onChange={(value) => {
                  this.setState({
                    isSameHour: value,
                  })
                }}
              />
            </div>
            {this.state.isSameHour === true && (
              <>
                <div className="imput col-2 " >
                  <p>{translate("com.tempedge.msg.label.download")}</p>
                </div>
                <div className="imput col-2 ">
                  <Switch
                    name="week"
                    checked={this.state.download}
                    onChange={(value) => {
                      this.setState({ download: value });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
        {
          <>
            {dayResul && employeesTimeEntry?.length > 0 && isFindByWeek && (
              <>
                <div className="time-entry__body">
                  <PayrollWeekResult
                    employeesTimeEntry={employeesTimeEntry}
                    dateStart={dateStart}
                    translate={translate}
                    dayResul={dayResul}
                    clientReportConf={this.state.clientReportConf}
                  />
                </div>
                <div className="time-entry__body">
                  <AbsentPayrollForWeek
                    dateStart={dateStart}
                    employeesTimeEntry={employeesTimeEntry}
                  />                 
                </div>
              </>
            )}
            {dayResul && employeesTimeEntry?.length > 0 && isFindByWeek && (
              <div className="row time-entry__body">
                <div className="imput col-6 " >
                  <p style={{ textAlign: "left" }}>
                  {translate('com.tempedge.msg.label.clickingconfirm1')} <b>{nameCompany}</b> { translate('com.tempedge.msg.label.clickingconfirm2')}
                  </p>
                </div>
                <div className="imput col-2">
                  <Switch
                    name="week"
                    checked={this.state.isSameHour}
                    onChange={(value) => {
                      this.setState({
                        isSameHour: value,
                      });
                    }}
                  />
                </div>
                {this.state.isSameHour === true && (
                  <>
                    <div className="imput col-2 " >
                      <p>{translate("com.tempedge.msg.label.download")}</p>
                    </div>
                    <div className="imput col-2 ">
                      <Switch
                        name="download"
                        checked={this.state.download}
                        onChange={(value) => {
                          this.setState({ download: value });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        }
         <div className="row ">
             {this.state.payrollHoursValidation &&
                <Modal
                  content={translate('com.tempedge.msg.label.confirmhoursValidation')}
                  title={translate('com.tempedge.msg.label.important')}
                  open={this.state.hourValidationOpen}
                  onClose={()=>this.setState({hourValidationOpen:false})}
                  modalSize={'modal-md'}
                  buttons={bottomsModal}
                />
              }
            </div>
      </ContainerBlue >
    );
  }
}

const mapStateToProps = ({ tempEdge, timeEntryReducer }) => ({
  tempEdge,
  isFindByWeek: timeEntryReducer.isFindByWeek,
  resultBarMessage: timeEntryReducer.resultBarMessage,
  client: timeEntryReducer?.client,
  positionList: timeEntryReducer?.positionList,
  employeesTimeEntry: timeEntryReducer?.employeesTimeEntry,
  employeesTimeEntryDay: timeEntryReducer?.employeesTimeEntryDay,
  absentEmployees: timeEntryReducer?.absentEmployees,
  saveTimeDayListResponse: timeEntryReducer?.saveTimeDayListResponse,
  saveTimeWeekListResponse: timeEntryReducer?.saveTimeWeekListResponse,
  shift: timeEntryReducer?.shift,
  weekday: timeEntryReducer?.weekday,
  requestDay: timeEntryReducer?.requestDay,
  dateStart: timeEntryReducer?.dateStart,
  payrollResponse: timeEntryReducer?.payrollResponse?.data?.result,
  dayResul: timeEntryReducer?.dayResul,
  positionSelectDay: timeEntryReducer?.positionSelectDay,
  absentEmployeesWeek: timeEntryReducer?.absentEmployeesWeek,
  totalWeekExcel:timeEntryReducer?.totalWeekExcel,
  weekActive: timeEntryReducer?.weekActive
});

const mapDispatchToProps = {
  tempedgeAPI,
  clearTempedgeStoreProp,
  resetEntireState,
  clearProp,
  changeState,
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(TimeEntry)
);
