import { store } from '../../../store/store';
import { getList, updateMessage } from '../../../Redux/actions/tempEdgeActions';
import types from '../../../Redux/actions/types';
import translationsEN from '../../../translations/en.tempedge.json';
import translationsES from '../../../translations/es.tempedge.json';
import { REACT_APP_URL_DICTIONARY_LIST_ALL } from '../Routes/URLs';

let addTranslationsForActiveLanguage = async (activeLanguage, addTranslationForLanguage, isAlwaysGetLanguage) => {
  try {
    // Default language while API get the Language
    let translations = translationsEN;
    switch (activeLanguage.code) {
      case 'en':
        translations = translationsEN;
        break;
      case 'es':
        translations = translationsES;
        break;
      default:
        translations = translationsEN;
    }

    addTranslationForLanguage(translations, activeLanguage.code);

    let state = store.getState();

    if (isAlwaysGetLanguage || !state.tempEdge.lang) {
      await store.dispatch(getList(REACT_APP_URL_DICTIONARY_LIST_ALL, types.GET_LANGUAGE));
    }

    state = store.getState();
    translations = state?.tempEdge?.lang?.english; // Default to English

    // Can add more languages if ever needed
    switch (activeLanguage.code) {
      case 'en':
        translations = state?.tempEdge?.lang?.english;
        break;
      case 'es':
        translations = state?.tempEdge?.lang?.spanish;
        break;
      default:
    }

    addTranslationForLanguage(translations, activeLanguage.code);
    store.dispatch(updateMessage(null));
  } catch (error) {
    let translations = translationsEN;
    switch (activeLanguage.code) {
      case 'en':
        translations = translationsEN;
        break;
      case 'es':
        translations = translationsES;
        break;
      default:
    }
    addTranslationForLanguage(translations, activeLanguage.code);
    store.dispatch(updateMessage(new Error(error)));
    throw error;
  }
};

export default addTranslationsForActiveLanguage;
