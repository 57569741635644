import React, { useMemo, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { LocalizeProvider } from 'react-localize-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Favicon from 'react-favicon';
import { store, persistor, history } from './store/store';
import NavBar from './components/common/NavBar/NavBar';
import NavPanelLeft from './components/common/NavPanelLeft/NavPanelLeft.js';
import BackgroundFade from './components/common/NavPanelLeft/BackgroundFade.js';
import Footer from './components/common/Footer/Footer';
import './assets/styles/Vars.css';
import Routes from './components/common/Routes/Routes';
import syncAuth from './Hoc/Authentication/SyncAuth';
import defaultLanguages from './utils/helpers/defaultLanguage';
import Loader from './components/common/Loader/Loader';

function TempEdge(props) {

    const {languages,options} = defaultLanguages();
    const  [panelNavShow, setPanelNavShow] = useState(false);


    function togglePanelNav() {
        setPanelNavShow(!panelNavShow);
    };

    const closeNav = () => {
        setPanelNavShow(false);
    }

    let footerContent = useMemo(() => (
        <p>
            © <Translate id="com.tempedge.msg.label.footer" /> <Translate id="com.tempedge.msg.label.rights" />
        </p>
        ),[]
    );
    
        
    let backgroundFade = panelNavShow ? <BackgroundFade closeNav={closeNav} /> : null;

     return (
        <PersistGate loading={< Loader />} persistor={persistor}>
        <LocalizeProvider store={store} initialize={{ languages: languages, options: options }}>
            <Favicon url="/img/favicon.ico" />
            <ConnectedRouter history={history}>
                <div className="contents">
                    <NavBar toggleNav={togglePanelNav} />
                    <NavPanelLeft toggleNav={togglePanelNav} show={panelNavShow} />
                    {backgroundFade}
                    <Routes/>
                </div>
            </ConnectedRouter>
            <Footer textColor="#fff" background="#df963d" content={footerContent} />
        </LocalizeProvider>
        </PersistGate>
     );
}

export default withLocalize(syncAuth(TempEdge));