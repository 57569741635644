import React, { useCallback, useEffect, useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployee,
  removeEmployee,
} from "../../../Redux/actions/dailyListActions";
import types from "../../../Redux/actions/types";
import TempEdgeApi from "../../../services/TempEdgeApi";
import Container from "../Container/Container";
import Loader from "../Loader/Loader";
import OutcomeBar from "../OutcomeBar";
import AdvanceSearch from "./AdvanceSearch";
import TPaginator from "./TPaginator";
import Table from "./Table";

const getCellsContent = (row) => {
  const cellContent = [];
  row.querySelectorAll("td").forEach((td) => {
    cellContent.push(td.textContent);
  });
  return cellContent;
};

const PaginatedTable = ({
  filterBy: filterInitial,
  parameterKey,
  orgId: defaultOrgId,
  apiUrl,
  title,
  onClickRows,
  multipleRows,
  selectRow,
  isSelected,
  singleSelect,
  translate,
}) => {
  const { promiseInProgress } = usePromiseTracker();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [filterByFIndInitial, setFilterByFIndInitial] = useState(filterInitial)
  const [filterBy, setFilterBy] = useState("");
  const [dataFilter, setDataFilter] = useState("");
  const [search, setSearch] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [loading, setLoading] = useState(false);

  const propsPersonType = filterInitial?.personType
  ? filterInitial.personType
  : "1";
  const size = 30;
  const orgId = defaultOrgId || JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId;
  const fetch = useCallback(
    async (request) => {
      setMessage("");
      try {
        const payload = {
          ...request,
          filterBy: {
            ...request.filterBy,             
          },
        };
        const { data: res } = await TempEdgeApi.post({
          url: apiUrl,
          payload: payload,
        });
        // Check if the component is still mounted before updating state
        if (isMounted) {
          const {
            status = 0,
            code = "",
            result: {
              data: { content: response = [] },
            },
          } = res;

          if (status === 200 && code === "TE00") {
            if (response.length > 0) {
              dispatch({
                type: types.TEMPEDGE_LIST,
                payload: res.result,
              });
            } else {
              dispatch({
                type: types.TEMPEDGE_LIST,
                payload: res.result,
              });
            }
          } else {
            dispatch({
              type: types.TEMPEDGE_LIST,
              payload: res.result,
            });
          }
        }
      } catch (error) {
        if (isMounted) {
          setMessage(error);
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    },
    [apiUrl, dispatch, isMounted]
  );

  const data = useSelector((state) => state.tempEdge.paginatorList);

  const requestInitialFind = useCallback(() => {
    const requestInitial = {
      orgId,
      size,
      filterBy: filterByFIndInitial,
      parameterKey,
    };
    fetch(requestInitial);
  }, [fetch, orgId, size, parameterKey, filterByFIndInitial]);

  useEffect(() => {
    setLoading(true);
    requestInitialFind();
    return () => {
      setIsMounted(false);
      setFilterByFIndInitial();
    };
  }, [requestInitialFind]);

  const changePage = (myPage) => {
    let payload = {
      orgId: orgId,
      page: myPage,
      size: size || 30,
      filterBy: { ...filterBy, ...dataFilter, personType: propsPersonType },
      parameterKey: parameterKey,
    };
    fetch(payload);
  };
  // Función para generar IDs únicos
  function generateUniqueId() {
    const timestamp = new Date().getTime().toString(36);
    const randomNumber = Math.random().toString(36).substring(2);
    return timestamp + randomNumber;
  }

  const selectRows = (event) => {
    if (selectRow) {
      event.currentTarget.classList.toggle("table-active");
      selectRow(event);
      return;
    }
    if (onClickRows) {
      const { currentTarget } = event;
      const cellContent = getCellsContent(currentTarget);
      const id = parseInt(currentTarget.dataset.id);
      cellContent.unshift(id);
      if (!multipleRows) {
        onClickRows(cellContent);
      } else {
        currentTarget.classList.toggle("table-active");
        const isRowSelected = !!currentTarget.classList.value.includes("table-active");
        const personId = parseInt(currentTarget.dataset.id);
        if (isRowSelected) {
          addRowToList({
            id: generateUniqueId(),
            personId,
            employeeId: cellContent[1],
            lastName: cellContent[4],
            firstName: cellContent[5],
            position: null,
            pay: null,
            attendance: null,
          });
        } else {
          removeRowFromList(personId);
        }
      }
    }
  };

  const applyFilter = (sortBy, filterValue) => {
    let updatedFilterBy = { ...filterBy };

    if (!sortBy || !filterValue) {
      if (updatedFilterBy.hasOwnProperty(sortBy) && filterValue === "") {
        delete updatedFilterBy[sortBy];
      }
    } else {
      if (sortBy.includes("user.")) {
        sortBy = sortBy.replace("user.", "");
      }
      updatedFilterBy = { ...updatedFilterBy, [sortBy]: filterValue };
    }

    // Check if sortBy exists in dataFilter and update it
    let updatedDataFilter = { ...dataFilter };
    if (updatedDataFilter.hasOwnProperty(sortBy)) {
      updatedDataFilter[sortBy] = filterValue;
    }

    let payload = {
      orgId: orgId,
      page: 0,
      size: size || 30,
      filterBy: { ...updatedFilterBy, ...updatedDataFilter, personType: propsPersonType },
      parameterKey: parameterKey,
    };

    fetch(payload);
    setFilterBy(updatedFilterBy);
  };


  const addRowToList = (row) => {
    dispatch(addEmployee(row));
  };

  const removeRowFromList = (personId) => {
    dispatch(removeEmployee(personId));
  };

  const renderButton = () => {
    if (multipleRows) {
      return (
        <div key="AcceptButton" className="row mt-3">
          <div className="col-2 offset-5">
            <button
              type="button"
              onClick={() => onClickRows()}
              className="btn btn-primary btn-blue btn-sm"
            >
              {translate("com.tempedge.msg.label.accept")}
            </button>
          </div>
        </div>
      );
    }
    return "";
  };

  const cancelSearch = (bol) => {
    setSearch(bol);
    setDataFilter({ personType: propsPersonType });
    const requestInitial = {
      orgId,
      size,
      filterBy,
      parameterKey,
    };
    if (bol) {
      if (
        Object.keys(filterBy).length === 0 ||
        Object.values(filterBy).includes("")
      ) {
        trackPromise(
          fetch({
            orgId,
            size,
            filterBy: { personType: propsPersonType },
            parameterKey,
          })
        );
      } else {
        let payload = {
          orgId: orgId,
          size: size || 30,
          filterBy: { ...filterBy, personType: propsPersonType },
          parameterKey: parameterKey,
        };
        fetch(payload)
      }
    } else {
      setLoading(true);
      setFilterBy({});
      setDataFilter({ personType: propsPersonType });
      trackPromise(
        fetch({ ...requestInitial, filterBy: { personType: propsPersonType } })
      )
    }
  };

  const changeDataFilter = (item) => {
    setDataFilter({ ...item, ...filterBy });
    const requestInitial = {
      orgId,
      size,
      filterBy: { ...item, ...filterBy, personType: propsPersonType },
      parameterKey,
    };
    if (
      Object.keys(filterBy).length === 0 ||
      Object.values(filterBy).includes("")
    ) {
      trackPromise(fetch(requestInitial));
    } else {
      fetch(requestInitial)
    }
  };

  if (loading) return <Loader />;
  return (
    <>
      <Container
        title={title}
        btns={
          !promiseInProgress && data && data.data ? (
            <TPaginator
              changePage={changePage}
              multipleRowsBtn={renderButton()}
            />
          ) : (
            ""
          )
        }
      >
        {search && data ? (
          <div className="col-md-12">
            <AdvanceSearch
              cancelSearch={cancelSearch}
              changeData={changeDataFilter}
              parameterKey={parameterKey}
            />
          </div>
        ) : null}
        {promiseInProgress ? (
          <div className="row mx-auto">
            <Loader />
          </div>
        ) : data ? (
          <>

            {!search &&
              (parameterKey === "com.tempedge.msg.menu.employeelist" ||
                parameterKey === "com.tempedge.msg.menu.clientlist") && (
                <div className="col-md-3 ml-auto">
                  <button
                    type="submit"
                    className="btn btn-green w-100 mb-2"
                    onClick={() => setSearch(true)}
                  >
                    <Translate id="com.tempedge.msg.label.advancedsearch" />
                  </button>
                </div>
              )}
            <div className="col-12">
              {message && <OutcomeBar response={message} />}
              <Table
                selectRows={selectRows}
                data={data}
                applyFilter={applyFilter}
                onClickRows={onClickRows}
                multipleRows={multipleRows}
                isSelected={isSelected}
                singleSelect={singleSelect}
                filterByFIndInitial={filterByFIndInitial}
              />
            </div>
          </>
        ) : (
          <div className="row mx-auto">
            <Translate id="com.tempedge.msg.label.norecordsfound" />
          </div>
        )}
      </Container>
    </>
  );
};

export default withLocalize(PaginatedTable);
