import { useEffect, useState } from 'react';
import TempEdgeApi from '../../../services/TempEdgeApi';
import { REACT_APP_URL_FIND_LAST_SEVEN_DAYS_HOURS_SUM_BY_ORG } from '../../../components/common/Routes/URLs';

const useSimpleAreaChartData = (date) => {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const orgId = JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId;
            setLoading(true);
            try {
                const { data } = await TempEdgeApi.post({
                    url: REACT_APP_URL_FIND_LAST_SEVEN_DAYS_HOURS_SUM_BY_ORG,
                    payload: {
                        orgId,
                        date
                    },
                });
                if (data?.status === 200) {
                    setChartData(data);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        
    }, []);

    return { chartData, loading, error };

};

export default useSimpleAreaChartData;
