import { useEffect, useState } from 'react';
import { REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE } from '../../components/common/Routes/URLs';
import TempEdgeApi from '../../services/TempEdgeApi';
import { useDispatch } from 'react-redux';
import { updateMessage } from '../../Redux/actions/attendanceActions';
import { normalizeDateSend } from '../../utils/ReGexs';

const useFetchActiveWeek = ({ clientId, dateList, startDate }) => {
  const [response, setResponse] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (clientId && (dateList || startDate)) {
        const payload = dateList ? { clientId, dateList : normalizeDateSend(dateList) } : { clientId, dateList : normalizeDateSend(startDate) };
        try {
          const { data } = await TempEdgeApi.post({
            url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
            payload,
          });
          if (data?.status === 200) {
            setResponse(data);
          }
        } catch (error) {
          dispatch(updateMessage(new Error(error)))
        }
      }
    };

    fetchData();
  }, [clientId, dateList, dispatch, startDate]);

  return { response };
};

export default useFetchActiveWeek;
