import React, { useMemo, useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import CancelBtn from "../../../components/common/Buttons/CancelBtn";
import BlueCommonBtn from "../../../components/common/Buttons/BlueCommonBtn";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import TimeClock from "./TimeClock";
import Loader from "../../../components/common/Loader/Loader";
import Switch from "../../../components/common/Switch/Switch";
import { Translate, withLocalize } from "react-localize-redux";
import OutcomeBar from "../../../components/common/OutcomeBar";
import TempEdgeApi from "../../../services/TempEdgeApi";
import {
  REACT_APP_URL_FIND_REPORT_CONFIGURATION,
} from "../../../components/common/Routes/URLs";
import { useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap';

const validateShema = Yup.object().shape({
  client: Yup.object().required("This field is required"),
  timeclock: Yup.object().required("This field is required"),
  payRate: Yup.boolean()
    .test(
      "payRate-required",
      "This field is required when timeclock.id is not 0 or -1",
      function (value) {
        const { timeclock } = this.parent;
        if (timeclock && (timeclock.id !== 0 && timeclock.id !== -1)) {
          return value !== undefined;
        }
        return true;
      }
    ),
});

const FileConfiguration = ({
  formData,
  setFormData,
  nextStep,
  message,
  translate,
}) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [timeclock, setTimeclock] = useState(formData.timeclock?.name);
  const [clock, setClock] = useState({});
  const formikRef = useRef();

  const reportByFile = {
    0: { id: 0, name: "NO" },
    1: { id: 1, name: "FingerTech" },
    2: { id: 2, name: "Sundial" },
    3: { id: 3, name: "Sundial V2" },
    "-1": { id: -1, name: translate("com.tempedge.msg.label.other") },
  };

  useEffect(() => {
    setInfo(message);
  }, [message]);

  useEffect(() => {
    const clock = TimeClock({ type: timeclock });
    setClock(clock);
  }, [timeclock]);

	const clientsListRedux = useSelector(state => {
    return state.tempEdge.clientList;
	});
	
	const clientsList = useMemo(() => clientsListRedux, [clientsListRedux])  


  const find = async (client) => {
    try {
      setLoading(true);
      setInfo(null);
      setFormData({
        client,
				timeclock: {
					id: 0,
					name: 'NO'
				},
				fileConfiguration: {},
				reportClientDepts: [],
      });      
      const { data: resp } = await TempEdgeApi.post({
        url: REACT_APP_URL_FIND_REPORT_CONFIGURATION,
        payload: {
          client: {
            clientId: client.clientId,
          },
        },
      });
      setTimeclock("NO");
      if (resp.status === 200 && resp.code === "TE00") {
        setInfo(null);
        const data = resp.result;      
        const reportCLientDepts = [];
        for (let item of data.reportClientDepts) {
          if (item.reportDeptDesc !== "" || item.reportPosDesc !== "")
            reportCLientDepts.push({
              reportDeptDesc: item.reportDeptDesc,
              reportPosDesc: item.reportPosDesc,
              position: item.position,
              department: item.department,
              name: `${item?.department?.name} - ${item?.position?.name}`,
            });
        }
        setFormData({
          client,
          timeclock: reportByFile[data.reportByFile],
          recurrentPayroll: data.recurrentPayroll
            ? data.recurrentPayroll
            : false,
          payRate: data.payRate ? data.payRate : false,
          headers: data.headers ? data.headers : false,
          reportClientDepts: reportCLientDepts,
          reportClientShifts: data.reportClientShifts,
          byEmployeeId: data.byEmployeeId,
          fileConfiguration: {
            colNamePos: data.colNamePos,
            colRatePos: data.colRatePos,
            colEmpidPos: data.colEmpidPos ? data.colEmpidPos : null,
            colDeptPos: data.colDeptPos,
            colSectionPos: data.colSectionPos,
            colHoursPos: data.colHoursPos,
            colOthoursPos: data.colOthoursPos,
            colTimeinPos: data.colTimeinPos,
            colTimeoutPos: data.colTimeoutPos,
            colNameSepPos: data.colNameSepPos ? data.colNameSepPos : null,
            colDatePos: data.colDatePos ? data.colDatePos : null,
            colShiftPos: data.colShiftPos ? data.colShiftPos : null,
          },
        });
        setTimeclock(reportByFile[data.reportByFile]?.name);
      } else {
        if(resp.status > 300){
          setInfo(resp);
        }else{
          setInfo(null);
        }
      }
    } catch (e) {
      setInfo({ type: "error", content: "com.tempedge.error.undefine" });
    } finally {
      setLoading(false);
    }
  };

  const getErrorsFromValidationError = (validationError) => {
    const errors = validationError.inner.reduce((errors, error) => {
      const path = error.path.split(".");
      if (path.length === 2)
        return {
          ...errors,
          [path[1]]: error.errors,
        };
      return {};
    }, {});
    return errors;
  };

  const handleSubmit = (values, { setErrors }) => {
    if (clock && clock.schema) {
      try {
        clock.schema.validateSync(values, { abortEarly: false });
        setFormData(values);
        nextStep();
      } catch (error) {
        const errors = getErrorsFromValidationError(error);
        setErrors(errors);
      }
    } else {
      setFormData(values);
      nextStep();
    }
  };

  const btns = (
    <Row className="mt-4">
      <Col className="text-right">
        <CancelBtn />
      </Col>
      <Col className="text-left">
      <BlueCommonBtn
            tag="com.tempedge.msg.label.next"
            onClick={() => {
              if (formikRef.current) formikRef.current.handleSubmit();
            }}
          />
      </Col>
    </Row>
  );

  if (loading) return <Loader />;

  return (
    <>
      <ContainerBlue title="com.tempedge.msg.menu.reportconf" btns={btns}>
        {info && <OutcomeBar response={message} />}
        <Formik
          innerRef={formikRef}
          initialValues={formData}
          enableReinitialize={true}
          validationSchema={validateShema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <form className="row p-2 justify-content-center">
                <div className="col-11 col-md-4">
                  <div className="green-text font-weight-bold text-uppercase mb-4">
                    <Translate id="com.tempedge.msg.label.filters" />
                  </div>
                  <div className="form-group">
                    <label className="text-left label-p">
                      <Translate id="com.tempedge.msg.label.company" />
                    </label>
                    <DropdownList
                      data={clientsList}
                      name="client"
                      textField="clientName"
                      value={{ clientId: formik.values.client.clientId }}
                      valueField="clientId"
                      onChange={(client) => {
                        find(client);
                      }}
                      isInvalid={formik.errors.client && formik.touched.client}
                    />
                  </div>
                  {formik.values.client && (
                    <>
                      <div className="form-group">
                        <label className="text-left label-p">
                          <Translate id="com.tempedge.msg.label.hastimeclock" />
                        </label>
                        <DropdownList
                          data={Object.values(reportByFile)}
                          name="timeclock"
                          textField="name"
                          valueField="id"
                          value={formik.values.timeclock}
                          onChange={(item) => {
                            formik.setFieldValue("timeclock", item);
                            setTimeclock(item.name);
                          }}
                          isInvalid={formik.errors.timeclock}
                        />
                      </div>
                      <div className="green-text font-weight-bold text-uppercase my-4">
                        <Translate id="com.tempedge.msg.label.configurations" />
                      </div>
                      {formik.values.timeclock.id !== 0 && (
                        <div className="form-group">
                          <Switch
                            name="payRate"
                            align="between"
                            size="sm"
                            isInvalid={formik.errors && formik.errors.payRate}
                            checked={formik.values.payRate}
                            onChange={(value) => {
                              formik.setFieldValue("payRate", value);
                            }}
                          >
                            <Translate id="com.tempedge.msg.label.reportpayrate" />
                          </Switch>
                        </div>
                      )}
                      {formik.values.timeclock.id === 0 && (
                        <div className="form-group">
                          <Switch
                            name="recurrentPayroll"
                            align="between"
                            size="sm"
                            checked={formik.values.recurrentPayroll}
                            onChange={(value) => {
                              formik.setFieldValue("recurrentPayroll", value);
                            }}
                          >
                            <Translate id="com.tempedge.msg.label.reportpayroll" />
                          </Switch>
                        </div>
                      )}
                    </>
                  )}
                  {formik.values.client && (
                    <>
                      <div className="form-group">
                        <Switch
                          name="byEmployeeId"
                          align="between"
                          size="sm"
                          checked={formik.values.byEmployeeId}
                          onChange={(value) => {
                            formik.setFieldValue("byEmployeeId", value);
                          }}
                        >
                          <Translate id="com.tempedge.msg.label.repbyemplid" />
                        </Switch>
                      </div>
                    </>
                  )}
                  {formik.values.client && (
                    <div className="form-group">
                      <Switch
                        name="headers"
                        align="between"
                        size="sm"
                        checked={formik.values.headers}
                        onChange={(value) => {
                          formik.setFieldValue("headers", value);
                        }}
                      >
                        <Translate id="com.tempedge.msg.label.reportheaders" />
                      </Switch>
                    </div>
                  )}
                </div>
                {formik.values.client && (
                  <div className="col-12 col-md-6">
                    <div className="green-text font-weight-bold text-uppercase mb-4">
                      <Translate id="com.tempedge.msg.label.fileConfiguration" />
                    </div>
                    {clock && clock.component && clock.component({ formik })}
                    {clock && clock.component && (
                      <div
                        style={{
                          borderTop: "1px dashed gray",
                          paddingTop: "0.5rem",
                        }}
                      >
                        <Translate id="com.tempedge.msg.label.fileConfigurationNote" />
                      </div>
                    )}
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </ContainerBlue>
    </>
  );
};

export default withLocalize(FileConfiguration);
