import React, { useState, useEffect } from "react"
import { withLocalize } from "react-localize-redux"
import DropdownList from "../../components/common/Dropdown/DropdownList"
import Datepicker from "../../components/common/Datepicker/Datepicker"
import moment from "moment"
import { useDispatch } from "react-redux"
import { addSameDateAdjustments } from "../../Redux/actions/adjustmentActions"
import { limitString } from "../../utils/ReGexs"

const DATE_FORMAT_FRONTEND = "MM/DD/YYYY";

function AdjustmentSameData({ translate, departments, weekActive, weekInfo, selectedDate }) {
  const dispatch = useDispatch();
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [pay, setPay] = useState("");
  const [totalRegHour, setTotalRegHour] = useState("");
  const [totalOtHour, setTotalOtHour] = useState("");
  const [dateAdjustment, setDateAdjustment] = useState(selectedDate);
  const [datePayment, setDatePayment] = useState(weekActive.end);

  useEffect(() => {
    // Sincroniza dateAdjustment con la fecha seleccionada en la parte superior
    setDateAdjustment(selectedDate);
  }, [selectedDate]);

  const handleSubmit = () => {
    const data = {
      pay: pay || "",  
      position: departmentSelected || "",
      totalOtHour: totalOtHour || "",
      totalRegHour: totalRegHour || "",
      paymentDate: moment(datePayment).format(DATE_FORMAT_FRONTEND),
      date: moment(dateAdjustment).format(DATE_FORMAT_FRONTEND),
      errors: {
        date: false,
        pay: false,
        position: false,
      }
    }
    dispatch(addSameDateAdjustments(data))
  }

  return (
    <div className="mb-2 row">
      <div className="col-10 mx-auto">
        <table className="table table-striped table-same-data mt-1 ">
          <thead className="">
            <tr>
              <th className="table-header-left-same text-center w-10">
                {translate("com.tempedge.msg.label.deptpos")}
              </th>
              <th className="table-header-mid-same text-center w-10">
                {translate("com.tempedge.msg.label.pay")}
              </th>
              <th className="table-header-mid-same text-center w-10">
                {translate("com.tempedge.reports.columns.colhourspos")}
              </th>
              <th className="table-header-mid-same text-center w-10 mx-auto">
                {translate("com.tempedge.msg.label.adjustmentdate")}
              </th>
              <th className="table-header-mid-same text-center w-10  mx-auto">
                {translate("com.tempedge.msg.label.paymentdate")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="time-sheet-row">
              <td className="text-center mx-auto">
                <DropdownList
                  data={departments}
                  textField={"positionName"}
                  valueField={"positionId"}
                  required={true}
                  value={limitString({
                    value: departmentSelected ? departmentSelected : "",
                    limit: 15,
                    replace: "..."
                  })}
                  onChange={(value) => {
                    setPay(value.pay)
                    setDepartmentSelected(value)
                  }}
                />
              </td>
              <td className="text-center mx-auto">
                <input
                  style={{ fontSize: "12px", textAlign: "center" }}
                  type="number"
                  value={pay}
                  placeholder="0.0"
                  maxLength={7}
                  className={`form-control tempEdge-input-box`}
                  onChange={(event) => {
                    setPay(event.target.value)
                  }}
                />
              </td>
              <td className="text-center mx-auto">
                <div className="input-group input-group-sm ">
                  <div
                    className="input-group-prepend "
                    style={{ width: "auto" }}
                  >
                    <span
                      className="input-group-text "
                      id="inputGroup-sizing-sm"
                    >
                      RH
                    </span>
                  </div>
                  <input
                    style={{ fontSize: "12px" }}
                    className={`form-control `}
                    type="text"
                    step="0.01"
                    placeholder="0.0"
                    maxLength={6}
                    aria-label="totalRegHour"
                    aria-describedby="basic-addon1"
                    value={totalRegHour}
                    onChange={(event) => {
                      setTotalRegHour(event.target.value)
                    }}
                  />
                  <span> </span>
                  <input
                    style={{ fontSize: "12px" }}
                    className={`form-control`}
                    type="text"
                    placeholder="0.0"
                    maxLength={6}
                    aria-label="totalOtHour"
                    aria-describedby="basic-addon2"
                    value={totalOtHour}
                    onChange={(event) => {
                      setTotalOtHour(event.target.value)
                    }}
                  />
                  <div className="input-group-append " id="cellWeek">
                    <span className="input-group-text " id="cellWeek">
                      OT
                    </span>
                  </div>
                </div>
              </td>
              <td className="text-center mx-auto">
                <Datepicker
                  customClass={`form-control text-center mx-auto tempEdge-input-box`}
                  selectedValue={
                    dateAdjustment
                      ? moment(dateAdjustment).toDate()
                      : moment().toDate()
                  }
                  maxDate={moment(weekInfo.endDate).toDate()}
                  onChange={(value) => {
                    setDateAdjustment(moment(value).format(DATE_FORMAT_FRONTEND));
                  }}
                />
              </td>
              <td className="mx-auto text-center w-full">
                <Datepicker
                  customClass={`form-control text-center tempEdge-input-box mx-auto`}
                  selectedValue={
                    datePayment
                      ? moment(datePayment).toDate()
                      : moment().toDate()
                  }
                  maxDate={moment(weekActive.end).toDate()}
                  onChange={(value) => {
                    setDatePayment(moment(value).format(DATE_FORMAT_FRONTEND));
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="form-group col-12 col-lg-3 ml-auto mt-5">
        <button
          type="button"
          onClick={() => handleSubmit()}
          className="btn btn-green w-100"
        >
          {translate("com.tempedge.msg.label.update")}
        </button>
      </div>
    </div>
  )
}

export default withLocalize(AdjustmentSameData)
