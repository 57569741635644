import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Translate, withLocalize } from "react-localize-redux";
import { useDispatch, useSelector } from 'react-redux';
import Modal from "../../../Modals/Modal/Modal";
import { resetEntireStateHistory } from "../../../Redux/actions/payrollPersonHistoryActions";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import Loader from "../../../components/common/Loader/Loader";
import OutcomeBar from "../../../components/common/OutcomeBar";
import PayrollHistoryTablet from "./PayrollHistoryTablet";
import { fetchPayroll } from '../../../Redux/actions/payrollHistoryActions';
import { Container, Row, Col } from "react-bootstrap";

const MyHistoryPayroll = ({ activeLanguage, history, addTranslationForLanguage }) => {
  const dispatch = useDispatch();
  let user = JSON.parse(localStorage.getItem("agency")).user;

  const [prevActiveLanguage, setPrevActiveLanguage] = useState(activeLanguage);

  useEffect(() => {
    const hasActiveLanguageChanged = prevActiveLanguage !== activeLanguage;
    if (hasActiveLanguageChanged) {
      history.push(`/intpayroll/history/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
      setPrevActiveLanguage(activeLanguage);
    }
  }, [activeLanguage, history, addTranslationForLanguage, prevActiveLanguage]);

  const { loading, message, data } = useSelector(state => state.payrollPersonHistory);
  const [dates, setDates] = useState(null);
  const [totalRH, setTotalRH] = useState(0);
  const [totalOT, setTotalOT] = useState(0);
  const [modal, setModal] = useState({
    show: false,
    content: null,
    size: null,
  });

  // Reset state when component mounts
  useEffect(() => {
    dispatch(resetEntireStateHistory());
  }, [dispatch]);

  // Reset state when component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetEntireStateHistory());
    };
  }, [dispatch]);

  const renderDateAndToggleFields = () => {
    return (
      <>
        <div className="form-group col-lg-3 col-12">
          <p className="text-left label-p">
            {<Translate id="com.tempedge.msg.label.date" />}
          </p>
          <DatePicker
            onChange={(date) => setDates(date)}
            selected={dates}
            value={dates}
            className="form-control tempEdge-input-box"
            showMonthDropdown
            showYearDropdown
            maxDate={null}
            placeholderText={"mm/dd/yyyy"}
          />
        </div>
      </>
    );
  };

  const btn = (
    <div className="col-md-6 mx-auto">
      <button
        type="button"
        className="btn btn-default btn-block register-save-btn previous"
        onClick={() => {
          dispatch(resetEntireStateHistory());
        }}
      >
        {<Translate id="com.tempedge.msg.label.cancel" />}
      </button>
    </div>
  );

  useEffect(() => {
    let totalRegHours = 0;
    let totalOTHours = 0;
    data.forEach((item) => {
      item.payrollWeekDTOS.forEach((week) => {
        totalRegHours += week.totalRegHours;
        totalOTHours += week.totalOTHours;
      });
    });

    setTotalOT(totalOTHours);
    setTotalRH(totalRegHours);
  }, [data]);

  if (loading) return <Loader />;
  return (
    <ContainerBlue title={"com.tempedge.msg.menu.payrollhistory"}
    btns={data.length > 0 && btn}>
      {message && <OutcomeBar response={message} />}
      {data?.length <= 0 && <Row className="search row flex mt-2 align-items-end">
        {renderDateAndToggleFields()}
        <Col className="form-group" lg={3} md={6} sm={12}>
          <button
            type="button"
            onClick={() => dispatch(fetchPayroll(dates, user))}
            className="btn btn-blue w-100 "
            style={{ marginTop: "2.8rem" }}
            disabled={!dates}
          >
            {<Translate id="com.tempedge.msg.label.find" />}
          </button>
        </Col>
      </Row>}
      {
        <>
          {data?.length > 0 && (
            <Container>
              <Row>
                <Col sm={8}>
                  <p className="green-text font-weight-bold text-uppercase mt-5">
                    {`${user?.firstName} ${user?.lastName}`}
                  </p>
                </Col>
                <Col className="ml-auto px-4">
                  <p className="font-weight-bold text-uppercase mt-5 px-4">
                    {<Translate id="com.tempedge.msg.label.totalreghours" />}
                    {`: ${totalRH}`}
                  </p>
                  <p className="font-weight-bold text-uppercase px-4">
                    {<Translate id="com.tempedge.msg.label.totalothours" />}
                    {`: ${totalOT}`}
                  </p>
                </Col>
              </Row>
              {data?.map((item, index) => {
                return (
                  <>
                    <div className="mt-5 time-entry__body" key={index + user.id}>
                      <Row>
                        <Col>
                          <Row>
                            <Col lg={3} md={6} sm={12}>
                              <p className="font-weight-bold text-uppercase">
                                {item?.client}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <PayrollHistoryTablet
                      payrollWeekDtos={item.payrollWeekDTOS}
                      dateStart={dates}
                      setTotalRH={setTotalRH}
                      setTotalOT={setTotalOT}
                      totalRH={totalRH}
                      totalOT={totalOT}
                    />
                  </>
                );
              })}
            </Container>
          )}
        </>
      }
      <Modal
        open={modal.show}
        content={modal.content}
        onClose={() => setModal({ show: false, content: null, size: null })}
        modalSize={modal.size ?? "modal-sm"}
      />
    </ContainerBlue>
  );
};

export default withLocalize(MyHistoryPayroll);