import React from 'react';
import Rating from 'react-rating';

const emptyStarRate =
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  viewBox="0 0 16 15.001">
      <path id="star_2_" data-name="star (2)" d="M2.866,14.85a.531.531,0,0,0,.746.593L8,13.187l4.389,2.256a.531.531,0,0,0,.746-.592l-.83-4.73L15.83,6.765a.556.556,0,0,0-.283-.95l-4.9-.7L8.465.792a.513.513,0,0,0-.927,0L5.354,5.12l-4.9.7a.556.556,0,0,0-.283.95L3.7,10.122l-.83,4.73Zm4.923-2.112L3.448,14.851l.82-4.657a.565.565,0,0,0-.163-.5L.577,6.34l4.888-.617a.525.525,0,0,0,.393-.288L8,1.081l2.254,4.355a.525.525,0,0,0,.393.288l4.9.617-3.6,3.347a.564.564,0,0,0-.163.506l.757,4.657L8.25,12.737a.5.5,0,0,0-.461,0Z" transform="translate(-0.001 -0.499)" fillRule="evenodd"/>
    </svg>
const fullStarRate = 
    <svg width="16" height="16" viewBox="0 0 16 16" className="bi bi-star-fill" fill="var(--tempedge-orange)" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
    </svg>


function ItemComment(props) {

    const {comment} = props;

    return (
        <div className="mb-3" style={{borderBottom: 'dotted 1px #626262'}}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" style={{height:'2.2em'}} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div>
                        <div className="font-weight-bold">{comment.userCreate.firstName+' '+comment.userCreate.lastName}</div>
                        <div style={{fontSize: '1rem'}}>{comment.dateCreate}</div>
                    </div>
                </div>
                <Rating 
                    emptySymbol={emptyStarRate}
                    fullSymbol={fullStarRate} 
                    initialRating={comment.score}
                    readonly
                />
            </div>
            <div>
                <p  className="my-2">{comment.comment}</p>
            </div>
        </div>
    );
}

export default ItemComment;