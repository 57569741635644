import {useEffect} from "react";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";

const useTranslateCode = ({location, activeLanguage}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        let path = location.pathname;
        path = path.substr(0, path.lastIndexOf('/')) + '/' + activeLanguage.code;
        dispatch(push(path));
    },[location.pathname,activeLanguage, dispatch]);
}

export default useTranslateCode;