import React, { useEffect, useState } from 'react';
import { RadarChartCustom } from '../Charts/RadarChartCustom';
import { CardContainer } from '../Components/CardContainer';
import useRadarChartData from '../Hooks/useRadarChartData';
import { MixBarChart } from '../Charts/MixBarChart';
import useMixBarChartData from '../Hooks/useMixBarChartData';
import { ProfileInfoCard } from '../ProfileInfoCard';
import { ReleaseNotesCard } from '../ReleaseNotesCard';
import { Col, Row } from 'react-bootstrap';
import BlueCommonBtn from '../../../components/common/Buttons/BlueCommonBtn';
import Container from '../../../components/common/Container/Container';
import { navigateTo } from '../../../utils/NavigateTo';
import Loader from '../../../components/common/Loader/Loader';

export const DashboardOffice = ({ translate }) => {
    const { radarChartData, loading: radarChartLoading } = useRadarChartData();
    const { chartData, loading: mixBarChartLoading } = useMixBarChartData();

    const [dashboardLoading, setDashboardLoading] = useState(true);

    useEffect(() => {
        setDashboardLoading(radarChartLoading || mixBarChartLoading);
    }, [radarChartLoading, mixBarChartLoading])

    if (dashboardLoading) {
        return (
            <Loader />
        )
    }

    return (
        <Container>
            <Row className='mx-auto'>
                <Col className='mx-auto' md={8} lg={8} sm={12}>
                    <Row>
                        <Col md={12} lg={12} sm={12}>
                            <CardContainer height={'300px'}>
                                <RadarChartCustom translate={translate} data={radarChartData?.result?.data} dataKey={radarChartData?.result?.keyField} title={'com.tempedge.msg.title.todayslogincount'} />
                            </CardContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={6} sm={12}>
                            <CardContainer height={'400px'}>
                                <MixBarChart data={chartData?.result?.data} title={'com.tempedge.msg.title.personcountvs'} translate={translate} />
                            </CardContainer>
                        </Col>
                        <Col md={12} lg={6} sm={12}>
                            <CardContainer height={'400px'} paddingBlock='30px'>
                                <Col md={12} lg={12} sm={12} className='p-8 flex-column justify-content-start '>
                                    <h2 className='text-center'> {translate('com.tempedge.msg.title.shortcuts')} </h2>
                                    <div className='mt-3'>
                                    </div>
                                    <BlueCommonBtn
                                        tag="com.tempedge.msg.menu.dailylist"
                                        onClick={() => navigateTo('/payroll/dailylist/en')}
                                        className="mb-3"
                                    />
                                    <div className='mt-3'>
                                    </div>
                                    <BlueCommonBtn
                                        tag="com.tempedge.msg.menu.employeelist"
                                        onClick={() => navigateTo('/employee/list/en')}
                                        className="mb-3"
                                    />
                                    <div className='mt-3'>
                                    </div>
                                    <BlueCommonBtn
                                        tag="com.tempedge.msg.menu.profile"
                                        onClick={() => navigateTo('/profile/en')}
                                    />
                                </Col>
                            </CardContainer>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} lg={4} sm={12}>
                    <ProfileInfoCard translate={translate} />
                    <ReleaseNotesCard />
                </Col>
            </Row>
        </Container>
    );
}
