export const attendanceTypes = {
    FETCH_ATTENDANCE_REQUEST: 'FETCH_ATTENDANCE_REQUEST',
    FETCH_ATTENDANCE_SUCCESS: 'FETCH_ATTENDANCE_SUCCESS',
    FETCH_ATTENDANCE_FAILURE: 'FETCH_ATTENDANCE_FAILURE',
    UPDATE_START_DATE: 'UPDATE_START_DATE',
    CLEAR_STATE: 'CLEAR_STATE',
    UPDATE_ATTENDANCE_LIST: 'UPDATE_ATTENDANCE_LIST',
    UPDATE_CLIENT_ID: 'UPDATE_CLIENT_ID',
    FETCH_SAVE_ATTENDANCE_REQUEST: 'FETCH_SAVE_ATTENDANCE_REQUEST',
    FETCH_SAVE_ATTENDANCE_SUCCESS: 'FETCH_SAVE_ATTENDANCE_SUCCESS',
    FETCH_SAVE_ATTENDANCE_FAILURE: 'FETCH_SAVE_ATTENDANCE_FAILURE',
    UPDATE_MESSAGE: 'UPDATE_MESSAGE',
    CLEAR_ATTENDANCE_LIST: 'CLEAR_ATTENDANCE_LIST',
    UPDATE_FIELD_INVALID: 'UPDATE_FIELD_INVALID',
    CLEAR_FIELD_INVALID: 'CLEAR_FIELD_INVALID',
    CLEAR_SEARCH_FORM: 'CLEAR_SEARCH_FORM',
    UPDATE_ACTIVE_WEEK: 'UPDATE_ACTIVE_WEEK',
};