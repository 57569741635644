import 'babel-polyfill';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store} from './store/store';
import './assets/styles/Vars.css';
import TempEdge from './TempEdge';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <TempEdge />
      </Router>
    </Provider>
  );
}

export default App;
