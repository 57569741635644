import { Component } from 'react';
import { push } from 'connected-react-router';
import ActiveLanguageAddTranslation from '../ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

class Container extends Component {
   constructor(props) {
      super(props);
      this.state = {
         key: '',
      };

      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   }

   componentDidUpdate(prevProps) {
      const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

      if (hasActiveLanguageChanged) {
         this.props.push(`/approveuser/${this.props.activeLanguage.code}`);
         ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
      }
   }

   render() {
      const { width, title, children, btns, label } = this.props;
      return (
         <>
            <div className="panel main-form-panel" style={{ width }}>
               <div className="container-form-title text-center">
                  {title && (
                     <h2>
                        <Translate id={title} />
                     </h2>
                  )}
                  {!title && label && <h2>{label}</h2>}
               </div>
               <div className="row mx-auto">{children}</div>
               <div className="">{btns}</div>
            </div>
         </>
      );
   }
}

export default withLocalize(connect(null, { push })(Container));
