import React from 'react';
import { validateOrg } from '../../../Redux/actions/tempEdgeActions.js';
import placeholder from './assets/250.png';
import tempEdgeLogo from '../../../assets/static/tempEge-logo-sm.png'

class Tile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      logo: ''
    }
  }

  onClick = async(org) => {
    validateOrg(org);
    window.localStorage.setItem('choose_agency', Date.now());
  }

  componentDidMount() {
    if (this.props.agency.organizationEntity.organizationName === 'TEMPEDGE') {
      this.setState({ logo: tempEdgeLogo })
    } else {
      this.setState({ logo: this.props.agency.organizationEntity.logo ? `data:/png;base64,${this.props.agency.organizationEntity.logo}` : placeholder })
    }
  }

  render() {
    let orgName = this.props.agency.organizationEntity.organizationName;
    
    return (
      <div className="col-lg-3 col-xs-12" style={{ display: "inline-block" }}>
        <div className="logo-container d-flex align-items-center w-80" style={{minHeight:'6.5rem'}}>
          <img src={this.state.logo} 
          style={{width: '100%', objectFit : 'COVER'}} 
          onClick={() => { this.onClick(this.props.agency) }} 
          alt={`${orgName}`} />
        </div>
      </div>
    );
  }
}

export default Tile;
