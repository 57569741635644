import { Field, Form, Formik } from "formik";
import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { getFormSyncErrors } from "redux-form";
import { normalizeZipCode, onlyAlphanumeric } from "../../../utils/ReGexs";
import DropdownList from "../Dropdown/DropdownList";

const ClientSearch = ({
  setRegionList,
  translate,
  countries,
  offices,
  regionList,
  cancelSearch,
  changeData,
  salesmanList,
  getCountry
}) => {
  const initialValues = {
    country: 234,
    region: "",
    city: "",
    office: "",
    zipcode: "",
    clientSellers: "",
  };
  return (
    <div className="time-sheet">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          const filteredObj = {};
          Object.entries(values).forEach(([key, value]) => {
            if (value !== null && value !== "" && value !== undefined) {
              if(key === "region" &&  value.regionId !== null &&
              value.regionId !== undefined &&
              value.regionId !== ""){
                filteredObj[key] = value.regionId.toString()
              } else 
              if (key === "clientSellers") {
                filteredObj[key] = { clientSellerId: value };
              } else if (
                key === "office" &&
                "officeId" in value &&
                value.officeId !== null &&
                value.officeId !== undefined &&
                value.officeId !== ""
              ) {
                filteredObj[key] = value.officeId.toString();
              } else {
                filteredObj[key] = value.toString();
              }
            }
          });
          changeData(filteredObj);
        }}
      >
        {({ values, setValues, setFieldValue, errors, touched, resetForm }) => (
          <Form className="mt-2">
            <div className="row">
              <div className="form-group col-12 col-lg-3">
                <label className="control-label">
                  <Translate id="com.tempedge.msg.label.country" />
                </label>
                <DropdownList
                  name={"country"}
                  data={countries}
                  textField={"name"}
                  valueField={"countryId" || 234}
                  value={{ countryId: values.country }}
                  isInvalid={
                    errors && touched && errors.country && touched.country
                  }
                  onChange={({ countryId }) => {
                    const country = countries.filter((country) => {
                      return country.countryId === countryId;
                    })[0];
                    setRegionList(country.regionEntityList);
                    setFieldValue("country", countryId);
                    setFieldValue("region", "");
                  }}
                />
              </div>
              <div className="form-group col-12 col-lg-3">
                <label className="control-label">
                  <Translate id="com.tempedge.msg.label.state" />
                </label>
                <DropdownList
                  data={regionList}
                  textField={"name"}
                  valueField={"regionId"}
                  value={ values.region }
                  isInvalid={errors.region && touched.region}
                  onChange={(regionId) => {
                    setFieldValue("region", regionId);
                  }}
                />
              </div>
              <div className="form-group col-12 col-lg-3">
                <label className="control-label">
                  <Translate id="com.tempedge.msg.label.city" />
                </label>
                <Field
                  className={`form-control tempEdge-input-box`}
                  name={"city"}
                  type="text"
                  value={values.city}
                  placeholder={translate("com.tempedge.msg.label.city")}
                  onChange={(e) =>
                    setFieldValue("city", onlyAlphanumeric(e.target.value.toUpperCase()))
                  }
                />
              </div>
              <div className="form-group col-12 col-lg-3">
                <label className="control-label">
                  <Translate id="com.tempedge.msg.label.agencyzipcode" />
                </label>
                <input
                  className={`form-control tempEdge-input-box 
                   ${
                     getFormSyncErrors &&
                     touched &&
                     errors.zipCode &&
                     touched.zipCode
                       ? "has-error"
                       : ""
                   }`}
                  name={"zipcode"}
                  type={"text"}
                  placeholder={translate(
                    "com.tempedge.msg.label.agencyzipcode"
                  )}
                  value={values.zipcode}
                  onChange={(event) => {
                    event.target.value = normalizeZipCode(event.target.value);
                    setFieldValue("zipcode", event.target.value);
                  }}
                />
              </div>
              {/* <div className="form-group col-12 col-lg-3">
                <label className="control-label">
                  <Translate id="com.tempedge.msg.label.salesman" />
                </label>
                <DropdownList
                  data={salesmanList}
                  textField={"salesmanName"}
                  valueField={"personId"}
                  value={{ personId: values.clientSellerId }}
                  onChange={({ personId }) => {
                    setFieldValue("clientSellerId", personId);
                  }}
                />
              </div> */}
            </div>
            <div className="row mt-5">
              <div className="form-group col-12 col-lg-3">
                <button
                  type="button"
                  onClick={() => cancelSearch(false)}
                  className="btn btn-gray square-right-side w-100"
                >
                  {translate("com.tempedge.msg.label.cancel")}
                </button>
              </div>
              <div className="form-group col-12 col-lg-3">
                <button
                  type="submit"
                  className="btn btn-blue square-left-side w-100"
                >
                  {translate("com.tempedge.msg.label.find")}
                </button>
              </div>
              <div className="form-group col-12 col-lg-3 ml-auto">
                <button
                  type="button"
                  onClick={() => {
                    cancelSearch(true);
                    resetForm();
                    getCountry();
                  }}
                  className="btn btn-orange w-100"
                >
                  {translate("com.tempedge.msg.label.clean")}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withLocalize(ClientSearch);
