export const maritalStatusData = [
  {
    filterBy: null,
    maritalStatusId: 158,
    description: "com.tempedge.msg.label.maritalstatus.single",
    orgId: 2,
    status: "A",
    code: "1",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 159,
    description: "com.tempedge.msg.label.maritalstatus.married",
    orgId: 2,
    status: "A",
    code: "2",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 160,
    description: "com.tempedge.msg.label.maritalstatus.marriedfillsep",
    orgId: 2,
    status: "A",
    code: "3",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 161,
    description: "com.tempedge.msg.label.maritalstatus.marriedworking",
    orgId: 2,
    status: "A",
    code: "4",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 162,
    description: "com.tempedge.msg.label.maritalstatus.marriedoneworking",
    orgId: 2,
    status: "A",
    code: "5",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 163,
    description: "com.tempedge.msg.label.maritalstatus.headhousehold",
    orgId: 2,
    status: "A",
    code: "6",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 164,
    description: "com.tempedge.msg.label.maritalstatus.marriedmultiempl",
    orgId: 2,
    status: "A",
    code: "7",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 165,
    description: "com.tempedge.msg.label.maritalstatus.widow",
    orgId: 2,
    status: "A",
    code: "8",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 166,
    description: "com.tempedge.msg.label.maritalstatus.marriednoliving",
    orgId: 2,
    status: "A",
    code: "9",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 167,
    description: "com.tempedge.msg.label.maritalstatus.marriedjointall",
    orgId: 2,
    status: "A",
    code: "10",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 168,
    description: "com.tempedge.msg.label.maritalstatus.marriedjointhalf",
    orgId: 2,
    status: "A",
    code: "11",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 169,
    description: "com.tempedge.msg.label.maritalstatus.marriedseparateall",
    orgId: 2,
    status: "A",
    code: "12",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 170,
    description: "com.tempedge.msg.label.maritalstatus.marriedjointnon",
    orgId: 2,
    status: "A",
    code: "13",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 171,
    description: "com.tempedge.msg.label.maritalstatus.marriedlivingspouse",
    orgId: 2,
    status: "A",
    code: "14",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 172,
    description: "com.tempedge.msg.label.maritalstatus.marriedwitholdsingle",
    orgId: 2,
    status: "A",
    code: "15",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 173,
    description: "com.tempedge.msg.label.maritalstatus.civilunion",
    orgId: 2,
    status: "A",
    code: "16",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
  {
    filterBy: null,
    maritalStatusId: 174,
    description: "com.tempedge.msg.label.maritalstatus.civilunionwithhold",
    orgId: 2,
    status: "A",
    code: "17",
    username: null,
    page: null,
    size: null,
    sortBy: null,
  },
];

export const Skills = [
  {
    skillId: 18,
    skill: "1st Shift preferred / Prefiere el Primer Turno",
  },
  {
    skillId: 19,
    skill: "2nd Shift preferred / Prefiere el Segundo Turno",
  },
  {
    skillId: 20,
    skill: "3rd Shift preferred / Prefiere el Tercer Turno",
  },
  {
    skillId: 15,
    skill: "About language : Habla Español",
  },
  {
    skillId: 16,
    skill: "About language : Speak English",
  },
  {
    skillId: 4,
    skill: "Assembly / Ensamblador",
  },
  {
    skillId: 3,
    skill: "Forklift Driver / Conductor de Montacargas",
  },
  {
    skillId: 5,
    skill: "Inventory / Inventario",
  },
  {
    skillId: 13,
    skill: "Janitor / Cleaning / Limpieza",
  },
  {
    skillId: 12,
    skill: "Line Supervisor / Supervisor de línea",
  },
  {
    skillId: 1,
    skill: "Machine Operator / Operador de Maquina",
  },
  {
    skillId: 2,
    skill: "Material Handler / Ayudante",
  },
  {
    skillId: 6,
    skill: "Mechanic Machine / Mecánico de maquinas",
  },
  {
    skillId: 8,
    skill: "Microsoft Excel",
  },
  {
    skillId: 7,
    skill: "Microsoft Word",
  },
  {
    skillId: 14,
    skill: "Office / Clerical / Oficina",
  },
  {
    skillId: 11,
    skill: "Pick / Pack",
  },
  {
    skillId: 9,
    skill: "Quick Books",
  },
  {
    skillId: 10,
    skill: "Shipping / Receiving",
  },
  {
    skillId: 17,
    skill: "Work with Cellphones / Trabaja con celulares",
  },
];

export const tax = () => ({
  allowanceJunior: 2000,
  allowanceSenior: 500,
});
