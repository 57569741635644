import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import InputBox from "../../../components/common/InputBox/InputBox.js";
import CountryRegionParser from "../../../components/common/CountryRegionParser/CountryRegionParser.js";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { push } from "connected-react-router";
import Validate from "../../Validations/Validations";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import { normalizeZipCode } from "../../../utils/ReGexs.js";


class WizardCreateNewAgencySecondPage extends Component {
  constructor(props) {
    super(props);

    ActiveLanguageAddTranslation(
      this.props.activeLanguage,
      this.props.addTranslationForLanguage
    );
  }

  state = {
    country_list: [],
    region_list: [],
  };

  componentDidMount = async () => {
    let list = await CountryRegionParser.getCountryList(
      this.props.country_region_list
    ).country_list;

    this.setState({
      country_list: list,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged =
      prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/registerAgency/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(
        this.props.activeLanguage,
        this.props.addTranslationForLanguage
      ).then(() => this.setState({ error: false }));
    }
  }

  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    if (typeof nextProps.country === "undefined") {
      let regionsList = await CountryRegionParser.getRegionList(
        this.props.country_region_list,
        "United States"
      );

      this.setState({
        region_list: regionsList,
      });
    } else {
      let regionsList = await CountryRegionParser.getRegionList(
        this.props.country_region_list,
        nextProps.country.name
      );

      this.setState({
        region_list: regionsList,
      });
    }
  };

  render() {
    let { handleSubmit } = this.props;
    const { translate } = this.props;

    return (
      <div className="wizard-wrapper">
        <h2 className="text-center page-title-agency">
          {translate("com.tempedge.msg.label.newagencyregistration")}
        </h2>
        <div className="row" style={{ padding: "0 2.2rem" }}>
          <div className="col-12">{this.props.resultBar}</div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="panel-body form-horizontal center-block register-form-agency"
          style={{ paddingBottom: "0px" }}
        >
          <div className="form-group row row-agency-name">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2">
                  <label className="control-label pull-right agency-label">
                    {translate("com.tempedge.msg.label.agencyname")}
                  </label>
                </div>
                <div
                  className="col-md-8"
                  style={{ paddingLeft: 0, paddingRight: 71 }}
                >
                  <Field
                    name="agencyname"
                    type="text"
                    placeholder={translate("com.tempedge.msg.label.agencyname")}
                    category="agency"
                    component={InputBox}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="panel register-form-panel">
            <div className="panel-heading register-header">
              <h2 className="text-center">
                {translate("com.tempedge.msg.label.officeinformation")}
              </h2>
            </div>
          </div>

          <div className="register-form-panel-inputs">
            <div className="form-group register-form wizard-register-agency-form row py-4">
                <div className="col-md-6">
                  <label className="control-label">
                    {translate("com.tempedge.msg.label.country")}
                  </label>
                  <Field
                    name="agencycountry"
                    data={this.state.country_list}
                    valueField="countryId"
                    textField="name"
                    category="agency"
                    component={DropdownList}
                  />
                </div>
                <div className="col-md-6">
                  <label className="control-label">
                    {translate("com.tempedge.msg.label.state")}
                  </label>
                  <Field
                    name="agencystate"
                    data={this.state.region_list}
                    valueField="regionId"
                    textField="name"
                    category="agency"
                    component={DropdownList}
                  />
                </div>                
                <div className="col-md-6 pt-2">
                  <label className="control-label">
                    {translate("com.tempedge.msg.label.agencyaddress")}
                  </label>
                  <Field
                    name="agencyaddress"
                    type="text"
                    placeholder={translate(
                      "com.tempedge.msg.label.agencyaddress"
                    )}
                    onInput={({ target }) => (target.value = target.value.replace(/[^#.a-zA-ZÀ-ú,-\d ]+/g, ''))}
                    category="agency"
                    component={InputBox}
                  />
                </div>
                <div className="col-md-6 pt-2">
                  <label className="control-label">
                    {translate("com.tempedge.msg.label.agencyaddress2")}
                  </label>
                  <Field
                    name="agencyappartment"
                    type="text"
                    placeholder={translate(
                      "com.tempedge.msg.label.agencyaddress2"
                    )}
                    onInput={({ target }) => (target.value = target.value.replace(/[^#.a-zA-ZÀ-ú,-\d ]+/g, ''))}
                    category="agency"
                    component={InputBox}
                  />
                </div>
                <div className="col-md-6 pt-2">
                  <label className="control-label">
                    {translate("com.tempedge.msg.label.agencyzipcode")}
                  </label>
                  <Field
                    name="agencyzipcode"
                    type="text"
                    maxlength={6}
                    placeholder={translate(
                      "com.tempedge.msg.label.agencyzipcode"
                    )}
                    category="agency"
                    component={InputBox}
                    normalize={normalizeZipCode}
                  />
                </div>                                                         
                <div className="col-md-6">
                  <label className="control-label">
                    {translate("com.tempedge.msg.label.city")}
                  </label>
                  <Field
                    name="agencycity"
                    type="text"
                    placeholder={translate("com.tempedge.msg.label.city")}
                    category="agency"
                    component={InputBox}
                    onInput={({ target }) => (target.value = target.value.replace(/[^a-zA-ZÀ-ú ]+/g, ''))}
                  />
                </div>     
            </div>
          </div>

          <div className="panel-footer register-footer panel-footer-agency-height-override">
            <div className="prev-next-btns-agency row">
              <div className="col-md-4 offset-md-2">
                <button
                  type="button"
                  className="btn btn-default btn-block register-save-btn previous"
                  onClick={this.props.previousPage}
                >
                  {translate("com.tempedge.msg.label.back")}
                </button>
              </div>
              <div className="col-md-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block register-save-btn next"
                  disabled={this.props.invalid || this.props.pristine}
                >
                  {translate("com.tempedge.msg.label.next")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

WizardCreateNewAgencySecondPage = reduxForm({
  form: "CreateNewAgency", //                 <------ form name
  destroyOnUnmount: false, //        <------ preserve form data
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: Validate,
})(WizardCreateNewAgencySecondPage);

let mapStateToProps = (state) => {
  let selector = formValueSelector("CreateNewAgency"); // <-- same as form name

  return {
    country_region_list: state.tempEdge.country_region_list,
    country: selector(state, "agencycountry"),
  };
};

export default withLocalize(
  connect(mapStateToProps, { push })(WizardCreateNewAgencySecondPage)
);
