import React, { useRef, useEffect, useMemo } from "react";
import { Formik, FieldArray, Form } from "formik";
import * as Yup from "yup";
import GrayCommonBtn from "../../../components/common/Buttons/GrayCommonBtn";
import BlueCommonBtn from "../../../components/common/Buttons/BlueCommonBtn";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import { Col, Row } from "react-bootstrap";
import { Translate } from "react-localize-redux";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import { shiftList } from "../../../components/common/Constants/Constants";

const validateSchema = Yup.object().shape({
  reportClientShifts: Yup.array()
    .of(
      Yup.object().shape({
        reportShiftDesc: Yup.string().required("required"),
        shift: Yup.number()
          .oneOf([0, 1, 2, 3], "Selected shift is invalid")
          .required("required"),
      })
    )
    .required("required")
    .min(1, "At least one shift must be selected"),
});

const UpdateShifts = ({ formData, setFormData, prevStep, nextStep }) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "reportClientShifts",
        formData.reportClientShifts
      );
    }
  }, [formData]);

  const btns = useMemo(() => {
    return (
      <Row className="prev-next-btns-agency mt-5">
        <Col md={{span:5, offset:1}}>
          <GrayCommonBtn
            tag="com.tempedge.msg.label.back"
            onClick={() => {
              setFormData(formikRef.current.values);
              prevStep();
            }}
          />
        </Col>
        <Col md={5}>
          <BlueCommonBtn
            type="button"
            tag="com.tempedge.msg.label.next"
            onClick={() => {
              formikRef.current.submitForm();
              if (formikRef.current.isValid) {
                setFormData(formikRef.current.values);
                nextStep();
              }
            }}
            disabled={!formikRef?.current?.isValid}
          />
        </Col>
      </Row>
    );
  }, [setFormData, prevStep, nextStep]);

  return (
    <ContainerBlue btns={btns} title="com.tempedge.msg.menu.reportconf">
      <Formik
        innerRef={formikRef}
        initialValues={formData}
        validationSchema={validateSchema}
        onSubmit={(values) => {}}
      >
        {(formik) => (
          <Form className="row p-2 justify-content-center">
            <Col md={8}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th colSpan="2">
                      <div className="d-flex justify-content-center">
                        <Translate id="com.tempedge.msg.label.shift" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="reportClientShifts"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {formik.values.reportClientShifts &&
                            formik.values.reportClientShifts.map(
                              (shift, index) => {
                                const shiftError = formik.errors.reportClientShifts?.[index]?.shift;
                                const shiftTouched = formik.touched.reportClientShifts;
                                return (
                                  <tr key={index}>
                                    <td>{shift.reportShiftDesc}</td>
                                    <td>
                                      <DropdownList
                                        value={{
                                          shiftId: shift.shift || shift.reportClientShiftId,
                                        }}
                                        data={shiftList}
                                        valueField="shiftId"
                                        textField="shiftName"
                                        onChange={(shiftSelected) => {
                                          formik.setFieldValue(
                                            `reportClientShifts[${index}].shift`,
                                            shiftSelected.shiftId
                                          );
                                          arrayHelpers.replace(index, {
                                            ...shift,
                                            shift: shiftSelected.shiftId,
                                          });
                                        }}
                                        isInvalid={shiftTouched && !!shiftError}
                                      />
                                      
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </>
                      );
                    }}
                  />
                </tbody>
              </table>
            </Col>
          </Form>
        )}
      </Formik>
    </ContainerBlue>
  );
};

export default UpdateShifts;
