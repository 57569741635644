import React from 'react';

import { withLocalize, Translate } from 'react-localize-redux';
import logo from '../../../assets/static/TELogo.png';
import '../../../assets/styles/components/Loader.css';

function Loader(props) {
  
   return (
            <div className="loader__loader-container">
              <div className="loader__spinners-container">
                <div className="loader__spinners-container--loader-outside">      </div>
                <div className="loader__spinners-container--loader-center">     </div>
                <div className="loader__spinners-container--loader-inside">    </div>
                <img className="loader__img-background" src={logo} alt="TempEdge Logo" />
              </div>
              <div className="loader__loading-message">
                <Translate id="com.tempedge.msg.label.loading" />
              </div>
            </div> 
   );
}

export default withLocalize(Loader);
