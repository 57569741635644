import React from 'react';
import { connect } from 'react-redux';
import {
  addToRemovedEmployeeList,
  changeEmployeeValue,
  disableSaveButton,
  removeEmployeeId
} from '../../Redux/actions/dailyListActions';
import '../../assets/styles/components/TimeSheetRow.css';
import DropdownList from '../../components/common/Dropdown/DropdownList';
import deleteIcon from '../../assets/icons/delete.png';
import EditableCell from './EditableCell';

class TimeSheetRow extends React.Component {
  handleOnChange = async (key, value) => {
    const { id, isPositionMissing } = this.props.employee;
    this.props.changeEmployeeValue({ id, key, value });
    if (key === 'position') {
      if (isPositionMissing) {
        this.props.changeEmployeeValue({ id, key: 'isPositionMissing', value: false });
      }
      this.props.changeEmployeeValue({ id, key: 'isPayMissing', value: false });
    }
  };

  handleOnChangeAttendance = (value) => {
    const { id } = this.props.employee;
    this.props.changeEmployeeValue({ id, key: 'attendance', value: value });
  }

  removeRowFromList = async (employee) => {
    const {addToRemovedEmployeeList,removeEmployeeId } = this.props;
    addToRemovedEmployeeList(employee);
    removeEmployeeId(employee.id);
  }

  highlightRow({ target }) {
    const childIndex = Array.prototype.indexOf.call(target?.parentNode?.children, target);
    if (target?.tagName === 'TD' && (childIndex === 0 || childIndex === 1 || childIndex === 2)) {
      this.handleOnChange('isHighlighted', !this.props.employee?.isHighlighted);
    }
  }

  handleOnBlur(isValidValue, key) {
    const { id, isPayMissing, pay } = this.props.employee;
    this.props.disableSaveButton(!isValidValue);

    if (key === 'pay' && isPayMissing) {
      this.props.changeEmployeeValue({ id, key: 'isPayMissing', value: false });
    }

    if (key === 'pay' && (pay === '' || pay === null || pay === undefined)) {
      this.props.changeEmployeeValue({ id, key: 'isPayMissing', value: true });
    }
  }

  render() {
    const { positionList, employee } = this.props;
    const {
      id,
      employeeId,
      lastName,
      firstName,
      pay,
      attendance,
      position,
      isHighlighted,
      textColor,
      isPositionMissing,
      isPayMissing
    } = employee;

    return (
      <tr className={isHighlighted ? 'time-sheet-row--selected' : 'time-sheet-row'} onClick={(e) => this.highlightRow(e)} key={this.props.key}>
        <td className={textColor}>{employeeId}</td>
        <td className={textColor}>{lastName}</td>
        <td className={textColor}>{firstName}</td>
        <td>
          <DropdownList
            data={positionList}
            valueField="positionId"
            textField="positionName"
            value={position}
            onChange={(value) => this.handleOnChange('position', value)}
            isInvalid={isPositionMissing}
          />
        </td>
        <EditableCell
          name="pay"
          value={pay}
          personId={id}
          handleOnChange={(e) => this.handleOnChange('pay', e.target.value)}
          handleOnBlur={(e) => this.handleOnBlur(e.target.checkValidity(), 'pay')}
          isInvalid={isPayMissing}
        />
        <EditableCell
          name="attendance"
          value={attendance}
          personId={id}
          handleOnChange={(value) => this.handleOnChangeAttendance(value)}
          handleOnBlur={(e) => this.handleOnBlur(e.target.checkValidity(), 'attendance')}
        />
        <td className="text-center">
          <button type="button" onClick={() => this.removeRowFromList(employee)} className="btn">
            <img className="icon-width" src={deleteIcon} alt="Delete icon" />
          </button>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = ({ dailyListReducer }) => ({
  dailyListReducer
});

const mapDispatchToProps = {
  removeEmployeeId,
  changeEmployeeValue,
  addToRemovedEmployeeList,
  disableSaveButton
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheetRow);
