import React, { useState, useEffect, useCallback } from "react";
import { withLocalize } from "react-localize-redux";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import OutcomeBar from "../../components/common/OutcomeBar";
import BlueCommonBtn from "../../components/common/Buttons/BlueCommonBtn";
import GrayCommonBtn from "../../components/common/Buttons/GrayCommonBtn";
import "../../assets/styles/components/CustomReport.css";
import { getAlphabeticSequence } from "../../utils/ReGexs";

const CustomReportFileds = ({
  formData,
  setFormData,
  translate,
  prevStep,
  nextStep,
  message,
  reset,
}) => {
  let userRoleId = `${JSON.parse(localStorage.getItem("agency")).userRoleId}`;

  const [newData, setNewData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (formData.customReport) {
      setData(formData.customReport);
    }
    return () => {
      setData([]);
      setNewData([]);
    };
  }, [formData]);

  const updateDataFromSetOptions = useCallback(() => {
    const { options } = formData;
    let dataFilters = [];
    let updatedOptions = { ...formData.options };

    Object.keys(options).forEach((key) => {
      updatedOptions[key] = options[key].filter((item) => {
        if (item.columnOrder !== null) {
          const alphabeticSequence = getAlphabeticSequence(item.columnOrder);
          dataFilters.push({
            orgId: JSON.parse(localStorage.getItem("agency"))
              ?.organizationEntity?.orgId,
            user: {
              id: userRoleId,
            },
            configuration: item,
            titleKey: key,
            reportParameter: {
              columnOrder: item.columnOrder,
              reportParameterId: item.reportParameterId,
              fieldKey: item.fieldKey,
              position: alphabeticSequence,
            },
            client: {
              clientId: formData.company.clientId,
            },
          });
          return false;
        }
        return true;
      });
    });

    dataFilters.sort(
      (a, b) => a.reportParameter.columnOrder - b.reportParameter.columnOrder
    );
    

    if (formData.customReport && formData.customReport.length > 0) {
      setData(formData.customReport);
      setNewData(formData.options);
    } else {
      setData(dataFilters);
      setNewData(updatedOptions);
    }
  }, [userRoleId, formData]);

  useEffect(() => {
    updateDataFromSetOptions();
  }, [updateDataFromSetOptions]);

  const setNewFiled = (value, key) => {
    const isDuplicate = data.some((item) => item.reportParameterId === value.reportParameterId);
    if (!isDuplicate) {
      const alphabeticSequence = getAlphabeticSequence(data.length);
      setData((prevData) => [
        ...prevData,
        {
          orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId,
          user: {
            id: userRoleId,
          },
          configuration: value,
          titleKey: key,
          reportParameter: {
            columnOrder: data.length,
            reportParameterId: value.reportParameterId,
            fieldKey: value.fieldKey,
            position: alphabeticSequence,
          },
          client: {
            clientId: formData.company.clientId,
          },
        },
      ]);

      if (newData.hasOwnProperty(key)) {
        newData[key] = newData[key].filter((obj) => obj.reportParameterId !== value.reportParameterId);
      }
    }
  };

  const btns = (
    <div className="prev-next-btns-agency row" style={{ marginTop: 30 }}>
      <div className="col-md-4">
        <GrayCommonBtn
          tag="com.tempedge.msg.label.cancel"
          onClick={() => {
            reset();
          }}
        />
      </div>
      <div className="col-md-4">
        <GrayCommonBtn
          tag="com.tempedge.msg.label.back"
          onClick={() => {
            prevStep();
          }}
        />
      </div>
      <div className="col-md-4">
        <BlueCommonBtn
          tag="com.tempedge.msg.label.next"
          onClick={() => {
            if (data.length > 0) {
              setFormData({
                ...formData,
                customReport: data,
                options: newData,
              });
              nextStep();
            }
          }}
        />
      </div>
    </div>
  );

  const handleDeleteItem = (index) => {
    const filteredData = data.filter(
      (obj) =>
        obj.configuration.reportParameterId !==
        index.configuration.reportParameterId
    );
  
    // Actualizar el índice de columnOrder y position después de eliminar el elemento
    filteredData.forEach((item, i) => {
      item.reportParameter.columnOrder = i;
      item.reportParameter.position = getAlphabeticSequence(i);
      item.configuration.columnOrder=i
    });
  
    setData(filteredData);
  
    // Encontrar el arreglo en newData que coincide con el titleKey de index
    const keyToAddTo = Object.keys(newData).find(
      (key) => key === index.titleKey
    );
  
    // Si se encuentra la clave, agregar index.configuration al arreglo correspondiente
    if (keyToAddTo) {
      newData[keyToAddTo].push(index.configuration);
    }
  };

  return (
    <>
      <ContainerBlue title="com.tempedge.msg.title.customreport" btns={btns}>
        {message && <OutcomeBar response={message} />}
        <div className="row p-2 justify-content-center">
          <div className="col-11 col-md-3">
            {/* AQUI SE PINTA LA INFORMACION DE LA TABLA DE LA IZQUIERDA*/}
            <div className="toolbar-left">
              {Object.entries(newData).map(([key, value], index) => (
                <div key={index}>
                  {(
                    <div className="green-text font-weight-bold text-uppercase mb-2 mt-5">
                      {translate(key)}
                    </div>
                  )}
                  <div className="toolbar-container">
                    {value?.map((item, subIndex) => (
                      <div
                        className="toolbar-title"
                        onClick={() => setNewFiled(item, key)}
                        key={subIndex}
                      >
                        <p>
                          {translate(item.fieldKey)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-11 col-md-9 toolbar-content">
            <div>
              <div className="toolbar-contentdata">
                {data.length > 0 &&
                  data?.map((item, index) => (
                    <div key={index} className="toolbar-left">
                      <div
                        className="custom-poitions-title"
                        onClick={() => handleDeleteItem(item)}
                      >
                        <span className="text-black">
                          {item.reportParameter.position}
                        </span>
                      </div>
                      <div className="custom-poitions-subtitle">
                        <p>
                          {translate(item.reportParameter.fieldKey)}
                        </p>
                      </div>
                      <div className="custom-poitions">
                        <p> data 1</p>
                      </div>
                      <div className="custom-poitions">
                        <p> data 2</p>
                      </div>
                      <div className="custom-poitions">
                        <p> data 3</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </ContainerBlue>
    </>
  );
};

export default withLocalize(CustomReportFileds);
