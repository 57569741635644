import React from 'react';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { store } from '../../store/store';
import OutcomeBar from '../../components/common/OutcomeBar';
import { tempedgeAPI } from '../../Redux/actions/tempEdgeActions';
import types from '../../Redux/actions/types';
import Loader from '../../components/common/Loader/Loader';
import { Link } from 'react-router-dom';
import { REACT_APP_URL_SAVE_NEW_PASSWORD } from '../../components/common/Routes/URLs';


const api_url = REACT_APP_URL_SAVE_NEW_PASSWORD;

class SaveNewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      resultBar: '',
      newPassword: '',
      confirmpassword: '',
      loading : false      
    }
    this.addTranslationsForActiveLanguage();
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
    const path = this.props.location.pathname.split("/");

    if (hasActiveLanguageChanged) {
      this.props.push(`/saveNewpassword/${path[2]}/${this.props.activeLanguage.code}`);
      this.addTranslationsForActiveLanguage();
    }
  }

  addTranslationsForActiveLanguage() {
    const { activeLanguage } = this.props;
    const state = store.getState();
    if (!activeLanguage || !state.tempEdge.lang) {
      return;
    }
    if (activeLanguage.code === 'es') {
      this.props?.addTranslationForLanguage(state.tempEdge?.lang?.spanish, activeLanguage.code)
    } else {
      this.props?.addTranslationForLanguage(state.tempEdge?.lang?.english, activeLanguage.code)
    }
  }
  showResultBar(data) {
    this.setState({
      resultBar: <OutcomeBar response={data} />,
    });
  }
  showSuccessResultBar(data) {
    this.showResultBar(data);
  }
  showErrorResultBar(data) {
    this.showResultBar(data);
  }


  handleOnChange = (e) => {
    let { name } = e.target
    let { value } = e.target
    if (!e.target.validity.patternMismatch) {
      e.target.setCustomValidity("")
      this.setState({ resultBar: '' })
    }
    this.setState({ [name]: value })
   
  }

 
  onSubmit = (e) => {
    e.preventDefault()
    const { activeLanguage } = this.props;
    let host = window.location.origin
    const path = this.props.location.pathname.split("/");
    const payload = {
      username: this.state.user,
      newPassword: this.state.newPassword,
      hostname: host,
      token: path[2]
    }
    if(this.state.newPassword !== this.state.confirmpassword){
      return this.showErrorResultBar(new Error('com.tempedge.msg.label.passwordsnomatch'))
    }
    else if(this.state.user && (this.state.newPassword === this.state.confirmpassword)) {
      this.setState({
        loading : true
      })
      this.props.tempedgeAPI(api_url, payload, types.SAVE_NEW_PASSWORD)
        .then(data => {       
          if (data.data.status === 200) {
            this.showSuccessResultBar(data);
            this.setState({ user: '', password: '', confirmpassword: '' })
            setTimeout(()=>this.props.push(`/auth/${activeLanguage.code}`),6000)
          }
          else {           
            this.showErrorResultBar(data);
          }
          this.setState({
            loading : false
          })
        })
        .catch(e => {        
          this.showErrorResultBar(e)
          this.setState({
            loading : false
          })
        })
    } else {
      this.showErrorResultBar(new Error('com.tempedge.error.undefined'));
      this.setState({
        loading : false
      })
    }
 
  }

  validates(e) {
    const { translate } = this.props
    if (this.state.newPassword !== this.state.confirmpassword) {
      e.target.setCustomValidity(translate('com.tempedge.msg.menu.passwordrules'));
      return this.showErrorResultBar(new Error('com.tempedge.msg.label.passwordnomatch'))
    }else{
      e.target.setCustomValidity(translate('com.tempedge.msg.menu.passwordrules'));
      this.setState({ resultBar: '' })
    }
    e.target.setCustomValidity("");
  }

  render() {
    
    const { activeLanguage,translate } = this.props;
    const login = `/signin/${activeLanguage.code}` 
    const { resultBar, user, newPassword, confirmpassword } = this.state;
    if(this.state.loading) 
        return <Loader/>;
    return (
      <div className="container login-container">
        <div className="row">
          <div className="col-md-12">
            <div className="login-form">
              <div className="panel panel-default login-form-panel" >
                <div className="panel-heading login-header">
                  <h2 className="text-center">
                    <Translate id="com.tempedge.changepass.title.changepass" />
                  </h2>
                </div>
                <form className="panel-body" onSubmit={this.onSubmit}>
                  <div className="form-group row">
                    <div className="col-12">
                      {resultBar}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12">
                      <p className="text-left label-p">
                        <Translate id="com.tempedge.msg.label.username" />
                      </p>
                      <input
                        type="text"
                        className={`form-control tempEdge-input-box `}
                        name='user'  
                        pattern='^[a-zA-Z0-9_.\-]{3,30}$'                                       
                        onChange={(e)=>this.handleOnChange(e)}
                        value={user}   
                        onInvalid={(e)=>e.target.setCustomValidity(translate('com.tempedge.msg.menu.usernamerules'))}              
                      />
                    </div>
                    <div className="col-12" style={{ marginTop: '1rem' }} >
                      <p className="text-left label-p">
                        <Translate id="com.tempedge.msg.label.newpassword" />
                      </p>
                      <input
                        type="password"
                        className={`form-control tempEdge-input-box `}
                        name='newPassword'
                        pattern='^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{10,16}$'
                        onChange={this.handleOnChange}                       
                        value={newPassword}
                        onInvalid={(e)=>e.target.setCustomValidity(translate('com.tempedge.msg.menu.passwordrules'))}
                      />
                    </div>
                    <div className="col-12" style={{ marginTop: '1rem' }} >
                      <p className="text-left label-p">
                        <Translate id='com.tempedge.msg.label.confirmpassword' />
                      </p>
                      <input
                        type="password"
                        className={`form-control tempEdge-input-box `}
                        name='confirmpassword'
                        pattern='^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{10,16}$'
                        onChange={this.handleOnChange}
                        onInvalid={(e) => this.validates(e)}
                        value={confirmpassword}                        
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-block" disabled={newPassword !== ''? false:true} >
                      <Translate id="com.tempedge.msg.label.save" />
                    </button>
                  </div>
                </form>
                <div className="panel-footer login-footer">                  
                  <span className="text-right register-link" >
                    <Link className="create-account" to={login} style={{marginTop:'1rem'}}>
                      <Translate id="com.tempedge.msg.label." />
                    </Link>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


let mapStatetoProps = (state) => ({
  error: state?.tempEdge?.response?.data?.error

});


const mapDispatchToProps = {
  push,
  tempedgeAPI,
};


export default withLocalize(connect(mapStatetoProps, mapDispatchToProps)(SaveNewPassword));
