import React, { Component } from "react";
import Select from "react-select";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { change } from "redux-form";

import ActiveLanguageAddTranslation from "../ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import ErrorRender from "../ErrorRender/ErrorRender";
import "../../../assets/styles/Vars.css";

const customStyles = ({ selectGroup }) => ({
  control: (provided, state) => ({
    ...provided,
    borderTopRightRadius: selectGroup ? '0' : '12px',
    borderTopLeftRadius: '12px',
    borderBottomRightRadius: selectGroup ? '0' : '12px',
    borderBottomLeftRadius: '12px',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
    borderColor: state.isFocused ? '#3eb7e9b3' : '#ced4da',
    fontSize: '12px',
    color: '#6c757d',
    '&:hover': {
      borderColor: 'none'
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '2'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: (state.isSelected || state.isFocused) && "bold",
    backgroundColor: state.isFocused ? "var(--tempedge-blue)" : "inherit",
    color: state.isFocused
      ? "white"
      : state.isSelected
      ? "var(--tempedge-blue)"
      : "gray",
    "&:hover": {
      backgroundColor: "var(--tempedge-blue)",
      color: "white",
      fontWeight: "bold",
    },
    "&:active": {
      backgroundColor: "var(--tempedge-blue)",
      color: "white",
    },
  }),
 menuList: (provided) => ({
    ...provided,
    maxHeight: "190px",
  }), 
  indicatorSeparator: () => null,
  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px",
  }),
});

const DropdownListReduxForms = (
  formProps,
  placeholder,
  styles,
  getOptionLabel
) => {
  const errorClass =
    formProps.meta &&
    `${
      (formProps.meta.error && formProps.meta.touched) || (formProps.meta.error && formProps.input.value.personId === null)
        ? "tempEdge-dropdown-input-box has-error-dropdown"
        : "tempEdge-dropdown-input-box"
    }`;

  return (
    <div className={errorClass}>
      <Select
        {...formProps.input}
        value={formProps.input.value || ""}
        options={formProps.data}
        onChange={(value) => formProps.input.onChange(value)}
        onBlur={() => {
          formProps.input && formProps.input.onBlur(formProps.input.value);
        }}
        getOptionLabel={getOptionLabel}
        getOptionValue={(option) => `${option[formProps.valueField]} `}
        clearable
        styles={styles}
        placeholder={placeholder}
      
      />
      <ErrorRender {...formProps} />
    </div>
  );
};

const DropdownListRaw = (props, placeholder, styles, getOptionLabel) => {

  const errorClass = props.isInvalid
    ? "tempEdge-dropdown-input-box has-error-dropdown"
    : "tempEdge-dropdown-input-box";

  return (
    <div className={errorClass}>
      <Select
        value={props?.value}
        options={props?.data}
        onChange={(value) => (props.onChange ? props.onChange(value) : null)}
        getOptionLabel={getOptionLabel}
        getOptionValue={(option) => `${option[props.valueField]} `}       
        styles={styles}
        placeholder={placeholder}
        defaultValue={props.defaultValue}
        isSerchable={props.disabled}
        disabled={props.disabled}
      />
    </div>
  );
};

class DropdownList extends Component {
  
  async componentDidUpdate(prevProps) {
    const hasActiveLanguageChanged =
      prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/employee/new/${this.props.activeLanguage.code}`);
      await this.addTranslationsForActiveLanguage();
    }
  }

  addTranslationsForActiveLanguage = async () => {
    const {
      activeLanguage,
      addTranslationForLanguage,
      requireTranslate,
      data,
    } = this.props;
    await ActiveLanguageAddTranslation(
      activeLanguage,
      addTranslationForLanguage
    );
    if (requireTranslate) {
      const selectedValue = data?.find(
        (row) => row.value === this.props.input.value.value
      );
      if(selectedValue && document){
        this.props.meta.dispatch(
          change(document, this.props.input.name, selectedValue)
        );
      }
    }
  };

  getOptionLabel = (option) => {
    let optionSelected = null;
    const { textField, valueField, data, limit = 30 } = this.props;
    // En caso que se envíe la propiedad que contiene la etiqueta
    if (option[textField] && textField !== "positionName") {
      optionSelected = `${option[this.props.textField]}`;
    } else {
      data?.forEach((item) => {
        if (item[valueField] === option[valueField]) {
          optionSelected = item[textField];
        }
      });
    }
    data && data?.map((data) =>{
      if(data[valueField]=== option[valueField]){        
        optionSelected=data[textField]               
      }
      return null
    })  

    return optionSelected && optionSelected.length > limit ?
          optionSelected.slice(0, limit) + '...' : optionSelected;

  };

  render() {
    const styles = customStyles(this.props);
    const placeholder = this.props.translate("com.tempedge.msg.label.select");
    // Si el componente fue llamado usando redux-forms Field component
    if (this.props.input) {
      return DropdownListReduxForms(
        this.props,
        placeholder,
        styles,
        this.getOptionLabel
      );
    }

    return DropdownListRaw(
      this.props,
      placeholder,
      styles,
      this.getOptionLabel
    );
  }
}

const mapDispatchToProps = {
  push,
  change,
};

export default withLocalize(connect(null, mapDispatchToProps)(DropdownList));
