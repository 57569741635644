import { Component } from 'react';
import { push } from 'connected-react-router';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import PaginatedTable from '../../../components/common/Table/PaginatedTable.js';
import { REACT_APP_URL_PERSON_LIST } from '../../../components/common/Routes/URLs.js';
import { updateMessage } from '../../../Redux/actions/tempEdgeActions.js';

const apiUrl = REACT_APP_URL_PERSON_LIST;

class EmployeeList extends Component {
   constructor(props) {
      super(props);
      this.state = {
         tablePage: 0,
         filterBy: {
            personType: '1',
         },
         data: [],
         search: false,
      };
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   }

   componentDidMount() {
      this.props.updateMessage('');
   }

   componentDidUpdate(prevProps, prevState) {
      const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
      if (hasActiveLanguageChanged) {
         this.props.push(`/employee/list/${this.props.activeLanguage.code}`);
         ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
      }
      this.props.updateMessage('');
   }

   render() {
      const { onClickRows, multipleRows, selectRow, isSelected = false, singleSelect = false } = this.props;

      return (
         <PaginatedTable
            apiUrl={apiUrl}
            filterBy={this.state.filterBy}
            onClickRows={onClickRows}
            multipleRows={multipleRows}
            selectRow={selectRow}
            isSelected={isSelected}
            singleSelect={singleSelect}
            parameterKey="com.tempedge.msg.menu.employeelist"
            title="com.tempedge.msg.menu.employeelist"
         />
      );
   }
}

export default withLocalize(connect(null, { push, updateMessage })(EmployeeList));
