import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/styles/components/FormEmployeesandCandidates.css";
import TempEdgeApi from "../../services/TempEdgeApi";
import ActiveLanguageAddTranslation from "../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js";
import {
  REACT_APP_URL_CANDIDATE_APPLY,
  REACT_APP_URL_CANDIDATE_FIND_ALL,
} from "../../components/common/Routes/URLs";
import {
  DATE_FORMAT,
  DEFAULT_COUNTRY,
} from "../../components/common/Constants/Constants";
import OutcomeBar from "../../components/common/OutcomeBar";
import Loader from "../../components/common/Loader/Loader";
import { Form, Formik } from "formik";
import { Translate, withLocalize } from "react-localize-redux";
import { FormCandidateSchema, transformData } from "./SchemaValidations";
import Contact from "./TabsForms/Contact";
import SkillsCandidate from "./TabsForms/SkillsCandidate";
import MiscCandidate from "./TabsForms/MiscCandidate";
import moment from "moment";
import { maritalStatusData } from "../../components/common/Constants/ConstantsCandidates.js";
import BottonsNavigation from "./BottonsNavigation.js";
import Information from "./TabsForms/Information.js";
import useFetchCountryList from "../../Hooks/useFetchCountryList.js";
import ContainerBlue from "../../components/common/Container/ContainerBlue.js";

const FormCandidate = ({
  activeLanguage,
  history,
  addTranslationForLanguage,
}) => {
  const [maritalStatusFind, setMaritalStatusFind] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [formatSSN, setFormatSSN] = useState("");
  const [page, setPage] = useState(1);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState(null);
  const location = useLocation();
  const countryData = useFetchCountryList();
  const [loading, setLoading] = useState(false);
  const [dataFormCandidate, setDataFormCandidate] = useState(transformData());

  const getTokenFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    return pathSegments[pathSegments.length - 2];
  };

  const token = getTokenFromUrl();
  const [prevActiveLanguage, setPrevActiveLanguage] = useState(activeLanguage);

  useEffect(() => {
    const hasActiveLanguageChanged = prevActiveLanguage !== activeLanguage;
    if (hasActiveLanguageChanged) {
      history.push(`/candidate/registration/${token}/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
      setPrevActiveLanguage(activeLanguage);
    }
  }, [
    activeLanguage,
    history,
    addTranslationForLanguage,
    prevActiveLanguage,
    token,
  ]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (!token) {
        setData(null);
      } else {
        try {
          const { data: res } = await TempEdgeApi.post({
            url: REACT_APP_URL_CANDIDATE_FIND_ALL,
            payload: { registrationToken: token },
          });
          if (res?.status === 200 && res?.code === "TE00") {
            setData(res.result);
            setDataFormCandidate(transformData(res.result));
          } else {
            setMessage(res);
          }
        } catch (error) {
          setMessage(error);
        }finally{
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [token]);

  useEffect(() => {
    async function fetchMarital() {
      if (maritalStatusData) {
        const newMarital = maritalStatusData.map((item) => {
          return {
            label: <Translate id={item.description} />,
            value: item.code,
            status: item.status,
          };
        });
        setMaritalStatusFind(newMarital);
      }
    }
    fetchMarital();
  }, []);

  useEffect(() => {
    if (countryData.length > 0) {
      setCountries(countryData);
      const country = countryData.find(
        (country) => country.countryId === DEFAULT_COUNTRY
      );
      setRegionList(country?.regionEntityList);
      setFormatSSN(country?.idFormat);
    }
  }, [countryData]);

  const handleValidationAndUppercase = (values) => {
    return {
      firstName: values.information.firstName?.trim().toUpperCase(),
      lastName: values.information.lastName?.trim().toUpperCase(),
      phone: values.contact.phone,
      registrationToken: token,
      orgId: data.orgId,
      officeId: data?.officeId,
      personEntity: {
        email: values.contact.email,
        orgId: data.orgId,
        address: values.contact.address?.trim().toUpperCase(),
        address2: values.contact.address2?.trim().toUpperCase(),
        birthDay: moment(values.information.birthDay).format(DATE_FORMAT),
        phone: values.contact.phone,
        cellPhone: values.contact.phone,
        city: values.contact.city?.trim().toUpperCase(),
        country: values.contact.country,
        backgroundtest: values.misc.backgroundTest === 1,
        backgroundTestDate:
          values.misc.backgroundTest === 1
            ? moment(values.misc.backgroundTestDate).format(DATE_FORMAT)
            : null,
        drugTest: values.misc.drugTest === 1,
        drugTestDate:
          values.misc.drugTest === 1
            ? moment(values.misc.drugTestDate).format(DATE_FORMAT)
            : null,
        idExpiration: moment(values.misc.idExpiration).format(DATE_FORMAT),
        firstName: values.information.firstName?.trim().toUpperCase(),
        gender:
          values.information.gender === 0
            ? "M"
            : values.information.gender === 1
            ? "F"
            : null,
        identification: values.information.ssn,
        lastName: values.information.lastName?.trim().toUpperCase(),
        maritalStatus: values.misc.maritalStatus,
        middleName: values.information.middleName?.trim().toUpperCase(),
        region: values.contact.region,
        taxRegion: values.contact.region,
        taxAllowanceJunior: values.misc?.juniorAllowances,
        taxAllowanceSenior: values.misc?.seniorAllowances,
        zipcode: values.contact.zipCode,
        multipleJobs: values.misc.multipleJobs,
        skills: values.skills
          ? values.skills?.map((skill) => ({ skillId: skill }))
          : [],
      },
    };
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    if (data === null || !data.orgId ) {
      setMessage({
        status: 400,
        code: "TE-E00",
        message: "com.tempedge.error.tokenisexpired",
      });
      setLoading(false);
    } else {
      const payload = handleValidationAndUppercase(values);
      try {
        const { data: res } = await TempEdgeApi.post({
          url: REACT_APP_URL_CANDIDATE_APPLY,
          payload,
        });

        if (res.status === 200 && res.code === "TE00") {
          setMessage(res);
          setPage(1);
          setData(null);
          setDataFormCandidate(transformData());
        } else {
          setMessage(res);
          setDataFormCandidate(values);
        }
      } catch (error) {
        setMessage(new Error(error));
        setDataFormCandidate(values);
      }finally{
        setLoading(false);
      }
    }
  };

  const titleForPage = () => {
    switch (page) {
      case 1:
        return "com.tempedge.msg.label.information";
      case 2:
        return "com.tempedge.msg.label.contact";
      case 3:
        return "com.tempedge.msg.label.skills";
      case 4:
        return "com.tempedge.msg.label.misc";
      default:
        return "com.tempedge.msg.label.candidate";
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={dataFormCandidate}
        validationSchema={FormCandidateSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ resetForm,errors,touched}) => {

          if (loading) {
            return (
              <div className="text-center mx-auto">
                <Loader />
              </div>
            );
          }
          const handleCancel = () => {
            setPage(1);
            // Reset the form values to initial values
            resetForm();
          };
          return (
            <ContainerBlue
              btns={
                <BottonsNavigation
                  page={page}
                  setPage={setPage}
                  onCancel={handleCancel}
                />
              }
              title={titleForPage()}
              className={ `${errors.skills && touched.skills ? "new-client-header nav-error" : ""}`}
            >
              {message && <OutcomeBar response={message} />}
              <Form>
                {page === 1 && <Information formatSSN={formatSSN} />}
                {page === 2 && (
                  <Contact
                    countryList={countries}
                    regionList={regionList}
                    setRegionList={setRegionList}
                  />
                )}
                {page === 3 && <SkillsCandidate />}
                {page === 4 && (
                  <MiscCandidate maritalStatusFind={maritalStatusFind} />
                )}
              </Form>
            </ContainerBlue>
          );
        }}
      </Formik>
    </>
  );
};

export default withLocalize(FormCandidate);
