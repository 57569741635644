import React from 'react'
import { withLocalize } from 'react-localize-redux'
import Switch from '../components/common/Switch/Switch'


const TitleMenusDataTables = ({
  translate,
  title,
  sameData,
  setSameData,
  employees,
  isSame =true
}) => {
  return (
    <div className="row mt-2 align-items-center ">
      <div className="col-12 col-lg-3">
        <p className="green-text font-weight-bold text-uppercase tempedge-control-label">
          {translate(`${title}`)}
        </p>
      </div>

      <div className=" col-12 col-lg-3 ">
        <p className='tempedge-control-label-semibold'>
          {translate("com.tempedge.msg.label.listsize")}:
          <span className="badge">{employees && employees.length}</span>
        </p>
      </div>
      <div className=" col-12 col-lg-4"></div>

      {isSame && <div className="col-lg-2 row ml-4 justify-content-around">
        <p className='tempedge-control-label-semibold'>
          {translate("com.tempedge.msg.label.samehour")}
        </p>
      
          <Switch
            name="week"
            checked={sameData}
            onChange={(value) => {
              setSameData(value);
            }}
          />
        
      </div>}
    </div>
  )
}

export default withLocalize(TitleMenusDataTables)