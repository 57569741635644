import types from "../actions/types";

let initialState = {
  login: "",
  timeOutModal: false,
  errorFields: [],
  lastRemoved: "",
  lang: null,
  showModal: false,
  logo: null,
  clients: [],
  clientsCodesDeleted: [],
  personId: 0,
  message: null,
  clientList: [],
  clientListView: [],
  deletePostClient: [],
  countrysList:[],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        loginError: action.payload,
      };
    case types.LOGOUT:
      return {
        ...state,
        login: action.payload,
      };
    case types.GET_COUNTRY_REGION_LIST:
      return {
        ...state,
        country_region_list: action.payload,
      };
    case types.GET_FUNDING_LIST:
      return {
        ...state,
        funding_list: action.payload,
      };
    case types.GET_ROLE_LIST:
      return {
        ...state,
        role_list: action.payload,
      };
    case types.TEMPEDGE_LIST:
      return {
        ...state,
        paginatorList: action.payload,
      };
    case types.SKILLS_LIST:
      return {
        ...state,
        skillsList: action.payload,
      };
    case types.VALIDATE_PERSON:
      return {
        ...state,
        validatePerson: action.payload,
      };
    case types.PERSON_SAVE:
      return {
        ...state,
        savePerson: action.payload,
      };
    case types.PERSON_UPDATE:
      return {
        ...state,
        updatePerson: action.payload,
      };
    case types.GET_ORG_DEPARTMENT_LIST:
      return {
        ...state,
        orgDepartmentList: action.payload,
      };
    case types.GET_OFFICE_LIST:
      return {
        ...state,
        officeList: action.payload,
      };
    case types.VIEW_OFFICE:
      return {
        ...state,
        viewOffice: action.payload,
      };
    case types.UPTDATE_CLIENT_LIST:
      return {
        ...state,
        clients: action.payload,
      };
    case types.DELETE_CLIENT_CODE:
      return {
        ...state,
        clientsCodesDeleted: [...state.clientsCodesDeleted, action.payload],
      };
    case types.CREATE_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case types.UPDATE_CLIENTE:
      return {
        ...state,
        client: action.payload,
      };
    case types.GET_CLIENT_LIST: {
      return {
        ...state,
        clientList: action.payload,
      };
    }
    case types.GET_COUNTRY_LIST_ALL: {
      return {
        ...state,
        countrysList: action.payload,
      };
    }
    case types.SAVE_FORM_POSITION:
      let formPosition = `${action.payload.form}WizardFormTracker`;
      return {
        ...state,
        [formPosition]: action.payload,
      };
    case types.SAVE_DEPARTMENTS_LIST:
      return {
        ...state,
        deptList: action.payload,
      };
    case types.DELETE_POST_CLIENT:
      return {
        ...state,
        deletePostClient: action.payload,
      };
    case types.SAVE_POSITIONS_LIST:
      return {
        ...state,
        deptPosList: action.payload,
      };
    case types.SAVE_TO_POSITIONS_LIST:
      let newState =
        typeof state.deptPosList === "undefined" ? [] : state.deptPosList;

      if (action.payload === "CLEAR") {
        newState = [];
      } else {
        newState.push(action.payload);
      }

      return {
        ...state,
        deptPosList: newState,
      };
    case types.REMOVE_FROM_POSITIONS_LIST:
      let newPosListState = state.deptPosList;
      let posIndex = action.payload;

      if (newPosListState !== undefined) {
        if (newPosListState[posIndex] !== undefined) {
          newPosListState.splice(posIndex, 1);
        }
      }
      return {
        ...state,
        deptPosList: newPosListState,
      };
    case types.REMOVE_FROM_DEPARTMENTS_LIST: {
      let newDeptListState = state.deptList;
      let index = action.payload;

      if (newDeptListState !== undefined) {
        if (newDeptListState[index] !== undefined) {
          newDeptListState.splice(index, 1);
        }
      }

      return {
        ...state,
        deptList: newDeptListState,
      };
    }
    case types.CLEAR_PROP:
      return {
        ...state,
        [action.payload]: undefined,
      };
    case types.CLEAR_ERROR_FIELD:
      return {
        ...state,
        errorFields: action.payload.errorFields,
        lastRemoved: action.payload.lastRemoved,
      };
    case types.SET_ERROR_FIELD:
      let errorFields =
        typeof state.errorFields === "undefined" ? [] : [...state.errorFields];
      let error = action.payload;

      if (errorFields.indexOf(error) < 0) {
        errorFields.push(error);
      }

      return {
        ...state,
        errorFields: errorFields,
        lastRemoved: "",
      };
    case types.REMOVE_ERROR_FIELD:
      let errorFieldsArray =
        typeof state.errorFields === "undefined" ? [] : [...state.errorFields];
      let error_ = action.payload;
      let foundIndex = -1;

      if (Array.isArray(errorFieldsArray)) {
        errorFieldsArray.forEach((errorField, index) => {
          if (errorField === error_) {
            foundIndex = index;
          }
        });
      }

      if (foundIndex > -1) {
        errorFieldsArray.splice(foundIndex, 1);
      }

      return {
        ...state,
        errorFields: errorFieldsArray,
        lastRemoved: error_,
      };
    case types.SAVE_BILL_RATE:
      return {
        ...state,
        billRate: action.payload,
      };
    case types.SAVE_OT_BILL_RATE:
      return {
        ...state,
        otBillRate: action.payload,
      };
    case types.GET_ACTIVITY_LIST:
      return {
        ...state,
        activityList: action.payload, // This comment on 13 abr. 2020
      };
    case types.GET_AGENCY_LIST:
      return {
        ...state,
        agencyList: action.payload, // This comment on 13 abr. 2020
      };
    case types.GET_SALESMAN_LIST:
      return {
        ...state,
        salesmanList: action.payload,
      };
    case types.SAVE_EMPLOYEE_LIST:
      return {
        ...state,
        saveEmployeeList: action.payload,
      };
    case types.CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: action.payload,
      };
    case types.RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.payload,
      };

    case types.SAVE_NEW_PASSWORD:
      return {
        ...state,
        resetPassword: action.payload,
      };

    case types.MODAL_VISIBILITY:
      return {
        ...state,
        showModal: action.payload,
      };

    case types.GET_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    case types.VIEW_PERSON:
      return {
        ...state,
        saveEmployeeList: action.payload,
      };

    case types.VIEW_PERSON_CHANGE_STATUS:
      return {
        ...state,
        viewPersonStatus: action.payload,
      };
    case types.VIEW_CLIENT:
      return {
        ...state,
        clientListView: action.payload,
      };
    case types.SAVE_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case types.CLEAN_LOGO:
      return {
        ...state,
        logo: null,
      };
    case types.TIMEOUT_MODAL:
      return {
        ...state,
        timeOutModal: action.payload,
      };

    case types.VIEW_USER:
      return {
        ...state,
        viewUser: action.payload,
      };

    case types.VIEW_USER_ID:
      return {
        ...state,
        viewUserId: action.payload,
      };

    case types.VIEW_USER_CHANGE_STATUS:
      return {
        ...state,
        viewUserStatus: action.payload,
      };

    case types.ENTITY_ID:
      return {
        ...state,
        [action.payload.data]: action.payload.id,
      };

    case types.UPDATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };

    case types.GET_ROLE:
      return {
        ...state,
        getRole: action.payload,
      };
    case types.APPROVE_USER:
      return {
        ...state,
        approveUser: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
