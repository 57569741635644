import React from 'react'
import { ProfileInfoCard } from '../ProfileInfoCard'
import { ReleaseNotesCard } from '../ReleaseNotesCard'
import Container from '../../../components/common/Container/Container'
import { Col } from 'react-bootstrap'

export const CommonDashboard = ({ translate }) => {
    return (
        <>
            <Container>
                <Col md={8} lg={8} sm={12}>
                    <ReleaseNotesCard />
                </Col>
                <Col md={4} lg={4} sm={12}>
                    <ProfileInfoCard translate={translate} />
                </Col>
            </Container>
        </>
    )
}
