import { customPayrollTypes } from "../types/customPayrollTypes";

const initialState = {
  employeesInCustomPayroll: [],
  payrollEntityList: [],
  deletedItems: [],
};

export const customPayrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case customPayrollTypes.ADD_EMPLOYEE_IN_CUSTOMPAYROLL:
      return {
        ...state,
        employeesInCustomPayroll: [
          ...state.employeesInCustomPayroll,
          action.payload,
        ],
      };
    case customPayrollTypes.ADD_CUSTOM_PAYROLL: {
      let newListCustomPayroll = [...state.payrollEntityList];
      if(action.payload.length >0 ){
      for (let payroll of action.payload) {
        const existingIndex = newListCustomPayroll.findIndex(
          (item) => item.id === payroll.id
        );
        if (existingIndex !== -1) {
          newListCustomPayroll[existingIndex] = {
            ...newListCustomPayroll[existingIndex],
            ...payroll,
          };
        } else {
          newListCustomPayroll.push(payroll);
        }
      }}
      return {
        ...state,
        payrollEntityList: newListCustomPayroll,
      };
    }
    case customPayrollTypes.ADD_SAME_DATA_CUSTOM_PAYROLL:{
     const updatedPayrollEntityList = state.employeesInCustomPayroll.map(
       (employee) => {
         const { personId } = employee
         const updatedPayload = {
           ...action.payload,
           id: personId, 
           person: {
             personId: personId
           }
         }
         return updatedPayload
       }
     )
     const employeesInCustomPayrollSame = state.employeesInCustomPayroll.map((employee)=>{
        let customSame=state.payrollEntityList.filter(item=>item.id !== employee.personId)
        let confirmEmployeeSameData={...employee}
        if (customSame.length >= 1) {
          confirmEmployeeSameData["confirmation"] = true;
        } else {
          confirmEmployeeSameData["confirmation"] = false;
        }

        return confirmEmployeeSameData
     
     })

     return {
       ...state,
       payrollEntityList: [
         ...state.payrollEntityList,
         ...updatedPayrollEntityList
       ],
       employeesInCustomPayroll:employeesInCustomPayrollSame
     }
    }

    case customPayrollTypes.DELETE_CUSTOM_PAYROLL: {
      let newListCustomPayroll = [...state.payrollEntityList];
      let deletedItems = [];
      const existingIndex = newListCustomPayroll.findIndex(
        (item) => item.id === action.payload.id
      );
      if (existingIndex !== -1) {
        deletedItems.push(newListCustomPayroll[existingIndex]);
        newListCustomPayroll = newListCustomPayroll.filter(
          (item, index) => index !== existingIndex
        );
      } else {
        deletedItems.push(action.payload);
      }
    
      return {
        ...state,
        payrollEntityList: newListCustomPayroll,
        deletedItems:[...state.deletedItems,...deletedItems],
      };
    }
    

    case customPayrollTypes.REMOVE_EMPLOYEE_IN_CUSTOMPAYROLL:
      return {
        ...state,
        employeesInCustomPayroll: state.employeesInCustomPayroll.filter(
          (employee) => employee.personId !== action.payload.personId
        ),
        payrollEntityList: state.payrollEntityList.filter(
          (employee) => employee.personId !== action.payload.personId
        ),
      };

    case customPayrollTypes.REMOVE_VALUE_CUSTOM_PAYROLL:
      const updatedPayrollEntityList = state.payrollEntityList.filter(
        (item) => {
          return item.id !== action.payload;
        }
      );
      return {
        ...state,
        payrollEntityList: updatedPayrollEntityList,
      };
    case customPayrollTypes.DELETE_VALUE_CUSTOM_PAYROLL:
      return {
        ...state,
      };

    case customPayrollTypes.CHANGE_EMPLOYEE_VALUE: {
      const { id, key, value } = action.payload;
      let employees = [...state.employeesInCustomPayroll];
      for (let employee of employees) {
        if (employee.personId === id) {
          employee[key] = value;
          break;
        }
      }
      return {
        ...state,
        employeesInCustomPayroll: employees,
      };
    }
    case customPayrollTypes.RESET_ENTIRE_STATE:
      return initialState;
    default:
      return state;
  }
};
