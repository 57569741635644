import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import InputBox from "../../../components/common/InputBox/InputBox.js";
import Validate from "../../Validations/Validations";
import { onlyDigitsAndDot, onlyNumbersandDecimals } from '../../../utils/ReGexs.js';
import { withRouter } from 'react-router-dom'; // Agrega withRouter

class WizardCreateNewUserFirstPage extends Component {
  constructor(props) {
    super(props);
    ActiveLanguageAddTranslation(
      this.props.activeLanguage,
      this.props.addTranslationForLanguage
    );
  }

  state = {
    mounted: false,
    salesmen: [],
  };

  componentDidMount() {
    this.setState(() => ({
      mounted: true,
    }));

    this.props.getDispatch(this.props.dispatch);
    this.concatSalesmanName();
  }

  concatSalesmanName() {
    const salesmen = this.props.salesmanList;
    salesmen?.forEach((salesman) => {
      salesman.salesmanName = salesman.firstName + " " + salesman.lastName;
    });
    this.setState({ salesmen });
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged =
      prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      let path = this.props.location.pathname.split("/");
      this.props.history.push(`/client/${path[2]}/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(
        this.props.activeLanguage,
        this.props.addTranslationForLanguage
      );
    }

    if (prevProps.salesmanList.length !== this.props.salesmanList.length) {
      this.concatSalesmanName();
    }
  }

  resetAllData = async () => {
    this.props.resetResultBar();
    this.props.resetEntireForm();
  };

  renderError(formProps) {
    let fieldId = "";

    if (typeof formProps.input !== "undefined") {
      if (
        formProps.index != null ||
        typeof formProps.index != "undefined" ||
        formProps.index !== ""
      ) {
        if (
          formProps.input.name.indexOf("recruitmentofficesalespersons") !== -1
        ) {
          if (formProps.input.name.indexOf("salespersonfirstname") !== -1) {
            fieldId = `com.tempedge.error.person.firstNamerequired`;
          } else if (
            formProps.input.name.indexOf("salespersonlastname") !== -1
          ) {
            fieldId = `com.tempedge.error.person.lastNamerequired`;
          } else if (formProps.input.name.indexOf("salespersongenre") !== -1) {
            fieldId = `com.tempedge.error.person.genderrequired`;
          } else if (
            formProps.input.name.indexOf("salespersonphonenumber") !== -1
          ) {
            fieldId = `com.tempedge.error.phonenumberrequired`;
          }
        }
      }

      if (
        formProps.meta.touched &&
        formProps.meta.error &&
        typeof formProps.meta.error !== "undefined"
      ) {
        return (
          <p style={{ color: "#a94442" }}>
            <Translate id={fieldId}>{formProps.meta.error}</Translate>
          </p>
        );
      }
    }
  }

  render() {
    let payrollCycle = [
      {
        payrollCycle:
          this.props.translate("com.tempedge.msg.label.sun") +
          " - " +
          this.props.translate("com.tempedge.msg.label.sat"),
        payrollId: 0,
      },
      {
        payrollCycle:
          this.props.translate("com.tempedge.msg.label.mon") +
          " - " +
          this.props.translate("com.tempedge.msg.label.sun"),
        payrollId: 1,
      },
      {
        payrollCycle:
          this.props.translate("com.tempedge.msg.label.tue") +
          " - " +
          this.props.translate("com.tempedge.msg.label.mon"),
        payrollId: 2,
      },
      {
        payrollCycle:
          this.props.translate("com.tempedge.msg.label.wed") +
          " - " +
          this.props.translate("com.tempedge.msg.label.tue"),
        payrollId: 3,
      },
      {
        payrollCycle:
          this.props.translate("com.tempedge.msg.label.thu") +
          " - " +
          this.props.translate("com.tempedge.msg.label.wed"),
        payrollId: 4,
      },
      {
        payrollCycle:
          this.props.translate("com.tempedge.msg.label.fri") +
          " - " +
          this.props.translate("com.tempedge.msg.label.thu"),
        payrollId: 5,
      },
      {
        payrollCycle:
          this.props.translate("com.tempedge.msg.label.sat") +
          " - " +
          this.props.translate("com.tempedge.msg.label.fri"),
        payrollId: 6,
      },
    ];
    return (
      <div
        className="sign-up-wrapper"
        style={{ margin: 0 }}
        ref="createNewUser1"
      >
        <h2 className="text-center page-title-new-client">
          {!this.props.editClient ? (
            <Translate id="com.tempedge.client.title.createclient"></Translate>
          ) : (
            <Translate id="com.tempedge.client.title.updateclient"></Translate>
          )}
        </h2>
        <div className="row" style={{ padding: "0 2.2rem" }}>
          <div className="col-12">{this.props.resultBar}</div>
        </div>
        <form
          onSubmit={this.props.handleSubmit}
          className="form-horizontal center-block panel-body"
          style={{ paddingBottom: "0px" }}
        >
          <div className="row new-client-form">
            <div className="col-lg-8 client-col">
              <div className="create-client">
                <div className="new-client-header">
                  <h2>
                    {!this.props.editClient ? (
                      <Translate id="com.tempedge.client.title.createclient"></Translate>
                    ) : (
                      <Translate id="com.tempedge.client.title.updateclient"></Translate>
                    )}
                  </h2>
                </div>

                <div className="new-clients-contents">
                  <div className="client-contents">
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.company"></Translate>
                        </label>
                        <Field
                          name="company"
                          type="text"
                          category="client"
                          maxlength="50"
                          component={InputBox}
                          readOnly={false}
                          onInput={({ target }) =>
                            (target.value = target.value.replace(
                              /[^a-zA-ZÀ-ú 0-9 .,-/_]+/g, 
                              ""
                            ))
                          }
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.salesman"></Translate>
                        </label>
                        <Field
                          name="salesman"
                          data={this.state.salesmen}
                          valueField="personId"
                          textField="salesmanName"
                          category="client"
                          component={DropdownList}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.payrollCycle"></Translate>
                        </label>
                        <Field
                          name="payrollCycle"
                          data={payrollCycle}
                          valueField="payrollId"
                          textField="payrollCycle"
                          category="client"
                          component={DropdownList}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.workCompCode"></Translate>
                        </label>
                        <Field
                          name="workCompCode"
                          type="text"
                          category="client"
                          maxlength="6"
                          onInput={({ target }) =>
                            (target.value = target.value.replace(
                              onlyNumbersandDecimals,
                              ""
                            ))
                          }
                          component={InputBox}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.workCompRate"></Translate>
                        </label>
                        <Field
                          name="workCompRate"
                          type="number"
                          category="client"
                          maxlength="6"                          
                          onInput={({ target }) =>
                            (target.value = target.value.replace(
                              onlyNumbersandDecimals,
                              ""
                            ))
                          }
                          component={InputBox}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.companyInitials"></Translate>
                        </label>

                        <Field
                          name="companyInitials"
                          type="text"
                          maxlength="20"
                          category="client"
                          component={InputBox}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.attnTo"></Translate>
                            </label>
                            <Field
                              name="attnTo"
                              type="text"
                              category="client"
                              maxlength="30"
                              onInput={({ target }) =>
                                (target.value = target.value.replace(
                                  /[^a-zA-ZÀ-ú 0-9 ._,-]+/g,
                                  ""
                                ))
                              }
                              component={InputBox}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.email"></Translate>
                            </label>
                            <Field
                              name="email"
                              type="email"
                              category="client"
                              component={InputBox}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.markupClient"></Translate>
                            </label>
                            <Field
                              name="markupClient"
                              type="text"
                              category="client"
                              component={InputBox}
                              maxlength="5"
                              onInput={({ target }) => {
                                target.value = onlyDigitsAndDot(target.value);
                              }}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.otMarkupClient"></Translate>
                            </label>
                            <Field
                              name="otMarkupClient"
                              type="text"
                              category="client"
                              component={InputBox}
                              maxlength="5"
                              onInput={({ target }) => {
                                target.value = onlyDigitsAndDot(target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="form-group col-12">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.comments"></Translate>
                            </label>
                            <Field
                              name="comments"
                              type="textarea"
                              category="client"
                              component={InputBox}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row py-4">
                    <div className="col-md-5 offset-md-1">
                      <button
                        type="button"
                        className="btn btn-default btn-block register-save-btn previous"
                        onClick={() => {
                          this.resetAllData();
                          if (this.props.editClient) {
                            this.props.history.goBack(); // Utiliza history.goBack() para retroceder
                          }
                        }}
                      >
                        <Translate id="com.tempedge.msg.label.cancel"></Translate>
                      </button>
                    </div>
                    <div className="col-md-5">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block register-save-btn next"
                        disabled={this.props.invalid}
                      >
                        <Translate id="com.tempedge.msg.label.next"></Translate>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 dept-col">
              <div className="department-list">
                <div className="department-list-header">
                  <h2>
                    <Translate id="com.tempedge.msg.label.departmentlist" />
                  </h2>
                </div>

                <div className="department-list-contents">
                  <div>
                    {!this.props.renderAddBtnDirty ? (
                      this.props.renderAddBtn()
                    ) : (
                      <div>
                        {this.props.departmentList}
                        {this.props.addDeptBtn}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

WizardCreateNewUserFirstPage = reduxForm({
  form: "CreateNewClient", //                 <------ form name
  destroyOnUnmount: false, //        <------ preserve form data
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: Validate,
})(WizardCreateNewUserFirstPage);

let mapStateToProps = (state) => {
  let clientdepartments = "";
  if (state.form.CreateNewClient !== undefined) {
    clientdepartments =
      state.form.CreateNewClient.values !== undefined
        ? state.form.CreateNewClient.values.clientdepartments
        : undefined;
  } else {
    clientdepartments = undefined;
  }

  return {
    clientDepartments: clientdepartments,
    salesmanList:
      state.tempEdge.salesmanList &&
      state.tempEdge.salesmanList.data &&
      state.tempEdge.salesmanList.data.result
        ? state.tempEdge.salesmanList.data.result.content
        : [],
  };
};

export default withLocalize(
  connect(mapStateToProps)(withRouter(WizardCreateNewUserFirstPage)) // Agrega withRouter
);