import { withLocalize } from 'react-localize-redux';
import { REACT_APP_URL_PERSON_LIST } from '../../../components/common/Routes/URLs';
import PaginatedTable from '../../../components/common/Table/PaginatedTable';

function ValidatePerson(props) {
   return (
      <>
         <div className="d-flex flex-column align-items-center">
            <PaginatedTable
               apiUrl={REACT_APP_URL_PERSON_LIST}
               filterBy={{
                  personType: '1',
                  firstName: props.dataSave.result.data[0].firstName,
                  lastName: props.dataSave.result.data[0].lastName,
               }}
               title="com.tempedge.msg.label.validatedpersonlist"
            />
            <div style={{ width: '100%' }}>
               <div className="row py-4">
                  <div className="col-md-5 offset-md-1">
                     <button type="button" className="btn btn-default btn-block register-save-btn previous" onClick={props.onCancel}>
                        {props.translate('com.tempedge.msg.label.cancel')}
                     </button>
                  </div>
                  <div className="col-md-5">
                     <button type="button" className="btn btn-primary btn-block register-save-btn next" onClick={() => props.onSave()}>
                        {props.translate('com.tempedge.msg.label.save')}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default withLocalize(ValidatePerson);
