import React, { useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { withLocalize } from "react-localize-redux";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import Loader from "../../../components/common/Loader/Loader";
import OutcomeBar from "../../../components/common/OutcomeBar";
import Switch from "../../../components/common/Switch/Switch";
//services
import { shallowEqual, useSelector } from "react-redux";
import {
  REACT_APP_URL_PAYROLL_SAVE_BY_REPORT,
  REACT_APP_URL_PERSON_SAVE_BY_REPORT,
} from "../../../components/common/Routes/URLs";
import TempEdgeApi from "../../../services/TempEdgeApi";

const sizeArray = 20;

const WizardConfirmation = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  const [confirmation, setConfirmation] = useState(false);
  const [message, setMessage] = useState(null);
  const [barProgress, setBarProgress] = useState(0);
  const [loading, setLoading] = useState(0);

  const user = useSelector((state) => {
    return state.tempEdge?.login?.portalUserList[0]?.user || null;
  }, shallowEqual);

  const handleSubmit = async () => {
    if (
      confirmation &&
      !(
        props.data.response.missName !== 0 ||
        props.data.response.noEmployeeNoList.length !== 0 ||
        props.data.response.missPunchList.length !== 0
      )
    ) {
      let payrolls = props.data.response.payrollWeekVOList;
      try {
        setLoading(true);
        const errors = [];
        for (let payroll of payrolls) {
          if (payroll.person.personId === null) {
            const { data } = await TempEdgeApi.post({
              url: REACT_APP_URL_PERSON_SAVE_BY_REPORT,
              payload: payroll.person,
            });
            if (data.status === 200 && data.code === "TE00") {
              payroll.person = data.result;
            } else {
              errors.push(data);
            }
          }
        }
        if (errors.length > 0) {
          setLoading(false);
          setMessage(errors[0]);
          return;
        } else {
          setBarProgress(5);
          setLoading(false);
          const count = Math.floor(payrolls.length / sizeArray);
          const residuos = Math.floor(payrolls.length % sizeArray);
          let index = 0;
          for (let i = 1; i <= count; i++) {
            const done = Math.floor((100 * i) / count);
            let currentPayrolls = payrolls.slice(index, i * sizeArray);
            const { data } = await TempEdgeApi.post({
              url: REACT_APP_URL_PAYROLL_SAVE_BY_REPORT,
              payload: {
                clientId: props.data.form.company.clientId,
                officeId: props.data.form.office.officeId,
                shift: 1,
                parameterKey: "com.tempedge.msg.menu.payroll",
                payrollEntityList: currentPayrolls,
              },
            });
            if (data.status !== 200 && data.code !== "TE00") {
              setMessage(data);
              return;
            }
            index = i * sizeArray;
            setBarProgress(done);           
            if (done >= 100 && residuos === 0) {
              props.reset(data);
            }
          }
          if (residuos !== 0) {
            let currentpayrollsResiduos = payrolls.slice(
              index,
              payrolls.length
            );
            const { data } = await TempEdgeApi.post({
              url: REACT_APP_URL_PAYROLL_SAVE_BY_REPORT,
              payload: {
                clientId: props.data.form.company.clientId,
                officeId: props.data.form.office.officeId,
                shift: 1,
                parameterKey: "com.tempedge.msg.menu.payroll",
                payrollEntityList: currentpayrollsResiduos,
              },
            });

            if (data.status !== 200 && data.code !== "TE00") {
              setMessage(data);
              return;
            }
            setBarProgress(100);
            props.reset(data);
          }
        }
      } catch (e) {
        setMessage(e);
      }
    }
  };

  const btns = (
    <div className="row py-4">
      <div className="col-md-5 offset-md-1">
        <button
          type="button"
          className="btn btn-default btn-block register-save-btn previous"
          onClick={() => {
            props.reset();
          }}
        >
          {props.translate("com.tempedge.msg.label.cancel")}
        </button>
      </div>
      <div className="col-md-5">
        <button
          type="submit"
          disabled={
            !confirmation ||
            props.data.response.missName !== 0 ||
            props.data.response.noEmployeeNoList.length !== 0 ||
            props.data.response.missPunchList.length !== 0 ||
            barProgress !== 0
          }
          className="btn btn-primary btn-block register-save-btn next"
          onClick={() => {
            trackPromise(handleSubmit());
          }}
        >
          {props.translate("com.tempedge.msg.label.submit")}
        </button>
      </div>
    </div>
  );

  if (loading) return <Loader />;

  return (
    <ContainerBlue
      title="com.tempedge.msg.label.upload"
      btns={btns}
      width="60%"
    >
      {message && <OutcomeBar response={message} />}
      {promiseInProgress && barProgress > 0 && ( 
        <div className="mt-2 mb-5">
          <ProgressBar striped animated now={barProgress} label={`${barProgress}%`} />
        </div>
      )}
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex mb-3 justify-content-between mb-4">
            <span className="confirmation-page-categories w-50">
              {props.translate("com.tempedge.msg.label.company")}
            </span>
            <span className="ml-4 confirmation-page-paragraph">
              {props.data.form.company.clientName}
            </span>
          </div>
          <div className="d-flex mb-3 justify-content-between mb-4 ">
            <span className="confirmation-page-categories w-50">
              {props.translate("com.tempedge.msg.label.totalemployeesread")}
            </span>
            <span className="ml-4 confirmation-page-paragraph">
              {props.data.response.payrollWeekVOList.length}
            </span>
          </div>
          <div className="d-flex mb-3 justify-content-between align-items-center mb-4">
            <span className="confirmation-page-categories w-50">
              {props.translate("com.tempedge.msg.label.hasemptyname")}
            </span>
            <span className="ml-4 confirmation-page-paragraph">
              {props.data.response.missName}
            </span>
          </div>
          <div className="d-flex mb-3 justify-content-between align-items-center mb-4">
            <span className="confirmation-page-categories w-50">
              {props.translate(
                "com.tempedge.msg.label.employeewithoutemployeeid"
              )}
            </span>
            <span className="ml-4 confirmation-page-paragraph">
              {props.data.response.noEmployeeNoList.length}
            </span>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column">
          {props.data.response.missName === 0 &&
            props.data.response.missPunchList.length === 0 &&
            props.data.response.noEmployeeNoList.length === 0 && (
              <div className="alert alert-success">
                {props.translate("com.tempedge.msg.label.filesuccess")}
              </div>
            )}
          {props.data.response.missPunchList.length !== 0 && (
            <>
              <div className="alert alert-danger">
                {props.translate("com.tempedge.msg.label.employeemisspuchlist")}
              </div>
              <table className="table table-striped table-input-group">
                <thead className="">
                  <tr>
                    <th className="table-header-left text-center w-10"></th>
                    <th className="table-header-mid text-center w-10"></th>
                  </tr>
                </thead>
                <tbody>
                  {props?.data?.response?.missPunchList?.map((item) => {
                    return (
                      <tr>
                        <td>{item?.person?.fullName}</td>
                        <td>{item?.dateList}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
          {props?.data?.response?.noEmployeeNoList?.length !== 0 && (
            <>
              <div className="alert alert-danger">
                {props.translate(
                  "com.tempedge.msg.label.employeewithoutemployeenumber"
                )}
              </div>
              <table className="table table-striped table-input-group">
                <thead className="">
                  <tr>
                    <th className="table-header-left text-center w-10"></th>
                    <th className="table-header-mid text-center w-10"></th>
                  </tr>
                </thead>
                <tbody>
                  {props?.data?.response?.noEmployeeNoList?.map((item) => {
                    return (
                      <tr>
                        <td colSpan="2">{item}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div
          className="col-md-12 d-flex pt-4"
          style={{ borderTop: "1px dashed gray" }}
        >
          <Switch
            name="confirmation"
            checked={confirmation}
            onChange={(value) => {
              setConfirmation(value);
            }}
          >
            <p>
              {props.translate("com.tempedge.msg.label.clickingconfirm1")}
              {user && (
                <span className="font-weight-bold">
                  {user.firstName + " " + user.lastName + " "}
                </span>
              )}
              {props.translate("com.tempedge.msg.label.clickingconfirm2")}
            </p>
          </Switch>
        </div>
      </div>
    </ContainerBlue>
  );
};

export default withLocalize(WizardConfirmation);
