import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Field } from 'redux-form';
import InputBox from '../../../components/common/InputBox/InputBox.js';
import { normalizeExtention, normalizePhone } from '../../../utils/ReGexs.js';

import deleteIcon from "./assets/delete.png"; // Tell Webpack this JS file uses this image
import addIcon from "./assets/plus.png";

let RenderPhoneNumberInputs = (formProps) => {


  useEffect(() => {
    if(formProps.fields.length < 1){
      formProps.fields.push({});
    }
  });

  return(
    <div className="list" style={{padding: 0}}>
      {formProps.fields.map((phoneOwner, index) => {
        return(
          <div key={index} className="list-item row mb-2 pb-4 border-bottom">
            <div className="col-md-4">
              <label className="control-label">{formProps.translate('com.tempedge.msg.label.phone')}</label>
              <Field
                name={`${phoneOwner}.phonenumber`}
                type="text"
                index={index}
                placeholder="xxx-xxx-xxxx"
                category="person"
                component={InputBox}
                normalize={normalizePhone}
              />
            </div>

            <div className="col-md-4">
              <label className="control-label">{formProps.translate('com.tempedge.msg.label.phoneext')}</label>
              <Field name={`${phoneOwner}.phoneext`} type="number" index={index} placeholder="xxxx" component={InputBox} normalize={normalizeExtention}/>
            </div>
            <div className="col-md-4 ">
              { <span
              className="pull-right"
              title="Remove Phone"
              onClick={() => formProps.fields.remove(index)}
              style={{paddingRight:'4rem'}}
              >
              <img className="delete-icon-phones" src={deleteIcon} alt="deleteIcon" />
              </span> }
            </div>
          </div>
        );
        })
      }
      <div className="list-item row">
        <div className="col-md-12">
          <span className="center-block pull-right add-fieldArray-btn" onClick={() => formProps.fields.push({})}><img src={addIcon} alt="addIcon" /></span>
        </div>
      </div>
    </div>
  );
}

export default withLocalize(RenderPhoneNumberInputs);

