import { push } from "connected-react-router";
import React, { Component, Suspense } from "react";
import Modal from "react-bootstrap/Modal";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { dispatchOtherValue, optionsTableList } from "../../../Redux/actions/tempEdgeActions";
import addIcon from "../../../assets/static/actionIcons/add.png";
import commentIcon from "../../../assets/static/actionIcons/comment.png";
import deleteIcon from "../../../assets/static/actionIcons/delete.png";
import editIcon from "../../../assets/static/actionIcons/edit.png";
import viewIcon from "../../../assets/static/actionIcons/view.png";
import { GetObjectUtil } from "../../../utils/ReGexs";
import ActiveLanguageAddTranslation from "../ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import { IconWrap } from "../IconWrap/IconWrap";

class TBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      componentToRender: null,
    };
    const { activeLanguage, addTranslationForLanguage } = this.props;
    ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
  }

  componentDidUpdate(prevProps) {
    const { activeLanguage, push, addTranslationForLanguage } = this.props;
    const hasActiveLanguageChanged =
      prevProps.activeLanguage !== activeLanguage;
    if (hasActiveLanguageChanged) {
      push(`/employee/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
    }
  }

  setCellValue(row, { field, userPrivileges }) {
    const { columnsToMap } = this.props;
    let cellValue = GetObjectUtil(row, field);
    if (columnsToMap) {
      columnsToMap.forEach((column) => {
        if (column.columnName === field) {
          cellValue = this.mapIdToValue(column, row[field]);
        }
      });
    }
    if (userPrivileges && userPrivileges.length > 0) {
      cellValue = this.setPrivelegesIcons(userPrivileges, row);
    }
    return cellValue;
  }

  setPrivelegesIcons(privileges, row) {
    const { activeLanguage, translate, keyField, data,filterByFIndInitial } = this.props;
    return (
      <div className="actions-cell d-flex justify-content-around flex-nowrap">
        {privileges.map((privilege) => {
          const { privilegeId, modal, description, component } = privilege;
          const path = `${privilege.path}/${activeLanguage.code}`;
          switch (description) {
            case "CREATE":
              return (
                <IconWrap
                  key={privilegeId}
                  isModal={modal}
                  title={translate("com.tempedge.msg.label.create")}
                  className="order-1"
                  path={path}
                >
                  <img
                    className="actions-cell__icon"
                    src={addIcon}
                    alt="Add icon"
                  />
                </IconWrap>
              );

            case "UPDATE":
              return (
                <IconWrap
                  key={privilegeId}
                  isModal={modal}
                  onClick={() =>{
                    this.props.optionsTableList(row[keyField], keyField)
                    this.props.dispatchOtherValue("parameters",filterByFIndInitial)
                  }
                  }
                  title={translate("com.tempedge.msg.label.update")}
                  className="order-4"
                  path={path}
                >
                  <img
                    className="actions-cell__icon"
                    src={editIcon}
                    alt="Edit icon"
                  />
                </IconWrap>
              );

            case "DELETE":
              return (
                <IconWrap
                  key={privilegeId}
                  isModal={modal}
                  title={translate("com.tempedge.msg.label.delete")}
                  className="order-5"
                  onClick={() => {
                    this.handleShowModal(component);
                  }}
                >
                  <img
                    className="actions-cell__icon"
                    src={deleteIcon}
                    alt="Delete icon"
                  />
                </IconWrap>
              );

            case "VIEW":
              return (
                <IconWrap
                  key={privilegeId}
                  isModal={modal}
                  title={translate("com.tempedge.msg.label.view")}
                  onClick={() =>{
                    this.props.optionsTableList(row[keyField], keyField, data)
                    this.props.dispatchOtherValue("parameters",filterByFIndInitial)
                  }
                  }
                  className="order-2"
                  path={path}
                >
                  <img
                    className="actions-cell__icon"
                    src={viewIcon}
                    alt="View icon"
                  />
                </IconWrap>
              );

            case "COMMENTS":
              return (
                <IconWrap
                  key={privilegeId}
                  title={translate("com.tempedge.msg.label.comments")}
                  className="order-3"
                  onClick={() => {
                    this.props.optionsTableList(row[keyField], keyField);
                    this.handleShowModal(privilege.path, row);
                  }}
                  isModal={modal}
                >
                  <img
                    className="actions-cell__icon"
                    src={commentIcon}
                    alt="Comments icon"
                  />
                </IconWrap>
              );
            default:
              return null;
          }
        })}
      </div>
    );
  }

  handleCloseModal = (bol) => {
    this.setState({ showModal: bol });
  };

  handleShowModal = (component, row) => {
    let ComponentToRender = null;
    if (component) {
      switch (component) {
        case "Comments":
          ComponentToRender = React.lazy(() => import("../Comments/Comments"));
          break;
        default:
          ComponentToRender = null;
          break;
      }
      this.setState({
        showModal: true,
        componentToRender: !ComponentToRender ? null : (
          <ComponentToRender
            data={row}
            handleCloseModal={this.handleCloseModal}
          />
        ),
      });
    }
  };

  mapIdToValue({ dataToMap }, idToFind) {
    const { translate } = this.props;
    let value = null;
    const keys = Object.keys(dataToMap);
    keys.forEach((row) => {
      if (row === String(idToFind)) {
        value = translate(dataToMap[row]);
      }
    });
    return value;
  }

  render() {
    const {
      data,
      columns,
      selectRows,
      keyField,
      employeesInTimeSheet,
      employeesInCustomPayroll,
      employeesInAdjustment,
      isSelected,
    } = this.props;

    const { showModal, componentToRender } = this.state;
    return (
      <>
        <tbody>
          {data && data?.length > 0 ? (
            data?.map((row, index) => {
              const personId = row[keyField];
              const isInTimeSheet = employeesInTimeSheet.some(employee => employee.personId === personId);
              const isInCustomPayroll = employeesInCustomPayroll.some(employee => employee.personId === personId);
              const isInAdjustment = employeesInAdjustment.some(employee => employee.personId === personId);
              const hasEmployees = isInTimeSheet || isInCustomPayroll || isInAdjustment;

              let className = "";
              className = hasEmployees ? "table-active" : null;
              // Add the condition here to check isSelected
              if (isSelected && isSelected(row[keyField])) {
                className += " table-active";
              }
              return (
                <tr
                  key={index}
                  className={className}
                  style={
                    row["score"] !== undefined &&
                      row["score"] !== null &&
                      row["score"] < 3
                      ? { color: "red" }
                      : {}
                  }
                  data-id={row[keyField]}
                  onClick={(e) => selectRows(e, row[keyField])}
                >
                  {columns ? (
                    columns.map((col, index) => {
                      return (
                        <td
                          key={index}
                          className={
                            index === 0
                              ? "table-content "
                              : index === columns.length - 1
                                ? "table-content"
                                : "table-content " +
                                (col.hide !== "undefined" && col.hide != null
                                  ? " d-none d-" + col.hide + "-table-cell"
                                  : "")
                          }
                          style={
                            col.maxFieldSize > 0
                              ? { maxWidth: col.maxFieldSize }
                              : {}
                          }
                        >
                          {this.setCellValue(row, col)}
                        </td>
                      );
                    })
                  ) : (
                    <td colSpan="100%">
                      <Translate id="com.tempedge.msg.label.norecordsfound" />
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="100%">
                <Translate id="com.tempedge.msg.label.norecordsfound" />
              </td>
            </tr>
          )}
        </tbody>
        <Modal
          show={showModal}
          onHide={() => {
            this.handleCloseModal();
          }}
        >
          <Modal.Header closeButton />
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              {componentToRender}
            </Suspense>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({
  dailyListReducer: { employeesInTimeSheet = [] },
  customPayrollReducer: { employeesInCustomPayroll = [] },
  adjustmentReducer: { employeesInAdjustment = [] },
}) => ({
  employeesInTimeSheet,
  employeesInCustomPayroll,
  employeesInAdjustment
});

const mapDispatchToProps = {
  push,
  optionsTableList,
  dispatchOtherValue,
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(TBody)
);
