import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Translate, withLocalize } from "react-localize-redux";
import ActiveLanguageAddTranslation from "../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import { Row, Col } from "react-bootstrap";
import TempEdgeApi from "../../services/TempEdgeApi";
import CancelBtn from "../../components/common/Buttons/CancelBtn";
import BlueCommonBtn from "../../components/common/Buttons/BlueCommonBtn";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import { REACT_APP_URL_CANDIDATE_SAVE, REACT_APP_URL_OFFICE_FIND_ALL, REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_RECAPTCHA_V2_SITE_KEY } from "../../components/common/Routes/URLs";
import OutcomeBar from "../../components/common/OutcomeBar";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import { normalizePhone } from "../../utils/ReGexs";
import Loader from "../../components/common/Loader/Loader";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RecaptchaV2 from '../../components/common/Captcha/RecaptchaV2';
import useRecaptchaTimer from '../../Hooks/Recaptcha/useRecaptchaTimer';

const CreateCandidate = ({
  activeLanguage,
  history,
  addTranslationForLanguage,
}) => {
  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    officeId: "",
    phone: "",
  };
  
  const [message, setMessage] = useState("");
  const [prevActiveLanguage, setPrevActiveLanguage] = useState(activeLanguage);
  const agency = JSON.parse(localStorage.getItem("agency"));
  const [officeList, setOfficeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataNewCandidate, setDataNewCandidate] = useState(initialValues);
  const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);
  const [recaptchaV2Token, setRecaptchaV2Token] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha(); 

  useRecaptchaTimer(() => { 
    setShowRecaptchaV2(true);  
  });

  const handleRecaptchaVerify = (token) => {
    setRecaptchaV2Token(token);
    setRecaptchaError(false);
  };

  useEffect(() => {
    const hasActiveLanguageChanged = prevActiveLanguage !== activeLanguage;
    if (hasActiveLanguageChanged) {
      history.push(`/candidate/new/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
      setPrevActiveLanguage(activeLanguage);
    }
  }, [activeLanguage, history, addTranslationForLanguage, prevActiveLanguage]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("email not valid").required("required"),
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string(),
  });

  useEffect(() => {
    const fetchOfficeList = async () => {
      try {
        setLoading(true);
        const { data } = await TempEdgeApi.post({
          url: REACT_APP_URL_OFFICE_FIND_ALL,
          payload: {
            filterBy: {},
            parameterKey: "com.tempedge.msg.menu.officelist",
          },
        });

        const officeList = data?.result?.data?.content;
        setOfficeList(officeList);
      } catch (error) {
        setMessage(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOfficeList();
  }, []);

  const Btn = ({ handleSubmit }) => (
    <Row className="py-4 mx-auto">
      <Col md={6}>
        <CancelBtn />
      </Col>
      <Col md={6}>
        <BlueCommonBtn
          type="submit"
          tag={"com.tempedge.msg.label.save"}
          onClick={handleSubmit}
          disabled={showRecaptchaV2 && !recaptchaV2Token} 
        />
      </Col>
    </Row>
  );

  if (loading) return <Loader />;
  
  return (
    <Formik
      initialValues={dataNewCandidate}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        if (showRecaptchaV2 && !recaptchaV2Token) {
          setRecaptchaError(true);
          return;
        }

        const requests = {
          email: values.email.toUpperCase(),
          firstName: values.firstName.toUpperCase(),
          lastName: values.lastName.toUpperCase(),
          officeId: values.officeId,
          orgId: agency.organizationEntity.orgId,
          phone: values.phone,
          token: recaptchaV2Token || ''
        };

        try {
          setLoading(true);

          if (executeRecaptcha && !recaptchaV2Token) { 
            const recaptchaToken = await executeRecaptcha('createCandidate');
            requests.token = recaptchaToken;
          }

          const { data } = await TempEdgeApi.post({
            url: REACT_APP_URL_CANDIDATE_SAVE,
            payload: requests,
          });

          setMessage(data);
          if (data.status === 200) {
            setDataNewCandidate(initialValues)
          }else{
            setDataNewCandidate(requests);
          }
        } catch (error) {
          setMessage(error);
          setDataNewCandidate(requests);
        } finally {
          setLoading(false);
        }
      }}
    >
      {({ errors, touched, handleSubmit, setFieldValue, values }) => {
        const handleChangeValues = (e) => {
          const { name, value } = e.target;
          setFieldValue(name, value.toUpperCase());
        };

        return (
          <ContainerBlue
            title={"com.tempedge.msg.title.newcandidate"}
            width="70%"
            btns={<Btn handleSubmit={handleSubmit} />}
          >
            <div className=" mx-auto">
              {message && <OutcomeBar response={message} />}
              <Form className="panel-body">
                <Row className="form-group justify-content-md-start">                
                  <Col md={6} className="mt-4">
                    <label className="control-label">
                      <Translate id="com.tempedge.msg.label.firstname" />
                    </label>
                    <Field
                      id="firstName"
                      name="firstName"
                      type="text"
                      className={`form-control tempEdge-input-box uppercase`}
                      onChange={handleChangeValues}
                    />
                  </Col>
                  <Col md={6} className="mt-4">
                    <label className="control-label">
                      <Translate id="com.tempedge.msg.label.lastname" />
                    </label>
                    <Field
                      id="lastName"
                      name="lastName"
                      type="text"
                      className={`form-control tempEdge-input-box uppercase`}
                      onChange={handleChangeValues}
                    />
                  </Col>
                  <Col md={6} className="mt-4">
                    <p className="text-left control-label">
                      <Translate id="com.tempedge.msg.label.email" />
                    </p>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className={`form-control tempEdge-input-box uppercase ${errors.email && touched.email && "has-error"}`}
                      onChange={handleChangeValues}
                    />
                  </Col>
                  <Col md={6} className="mt-4">
                    <label className="control-label">
                      <Translate id="com.tempedge.msg.label.phone" />
                    </label>
                    <Field
                      id="phone"
                      name="phone"
                      type="text"
                      className={`form-control tempEdge-input-box`}
                      onChange={(e) => {
                        setFieldValue("phone", normalizePhone(e.target.value));
                      }}
                    />
                  </Col>
                  {officeList?.length > 0 && (
                    <Col md={6} className="form-group mt-4">
                      <label className="control-label">
                        <Translate id="com.tempedge.msg.label.office" />
                      </label>
                      <Field
                        name="officeId"
                        type="text"
                        data={officeList}
                        value={{ officeId: values.officeId }}
                        textField="name"
                        component={DropdownList}
                        onChange={(value) =>
                          setFieldValue("officeId", value.officeId)
                        }
                        valueField="officeId"
                        isInvalid={errors.officeId && touched.officeId}
                      />
                    </Col>
                  )}
                </Row>
                {showRecaptchaV2 && (
  <div className="form-group mt-3 d-flex justify-content-center">
    <div 
      id="recaptcha-v2-container" 
      style={{
        border: recaptchaV2Token === '' ? '2px solid red' : 'none',
        borderRadius: '4px'
      }}
    >
      <RecaptchaV2 
        sitekey={REACT_APP_RECAPTCHA_V2_SITE_KEY} 
        onVerify={handleRecaptchaVerify} 
      />
    </div>
  </div>
)}

              </Form>
            </div>
          </ContainerBlue>
        );
      }}
    </Formik>
  );
};

export default withLocalize((props) => (
  <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
    <CreateCandidate {...props} />
  </GoogleReCaptchaProvider>
));
