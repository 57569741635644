import React from 'react';
import BackgroundSlider from 'react-background-slider';

export const HeaderSectionBackground = ({images}) => {
    return (
        <BackgroundSlider
            images={images}
            duration={0.2}
            transition={10}
        />
    )
}

export default HeaderSectionBackground;
