import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useIdleTimer } from "react-idle-timer";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { doLogout } from "../../../Redux/actions/tempEdgeActions";
import {
    getSecondsRemaining,
    getTimeElapsedSinceTheLastRefresh,
} from "../../../utils/helpers/storage";
import HttpService from "../../../utils/services/httpService/httpService";

import { DefaultButton, PrimaryButton } from "../Buttons/Button";
import {
    REFRESH_TOKEN_INTERVAL_IN_SECONDS,
    TIME_OUT_TO_NOTIFY_IN_MILISECONDS,
    TIME_TO_SHOW_THE_TIMER_IN_SECONDS,
} from "../Constants/Constants";
import ContainerBlue from "../Container/ContainerBlue";
import { useCallback } from "react";

const TimeOutModal = ({ doLogout }) => {
    const [secondsRemaining, setSecondsRemaining] = useState(
        getSecondsRemaining()
    );
    const [showModal, setShowModal] = useState(false);

    const refreshAuthToken = useCallback(async () => {
        try {
            const response = await HttpService.refreshAuthToken(
                localStorage.getItem("refresh_token")
            );
            if (response.status === 200) {
                localStorage.setItem("access_token", response.data.access_token);
                localStorage.setItem("refresh_token", response.data.refresh_token);
                localStorage.setItem(
                    "lastRefresh",
                    Math.floor(new Date().getTime() / 1000)
                );
            } else {
                doLogout("en");
            }
        } catch (error) {
            doLogout("en");
        }
    }, [doLogout]);

    useEffect(() => {
        let allSecondsRemaining = setInterval(() => {
            setSecondsRemaining((prevSeconds) => {
                const updatedSeconds = getSecondsRemaining();
                if (updatedSeconds > TIME_TO_SHOW_THE_TIMER_IN_SECONDS && showModal) {
                    setShowModal(false);
                }
                if (updatedSeconds <= TIME_TO_SHOW_THE_TIMER_IN_SECONDS && !showModal) {
                    setShowModal(true);
                }
                if (updatedSeconds <= 0) {
                    if (!showModal) setShowModal(true);
                    clearInterval(allSecondsRemaining);
                    doLogout("en");
                }
                if (
                    getTimeElapsedSinceTheLastRefresh() >=REFRESH_TOKEN_INTERVAL_IN_SECONDS
                ) {
                    refreshAuthToken();
                }
                return updatedSeconds;
            });

        }, 1000);

        return () => {
            clearInterval(allSecondsRemaining);
        };
    }, [doLogout, refreshAuthToken, showModal, secondsRemaining]);

    const handleOnAction = (e) => {
        if (!showModal)
            localStorage.setItem(
                "lastActiveTime",
                Math.floor(new Date().getTime() / 1000)
            );
    };

    useIdleTimer({
        timeout: TIME_OUT_TO_NOTIFY_IN_MILISECONDS,
        onAction: handleOnAction,
        debounce: 500,
    });

    const handleLogout = () => {
        doLogout("en");
    };

    const extendSession = () => {
        localStorage.setItem(
            "lastActiveTime",
            Math.floor(new Date().getTime() / 1000)
        );
        setSecondsRemaining(getSecondsRemaining());
        setShowModal(false);
        refreshAuthToken();
    };

    const ContainerButtons = () => (
        <Row>
            <Col xs={6} md={{ span: 4, offset: 4 }}>
                <DefaultButton onClick={handleLogout} rightSquareSide>
                    <Translate id="com.tempedge.msg.label.logout" />
                </DefaultButton>
            </Col>
            <Col xs={6} md={4}>
                <PrimaryButton onClick={extendSession} leftSquareSide>
                    <Translate id="com.tempedge.msg.label.stay" />
                </PrimaryButton>
            </Col>
        </Row>
    );

    return (
        <Modal
            show={showModal}
            onHide={extendSession}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                {secondsRemaining > 0 && (
                    <>
                        <ContainerBlue
                            title="com.tempedge.msg.label.idleuser"
                            btns={ContainerButtons()}
                        >
                            <div>
                                <Translate id="com.tempedge.msg.label.idletimeout" />
                                {secondsRemaining}
                            </div>
                        </ContainerBlue>
                    </>
                )}

                {secondsRemaining < 1 && (
                    <ContainerBlue title="com.tempedge.msg.label.idleuser">
                        <div>
                            <Translate id="com.tempedge.msg.label.signingout" />
                        </div>
                    </ContainerBlue>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default connect(null, { doLogout })(TimeOutModal);
