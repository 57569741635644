import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { push } from 'connected-react-router';
import ActiveLanguageAddTranslation from '../ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';

class DepartmentTables extends React.Component {
  componentDidUpdate(prevProps) {
    const { activeLanguage, push, addTranslationForLanguage } = this.props;
    const hasActiveLanguageChanged = prevProps.activeLanguage !== activeLanguage;

    if (hasActiveLanguageChanged) {
      push(`/payroll/dailylist/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
    }
  }

  render() {

    const { departments, clientName } = this.props.clientSelected ? this.props.clientSelected : {};

    const { translate } = this.props;
    return (
      <>
        <div className="text-center mb-4">
          <h3>{clientName}</h3>
        </div>
        {departments && departments.map((department) => (
          <div key={department.departmentId} className="row">
            <div className="col-12 col-lg-12">
              <p>{department.name}</p>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="table-header-left text-center w-40">{translate('com.tempedge.msg.label.position')}</th>
                    <th className="table-header-mid text-center w-40">{translate('com.tempedge.msg.label.description')}</th>
                    <th className="table-header-right text-center w-20">Pay</th>
                  </tr>
                </thead>
                <tbody>
                  {department?.positions?.map((position) => (
                    <tr key={position.positionId}>
                      <td>{position.name}</td>
                      <td>{position.description}</td>
                      <td className="text-center">{position.pay}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </>
    );
  }
}

const mapDispatchToProps = {
  push
};

export default withLocalize(connect(null, mapDispatchToProps)(DepartmentTables));
