import React,{useCallback, useEffect, useState} from "react";
import { Translate } from "react-localize-redux";
import {moveEmployeeToAbsentTable} from '../../../Redux/actions/timeEntryActions';
import { useDispatch } from "react-redux";
import { formatUsNumber } from "../../../utils/ReGexs";


const PayrrollDayResult = ({ employeesTimeEntryDay}) => {
  const [editedValues, setEditedValues] = useState({});
  const dispatch = useDispatch();
  
  const ResultData = useCallback(() => {
    employeesTimeEntryDay.forEach((employee) => {
      if (employee.totalRegHour === null && employee.totalOtHour === null) {
        dispatch(moveEmployeeToAbsentTable(employee));
      }
    });
  }, [dispatch, employeesTimeEntryDay]);
     

  useEffect(() => {
    ResultData();
  }, [ResultData]);
  
  useEffect(() => {
    const updatedValues = {};
    employeesTimeEntryDay.forEach((employee) => {
      if (employee.totalRegHour !== undefined) {
        updatedValues[employee.payrollId] = { totalRegHour: employee.totalRegHour };
      }
    });
    setEditedValues(updatedValues);
  }, [employeesTimeEntryDay]);

  let sortedListEmployee = [...employeesTimeEntryDay];
  sortedListEmployee.sort((a, b) => {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  });

  let total = 0;
  let sumTotal = 0;
  return (
    <>
      <div className="form-group col-12 col-lg-3">
        <p>
          <Translate id={"com.tempedge.msg.label.listsize"} />:
          <span className="badge">{employeesTimeEntryDay.length}</span>
        </p>
      </div>
      <div className="row">
        <div className="form-check col-12 col-lg-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="table-header-left text-center w-20">
                  <Translate id={"com.tempedge.msg.label.lastname"} />
                </th>
                <th className="table-header-mid text-center w-20">
                  <Translate id={"com.tempedge.msg.label.firstname"} />
                </th>
                <th className="table-header-mid text-center w-20">
                  <Translate id={"com.tempedge.msg.label.deptpos"} />
                </th>
                <th className="table-header-mid text-center w-10">
                  <Translate id={"com.tempedge.msg.label.timein"} />
                </th>
                <th className="table-header-mid text-center w-5">
                <Translate id={"com.tempedge.msg.label.lunch"}/>
                </th>
                <th className="table-header-mid text-center w-10">
                  <Translate id={"com.tempedge.msg.label.timeout"} />
                </th>
                <th className="table-header-mid text-center w-5">Total Reg</th>
                
              </tr>
            </thead>
            <tbody>
              {sortedListEmployee.map((employee,index) => {
                if(employee.totalRegHour){
                  total += parseFloat(employee.totalRegHour);
                  if(isNaN(total)){sumTotal=0}
                  sumTotal = total
                }
                
                return (
                  <tr key={index+1}>
                    <td className="text-center">{employee.lastName}</td>
                    <td className="text-center">{employee.firstName}</td>
                     <td className="text-center">
                     {employee.position.departmentName && employee.position.positionName ? 
                     `${employee.position.departmentName}-${employee.position.positionName}`:
                     employee.position.name}
                     </td>
                    <td className="text-center">{employee.timeIn}</td>
                    <td className="text-center">{employee.lunch}</td>
                    <td className="text-center">{employee.timeOut}</td>
                    <td className="text-center">{editedValues[employee.payrollId]?.totalRegHour || employee.totalRegHour}</td>
                  </tr>
                );
              })}
              <tr className="tableRow">
                <td
                  colSpan="6"
                  className="table-content text-uppercase text-right"
                >
                  <Translate id={"com.tempedge.msg.label.total"} />
                </td>
                <td className="table-content text-center">{formatUsNumber(sumTotal)}</td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PayrrollDayResult;
