import React from 'react';
import { Link } from 'react-router-dom';

export const IconWrap = (props) => {
  const { title, className, path, isModal, children, onClick } = props;
  return (
    isModal
      ? (
        <button
          type="button"
          title={title}
          className={className}
          onClick={onClick}
        >
          {children}
        </button>
      )
      : (
        <button type="button" onClick={onClick}>
          <Link
            title={title}
            className={className}
            to={path}
          >
            {children}
          </Link>
        </button>
      )
  );
};