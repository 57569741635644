import moment from "moment";
import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import Modal from "../../Modals/Modal/Modal";
import {
  resetEntireStateAdjustment
} from "../../Redux/actions/adjustmentActions";
import { resetEntireStateCustom } from "../../Redux/actions/customPayrollActions";
import {
  addEmployee,
  changeEmployeeValue,
  clearProp,
  loadValuesToEmployeeTimeSheet,
  onChangeMailTo,
  resetEntireState,
} from "../../Redux/actions/dailyListActions";
import {
  clearTempedgeStoreProp,
  modalVisibility,
  tempedgeAPI,
} from "../../Redux/actions/tempEdgeActions";
import types from "../../Redux/actions/types";
import { dailyListTypes } from "../../Redux/types/dailyListTypes";
import { timeEntryTypes } from "../../Redux/types/timeEntryTypes";
import ActiveLanguageAddTranslation from "../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import CalculateBillRates from "../../components/common/CalculateBillRates/CalculateBillRates";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import Datepicker from "../../components/common/Datepicker/Datepicker";
import DeparmentTables from "../../components/common/DepartmentTables/DepartmentTables";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import Loader from "../../components/common/Loader/Loader";
import InfoModal from "../../components/common/Modal/InfoModal";
import OutcomeBar from "../../components/common/OutcomeBar";
import {
  REACT_APP_URL_CLIENT_LIST_ALL,
  REACT_APP_URL_OFFICE_FIND_ALL,
  REACT_APP_URL_PAYROLL_DELETE_DAY,
  REACT_APP_URL_PAYROLL_DELETE_EMPLOYEE,
  REACT_APP_URL_PAYROLL_FIND_BY_DAY,
  REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
  REACT_APP_URL_PAYROLL_SAVE_LIST,
} from "../../components/common/Routes/URLs";
import EmployeeList from "../Employee/EmployeeList/EmployeeList";
import DateModal from "./DateModal";
import TimeSheet from "./TimeSheet";
import { generateUniqueId } from "../../utils/PayrollUtils";

const dailyListOptionMenu = "com.tempedge.msg.menu.dailylist";
const dailyListPageUrl = "/payroll/dailylist/";

class DailyList extends Component {
  static mapDepartmentAndPositionList(departments) {
    if (!departments) return [];
    const positionAndDepartmentList = [];
    departments?.forEach((department) => {
      department.positions.forEach((position) => {
        positionAndDepartmentList.push({
          positionName: `${department.name} - ${position.name}`,
          positionId: position.positionId,
          pay: position.pay,
          departmentId: department.departmentId,
        });
      });
    });
    return positionAndDepartmentList;
  }

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      deptButtonDisabled: true,
      officeSelected: null,
      shiftSelected: null,
      dateInputWillBeRendered: null,
      isFindListChecked: null,
      downloadFile: false,
      findListDate: null,
      officeSelectedIsInvalid: false,
      clientSelectedIsInvalid: false,
      shiftSelectedIsInvalid: false,
      dateInputWillBeRenderedIsInvalid: false,
      oneCompany: false,
      loading: false,
      tempEmployeesInTimeSheet: [],
      findDatelistResponse: null,
    };
    this.positionList = [];
    this.clientSelected = {};
    this.clientListInState = [];
    this.dailyListForm = React.createRef();
    // preserve the initial state in a new object
    this.baseState = this.state;
    this.dateModal = null;
  }

  componentDidMount() {
    const { resetEntireStateCustom,resetEntireStateAdjustment,clearTempedgeStoreProp } = this.props
    resetEntireStateAdjustment()
    resetEntireStateCustom()
    clearTempedgeStoreProp("paginatorList");
    this._isMounted = true;
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const {
      clientListInProps,
      activeLanguage,
      history,
      addTranslationForLanguage,
    } = this.props;
    const hasActiveLanguageChanged =
      prevProps.activeLanguage !== activeLanguage;
    if (this.clientListInState.length === 0 && clientListInProps.length > 0) {
      this.clientListInState = clientListInProps;
      if (this.clientListInState.length === 1) {
        this.onChangeClientList(this.clientListInState[0]);
      }
    }
    if (hasActiveLanguageChanged) {
      history.push(`${dailyListPageUrl}${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
    }
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    const { orgId } = this.state;

    try {
      if (this._isMounted) {
        await this.props.tempedgeAPI(
          REACT_APP_URL_OFFICE_FIND_ALL,
          { orgId, filterBy: {} },
          types.GET_OFFICE_LIST
        );

        await this.props.tempedgeAPI(
          REACT_APP_URL_CLIENT_LIST_ALL,
          { orgId, filterBy: {}, parameterKey: "com.tempedge.msg.menu.clientlist" },
          types.TEMPEDGE_LIST
        );

        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      }
    } catch (error) {
      this.setState({ message: error })
    }
  }

  onChangeClientList(clientSelected) {
    const { loadValuesToEmployeeTimeSheet, onChangeMailTo } = this.props;
    const { departments } = clientSelected;
    loadValuesToEmployeeTimeSheet({
      attendance: null,
      pay: "",
      position: "",
    });

    if (departments) {
      const positionAndDepartmentList =
        this.constructor.mapDepartmentAndPositionList(departments);
      this.onChangeDropdownList(clientSelected, "clientSelected");
      this.setState({
        deptButtonDisabled: false,
        positionList: positionAndDepartmentList,
      });
      onChangeMailTo(clientSelected.email);
    }
  }

  onChangeDropdownList(optionSelected, key) {
    this.setState({
      [key]: optionSelected,
    });
    this.setState({ resultBar:null, dateInputWillBeRenderedIsInvalid:false});
    this.setFieldAsInvalid([{ key, value: optionSelected }]);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  onError(res) {
    if (typeof res === "string") {
      this.setState({ resultBar: <OutcomeBar response={res} /> });
    } else {
      this.setState({ resultBar: <OutcomeBar response={res} /> });
    }
    this.props.modalVisibility(false);
    window.scrollTo(0, 0);
  }

  deleteDay = async (date) => {
    const { orgId, clientSelected, shiftSelected, officeSelected } = this.state;
    const { modalVisibility } = this.props;
    let request = {};
    try {
      if (!clientSelected || !officeSelected || !shiftSelected || !date) {
        this.setFieldAsInvalid([
          { key: "clientSelected", value: clientSelected },
          { key: "officeSelected", value: officeSelected },
          { key: "shiftSelected", value: shiftSelected },
        ]);
        throw new Error("com.tempedge.error.fieldsrequired");
      }
      request = {
        orgId,
        dateList: date,
        clientId: clientSelected.clientId,
        officeId: officeSelected.officeId,
        shift: shiftSelected.shiftId,
      };
    } catch (error) {
      this.onError(error);
      return;
    }
    this.setState({
      loading: true,
    });
    await this.props.tempedgeAPI(
      REACT_APP_URL_PAYROLL_DELETE_DAY,
      request,
      dailyListTypes.DELETE_DAY
    );
    this.showDeleteDayResponse();

    modalVisibility(false);
  };

  findListByDay = async () => {
    const {
      orgId,
      clientSelected,
      shiftSelected,
      officeSelected,
      findListDate,
    } = this.state;
    let request = {};

    if (findListDate) {
      try {
        if (!clientSelected || !officeSelected || !shiftSelected) {
          this.setFieldAsInvalid([
            { key: "clientSelected", value: clientSelected },
            { key: "officeSelected", value: officeSelected },
            { key: "shiftSelected", value: shiftSelected },
          ]);
          throw new Error("com.tempedge.error.fieldsrequired");
        }
        request = {
          orgId,
          dateList: moment(findListDate).format("YYYY-MM-DD"),
          clientId: String(clientSelected.clientId),
          officeId: String(officeSelected?.officeId || ""),
          shift: String(shiftSelected?.shiftId || ""),
          parameterKey: dailyListOptionMenu,
        };
      } catch (error) {
        this.onError(error);
        return;
      }
      this.setState({
        loading: true,
      });
      await this.props.tempedgeAPI(
        REACT_APP_URL_PAYROLL_FIND_BY_DAY,
        request,
        dailyListTypes.FIND_LIST_BY_DAY
      );
      this.showFindListResponse();
    } else {
      if (!clientSelected || !officeSelected || !shiftSelected || !findListDate) {
        this.setFieldAsInvalid([
          { key: "clientSelected", value: clientSelected },
          { key: "officeSelected", value: officeSelected },
          { key: "shiftSelected", value: shiftSelected },
          { key: "dateInputWillBeRendered", value: findListDate},
        ]);
        this.onError(new Error("com.tempedge.error.fieldsrequired"));
        return
      }
    }
  };

  resetEntireForm = (cleanResultBar) => {
    const { resetEntireState } = this.props;
    this.dailyListForm?.current?.reset();
    this.setState({ ...this.baseState, clientSelected: null });
    resetEntireState();
    if (cleanResultBar)
      this.setState({ resultBar: null, clientSelected: null });
    this.props.modalVisibility(false);
    clearProp("findListResponse");
    clearProp("employeesInTimeSheet");
    clearProp("deleteDayResponse");
  };

  saveDailyList = async (date) => {
    const {
      orgId,
      clientSelected,
      shiftSelected,
      officeSelected = "",
      downloadFile,
      isFindListChecked,
      findDatelistResponse
    } = this.state;
    const {
      employeesInTimeSheet,
      tempedgeAPI,
      removedEmployeeList,
      mailTo,
      mailCc,
      sendEmail,
    } = this.props;

    let employeeListToMap = employeesInTimeSheet;
    let request = {};
    let payrollEntityList = [];
    if (
      isFindListChecked &&
      removedEmployeeList &&
      removedEmployeeList.length > 0
    ) {
      employeeListToMap = removedEmployeeList;
    }

    try {
      if (!clientSelected || !officeSelected || !shiftSelected) {
        this.setFieldAsInvalid([
          { key: "clientSelected", value: clientSelected },
          { key: "officeSelected", value: officeSelected },
          { key: "shiftSelected", value: shiftSelected },
        ]);
        throw new Error("com.tempedge.error.fieldsrequired");
      }
      payrollEntityList = this.mapTimeSheetToRequestObject(
        employeeListToMap,
        date
        );

      this.setState({tempEmployeesInTimeSheet:employeesInTimeSheet})
      if(findDatelistResponse && moment(findDatelistResponse).format("MM-DD-YYYY") !== moment(date).format("MM-DD-YYYY")){
        payrollEntityList.forEach((item) => {
          item.payrollId=''
          return item
        })
      }
      
      request = {
        orgId,
        dateList: date,
        clientId: clientSelected.clientId,
        officeId: officeSelected.officeId,
        shift: shiftSelected.shiftId,
        downloadFile,
        sendEmail,
        parameterKey: "com.tempedge.msg.menu.dailylist",
        mailTo: sendEmail ? mailTo : "",
        mailCc: sendEmail ? mailCc : "",
        payrollEntityList,
      };
    } catch (error) {
      this.onError(error);
      return;
    }
    if (
      isFindListChecked &&
      removedEmployeeList &&
      removedEmployeeList.length > 0
    ) {
      this.setState({
        loading: true,
      });
      if(findDatelistResponse && moment(findDatelistResponse).format("MM-DD-YYYY") === moment(date).format("MM-DD-YYYY")){
      await tempedgeAPI(
        REACT_APP_URL_PAYROLL_DELETE_EMPLOYEE,
        request,
        dailyListTypes.DAILY_LIST_SAVING
      );
      this.showDeleteFromPayrollResponse(this.saveDailyList, date);
      }
    } else {
      let requestWeekActive = {
        orgId: orgId,
        clientId: clientSelected.clientId,
        dateList: date,
      };
      const weekActive = await tempedgeAPI(
        REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
        requestWeekActive,
        timeEntryTypes.FIND_WEEK_ACTIVE
      );
      if (weekActive.data?.result?.active) {
        this.setState({
          loading: true,
        });
        if (removedEmployeeList && removedEmployeeList.length > 0) {
          const payrolls = [];
          removedEmployeeList.forEach((item) => {
            if (item.position) {
              payrolls.push({                
                payrollId: item.payrollId,
              });
            }
          });
          if(moment(findDatelistResponse).format("MM-DD-YYYY") === moment(date).format("MM-DD-YYYY")){
          await tempedgeAPI(
            REACT_APP_URL_PAYROLL_DELETE_EMPLOYEE,
            { ...request, payrollEntityList: payrolls },
            dailyListTypes.DAILY_LIST_SAVING
          );}
        }
        
        await tempedgeAPI(
          REACT_APP_URL_PAYROLL_SAVE_LIST,
          request,
          dailyListTypes.DAILY_LIST_SAVING
        );
        this.showSavingResponse();
      } else {
        this.setState({
          resultBar: (
            <OutcomeBar
              response={new Error("com.tempedge.error.weeknotactive")}
            />
          ),
        });
        window.scrollTo(0, 100);
        this.setState({
          loading: false,
        });
      }
    }
  };

  /**
   * Set fields as invalid highlighting them with red color
   * @param {Array} fields Array of fields to highlight as invalid
   */
  setFieldAsInvalid(fields) {
    fields.forEach((field) => {
      if (!field.value) {
        this.setState({
          [`${field.key}IsInvalid`]: true,
        });
      } else {
        this.setState({
          [`${field.key}IsInvalid`]: false,
        });
      }
    });
  }

  showDateModal = (isSaveDailyList) => {
    const handleOnSubmit = isSaveDailyList
      ? this.saveDailyList
      : this.deleteDay;
    this.props.modalVisibility(true);
    this.dateModal = <DateModal showModal onClick={handleOnSubmit} />;
  };

  mapTimeSheetToRequestObject(employeesInTimeSheet, date) {
    const { clientSelected } = this.state;
    let isMissingFields = false;
    const requestObject = [];
  
    employeesInTimeSheet.forEach((employee) => {
      if (!employee.position || !employee.position || !employee.position.positionId) {
        this.props.changeEmployeeValue({
          id: employee.id,
          key: "isPositionMissing",
          value: true,
        });
        isMissingFields = true; // Marcamos isMissingFields como true si falta department o position
      }
      if (
        employee.position &&
        employee.pay !== null &&
        employee.pay !== undefined &&
        employee.pay !== ""
      ) {
        requestObject.push({
          id: employee.id,
          dateList: date,
          person: {
            personId: employee.personId,
          },
          department: {
            departmentId: employee.position.departmentId,
          },
          position: {
            positionId: employee.position.positionId,
          },
          payRate: employee.pay,
          billRate: CalculateBillRates(employee.pay, clientSelected.commonMarkup),
          otBillRate: CalculateBillRates(employee.pay, clientSelected.commonOtMarkup, true),
          attendance: employee.attendance,
          payrollId : employee.payrollId
        });
      } else {
        isMissingFields = true;
        if (
          employee.pay === null ||
          employee.pay === undefined ||
          employee.pay === ""
        ) {
          this.props.changeEmployeeValue({
            id: employee.id,
            key: "isPayMissing",
            value: true,
          });
        }
      }
    });
  
    if (isMissingFields) {
      throw new Error("com.tempedge.error.fieldsrequired");
    }
    return requestObject;
  }
  
  

  addEmployeesToTimesheet(employees = [], textColor) {
    const { addEmployee } = this.props;
  
    employees.forEach((employee, index) => {
      const formattedEmployee = {
        id: employee.person?.personId + generateUniqueId() || employee.id  || '',
        personId: employee.person?.personId || employee.personId || '',
        employeeId: employee.person?.employeeId || employee.employeeId || '',
        lastName: employee.person?.lastName || employee.lastName || '',
        firstName: employee.person?.firstName || employee.firstName || '',
        position: {
          positionName: employee.position?.name || employee.position?.positionName || '',
          positionId: employee.position?.positionId || '',
          pay: employee.position?.pay || '',
          departmentId: employee.department?.departmentId || employee.position?.departmentId || '',
        },
        pay: employee.payRate || employee.pay || '',
        attendance: employee.attendance,
        textColor,
        payrollId:employee.payrollId || ''
      };
  
      addEmployee(formattedEmployee);
    });
  }


  downloadExcel(base64, dateList) {
    const base64URL = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
    const link = document.createElement("a");
    link.setAttribute("href", base64URL);
    link.setAttribute(
      "download",
      `${this.state.clientSelected?.clientName}_${moment(dateList).format(
        "MM-DD-YYYY"
      )}.xlsx`
    );
    link.click();
  }

  showFindListResponse() {
    const { findListResponse } = this.props;
   
    if (findListResponse && findListResponse.data) {
      this.setState({
        loading: false,
      });
      if (findListResponse.data.code === "TE00") {
        if (findListResponse.data.result.length > 0) {
          this.setState({ resultBar: null,findDatelistResponse :findListResponse.data.result[0].dateList });
          this.addEmployeesToTimesheet(findListResponse.data.result);
        } else {
          this.onError(new Error("com.tempedge.msg.label.norecordsfound"));
        }
      } else {
        this.onError(findListResponse.data);
        this.setState({
          loading: false,
        });
      }
    } else {
      this.onError(findListResponse.data);
      this.setState({
        loading: false,
      });
    }
  }

  showSavingResponse() {
    const { saveDailyListResponse } = this.props;
    if (saveDailyListResponse && saveDailyListResponse.data) {
      const { code, result } = saveDailyListResponse.data;
      if (code === "TE00" || code === "TE-E08") {
        if (result.report) {
          this.downloadExcel(result.report, result.dateList);
        }
        this.setState({
          resultBar: <OutcomeBar response={saveDailyListResponse.data} />,
        });
        this.resetEntireForm();
        this.setState({ loading: false });
      } else {
        this.onError(saveDailyListResponse.data);
        this.setState({
          loading: false,
        });
        this.addEmployeesToTimesheet(this.state.tempEmployeesInTimeSheet)
      }
    } else if (saveDailyListResponse.status === 401) {
      this.onError(saveDailyListResponse.data);
      this.addEmployeesToTimesheet(this.state.tempEmployeesInTimeSheet)
      this.setState({ loading: false });
    } else {
      this.onError(saveDailyListResponse.data);
      this.setState({ loading: false });
      this.addEmployeesToTimesheet(this.state.tempEmployeesInTimeSheet)
    }
  }

  showDeleteDayResponse() {
    const { deleteDayResponse } = this.props;

    if (deleteDayResponse && deleteDayResponse.data) {
      const { code } = deleteDayResponse.data;
      if (code === "TE00") {
        this.setState({
          loading: false,
        });
        this.setState({
          resultBar: <OutcomeBar response={deleteDayResponse.data} />,
        });
        this.resetEntireForm();
        this.setState({
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        this.onError(deleteDayResponse.data);
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
      this.onError(deleteDayResponse.data);
      this.setState({
        loading: false,
      });
    }
  }

  async showDeleteFromPayrollResponse(saveDailyList, date) {
    const { saveDailyListResponse, clearProp } = this.props;
    if (saveDailyListResponse && saveDailyListResponse.data) {
      const { code, result } = saveDailyListResponse.data;
      if (code === "TE00") {
        await clearProp("removedEmployeeList");
        saveDailyList(date);
        this.setState({
          resultBar: <OutcomeBar response={saveDailyListResponse.data} />,
        });
        this.setState({
          loading: false,
        });
      } else if (code === "TEY01") {
        clearProp("employeesInTimeSheet");
        this.addEmployeesToTimesheet(result, "text-danger"); // Add employees that cannot be removed
        this.onError(saveDailyListResponse.data);
        this.setState({
          loading: false,
        });
      } else {
        this.onError(saveDailyListResponse.data);
        this.setState({
          loading: false,
        });
      }
    } else {
      this.onError(saveDailyListResponse.data);
      this.setState({
        loading: false,
      });
    }
  }

  toggleDeparmentListModal() {
    const departmentTables = (
      <DeparmentTables clientSelected={this.state.clientSelected} />
    );
    this.toggleModal(departmentTables, "modal-sm");
  }

  toggleDeparmentListModalOneCompany(data) {
    const departmentTables = <DeparmentTables clientSelected={data} />;
    this.toggleModal(departmentTables, "modal-sm");
  }

  toggleDeparmentListClientModal() {
    const departmentTables = (
      <DeparmentTables clientSelected={this.clientListInState[0]} />
    );
    this.toggleModal(departmentTables, "modal-sm");
  }

  // Set Modal visible or not
  toggleModal(content, modalSize, buttons) {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
      content,
      modalSize,
      buttons: buttons || null,
    });
  }

  toggleEmployessListModal() {
    this.toggleModal(
      <EmployeeList multipleRows onClickRows={() => this.toggleModal()} />
    );
  }

  renderContainerButtons() {
    const { translate } = this.props;
    return (
      <>
        <div className="row">
          <div className="form-group col-12 col-lg-3">
            <p className="green-text font-weight-bold text-uppercase">
              {translate("com.tempedge.msg.label.findoldlist")}
            </p>
          </div>
        </div>
        {this.renderDateOptionList()}
      </>
    );
  }

  onChangeDataYesterday(data) {
    this.setState({ findListDate: moment().subtract(1, "day").toDate() });
  }

  renderDateOptionList() {
    const { translate } = this.props;
    const dropdownlistData = [
      { value: 0, label: translate("com.tempedge.msg.label.yesterday") },
      { value: 1, label: translate("com.tempedge.msg.label.custom") },
    ];

    return (
      <div className="row align-items-end">
        <div className="form-group col-12 col-lg-3">
          <label className="text-left label-p">
            {translate("com.tempedge.msg.label.selectanoption")}
          </label>
          <DropdownList
            className="form-control"
            data={dropdownlistData}
            value={this.state.dateInputWillBeRendered}
            valueField="value"
            textField="label"
            onChange={(optionSelected) =>
              optionSelected.value === 1
                ? this.onChangeDropdownList(
                  optionSelected,
                  "dateInputWillBeRendered"
                )
                : this.onChangeDropdownList(
                  optionSelected,
                  "dateInputWillBeRendered"
                ) + this.onChangeDataYesterday(optionSelected)
            }
            isInvalid={this.state.dateInputWillBeRenderedIsInvalid}
          />
        </div>
        {this.renderDateInput()}
        <div className="form-group col-12 col-lg-3 ">
          <button
            type="button"
            onClick={this.findListByDay}
            className="btn btn-green w-100"
          >
            {translate("com.tempedge.msg.label.find")}
          </button>
        </div>
      </div>
    );
  }

  renderDateInput() {
    const { dateInputWillBeRendered, findListDate } = this.state;
    const { translate } = this.props;
    if (dateInputWillBeRendered && dateInputWillBeRendered.value === 1) {
      return (
        <div className="form-group col-12 col-lg-3">
          <label className="text-left label-p">
            {translate("com.tempedge.msg.label.date")}
          </label>
          <Datepicker
            onChange={(date) =>
              moment(date).isValid() && this.setState({ findListDate: date })
            }
            selectedValue={findListDate}
            maxDate={moment(this.state.date).add(2, "week").toDate()}
            customClass="form-control tempEdge-input-box"
          />
        </div>
      );
    }

    return null;
  }

  renderModal() {
    const { showModal, content, modalSize, buttons } = this.state;
    return (
      <Modal
        content={content}
        open={showModal}
        onClose={() => this.toggleModal()}
        modalSize={modalSize}
        buttons={buttons}
      />
    );
  }

  getCompany() {
    let companyField;

    if (this.clientListInState?.length === 1) {
      companyField = (
        <InfoModal
          value={this.clientListInState[0]?.clientName}
          isModal={true}
          onClick={() => {
            this.toggleDeparmentListModalOneCompany(this.clientListInState[0]);
          }}
        />
      );
    } else {
      companyField = (
        <>
          <div className="has-error">
            <div className="input-group">
              <DropdownList
                className="form-control"
                value={this.state.clientSelected}
                data={this.clientListInState}
                valueField="clientId"
                textField="clientName"
                selectGroup
                defaultValue={null}
                onChange={(clientSelected) =>{
                  this.onChangeClientList(clientSelected)
                  if(!this.state?.clientSelectedIsInvalid)this.setState({resultBar:null})
                }}
                isInvalid={this.state?.clientSelectedIsInvalid}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  onClick={() => this.toggleDeparmentListModal()}
                  title={
                    <Translate id="com.tempedge.msg.label.viewdeptposlist" />
                  }
                  className="btn btn-green"
                  disabled={this.state.deptButtonDisabled}
                >
                  &#128712;
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }

    return companyField;
  }

  render() {
    const { officeList, translate, employeesInTimeSheet, oneClient } =
      this.props;
    const {
      officeSelected,
      clientSelected,
      shiftSelected,
      resultBar,
      isFindListChecked,
      positionList,
      officeSelectedIsInvalid,
      shiftSelectedIsInvalid,
      loading,
    } = this.state;
    const shiftList = [
      { shiftName: translate("com.tempedge.msg.label.1st"), shiftId: 1 },
      { shiftName: translate("com.tempedge.msg.label.2nd"), shiftId: 2 },
      { shiftName: translate("com.tempedge.msg.label.3rd"), shiftId: 3 },
    ];
    if (loading) return <Loader />;
    return (
      <form ref={this.dailyListForm} autoComplete="off">
        <ContainerBlue
          title="com.tempedge.dailylist.title.dailylist"
          btns={this.renderContainerButtons()}
        >
          <div className="row">
            <div className="col-12">{resultBar}</div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-12 col-lg-3">
              <label className="text-left label-p">
                {translate("com.tempedge.msg.label.office")}
              </label>
              <DropdownList
                data={officeList}
                value={officeSelected}
                valueField="officeId"
                textField="name"
                onChange={(optionSelected) =>{
                  this.onChangeDropdownList(optionSelected, "officeSelected")
                  if (!officeSelectedIsInvalid) this.setState({ resultBar: null})
                }}
                isInvalid={officeSelectedIsInvalid}
              />
            </div>
            <div className="form-group col-12 col-lg-3">
              <label className="text-left label-p">
                {translate("com.tempedge.msg.label.company")}
              </label>
              {this.getCompany()}
            </div>

            <div className="form-group col-12 col-lg-3">
              <label className="text-left label-p">
                {translate("com.tempedge.msg.label.shift")}
              </label>
              <DropdownList
                value={shiftSelected}
                data={shiftList}
                valueField="shiftId"
                textField="shiftName"
                onChange={(optionSelected) =>{
                  this.onChangeDropdownList(optionSelected, "shiftSelected")
                  if (!shiftSelectedIsInvalid) this.setState({ resultBar: null})
                }}
                isInvalid={shiftSelectedIsInvalid}
              />
            </div>
            <div className="form-group col-12 col-lg-3">
              <label className="text-left label-p">
                {translate("com.tempedge.msg.label.employees")}
              </label>
              <button
                type="button"
                onClick={() => this.toggleEmployessListModal()}
                className="btn btn-green w-100"
              >
                {translate("com.tempedge.msg.label.selectemployees")}
              </button>
            </div>
          </div>

          {employeesInTimeSheet.length > 0 && (
            <TimeSheet
              showDateModal={this.showDateModal}
              clientSelected={clientSelected || oneClient}
              positionList={positionList}
              resetEntireForm={this.resetEntireForm}
              handleDownloadFile={(value) =>
                this.setState({ downloadFile: value })
              }
              isFindListChecked={isFindListChecked}
            />
          )}
          {this.renderModal()}
          {this.dateModal}
        </ContainerBlue>
      </form>
    );
  }
}

const mapStateToProps = ({
  tempEdge: { officeList = [], paginatorList, showModal = false },
  dailyListReducer: {
    employeesInTimeSheet = [],
    saveDailyListResponse = null,
    findListResponse = null,
    deleteDayResponse = null,
    removedEmployeeList = [],
    mailTo = "",
    mailCc = "",
    sendEmail,
  },
}) => ({
  sendEmail,
  mailTo,
  mailCc,
  showModal,
  saveDailyListResponse,
  employeesInTimeSheet,
  officeList: officeList?.data?.result?.data?.content
    ? officeList.data.result.data.content
    : [],
  oneClient: paginatorList?.data?.result?.data?.content
    ? paginatorList?.data?.result?.data?.content[0]
    : [],
  clientListInProps: paginatorList?.data?.result?.data?.content
    ? paginatorList.data.result.data.content
    : [],
  findListResponse,
  deleteDayResponse,
  removedEmployeeList,
});

const mapDispatchToProps = {
  addEmployee,
  loadValuesToEmployeeTimeSheet,
  tempedgeAPI,
  clearProp,
  modalVisibility,
  clearTempedgeStoreProp,
  onChangeMailTo,
  resetEntireState,
  changeEmployeeValue,
  resetEntireStateCustom,
  resetEntireStateAdjustment
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(DailyList)
);
