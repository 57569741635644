import React, { Suspense, useCallback, useEffect, useState } from "react";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import { FieldArray, Form, Formik } from "formik";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import { Translate, withLocalize } from "react-localize-redux";
import Switch from "../../components/common/Switch/Switch";
import addIcon from "../../components/common/ClientCode/assets/plus.png";
import deleteIcon from "../../components/common/ClientCode/assets/delete.png";
import GrayCommonBtn from "../../components/common/Buttons/GrayCommonBtn";
import { useDispatch } from "react-redux";
import {
  addCustomPayroll,
  changeEmployeeValue,
  deleteCustomPayroll,
} from "../../Redux/actions/customPayrollActions";
import CommentModal from "../../components/common/CommentModal/CommentModal";
import commentIcon from "../../assets/static/actionIcons/comment.png";
import Modal from "react-bootstrap/Modal";
import { validate } from "./CustomPayrollSchema";
import TempEdgeApi from "../../services/TempEdgeApi";
import { REACT_APP_URL_FIND_CUSTOM_PAYROLL } from "../../components/common/Routes/URLs";
import Loader from "../../components/common/Loader/Loader";
import OutcomeBar from "../../components/common/OutcomeBar";

const CustomPayrollForm = ({
  person,
  closeModal,
  weekActive,
  messageppl,
  dataGlobal,
  client,
  customPayrollEntityList,
  customPayrollDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [newcustomPayroll, setNewcustomPayroll] = useState([]);
  const dispatch = useDispatch();
  const [newDataGlobal, setNewDataGlobal] = useState();
  const [componentToRender, SetComponentToRender] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteElements, setDeleteElements] = useState([]);
  const [messageCustom, setMessageCustom] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const initialValues = {
    id: person.personId,
    billRate: "",
    dateList: weekActive.end,
    payRate: "",
    person: {
      personId: person.personId,
    },
    hoursPay: "",
    amountPay: "",
    hoursBill: "",
    amountBill: "",
    sepCheck: false,
    sepInvoice: false,
    payCode: { customPayrollTypeId: null },
    billingCode: { customPayrollTypeId: null },
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const request = {
        orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
          ?.orgId,
        startDate: weekActive.start,
        endDate: weekActive.end,
        dateList: weekActive.end,
        clientId: client.clientId,
        personId: person.personId,
      };
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_FIND_CUSTOM_PAYROLL,
        payload: request,
      });
      if (res.status === 200 && res.result && res.result.length > 0) {
        const updatedFindCustom = res.result.map((item, index) => {
          return { id: item.customPayrollId, ...item };
        });
        dispatch(
          changeEmployeeValue({
            id: person.personId,
            key: "findCustom",
            value: true,
          })
        );
        if (customPayrollDelete.length > 0 && updatedFindCustom.length > 0) {
          const findDeletecustompayroll = updatedFindCustom.filter((item) => {
            return !customPayrollDelete.some(
              (deleteItem) => deleteItem.customPayrollId === item.id
            );
          });
          setNewcustomPayroll((prev) => {
            return [...prev, ...findDeletecustompayroll];
          });
        } else {
          setNewcustomPayroll(updatedFindCustom);
        }
      }
    } catch (error) {
      setLoading(error);
    } finally {
      setLoading(false);
    }
  }, [client, customPayrollDelete, dispatch, person, weekActive]);

  const updateCustomPayroll = useCallback(() => {
    if (customPayrollEntityList.length > 0) {
      const customPayroll = customPayrollEntityList.filter(
        (entity) => entity.person.personId === person.personId
      );
      if (customPayroll.length > 0) {
        setNewcustomPayroll(customPayroll);
      } else {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [customPayrollEntityList, fetchData, person]);

  useEffect(() => {
    setMessageCustom("");
    updateCustomPayroll();
  }, [updateCustomPayroll]);

  //funcion que cambia la data del custompayroll type
  useEffect(() => {
    setMessageCustom("");
    if (dataGlobal.length > 0) {
      const newGlobal = [
        {
          customPayrollTypeId: 0,
          filterBy: null,
          code: "select",
          description: "",
        },
        ...dataGlobal,
      ];
      if (newGlobal.length > 0) {
        const newGlobalEdit = newGlobal.map((data) => {
          data.select =
            data.code && data.description
              ? `${data.code} - ${data.description}`
              : data.code;
          return data;
        });
        setNewDataGlobal(newGlobalEdit);
      }
    }
  }, [dataGlobal]);

  //TODO modal de los comentarios (este se cambiara por un nuevo servicio)
  const modalComment = (payroll) => {
    let ComponentToRenders = React.lazy(
      () => import("../../components/common/Comments/Comments")
    );
    ComponentToRenders = ComponentToRenders ? setShowModal(true) : null;
    ComponentToRenders = (
      <CommentModal payroll={payroll} setShowModal={setShowModal} />
    );
    SetComponentToRender(ComponentToRenders);
  };

  const handleSubmit = (values) => {
    messageppl(null);
    const { customPayroll } = values;
    if (deleteElements.length > 0) {
      for (let index = 0; index < deleteElements.length; index++) {
        dispatch(deleteCustomPayroll(deleteElements[index]));
      }
    }
    if (customPayroll && customPayroll.length > 0) {
      dispatch(addCustomPayroll(customPayroll));
      dispatch(
        changeEmployeeValue({
          id: person.personId,
          key: "confirmation",
          value: true,
        })
      );
      closeModal();
    } else {
      if (person.findCustom) {
        dispatch(
          changeEmployeeValue({
            id: person.personId,
            key: "confirmation",
            value: true,
          })
        );
        closeModal();
      } else {
        setMessageCustom("com.tempedge.msg.label.onecustomrequired");
      }
    }
  };

  const handleCancel=()=>{
    if (deleteElements.length > 0 ) {
      for (let index = 0; index < deleteElements.length; index++) {
        if(deleteElements[index].id === person.personId){
        dispatch(addCustomPayroll(deleteElements[index]));}
      }
    }
    closeModal();
  }

  if (loading) return <Loader />;

  return (
    <>
      <ContainerBlue label={person.firstName + " " + person.lastName}>
        {messageCustom && <OutcomeBar personMessage={messageCustom} />}
        <Formik
          enableReinitialize
          initialValues={{
            customPayroll:
              newcustomPayroll.length > 0
                ? newcustomPayroll
                : [{ ...initialValues }],
          }}
          validate={(values) => validate(values)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange }) => {
            return (
              <Form className="mx-auto">
                <FieldArray
                  name="customPayroll"
                  render={({ push, remove, replace }) => (
                    <div>
                      {values?.customPayroll?.length > 0
                        ? values?.customPayroll?.map((custom, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="form-group row mt-3 mx-auto">
                                <div className="list-item row ml-2">
                                  <div className="col-3">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.paycode" />
                                    </label>
                                    <div className="form-group">
                                      <DropdownList
                                        name={`customPayroll.${index}.payCode`}
                                        value={{
                                          customPayrollTypeId: custom.payCode
                                            ?.customPayrollTypeId
                                            ? custom.payCode
                                              ?.customPayrollTypeId
                                            : 0,
                                        }}
                                        data={newDataGlobal}
                                        valueField="customPayrollTypeId"
                                        textField={"select"}
                                        onChange={(value) => {
                                          replace(index, {
                                            ...custom,
                                            payCode: {
                                              customPayrollTypeId:
                                                value.customPayrollTypeId,
                                            },
                                          });
                                        }}
                                        placeholder={
                                          <Translate id="com.tempedge.msg.label.select" />
                                        }
                                        isInvalid={
                                          errors &&
                                            errors.customPayroll &&
                                            errors?.customPayroll[index]
                                              ?.payCode
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.amount" />
                                    </label>
                                    <input
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                      }}
                                      type="number"
                                      step="0.01"
                                      name={`customPayroll.${index}.amountPay`}
                                      value={custom.amountPay || ""}
                                      placeholder="0.0"
                                      className={`form-control tempEdge-input-box ${errors.customPayroll &&
                                          touched.customPayroll
                                          ? errors?.customPayroll[index]
                                            ?.amountPay
                                            ? "has-error"
                                            : ""
                                          : ""
                                        }`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.hourspay" />
                                    </label>
                                    <input
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                      }}
                                      type="number"
                                      step="0.01"
                                      name={`customPayroll.${index}.hoursPay`}
                                      value={custom.hoursPay || ""}
                                      placeholder="0.0"
                                      className={`form-control tempEdge-input-box ${errors.customPayroll &&
                                          touched.customPayroll
                                          ? errors?.customPayroll[index]
                                            ?.hoursPay
                                            ? "has-error"
                                            : ""
                                          : ""
                                        }`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.payRate" />
                                    </label>
                                    <input
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                      }}
                                      type="number"
                                      step="0.01"
                                      name={`customPayroll.${index}.payRate`}
                                      value={custom.payRate || ""}
                                      placeholder="0.0"
                                      className={`form-control tempEdge-input-box ${errors.customPayroll &&
                                          touched.customPayroll
                                          ? errors?.customPayroll[index]
                                            ?.payRate
                                            ? "has-error"
                                            : ""
                                          : ""
                                        }`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-3 row mx-auto">
                                    <div>
                                      <label className="control-label">
                                        <Translate id="com.tempedge.msg.label.sepcheck" />
                                      </label>
                                      <Switch
                                        name={`customPayroll.${index}.sepCheck`}
                                        checked={custom.sepCheck || false}
                                        children=""
                                        size="md"
                                        align="between"
                                        onChange={(value) => {
                                          replace(index, {
                                            ...custom,
                                            sepCheck: value,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="ml-auto mr-4">
                                      <img
                                        className="actions-cell__icon"
                                        style={{
                                          width: "32px",
                                          paddingTop: "35px",
                                        }}
                                        type="button"
                                        onClick={() => modalComment(person)}
                                        src={commentIcon}
                                        alt="Comments icon"
                                      />
                                      <span
                                        className="px-3"
                                        title="Remove file"
                                        onClick={() => {
                                          const idToDelete = custom.id;
                                          const indexToDelete =
                                            values.customPayroll.findIndex(
                                              (item) => item.id === idToDelete
                                            );
                                          setDeleteElements([
                                            ...deleteElements,
                                            values.customPayroll[index],
                                          ]);
                                          remove(indexToDelete);
                                        }}
                                      >
                                        <img
                                          className="delete-icon-phones"
                                          src={deleteIcon}
                                          alt="deleteIcon"
                                        />
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-3">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.billingcode" />
                                    </label>
                                    <div className="form-group">
                                      <DropdownList
                                        data={newDataGlobal}
                                        name={`customPayroll.${index}.billingCode.payrollTypeId`}
                                        value={{
                                          customPayrollTypeId: custom
                                            .billingCode?.customPayrollTypeId
                                            ? custom.billingCode
                                              ?.customPayrollTypeId
                                            : 0,
                                        }}
                                        valueField="customPayrollTypeId"
                                        textField="select"
                                        placeholder={
                                          <Translate id="com.tempedge.msg.label.select" />
                                        }
                                        onChange={(value) => {
                                          replace(index, {
                                            ...custom,
                                            billingCode: {
                                              customPayrollTypeId:
                                                value.customPayrollTypeId,
                                            },
                                          });
                                        }}
                                        isInvalid={
                                          errors &&
                                            errors.customPayroll &&
                                            errors?.customPayroll[index]
                                              ?.billingCode
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.amountbill" />
                                    </label>
                                    <input
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                      }}
                                      type="number"
                                      step="0.01"
                                      name={`customPayroll.${index}.amountBill`}
                                      value={custom.amountBill ||""}
                                      placeholder="0.0"
                                      className={`form-control tempEdge-input-box ${errors.customPayroll &&
                                          touched.customPayroll
                                          ? errors?.customPayroll[index]
                                            ?.amountBill
                                            ? "has-error"
                                            : ""
                                          : ""
                                        }`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.hoursbill" />
                                    </label>
                                    <input
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                      }}
                                      type="number"
                                      step="0.01"
                                      name={`customPayroll.${index}.hoursBill`}
                                      value={custom.hoursBill || ""}
                                      placeholder="0.0"
                                      className={`form-control tempEdge-input-box ${errors.customPayroll &&
                                          touched.customPayroll
                                          ? errors?.customPayroll[index]
                                            ?.hoursBill
                                            ? "has-error"
                                            : ""
                                          : ""
                                        }`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.billRate" />
                                    </label>
                                    <input
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                      }}
                                      type="number"
                                      step="0.01"
                                      name={`customPayroll.${index}.billRate`}
                                      value={custom.billRate || ""}
                                      placeholder="0.0"
                                      className={`form-control tempEdge-input-box ${errors.customPayroll &&
                                          touched.customPayroll
                                          ? errors?.customPayroll[index]
                                            ?.billRate
                                            ? "has-error"
                                            : ""
                                          : ""
                                        }`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <label className="control-label">
                                      <Translate id="com.tempedge.msg.label.sepinvoice" />
                                    </label>
                                    <Switch
                                      name={`customPayroll.${index}.sepInvoice`}
                                      checked={custom.sepInvoice || false}
                                      children=""
                                      size="md"
                                      align="between"
                                      onChange={(value) => {
                                        replace(index, {
                                          ...custom,
                                          sepInvoice: value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  borderBottom: "dotted 1px #626262",
                                  marginBottom: "15px",
                                }}
                              ></div>
                            </React.Fragment>
                          );
                        })
                        : person.findCustom && (
                          <div className="mt-8 ml-5">
                            <h2>
                              {
                                <Translate id="com.tempedge.msg.label.deleteallcustom" />
                              }
                            </h2>
                          </div>
                        )}
                      <div className="form-group row">
                        <div className="col-12 list-item ">
                          <span
                            style={{ paddingRight: "2.2rem" }}
                            className="center-block pull-right add-fieldArray-btn"
                            onClick={() => {
                              const newId = `${person.personId}_${values.customPayroll.length}`;
                              const newInitialValues = {
                                ...initialValues,
                                id: newId,
                              };
                              push(newInitialValues);
                            }}
                          >
                            <img
                              style={{ width: "42px", paddingTop: "12px" }}
                              src={addIcon}
                              alt="addIcon"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="row py-4">
                        <div className="col-md-5 offset-md-1">
                          <GrayCommonBtn
                            tag="com.tempedge.msg.label.cancel"
                            onClick={() => {
                              handleCancel(person)
                            }}
                          />
                        </div>
                        <div className="col-md-5">
                          <button
                            type="submit"
                            className={
                              errors.customPayroll === "1 required"
                                ? "has-error btn btn-danger btn-block register-save-btn"
                                : "btn btn-primary btn-block register-save-btn"
                            }
                          >
                            {<Translate id="com.tempedge.msg.label.save" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
        <Modal show={showModal} onHide={() => handleCloseModal()}>
          <Modal.Header closeButton />
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              {componentToRender}
            </Suspense>
          </div>
        </Modal>
      </ContainerBlue>
    </>
  );
};

export default withLocalize(CustomPayrollForm);
