import { payrollPersonHistoryType } from "../types/payrollPersonHistoryType";

const initialState = {
  employeesInList: [],
  loading: false,
  data: [],
  message: null,
};

export const payrollPersonHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case payrollPersonHistoryType.ADD_PERSON_PAYROLL_HISTORY:
      return {
        ...state,
        employeesInList: [action.payload]
      }
    case payrollPersonHistoryType.REMOVE_PERSON_PAYROLL_HISTORY:
      return {
        ...state,
        employeesInList: state.employeesInList
          .filter(employee => employee.id !== action.payload.personId),
      }
    case payrollPersonHistoryType.RESET_ENTIRE_STATE:
      return initialState;
    case payrollPersonHistoryType.FETCH_PAYROLL_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case payrollPersonHistoryType.FETCH_PAYROLL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case payrollPersonHistoryType.FETCH_PAYROLL_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    default:
      return state;
  }
}
