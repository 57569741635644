import { useEffect, useState } from 'react';
import TempEdgeApi from '../../../services/TempEdgeApi';
import { REACT_APP_URL_FIND_TOTAL_CLIENT_EMPLOYEES_BY_DAY } from '../../../components/common/Routes/URLs';

const useMixBarChartData = (clientId) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const orgId = JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId;
      setLoading(true);
      try {
        const { data } = await TempEdgeApi.post({
          url: REACT_APP_URL_FIND_TOTAL_CLIENT_EMPLOYEES_BY_DAY,
          payload: {
            orgId,
            clientId : clientId || null
          },
        });
        if (data?.status === 200) {
          setChartData(data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [clientId]);

  return { chartData, loading, error };

};

export default useMixBarChartData;
