import { useEffect, useState } from 'react';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import { normalizeDate } from '../../../utils/ReGexs';
import useWeekDates from '../../../Hooks/useWeekDates';

const DropdownClientWeekRange = ({ onChange, client, isInvalid = false, value = null }) => {
    const { weekDates, fetchWeekDates } = useWeekDates();
    const [weekdayOptions, setWeekdayOptions] = useState([]);
    const [selectedWeekday, setSelectedWeekday] = useState(value);

    useEffect(() => {
        if (client) {
            fetchWeekDates(client);
        } else {
            setSelectedWeekday(null);
            setWeekdayOptions([]);
        }
    }, [client, fetchWeekDates]);

    useEffect(() => {
        if (client && weekDates) {
            const options = [
                {
                    label: `FROM ${normalizeDate(weekDates.lastWeekInit)} TO ${normalizeDate(weekDates.lastWeekEnd)}`,
                    value: "LASTWEEK",
                },
                {
                    label: `FROM ${normalizeDate(weekDates.thisWeekInit)} TO ${normalizeDate(weekDates.thisWeekEnd)}`,
                    value: "THISWEEK",
                },
                { label: "Custom", value: "CUSTOM" },
            ];
            setWeekdayOptions(options);
        }
    }, [client, weekDates]);

    const handleChange = (selected) => {
        setSelectedWeekday(selected);
        onChange(selected);
    };

    return (
        <DropdownList
            value={selectedWeekday}
            data={weekdayOptions}
            valueField="value"
            textField="label"
            onChange={handleChange}
            isInvalid={isInvalid}
        />
    );
};

export default DropdownClientWeekRange;
