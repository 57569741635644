import React, { useState } from "react";
import { withLocalize } from "react-localize-redux";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import { normalizeDate } from "../../../utils/ReGexs";
import { REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_RECAPTCHA_V2_SITE_KEY } from "../../../components/common/Routes/URLs";
import RecaptchaV2 from '../../../components/common/Captcha/RecaptchaV2';
import useRecaptchaTimer from '../../../Hooks/Recaptcha/useRecaptchaTimer';

function WizardSecondPage({
  translate,
  prev,
  valuesFormik,
  handleSubmit,
  next,
}) {
  const [token, setToken] = useState(valuesFormik?.token || "");
  const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaV2Verified, setRecaptchaV2Verified] = useState(false);

  useRecaptchaTimer(() => {
    setShowRecaptchaV2(true);
    setIsSubmitDisabled(true); // Deshabilitar el botón de submit cuando se muestra el reCAPTCHA v2
});

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      if (executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha('wizardSecondPage');
        setToken(recaptchaToken);
        handleSubmit({ ...valuesFormik, token: recaptchaToken });
      } else {
        console.warn('No se pudo obtener el contexto de Google ReCaptcha.');
        handleSubmit(valuesFormik);
      }
    } catch (error) {
      console.error('Error al ejecutar reCAPTCHA:', error);
      handleSubmit(valuesFormik);
    }
  };

  const handleRecaptchaVerify = (token) => {
    setIsSubmitDisabled(false);  // Habilitar el botón de submit cuando se complete el reCAPTCHA v2
    setRecaptchaV2Verified(true);  // Marcar el reCAPTCHA v2 como verificado
};

  const btns = (
    <>
      <div className="row py-4" style={{ justifyContent: "space-evenly" }}>
        <div className="col-md-5">
          <button
            type="button"
            className="btn btn-default btn-block register-save-btn previous"
            onClick={() => prev()}
          >
            {translate("com.tempedge.msg.label.back")}
          </button>
        </div>
        <div className="col-md-5">
          <button
            onClick={handleFormSubmit}
            type="button"
            className="btn btn-primary btn-block register-save-btn next"
            disabled={isSubmitDisabled} // Botón deshabilitado si es necesario
          >
            {translate("com.tempedge.msg.label.submit")}
          </button>
        </div>
      </div>
    </>
  );

  return (
    <ContainerBlue
      title={
        valuesFormik?.edit
          ? "com.tempedge.msg.title.updateaccount"
          : "com.tempedge.msg.title.newaccount"
      }
      btns={btns}
      width={1280}
    >
      <div className="form-group row justify-content-center ">
        <div className="col-md-4 ">
          <label className="control-label " style={{ marginLeft: "8rem" }}>
            {translate("com.tempedge.msg.label.firstname")}
          </label>
          <label className="control-text-user" style={{ marginLeft: "8rem" }}>
            {valuesFormik.account.firstName}
          </label>
        </div>
        <div className="col-md-4">
          <label className="control-label " style={{ marginLeft: "3rem" }}>
            {translate("com.tempedge.msg.label.middlename")}
          </label>
          <label className="control-text-user" style={{ marginLeft: "3rem" }}>
            {valuesFormik.account.middleName}
          </label>
        </div>
        <div className="col-md-4">
          <label className="control-label  " style={{ marginLeft: "8rem" }}>
            {translate("com.tempedge.msg.label.lastname")}
          </label>
          <label className="control-text-user" style={{ marginLeft: "8rem" }}>
            {valuesFormik.account.lastName}
          </label>
        </div>
      </div>
      <div className="form-group row justify-content-center">
        <div className="col-md-4 ">
          <label className="control-label" style={{ marginLeft: "8rem" }}>
            {translate("com.tempedge.msg.label.gender")}
          </label>
          <label className="control-text-user" style={{ marginLeft: "8rem" }}>
            {valuesFormik.account.gender === 0
              ? translate("com.tempedge.msg.label.gender.male")
              : valuesFormik.account.gender === 1
              ? translate("com.tempedge.msg.label.gender.female")
              : translate("com.tempedge.msg.label.select")}
          </label>
        </div>
        <div className="col-md-4">
          <label className="control-label " style={{ marginLeft: "3rem" }}>
            {translate("com.tempedge.msg.label.email")}
          </label>
          <label className="control-text-user" style={{ marginLeft: "3rem" }}>
            {valuesFormik.account.email}
          </label>
        </div>
        <div className="col-md-4">
          <label className="control-label" style={{ marginLeft: "8rem" }}>
            {translate("com.tempedge.msg.label.birthday")}
          </label>
          <label className="control-text-user" style={{ marginLeft: "8rem" }}>
            {normalizeDate(valuesFormik.account.birthDay)}
          </label>
        </div>
      </div>
      {!valuesFormik.edit && (
        <div className="form-group row justify-content-center">
          <div className="col-md-4">
            <label className="control-label " style={{ marginLeft: "8rem" }}>
              {translate("com.tempedge.msg.label.role")}
            </label>
            <label className="control-text-user" style={{ marginLeft: "8rem" }}>
              {valuesFormik.user.role.name}
            </label>
          </div>
          <div className="col-md-4 ">
            <label className="control-label " style={{ marginLeft: "3rem" }}>
              {translate("com.tempedge.msg.label.organization")}
            </label>
            <label className="control-text-user" style={{ marginLeft: "3rem" }}>
              {valuesFormik.user.orgName}
            </label>
          </div>
          <div className="col-md-4 ">
            {valuesFormik.user.role &&
              valuesFormik.user.role.name &&
              valuesFormik.user.role.name.indexOf("CLIENT") >= 0 && (
                <>
                  <label
                    className="control-label"
                    style={{ marginLeft: "8rem" }}
                  >
                    {translate("com.tempedge.msg.label.client")}
                  </label>
                  <label
                    className="control-text-user"
                    style={{ marginLeft: "8rem" }}
                  >
                    {valuesFormik.user.agencyclient}
                  </label>
                </>
              )}
            {valuesFormik.user.role &&
              valuesFormik.user.role.name &&
              valuesFormik.user.role.name.indexOf("CLIENT") < 0 && (
                <>
                  <label
                    className="control-label "
                    style={{ marginLeft: "8rem" }}
                  >
                    {translate("com.tempedge.msg.label.office")}
                  </label>
                  <label
                    className="control-text-user"
                    style={{ marginLeft: "8rem" }}
                  >
                    {valuesFormik.user.agencyoffice}
                  </label>
                </>
              )}
          </div>
        </div>
      )}
      {showRecaptchaV2 && (
        <div className="form-group mt-3 d-flex justify-content-center">
          <div
            id="recaptcha-v2-container"
            style={{
              border: recaptchaV2Verified ? 'none' : '1px solid red', // Muestra borde rojo si no está verificado
              borderRadius: '4px'
            }}
          >
            <RecaptchaV2 sitekey={REACT_APP_RECAPTCHA_V2_SITE_KEY} onVerify={handleRecaptchaVerify} />
          </div>
        </div>
      )}
    </ContainerBlue>
  );
}

// Se asegura que el componente esté siempre dentro del contexto de reCAPTCHA
const WrappedWizardSecondPage = (props) => (
  <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
    <WizardSecondPage {...props} />
  </GoogleReCaptchaProvider>
);

export default withLocalize(WrappedWizardSecondPage);
