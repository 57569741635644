import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import WizardFirstPage from "./WizardFirstPage";
import WizardSecondPage from "./WizardSecondPage";
import { initialValues } from "./Schema";
import Loader from "../../../components/common/Loader/Loader";
import { shallowEqual, useSelector } from "react-redux";
import TempEdgeApi from "../../../services/TempEdgeApi";
import { useHistory, useLocation } from "react-router-dom";
import UpdatePortalUserList from "./UpdatePortalUserList";
import {
  REACT_APP_URL_ROLE_LIST_ALL,
  REACT_APP_URL_USER_ID,
  REACT_APP_URL_USER_SAVE,
  REACT_APP_URL_USER_UPDATE,
} from "../../../components/common/Routes/URLs";
import { useDispatch } from "react-redux";
import { resetEntireState } from "../../../Redux/actions/customPayrollActions";

const filtersNameRols = [
  "TE_ADMIN",
  "CLIENT",
  "CLIENT_PAYROLL",
  "CLIENT_FACEREC",
  "TEMPEDGE_USER"
];

function FormUser({ user }) {
  const [page, setPage] = useState(0);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState(initialValues);
  const [valuesFormik, SetValuesFormik] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const username = useSelector((state) => {
    return state.tempEdge.username || "";
  }, shallowEqual);

  useEffect(() => {
    dispatch(resetEntireState());
    async function fetch() {
      if (user) {
        try {
          setLoading(true);
          const { data: roles } = await TempEdgeApi.get({
            url: REACT_APP_URL_ROLE_LIST_ALL,
          });
          setData({
            ...initialValues,
            edit: true,
            account: {
              ...initialValues.account,
              firstName: user.user.firstName,
              lastName: user.user.lastName,
              email: user.user.email,
              username: user.user.username,
              password: "",
              passwordConfirmation: "",
              id: user.user.id,
            },
            user: {
              orgName: user.orgName,
              agencyoffice: user.officeName || "",
              agencyclient: user.clientName || "",
              id: user.user.id,
              ssnlastfour: user.personIdentification,
              role:
                roles.result.find((role) => role.roleId === user.userRoleId) ||
                initialValues.user.role,
            },
            status: user.status,
          });
          setLoading(false);
        } catch (err) {
          setData(initialValues);
          setLoading(false);
        }
      }
    }
    fetch();
  }, [user, dispatch]);

  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        const { data: rol } = await TempEdgeApi.get({
          url: REACT_APP_URL_ROLE_LIST_ALL,
        });
        if (rol.status === 200 && rol.code === "TE00") {
          const filteredData = rol.result.filter(obj => filtersNameRols.includes(obj.name));
          setRoles(filteredData);
        }
      } catch (e) {
        setMessage(e);
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, []);


  useEffect(() => {
    async function fetch() {
      const path = location.pathname.split("/");
      if (username && path[2] === "update") {
        try {
          setLoading(true);
          const { data: resp } = await TempEdgeApi.post({
            url: REACT_APP_URL_USER_ID,
            payload: {
              filterBy: {
                username,
              },
            },
          });
          if (resp.status === 200 && resp.code === "TE00") {
            const { data: roles } = await TempEdgeApi.get({
              url: REACT_APP_URL_ROLE_LIST_ALL,
            });
            const user = resp.result[0].user;
            setData({
              ...initialValues,
              edit: true,
              account: {
                ...initialValues.account,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                username: user.username,
                password: "******",
                passwordConfirmation: "******",
              },
              user: {
                orgName: resp.result[0].orgName,
                agencyoffice: resp.result[0].officeName || "",
                agencyclient: resp.result[0].clientName || "",
                id: resp.result[0].user.id,
                role:
                  roles.result.find(
                    (role) => role.roleId === resp.result[0].userRoleId
                  ) || initialValues.user.role,
              },
              portalUserList: resp.result.map((item) => {
                return {
                  portalUserConfId: item.portalUserConfId,
                  orgName: item.orgName,
                  clientId: item.clientId || "",
                  officeId: item.officeId || "",
                  role:
                    roles.result.find(
                      (role) => role.roleId === item.userRoleId
                    ) || initialValues.user.role,
                  user: item.user.id,
                  personalIdentification: item.personIdentification,
                };
              }),
              status: resp.result[0].status,
            });
          } else {
            setMessage(resp);
          }
        } catch (e) {
          setMessage({ type: "fail", content: "com.tempedge.error.undefined" });
        } finally {
          setLoading(false);
        }
      }
    }
    fetch();
  }, [username, location]);

  function next() {
    setPage(page + 1);
  }

  function prev() {
    setPage(page - 1);
  }

  function changeFormikValues(values) {
    SetValuesFormik(values);
    setData(values);
  }
  function resetFormikValues() {
    SetValuesFormik(initialValues);
    setData(initialValues);
    setMessage("");
  }

  function handleSubmit(values) {
    if (values.edit) {
      update(values);
      return;
    }
    save(values);
  }

  async function update(values) {
    let portalList = [];

    if (values.status === "P") {
      setMessage({ type: "fail", content: "com.tempedge.error.pendingUser" });
      setPage(0);
      return;
    }

    if (values.status === "R") {
      portalList = [
        ...portalList,
        {
          portalUserConfId:
            values.portalUserList && values.portalUserList.length > 0
              ? values.portalUserList[0]?.portalUserConfId
              : null,
          orgName: values.user.orgName,
          clientName: values.user.agencyclient,
          officeName: values.user.agencyoffice,
          userRoleId: values.user.role.roleId,
          user: {
            ...values.account,
            ...values.user,
          },
          personIdentification: values.user.ssnlastfour,
        },
      ];
    }

    if (values.status === "A")
      portalList = values.portalUserList.map((item) => {
        return {
          portalUserConfId: item.portalUserConfId,
          clientId: item.clientId,
          officeId: item.officeId,
          user: {
            ...values.account,
            id: item.user,
          },
          personIdentification: item.personIdentification,
        };
      });

    try {
      setLoading(true);
      const { data } = await TempEdgeApi.post({
        url: REACT_APP_URL_USER_UPDATE,
        payload: { portalList },
      });
      if (data.status !== 200 && data.code !== "TE00") {
        setData(values);
      }
      setMessage(data);
      setLoading(false);
      setPage(0);
      history.goBack();
    } catch (err) {
      setPage(0);
      setData(values);
      setMessage({ type: "fail", content: "com.tempedge.error.undefined" });
      setLoading(false);
    }
  }

  async function save(values) {
    const data = {
      orgName: values.user.orgName,
      user: {
        firstName: values.account.firstName,
        lastName: values.account.lastName,
        username: values.account.username,
        password:
          values.account.password === values.account.passwordConfirmation
            ? values.account.password
            : null,
        email: values.account.email,
        birthday: values.account.birthday,
        gender: values.account.gender,
      },
      clientName: values.user.agencyclient || null,
      officeName: values.user.agencyoffice || null,
      roleId: values.user.role.roleId,
      agencyssnlastfour: values.user.ssnlastfour || null,
      personId: values.personId,
    };
    try {
      setLoading(true);
      const { data: resp } = await TempEdgeApi.post({
        url: REACT_APP_URL_USER_SAVE,
        payload: data,
      });
      setMessage(resp);
      if (resp.status !== 200 && resp.code !== "TE00") {
        setData(values);
      } else {
        setData(initialValues);
      }
    } catch (err) {
      setData(values);
      setMessage({ type: "fail", content: "com.tempedge.error.undefine" });
    } finally {
      setLoading(false);
      setPage(0);
    }
  }

  if (loading) return <Loader />;

  return (
    <div className="wizard-create-agency">
      <Stepper
        steps={
          data.edit
            ? [{ title: "" }, { title: "" }, { title: "" }]
            : [{ title: "" }, { title: "" }]
        }
        activeStep={page}
        activeColor="#eb8d34"
        completeColor="#8cb544"
        defaultBarColor="#eb8d34"
        completeBarColor="#8cb544"
        barStyle="solid"
        circleFontSize={16}
      />
      {page === 0 && (
        <WizardFirstPage
          next={() => next()}
          data={data}
          changeFormikValues={changeFormikValues}
          roles={roles}
          message={message}
          resetFormikValues={resetFormikValues}
        />
      )}
      {page === 1 &&
        (!data.edit ? (
          <WizardSecondPage
            next={() => next()}
            valuesFormik={valuesFormik}
            prev={prev}
            handleSubmit={handleSubmit}
          />
        ) : (
          <UpdatePortalUserList
            formik={data}
            prev={prev}
            next={() => next()}
            roles={roles}
            handleSubmit={handleSubmit}
            changeFormikValues={changeFormikValues}
            valuesFormik={valuesFormik}
          />
        ))}
      {page === 2 && (
        <WizardSecondPage
          next={() => next()}
          valuesFormik={valuesFormik}
          prev={prev}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

export default FormUser;
