import React from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FcInfo } from "react-icons/fc";

let Tooltip_ = (props) => {
    return(
        <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="popover-contained"
                  style={{ fontSize: '15px' }}
                >{props.translate}
                </Tooltip>}
            >
              <FcInfo className="tooltip-icon" style={{ width: props.iconWidth, height: props.iconHeight, float: (typeof props.iconFloat !== 'undefined' && props.iconFloat !== '') ? props.iconFloat: 'none' }} />
        </OverlayTrigger>
    )
}

export default Tooltip_; 
