import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const useClientList = () => {
  const clientsListRedux = useSelector(state => state.tempEdge.clientList);
  const clientList = useMemo(() => clientsListRedux, [clientsListRedux]);
  return clientList;
};

export default useClientList;
