import React, { useCallback, useState } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'
import Modal from '../../Modals/Modal/Modal'
import { getList } from '../../Redux/actions/tempEdgeActions'
import types from '../../Redux/actions/types'
import addIcon from "../../components/common/ClientCode/assets/plus.png"
import { globalparameterlist, sizePagesList } from '../../components/common/Constants/Constants'
import ContainerBlue from '../../components/common/Container/ContainerBlue'
import DropdownList from '../../components/common/Dropdown/DropdownList'
import Loader from '../../components/common/Loader/Loader'
import OutcomeBar from '../../components/common/OutcomeBar'
import { REACT_APP_URL_DICTIONARY_LIST_ALL } from '../../components/common/Routes/URLs'
import ParametersTable from '../../components/common/Table/ParametersTable'
import TempEdgeApi from '../../services/TempEdgeApi'
import { store } from '../../store/store'


const GlobalParameter = (props) => {
  const dispatch = useDispatch();

  const { promiseInProgress } = usePromiseTracker();
  
  const [message, setMessage] = useState("")
  const [selectParameters, setSelectParameters] = useState("")
  const [data, setData] = useState([])
  const [validationOk, setValidationOk] = useState(false)
  const [errors, setErrors] = useState({});

  const resetStates = useCallback(() => {
    dispatch({
      type: types.CLEAR_PROP,
      payload: "paginatorList",
    });
    setSelectParameters("");
    setData([]);
    setValidationOk(false);
  },[dispatch])



  const changePage = async(myPage) => {
    let payload = {
      page: myPage,
      size: sizePagesList,
      parameterKey: selectParameters.key,
    };
    try {
      setMessage(null)
      const { data: res } = await TempEdgeApi.post({
        url: selectParameters.find,
        payload:payload
      })
      if (res.status === 200) {
        setData(res.result)
        dispatch({
          type: types.TEMPEDGE_LIST,
          payload: res.result,
        });
      }else{
        setData([])
      }
    } catch (error) {
      setMessage(error)
    }
  };

  const getData = async (value) => {
    const request={
      parameterKey:value.key,
      size:sizePagesList,
      page:0,
    }
    try {
      setMessage(null)
      const { data: res } = await TempEdgeApi.post({
        url: value.find,
        payload:value.key=== "com.tempedge.global.param.dictionary"?request:{parameterKey:value.key},
      })
      if (res.status === 200) {
        setData(res.result)
        dispatch({
          type: types.TEMPEDGE_LIST,
          payload: res.result,
        });
      }else{
        setData([])
      }
    } catch (error) {
      setMessage(error)
    }
  }

  const onSubmit = async () => {
    try {
     let newData_filter=[];
      setValidationOk(false)
      setMessage(null)  

      data.data.content.map(async (item) => {
        if (item[data.keyField] && item[data.keyField].toString().includes('freeId')) delete item[data.keyField]
        let isEmpty = Object.values(item).some(x => (x === ''))
        if (!isEmpty) {
          newData_filter.push(item)
        }
        return null
      })

      const { data: res } = await TempEdgeApi.postArray({
        url: selectParameters.save,
        payload: newData_filter
      })
      if (res.status === 200 || res.status === 201) {        
        if(selectParameters.key === "com.tempedge.global.param.dictionary"){
          await store.dispatch(getList(REACT_APP_URL_DICTIONARY_LIST_ALL, types.GET_LANGUAGE));
        }
        setErrors({ ...errors, label: null });
        resetStates();
      } 

      setMessage(res);
    } catch (res) {
      setMessage(res);
    }finally{
      resetStates();
    }
  }

  const onChangeDropdownList = (value) => {
    trackPromise(getData(value))
    setSelectParameters(value)
  }

  const addObjectTable = () => {
    let newData = data;
    let newItem = {}
    data.columns.map(value => {
      newItem[data.keyField] = 'freeId' + data.data.length 
      newItem[value.field] = ''
      newItem.orgId=JSON.parse(window.localStorage.getItem('agency'))?.organizationEntity?.orgId
      return value
    })
    newData.data.content.unshift(newItem)
    return setData({ ...data, ...data.data.newItem })
  }

  const bottomsModal = (
    <>
      <div className="col">
        <button
          type="button"
          style={{ minHeight: '0' }}
          className="btn btn-default btn-block register-save-btn previous "
          onClick={() => setValidationOk(false)}
        >
          {props.translate('com.tempedge.msg.label.cancel')}
        </button>
      </div>
      <div className="col">
        <button
          type="submit"
          style={{ minHeight: '0' }}
          className="btn btn-primary btn-block register-save-btn save"
          onClick={() => trackPromise(onSubmit())}
        >
          {props.translate('com.tempedge.msg.label.save')}
        </button>
      </div>
    </>
  )


  return (
    promiseInProgress ?
      <Loader /> :
      <ContainerBlue title='com.tempedge.msg.title.globalparams'>
        <div className="row">
          <div className="col-12">{message && <OutcomeBar response={message} />}</div>
        </div>
        <div className="row mt-2">
          <div className="form-group col-12 col-lg-3">
            <label className="text-left label-p">{props.translate('com.tempedge.msg.label.parameters')}</label>
            <DropdownList
              data={globalparameterlist}
              textField={'label'}
              valueField={'key'}
              value={selectParameters}
              isInvalid={errors.label ? true : false}             
              onChange={(value) => onChangeDropdownList(value)}
            />
          </div>
        </div>
        {
          data?.columns?.length > 0 &&
          <>
            <div className="time-sheet">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="form-group col-12 ">
                        <p className="green-text font-weight-bold text-uppercase">{<Translate id='com.tempedge.msg.label.parameters' />}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-11">
                    <ParametersTable data={data} setValidationOk={setValidationOk} setData={setData} resetState={resetStates}  changePage={changePage}/>
                  </div>
                  <div className="col-1">
                    <span
                      style={{ paddingRight: "2.2rem" }}
                      className="center-block pull-right add-fieldArray-btn"
                      onClick={() => {
                        addObjectTable()
                      }}
                    >
                      <img
                        style={{ width: "42px", paddingTop: "12px" }}
                        src={addIcon}
                        alt="addIcon"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              {validationOk &&
                <Modal
                  title={<Translate id={'com.tempedge.msg.label.saveglobalparam'} />}
                  content={<Translate id={'com.tempedge.msg.label.confirmsaveglobalparam'} />}
                  open={validationOk}
                  onClose={() => setValidationOk(false)}
                  modalSize={'modal-md'}
                  buttons={bottomsModal}
                />
              }
            </div>
          </>
        }
      </ContainerBlue>
  )
}

export default withLocalize(GlobalParameter);