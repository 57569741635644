import React from "react";
import { connect, useSelector } from "react-redux";
import { withLocalize, Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import "../../../assets/styles/components/ResultBar.css";
import translationsEN from "../../../translations/en.tempedge.json";

const handlerError = (status, code) => {
  if (status < 300) {
    if (code === "TE00") return "success";
    else if (code === "TE-E08") return "warning";
    else return "fail";
  } else {
    return "fail";
  }
};

const OutcomeBar = (props) => {
  const {
    customData = "",
    response = "",
    customMessage = "",
    personMessage = "",
  } = props;
  var lenguageSelect = props.activeLanguage?.name.toLowerCase();
  const translations = useSelector((state) => {
    if (state?.tempEdge?.lang) {
      if (Object.entries(state?.tempEdge?.lang).length > 0) {
        return state?.tempEdge?.lang[lenguageSelect];
      }
    }
    return translationsEN;
  });
  let message = "";

  if (
    (response?.message && response?.message.includes("Request")) ||
    (response?.data?.message && response?.data?.message.includes("Request"))
  ) {
    message = "com.tempedge.error.undefine";
  } else {
    message =
      response?.message && response?.message !== ""
        ? response?.message
        : response?.data?.message;
  }

  const code = response?.data ? response?.data?.code : response?.code;
  const status = response?.data ? response?.data?.status : response?.status;
  let type = handlerError(status, code);

  const findtitle = translations[message];

  return (
    <div className={`announcement-bar ${type}`}>
      <p>
        {code && `${code} - `}
        {personMessage !== "" ? (
          <Translate id={personMessage} options={{ renderInnerHtml: true }} />
        ) : (
          <>
            <Translate
              id={findtitle ? message : "com.tempedge.error.undefine"}
              data={customData}
              options={{ renderInnerHtml: true }}
            />
            {customMessage !== "" && customMessage !== null
              ? " - " + customMessage
              : ""}
          </>
        )}
      </p>
    </div>
  );
};

OutcomeBar.propTypes = {
  customData: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
  }),
};

OutcomeBar.defaultProps = {
  customData: { data: "" },
};

export default withLocalize(connect(null, {})(OutcomeBar));
