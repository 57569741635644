import React from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import './dashboard.css';
import { CardContainer } from './Components/CardContainer';

export const ProfileInfoCard = ({ translate }) => {
    const portalUser = useSelector((state) => {
        return state.tempEdge.login !== "" &&
            state.tempEdge.login.portalUserList.length > 0
            ? state.tempEdge.login.portalUserList[0]
            : null;
    }, shallowEqual);

    return (
        <>
            <CardContainer>
                <h1 className='card-title'> {translate('com.tempedge.msg.label.welcome')} <strong > {portalUser.user.firstName + ' ' + portalUser.user.lastName}</strong> </h1>                
                {portalUser.officeName && (<h3> <strong >{translate('com.tempedge.msg.label.officename')}: </strong> {portalUser.officeName} </h3>)}
                {portalUser.clientName && (<h3> <strong >{translate('com.tempedge.msg.label.client')}: </strong> {portalUser.clientName} </h3>)}
                {portalUser && portalUser.user && (
                    <>
                        <h3 className="font-weight-bold">
                            {translate("com.tempedge.msg.label.lastLogin")}:
                        </h3>
                        <p>{portalUser.user.lastLogin}</p>
                    </>
                )}
            </CardContainer>
        </>
    )
}
