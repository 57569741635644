import React from 'react'
import '../dashboard.css';
import { Card } from 'react-bootstrap';

export const CardContainer = ({children, width, height, paddingBlock = '5px', paddingInline}) => {
  return (
    <>
        <Card className="main-form-panel mx-auto justify-content-center align-items-center" style={{ width, height, paddingBlock, paddingInline }}>
            {children}
        </Card>
    </>
  )
}
