import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import Stepper from 'react-stepper-horizontal';
import WizardUploadFile from './WizardUploadFile';
import WizardConfirmation from './WizardConfirmation';

const ReportUpload = () => {

	const [currentPage, setCurrentPage] = useState(0);
	const [message, setMessage] = useState(null);
	const [data, setData] = useState({});

	const confirmation = (data) => {
		setData(data);
		setCurrentPage(1);
	}

	const reset = (message) => {
		setCurrentPage(0);
		setMessage(message);
		setData({});
	}

	return (
		<>
			<Stepper
				steps={[{ title: '' }, { title: '' }]}
				activeStep={currentPage}
				activeColor="#eb8d34"
				completeColor="#8cb544"
				defaultBarColor="#eb8d34"
				completeBarColor="#8cb544"
				barStyle="solid"
				circleFontSize={16}
			/>
			{currentPage === 0 && <WizardUploadFile message={message} data={data} confirmation={confirmation} />}
			{currentPage === 1 && <WizardConfirmation reset={reset} data={data} setData={setData} />}
		</>
	);

}

export default withLocalize(ReportUpload);
