import moment from 'moment';
import { payrollPersonHistoryType } from '../types/payrollPersonHistoryType';
import TempEdgeApi from '../../services/TempEdgeApi';
import { addHistory } from '../../utils/PayrollUtils';
import { REACT_APP_URL_FIND_PAYROLL_BY_PERSON } from '../../components/common/Routes/URLs';

export const fetchPayrollRequest = () => ({
  type: payrollPersonHistoryType.FETCH_PAYROLL_REQUEST,
});

export const fetchPayrollSuccess = (data) => ({
  type: payrollPersonHistoryType.FETCH_PAYROLL_SUCCESS,
  payload: data,
});

export const fetchPayrollFailure = (error) => ({
  type: payrollPersonHistoryType.FETCH_PAYROLL_FAILURE,
  payload: error,
});

export const fetchPayroll = (dates, user) => async (dispatch) => {
  dispatch(fetchPayrollRequest());

  const startDateFormatted = moment(dates).format('yyyy-MM-DD');
  const request = {
    dateList: startDateFormatted,
    personId: user.id,
  };

  try {
    const { data: res } = await TempEdgeApi.post({
      url: REACT_APP_URL_FIND_PAYROLL_BY_PERSON,
      payload: {
        orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
        ...request,
      },
    });

    if (res.status === 200 && res.code === 'TE00') {
      dispatch(fetchPayrollSuccess(addHistory(res.result)));
    } else {
      dispatch(fetchPayrollFailure(res));
    }
  } catch (error) {
    dispatch(fetchPayrollFailure(new Error(error)));
  }
};
