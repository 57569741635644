import React from 'react';
import ThreeStateSwitch from '../../components/common/ThreeStateSwitch/ThreeStateSwitch';

const EditableCell = (props) => {
  const { name, value, handleOnChange, handleOnBlur, isInvalid } = props;

  return (
    <td>
      {
        name === 'pay' &&
        <input
          type="text"
          className={`form-control tempEdge-input-box ${isInvalid && 'has-error'}`}
          style={{ width: '70px' }}
          name={name}
          onChange={handleOnChange}
          pattern="^(?!0\.00)-{0,1}\d{1,2}(\.\d{1,2})?$"
          maxLength="6"
          onInput={({ target }) => {
            target.value = target.value.replace(/[^-\d.]/g, '');
          }}
          onBlur={handleOnBlur}
          value={value === undefined || value === null ? '' : value}
        />
      }
      {        
        name === 'attendance' &&        
          <ThreeStateSwitch
            name={'Switch'}
            size={'md'}
            currentValue={value}
            onClick={handleOnChange}
          />
      }
    </td>
  );
};

export default EditableCell;
