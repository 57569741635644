import React, { useEffect, useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { Tab, Tabs } from "react-bootstrap";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import { Formik, Form, Field, FieldArray } from "formik";
import TempEdgeApi from "../../../services/TempEdgeApi";
import Loader from "../../../components/common/Loader/Loader";
import { REACT_APP_URL_OFFICE_FIND_ALL } from "../../../components/common/Routes/URLs";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import "../../../assets/styles/components/Tabs.css";

function UpdatePortalUserList({
  translate,
  prev,
  next,
  formik,
  changeFormikValues,
  valuesFormik,
}) {

  const [step, setStep] = useState(0);
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchOffices() {
      try {
        setLoading(true);
        const { data: office } = await TempEdgeApi.post({
          url: REACT_APP_URL_OFFICE_FIND_ALL,
          payload: { page: 0, size: 50, filterBy: {} },
        });
        if (office.status === 200) {
          setOffices(office?.result?.data?.content);
        } else {
          setOffices([]);
        }
      } catch (e) {
        setOffices([]);
      } finally {
        setLoading(false);
      }
    }
    fetchOffices();
  }, []);

  if (loading) return <Loader />;

  return (
    <ContainerBlue
      title={"com.tempedge.msg.title.accountconfiguration"}
      width="720px"
    >
      <Formik
        initialValues={{
          portalUserList: formik.portalUserList,
          token: "",
        }}
        onSubmit={(values) => {
          next();
        }}
      >
        <Form className="p-3 col-12">
          <FieldArray
            name={"portalUserList"}
            render={(arrayHelpers) => (
              <Tabs
                id="controlled-tab-example"
                activeKey={step}
                onSelect={(step) => {
                  setStep(+step);
                }}
                className="mb-3"
              >
                {formik.portalUserList &&
                  formik.portalUserList?.length > 0 &&
                  formik.portalUserList?.map((portal, index) => (
                    <Tab key={index} eventKey={index} title={portal.orgName}>
                     
                      <div className={"tab"}>
                        <div className="form-group row mx-auto">
                          <div className="col-md-8">
                            <label className="control-label top-label-agency-form">
                              {translate("com.tempedge.msg.label.role")}
                            </label>
                            <Field
                              className={`form-control tempEdge-input-box`}
                              name={`portalUserList[${index}].role.description`}
                              type="text"
                              disabled
                              placeholder={translate(
                                "com.tempedge.msg.label.role"
                              )}
                            />
                          </div>
                          <div className="col-md-8">
                            {formik.portalUserList[index]?.role &&
                              formik.portalUserList[index]?.role?.name &&
                              formik.portalUserList[index]?.roles?.name.indexOf(
                                "CLIENT"
                              ) >= 0 && (
                                <Field
                                  name={`portalUserList[${index}].clientId`}
                                  render={({ field }) => (
                                    <DropdownList
                                      valueField={"clientId"}
                                      textField={"name"}
                                      value={field.value}
                                      onChange={field.onChange}
                                      name={field.name}
                                    />
                                  )}
                                />
                              )}
                            {formik.portalUserList[index]?.role &&
                              formik.portalUserList[index]?.role.name &&
                              formik.portalUserList[index]?.role?.name.indexOf(
                                "CLIENT"
                              ) < 0 && (
                                <div className="my-2">
                                  <label className="control-label">
                                    <Translate id="com.tempedge.msg.label.office" />
                                  </label>
                                  <DropdownList
                                    name={`portalUserList[${index}].officeId`}
                                    value={{ officeId: portal.officeId }}
                                    data={offices}
                                    valueField="officeId"
                                    textField="name"
                                    onChange={(value) => {
                                      const updatedList =
                                        formik.portalUserList.map(
                                          (portalUser, idx) => {
                                            if (idx === index) {
                                              return {
                                                ...portalUser,
                                                officeId: value.officeId,
                                              };
                                            }
                                            return portalUser;
                                          }
                                        );

                                      changeFormikValues({
                                        ...valuesFormik,
                                        portalUserList: updatedList,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </Tab>
                  ))}
              </Tabs>
            )}
          />

          <div
            className="prev-next-btns-agency row mt-5"
            style={{ width: "-webkit-fill-available" }}
          >
            <div className="col-md-6 ">
              <button
                type="button"
                className="btn btn-default btn-block register-save-btn previous"
                onClick={() => {
                  prev();
                }}
              >
                <Translate id="com.tempedge.msg.label.back" />
              </button>
            </div>
            <div className="col-md-6">
              <button
                type="submit"
                className="btn btn-primary btn-block register-save-btn next"
              >
                <Translate id="com.tempedge.msg.label.next" />
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </ContainerBlue>
  );
}

export default withLocalize(UpdatePortalUserList);
