import React,{ useEffect} from "react";
import { connect } from "react-redux";
import { validateAgency, validateOrg} from "../../Redux/actions/tempEdgeActions";
import types from "../../Redux/actions/types";

function syncAuth(WrapedComponent) {
  
  function NewComponent(props) {
   
    const {setLogin,logout } = props;

    useEffect(() => {

      async function sync(evt)  {
          if(evt.key === "choose_agency"){
            let path = window.location.pathname.split('/');
            if(path[1] === 'organization-select'){
                let agency = JSON.parse(evt.storageArea.agency);
                validateOrg(agency);
            }
          }
          if (evt.key === "logout") {
            await logout();
          }
          if (evt.key === "sign_in") {
            let login = JSON.parse(evt.storageArea.login);
            setLogin(login);
          }
      }

      const token = window.localStorage.getItem("access_token");
      const user = JSON.parse(window.localStorage.getItem("login"));
      if (token && user){
         setLogin(user);
      }
      window.addEventListener("storage", sync);
      return () => {
        window.removeEventListener("storage", sync);
        window.localStorage.removeItem('logout');
      };
    },[setLogin,logout]);

    return (<WrapedComponent {...props} />);
  }

  const mapStateToProps = (state) => {
    return {
      login: state.tempEdge.login,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      setLogin(user) {
        dispatch({
              type: types.LOGIN,
              payload: user 
        });
        validateAgency(user.portalUserList);
      },
      logout(){
        dispatch({
              type: types.LOGOUT,
              payload: {}
        });
      }
    };
  };

  return connect(mapStateToProps,mapDispatchToProps)(NewComponent);

}

export default syncAuth;
