import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { push } from 'connected-react-router';
import ReactPaginate from 'react-paginate';
import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import TimeSheetRow from './TimeSheetRow';
import {resetEntireState} from '../../Redux/actions/dailyListActions';
import '../../assets/styles/components/Pagination.css';

class TimeSheetTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      perPage: 20,
      currentPage: 0,
      pageCount: null
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    this.changePageCounter();
  }

  componentDidUpdate(prevProps) {
    const { activeLanguage, addTranslationForLanguage, employeesInTimeSheet } = this.props;
    const hasActiveLanguageChanged = prevProps.activeLanguage !== activeLanguage;

    if (hasActiveLanguageChanged) {
      push(`/payroll/dailylist/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
    }
    if (prevProps.employeesInTimeSheet.length !== employeesInTimeSheet.length) {
      this.changePageCounter();
    }
  }

  changePageCounter = () => {
    const { employeesInTimeSheet } = this.props;
    const { perPage } = this.state;
    this.setState({
      pageCount: Math.ceil(employeesInTimeSheet.length / perPage)
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset
    });
  };

  renderTimeSheetRows = () => {
    const { employeesInTimeSheet, positionList, isFindListChecked } = this.props;
    const { offset, perPage } = this.state;
    const slice = employeesInTimeSheet.slice(offset, offset + perPage);
    return slice.map((employee,index) => (
      <TimeSheetRow
        employee={employee}
        key={index}
        positionList={positionList}
        isFindListChecked={isFindListChecked}
      />
    ));
  };

  componentWillUnmount() {
    this.setState(this.baseState)
    const { resetEntireState } = this.props;
    resetEntireState();
  }

  render() {
    const { translate, employeesInTimeSheet } = this.props;
    const { perPage, pageCount, currentPage } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="table-header-left text-center w-5">EE #</th>
                  <th className="table-header-mid text-center w-20">{translate('com.tempedge.msg.label.lastname')}</th>
                  <th className="table-header-mid text-center w-20">{translate('com.tempedge.msg.label.firstname')}</th>
                  <th className="table-header-mid text-center w-30">{translate('com.tempedge.msg.label.deptpos')}</th>
                  <th className="table-header-mid text-center w-5">{translate('com.tempedge.msg.label.pay')}</th>
                  <th className="table-header-mid text-center w-5">{translate('com.tempedge.msg.label.attendance')}</th>
                  <th className="table-header-right text-center w-5">{translate('com.tempedge.msg.label.opt')}</th>
                </tr>
              </thead>
              <tbody>{this.renderTimeSheetRows()}</tbody>
            </table>
          </div>
        </div>

        {employeesInTimeSheet && employeesInTimeSheet.length > perPage - 1 && (
          <ReactPaginate
            previousLabel={translate('com.tempedge.msg.label.previous')}
            nextLabel={translate('com.tempedge.msg.label.next')}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={currentPage}
            onPageChange={this.handlePageClick}
            containerClassName="pagination"
            pageLinkClassName="btn"
            previousLinkClassName="btn-navigation btn-previous"
            nextLinkClassName="btn-navigation btn-next"
            previousClassName=""
            activeLinkClassName="active"
            disabledLinkClassName="disabled"
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  resetEntireState
};

const mapStateToProps = ({ dailyListReducer: { employeesInTimeSheet = [] } }) => ({
  employeesInTimeSheet
});

export default withLocalize(connect(mapStateToProps,mapDispatchToProps)(TimeSheetTable));
