import React from 'react';
import {withLocalize} from "react-localize-redux";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import {connect} from "react-redux";
import { push } from 'connected-react-router';
import FormUser from "./FormUser";

class CreateOrUpdateUser extends React.Component {

    constructor(props){
        super(props);
        //language
        this.addTranslationsForActiveLanguage();
    }

    addTranslationsForActiveLanguage = async () => {
        await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    };

    async componentDidUpdate(prevProps) {
        let hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
        if (hasActiveLanguageChanged && (this.props.user === null || this.props.user === undefined)) {
            let path = this.props.location.pathname;
            path = path.substr(0, path.lastIndexOf('/')) + '/' + this.props.activeLanguage.code;
            await this.props.push(path);
            this.addTranslationsForActiveLanguage();
        }
    }

    render() {
        return <FormUser user={this.props.user} error={this.props.error}/>;
    }

}

export default withLocalize(
    connect(null,{push})(CreateOrUpdateUser)
);
