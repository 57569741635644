import { Translate, withLocalize } from 'react-localize-redux';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import { FieldArray } from 'formik';
import deleteIcon from '../../../components/common/ClientCode/assets/delete.png';
import addIcon from '../../../components/common/ClientCode/assets/plus.png';
import UploadFile from '../../../components/common/UploadFile/UploadFile';
import { limitString } from '../../../utils/ReGexs';
import { typeFile } from '../../../utils/helpers/file';

function FileUpdate(props) {
   const link = (fileNames, files) => {
      const type = typeFile(fileNames);
      return `data:${type};base64,${files}`;
   };

   return (
      <div className={'tab'}>
         <FieldArray
            name={'fileUpdate'}
            render={({ replace, remove, push }) => {
               return (
                  <div>
                     {props.formik.values.fileUpdate && props.formik.values.fileUpdate.length > 0 ? (
                        props.formik.values.fileUpdate.map((client, index) => {
                           return (
                              <div key={index} className="list-item d-flex align-items-center mb-2 pb-4 border-bottom">
                                 <div className="col-md-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.filetype" />
                                    </label>
                                    <DropdownList
                                       name={`fileUpdate.${index}.type`}
                                       data={props.typeFile}
                                       textField="type"
                                       valueField="type"
                                       value={{ type: client.type }}
                                       onChange={(e) => {
                                          const findType = props.formik.values.fileUpdate.some((item) => {
                                             return item.type === e.type;
                                          });
                                          replace(index, {
                                             type: findType ? e.type + index : e.type,
                                             fileNames: client.fileNames,
                                             file: client.file,
                                          });
                                       }}
                                       isInvalid={
                                          props.formik.values.fileUpdate.length > 0 &&
                                          props.formik.errors.fileUpdate &&
                                          props.formik.touched.fileUpdate &&
                                          props.formik.errors.fileUpdate[index]?.name &&
                                          props.formik.touched.fileUpdate[index]?.name
                                       }
                                    />
                                 </div>
                                 <div className="col-md-4">
                                    <label className="control-label">
                                       <Translate id="com.tempedge.msg.label.filename" />
                                    </label>
                                    {typeof props.formik.values.fileUpdate[index].file === 'string' ? (
                                       <span className="mt-2">
                                          <a href={link(client.fileNames, client.file)} download={client.fileNames}>
                                             {limitString({
                                                value: client.fileNames ? client?.fileNames : '',
                                                limit: 25,
                                                replace: '...',
                                             })}
                                          </a>
                                       </span>
                                    ) : (
                                       <input
                                          className={`form-control tempEdge-input-box 
                    ${props.formik.values.fileUpdate.length > 0 && props.formik.errors.fileUpdate && props.formik.touched.fileUpdate && props.formik.errors.fileUpdate[index]?.fileNames && props.formik.touched.fileUpdate[index]?.fileNames && 'has-error'}`}
                                          disabled={true}
                                          value={limitString({
                                             value: client.fileNames ? client?.fileNames : '',
                                             limit: 25,
                                             replace: '...',
                                          })}
                                       />
                                    )}
                                 </div>
                                 <div className="col-md-2">
                                    <UploadFile
                                       accept="application/msword,application/pdf"
                                       onFileSelectSuccess={(files) => {
                                          replace(index, {
                                             type: client.type,
                                             fileNames: files.name,
                                             file: files,
                                          });
                                       }}
                                       onFileSelectError={({ error }) => alert(error)}
                                       fileNames={props.formik.values.fileUpdate[index].fileNames ?? ''}
                                       files={props.formik.values.fileUpdate[index].file}
                                    />
                                 </div>
                                 <div className="col-md-2">
                                    <span
                                       className="pull-right"
                                       title="Remove file"
                                       onClick={() => {
                                          if (client.name || client.fileNames || props.formik.value?.fileUpdate[index]?.fileNames) {
                                             props.formik.values.fileUpdateDeleted.push({
                                                type: client.type,
                                                file: client.file,
                                                fileNames: client.fileNames,
                                             });
                                          }
                                          remove(index);
                                       }}
                                       style={{ paddingRight: '1.3rem' }}
                                    >
                                       <img className="delete-icon-phones" src={deleteIcon} alt="deleteIcon" />
                                    </span>
                                 </div>
                              </div>
                           );
                        })
                     ) : (
                        <div className="mt-5 ml-5">
                           <h2>{props.translate('com.tempedge.msg.label.emptyfile')}</h2>
                        </div>
                     )}
                     <div className="list-item row">
                        <div className="col-md-12 ">
                           <span
                              style={{ paddingRight: '2.2rem' }}
                              className="center-block pull-right add-fieldArray-btn"
                              onClick={() => {
                                 push({ type: '', fileNames: '', file: '' });
                              }}
                           >
                              <img style={{ width: '42px', paddingTop: '12px' }} src={addIcon} alt="addIcon" />
                           </span>
                        </div>
                     </div>
                  </div>
               );
            }}
         />
      </div>
   );
}

export default withLocalize(FileUpdate);
