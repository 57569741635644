import moment from "moment";
import { Translate } from "react-localize-redux";
import useHeaderDate from "../PayrollByWeek/useHeaderDate";
import React from "react";


const SetTableHeadingPayrate = (dateStart) => {

  const {findDate} = useHeaderDate(dateStart);

  return (
    <tr>
      {Object.keys(findDate).map((currentDay,index )=> {
        const day = moment().day(findDate[currentDay].day).format("dddd").toLowerCase()
         return (
          <th key={index} className="table-header-mid text-center w-10">
               <Translate id={`com.tempedge.msg.label.${day}`} />
               <br></br>
              {findDate[currentDay].date ? findDate[currentDay].date : ''}
          </th>
         )
      })}
    </tr>
  );
}


export default SetTableHeadingPayrate;
