import React from 'react';
import ReactDOM from 'react-dom';
import '../../../assets/styles/components/Backdrop.css';

const Backdrop = (props) => {
  const { content, open, onClose } = props;

  const Backdrop = open && (
    <div onClick={() => onClose()} className="backdrop modal">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="backdrop">{content}</div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(Backdrop, document.querySelector('#modal'));
};

export default Backdrop;
