import React from 'react'
import { Carousel } from 'react-bootstrap';
import { CardVideo } from './CardVideo';

export const VideoCarousel = ({showItems}) => {

    const resultArray = slidesData.reduce(
        (result, value, index, array) => {
            if (index % showItems === 0)
                result.push(array.slice(index, index + showItems));
            return result;
        }, []);

    return (
        <Carousel
            slide={false}
            indicators={false}
            interval={5000}
            prevIcon={<div className='arrow-carousel small-text' > <div>{'<'}</div> </div>}
            nextIcon={<div className='arrow-carousel arrow-carousel-right small-text'> <div>{'>'}</div> </div>}
        >            
            {resultArray.map((pairArray, index) =>            
                <Carousel.Item key={index}>
                    <div className='carousel-container'>
                        {pairArray.map((video, innerIndex) => {
                            return (
                                <CardVideo title={video.title} id={video.id} key={innerIndex}/>
                            );
                        })}
                    </div>
                </Carousel.Item>                
            )}            
        </Carousel>
    );
};

const slidesData = [
    { id: 'HknzsUPasMk', title: 'How to Log In' },
    { id: '3DPIaPxeVNY', title: 'How to Add an Employee' },
    { id: 'SXdQamiH--I', title: 'How To Add an Employee with Temporary Data' },
    { id: 'uM4sk5AP9lc', title: 'How To Edit an Employee with Temporary Data' },
    { id: 'vYv3klHHLM8', title: 'How To Search an Employee' },
    { id: 'tn4Hd5LPckA', title: 'How to View an Employees SSN' },
    { id: 'yXFbatfKwKM', title: 'How to Activate a Terminated Employee' },
    { id: 'GUc2a-75D7M', title: 'How to Create a User' },
    { id: 'stzv-70pTRM', title: 'How to Approve User' },
    { id: 'fjF39cS7QFE', title: 'Time Entry: How to Add Employee Hours by Week' },
    { id: 'OV-dCXtCYBA', title: 'Time Entry: How to Add Employee Hours by Day' },
    { id: 'KoA4dHkvXY0', title: 'How to Create Temporary Data' },
    { id: 'FEEE6oglOUM', title: 'How to Add a Company' },
    /*{ id: 'CL5K5SmBjRk', title: 'How to Add An Internal Employee' },
    /*{ id: 'O68Y5wwfeq0', title: 'Como iniciar sesión' },
    { id: 'FOYGQhRlSHI', title: 'Como agregar empleado nuevo' },
    { id: 'B0bOfrRs2XA', title: 'Como agregar un empleado con información temporal' },
    { id: 'LMJM91sbjeo', title: 'Como editar un empleado con información temporal' },
    { id: '4TKcIiAn0hk', title: 'Como buscar un empleado' },
    { id: 'Wm-xEXbXeEk', title: 'Como ver SSN de un empleado' },
    { id: 'KObJVQzfTI4', title: 'Como activar empleado terminado' },
    { id: 'wHTMaPMxEMw', title: 'Como crear un usuario' },
    { id: 'Q7fztYK-KfU', title: 'Como aprobar un usuario' },
    { id: '8OAmPsOw7wQ', title: 'Añadir Compañía' },
    { id: 'GnT-Co3syEk', title: 'Crear Empleado Interno' },*/
];