import React, { useMemo, useState, useEffect, useRef } from "react";
import { withLocalize } from "react-localize-redux";
import { normalizeDate, normalizeDateSend, normalizeDateSendReports } from "../../utils/ReGexs";
import Loader from "../../components/common/Loader/Loader";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import OutcomeBar from "../../components/common/OutcomeBar";
import BlueCommonBtn from "../../components/common/Buttons/BlueCommonBtn";
import GrayCommonBtn from "../../components/common/Buttons/GrayCommonBtn";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import Switch from "../../components/common/Switch/Switch";
import { Formik } from "formik";
import { SecondSchema, initialValues } from "./Schema";
import TempEdgeApi from "../../services/TempEdgeApi";
import moment from "moment";
import Datepicker from "../../components/common/Datepicker/Datepicker";
import {
  REACT_APP_URL_CUSTOM_REPORT_GENERATE,
  REACT_APP_URL_SAVE_USER_CONF_REPORT,
} from "../../components/common/Routes/URLs";

const formatDate = "YYYY-MM-DD";
const selectThisWeek = "THISWEEK";
const selectLastWeek = "LASTWEEK";
const selectCustomWeek = "CUSTOM";

const LastFiltersReport = ({ formData, prevStep, reset, translate }) => {
  const today = moment();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [message, setMessage] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const formikRef = useRef();

  useEffect(() => {
    formikRef.current.setValues({
      ...formikRef.current.values,
      weekDay: selectLastWeek,
      startDate: formData.weekDates.lastWeekInit,
      endDate: formData.weekDates.lastWeekEnd,
    });
  }, [formData.weekDates.lastWeekEnd, formData.weekDates.lastWeekInit]);

  const weekdayOptions = formData.company.clientId
    ? [
        {
          label: `FROM ${normalizeDate(
            formData.weekDates.lastWeekInit
          )} TO ${normalizeDate(formData.weekDates.lastWeekEnd)}`,
          value: selectLastWeek,
        },
        {
          label: `FROM ${normalizeDate(
            formData.weekDates.thisWeekInit
          )} TO ${normalizeDate(formData.weekDates.thisWeekEnd)}`,
          value: selectThisWeek,
        },
        {
          label: translate("com.tempedge.msg.label.custom"),
          value: selectCustomWeek,
        },
      ]
    : [];

  useEffect(() => {
    if (formData.company.departments) {
      const modifiedDepartments = [...formData.company.departments];
      const allExists = modifiedDepartments.some((dep) => dep?.name === "All");
      if (!allExists) {
        modifiedDepartments.unshift({ departmentId: null, name: "All" });
      }

      if (
        allExists &&
        modifiedDepartments.length > 1 &&
        modifiedDepartments[0]?.name === "All"
      ) {
        modifiedDepartments.shift();
      }

      setDepartments(modifiedDepartments);
    }
  }, [formData.company.departments]);

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const requestSave = saveReportRequest(values);
      const saveResponse = await saveReportConfigurations(requestSave);

      if (
        saveResponse.status === 200 &&
        (saveResponse.code === "TE00" || saveResponse.code === "TE-E08")
      ) {
        const request = buildReportRequest(values);
        const response = await sendReportRequest(request);

        if (
          response.status === 200 &&
          (response.code === "TE00" || response.code === "TE-E08")
        ) {
          const name = `${
            formData.company.clientName
          }_${formData.reportType.toUpperCase()}`;
          downloadFile(response.result, name);
          setData(values);
          reset(response);
        } else {
          setData(values);
          setMessage(response);
        }
      } else {
        setData(values);
        setMessage(saveResponse);
      }
    } catch (error) {
      setData(values);
      setMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const sendReportRequest = async (request) => {
    try {
      const response = await TempEdgeApi.post({
        url: REACT_APP_URL_CUSTOM_REPORT_GENERATE,
        payload: request,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const saveReportConfigurations = async (request) => {
    try {
      const response = await TempEdgeApi.post({
        url: REACT_APP_URL_SAVE_USER_CONF_REPORT,
        payload: request,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  function downloadFile(base64, name) {
    if (typeof base64 === "string") {
      const base64URL = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
      const link = document.createElement("a");
      link.setAttribute("href", base64URL);
      link.setAttribute(
        "download",
        `${name}_${moment(today).format("YYYY-MM-DD-HH-mm")}.xlsx`
      );
      link.click();
    }
  }

  const saveReportRequest = (values) => {
    const newCustomReport = [];

    formData.customReport.forEach((dato) => {
      newCustomReport.push({
        columnOrder: dato.columnOrder || dato.reportParameter.columnOrder,
        reportParameter: {
          reportParameterId: dato.reportParameter.reportParameterId,
        },
      });
    });

    let startDateWeek = moment(values.startDate).format(formatDate);
    let endDateWeek = moment(values.endDate).format(formatDate);

    if (values.weekDay !== selectCustomWeek) {
      startDateWeek =
        values.weekDay === selectThisWeek
          ? formData.weekDates.thisWeekInit
          : formData.weekDates.lastWeekInit;
      endDateWeek =
        values.weekDay === selectThisWeek
          ? formData.weekDates.thisWeekEnd
          : formData.weekDates.lastWeekEnd;
    }
    return {
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      startDate: normalizeDateSendReports(startDateWeek),
      endDate: normalizeDateSendReports(endDateWeek),
      clientId: formData.company.clientId,
      configList: newCustomReport,
      reportType:
        formData.reportType === "payroll"
          ? "com.tempedge.msg.report.payroll"
          : "com.tempedge.msg.report.invoice",
    };
  };

  const buildReportRequest = (values) => {
    let startDateWeek = moment(values.startDate).format(formatDate);
    let endDateWeek = moment(values.endDate).format(formatDate);

    if (values.weekDay !== selectCustomWeek) {
      startDateWeek =
        values.weekDay === selectThisWeek
          ? formData.weekDates.thisWeekInit
          : formData.weekDates.lastWeekInit;
      endDateWeek =
        values.weekDay === selectThisWeek
          ? formData.weekDates.thisWeekEnd
          : formData.weekDates.lastWeekEnd;
    }
    return {
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      startDate: normalizeDateSendReports(startDateWeek),
      endDate: normalizeDateSendReports(endDateWeek),
      departmentId: values.department?.departmentId,
      positionId: values.position?.positionId,
      clientId: formData.company.clientId,
      shift: values.shift?.shiftId,
      reportByDept: values.filter.reportByDept,
      reportByPosition: values.filter.reportByPosition,
      reportType:
        formData.reportType === "payroll"
          ? "com.tempedge.msg.report.payroll"
          : "com.tempedge.msg.report.invoice",
      showTotals: values.filter.showTotals,
      separateCustomPayrolls: values.filter.separateCustomPayroll,
    };
  };

  const btns = useMemo(() => {
    return (
      <div className="prev-next-btns-agency row" style={{ marginTop: 30 }}>
        <div className="col-md-4">
          <GrayCommonBtn
            tag="com.tempedge.msg.label.cancel"
            onClick={() => {
              reset();
            }}
          />
        </div>
        <div className="col-md-4">
          <GrayCommonBtn
            tag="com.tempedge.msg.label.back"
            onClick={() => {
              prevStep();
            }}
          />
        </div>
        <div className="col-md-4">
          <BlueCommonBtn
            tag="com.tempedge.msg.label.download"
            onClick={() => {
              formikRef.current.handleSubmit();
            }}
          />
        </div>
      </div>
    );
  }, [prevStep, reset]);

  if (loading) return <Loader />;

  return (
    <>
      <ContainerBlue title="com.tempedge.msg.title.customreport" btns={btns}>
        {message && <OutcomeBar response={message} />}
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={data || initialValues}
          validationSchema={SecondSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(formik) => (
            <div className="row p-2">
              <div className="col-12 col-md-4 filters">
                <div className="green-text font-weight-bold text-uppercase mb-3">
                  {translate("com.tempedge.msg.label.filters")}
                </div>
                {/* company select */}
                <div className="form-group mt-2">
                  <label className="text-left label-p">
                    {translate("com.tempedge.msg.label.orgdepartment")}
                  </label>
                  <DropdownList
                    data={departments}
                    textField="name"
                    valueField="departmentId"
                    value={formik.values.department}
                    onChange={(department) => {
                      formik.setFieldValue("department", department);
                      formik.setFieldValue("position", null);
                      setMessage(null);
                      setPositions(department.positions);
                    }}
                  />
                </div>
                {/* position select */}
                <div className="form-group">
                  <label className="text-left label-position">
                    {translate("com.tempedge.msg.label.position")}
                  </label>
                  <DropdownList
                    data={positions}
                    textField="name"
                    valueField="positionId"
                    value={formik.values.position}
                    onChange={(position) => {
                      formik.setFieldValue("position", position);
                      setMessage(null);
                    }}
                  />
                </div>
                {/* shift */}
                <div className="form-group">
                  <label className="text-left label-p">
                    {translate("com.tempedge.msg.label.shift")}
                  </label>
                  <DropdownList
                    data={[
                      {
                        shiftName: translate("com.tempedge.msg.label.1st"),
                        shiftId: 1,
                      },
                      {
                        shiftName: translate("com.tempedge.msg.label.2nd"),
                        shiftId: 2,
                      },
                      {
                        shiftName: translate("com.tempedge.msg.label.3rd"),
                        shiftId: 3,
                      },
                    ]}
                    textField="shiftName"
                    valueField="shiftId"
                    value={formik.values.shift}
                    onChange={(shift) => {
                      formik.setFieldValue("shift", shift);
                      setMessage(null);
                    }}
                  />
                </div>
              </div>

              {/* separatedBy */}
              <div className="col-12 col-md-4 options">
                {/* select pay period */}
                <div className="form-grou mt-5">
                  <div className="form-group mt-3">
                    <label className="text-left label-p">
                      {translate("com.tempedge.msg.label.selectweek")}
                    </label>
                    <DropdownList
                      data={weekdayOptions}
                      textField="label"
                      valueField="value"
                      value={{ value: formik.values.weekDay }}
                      onChange={({ value }) => {
                        formik.setFieldValue("weekDay", value);
                      }}
                    />
                  </div>

                  {formik.values.weekDay === selectCustomWeek && (
                    <div className="row mt-3">
                      <div className="col-12 col-md-6">
                        <label className="text-left label-p mb-2">
                          {translate("com.tempedge.msg.label.from")}
                        </label>
                        <Datepicker
                          customClass="form-control tempEdge-input-box"
                          selectedValue={formik.values.startDate}
                          onChange={(date) => {
                            formik.setFieldValue(
                              "startDate",
                              moment(date, formatDate)
                            );
                            setMessage(null);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="text-left label-p mb-2">
                          {translate("com.tempedge.msg.label.to")}
                        </label>
                        <Datepicker
                          customClass="form-control tempEdge-input-box"
                          selectedValue={formik.values.endDate}
                          onChange={(date) => {
                            formik.setFieldValue(
                              "endDate",
                              moment(date, formatDate)
                            );
                            setMessage(null);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-4 filters">
                <div className="green-text font-weight-bold text-uppercase ">
                  {translate("com.tempedge.msg.label.separatedby")}
                </div>
                {/* department */}
                <div className="form-group mt-2">
                  <Switch
                    customClass="font-weight-bold text-uppercase"
                    name="separatedByDept"
                    size={"md"}
                    align={"between"}
                    checked={
                      formik.values.filter && formik.values.filter.reportByDept
                    }
                    onChange={(value) => {
                      formik.setFieldValue("filter", {
                        ...formik.values.filter,
                        reportByDept: value,
                        reportByPosition: false,
                      });
                      setMessage(null);
                    }}
                  >
                    <label className="text-left label-p">
                      {translate("com.tempedge.msg.label.orgdepartment")}
                    </label>
                  </Switch>
                </div>
                {/* positions */}
                {formik.values.filter && formik.values.filter.reportByDept && (
                  <div className="form-group">
                    <Switch
                      name="separatedByPosition"
                      size={"md"}
                      align={"between"}
                      checked={
                        formik.values.filter &&
                        formik.values.filter.reportByPosition
                      }
                      onChange={(value) => {
                        formik.setFieldValue("filter", {
                          ...formik.values.filter,
                          reportByPosition: value,
                        });
                        setMessage(null);
                      }}
                    >
                      <label className="text-left label-p">
                        {translate("com.tempedge.msg.label.position")}
                      </label>
                    </Switch>
                  </div>
                )}
                {
                  <div className="form-group">
                    <Switch
                      name="separateCustomPayroll"
                      size={"md"}
                      align={"between"}
                      checked={
                        formik.values.filter &&
                        formik.values.filter.separateCustomPayroll
                      }
                      onChange={(value) => {
                        formik.setFieldValue("filter", {
                          ...formik.values.filter,
                          separateCustomPayroll: value,
                        });
                        setMessage(null);
                      }}
                    >
                      <label className="text-left label-p">
                        {translate(
                          "com.tempedge.msg.label.separatecustompayroll"
                        )}
                      </label>
                    </Switch>
                  </div>
                }
                {
                  <div className="form-group">
                    <Switch
                      name="showTotals"
                      size={"md"}
                      align={"between"}
                      checked={
                        formik.values.filter && formik.values.filter.showTotals
                      }
                      onChange={(value) => {
                        formik.setFieldValue("filter", {
                          ...formik.values.filter,
                          showTotals: value,
                        });
                        setMessage(null);
                      }}
                    >
                      <label className="text-left label-p">
                        {translate("com.tempedge.msg.label.showtotals")}
                      </label>
                    </Switch>
                  </div>
                }
              </div>
            </div>
          )}
        </Formik>
      </ContainerBlue>
    </>
  );
};

export default withLocalize(LastFiltersReport);
