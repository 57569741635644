import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import GrayCommonBtn from "../../components/common/Buttons/GrayCommonBtn";
import BlueCommonBtn from "../../components/common/Buttons/BlueCommonBtn";
import CancelBtn from "../../components/common/Buttons/CancelBtn";

const BottonsNavigation = ({ page, setPage, onCancel }) => {
  const { validateForm, setTouched, touched, submitForm } = useFormikContext();

  const sections = ["information", "contact", "skills", "misc"];

  const handleNextPage = async () => {
    const currentSection = sections[page - 1];
    const errors = await validateForm();

    const sectionErrors = Object.keys(errors).filter((key) =>
      key.startsWith(currentSection)
    );

    if (sectionErrors.length === 0) {
      if (page === 4) {
        submitForm();
      } else {
        setPage((prevPage) => prevPage + 1);
      }
    } else {
      setTouched({
        ...touched,
        [currentSection]: true,
      });
    }
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  return (
    <Row className="py-2">
      <Col
        sm={12}
        md={page === 1 ? 5 : 4}
        className={page === 1 ? "offset-lg-1 mt-2" : "mt-2"}
      >
        <CancelBtn />
      </Col>
      {page > 1 && (
        <Col sm={12} md={4} className="mt-2">
          <GrayCommonBtn
            tag={page > 1 && "com.tempedge.msg.label.back"}
            onClick={handlePreviousPage}
          />
        </Col>
      )}
      <Col sm={12} md={page === 1 ? 5 : 4} className="mt-2">
        <BlueCommonBtn
          tag={
            page === 4
              ? "com.tempedge.msg.label.save"
              : "com.tempedge.msg.label.next"
          }
          type={"button"}
          onClick={handleNextPage}
        />
      </Col>
    </Row>
  );
};

export default BottonsNavigation;
