import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputBox from '../../../components/common/InputBox/InputBox.js';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import Validate from '../../Validations/Validations';
import { normalizePhone } from '../../../utils/ReGexs.js';

class WizardCreateNewClientThirdPage extends Component {
  constructor(props) {
    super(props);

    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  }

  state = { mounted: false, resultBar: '' };

  componentDidMount() {
    this.setState(() => ({
      mounted: true
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      let path = this.props.location.pathname.split('/');
      this.props.push(`/client/${path[2]}/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }

  }

  UNSAFE_componentWillReceiveProps = () => {
    if (this.props.resultBar !== "") {
      this.setState(() => ({
        resultBar: this.props.resultBar
      }));
    }
  };

  render() {
    return (
      <div className="sign-up-wrapper" style={{ margin: 0 }} ref="createNewUser1">
        <h2 className="text-center page-title-new-client">
          {!this.props.editClient ? <Translate id="com.tempedge.client.title.createclient"></Translate> :
            <Translate id="com.tempedge.client.title.updateclient"></Translate>
          }
        </h2>
        <div className="row" style={{ padding: '0 2.2rem' }}>
          <div className="col-12">{this.props.resultBar}</div>
        </div>
        <form onSubmit={this.props.handleSubmit} className="form-horizontal center-block panel-body" style={{ paddingBottom: '0px' }}>
          <div className="row new-client-form">
            <div className="col-lg-8 client-col">
              <div className="create-client">
                <div className="new-client-header">
                  <h2>
                    {<Translate id="com.tempedge.msg.label.information"></Translate>}
                  </h2>
                </div>
                <div className="new-clients-contents">
                  <div className="client-contents">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.lastname"></Translate>
                        </label>
                        <Field
                          name="clientlastName"
                          type="text"
                          category="client"
                          maxlength="30"
                          onInput={({ target }) => (target.value = target.value.replace(/[^A-z À-ú]+/g, ''))}
                          component={InputBox}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.firstname"></Translate>
                        </label>
                        <Field
                          name="clientfirstName"
                          type="text"
                          category="client"
                          maxlength="20"
                          onInput={({ target }) => (target.value = target.value.replace(/[^A-z À-ú]+/g, ''))}
                          component={InputBox}
                        />
                      </div>
                    </div>

                    <div className="form-group row bottom-row">
                      <div className="col-md-6">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.clientcontactphone"></Translate>
                        </label>
                        <Field name="clientcontactphone" type="text" category="client" normalize={normalizePhone} component={InputBox} />
                      </div>
                      <div className="col-md-6">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.clientcontactcellphone"></Translate>
                        </label>
                        <Field name="clientcontactcellphone" type="text" category="client" normalize={normalizePhone} component={InputBox} />
                      </div>
                    </div>

                    <div className="new-clients-footer">
                      <div className="prev-next-btns-agency row">
                        <div className="col-md-5 offset-md-1">
                          <button type="button" className="btn btn-default btn-block register-save-btn previous" onClick={this.props.previousPage}>
                            <Translate id="com.tempedge.msg.label.back"></Translate>
                          </button>
                        </div>
                        <div className="col-md-5">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block register-save-btn next"
                            disabled={this.props.invalid}
                          >
                            <Translate id="com.tempedge.msg.label.next"></Translate>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 dept-col">
              <div className="department-list">
                <div className="department-list-header">
                  <h2>
                    <Translate id="com.tempedge.msg.label.departmentlist" />
                  </h2>
                </div>
                <div className="department-list-contents">
                  <div>
                    {!this.props.renderAddBtnDirty ? (
                      this.props.renderAddBtn()
                    ) : (
                      <div>
                        {this.props.departmentList}
                        {this.props.addDeptBtn}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

WizardCreateNewClientThirdPage = reduxForm({
  form: 'CreateNewClient', //                 <------ form name
  destroyOnUnmount: false, //        <------ preserve form data
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: Validate
})(WizardCreateNewClientThirdPage);

export default withLocalize(connect(null, { push })(WizardCreateNewClientThirdPage));
