import moment from "moment";
import React, { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { moveEmployeeToAbsentWeekTable, totalEmployeeWeekTableExcel } from '../../../Redux/actions/timeEntryActions';
import { TotalHourByWeekDay, TotalOtHourWeek, TotalRegHourWeek, roundToTwoDecimals, totalHoursByConfig } from '../../../utils/helpers/TotalHoursFormat';
import { ExportExcel } from "../PayrollToExcel";
import SetTableHeading from "./SetTableHeading";
import useHeaderDate from "./useHeaderDate";
import { formatUsNumber} from "../../../utils/ReGexs";

const PayrollWeekResult = ({ employeesTimeEntry, dateStart, translate,clientReportConf }) => {

  const dispatch = useDispatch();
  const { findDate } = useHeaderDate(dateStart);

  useEffect(() => {
    const {data,totals}=ExportExcel(findDate, employeesTimeEntry, translate)
      // aquí le paso la data de payroll  a un arreglo en el store para consumirla solo en el excel de semana del timeentry falta cambiar nombre porque no corresponde y validar que no existan valores en 0 se envia null o vacio
  dispatch(moveEmployeeToAbsentWeekTable(data))
  dispatch(totalEmployeeWeekTableExcel(totals))
  }, [findDate, translate, employeesTimeEntry,dispatch])

  // utilizo object.values para mirar la data con horas totales mayor a 0 para iterar un nuevo arreglo con empleados que trabajaron
  const weekResults = Object.values(employeesTimeEntry).filter(value => (value != null) && (value?.totalRegHours !== 0 || value?.totalOTHours !== 0))

  return weekResults.length > 0 &&
    <>
      <div className="form-group col-12 col-lg-3" >
        <p>
          <Translate id={'com.tempedge.msg.label.listsize'} />
          <span className="badge">{weekResults.length}</span>
        </p>
      </div>
      <div className="row">
        <div className="form-check col-12 col-lg-12">
          <table className="table table-striped table-input-group" id="WeekResult">

            <thead>{SetTableHeading(dateStart)}</thead>
            <tbody>
              <>
                {weekResults.map((e, index) => {
                  return (
                    <tr key={index + 1}>
                      <td>{`${e?.person?.fullName}`}</td>
                      <td>{e.department !== null ? `${e.department?.name}-${e.position?.name}` : e.position?.name}</td>
                      {Object.keys(findDate).map((currentDay, index) => {
                    const payrollDay = e[findDate[currentDay].day];

                    if (
                      payrollDay &&
                      payrollDay.dateList &&
                      moment(payrollDay.dateList).format("MM/DD/YYYY") ===
                      findDate[currentDay].date &&
                      (payrollDay.totalRegHour !== null || payrollDay.totalOtHour !== null)
                    ) {
                      return (
                        <td
                          key={index + 1}
                          className="text-center"
                          style={{ fontSize: "12px" }}
                        >
                          {payrollDay.totalRegHour !== null ||
                            payrollDay.totalOtHour !== null
                            ? `RG: ${roundToTwoDecimals(payrollDay?.totalRegHour) ?? '0.00'} - ${roundToTwoDecimals(payrollDay?.totalOtHour) ?? '0.00'
                            } : OT`
                            : null}
                        </td>
                      );
                    } else {
                      return <td key={index + 1}></td>;
                    }
                  })}
                      <td className="text-center">{e.totalRegHours ? totalHoursByConfig(e.totalRegHours,clientReportConf) : '0.00'}</td>
                      <td className="text-center">{e.totalOTHours ? formatUsNumber(e.totalOTHours) : '0.00'}</td>
                    </tr>
                  )
                })}
                <tr className="tableRow text-center">
                  <td colSpan="2" className="table-content text-uppercase text-right">
                    <Translate id={'com.tempedge.msg.label.total'} />
                  </td>
                  {
                    Object.keys(findDate).map((currentDay, index) => {
                      return (
                        <td key={index + 1} className="table-content">
                          {TotalHourByWeekDay(employeesTimeEntry, findDate[currentDay].day)}
                        </td>
                      );
                    })
                  }
                  <td className="table-content">{TotalRegHourWeek(employeesTimeEntry)}</td>
                  <td className="table-content">{TotalOtHourWeek(employeesTimeEntry)}</td>
                </tr>
              </>
            </tbody>
          </table>
        </div>
      </div>
    </>
    ;
}

export default PayrollWeekResult



