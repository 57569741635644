import React from "react";
import { withLocalize } from "react-localize-redux";
import { useDispatch } from "react-redux";
import useTranslateCode from "../../Hooks/UseTranslateCode";
import "../../assets/styles/components/Dashboard.css";
import useFetchClientList from "./Hooks/useFetchClientList";
import usePortalUser from "./Hooks/usePortalUser";
import { DashboardAdmin } from "./Admin/DashboardAdmin";
import { DashboardPayroll } from "./Payroll/DashboardPayroll";
import { DashboardOffice } from "./Office/DashboardOffice";
import { DashboardClient } from "./Client/DashboardClient";
import { CommonDashboard } from "./Common/CommonDashboard";
import useFetchCountryList from "../../Hooks/useFetchCountryList";

const Dashboard = ({ location, activeLanguage, translate }) => {
  useTranslateCode({ location, activeLanguage });
  const dispatch = useDispatch();
  const { role } = usePortalUser();
  const orgId = JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId;
  useFetchClientList(orgId, dispatch);
  useFetchCountryList();

  return (
    <>
      {
        role && (
          (role === 'ADMIN' || role === 'TE_ADMIN' || role === 'OWNER' || role === 'ORGANIZATION_ADMIN') ?
          <DashboardAdmin translate={translate}/> :
          role === 'PAYROLL' ?
          <DashboardPayroll translate={translate}/> :
          role === 'RECRUITING_OFFICE' ?
          <DashboardOffice translate={translate} /> :
          role === 'CLIENT' || role === 'CLIENT_PAYROLL' ?
          <DashboardClient translate={translate}/> :
          <CommonDashboard translate={translate}/>
        )
      }
    </>
  );
}

export default withLocalize(Dashboard);
