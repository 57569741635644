import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import plus from '../../../assets/icons/plus.png';
import { moveEmployeeToTimeEntryTable } from '../../../Redux/actions/timeEntryActions';

const AbsentPayrollForDay = ({ translate, absentEmployees, moveEmployeeToTimeEntryTable }) => {

  const removeFromAbsentTable = (employee) => {
    moveEmployeeToTimeEntryTable(employee);
  };

  return (
    <>
    <div className="row">
      <div className="form-check col-12 col-lg-12">
        <p>
          {translate('com.tempedge.msg.label.listsize')}
                :
          <span className="badge">{absentEmployees.length}</span>
        </p>
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="table-header-left text-center w-15">
                {translate('com.tempedge.msg.label.lastname')}
              </th>
              <th className="table-header-mid text-center w-15">
                {translate('com.tempedge.msg.label.firstname')}
              </th>
              <th className="table-header-mid text-center w-20">
                {translate('com.tempedge.msg.label.orgdepartment')}
              </th>
              <th className="table-header-mid text-center w-10">
                {translate('com.tempedge.msg.label.position')}
              </th>
              <th className="table-header-right text-center w-5">
                {translate('com.tempedge.msg.label.backtopayroll')}
              </th>
            </tr>
          </thead>
          <tbody>
            {absentEmployees.map((employee,index) => (
              <tr key={index+1}>
                <td className="text-center">{employee?.lastName}</td>
                <td className="text-center">{employee?.firstName}</td>
                <td className="text-center">{employee?.position.departmentName}</td>
                <td className="text-center">{employee?.position.positionName}</td>
                <td className="text-center">
                  <button type="button" disabled={employee?.lockPayroll} className="btn" onClick={() => removeFromAbsentTable(employee)}>
                    <img src={plus} alt="Delete icon" style={{width: '30px'}} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>  
    </>
  );
};
const mapStateToProps = ({ timeEntryReducer }) => ({
  absentEmployees: timeEntryReducer.absentEmployees
});
const mapDispatchToProps = {
  moveEmployeeToTimeEntryTable
};
export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(AbsentPayrollForDay));
