import { 
TIME_OUT_TO_NOTIFY_IN_MILISECONDS  
} from "../../components/common/Constants/Constants";

export const getSecondsRemaining = () => {
 
  let lastActiveTime = window.localStorage.getItem('lastActiveTime');

  if(lastActiveTime === null){
    window.localStorage.setItem('lastActiveTime',Math.floor(new Date().getTime() / 1000));
    lastActiveTime =  localStorage.getItem('lastActiveTime');
  }

  const secondsSincelastActiveTime = Number(Math.floor(new Date().getTime() / 1000) - lastActiveTime);
  return Number((TIME_OUT_TO_NOTIFY_IN_MILISECONDS / 1000) - secondsSincelastActiveTime);

};


export const getTimeElapsedSinceTheLastRefresh = () => {
   let lastRefresh = window.localStorage.getItem('lastRefresh');
   return Number(Math.floor(new Date().getTime() / 1000) - lastRefresh);
}