import React from 'react'
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from 'recharts';

export const RadarChartCustom = ({ translate, title, data, dataKey }) => {

    return (
        <>
            <h1> {translate(title)} </h1>
            <ResponsiveContainer width="100%" height="80%">
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey={dataKey} />
                    <PolarRadiusAxis />
                    <Tooltip />
                    <Radar name={translate("com.tempedge.msg.label.lastLogin")} dataKey="count" stroke="#008000" fill="#44D9AF" fillOpacity={0.6} isAnimationActive={false}/>
                </RadarChart>
            </ResponsiveContainer>
        </>
    )
}