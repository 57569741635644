export const customPayrollTypes = {
  ADD_EMPLOYEE_IN_CUSTOMPAYROLL: 'ADD_EMPLOYEE_IN_CUSTOMPAYROLL',
  REMOVE_EMPLOYEE_IN_CUSTOMPAYROLL:'REMOVE_EMPLOYEE_IN_CUSTOMPAYROLL',
  RESET_ENTIRE_STATE:'RESET_ENTIRE_STATE',
  CHANGE_EMPLOYEE_VALUE:'CHANGE_EMPLOYEE_VALUE',
  ADD_CUSTOM_PAYROLL:'ADD_CUSTOM_PAYROLL',
  REMOVE_VALUE_CUSTOM_PAYROLL:'REMOVE_VALUE_CUSTOM_PAYROLL',
  DELETE_VALUE_CUSTOM_PAYROLL:' DELETE_VALUE_CUSTOM_PAYROLL',
  DELETE_CUSTOM_PAYROLL:' DELETE_CUSTOM_PAYROLL',
  ADD_SAME_DATA_CUSTOM_PAYROLL: 'ADD_SAME_DATA_CUSTOM_PAYROLL'
}