import React, { useEffect } from 'react';
import DropdownList from "../../../components/common/Dropdown/DropdownList.js"; 
import { Translate, withLocalize } from 'react-localize-redux';
import { Field } from 'redux-form';
import InputBox from '../InputBox/InputBox';
import deleteIcon from './assets/delete.png'; 
import addIcon from './assets/plus.png';
import { normalizePhone } from '../../../utils/ReGexs.js';

const RenderSalesPersonInputs = (formProps) => { 
  
    useEffect(() => {
        if (formProps.fields.length < 1) {
            formProps.fields.push({});
        }
    },[formProps]);

      
    const genders = [
      { value: 0, label: formProps.translate('com.tempedge.msg.label.gender.male') },      
      { value: 1, label: formProps.translate('com.tempedge.msg.label.gender.female') },
      { value: 2, label: formProps.translate("com.tempedge.msg.label.select")}
    ];
  

    let block = formProps.fields.map((salesPerson, index) => (
      <div key={index} className="row mb-2 pb-4 border-bottom">
        <div className="col-md-5">
            <label className="control-label">
              <Translate id="com.tempedge.msg.label.firstname" />
            </label>
            <Field
              name={`${salesPerson}.salespersonfirstname`}
              type="text"
              placeholder={formProps.translate('com.tempedge.msg.label.firstname')}
              index={index}
              label={formProps.translate('com.tempedge.msg.label.firstname')}
              fields={formProps.fields}
              onInput={({ target }) => (target.value = target.value.replace(/[^a-zA-ZÀ-ú]+/g, ''))}
              maxlength="30"
              component={InputBox}
            />
        </div>
        <div className="col-md-5">
            <label className="control-label">
              <Translate id="com.tempedge.msg.label.lastname" />
            </label>
            <Field
              name={`${salesPerson}.salespersonlastname`}
              type="text"
              placeholder={formProps.translate('com.tempedge.msg.label.lastname')}
              index={index}
              label={formProps.translate('com.tempedge.msg.label.lastname')}
              fields={formProps.fields}
              onInput={({ target }) => (target.value = target.value.replace(/[^a-zA-ZÀ-ú]+/g, ''))}
              maxlength="30"
              component={InputBox}
            />
        </div>
        <div className="col-10 col-md-5 col-lg-5">
            <label className="control-label">
              <Translate id="com.tempedge.msg.label.gender" />
            </label>
            <Field
                  id="genderDropdown"
                  name={`${salesPerson}.salespersongenre`}
                  document="CreateNewAgency"
                  data={genders}
                  requireTranslate
                  valueField="value"
                  textField="label"
                  category="person"
                  component={DropdownList}
                />
        </div>
        <div className="col-md-5">
            <label className="control-label">
              <Translate id="com.tempedge.msg.label.clientcontactphone" />
            </label>
            <Field
              name={`${salesPerson}.salespersonphonenumber`}
              type="text"
              placeholder="xxx-xxx-xxxx"
              index={index}
              label={formProps.translate('com.tempedge.msg.label.clientcontactphone')}
              fields={formProps.fields}
              component={InputBox}
              normalize={normalizePhone}
            />
        </div>
        <div className="col-md-2">
            { <span 
            className="pull-right" 
            title="Remove Phone" 
            onClick={() => formProps.fields.remove(index)}
            style={{paddingRight:'2rem'}}
            >
              <img className="delete-icon-phones" src={deleteIcon} alt="deleteIcon" /></span> }
        </div>
      </div>
    ));

    return (
      <React.Fragment>
        <div>
          {block}
          <div>
            <div className="row">
              <div className="col-md-12" style={{ padding: 0 }}>
                <span className="center-block pull-right add-fieldArray-btn" onClick={() => formProps.fields.push({})}>
                  <img src={addIcon} alt="addIcon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
};


export default withLocalize(RenderSalesPersonInputs);