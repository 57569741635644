/* eslint-disable react/no-string-refs */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { withLocalize, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import types from '../../../Redux/actions/types.js';
import { clearTempedgeStoreProp, tempedgeAPI, getList } from '../../../Redux/actions/tempEdgeActions';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import Container from '../../../components/common/Container/Container';
import { PositionDetails } from './PositionDetails.js';
import downIcon from '../../../assets/icons/down.png';
import GrayCommonBtn from '../../../components/common/Buttons/GrayCommonBtn';
import BlueCommonBtn from '../../../components/common/Buttons/BlueCommonBtn';
import Loader from '../../../components/common/Loader/Loader.js';
import DownloadFile from '../../../components/common/downloadFile/DownloadFile.js';
import { limitString, normalizePhone } from '../../../utils/ReGexs.js';
import {
   REACT_APP_URL_CLIENT_VIEW,
   REACT_APP_URL_COUNTRY_LIST_ALL,
   REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
} from '../../../components/common/Routes/URLs.js';

import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { IconATT, Iconenvelope, IconLocation, IconPersonCicle, IconPhone } from '../../../components/common/Icons/Icon_svg.js';
const icons = '24px';

class ViewClient extends Component {
   constructor(props) {
      super(props);
      this.state = {
         key: 0,
         page: 1,
         orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
         loading: false,
         selectedClient: this.props.selectedClient,
      };
      this.addTranslationsForActiveLanguage();
   }

   async componentDidMount() {
      this.setState({
         loading: true,
      });
      await this.fetchData();
      this.setState({
         loading: false,
      });
   }

   componentDidUpdate(prevProps) {
      const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

      if (hasActiveLanguageChanged) {
         this.props.push(`/client/view/${this.props.activeLanguage.code}`);
         this.addTranslationsForActiveLanguage();
      }
   }

   async fetchData() {
      const { clientId, tempedgeAPI, countryRegionList } = this.props;
      const { orgId } = this.state;

      if (countryRegionList.length === 0) {
         await this.props.getList(REACT_APP_URL_COUNTRY_LIST_ALL, types.GET_COUNTRY_REGION_LIST);
      }
      await tempedgeAPI(REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL, { orgId, filterBy: {} }, types.GET_ORG_DEPARTMENT_LIST);

      const payload = {
         orgId: this.state.orgId,
         id: clientId,
      };

      const response = await tempedgeAPI(REACT_APP_URL_CLIENT_VIEW, payload, types.VIEW_CLIENT);

      if (response?.data?.result) {
         this.setState({
            selectedClient: response.data.result,
         });
      } else {
         this.cancelAction();
      }
   }

   async addTranslationsForActiveLanguage() {
      await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   }

   mapCountry = (userCountry, returnField, findField) => {
      const clientCountry = this.props.countryRegionList.filter((country) => country.countryId === this.state.selectedClient.country);

      if (clientCountry && this.props.countryRegionList) {
         return this.mapList(this.props.countryRegionList, returnField, findField, userCountry);
      }
   };

   mapRegion = (userState, returnField, findField) => {
      const selectedCountry = this.props.countryRegionList.filter((country) => country.countryId === this.state.selectedClient.country);

      if (selectedCountry.length > 0) {
         const regions = selectedCountry[0].regionEntityList;

         if (userState && regions) {
            return this.mapList(regions, returnField, findField, userState);
         }
      }
   };

   mapPayroll(payroll) {
      switch (payroll) {
         case 0:
            return this.props.translate('com.tempedge.msg.label.sun') + ' - ' + this.props.translate('com.tempedge.msg.label.sat');
         case 1:
            return this.props.translate('com.tempedge.msg.label.mon') + ' - ' + this.props.translate('com.tempedge.msg.label.sun');
         case 2:
            return this.props.translate('com.tempedge.msg.label.tue') + ' - ' + this.props.translate('com.tempedge.msg.label.mon');
         case 3:
            return this.props.translate('com.tempedge.msg.label.wed') + ' - ' + this.props.translate('com.tempedge.msg.label.tue');
         case 4:
            return this.props.translate('com.tempedge.msg.label.thu') + ' - ' + this.props.translate('com.tempedge.msg.label.wed');
         case 5:
            return this.props.translate('com.tempedge.msg.label.fri') + ' - ' + this.props.translate('com.tempedge.msg.label.thu');
         case 6:
            return this.props.translate('com.tempedge.msg.label.sat') + ' - ' + this.props.translate('com.tempedge.msg.label.fri');
         default:
            return 'N/A';
      }
   }

   mapDeparments = (deparments) => {
      return deparments.map((department) => (
         <div key={`department-${department.departmentId}`}>
            <div className="btn-deptarment">
               <a
                  className="up-down-arrow pull-left"
                  data-toggle="collapse"
                  href={`#department-${department.departmentId}`}
                  role="button"
                  aria-expanded="false"
                  aria-controls={`department-${department.departmentId}`}
               >
                  <img src={downIcon} className="downIcon" alt="Show deparment" />
               </a>
               <span>{department.name}</span>
            </div>

            <div className="collapse multi-collapse" id={`department-${department.departmentId}`}>
               <div className="card card-body">
                  <div className="row">
                     {department.positions && department.positions.length > 0
                        ? department.positions.map((position) => {
                             return (
                                <PositionDetails
                                   position={position}
                                   id={`position-${position.name}-${position.positionId}`}
                                   key={`position-${position.name}-${position.positionId}`}
                                />
                             );
                          })
                        : ''}
                  </div>
               </div>
            </div>
         </div>
      ));
   };

   editAction() {
      this.props.history.push({
         pathname: `/client/update/${this.props.activeLanguage.code}`,
      });
   }

   // Cancel actions
   cancelAction() {
      this.props.history.push(`/client/list/${this.props.activeLanguage.code}`);
   }

   /**
    *
    * @param {Array} list lista de datos
    * @param {String} returnField campo a retornar de la lista
    * @param {String} findField campo a buscar de la lista (campo a comparar)
    * @param {*} findValue valor a encontrar (valor de comparación)
    */

   mapList = (list, returnField, findField, findValue) => {
      if (list) {
         for (let index = 0; index < list.length; index++) {
            if (list[index][findField] === findValue) {
               return list[index][returnField];
            }
         }
      }
   };

   render() {
      const approveBtns = (
         <div className="row">
            <div className="col-md-6">
               <GrayCommonBtn tag={'com.tempedge.msg.label.cancel'} onClick={() => this.cancelAction()} />
            </div>
            <div className="col-md-6">
               <BlueCommonBtn tag={'com.tempedge.msg.label.edit'} onClick={() => this.editAction()} />
            </div>
         </div>
      );

      if (this.state.loading) return <Loader />;
      return (
         <Container title="com.tempedge.client.title.viewclient" btns={approveBtns}>
            <Row className="container-content">
               <Col sm={12} md={4} lg={4} className="formPanel-EmployeeData">
                  <div>
                     <Row>
                        <Col sm={12} md={12} lg={12}>
                           <p className="label-view-data-name text-center">
                              {this.state.selectedClient && this.state.selectedClient.clientName
                                 ? this.state.selectedClient.clientName
                                 : ''}
                           </p>
                        </Col>
                     </Row>

                     <Row>
                        <OverlayTrigger
                           placement="top"
                           overlay={
                              <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
                                 <Translate id="com.tempedge.msg.label.phone" />
                              </Tooltip>
                           }
                        >
                           <Col sm={6} md={2} lg={2}>
                              <IconPhone icons={icons} />
                           </Col>
                        </OverlayTrigger>
                        <Col sm={12} md={10} lg={10}>
                           <label className="label-view-data-info">
                              {this.state.selectedClient && this.state.selectedClient.phone
                                 ? normalizePhone(this.state.selectedClient.phone, 0)
                                 : '---'}
                           </label>
                        </Col>

                        <OverlayTrigger
                           placement="top"
                           overlay={
                              <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
                                 <Translate id="com.tempedge.msg.label.email" />
                              </Tooltip>
                           }
                        >
                           <Col sm={12} md={2} lg={2}>
                              <Iconenvelope icons={icons} />
                           </Col>
                        </OverlayTrigger>
                        <Col sm={12} md={10} lg={10} className="email-field">
                           <label className="label-view-data-info">
                              {this.state.selectedClient && this.state.selectedClient.email
                                 ? limitString({
                                      value: this.state.selectedClient.email,
                                      limit: 22,
                                      replace: '...',
                                   })
                                 : ' '}
                           </label>
                        </Col>

                        <OverlayTrigger
                           placement="top"
                           overlay={
                              <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
                                 <Translate id="com.tempedge.msg.label.name" />
                              </Tooltip>
                           }
                        >
                           <Col sm={6} md={2} lg={2}>
                              <IconPersonCicle icons={icons} />
                           </Col>
                        </OverlayTrigger>
                        <Col sm={12} md={10} lg={10}>
                           <label className="label-view-data-info">
                              {this.state.selectedClient && this.state.selectedClient.contact
                                 ? this.state.selectedClient.contact.firstName + ' ' + this.state.selectedClient.contact.lastName
                                 : ''}
                           </label>
                        </Col>

                        <OverlayTrigger
                           placement="top"
                           overlay={
                              <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
                                 <Translate id="com.tempedge.msg.label.attnTo" />
                              </Tooltip>
                           }
                        >
                           <Col sm={6} md={2} lg={2}>
                              <IconATT icons={icons} />
                           </Col>
                        </OverlayTrigger>
                        <Col sm={12} md={10} lg={10}>
                           <label className="label-view-data-info">
                              {this.state.selectedClient && this.state.selectedClient.attn ? this.state.selectedClient.attn : ''}
                           </label>
                        </Col>

                        <OverlayTrigger
                           placement="top"
                           overlay={
                              <Tooltip id="popover-contained" style={{ fontSize: '15px' }}>
                                 <Translate id="com.tempedge.msg.label.city" />
                              </Tooltip>
                           }
                        >
                           <Col sm={12} md={2} lg={2}>
                              <IconLocation icons={icons} />
                           </Col>
                        </OverlayTrigger>
                        <Col sm={12} md={10} lg={10}>
                           <label className="label-view-data-info">
                              {this.state.selectedClient && this.state.selectedClient.city ? this.state.selectedClient.city : '---'}
                           </label>
                        </Col>

                        {/* comments */}
                        <Col sm={12} md={12} lg={12}>
                           <Row>
                              <Col sm={12} md={12} lg={12}>
                                 <label className="control-label">
                                    <Translate id="com.tempedge.msg.label.comments" />
                                 </label>
                                 <div className="form-group basic-textarea">
                                    <textarea
                                       className="form-control z-depth-1 comment-section comment-media"
                                       rows="3"
                                       value={
                                          this.state.selectedClient && this.state.selectedClient.notes
                                             ? this.state.selectedClient.notes
                                             : ''
                                       }
                                       disabled
                                    />
                                 </div>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </div>
               </Col>
               <Col sm={12} md={8} lg={8} className="formPanel-EmployeeInfo">
                  <div className="" ref="viewClient">
                     <div className="center-block">
                        <ul className="nav nav-panel">
                           <li className="nav-item first-panel " onClick={() => this.setState({ key: 0 })}>
                              <a className={this.state.key === 0 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab1">
                                 <Translate id="com.tempedge.msg.label.companyinformation" />
                              </a>
                           </li>
                           <li className="nav-item panel " onClick={() => this.setState({ key: 1 })}>
                              <a className={this.state.key === 1 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab2">
                                 <Translate id="com.tempedge.msg.label.files" />
                              </a>
                           </li>
                           <li className="nav-item last-panel" onClick={() => this.setState({ key: 2 })}>
                              <a className={this.state.key === 2 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href="#tab3">
                                 <Translate id="com.tempedge.msg.label.departmentlist" />
                              </a>
                           </li>
                        </ul>
                        <div className="form-group" style={{ marginLeft: '1px' }}>
                           <div className="tab-content formPanelBody formPanel-EmployeeInfo">
                              <div
                                 className={this.state.key === 0 ? 'tab-pane fade show active' : 'tab-pane fade'}
                                 id="tab1"
                                 role="tabpanel"
                              >
                                 <Row className="form-group">
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.country" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient && this.state.selectedClient.country
                                             ? this.mapCountry(this.state.selectedClient.country, 'name', 'countryId')
                                             : ''}
                                       </label>
                                    </Col>
                                 </Row>
                                 <Row className="form-group">
                                    <Col sm={10} md={8} lg={8}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.address" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient && this.state.selectedClient.address
                                             ? this.state.selectedClient.address
                                             : ''}
                                       </label>
                                    </Col>
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.agencyaddress2" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient && this.state.selectedClient.address2
                                             ? this.state.selectedClient.address2.length > 16
                                                ? `${this.state.selectedClient.address2.slice(0, 16)}...`
                                                : this.state.selectedClient.address2
                                             : ''}
                                       </label>
                                    </Col>
                                 </Row>
                                 <Row className="form-group">
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.city" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient && this.state.selectedClient.city
                                             ? this.state.selectedClient.city
                                             : ''}
                                       </label>
                                    </Col>
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.state" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient &&
                                          this.state.selectedClient.country &&
                                          this.state.selectedClient.region
                                             ? this.mapRegion(this.state.selectedClient.region, 'name', 'regionId')
                                             : ''}
                                       </label>
                                    </Col>
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.agencyzipcode" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient && this.state.selectedClient.zipcode
                                             ? this.state.selectedClient.zipcode
                                             : ''}
                                       </label>
                                    </Col>
                                 </Row>
                                 <hr />
                                 <br />
                                 <Row>
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.workCompCode" />
                                       </label>
                                       <label className="label-view-data-info">
                                          {this.state.selectedClient && this.state.selectedClient.wcCode
                                             ? this.state.selectedClient.wcCode
                                             : ''}
                                       </label>
                                    </Col>
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.workCompRate" />
                                       </label>
                                       <label className="label-view-data-info">
                                          {this.state.selectedClient && this.state.selectedClient.wcRate
                                             ? this.state.selectedClient.wcRate
                                             : ''}
                                       </label>
                                    </Col>
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.workstate" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient && this.state.selectedClient.workRegion
                                             ? this.mapRegion(this.state.selectedClient.workRegion, 'shortCode', 'regionId') : ''}
                                       </label>
                                    </Col>
                                 </Row>
                                 <Row className="form-group">
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.markupClient" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient && this.state.selectedClient.commonMarkup
                                             ? this.state.selectedClient.commonMarkup
                                             : ''}
                                       </label>
                                    </Col>
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.otMarkupClient" />
                                       </label>
                                       <label className="label-view-data">
                                          {this.state.selectedClient && this.state.selectedClient.commonOtMarkup
                                             ? this.state.selectedClient.commonOtMarkup
                                             : ''}
                                       </label>
                                    </Col>
                                    <Col sm={10} md={5} lg={4}>
                                       <label className="control-label">
                                          <Translate id="com.tempedge.msg.label.payrollCycle" />
                                       </label>
                                       <label className="label-view-data-info">
                                          {this.state.selectedClient && typeof this.state.selectedClient.payrollSchedule === 'number'
                                             ? this.mapPayroll(parseInt(this.state.selectedClient.payrollSchedule))
                                             : ''}
                                       </label>
                                    </Col>
                                 </Row>
                              </div>
                              <div
                                 className={this.state.key === 1 ? 'tab-pane fade show active' : 'tab-pane fade'}
                                 id="tab2"
                                 role="tabpanel"
                              >
                                 {this.state.selectedClient && this.state.selectedClient.fileEntities ? (
                                    <>
                                       <Row className="form-group ">
                                          <Col sm={10} md={5} lg={4} className="form-group">
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.filetype" />
                                             </label>
                                          </Col>
                                          <Col sm={10} md={5} lg={4} className="form-group">
                                             <label className="control-label">
                                                <Translate id="com.tempedge.msg.label.filename" />
                                             </label>
                                          </Col>
                                       </Row>
                                       {this.state.selectedClient.fileEntities.map((item, index) => {
                                          if (item.file !== null && item.file !== 'undefined') {
                                             return (
                                                <Row key={index} className="form-group ">
                                                   <Col sm={10} md={5} lg={4} className="form-group">
                                                      {item.type}
                                                   </Col>
                                                   <Col sm={10} md={5} lg={4} className="form-group">
                                                      {item.fileName}
                                                   </Col>
                                                   <Col sm={10} md={5} lg={4} className="form-group">
                                                      <DownloadFile file={item.file} fileName={item.fileName} />
                                                   </Col>
                                                </Row>
                                             );
                                          }
                                          return null;
                                       })}
                                    </>
                                 ) : null}
                              </div>
                              <div
                                 className={this.state.key === 2 ? 'tab-pane fade show active' : 'tab-pane fade'}
                                 id="tab3"
                                 role="tabpanel"
                              >
                                 {this.state.selectedClient && this.state.selectedClient.departments
                                    ? this.mapDeparments(this.state.selectedClient.departments)
                                    : ''}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </Col>
            </Row>
         </Container>
      );
   }
}

ViewClient.propTypes = {
   tempedgeAPI: PropTypes.func.isRequired,
   countryRegionList: PropTypes.array,
   clearTempedgeStoreProp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
   return {
      countryRegionList: state.tempEdge.country_region_list ? state.tempEdge.country_region_list : [],
      clientId: state.tempEdge.clientId,
   };
};

export default withLocalize(
   connect(mapStateToProps, {
      push,
      clearTempedgeStoreProp,
      tempedgeAPI,
      getList,
   })(ViewClient),
);
