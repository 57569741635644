import React, { Component } from 'react';

class InputModal extends Component {
  render() {
    let { name, value, placeholder, title, onChange, styleClass } = this.props;

    return (
      <div className={styleClass}>
        <div className="input-group">
          <input className="form-control tempEdge-input-box square-right-side"
            name={name}
            value={value ? value : ''}
            onChange={onChange}
            placeholder={placeholder}
            type="text"
            customclass="square-right-side"
            disabled
          />
          <div className="input-group-append" style={{"z-index": "0"}}>
            <button className="btn btn-green z-index-0" title={title} onClick={this.props.onClick} type="button" >
              +
          </button>
          </div>
        </div >
      </div >
    );
  }
}

export default InputModal;