import { useFormikContext, FieldArray } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Skills } from "../../../components/common/Constants/ConstantsCandidates";


const SkillsCandidate = () => {
  const { values} = useFormikContext();

  return (
    <>
        <Row className="py-5">
          <FieldArray
            name={"skills"}
            className="form-group d-flex justify-content-between w-100 py-4"
            render={(arrayHelper) => (
              <>
                <Col  sm={12} md={6} lg={6}>
                  {typeof Skills !== "undefined"
                    ? Skills.filter(
                        (_, index) => index < (Skills.length - 1) / 2
                      ).map((item) => (
                        <div
                          key={item.skillId}
                          className="skill-item form-check d-flex align-items-center"
                        >
                          <input
                            className={"mr-2"}
                            id={`skillId${item.skillId}`}
                            name={"skills"}
                            type="checkbox"
                            value={item.skillId}
                            checked={values?.skills?.includes(item.skillId)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                arrayHelper.push(item.skillId);
                              } else {
                                const index = values.skills.indexOf(
                                  item.skillId
                                );
                                arrayHelper.remove(index);
                              }
                            }}
                          />
                          <label
                            htmlFor={`skillId${item.skillId}`}
                            className="mb-0"
                            style={{ fontSize: "1.6rem" }}
                          >
                            {item.skill}
                          </label>
                        </div>
                      ))
                    : ""}
                </Col>
                <Col  sm={12} md={6} lg={6}>
                  {typeof Skills !== "undefined"
                    ? Skills.filter(
                        (_, index) => index > (Skills.length - 1) / 2
                      ).map((item) => (
                        <div
                          key={item.skillId}
                          className="skill-item form-check d-flex align-items-center"
                        >
                          <input
                            className={"mr-2"}
                            id={`skillId${item.skillId}`}
                            name={"skills"}
                            type="checkbox"
                            value={item.skillId}
                            checked={values.skills?.includes(item.skillId)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                arrayHelper.push(item.skillId);
                              } else {
                                const index = values.skills?.indexOf(
                                  item.skillId
                                );
                                arrayHelper.remove(index);
                              }
                            }}
                          />
                          <label
                            htmlFor={`skillId${item.skillId}`}
                            className="mb-0"
                            style={{ fontSize: "1.6rem" }}
                          >
                            {item.skill}
                          </label>
                        </div>
                      ))
                    : ""}
                </Col>
              </>
            )}
          />
        </Row>
    </>
  );
};

export default SkillsCandidate;
