import React, { Component } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  disableSaveButton,
  loadValuesToEmployeeTimeSheet,
  onChangeMailCc,
  onChangeMailTo,
  onToggleSendEmail
} from '../../Redux/actions/dailyListActions';
import '../../assets/styles/components/TimeSheet.css';
import DropdownList from '../../components/common/Dropdown/DropdownList';
import Switch from '../../components/common/Switch/Switch';
import Tooltip from "../../components/common/Tooltip";
import { handleMultipleEmailValidation } from '../../utils/ReGexs';
import TimeSheetTable from './TimeSheetTable';
import ThreeStateSwitch from '../../components/common/ThreeStateSwitch/ThreeStateSwitch';
import { Row } from 'react-bootstrap';


class TimeSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positionSelected: null,
      payForAll: '',
      attendanceForAll: null,
      disabledButton: false
    };
    this.baseState = this.state;
  }

  componentWillUnmount() {
    this.setState(this.baseState);
  }

  onChangeAttendanceForAll = (value) => {
    this.setState({
      'attendanceForAll': value
    });
  }

  onChangePayAndAttendanceForAll = ({ target }) => {
    this.setState({
      [target.name]: target.value
    });
  }

  onBlurPayAndAttendanceForAll = (isValidValue) => {
    this.setState({
      disabledButton: !isValidValue
    });
  }

  onChangeMail = ({ target }) => {
    if (target.name === 'mailTo') {
      this.props.onChangeMailTo(target.value);
    } else {
      this.props.onChangeMailCc(target.value);
    }
  }

  setPositionValue(positionSelected) {
    if (positionSelected) {
      this.setState({
        positionSelected,
        payForAll: positionSelected.pay
      });
    }
  }

  loadPayAndAttendanceValues() {
    const { loadValuesToEmployeeTimeSheet } = this.props;
    const { attendanceForAll, payForAll, positionSelected } = this.state;
    const valuesToLoad = {
      attendance: attendanceForAll,
      pay: payForAll,
      position: positionSelected || ''
    };
    loadValuesToEmployeeTimeSheet(valuesToLoad);
  }

  renderEmailInputs() {
    const { translate, mailTo, mailCc, sendEmail } = this.props;
    
    if (sendEmail) {
      return (
        <>
          <div className="form-group col-12 col-lg-3">
            <label className="text-left label-p">{translate('com.tempedge.msg.label.clientemail')}</label>
            <input type="email" name="mailTo" className="form-control tempEdge-input-box" value={mailTo} onChange={this.onChangeMail} required />
          </div>
          <div className="form-group col-12 col-lg-3">
            <label className="text-left label-p" style={{ marginLeft: '10px' }}>CC.</label>
            <Tooltip translate={<Translate id="com.tempedge.msg.label.infoccemail" />} iconWidth={'20px'} iconHeight={'20px'} iconFloat={'right'} />
            <input type="email" name="mailCc" multiple className={`form-control tempEdge-input-box ${mailCc.trim() !== '' && !handleMultipleEmailValidation(mailCc) ? 'has-error' : ''
              }`} value={mailCc} onChange={this.onChangeMail} />
          </div>
        </>
      );
    }
    return null;
  }

  renderButtonName() {
    const { translate, sendEmail } = this.props;
    return sendEmail ? translate('com.tempedge.msg.label.savesendemail') : translate('com.tempedge.msg.label.save');
  }

  render() {
    const { disabledSaveButton, employeesInTimeSheet, translate, resetEntireForm, showDateModal, positionList, isFindListChecked, handleDownloadFile, onToggleSendEmail } = this.props;
    const { positionSelected, payForAll, attendanceForAll, disabledButton } = this.state;

    return (
      <div className="time-sheet">
        <div className="time-sheet__header">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="form-group col-12 col-lg-3">
                  <p className="green-text font-weight-bold text-uppercase">{translate('com.tempedge.msg.label.timesheet')}</p>
                </div>
                <div className="form-group col-12 col-lg-3">
                  <p>
                    {translate('com.tempedge.msg.label.listsize')}
                    :
                    <span className="badge">{employeesInTimeSheet.length}</span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-lg-3">
                  <label className="text-left label-p">{translate('com.tempedge.msg.label.deptpos')}</label>
                  <DropdownList
                    value={positionSelected}
                    data={positionList}
                    valueField="positionId"
                    textField="positionName"
                    onChange={(positionSelected) => this.setPositionValue(positionSelected)}
                  />
                </div>
                <div className="form-group col-12 col-lg-3">
                  <label className="text-left label-p">{translate('com.tempedge.msg.label.pay')}</label>
                  <input
                    ref={this.payInput}
                    type="text"
                    name="payForAll"
                    pattern="^(?!0\.00)-{0,1}\d{1,2}(\.\d{1,2})?$"
                    maxLength="6"
                    className="form-control tempEdge-input-box"
                    value={payForAll}
                    onChange={this.onChangePayAndAttendanceForAll}
                    onInput={({ target }) => {
                      target.value = target.value.replace(/[^-\d.]/g, '');
                    }}
                    onBlur={(e) => this.onBlurPayAndAttendanceForAll(e.target.checkValidity())}
                  />
                </div>
                <div className="form-group col-12 col-lg-3">
                  <label className="text-left label-p">{translate('com.tempedge.msg.label.attendance')}</label>
                  <Row>
                    <ThreeStateSwitch
                      name={'Switch'}
                      size={'md'}
                      currentValue = {attendanceForAll}                      
                      onClick={this.onChangeAttendanceForAll}                    
                    />
                  </Row>                  
                </div>
                <div className="form-group col-12 col-lg-3">
                  <label className="text-left label-p">{translate('com.tempedge.msg.label.loadlist')}</label>
                  <button type="button" onClick={() => this.loadPayAndAttendanceValues()} className="btn btn-green w-100" disabled={disabledButton}>
                    {translate('com.tempedge.msg.label.loadvalues')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="time-sheet__body">
          <TimeSheetTable positionList={positionList} isFindListChecked={isFindListChecked} />
          <div className="row mt-2">
            <div className="form-check col-12 col-lg-3">
              <Switch name="download" size='sm' onChange={(value) => handleDownloadFile(value)}>
                {translate('com.tempedge.msg.label.downloadlist')}
              </Switch>
            </div>
            <div className="form-check col-12 col-lg-3">
              <Switch name="sendEmail" size='sm' onChange={(value) => onToggleSendEmail(value)} >
                {translate('com.tempedge.msg.label.sendemail')}
              </Switch>
            </div>
            {this.renderEmailInputs()}
          </div>

          <div className="row mt-5">
            <div className="form-group col-12 col-lg-3">
              <button
                type="button"
                onClick={() => resetEntireForm(true)}
                className="btn btn-gray square-right-side w-100"
              >
                {translate('com.tempedge.msg.label.cancel')}
              </button>
            </div>
            <div className="form-group col-12 col-lg-3">
              <button type="button" onClick={() => showDateModal(true)} className="btn btn-blue square-left-side w-100" disabled={disabledSaveButton}>
                {this.renderButtonName()}
              </button>
            </div>

            <div className="form-group col-12 col-lg-3 ml-auto">
              <button type="button" onClick={() => showDateModal()} className="btn btn-orange w-100">
                {translate('com.tempedge.msg.label.deleteday')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ dailyListReducer: { employeesInTimeSheet = [], mailTo = '', mailCc = '', sendEmail, disabledSaveButton } }) => ({
  employeesInTimeSheet,
  mailTo,
  mailCc,
  sendEmail,
  disabledSaveButton
});

const mapDispatchToProps = {
  loadValuesToEmployeeTimeSheet,
  onChangeMailTo,
  onChangeMailCc,
  onToggleSendEmail,
  disableSaveButton
};

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(TimeSheet));
