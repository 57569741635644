import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";

const usePortalUser = () => {
  const portalUser = useSelector((state) => {
    return state.tempEdge.login !== "" &&
      state.tempEdge.login.portalUserList.length > 0
      ? state.tempEdge.login.portalUserList[0]
      : null;
  }, shallowEqual);

  if (!portalUser) {
    return null;
  }

  const { user } = portalUser;
  const { roles } = user;

  const role = roles.length > 0 ? roles[0].name : null;

  return {portalUser, role};
};

export default usePortalUser;
