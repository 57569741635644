import {useState, useEffect} from 'react';
import moment from "moment";

const useHeaderDate = (dateStart) => {
	const [findDate, setFindDate] = useState({
	   firstDate: { day: '', date: '' },
	   secondDate: { day: '', date: '' },
	   thirdDate: { day: '', date: '' },
	   fourthDate: { day: '', date: '' },
	   fifthDate: { day: '', date: '' },
	   sixthDate: { day: '', date: '' },
	   seventhDate: { day: '', date: '' },
	})
	    
	    
	useEffect(() => {
		setFindDate({
			firstDate: { day: moment(dateStart).format("ddd").toLowerCase(), date: moment(dateStart).format("MM/DD/YYYY") },
			secondDate: { day: moment(dateStart).add(1, 'd').format("ddd").toLowerCase(), date: moment(dateStart).add(1, 'd').format("MM/DD/YYYY") },
			thirdDate: { day: moment(dateStart).add(2, 'd').format("ddd").toLowerCase(), date: moment(dateStart).add(2, 'd').format("MM/DD/YYYY") },
			fourthDate: { day: moment(dateStart).add(3, 'd').format("ddd").toLowerCase(), date: moment(dateStart).add(3, 'd').format("MM/DD/YYYY") },
			fifthDate: { day: moment(dateStart).add(4, 'd').format("ddd").toLowerCase(), date: moment(dateStart).add(4, 'd').format("MM/DD/YYYY") },
			sixthDate: { day: moment(dateStart).add(5, 'd').format("ddd").toLowerCase(), date: moment(dateStart).add(5, 'd').format("MM/DD/YYYY") },
			seventhDate: { day: moment(dateStart).add(6, 'd').format("ddd").toLowerCase(), date: moment(dateStart).add(6, 'd').format("MM/DD/YYYY") },
		})

	}, [dateStart])
	
	  
	return {
	  findDate
	}
};


export default useHeaderDate;