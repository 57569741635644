import axios from "axios";
import { REACT_APP_BASE_URL_TEMPEDGE, REACT_APP_URL_DELETE_ADJUSTMENT, REACT_APP_URL_FIND_BY_PERSON_ADJUSTMENT, REACT_APP_URL_SAVE_ADJUSTMENT } from "../components/common/Routes/URLs";

const AdjustmentServices = {
    saveAdjustment: async function(request) {
        try {
            const { data } = await axios({
                url: REACT_APP_BASE_URL_TEMPEDGE + REACT_APP_URL_SAVE_ADJUSTMENT,
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    orgId: JSON.parse(localStorage.getItem('agency')).organizationEntity.orgId,
                    ...request,
                },
                params: {
                    access_token: localStorage.getItem('access_token')
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    },
    findByPerson: async function(request) {
        try {
            const { data } = await axios({
                url: REACT_APP_BASE_URL_TEMPEDGE + REACT_APP_URL_FIND_BY_PERSON_ADJUSTMENT,
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    orgId: JSON.parse(localStorage.getItem('agency')).organizationEntity.orgId,
                    ...request,
                },
                params: {
                    access_token: localStorage.getItem('access_token')
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    },   
    
    deleteAdjustment: async function(request) {
        try {
            const { data } = await axios({
                url: REACT_APP_BASE_URL_TEMPEDGE + REACT_APP_URL_DELETE_ADJUSTMENT,
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    orgId: JSON.parse(localStorage.getItem('agency')).organizationEntity.orgId,
                    ...request,
                },
                params: {
                    access_token: localStorage.getItem('access_token')
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default AdjustmentServices;
