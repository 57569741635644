import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import PaginatedTable from '../../components/common/Table/PaginatedTable.js';
import { REACT_APP_URL_USER_LIST } from '../../components/common/Routes/URLs.js';

const api_url = REACT_APP_URL_USER_LIST;

class ListUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tablePage: 0,
      filterBy: {username:""},
      data: []
    }
    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  }
    
  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
    if (hasActiveLanguageChanged) {
      this.props.push(`/user/list/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  render() {
    const { onClickRows, multipleRows } = this.props;
    return (
      <PaginatedTable
        apiUrl={api_url}
        filterBy={this.state.filterBy}
        onClickRows={onClickRows}
        multipleRows={multipleRows}
        title="com.tempedge.msg.title.userlist"
        parameterKey="com.tempedge.msg.menu.userlist"
      />
    );
  }
}
export default withLocalize(connect(null, { push })(ListUsers));
