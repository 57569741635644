import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeWeekTimeEntryValue,
} from "../../../Redux/actions/timeEntryActions";
import { roundToTwoDecimals } from "../../../utils/helpers/TotalHoursFormat";


const EditableCell = ({ payroll, day }) => {

  const [totalOtHour, setTotalOtHour] = useState("");
  const [totalRegHour, setTotalRegHour] = useState("");
  const [payrollDisable, setPayrollDisable] = useState(true);
  

  useEffect(() => {
    const roundedTotalRegHour =roundToTwoDecimals(payroll[day]?.totalRegHour);
    const roundedTotalOtHour =roundToTwoDecimals(payroll[day]?.totalOtHour);
    if (payroll[day]?.totalRegHour === 0) {
      setTotalRegHour("");
    } else  {
      setTotalRegHour(roundedTotalRegHour);
    }

    if (payroll[day]?.totalOtHour === 0) {
      setTotalOtHour("");
    } else  {
      setTotalOtHour(roundedTotalOtHour);
    }

    if (payroll[day]?.lockPayroll) {
      setPayrollDisable(true);
    } else {
      setPayrollDisable(false);
    }
  }, [payroll, day]);

  const dispatch = useDispatch();

  const validateInput = (value) => {
    const regularExpression = /^-?\d{0,3}(\.\d{0,2})?$/;
    return regularExpression.test(value);
  };

  const handleValueChange = (key, value) => {
    if (validateInput(value)) {
      const data = {
        payrollId: payroll[day].payrollId,
        personId: payroll.person?.personId,
        key: key,
        value: value ? value : 0,
        day: day,
      };
      const actionToDispatch = changeWeekTimeEntryValue(data);
      dispatch(actionToDispatch);
    }
  };


  return (
    <>
      {
        payroll[day] !== null ? (
          <td className="editableCell" style={{ textAlign: "center" }}>
            <div className="input-group input-group-sm">
              <div className="input-group-prepend" style={{ width: "auto" }}>
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  RG
                </span>
              </div>
              <input
                style={{ fontSize: "12px" }}
                type="text"
                className="form-control"
                placeholder="0.00"
                aria-label="totalRegHour"
                aria-describedby="basic-addon1"
                value={totalRegHour}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  // Regular expression to match the desired format
                  const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                  if (regex.test(inputValue) || inputValue === "") {
                    setTotalRegHour(inputValue);
                  }
                }}
                onBlur={() => handleValueChange("totalRegHour", totalRegHour)}
                disabled={payrollDisable}
              />
              <span> </span>
              <input
                style={{ fontSize: "12px" }}
                type="text"
                className="form-control"
                placeholder="0.00"
                aria-label="totalOtHour"
                aria-describedby="basic-addon2"
                value={totalOtHour}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  // Regular expression to match the desired format
                  const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
                  if (regex.test(inputValue) || inputValue === "") {
                    setTotalOtHour(inputValue);
                  }
                }}
                onBlur={() => handleValueChange("totalOtHour", totalOtHour)}
                disabled={payrollDisable}
              />
              <div className="input-group-append" id="cellWeek">
                <span className="input-group-text" id="cellWeek">
                  OT
                </span>
              </div>
            </div>
          </td>
        ) : (
          <td></td>
        )
      }
    </>
  );
};

export default EditableCell;
