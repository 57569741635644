import { useState, useEffect, useRef, useMemo } from 'react';
import UploadFile from '../../../components/common/UploadFile/UploadFile';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import { withLocalize } from 'react-localize-redux';
import ContainerBlue from '../../../components/common/Container/ContainerBlue';
import Loader from '../../../components/common/Loader/Loader';
import { Formik } from 'formik';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

// services
import OutcomeBar from '../../../components/common/OutcomeBar';
import TempEdgeApi from '../../../services/TempEdgeApi';
import { REACT_APP_URL_CLIENT_PERSON_CODE_SAVE_BY_FILE } from '../../../components/common/Routes/URLs';
import { useSelector } from 'react-redux';

const validate = (values) => {
   const errors = {};
   if (!values.company) errors.company = 'Required';
   if (!values.file) errors.file = 'Required';
   return errors;
};

function WizardUploadFile(props) {
   const { promiseInProgress } = usePromiseTracker();
   const [message, setMessage] = useState(null);
   const [data, setData] = useState(null);
   const [nameFile, setNameFile] = useState(null);
   const formikRef = useRef();

   useEffect(() => {
      setMessage(props.message);
   }, [props.message]);

   const clientsListRedux = useSelector((state) => {
      return state.tempEdge.clientList;
   });

   const clientsList = useMemo(() => {
      return clientsListRedux;
   }, [clientsListRedux]);

   const handleSubmit = async (values) => {
      const typeExtension = /[.]/.exec(values.file.name) ? /[^.]+$/.exec(values.file.name)[0] : undefined;
      const formData = new FormData();

      const stringData = JSON.stringify({
         orgId: JSON.parse(localStorage.getItem('agency')).organizationEntity?.orgId,
         clientId: values.company.clientId,
      });

      const blob = new Blob([stringData], { type: 'application/json' });
      formData.append('clientPersonCodeDTO', blob);
      formData.append('fileType', typeExtension);
      formData.append('file', values.file);

      try {
         const { data } = await TempEdgeApi.postMultipart({
            url: REACT_APP_URL_CLIENT_PERSON_CODE_SAVE_BY_FILE,
            payload: formData,
         });
         if (data.status === 200 && data.code === 'TE00') {
            setNameFile('');
            setMessage(data);
         } else {
            setData(values);
            setMessage(data);
         }
      } catch (e) {
         setMessage(e);
      }
   };

   const btns = (
      <div className="row py-4">
         <div className="col-md-5 offset-md-1">
            <button
               type="button"
               className="btn btn-default btn-block register-save-btn previous"
               onClick={() => {
                  if (formikRef.current != null) formikRef.current.resetForm();
                  setMessage(null);
               }}
            >
               {props.translate('com.tempedge.msg.label.cancel')}
            </button>
         </div>
         <div className="col-md-5">
            <button
               type="submit"
               className="btn btn-primary btn-block register-save-btn next"
               onClick={() => {
                  if (formikRef.current) formikRef.current.handleSubmit();
               }}
            >
               {props.translate('com.tempedge.msg.label.upload')}
            </button>
         </div>
      </div>
   );

   if (promiseInProgress) return <Loader />;

   return (
      <ContainerBlue title="com.tempedge.msg.label.upload" btns={btns} width="40%">
         {message && <OutcomeBar response={message} />}
         <div className="d-flex flex-column align-items-center">
            <Formik
               innerRef={formikRef}
               enableReinitialize
               initialValues={
                  data || {
                     company: clientsList.length === 1 ? clientsList[0] : '',
                     file: null,
                  }
               }
               validate={validate}
               onSubmit={(values) => {
                  trackPromise(handleSubmit(values));
               }}
            >
               {(formik) => (
                  <>
                     <div className="form-group col-12">
                        <label className="text-left label-p">{props.translate('com.tempedge.msg.label.company')}</label>
                        {clientsList.length > 0 && (
                           <>
                              <div className="d-flex flex-column">
                                 <DropdownList
                                    className="form-control"
                                    isInvalid={formik.errors.company}
                                    data={clientsList}
                                    name="client"
                                    textField="clientName"
                                    valueField="clientId"
                                    value={{ clientId: formik.values.company.clientId }}
                                    onChange={(value) => {
                                       formik.setFieldValue('company', value);
                                    }}
                                 />
                              </div>

                              <div className="row justify-content-center">
                                 <div>
                                    <UploadFile
                                       title="com.tempedge.msg.label.upload"
                                       accept=".xls, .xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onFileSelectSuccess={(file) => {
                                          setNameFile(file.name);
                                          formik.setFieldValue('file', file);
                                       }}
                                       nameDoc={nameFile}
                                       showTag={false}
                                    />
                                    {formik.errors.file && (
                                       <div className="alert alert-danger" style={{ marginTop: 18 + 'px' }}>
                                          {props.translate('com.tempedge.msg.label.choosefile')}
                                       </div>
                                    )}
                                 </div>
                              </div>
                           </>
                        )}
                     </div>
                  </>
               )}
            </Formik>
         </div>
      </ContainerBlue>
   );
}

export default withLocalize(WizardUploadFile);
