import React, { useState, useEffect } from "react"; //hooks
import { useDispatch } from "react-redux";
import TimePicker from '../../components/common/TimePicker/TimePicker';
import { loadValuesToEmployeeSameTime, loadValuesToEmployeeSameTimeWeek } from "../../Redux/actions/timeEntryActions";
import { Translate } from "react-localize-redux";
import Switch from '../../components/common/Switch/Switch';
import { TotalHoursByDay } from '../../utils/helpers/TotalHoursFormat';

const SameTimePayroll = ({ isFindByWeek,weekActive }) => {

  const [timeIn, setTimeIn] = useState("");
  const [timeOut, setTimeOut] = useState("");
  const [lunch, setlunch] = useState("");
  const [totalRegHour, setTotalRegHour] = useState("");
  const [sameHour, setSameHour] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (timeIn !== null && timeIn !== "" && timeOut !== null && timeOut !== "") {
      setTotalRegHour(TotalHoursByDay(timeIn, timeOut, lunch));
    }
  }, [timeIn, timeOut, lunch]);

  const regexPattern = "^[0-9]+([.][0-9]{1,2})?$";

  const setDailyTimeIn = (time) => {
    setTimeIn(time);
  };

  const setDailyTimeOut = (time) => {
    setTimeOut(time);
  };

  const onChangeDailyTotalRegHour = (e) => {
    const value = e.target.value;
    const regularExpressions = RegExp(/^-?\d{1,2}(\.\d{1,2})?$/);
    if (regularExpressions.test(value) || value === '') {
      setTotalRegHour(value === '' ? '' : parseFloat(value)); 
    }
  };
  
  const onBlurDailyTotalRegHour = (e) => {
    if (e.target.value === '') {
    }
  };  

  const onChangeLunch = (value) => {
    setlunch(value);
  };

  const onChangeRegHour = (e) => {
    const num = e.target?.value;
    setTotalRegHour(num === '' ? '' : parseFloat(num)); // Convierte a número si no está vacío
  };

  const UpdateDailySameHours = () => {
    const valuesToLoad = { timeIn, timeOut, lunch, totalRegHour: totalRegHour || '0' };  
    let regularExpressions = RegExp(/^-?\d{1,2}(\.\d{1,2})?$/);
    if (regularExpressions.test(valuesToLoad.totalRegHour) && valuesToLoad.totalRegHour <= 99.99) {
      dispatch(loadValuesToEmployeeSameTime(valuesToLoad));
    } 
  };

  const saveTimeWeek = () => {
    let regularExpresions = RegExp(/(^-?\d{1,2})+(?:\.\d{1,2})?$/);
    if (regularExpresions.test(totalRegHour) && totalRegHour <= 99.99) {
      const totalRegHourParsed = parseFloat(totalRegHour) || 0;
      dispatch(loadValuesToEmployeeSameTimeWeek(totalRegHourParsed));
    }
  };

  if (isFindByWeek) {
    return (
      <div className="row">
        <div className="form-group col-3">
          <label className="text-left label-p"><Translate id={"com.tempedge.msg.label.samehour"} /></label>
          <div className="imput" style={{ marginTop: '1rem' }}>
            <Switch
              name="week"
              checked={sameHour}
              onChange={(value) => {
                setSameHour(value)
              }}
             
            />
          </div>
        </div>
        {sameHour && (
          <>
            <div className="form-group col-12 col-lg-3">
              <label className="text-left label-p"><Translate id={"com.tempedge.msg.label.hour"} /></label>
              <div style={{ marginTop: '1rem' }}>
                <input
                  type="number"
                  name="hours"
                  step="0.01"
                  pattern={regexPattern}
                  max='99.99'
                  min='-99.99'
                  maxLength='5'
                  style={{ marginTop: '1rem' }}
                  value={totalRegHour}
                  className="form-control tempEdge-input-box mt-1"
                  onChange={(e) => onChangeRegHour(e)}
                  disabled={!weekActive}
                />
              </div>
            </div>
            <div className="form-group col-12 col-lg-3">
              <div style={{ marginTop: '1rem' }}>
                <button
                  id="calculate"
                  type="button"
                  className="btn btn-green w-100 "
                  onClick={() => saveTimeWeek()}
                  disabled={!weekActive}
                  style={{marginTop:'2.4rem'}}
                >
                  <Translate id={"com.tempedge.msg.label.update"} />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
  return (
    <>
      <div className="row">
        <div className="form-group col-3">
          <label className="text-left label-p"><Translate id={"com.tempedge.msg.label.samehour"} /></label>
          <div className="imput " style={{ marginTop: '1rem' }}>
            <Switch
              name="week"
              checked={sameHour}
              onChange={(value) => {
                setSameHour(value)
              }}
              
            />
          </div>
        </div>

        {sameHour === true && (
          <>
            <div className="col-md-9">
              <div className="row">
                <div className="col-4">
                  <label className="text-left label-p">
                    <Translate id={"com.tempedge.msg.label.timein"} />
                  </label>
                  <TimePicker
                    time={timeIn}
                    setTime={(time) => {
                      setTimeIn(time);
                      setDailyTimeIn(time);
                    }}
                    disabled={!weekActive}
                  />
                </div>
                <div className="col-4">
                  <label className="text-left label-p">
                    <Translate id={"com.tempedge.msg.label.timeout"} />
                  </label>
                  <TimePicker
                    time={timeOut}
                    setTime={(time) => {
                      setTimeOut(time);
                      setDailyTimeOut(time);
                    }}
                    disabled={!weekActive}
                  />
                </div>
                <div className="col-4"></div>
                <div className="col-4">
                  <label className="text-left label-p"><Translate id={"com.tempedge.msg.label.lunch"} /></label>
                  <div style={{ marginTop: '1rem' }}>
                    <input
                      type="text"
                      name="lunchForAll"
                      max="60"
                      maxLength='2'
                      value={lunch}
                      className="form-control tempEdge-input-box mt-1 text-center"
                      onChange={(e) => {
                        let regularExpresions = RegExp(/(^-?\d{,2})?$/)
                        if (regularExpresions.test(e.target.value) && e.target.value <= 60) onChangeLunch(e.target.value)
                      }
                      }
                      disabled={!weekActive}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <label className="text-left label-p">
                    <Translate id={"com.tempedge.msg.label.totalreghours"} />
                  </label>
                  <div style={{ marginTop: '1rem' }}>
                    <input
                      type="number"
                      name="TotalReg"
                      step="0.01"
                      pattern={regexPattern}
                      max='99.99'
                      min='-99.99'
                      maxLength='5'
                      style={{ marginTop: '1rem' }}
                      value={totalRegHour}
                      onChange={onChangeDailyTotalRegHour}
                      onBlur={onBlurDailyTotalRegHour} 
                      className="form-control tempEdge-input-box mt-1 text-center"
                      disabled={!weekActive}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div >
      {sameHour === true && (
        <div className="row " >
          <div className="col-3 offset-md-9">            
            <button
              type="button"
              onClick={() => UpdateDailySameHours()}
              className="btn btn-green w-100"
              disabled={!weekActive}
            >
              <Translate id={"com.tempedge.msg.label.update"} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SameTimePayroll;
