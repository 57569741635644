import { dailyListTypes } from '../types/dailyListTypes';

export const addEmployee = (payload) => ({
  type: dailyListTypes.ADD_EMPLOYEE_IN_TIMESHEET,
  payload
});

export const removeEmployee = (personId) => ({
  type: dailyListTypes.REMOVE_EMPLOYEE_IN_TIMESHEET,
  payload: { personId }
});

export const removeEmployeeId = (id) => ({
  type: dailyListTypes.REMOVE_EMPLOYEE_IN_TIMESHEET_ID,
  payload: { id }
});

export const loadValuesToEmployeeTimeSheet = (valuesToLoad) => ({
  type: dailyListTypes.LOAD_VALUES_TO_EMPLOYEE_TIMESHEET,
  payload: valuesToLoad
});

export const changeEmployeeValue = (payload) => ({
  type: dailyListTypes.CHANGE_EMPLOYEE_VALUE,
  payload
});

export const getDailyList = (payload) => ({
  type: dailyListTypes.GET_DAILY_LIST,
  payload
});

export const addToRemovedEmployeeList = (employee) => ({
  type: dailyListTypes.ADD_TO_REMOVED_EMPLOYEES_LIST,
  payload: { employee }
});

export const clearProp = (payload) => ({
  type: dailyListTypes.CLEAR_PROP,
  payload
});

export const onChangeMailTo = (payload) => ({
  type: dailyListTypes.CHANGE_MAIL_TO,
  payload
});

export const onChangeMailCc = (payload) => ({
  type: dailyListTypes.CHANGE_MAIL_CC,
  payload
});

export const onToggleSendEmail = (payload) => ({
  type: dailyListTypes.TOGGLE_SEND_EMAIL,
  payload
});

export const resetEntireState = () => ({
  type: dailyListTypes.RESET_ENTIRE_STATE
});

export const disableSaveButton = (payload) => ({
  type: dailyListTypes.DISABLE_SAVE_BUTTON,
  payload
});
