import React, { useCallback, useEffect, useState } from 'react'
import TempEdgeApi from "../../services/TempEdgeApi";
import { CardContainer } from './Components/CardContainer';
import '../Dashboard/dashboard.css';
import { Col, Row } from 'react-bootstrap';
import { REACT_APP_URL_FIND_RELEASE_NOTES } from '../../components/common/Routes/URLs';

export const ReleaseNotesCard = () => {
    const [dataFetched, setDataFetched] = useState(false);
    const [data, setData] = useState([]);

    const getData = useCallback(async () => {
        try {
            if (!dataFetched) {
                const { data: res } = await TempEdgeApi.get({
                    url: REACT_APP_URL_FIND_RELEASE_NOTES,
                });
                if (res.status === 200) {
                    setData(res.result);
                    setDataFetched(true);
                }
            }
        } catch (error) { }
    }, [dataFetched]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            <CardContainer>
                {data.length > 0 && (
                    <>
                        <h2 className='text-center m-4'>RELEASE NOTES</h2>
                        <Col>
                            {data.map((item, index) => (
                                <div key={index} className='dotted-border'>
                                    {Object.values(item).map((value, valueIndex) => (
                                        <Row key={valueIndex} className='m-3 p-2'>
                                            {Array.isArray(value) ? (
                                                <ul>
                                                    {value.map((note, noteIndex) => (
                                                        <li className='p-2' key={noteIndex}>{note}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                value
                                            )}
                                        </Row>
                                    ))}
                                </div>
                            ))}
                        </Col>
                    </>
                )
                }
            </CardContainer>
        </>
    )
}
