import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import AttendanceTableHeader from './AttendanceTableHeader';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useHeaderDate from '../PayrollByWeek/useHeaderDate';
import { TotalAttendanceWeek, TotalSumAttendanceByWeek, TotalSumByWeekDay } from '../../../utils/helpers/TotalHoursFormat';
import EditableCell from './EditableCell.js';

export const AttendanceTable = ({ attendanceWeekList, translate }) => {
   const startDate = useSelector((store) => store.attendance.startDate);
   const { findDate } = useHeaderDate(startDate);

   return (
      <>
         <Col className="form-group" md={6} lg={3} sm={12}>
            <h2>
               <Translate id={'com.tempedge.msg.label.attendancevalidation'} />
            </h2>
         </Col>

         <Col className="form-group" md={6} lg={3} sm={12}>
            <p style={{ textAlign: 'left' }}>
               <Translate id={'com.tempedge.msg.label.listsize'} /> <span className="badge">{attendanceWeekList.length}</span>{' '}
            </p>
         </Col>

         <Row>
            <Col className="form-check " md={6} lg={12}>
               <table className="table table-striped table-input-group">
                  <thead>{findDate && <AttendanceTableHeader findDate={findDate} />}</thead>
                  <tbody>
                     {attendanceWeekList?.map((payroll, index) => (
                        <tr key={payroll.person?.personId || index}>
                           <td>{`${payroll.person?.fullName}`}</td>
                           {Object.keys(findDate).map((currentDay, dayIndex) => {
                              const payrollDate = payroll && payroll[findDate[currentDay].day]?.dateList;
                              return currentDay && payrollDate && moment(payrollDate).format('MM/DD/YYYY') === findDate[currentDay].date ? (
                                 <EditableCell
                                    key={`${payroll.person?.personId}-${dayIndex}`}
                                    payroll={payroll}
                                    day={findDate[currentDay].day}
                                    translate={translate}
                                 />
                              ) : (
                                 <td key={`${payroll.person?.personId}-${dayIndex}`}></td>
                              );
                           })}
                           <td className="text-center">{TotalSumAttendanceByWeek(payroll, findDate)}</td>
                        </tr>
                     ))}
                     <tr className="tableRow text-center">
                        <td className="table-content text-uppercase text-right">
                           <Translate id={'com.tempedge.msg.label.total'} />
                        </td>
                        {findDate &&
                           Object.keys(findDate).map((currentDay, index) => (
                              <td key={`total-${index}`} className="table-content">
                                 {TotalSumByWeekDay(attendanceWeekList, findDate[currentDay].day)}
                              </td>
                           ))}
                        <td>{TotalAttendanceWeek(attendanceWeekList, findDate)}</td>
                     </tr>
                  </tbody>
               </table>
            </Col>
         </Row>
      </>
   );
};
