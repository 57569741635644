import Axios from 'axios';
import FormData from 'form-data';
import { REACT_APP_BASE_URL_TEMPEDGE, REACT_APP_TEMPEDGE_PASSWORD, REACT_APP_TEMPEDGE_USER, REACT_APP_URL_GET_AUTH_TOKEN } from '../../../components/common/Routes/URLs.js';

let baseUrlTempEdge = REACT_APP_BASE_URL_TEMPEDGE; //***Must change this URL in the actions file as well***

const HttpService = {
  get: async (url) => {
    let response = await Axios({
      url: baseUrlTempEdge + url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    });

    return response;
  },
  post: async (url, data) => {
    //Create New User, Agency & others
    let response = await Axios({
      url: baseUrlTempEdge + url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    });

    return response;
  },
  getAuthToken: async (url, data) => {
    //FR y TempEdge
    let bodyFormData = new FormData();
    bodyFormData.set('username', data.username);
    bodyFormData.set('password', data.password);
    bodyFormData.set('grant_type', data.grant_type);

    return Axios.post(baseUrlTempEdge + url, bodyFormData, {
      headers: {
        Authorization: 'Basic ' + btoa(REACT_APP_TEMPEDGE_USER + ':' + REACT_APP_TEMPEDGE_PASSWORD)
      }
    });
  },
  refreshAuthToken: async (refreshToken) => {
    const bodyFormData = new FormData();
    bodyFormData.set('refresh_token', refreshToken);
    bodyFormData.set('grant_type', 'refresh_token');

    return Axios.post(baseUrlTempEdge + REACT_APP_URL_GET_AUTH_TOKEN, bodyFormData, {
      headers: {
        Authorization: 'Basic ' + btoa(REACT_APP_TEMPEDGE_USER + ':' + REACT_APP_TEMPEDGE_PASSWORD)
      }
    });
  },
  tokenValidation: async (url, token) => {
    let bodyFormData = new FormData();
    bodyFormData.set('token', token);
    return Axios.post(baseUrlTempEdge + url, bodyFormData, {
      headers: {
        Authorization: 'Basic ' + btoa(REACT_APP_TEMPEDGE_USER + ':' + REACT_APP_TEMPEDGE_PASSWORD)
      }
    });
  },
  postMultipart: async (url, body) => {
    const response = await Axios({
      method: 'post',
      url: baseUrlTempEdge + url,
      data: body,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    });

    return response;
  }
};

export default HttpService;
