import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { CardContainer } from '../Components/CardContainer';
import { TableDashboard } from '../Components/TableDashboard';
import { formatToDollars, formatUsNumber } from '../../../utils/ReGexs';

export const CardTable = ({ tableData, translate, onChangeDate, orgName, selectedDate }) => {
    const handleDateChange = (date) => {
        onChangeDate(date);
    };

    return (
        <CardContainer>
            {tableData && (
                <>
                    <Card.Header className='bg-inherit w-100 p-0 pb-4'>
                        <Row className='align-items-start mr-0 mt-n3'>
                            <Col md={12} sm={12} lg={3}>
                                <CardContainer>
                                    <div className='text-center'>
                                        <strong>{orgName}</strong>
                                    </div>
                                </CardContainer>
                            </Col>
                            <Col md={12} sm={12} lg={3}>
                                <CardContainer>
                                    <div className='text-no-wrap'>
                                        <strong> {translate('com.tempedge.msg.label.earnings') + ' : '} </strong>
                                        <span className='span-data-card'> {formatToDollars(tableData?.result?.companyVariation.amount)} </span>
                                    </div>
                                </CardContainer>
                                <CardContainer >
                                    <div className='text-no-wrap'>
                                        <strong>{translate('com.tempedge.msg.label.amountvariation') + ' : '}</strong>
                                        <span className={tableData?.result?.companyVariation?.variationAmount < 0 ? 'positive-variation' : 'negative-variation'}>
                                            {tableData?.result?.companyVariation?.variationAmount} %
                                        </span>
                                    </div>
                                </CardContainer>
                            </Col>
                            <Col md={6} sm={6} lg={3}>
                                <CardContainer >
                                    <div className='text-no-wrap'>
                                        <strong>{translate('com.tempedge.msg.label.hours') + ' : '}</strong>
                                        <span className='span-data-card'> {formatUsNumber(tableData?.result?.companyVariation.hours)} </span>
                                    </div>
                                </CardContainer>
                                <CardContainer >
                                    <div className='text-no-wrap'>
                                        <strong>{translate('com.tempedge.msg.label.hoursvariation') + ' : '}</strong>
                                        <span className={tableData?.result?.companyVariation?.variationHours < 0 ? 'positive-variation' : 'negative-variation'}>
                                            {tableData?.result?.companyVariation?.variationHours} %
                                        </span>
                                    </div>
                                </CardContainer>
                            </Col>
                            <Col md={6} sm={6} lg={3}>
                                <CardContainer >
                                    <div className='text-no-wrap'>
                                        <strong>{translate('com.tempedge.msg.label.employees') + ' : '}</strong>
                                        <span className='span-data-card'> {tableData?.result?.companyVariation.countEmployees} </span>
                                    </div>
                                </CardContainer>
                                <CardContainer >
                                    <div className='text-no-wrap'>
                                        <strong>{translate('com.tempedge.msg.label.employeevariation') + ' : '}</strong>
                                        <span className={tableData?.result?.companyVariation?.variationEmployees < 0 ? 'positive-variation' : 'negative-variation'}>
                                            {tableData?.result?.companyVariation?.variationEmployees} %
                                        </span>
                                    </div>
                                </CardContainer>
                            </Col>
                        </Row>
                    </Card.Header>

                    <div className='table-container'>
                        <TableDashboard translate={translate} data={tableData?.result?.tableData} />
                    </div>

                    <Card.Footer className='w-100 bg-inherit'>
                        <Row className="d-flex justify-content-center align-items-center pt-2">
                            <Col md={6} lg={6} sm={12} className="d-flex justify-content-end">
                                <h3 className='text-center'>
                                    {translate('com.tempedge.msg.label.select') + ' ' + translate('com.tempedge.msg.label.date') + ':'}
                                </h3>
                            </Col>
                            <Col md={6} lg={6} sm={12} className="d-flex justify-content-start">
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    className="form-control tempEdge-input-box"
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={null}
                                    placeholderText={"mm/dd/yyyy"}
                                />
                            </Col>
                        </Row>
                    </Card.Footer>
                </>
            )}
        </CardContainer>
    );
};
