import { useEffect, useState } from 'react';
import TempEdgeApi from '../../../services/TempEdgeApi';
import { REACT_APP_URL_FIND_VARIATION_BY_ORG_ID } from '../../../components/common/Routes/URLs';

const useDashboardTableData = (date) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const orgId = JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId;
      setLoading(true);
      try {
        const { data } = await TempEdgeApi.post({
          url: REACT_APP_URL_FIND_VARIATION_BY_ORG_ID,
          payload: {
            orgId,
            date
          },
        });
        if (data?.status === 200) {
            setData(data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [date]);

  return { data, loading, error };

};

export default useDashboardTableData;

