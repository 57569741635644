
import React, { Component } from 'react';
import InputBox from '../../../components/common/InputBox/InputBox.js';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import Captcha from '../../../components/common/Captcha/Captcha';
import Validate from '../../Validations/Validations';

class WizardCreateNewAgencyEighthPage extends Component {
  constructor(props) {
    super(props);

    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  }

  state = { captchaRef: null, reCaptchaToken: '', btnDisabled: true }

  componentDidUpdate(prevProps) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/registerAgency/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  onChange = (recaptchaToken) => {
    this.setState({
      reCaptchaToken: recaptchaToken,
      btnDisabled: false
    });
  }

  setCaptchaRef = (ref) => {
    this.setState(
      () => {
        return {
          captchaRef: React.createRef(ref)
        }
      });
  }

  generateCaptcha = (formProps) => {
    return <Captcha formProps={formProps} setCaptchaRef={this.setCaptchaRef} onChange={this.onChange} />;
  }

  render() {
    return (
      <div className="wizard-wrapper">
        <h2 className="text-center page-title-agency"><Translate id="com.tempedge.msg.label.newagencyregistration"></Translate></h2>
        <div className="row" style={{ padding: "0 2.2rem" }}>
          <div className="col-12">{this.props.resultBar}</div>
        </div>
        <form onSubmit={this.props.handleSubmit} className="panel-body form-horizontal center-block register-form-agency" style={{ paddingBottom: "0px" }}>
          <div className="form-group row row-agency-name">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2">
                  <label className="control-label pull-right" style={{ paddingTop: 8 }}><Translate id="com.tempedge.msg.label.agencyname"></Translate></label>
                </div>
                <div className="col-md-8" style={{ paddingLeft: 0, paddingRight: 71 }}>
                  <Field name="agencyname" type="text" placeholder="Agency Name" active="disabled" component={InputBox} />
                </div>
              </div>
            </div>
          </div>
          <div className="panel register-form-panel">
            <div className="panel-heading register-header">
              <h2 className="text-center"><Translate id="com.tempedge.msg.label.confirm"></Translate></h2>
            </div>
          </div>
          <div className="register-form-panel-inputs">
            <div className="form-group register-form wizard-register-agency-form row">
              <table className="table table-striped mt-5">
                <thead>
                  <tr>                    
                  <td className="panel-heading register-header text-center" colSpan="2" style={{borderBottomRightRadius:'0', borderBottomLeftRadius:'0'}}>
                    <Translate id="com.tempedge.msg.label.confirminformation"></Translate>
                  </td>
                  </tr>                  
                </thead>
                <tbody>
                  <tr className="tableRow">
                    <td className="table-content" style={{ width: '50%' }}>
                      <Translate id="com.tempedge.msg.label.address"></Translate>
                    </td>
                    <td className="table-content" style={{ width: '50%' }}>
                      {this.props.address || '-'}
                    </td>
                  </tr>
                  <tr className="tableRow">
                    <td className="table-content" style={{ width: '50%' }}>
                      <Translate id="com.tempedge.msg.label.city"></Translate>
                    </td>
                    <td className="table-content" style={{ width: '50%' }}>
                      {this.props.city || '-'}
                    </td>
                  </tr>
                  <tr className="tableRow">
                    <td className="table-content" style={{ width: '50%' }}>
                      <Translate id="com.tempedge.msg.label.addrecruitingoffice"></Translate>
                    </td>
                    <td className="table-content" style={{ width: '50%' }}>
                      {this.props.recruitmentoffice || '-'}
                    </td>
                  </tr>
                  <tr className="tableRow">
                    <td className="table-content" style={{ width: '50%' }}>
                      <Translate id="com.tempedge.msg.label.phonesadded"></Translate>
                    </td>
                    <td className="table-content" style={{ width: '50%' }}>
                      {this.props.phonenumbers || '-'}
                    </td>
                  </tr>
                  <tr className="tableRow">
                    <td className="table-content" style={{ width: '50%' }}>
                      <Translate id="com.tempedge.msg.label.salesmenadded"></Translate>
                    </td>
                    <td className="table-content" style={{ width: '50%' }}>
                      {this.props.salesmen || '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12">
                <div className="new-agency-captcha" style={{ height: 'auto' }}>
                  <Field name='captcha' size="normal" height="130px" theme="light" component={this.generateCaptcha} />
                </div>
              </div>
            </div>
          </div>

          <div className="panel-footer register-footer panel-footer-agency-height-override">
            <div className="prev-next-btns-agency row">
              <div className="col-md-4 offset-md-2">
                <button type="button" className="btn btn-default btn-block register-save-btn previous" onClick={this.props.previousPage}>Back</button>
              </div>
              <div className="col-md-4">
                <button type="submit" className="btn btn-primary btn-block register-save-btn next" disabled={this.props.invalid || this.props.submiting || this.props.pristine || this.state.btnDisabled}><Translate id="com.tempedge.msg.label.save">Save</Translate></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if (state.form.CreateNewAgency && state.form.CreateNewAgency.values) {
    return {
      address: state.form.CreateNewAgency.values.agencyaddress,
      city: state.form.CreateNewAgency.values.agencycity,
      recruitmentoffice: state.form.CreateNewAgency.values.recruitmentofficephonenumbers.length,
      phonenumbers: state.form.CreateNewAgency.values.recruitmentofficephonenumbers.length,
      salesmen: state.form.CreateNewAgency.values.recruitmentofficesalespersons.length
    };
  }
  return null;
};

WizardCreateNewAgencyEighthPage = reduxForm({
  form: 'CreateNewAgency',
  destroyOnUnmount: false,
  validate: Validate
})(WizardCreateNewAgencyEighthPage);

export default withLocalize(connect(mapStateToProps, { push })(WizardCreateNewAgencyEighthPage));
