import SearchForm from './SearchForm';
import { useEffect, useState } from 'react';
import ContainerBlue from '../../../components/common/Container/ContainerBlue';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import {
   clearAttendanceList,
   clearState,
   fetchAttendance,
   fetchSaveAttendance,
} from '../../../Redux/actions/attendanceActions';
import { AttendanceTable } from './AttendanceTable';
import '../../../assets/styles/components/TimeEntry.css';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import OutcomeBar from '../../../components/common/OutcomeBar';
import CancelBtn from '../../../components/common/Buttons/CancelBtn';
import SaveBtn from '../../../components/common/Buttons/SaveBtn';
import Loader from '../../../components/common/Loader/Loader';
import useFetchOfficeList from '../../../Hooks/fetch/useFetchOfficeList';
import Switch from '../../../components/common/Switch/Switch';

const Attendance = ({ translate, activeLanguage, addTranslationForLanguage, history }) => {
   const dispatch = useDispatch();
   const nameCompany = `${JSON.parse(localStorage.getItem('agency')).user.firstName} ${JSON.parse(localStorage.getItem('agency')).user.lastName}`;

   const attendanceData = useSelector((state) => state.attendance.result);
   const message = useSelector((state) => state.attendance.message);
   const loading = useSelector((state) => state.attendance.loading);
 
   const { loading: officeLoading, offices: officeList } = useFetchOfficeList();

   const [prevActiveLanguage, setPrevActiveLanguage] = useState(activeLanguage);
   const clearForm = useSelector((state) => state.attendance.clearForm);

   const [client, setClient] = useState(null);
   const [shift, setShift] = useState(null);
   const [officeSelected, setOfficeSelected] = useState(null);
   const [rangeDate, setRangeDate] = useState(null);
   const [customDate, setCustomDate] = useState(null);
   const [requestJson, setRequestJson] = useState({
      dateList: null,
      startDate: null,
      endDate: null,
      clientId: null,
      orgId: null,
      shift: null,
      downloadReport: false,
   });
   const [checkDownload, setCheckDownload] = useState(false);

   const attendanceLoading = loading || officeLoading;

   useEffect(() => {
      if (clearForm) {
         setClient(null);
         setRangeDate(null);
         setCustomDate(null);
         setOfficeSelected(null);
         setShift(null);
         setRequestJson({
            dateList: null,
            startDate: null,
            endDate: null,
            clientId: null,
            orgId: null,
            shift: null,
            downloadReport: false,
         });
         setCheckDownload(false);
      }
   }, [clearForm]);

   useEffect(() => {
      const hasActiveLanguageChanged = prevActiveLanguage !== activeLanguage;
      if (hasActiveLanguageChanged) {
         history.push(`/attendance/${activeLanguage.code}`);
         ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
         setPrevActiveLanguage(activeLanguage);
      }
   }, [activeLanguage, history, addTranslationForLanguage, prevActiveLanguage, dispatch]);

   useEffect(() => {
      dispatch(clearAttendanceList());
      setCheckDownload(false);
      return () => {
         dispatch(clearState());
      };
   }, [dispatch]);

   const handleSearch = (criteria) => {
      setRequestJson(prevState => ({
         ...prevState,
         ...criteria,
         shift: shift?.shiftId ? String(shift.shiftId) : '',
      }));
      dispatch(fetchAttendance({
         ...criteria,
         shift: shift?.shiftId ? String(shift.shiftId) : '',
      }));
   }

   const saveAttendance = () => {
      dispatch(fetchSaveAttendance({ ...requestJson, clientName: client?.clientName }));
   };

   const buttons = (
      <>
         {!attendanceLoading && attendanceData && attendanceData.length > 0 && (
            <Row className="py-4">
               <Col lg={6} md={6} sm={12}>
                  <CancelBtn />
               </Col>
               <Col md={6} lg={6} sm={12} disabled={true}>
                  <SaveBtn onClick={saveAttendance} invalid={attendanceData?.length <= 0  || !checkDownload} />
               </Col>
            </Row>
         )}
      </>
   );

   if (attendanceLoading) {
      return <Loader />;
   }

   return (
      <>
         <ContainerBlue title="com.tempedge.msg.menu.attendance" btns={buttons}>
            {(message) && <OutcomeBar response={message} />}
            <Col className="margin-bottom 3rem">
               <SearchForm
                  translate={translate}
                  onSearch={handleSearch}
                  officeList={officeList}
                  client={client}
                  setClient={setClient}
                  officeSelected={officeSelected}
                  setOfficeSelected={setOfficeSelected}
                  rangeDate={rangeDate}
                  setRangeDate={setRangeDate}
                  customDate={customDate}
                  setCustomDate={setCustomDate}
                  shift={shift}
                  setShift={setShift}
               />
            </Col>
            {!loading && attendanceData && attendanceData.length > 0 && (
               <>
                  <Col className="time-entry__body">
                     <AttendanceTable attendanceWeekList={attendanceData} translate={translate} />
                  </Col>
                  <Row className=" time-entry__body">
                     <Col  md={6} lg={6} sm={12} className="imput">
                        <p style={{ textAlign: 'left' }}>
                           {translate('com.tempedge.msg.label.clickingconfirm1')} <b>{nameCompany}</b>{' '}
                           {translate('com.tempedge.msg.label.clickingconfirm2')}
                        </p>
                     </Col>
                     <Col md={2} lg={2} sm={6} className="imput">
                        <Switch
                           name="attendance"
                           checked={checkDownload}
                           onChange={(value) => {
                              setCheckDownload(value);
                           }}
                        />
                     </Col>
                     {checkDownload && (
                        <Col md={4} lg={4} sm={8} className="imput ">
                           <Row>
                              <Col md={7} lg={8} sm={12} className="imput">
                                 <p>{translate('com.tempedge.msg.label.download')}</p>
                              </Col>
                              <Col md={2} lg={2} sm={6} className="imput">
                                 <Switch
                                    name="attendance"
                                    checked={requestJson.downloadReport}
                                    onChange={(value) => {
                                       setRequestJson({ ...requestJson, downloadReport: value });
                                    }}
                                 />
                              </Col>
                           </Row>
                        </Col>
                     )}
                  </Row>
               </>
            )}
         </ContainerBlue>
      </>
   );
};

export default withLocalize(Attendance);
