import React from 'react'

export const TagNameContainer = ({tagName}) => {
    return (
        <>
            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                <div className="section-tag mb-8">
                    {tagName}
                </div>
            </div>
        </>
    )
}
