import React from 'react';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import XLSX from 'xlsx';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { tempedgeAPI, getList } from '../../../Redux/actions/tempEdgeActions';
import types from '../../../Redux/actions/types';
import OutcomeBar from '../../../components/common/OutcomeBar';
import CountryRegionParser from '../../../components/common/CountryRegionParser/CountryRegionParser';
import ContainerBlue from '../../../components/common/Container/ContainerBlue';
import UploadFile from '../../../components/common/UploadFile/UploadFile';
import { keyBy, normalizePhone, normalizeSSN } from '../../../utils/ReGexs';
import {
   REACT_APP_URL_COUNTRY_LIST_ALL,
   REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
   REACT_APP_URL_PERSON_SAVE_LIST,
} from '../../../components/common/Routes/URLs.js';
import moment from 'moment';
import TempEdgeApi from '../../../services/TempEdgeApi';
import Loader from '../../../components/common/Loader/Loader';
const defaultCountry = 'United States';

class UploadEmployeeList extends React.Component {
   constructor(props, context) {
      super(props, context);
      this.state = {
         btnDisabled: true,
         barProgress: 0,
         orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
         regionsList: [],
         departments: [],
         filename: '',
         loading: false,
      };
      const { activeLanguage } = this.props;
      const { addTranslationForLanguage } = this.props;
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
   }

   componentDidMount() {
      this.fetchData();
   }

   componentDidUpdate(prevProps) {
      const { activeLanguage, addTranslationForLanguage } = this.props;
      const hasActiveLanguageChanged = prevProps.activeLanguage !== activeLanguage;

      if (hasActiveLanguageChanged) {
         this.props.push(`/employee/uploadlist/${activeLanguage.code}`);
         ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
      }
   }

   async fetchData() {
      const { getList, countryRegionList = [] } = this.props;
      if (countryRegionList.length === 0) {
         const response = await getList(REACT_APP_URL_COUNTRY_LIST_ALL, types.GET_COUNTRY_REGION_LIST);
         if (response?.data?.code === 'TE00') {
            this.setState({
               regionsList: await CountryRegionParser.getRegionList(response.data.result, defaultCountry),
            });
         }
      } else {
         this.setState({
            regionsList: await CountryRegionParser.getRegionList(countryRegionList, defaultCountry),
         });
      }
      const { data: responseDepartments } = await TempEdgeApi.post({
         url: REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL,
         payload: {
            page: 0,
            size: 200,
            filterBy: {},
         },
      });
      if (responseDepartments.status === 200) this.setState({ departments: responseDepartments.result.data.content });
   }

   onChangeFile = (file) => {
      this.setState({
         loading: true,
      });
      this.hideResultBar();
      this.changeProgressbar(0);
      this.setState({ filename: file.name });
      if (
         file &&
         (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/wps-office.xlsx')
      ) {
         const reader = new FileReader();
         // Event Listener for when a file is selected to be uploaded
         reader.onload = (event) => {
            const binaryString = event.target.result;
            this.setState(() => ({
               binaryString,
               btnDisabled: false,
            }));
         };
         // Read Blob as binary
         reader.readAsBinaryString(file);
         this.setState({
            loading: false,
         });
      } else if (file) {
         this.setState(() => ({
            btnDisabled: true,
         }));
         this.showErrorResultBar('com.tempedge.error.invalidfiletype');
         this.setState({
            loading: false,
         });
      }
   };

   upload = async () => {
      this.setState({
         loading: true,
         btnDisabled: true,
      });
      setTimeout(() => {
         this.saveEmployeeList();
      }, 1000);
   };

   saveEmployeeList = async () => {
      const { binaryString, orgId } = this.state;
      const { tempedgeAPI } = this.props;
      this.setState({
         loading: true,
      });
      this.changeProgressbar(0);
      try {
         this.setState({
            loading: true,
         });
         const spreadsheet = XLSX.read(binaryString, {
            type: 'binary',
            cellDates: true,
            cellText: false,
         });
         // Get first worksheet
         const sheetName = spreadsheet.SheetNames[0];
         const sheet = spreadsheet.Sheets[sheetName];
         // Convert excel to json
         const employeeList = XLSX.utils.sheet_to_json(sheet, {
            dateNF: 'YYYY-MM-DD',
            raw: false,
            header: [
               'fein', // a
               'employeeId', // b
               'identification', // c
               'branchName', // d
               'firstName',
               'middleName',
               'lastName',
               'address',
               'address2',
               'city',
               'region', // k
               'zipcode',
               'phone',
               'gender',
               'workCompCode',
               'empClass',
               'birthDay',
               'taxState',
               'workState',
               'hireDate',
               'fedMaritalStatus',
               'fedAllowance',
               'fedAddlAmount',
               'fedAddlPercent',
               'stateMaritalStatus',
               'stateAllowances',
               'stateAddlAmount',
               'stateAddlPercent',
               'primaryABA',
               'primaryAcct',
               'primaryType',
               'primaryBankName',
               'primaryAmt',
               'secondABA',
               'secondAcct',
               'secondType',
               'secondBankName',
               'secondAmt',
               'thirdABA',
               'thirdAcct',
               'thirdType',
               'thirdBankName',
               'thirdAmt',
               'empStatus',
               'termDate',
               'empDepartment',
               'fedFormYear',
               'fedMultJobs',
               'fedDependents',
               'fedOtherIncm',
               'fedOtherDeds',
               'fedTaxExempt',
               'stTaxExempt',
               'maxCheckDate',
            ],
         });
         if (employeeList.length === 0) {
            this.showErrorResultBar('com.tempedge.msg.label.norecordsfound');
            return;
         }
         const listEmployeeTrim = employeeList.map((obj) => {
            const trimmedObj = {};
            for (const key in obj) {
               if (Object.hasOwnProperty.call(obj, key)) {
                  trimmedObj[key] = obj[key].trim();
               }
            }
            return trimmedObj;
         });
         const chunckSize = 300;
         const count = Math.floor(listEmployeeTrim.length / chunckSize);
         const residuos = Math.floor(listEmployeeTrim.length % chunckSize);
         let index = 0;
         let error = null;
         for (let i = 1; i <= count; i++) {
            const done = Math.floor((100 * i) / count); // This is to see progresBar
            let currentEmployees = listEmployeeTrim.slice(index, i * chunckSize);
            currentEmployees = currentEmployees.filter((item) => {
               return item.empDepartment !== 320;
            });

            currentEmployees = currentEmployees.map((item) => {
               return {
                  ...item,
                  maritalStatus: item.fedMaritalStatus,
                  personAux: {
                     stateAllowances: item.stateAllowances,
                     fedAllowance: item.fedAllowance,
                     stateMaritalStatus: item.stateMaritalStatus,
                     fedMaritalStatus: item.fedMaritalStatus,
                     taxState: item.taxState,
                     workState: item.workState,
                  },
               };
            });

            const request = { orgId, personEntityList: currentEmployees };
            this.validateEmployeeList(currentEmployees);
            const { data } = await tempedgeAPI(REACT_APP_URL_PERSON_SAVE_LIST, request, types.SAVE_EMPLOYEE_LIST);
            if (data.code === 'TE-E08') {
               this.showSuccessResultBar(data);
            }
            this.changeProgressbar(done);
            index = i * chunckSize;
            this.setState({
               loading: false,
            });
         }
         if (residuos !== 0) {
            let currentEmployees = listEmployeeTrim.slice(index, listEmployeeTrim.length);
            currentEmployees = currentEmployees.filter((item) => {
               return item.empDepartment !== 320;
            });
            this.validateEmployeeList(currentEmployees);
            currentEmployees = currentEmployees.map((item) => {
               return {
                  ...item,
                  maritalStatus: item.fedMaritalStatus,
                  personAux: {
                     stateAllowances: item.stateAllowances,
                     stateMaritalStatus: item.stateMaritalStatus,
                     fedFormYear: item.fedFormYear,
                     taxState: item.taxState,
                     workState: item.workState,
                  },
               };
            });

            const request = { orgId, personEntityList: currentEmployees };
            const { data } = await tempedgeAPI(REACT_APP_URL_PERSON_SAVE_LIST, request, types.SAVE_EMPLOYEE_LIST);
            if (data.code === 'TE-E08') {
               error = data;
            }
            this.changeProgressbar(100);
         }
         if (error === null) {
            this.showSuccessResultBar({
               status: 200,
               code: 'TE00',
               message: 'com.tempedge.msg.info.msg.success',
            });
            this.setState({
               loading: false,
            });
         } else {
            this.showWarningResultBar(error);
            this.setState({
               loading: false,
            });
         }
         this.setState(() => ({
            btnDisabled: false,
         }));
      } catch (error) {
         this.showErrorResultBar(error);
         this.setState(() => ({
            btnDisabled: false,
         }));
         this.setState({
            loading: false,
         });
      }
   };

   showResponse(response) {
      this.changeProgressbar(100);
      if (response?.status === 200) {
         if (response?.data?.code === 'TE00') {
            this.showSuccessResultBar(response.data);
         } else {
            this.showErrorResultBar(response.data);
         }
      } else {
         this.showErrorResultBar(new Error('com.tempedge.error.undefine'));
      }
      this.resetForm();
   }

   validateEmployeeList(employeeList) {
      let defaultDepartment = {};
      this.state.departments.map((department) => {
         if (department.default) {
            defaultDepartment = department;
         }
         return department;
      });

      const requiredFields = ['empDepartment', 'employeeId', 'identification', 'lastName', 'address', 'city', 'region', 'birthDay'];

      // Index region list by short code
      const regionListKeyedByShortCode = keyBy(this.state.regionsList, 'shortCode');

      // Loop on employee list
      employeeList.forEach((employee) => {
         employee.empDepartment =
            employee.empDepartment && employee.empDepartment !== ' ' && employee.empDepartment !== ''
               ? employee.empDepartment
               : defaultDepartment?.orgDepartmentCode;

         // Check if required fields are in employee data
         requiredFields.forEach((requiredField) => {
            if (!Object.keys(employee).includes(requiredField)) {
               throw new Error('com.tempedge.error.person.partialsave');
            }
         });

         // Format SSN/ID
         employee.identification = normalizeSSN(String(employee.identification));

         // Format Phone
         employee.phone = normalizePhone(String(employee.phone));

         employee.birthDay = moment(employee.birthDay).format('YYYY-MM-DD');
         employee.hireDate = moment(employee.hireDate).format('YYYY-MM-DD');
         employee.termDate = employee.termDate instanceof Date ? moment(employee.termDate).format('YYYY-MM-DD') : null;
         employee.maxCheckDate = moment(employee.maxCheckDate).format('YYYY-MM-DD');

         // If short code does not exist
         const region = employee.region.trim();
         if (!regionListKeyedByShortCode[region]?.regionId) {
            throw new Error('com.tempedge.error.person.partialsave');
         } else {
            // Change region short code by region Id
            employee.region = regionListKeyedByShortCode[region].regionId;
         }
      });
   }

   showResultBarCUstomMessage(data) {
      this.setState({
         resultBar: <OutcomeBar customMessage={data} />,
      });
   }

   showResultBar(data) {
      this.setState({
         resultBar: <OutcomeBar response={data} />,
      });
   }

   hideResultBar() {
      this.setState({
         resultBar: '',
      });
   }

   showSuccessResultBar(data) {
      this.showResultBar(data);
   }

   showErrorResultBar(res) {
      this.showResultBar(res);
   }

   showWarningResultBar(data) {
      this.showResultBar(data);
   }

   changeProgressbar(barProgress) {
      this.setState({
         barProgress,
      });
   }

   resetForm() {
      this.setState(() => ({
         btnDisabled: true,
      }));
   }

   render() {
      const { btnDisabled, barProgress, resultBar } = this.state;

      const btns = (
         <div className="row">
            <div className="col-12">
               <button
                  type="button"
                  onClick={() => {
                     this.upload();
                  }}
                  className="btn btn-blue"
                  disabled={btnDisabled}
               >
                  <Translate id="com.tempedge.msg.label.upload" />
               </button>
            </div>
         </div>
      );

      if (this.state.loading) return <Loader />;

      return (
         <ContainerBlue title="com.tempedge.upload.title.uploademployee" width="40%" btns={btns}>
            <div className="panel-body" onSubmit={(e) => e.preventDefault}>
               <div className="row">
                  <div className="col-12">{resultBar}</div>
               </div>

               <div className="">
                  <div className="col-12">
                     <UploadFile
                        title="com.tempedge.msg.label.upload"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        onFileSelectSuccess={(file) => this.onChangeFile(file)}
                        onFileSelectError={({ error }) => this.showResultBarCUstomMessage(error)}
                     />
                  </div>
               </div>
               {this.state.filename !== '' && (
                  <>
                     <div className="col-12 text-center">{this.state.filename}</div>
                     <div className="col-12 mt-2">
                        <ProgressBar striped animated now={barProgress} label={`${barProgress}%`} />
                     </div>
                  </>
               )}
            </div>
         </ContainerBlue>
      );
   }
}

UploadEmployeeList.propTypes = {
   tempedgeAPI: PropTypes.func.isRequired,
};

const mapStateToProps = ({ tempEdge }) => ({
   countryRegionList: tempEdge.country_region_list,
});

export default withLocalize(
   connect(mapStateToProps, {
      tempedgeAPI,
      push,
      getList,
   })(UploadEmployeeList),
);
