import React, { Component } from 'react';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { tempedgeAPI } from '../../../Redux/actions/tempEdgeActions';
import PaginatedTable from '../../../components/common/Table/PaginatedTable.js';
import { REACT_APP_URL_USER_PENDING_LIST } from '../../../components/common/Routes/URLs.js';

const api_url = REACT_APP_URL_USER_PENDING_LIST;

class PendingUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablePage: 0,
      data: []
    }

    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/pendinguser/pending/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  render() {
    return <PaginatedTable apiUrl={api_url} title="com.tempedge.msg.label.pendingusrlist" parameterKey="com.tempedge.msg.menu.viewuser" />;
  }
}

PendingUser.propTypes = {
  tempedgeAPI: PropTypes.func.isRequired
};

export default withLocalize(connect(null, { push, tempedgeAPI })(PendingUser));