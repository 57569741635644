import React, { useMemo, useRef} from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';


const maxSizeAllowedForFiles = 1e8; // Equals to 10MB -> 10 000 000 bytes

function UploadFile({accept, onFileSelectError, onFileSelectSuccess,fileNames,nameDoc}) {

  const inputElement = useRef();
 
  const labelFile = useMemo(() => {
      return fileNames!==''?'com.tempedge.msg.label.changefile':'com.tempedge.msg.label.choosefile';
  },[fileNames]);

  const handleFileInput = event => {
    const file = event.target.files[0];
    
    if(file === undefined || file === null)
      return;
    if(file.size > maxSizeAllowedForFiles){      
      onFileSelectError({error: `File size cannot exceed more than ${parseInt(maxSizeAllowedForFiles/1024)}MB`});
      return;  
    }else{
      onFileSelectSuccess(file);
    }
  }

  return (
    <div className="d-flex flex-column mt-5 w-100">       
        <input         
          style={{display: 'none'}}
          type="file"
          onChange={event => handleFileInput(event)}
          ref={inputElement}
          accept={accept}
        ></input>
        <button 
          type="button"
          className="btn btn-green w-100" 
          onClick={e => {
            e.preventDefault();
            inputElement.current && inputElement.current.click()
          }}
        >
          <Translate id={labelFile} />
        </button>
        <p>{nameDoc}</p>
    </div>    
  ); 
}

UploadFile.protoTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  accept: PropTypes.string.isRequired,
  onFileSelectSuccess: PropTypes.func.isRequired,
  onFileSelectError: PropTypes.func.isRequired
}

export default withLocalize(UploadFile);