import { Field, useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import TranslatedLabel from "../../../components/common/TranslatedLabel/TranslatedLabel";
import { Translate } from "react-localize-redux";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import moment from "moment";
import { normalizeSSN } from "../../../utils/ReGexs";
import Datepicker from "../../../components/common/Datepicker/Datepicker";

const Information = ({ formatSSN }) => {
  const dataGender = [
    { value: 0, label: <Translate id="com.tempedge.msg.label.gender.male" /> },
    {
      value: 1,
      label: <Translate id="com.tempedge.msg.label.gender.female" />,
    },
    { value: 2, label: <Translate id="com.tempedge.msg.label.select" /> },
  ];
  const { setFieldValue, values, errors, touched } = useFormikContext();

  return (
    <>
      <Row className="form-group">
        <Col sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.ssn" />
          <Field
            id="information.ssn"
            name="information.ssn"
            type="text"
            className={` tempEdge-input-box form-control uppercase ${
              errors.information?.ssn &&
              (touched.information?.ssn || touched.information)
                ? "has-error"
                : ""
            }`}
            maxLength="50"
            onChange={(e) => {
              setFieldValue(
                "information.ssn",
                normalizeSSN(e.target.value, formatSSN)
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.firstname" />
          <Field
            id="information.firstName"
            name="information.firstName"
            type="text"
            className={` tempEdge-input-box form-control uppercase  ${
              errors.information?.firstName &&
              (touched.information?.firstName || touched.information)
                ? "has-error"
                : ""
            }`}
            maxLength="50"
          />
        </Col>
        <Col sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.middlename" />
          <Field
            id="information.middleName"
            name="information.middleName"
            type="text"
            className={` tempEdge-input-box form-control uppercase  ${
              errors.information?.middleName && touched.information?.middleName
                ? "has-error"
                : ""
            }`}
            maxLength="50"
          />
        </Col>
        <Col sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.lastname" />
          <Field
            id="information.lastName"
            name="information.lastName"
            type="text"
            className={` tempEdge-input-box form-control uppercase  ${
              errors.information?.lastName &&
              (touched.information?.lastName || touched.information)
                ? "has-error"
                : ""
            }`}
            maxLength="50"
          />
        </Col>

        <Col sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.birthday" />
          <Datepicker
            customClass={`form-control tempEdge-input-box
              ${
                errors.information &&
                touched.information &&
                errors.information.birthDay &&
                touched.information.birthDay
                  ? "has-error"
                  : ""
              }`}
            selectedValue={values.information?.birthDay}
            onChange={(date) => {
              setFieldValue("information.birthDay", date);
            }}
          />
        </Col>
        <Col sm={12} md={4} lg={4}>
          <TranslatedLabel id="com.tempedge.msg.label.age" />
          <label htmlFor="age" className="control-label">
            {moment().diff(values.information?.birthDay, "years", false)}
            {moment().diff(values.information?.birthDay, "years", false) <
              18 && (
              <div className="text-danger">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "10px",
                  }}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
            )}
          </label>
        </Col>
        <Col sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.gender" />
          <Field
            id="information.gender"
            name="information.gender"
            data={dataGender}
            type="text"
            className={` tempEdge-input-box form-control uppercase  ${
              errors.information?.gender && touched.information?.gender
                ? "has-error"
                : ""
            }`}
            maxLength="50"
            valueField="value"
            textField="label"
            value={{ value: values.information?.gender }}
            component={DropdownList}
            onChange={(e) => setFieldValue("information.gender", e.value)}
            isInvalid={
              errors.information?.gender && touched.information?.gender
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Information;
