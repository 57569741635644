import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import InputBox from '../../../components/common/InputBox/InputBox.js';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import CountryRegionParser from '../../../components/common/CountryRegionParser/CountryRegionParser.js';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import Validate from '../../Validations/Validations';
const defaultCountry = { name: 'United States', countryId: 234 };

class WizardCreateNewUserSecondPage extends Component {
  constructor(props) {
    super(props);

    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  }

  state = {
    mounted: false,
    country_list: [],
    region_list: []
  };

  componentDidMount = async () => {
    const { country, country_region_list } = this.props;
    let regionsList = [];
    if (!country) {
      this.props.dispatch(change('CreateNewClient', 'clientcountry', defaultCountry));
      regionsList = await CountryRegionParser.getRegionList(country_region_list, defaultCountry.name);
    } else {
      regionsList = await CountryRegionParser.getRegionList(country_region_list, country.name);
    }
    this.setState(() => ({
      region_list: regionsList,
      mounted: true
    }));
  };

  async componentDidUpdate(prevProps) {
    
    if(this.props.country){
      if (typeof prevProps.country !== 'undefined' && prevProps.country.countryId !== this.props.country.countryId) {
        let regionsList = await CountryRegionParser.getRegionList(this.props.country_region_list, this.props.country.name);
        this.props.dispatch(change('CreateNewClient', 'clientstate', ''));
        this.setState({
          region_list: regionsList
        });
      }
    }
    
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      let path = this.props.location.pathname.split('/');
      this.props.push(`/client/${path[2]}/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  render() {

    let countryList = this.props.country_region_list;
    let regionList = this.state.region_list;

    return (
      <div className="sign-up-wrapper" style={{ margin: 0 }} ref="createNewUser1">
        <h2 className="text-center page-title-new-client">
            { !this.props.editClient ? <Translate id="com.tempedge.client.title.createclient"></Translate> :
              <Translate id="com.tempedge.client.title.updateclient"></Translate>
            } 
        </h2>
        <div className="row" style={{ padding: '0 2.2rem' }}>
          <div className="col-12">{this.props.resultBar}</div>
        </div>
        <form onSubmit={this.props.handleSubmit} className="panel-body form-horizontal center-block" style={{ paddingBottom: '0px' }}>
          <div className="row new-client-form">
            <div className="col-lg-8 client-col">
              <div className="create-client">
                <div className="new-client-header">
                  <h2>
                    { !this.props.editClient ? <Translate id="com.tempedge.client.title.createclient"></Translate> :
                      <Translate id="com.tempedge.client.title.updateclient"></Translate>
                    } 
                  </h2>
                </div>
                <div className="new-clients-contents">
                  <div className="client-contents">
                    <div className="form-group row">
                      <div className="col-md-7">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.country"></Translate>
                        </label>
                        <Field name="clientcountry" 
                        data={countryList} 
                        valueField="countryId" 
                        textField="name" 
                        category="agency" 
                        component={DropdownList} />
                      </div>
                      <div className="col-md-5">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.city"></Translate>
                        </label>
                        <Field
                          name="clientcity"
                          type="text"
                          category="agency"
                          onInput={({ target }) => (target.value = target.value.replace(/[^a-zA-ZÀ-ú .,-]+/g, ''))}
                          component={InputBox}
                        />
                      </div>
                    </div>
                        <div className="form-group row">
                          <div className="col-md-7">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.agencyaddress"></Translate>
                            </label>
                            <Field
                              name="clientaddress"
                              type="text"
                              category="agency"
                              onInput={({ target }) => (target.value = target.value.replace(/[^a-zA-ZÀ-ú .,\-# 0-9]+/g, ''))}
                              component={InputBox}
                            />
                          </div>
                      <div className="col-md-5">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.agencyaddress2"></Translate>
                        </label>
                        <Field
                          name="clientaddress2"
                          type="text"
                          category="agency"
                          onInput={({ target }) => {
                            target.value = target.value.replace(/[^a-zA-ZÀ-ú .,\-# 0-9]+/g, '');
                            if (target.value.length > 50) {
                              target.value = target.value.slice(0, 50);
                            }
                          }}
                          component={InputBox}
                        />
                      </div>
                        </div>
                    <div className="form-group row bottom-row">
                      <div className="col-md-7">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.state"></Translate>
                        </label>
                        <Field name="clientstate"
                          data={regionList}
                          valueField="regionId"
                          textField="name"
                          category="agency"
                          component={DropdownList} />
                      </div>
                      <div className="col-md-5">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.agencyzipcode"></Translate>
                        </label>
                        <Field
                          name="clientzipcode"
                          type="text"
                          maxlength="10"
                          category="agency"
                          onInput={({ target }) => (target.value = target.value.replace(/[^\d]+/g, ''))}
                          component={InputBox}
                        />
                      </div>
                    </div>
                    <div className="new-clients-footer">
                      <div className="prev-next-btns-agency row">
                        <div className="col-md-5 offset-md-1">
                          <button type="button" className="btn btn-default btn-block register-save-btn previous" onClick={this.props.previousPage}>
                            <Translate id="com.tempedge.msg.label.back" />
                          </button>
                        </div>
                        <div className="col-md-5">
                          <button type="submit" className="btn btn-primary btn-block register-save-btn next" disabled={this.props.invalid}>
                            <Translate id="com.tempedge.msg.label.next" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 dept-col">
              <div className="department-list">
                <div className="department-list-header">
                  <h2>
                    <Translate id="com.tempedge.msg.label.departmentlist" />
                  </h2>
                </div>

                <div className="department-list-contents">
                  <div>
                    {!this.props.renderAddBtnDirty ? (
                      this.props.renderAddBtn()
                    ) : (
                      <div>
                        {this.props.departmentList}
                        {this.props.addDeptBtn}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

WizardCreateNewUserSecondPage = reduxForm({
  form: 'CreateNewClient', //                 <------ form name
  destroyOnUnmount: false, //        <------ preserve form data
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: Validate
})(WizardCreateNewUserSecondPage);

let mapStateToProps = (state) => {
  let selector = formValueSelector('CreateNewClient'); // <-- same as form name

  return {
    country_region_list: state.tempEdge.country_region_list,
    country: selector(state, 'clientcountry')
  };
};

export default withLocalize(connect(mapStateToProps, { push })(WizardCreateNewUserSecondPage));
