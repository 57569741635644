import moment from 'moment';

//calculates the week of a specific company on a given date

function getFirstDayOfWeekWithCustomStart(date, startDay) {
    const toDate = moment(date);

    const currentDay = toDate.isoWeekday(); // Obtiene el día de la semana actual (1-7, donde 1 es Lunes y 7 es Domingo)

    const diff = startDay - currentDay; // Calcula la diferencia para ajustar el inicio de la semana
    const firstDay = moment(toDate).add(diff, 'days').startOf('isoWeek'); // Ajusta el inicio de la semana


    return firstDay.toDate(); // Devuelve el primer día de la semana como objeto Date
}

function week({ date, company }) {
    if (company === null || company === undefined)
        return { startDate: new Date(), endDate: new Date() };

    if (date === null || date === undefined)
        return { startDate: new Date(), endDate: new Date() };

    const payrollSchedule = company.payrollSchedule;
    const dateSpecial = getFirstDayOfWeekWithCustomStart(date, payrollSchedule);
    let startDate = moment(dateSpecial);
    let endDate = moment(startDate).add(6, 'days');

    return { startDate: startDate.toDate(), endDate: endDate.toDate() };
}


function thisWeek({ company }) {
    const date = new Date();
    return week({ date, company });
}

function lastWeek({ company }) {
    const date = new Date(moment(new Date()).subtract(7, "d").format('YYYY-MM-DD'))
    return week({ date, company });
}

  // Función para generar IDs únicos
function generateUniqueId() {
  return "_" + Math.random().toString(36).substr(2, 9);
  }

  function addHistory(dataArray){
    const updatedData = dataArray.map((obj) => {
      const updatedPayrollWeekDTOS = obj.payrollWeekDTOS
        .map((payrollObj) => {
          return { id: generateUniqueId(), ...payrollObj };
        })
        .sort((a, b) => {
          if (a.person.fullName > b.person.fullName) {
            return 1;
          }
          if (a.person.fullName < b.person.fullName) {
            return -1;
          }
          return 0;
        });

      return { ...obj, payrollWeekDTOS: updatedPayrollWeekDTOS };
    });

    return updatedData;
  };

export { addHistory, generateUniqueId, lastWeek, thisWeek, week };



