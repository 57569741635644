import moment from 'moment';
import * as Yup from 'yup';

export const Schema = Yup.object().shape({
   company:  Yup.object().required('Company is required'),
   startDate: Yup.date().required('Start date is required'),
   endDate: Yup.date().required('End date is required'),
   weekDay: Yup.string().required('week day is required'),
   reportType: Yup.string().required('Report type is required'),
});
const today = moment();
export const initialValues = {
    company: '',
    department: '',
    position: '',
    shift: '',
    reportType: 'payroll',
    weekDay: 'CUSTOM',
    startDate: today.clone().startOf("isoWeek").format("MM-DD-YYYY"),
    endDate: today.clone().endOf("isoWeek").format("MM-DD-YYYY"),
    filter: {
       reportByDept: false,
       reportByPosition: false,
       separatedByDay: false,
       showMarkup: false,
   }
}
