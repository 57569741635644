import { push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { tempedgeAPI } from '../../Redux/actions/tempEdgeActions';
import types from '../../Redux/actions/types';
import Loader from '../../components/common/Loader/Loader';
import OutcomeBar from '../../components/common/OutcomeBar';
import { REACT_APP_URL_RESET_PASSWORD, REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_RECAPTCHA_V2_SITE_KEY } from '../../components/common/Routes/URLs';
import { store } from '../../store/store';
import { validateEmail } from '../../utils/ReGexs';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useRecaptchaTimer from '../../Hooks/Recaptcha/useRecaptchaTimer';
import RecaptchaV2 from '../../components/common/Captcha/RecaptchaV2';

const api_url = REACT_APP_URL_RESET_PASSWORD;

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      email: '',
      resultBar: '',
      loading: false,
      recaptchaToken: '',
      recaptchaV2Verified: false, // Añadir estado para verificar reCAPTCHA v2
    }
    this.addTranslationsForActiveLanguage();
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
    if (hasActiveLanguageChanged) {
      this.props.push(`/resetpassword/${this.props.activeLanguage.code}`);
      this.addTranslationsForActiveLanguage();
    }
  }

  addTranslationsForActiveLanguage() {
    const { activeLanguage } = this.props;
    const state = store.getState();
    if (!activeLanguage) {
      return;
    }
    if (activeLanguage.code === 'es') {
      this?.props?.addTranslationForLanguage(state.tempEdge?.lang?.spanish, activeLanguage.code)
    } else {
      this?.props?.addTranslationForLanguage(state.tempEdge?.lang?.english, activeLanguage.code)
    }
  }

  showResultBar(data) {
    this.setState({
      resultBar: <OutcomeBar response={data} />,
    });
  }

  showSuccessResultBar(data) {
    this.showResultBar(data);
  }

  showErrorResultBar(data) {
    this.showResultBar(data);
  }
  
  handleOnChange(e){
    let { name } = e.target
    let { value } = e.target        
    if (!e.target.validity.patternMismatch) {
      e.target.setCustomValidity("")
    }
    if (!e.target.validity.typeMismatch) {    
          e.target.setCustomValidity("");
      }
    this.setState({ [name]: value})
  }

  handleRecaptchaVerify = (token) => {
    if (token) {
      this.setState({ recaptchaToken: token, recaptchaV2Verified: true }); // Actualiza el estado de verificación
      document.getElementById("submit-button").disabled = false; // Habilitar el botón de submit
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
    resultBar: null,
    });

    let host = window.location.origin;
    const payload = {
      email: this.state.email.toUpperCase(),
      username: this.state.user,
      hostname: host,
      recaptchaToken: this.state.recaptchaToken,
    };
    
    this.setState({
      loading: true,
    });

    this.props.tempedgeAPI(api_url, payload, types.RESET_PASSWORD)
    .then(data => {
        if (data.data.status === 200 ) {
          if (this.state.user && this.state.email) {
            this.showSuccessResultBar(data);
            this.setState({ user: '', email: '' })
          } else {
            this.showErrorResultBar(data);
          }
        } else {
          this.showErrorResultBar(data);
        }
        this.setState({
          loading : false
        });
      })
      .catch(e => {        
        this.showErrorResultBar(e)
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { activeLanguage, translate, showRecaptchaV2 } = this.props;
    const login = `/signin/${activeLanguage.code}`;
    const { resultBar, user, email, recaptchaV2Verified } = this.state;
    
    if (this.state.loading) return <Loader />;

    return (
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
        <div className="container login-container" style={{ width: '80vw' }}>
          <div className="row">
            <div className="col-md-12">
              <div className="login-form">
                <div className="panel panel-default login-form-panel">
                  <div className="panel-heading login-header">
                    <h2 className="text-center">
                      <Translate id="com.tempedge.msg.title.recoverpassword" />
                    </h2>
                  </div>
                  <form className="panel-body" onSubmit={this.onSubmit}>
                    <div className="form-group row">
                      <div className="col-12">
                        {resultBar}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-12">
                        <p className="text-left label-p">
                          <Translate id="com.tempedge.msg.label.username" />
                        </p>
                        <input
                          type="text"                
                          className={`form-control tempEdge-input-box `}
                          name='user'
                          minLength={3}
                          maxLength={30}
                          value={user}
                          onChange={(e) => this.handleOnChange(e)}
                          onInvalid={(e) => e.target.setCustomValidity(translate('com.tempedge.msg.menu.usernamerules'))}                      
                        />                      
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-12">
                        <p className="text-left label-p">
                          <Translate id="com.tempedge.msg.label.email" />
                        </p>
                        <input
                          type="email"                       
                          className={`form-control tempEdge-input-box ${email.trim() !== "" && !validateEmail(email) ? 'has-error' : ''}`}
                          name='email'
                          onChange={(e) => this.setState({ [e.target.name]: e.target.value.toUpperCase() })}
                          value={email}    
                          onBlur={(e) => {
                            if (!validateEmail(e.target.value)) {
                              e.target.setCustomValidity(translate('com.tempedge.msg.menu.emailrules'));
                            } else {
                              e.target.setCustomValidity('');
                            }
                          }}                                 
                        />
                      </div>
                    </div>
                    {showRecaptchaV2 && REACT_APP_RECAPTCHA_V2_SITE_KEY && (
                      <div className="form-group mt-3 d-flex justify-content-center">
                        <div 
                          id="recaptcha-v2-container" 
                          style={{
                            border: recaptchaV2Verified ? 'none' : '1px solid red',
                            borderRadius: '4px'
                          }}
                        >
                          <RecaptchaV2 
                            sitekey={REACT_APP_RECAPTCHA_V2_SITE_KEY} 
                            onVerify={this.handleRecaptchaVerify} 
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <div className="col-12">
                        <button 
                          id="submit-button" 
                          type="submit" 
                          className="btn btn-primary btn-block"
                          disabled={showRecaptchaV2 && !recaptchaV2Verified} // Deshabilita si v2 está presente y no verificado
                        >
                          <Translate id="com.tempedge.msg.label.submit"/>
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="panel-footer login-footer">
                    <span className="text-right no-account-query">
                    </span>
                    <span className="text-right register-link">
                      <Link className="create-account" to={login}>
                        <Translate id="com.tempedge.msg.label.sign_in"></Translate>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GoogleReCaptchaProvider>
    );
  }
}

let mapStatetoProps = (state) => ({
  error: state?.tempEdge?.response?.data?.error
});

const mapDispatchToProps = {
  push,
  tempedgeAPI,
};

const WrappedForgotPassword = withLocalize(connect(mapStatetoProps, mapDispatchToProps)(ForgotPassword));

const ForgotPasswordWithRecaptcha = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha(); // Usamos useGoogleReCaptcha aquí
  const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);

  useEffect(() => {
    const recaptchaScript = document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]');
    if (recaptchaScript) {
      recaptchaScript.remove();
    }
  }, []);

  useRecaptchaTimer(() => { 
    setShowRecaptchaV2(true);
  });

  return (
    <WrappedForgotPassword
      {...props}
      executeRecaptcha={executeRecaptcha} // executeRecaptcha aquí
      showRecaptchaV2={showRecaptchaV2} 
    />
  );
}

export default (props) => (
  <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
    <ForgotPasswordWithRecaptcha {...props} />
  </GoogleReCaptchaProvider>
);
