import React, { useEffect, useState } from 'react';
import { Container as BSContainer, ToggleButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../../assets/styles/components/ThreeStateSwitch.css';
import { Translate, withLocalize } from 'react-localize-redux';
import { ToggleButtonGroup } from 'react-bootstrap';

let defaultValues = [true, null, false];
let defaultLabelValues = ['com.tempedge.msg.label.yes', 'com.tempedge.msg.label.na', 'com.tempedge.msg.label.no']

const ThreeStateSwitch = ({ values = defaultValues, labelValues = defaultLabelValues, size = 'sm', isInvalid = false, name, onClick, defaultValue = values[1], currentValue}) => {
  const [selectedState, setSelectedState] = useState(currentValue);

  useEffect(() => {
    setSelectedState(currentValue);
  }, [currentValue])

  const handleClick = (value) => {
      onClick(value);
      setSelectedState(value);
  };

  return (
    <ToggleButtonGroup
      type="checkbox"
      name={name}
      value={selectedState}
    >
      <BSContainer className={`main-container justify-content-start ${isInvalid ? 'main-container-error' : ''}`}>
        <ToggleButton
          name={`${name}first`}
          className={`left-container common-container common-container-${size} ${selectedState === values[0] ? 'selected-blue' : ''}`}
          value={values[0] !== null ? values[0] : ''}
          onClick={() => handleClick(values[0])}
        >
          <Translate id={labelValues[0]} />
        </ToggleButton>
        <ToggleButton
          name={`${name}second`}
          className={`common-container common-container-gray common-container-${size} ${selectedState === values[1] ? 'selected-gray' : ''}`}
          value={values[1] !== null ? values[1] : ''}
          onClick={() => handleClick(values[1])}
        >
          <Translate id={labelValues[1]} />
        </ToggleButton>
        <ToggleButton
          name={`${name}third`}
          className={`right-container common-container common-container-orange common-container-${size} ${selectedState === values[2] ? 'selected-orange' : ''}`}
          value={values[2] !== null ? values[2] : ''}
          onClick={() => handleClick(values[2])}
        >
          <Translate id={labelValues[2]} />
        </ToggleButton>
      </BSContainer>
    </ToggleButtonGroup>
  );
};

ThreeStateSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md']),
  isInvalid: PropTypes.bool,
  values: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  labelValues: PropTypes.array,
  defaultValue: PropTypes.any,
  currentValue: PropTypes.any,
};

export default withLocalize(ThreeStateSwitch);
