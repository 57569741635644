import React, { Component } from "react";
import { reduxForm, FieldArray } from "redux-form";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js";
import { connect } from "react-redux";
import { withLocalize, Translate } from "react-localize-redux";
import { push } from "connected-react-router";
import Validate from "../../Validations/Validations";
import DropdownList from "../../../components/common/Dropdown/DropdownList.js";
import { typeFile } from "../../../utils/helpers/file.js";
import { limitString } from "../../../utils/ReGexs";
import UploadFile from "../../../components/common/UploadFile/UploadFile.js";
import deleteIcon from "../../../components/common/ClientCode/assets/delete.png";
import addIcon from "../../../components/common/ClientCode/assets/plus.png";
import { fileTypeClient } from "../../../components/common/Constants/Constants.js";

class WizardCreateNewClientFourthPage extends Component {
  constructor(props) {
    super(props);
    ActiveLanguageAddTranslation(
      this.props.activeLanguage,
      this.props.addTranslationForLanguage
    );
  }

  state = {
    mounted: false,
    resultBar: "",
    selectTypes: "",
    fileErr: "",
    inputErr: false,
  };

  componentDidMount() {
    this.setState(() => ({
      mounted: true,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged =
      prevProps.activeLanguage !== this.props.activeLanguage;
    const filesupdates =
      prevProps.formValues.fileUpdate !== this.props.formValues.fileUpdate;
    if (hasActiveLanguageChanged) {
      let path = this.props.location.pathname.split("/");
      this.props.push(`/client/${path[2]}/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(
        this.props.activeLanguage,
        this.props.addTranslationForLanguage
      );
    }
    if (filesupdates) {
      this.props?.formValues?.fileUpdate?.map((client, index) => {
        let isEmpty = Object.entries(client).length === 0;
        if (!isEmpty) {
          if (client.type === undefined) this.setState({ inputErr: true });
          else if (client.fileName === undefined)
            this.setState({ fileErr: true });
          else if (client.fileName === "" && client.type === "")
            this.setState({ inputErr: true });
          else {
            this.setState({ inputErr: false });
            this.setState({ fileErr: false });
          }
        } else {
          this.setState({ inputErr: true });
        }
        return client;
      });
    }
  }

  UNSAFE_componentWillReceiveProps = () => {
    if (this.props.resultBar !== "") {
      this.setState(() => ({
        resultBar: this.props.resultBar,
      }));
    }
  };

  link = (fileNames, fileUpdate) => {
    const type = typeFile(fileNames);
    return `data:${type};base64,${fileUpdate}`;
  };

  render() {
    const {
      array: { push },
      handleSubmit,
      resultBar,
      editClient,
      formValues,
    } = this.props;
    return (
      <div
        className="sign-up-wrapper"
        style={{ margin: 0 }}
        ref="createNewUser1"
      >
        <h2 className="text-center page-title-new-client">
          {!editClient ? (
            <Translate id="com.tempedge.client.title.createclient"></Translate>
          ) : (
            <Translate id="com.tempedge.client.title.updateclient"></Translate>
          )}
        </h2>
        <div className="row" style={{ padding: "0 2.2rem" }}>
          <div className="col-12">{resultBar}</div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="form-horizontal center-block panel-body"
          style={{ paddingBottom: "0px" }}
        >
          <div className="row new-client-form">
            <div className="col-lg-8 client-col">
              <div className="create-client">
                <div className="new-client-header">
                  <h2>
                    {<Translate id="com.tempedge.msg.label.files"></Translate>}
                  </h2>
                </div>
                <div className="new-clients-contents">
                  <div className="client-contents">
                    <FieldArray
                      name="fileUpdate"
                      component={(fileUpdate) => (
                        <div className="form-group ">
                          {fileUpdate.fields?.length > 0 ? (
                            fileUpdate?.fields?.map((client, index) => {
                              if (formValues.fileUpdate[index].file !== null) {
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-md-4">
                                      <label className="control-label">
                                        <Translate id="com.tempedge.msg.label.filetype" />
                                      </label>
                                      <DropdownList
                                        name={`fileUpdate.${index}.type`}
                                        data={fileTypeClient}
                                        textField="type"
                                        valueField="type"
                                        value={{
                                          type: formValues.fileUpdate[index]
                                            .type,
                                        }}
                                        onChange={(e) => {
                                          let findType =
                                            formValues.fileUpdate.some(
                                              (item) => {
                                                return item.type === e.type;
                                              }
                                            );
                                          fileUpdate.fields.insert(index, {
                                            type: findType
                                              ? e.type + index
                                              : e.type,
                                            fileName:
                                              formValues.fileUpdate[index]
                                                .fileName,
                                            file: formValues.fileUpdate[index]
                                              .file,
                                          });
                                          this.setState({
                                            selectTypes: e.type + index,
                                          });
                                          fileUpdate.fields.pop();
                                        }}
                                        isInvalid={
                                          !formValues.fileUpdate[index].type
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <label className="control-label">
                                        <Translate id="com.tempedge.msg.label.filename" />
                                      </label>
                                      {typeof formValues.fileUpdate[index]
                                        .file === "string" ? (
                                        <span className="mt-2">
                                          <a
                                            href={this.link(
                                              formValues.fileUpdate[index]
                                                .fileName,
                                              formValues.fileUpdate[index].file
                                            )}
                                            download={
                                              formValues.fileUpdate[index]
                                                .fileName
                                            }
                                          >
                                            {limitString({
                                              value: formValues.fileUpdate[
                                                index
                                              ].fileName
                                                ? formValues.fileUpdate[index]
                                                    .fileName
                                                : "",
                                              limit: 25,
                                              replace: "...",
                                            })}
                                          </a>
                                        </span>
                                      ) : (
                                        <input
                                          className={`form-control tempEdge-input-box 
                                ${this.state.fileErr && "has-error"}`}
                                          disabled={true}
                                          value={limitString({
                                            value: formValues.fileUpdate[index]
                                              .fileName
                                              ? formValues.fileUpdate[index]
                                                  .fileName
                                              : "",
                                            limit: 25,
                                            replace: "...",
                                          })}
                                        />
                                      )}
                                    </div>
                                    <div className="col-md-3 mt-auto">
                                      <UploadFile
                                        accept="application/msword,application/pdf"
                                        onFileSelectSuccess={(files) => {
                                          fileUpdate.fields.insert(index, {
                                            type: formValues.fileUpdate[index]
                                              .type
                                              ? formValues.fileUpdate[index]
                                                  .type
                                              : this.state.selectTypes,
                                            fileName: files.name,
                                            file: files,
                                          });
                                          fileUpdate.fields.pop();
                                          this.setState({ selectTypes: "" });
                                        }}
                                        onFileSelectError={({ error }) =>
                                          this.setState({ fileErr: error })
                                        }
                                        fileNames={
                                          formValues.fileUpdate[index]
                                            .fileNames ?? ""
                                        }
                                        fileUpdate={
                                          formValues.fileUpdate[index].file
                                        }
                                      />
                                    </div>
                                    <div className="col-md-1 mt-auto">
                                      <span
                                        className="pull-right"
                                        title="Remove file"
                                        onClick={() => {
                                          if (
                                            typeof formValues.fileUpdate[index]
                                              .file === "string"
                                          ) {
                                            push(
                                              "fileUpdateDelete",
                                              formValues.fileUpdate[index]
                                            );
                                          }
                                          fileUpdate.fields.remove(index);
                                        }}
                                        style={{ paddingRight: "1.3rem" }}
                                      >
                                        <img
                                          className="delete-icon-phones"
                                          src={deleteIcon}
                                          alt="deleteIcon"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                          ) : (
                            <div className="mt-5 ml-5">
                              <h2>
                                <Translate id="com.tempedge.msg.label.emptyfile" />
                              </h2>
                            </div>
                          )}
                          <div className="col-md-12 mt-5 border-top">
                            <span
                              style={{ paddingRight: "2.2rem" }}
                              className="center-block pull-right add-fieldArray-btn"
                              onClick={() => push("fileUpdate", {})}
                            >
                              <img
                                style={{ width: "42px", paddingTop: "12px" }}
                                src={addIcon}
                                alt="addIcon"
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    />

                    <div
                      className="new-clients-footer"
                      style={{ marginTop: "130px" }}
                    >
                      <div className="prev-next-btns-agency row">
                        <div className="col-md-5 offset-md-1">
                          <button
                            type="button"
                            className="btn btn-default btn-block register-save-btn previous"
                            onClick={this.props.previousPage}
                          >
                            <Translate id="com.tempedge.msg.label.back"></Translate>
                          </button>
                        </div>
                        <div className="col-md-5">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block register-save-btn next"
                            disabled={
                              this.props.invalid ||
                              this.state.inputErr ||
                              this.state.fileErr
                            }
                          >
                            <Translate id="com.tempedge.msg.label.save"></Translate>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 dept-col">
              <div className="department-list">
                <div className="department-list-header">
                  <h2>
                    <Translate id="com.tempedge.msg.label.departmentlist" />
                  </h2>
                </div>
                <div className="department-list-contents">
                  <div>
                    {!this.props.renderAddBtnDirty ? (
                      this.props.renderAddBtn()
                    ) : (
                      <div>
                        {this.props.departmentList}
                        {this.props.addDeptBtn}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

WizardCreateNewClientFourthPage = reduxForm({
  form: "CreateNewClient", //                 <------ form name
  destroyOnUnmount: false, //        <------ preserve form data
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: Validate,
})(WizardCreateNewClientFourthPage);

export default withLocalize(
  connect(null, { push })(WizardCreateNewClientFourthPage)
);
