import moment from "moment";
import React, { useEffect, useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { useDispatch } from "react-redux";
import {
  changeEmployeeValue, deleteAdjustmentTotal
} from "../../Redux/actions/adjustmentActions";
import BlueCommonBtn from "../../components/common/Buttons/BlueCommonBtn";
import GrayCommonBtn from "../../components/common/Buttons/GrayCommonBtn";
import deleteIcon from "../../components/common/ClientCode/assets/delete.png";
import Container from "../../components/common/Container/Container";
import Switch from "../../components/common/Switch/Switch";
import SetTableHeading from "../Payroll/PayrollByWeek/SetTableHeading";
import useHeaderDate from "../Payroll/PayrollByWeek/useHeaderDate";
import { DATE_FORMAT } from "../../components/common/Constants/Constants";

function AdjustmentInfo({
  person,
  week,
  closeModal,
  translate,
  weekActive,
  deleteEntiti,
  adjustment
}) {
 
  const { findDate } = useHeaderDate(week.startDate)
 
  const [confirmation, setConfirmation] = useState(false);
  const [deleteAdjustment, setDeleteAdjustment] = useState([]);
  
  const [personEntities,setPersonEntities]=useState(person)
  const [adjustmentChanged, setAdjustmentChanged] = useState(false); // Bandera de cambios

  const dispatch = useDispatch();

  useEffect(() => {
    setConfirmation(person.confirmation);
    setPersonEntities(person); // Actualizar personEntities si cambia 'person'
  }, [person]);

  const saveinformationEmployee = () => {
    dispatch(
      changeEmployeeValue({
        id: person.id,
        key: "confirmation",
        value: confirmation,
      })
    );
    deleteAdjustment.forEach((adjustmentId) => {
      if (adjustmentId !== undefined) {
        dispatch(deleteAdjustmentTotal(adjustmentId));
      }
    });
    closeModal();
  };

  useEffect(() => {
    if (deleteAdjustment.length > 0 && adjustmentChanged) {
      const filteredEntities = personEntities.adjustmentEntities.filter(
        (adjustment) => !deleteAdjustment.includes(adjustment.adjustmentId)
      );

      setPersonEntities(prevState => ({
        ...prevState,
        adjustmentEntities: filteredEntities
      }));
      setAdjustmentChanged(false); // Restablecer la bandera de cambios
    }
  }, [deleteAdjustment, personEntities, adjustmentChanged]);

  const handleAdjustmentDeletion = (adjustmentId) => {
    setDeleteAdjustment([...deleteAdjustment, adjustmentId]);
    setAdjustmentChanged(true); // Indicar que se han aplicado cambios en los ajustes
  };

  const btn = (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-4">
          <GrayCommonBtn
            tag="com.tempedge.msg.label.cancel"
            onClick={() => {
              closeModal();
            }}
          />
        </div>
        <div className="col-md-4">
          <BlueCommonBtn
            tag="com.tempedge.msg.label.save"
            onClick={() => {
              saveinformationEmployee();
            }}
          />
        </div>
      </div>
    </div>
  );

  const CalcTotal = (key) => {
    let total = 0;
    personEntities.adjustmentEntities.map((employees) => {
      if (isNaN(employees[key])) {
        return (total += 0);
      }
      return (total += parseFloat(employees[key]));
    });
    if (isNaN(total)) return 0;
    return total.toFixed(2);
  };

  return (
    <Container label={personEntities.firstName + " " + personEntities.lastName} btns={btn}>
      <div
        className={
          personEntities.payrollEntityList.length > 0 ||
          personEntities.adjustmentEntities.length > 0
            ? "mx-auto"
            : ""
        }
      >
        {personEntities &&
          personEntities.payrollEntityList.length === 0 &&
          personEntities.adjustmentEntities.length === 0 && (
            <div
              className="font-weight-bold text-uppercase my-4 text-left"
              style={{ color: "var(--tempedge-green)" }}
            >
              {translate("com.tempedge.msg.label.employeewithouthour")}
            </div>
          )}
        {personEntities && personEntities.payrollEntityList.length > 0 && (
          <div
            className="adjustments"
            style={{ borderBottom: "1px dotted gray" }}
          >
            <h3
              className="font-weight-bold text-uppercase my-4"
              style={{ color: "var(--tempedge-green)" }}
            >
              {translate("com.tempedge.msg.label.employeehours")}
            </h3>
            <div className="row">
              <div className="form-check col-12 col-lg-12 mb-4">
                <table className="table table-striped table-input-group">
                  <thead>{SetTableHeading(week.startDate)}</thead>
                  <tbody>
                    {personEntities.payrollEntityList.map((e, index) => {                      
                      return (
                        <tr key={index + 1 + e.person.employeeId}>
                          <td>{`${e?.person?.fullName}`}</td>
                          <td>
                            {e.department !== null
                              ? `${e.department?.name}-${e.position?.name}`
                              : e.position?.name}
                          </td>                          
                          {                          
                          Object.keys(findDate).map((currentDay, index) => {
                            const payrollDate =
                              e[findDate[currentDay].day]?.dateList;
                            return moment(payrollDate).format(DATE_FORMAT) ===
                              findDate[currentDay].date &&
                              (e[findDate[currentDay].day]?.totalRegHour > 0 ||
                                e[findDate[currentDay].day]?.totalOtHour > 0 ||
                                e[findDate[currentDay].day]?.totalRegHour < 0 ||
                                e[findDate[currentDay].day]?.totalOtHour <
                                  0) ? (
                              <td
                                key={index + e.person.employeeId}
                                className="text-center"
                                style={{ fontSize: "13px" }}
                              >
                                RH:{" "}
                                {e[
                                  findDate[currentDay].day
                                ]?.totalRegHour.toFixed(2)}{" "}
                                -{" "}
                                {e[
                                  findDate[currentDay].day
                                ]?.totalOtHour.toFixed(2)}{" "}
                                :OT
                              </td>
                            ) : (
                              <td key={index + 5 + e.person.employeeId}></td>
                            );
                          })}
                          <td className="text-center">
                            {e.totalRegHours ? e.totalRegHours.toFixed(2) : 0}
                          </td>
                          <td className="text-center">
                            {e.totalOTHours ? e.totalOTHours.toFixed(2) : 0}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {personEntities && personEntities.adjustmentEntities.length > 0 && (
          <div className="employee-hours">
            <h3
              className="font-weight-bold text-uppercase my-4"
              style={{ color: "var(--tempedge-green)" }}
            >
              {translate("com.tempedge.msg.label.adjustments")}
            </h3>
            <div className="row">
              <div className="form-check col-12 col-lg-12 mb-4">
                <table className="table table-striped table-input-group">
                  <thead>
                    <tr>
                      <th className="table-header-left text-center w-5">
                        EE #
                      </th>
                      <th className="table-header-mid text-center w-20">
                        {translate("com.tempedge.msg.label.lastname")}
                      </th>
                      <th className="table-header-mid text-center w-20">
                        {translate("com.tempedge.msg.label.firstname")}
                      </th>
                      <th className="table-header-mid text-center w-10">
                        {translate("com.tempedge.msg.label.adjustmentdate")}
                      </th>
                      <th className="table-header-mid text-center w-10">
                        {translate("com.tempedge.msg.label.paymentdate")}
                      </th>
                      <th className="table-header-mid text-center w-15">
                        {translate("com.tempedge.msg.label.deptpos")}
                      </th>
                      <th className="table-header-mid text-center w-5">
                        {translate("com.tempedge.msg.label.pay")}
                      </th>
                      <th className="table-header-mid text-center w-15">
                        {translate("com.tempedge.msg.label.reghours")}
                      </th>
                      <th className="table-header-mid text-center w-15">
                        {translate("com.tempedge.msg.label.billRate")}
                      </th>
                      <th className="table-header-mid text-center w-15">
                        {translate("com.tempedge.msg.label.othours")}
                      </th>
                      <th className="table-header-mid text-center w-15">
                        {translate("com.tempedge.msg.label.otBillRate")}
                      </th>
                      <th className="table-header-mid text-center w-10">
                        {translate("com.tempedge.msg.label.options")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {personEntities &&
                      personEntities.adjustmentEntities.length > 0 &&
                      personEntities.adjustmentEntities.map((item, index) => {
                        return (
                          <tr key={index + item.person.employeeId}>
                            <td className={"text-center"}>
                              {item.person.employeeId}
                            </td>
                            <td className={"text-center"}>
                              {item.person.lastName}
                            </td>
                            <td className={"text-center"}>
                              {item.person.firstName}
                            </td>
                            <td className={"text-center"}>
                              {moment(item.requestDate).format(DATE_FORMAT)}
                            </td>
                            <td className={"text-center"}>
                              {moment(item.paymentDate).format(DATE_FORMAT)}
                            </td>
                            <td className={"text-center"}>
                              {item.department.name +
                                " - " +
                                item.position.name}
                            </td>
                            <td className={"text-center"}>{item.payRate}</td>
                            <td className={"text-center"}>
                              {item.totalRegHour?.toFixed(2) ?? 0.0}
                            </td>
                            <td className={"text-center"}>
                              {item.billRate?.toFixed(2) ?? 0.0}
                            </td>
                            <td className={"text-center"}>
                              {item.totalOtHour?.toFixed(2) ?? "0.00"}
                            </td>
                            <td className={"text-center"}>
                              {item.otBillRate?.toFixed(2) ?? "0.00"}
                            </td>
                            <td className={"text-center"}>
                              <span
                                className="mx-auto"
                                title="Remove file"
                                onClick={() => handleAdjustmentDeletion(item.adjustmentId)}
                              >
                                <img
                                  style={{ width: "18px" }}
                                  className="icon-width"
                                  src={deleteIcon}
                                  alt="Delete icon"
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    <tr className="text-center">
                      <td
                        colSpan="7"
                        className="table-content text-uppercase text-right ml-2"
                      >
                        <Translate id={"com.tempedge.msg.label.total"} />
                      </td>
                      <td>{CalcTotal("totalRegHour")}</td>
                      <td>{CalcTotal("billRate")}</td>
                      <td>{CalcTotal("totalOtHour")}</td>
                      <td>{CalcTotal("otBillRate")}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        <div className="mt-5 mb-5 text-left">
          <div className="d-flex mt-4">
            <Switch
              name="confirmation"
              checked={confirmation}
              children={translate("com.tempedge.msg.label.confirmmsg")}
              size="md"
              align="between"
              onChange={(value) => {
                setConfirmation(value);
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default withLocalize(AdjustmentInfo);
