import React, { Component } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import types from "../../../Redux/actions/types.js";
import { withLocalize, Translate } from "react-localize-redux";
import { connect } from "react-redux";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js";
import {
  tempedgeAPI,
  getList,
  clearTempedgeStoreProp,
} from "../../../Redux/actions/tempEdgeActions";
import PaginatedTable from "../../../components/common/Table/PaginatedTable.js";
import ClientList from "../../Client/ClientList/ClientList";
import Modal from "../../../Modals/Modal/Modal";
import ModalSimple from "../../../Modals/ModalSimple/ModalSimple.js";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import { ViewUserFields } from "./ViewUserFields.js";
import BlueCommonBtn from "../../../components/common/Buttons/BlueCommonBtn";
import GrayCommonBtn from "../../../components/common/Buttons/GrayCommonBtn";
import InputModal from "../../../components/common/Modal/InputModal";
import {
  REACT_APP_URL_AGENCY_APPROVE,
  REACT_APP_URL_AGENCY_FIND_ALL,
  REACT_APP_URL_OFFICE_FIND_ALL,
  REACT_APP_URL_ROLE_VIEW,
  REACT_APP_URL_USER_APPROVE,
  REACT_APP_URL_USER_VIEW,
  REACT_APP_URL_ROLE_LIST_ALL,
  REACT_APP_URL_CLIENT_LIST_ALL,
} from "../../../components/common/Routes/URLs.js";
import { fullName, MapWeekDays } from "../../../utils/ReGexs.js";
import OutcomeBar from "../../../components/common/OutcomeBar/index.js";
import TempEdgeApi from "../../../services/TempEdgeApi.js";
import DropdownList from "../../../components/common/Dropdown/DropdownList.js";
import Loader from "../../../components/common/Loader/Loader.js";

let api;

const filtersNameRols = [
  "TE_ADMIN",
  "CLIENT",
  "CLIENT_PAYROLL",
  "CLIENT_FACEREC",
  "TEMPEDGE_USER",
  "TMP_INTERNAL_EMPLOYEE"
];

class PendingUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: "",
      InputModal: false,
      showClientsModal: false,
      showOfficesModal: false,
      showOrgsModal: false,
      showApproveModal: false,
      showDeniedModal: false,
      resultBar: false,
      filterBy: {},
      actualUser: {},
      approveUser: {},
      clientSelected: {},
      officeSelected: {},
      orgSelected: {},
      listRoles: [],
      isUser: true,
      errorClient:"",
      errorOffice:"",
      errorOrg:"",
      loading:false,
      agency: JSON.parse(localStorage.getItem("agency")),
    };

    ActiveLanguageAddTranslation(
      this.props.activeLanguage,
      this.props.addTranslationForLanguage
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const hasActiveLanguageChanged =
      prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/user/view/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(
        this.props.activeLanguage,
        this.props.addTranslationForLanguage
      );
    }
  }

  async fetchData() {
    const { tempedgeAPI, portalUserConfId } = this.props;

    const payload = {
      filterBy: {
        portalUserConfId: String(portalUserConfId),
      },
    };

    const response = await tempedgeAPI(
      REACT_APP_URL_USER_VIEW,
      payload,
      types.VIEW_USER
    );
    if (response?.data?.result) {
      const isUser = response.data.result.organizationEntity;
      this.setState({
        actualUser: response.data.result,
        isUser,
      });
    }
    const role = String(this.state.actualUser.userRoleId);
    await this.props.tempedgeAPI(
      REACT_APP_URL_ROLE_VIEW,
      { filterBy: { roleId: role } },
      types.GET_ROLE
    );
    const { data: rol } = await TempEdgeApi.get({
      url: REACT_APP_URL_ROLE_LIST_ALL,
    });
    if (rol.status === 200 && rol.code === "TE00") {
      const filteredData = rol.result.filter(obj => !filtersNameRols.includes(obj.name));
      this.setState({ listRoles: filteredData });
    }
  }

  componentWillUnmount() {
    this.props.clearTempedgeStoreProp("viewUser");
    this.setState({
      actualUser: {},
    });
  }

  //Open Modals
  openClientsModal = () => {
    this.createClientTable();
    this.setState({
      showClientsModal: true,
    });
  };

  openOfficesModal = () => {
    this.setState({
      showOfficesModal: true,
    });
  };

  openOrgsModal = () => {
    this.setState({
      showOrgsModal: true,
    });
  };

  openApproveModal = () => {
    const { orgSelected, clientSelected, officeSelected } = this.state;
    let errorOrg = "";
    let errorClient = "";
    let errorOffice = "";

    if (Object.keys(orgSelected).length === 0) {
      errorOrg = "has-error";
    }

    if (Object.keys(clientSelected).length === 0) {
      errorClient = "has-error";
    }

    if (Object.keys(officeSelected).length === 0) {
      errorOffice = "has-error";
    }

    if (errorClient || errorOffice) {
      this.setState({
        errorOrg,
        errorClient,
        errorOffice,
        showApproveModal: !errorOrg,
      });
    } else {
      this.setState({
        errorOrg,
        errorClient,
        errorOffice,
      });
    }
};


  openDeniedModal = () => {
    this.setState({
      showDeniedModal: true,
    });
  };

  //Close Modals
  closeModal = () => {
    this.setState({
      showClientsModal: false,
      showOfficesModal: false,
      showOrgsModal: false,
      showApproveModal: false,
      showDeniedModal: false,
    });
  };

  //Set value for Client Table
  createClientTable() {
    const clientTable = (
      <ClientList onClickRows={(rowData) => this.setClientValue(rowData)} />
    );
    this.setState({
      clientTable,
    });
  }

  setClientValue(selectedData) {
    const [clientId, clientName] = selectedData;
    //TODO buscar el objeto client con el ID seleccionado
    let clientSelected = {
      clientId,
      clientName,
    };
    this.setState({
      clientSelected,
    });

    this.closeModal();
  }

  //Set value for Office Table
  setOfficeValue = (selectedData) => {
    const [officeId, officeName] = selectedData;
    let officeSelected = {
      officeId,
      officeName,
    };
    this.setState({
      officeSelected,
    });
    this.setState({
      actualUser: {
        ...this.state.actualUser,
        officeName: officeName,
      },
    });

    this.closeModal();
  };

  //Set value for Organization Table
  setOrgValue = (selectedData) => {
    const [orgId, orgName] = selectedData;
    let orgSelected = {
      orgId,
      orgName,
    };
    this.setState({
      orgSelected,
    });
    this.setState({
      actualUser: {
        ...this.state.actualUser,
        orgName: orgName,
      },
    });

    this.closeModal();
  };

  //Cancel action function
  cancelAction() {
    this.props.clearTempedgeStoreProp("viewUser");
    this.props.push(`/pendinguser/pending/${this.props.activeLanguage.code}`);
  }

  //Approve or Denied officeEntityList status
  mapOfficeEntityList(officeEntityList, newStatus) {
    for (let i = 0; i < officeEntityList.length; i++) {
      officeEntityList[i].status = newStatus;
    }
  }

  //Function to response
  responseUser() {
    let responseUser = this.props.approveUser.data;
    this.setState({
      resultBar: responseUser,
    });

    if (responseUser?.code === "TE00" && responseUser?.status === 200) {
      this.closeModal();
      setTimeout(() => {
        this.props.clearTempedgeStoreProp("viewUser");
        this.props.push(
          `/pendinguser/pending/${this.props.activeLanguage.code}`
        );
      }, 1000);
    } else {
      this.closeModal();
    }
  }

  //Approve call to API
  ApproveAction = async () => {
    this.setState({loading:true});
    let user = this.state.actualUser;
    user.status = "A";
    let api;
  
    // Verificar si el usuario es un usuario normal o no
    if (this.state.isUser) {
      // Verificar las condiciones para actualizar los valores de oficina y cliente
      if (user.officeName || (user.officeName !== null && user.officeName !== "")) {
        user.officeName = this.state.officeSelected.officeName;
        user.officeId = this.state.officeSelected.officeId;
      } else if (
        user.clientName ||
        (user.clientName !== null && user.clientName !== "")
      ) {
        user.clientName = this.state.clientSelected.clientName;
        user.clientId = this.state.clientSelected.clientId;
      }
  
      // Actualizar la entidad de organización del usuario
      user.organizationEntity.organizationName = this.state.orgSelected.orgName;
      user.organizationEntity.orgId = this.state.orgSelected.orgId;
  
      api = REACT_APP_URL_USER_APPROVE;
    } else {
      // Actualizar el estado de la entidad de la organización
      user.organizationEntity.status = user.status;
  
      // Verificar la existencia y mapear la lista de entidades de oficina si es necesario
      if (
        user.organizationEntity &&
        user.organizationEntity.officeEntityList
      ) {
        this.mapOfficeEntityList(
          user.organizationEntity.officeEntityList,
          user.status
        );
      } else {
        this.setState({
          errorMessage: new Error("com.tempedge.error.undefine"),
        });
        this.closeModal();
        return;
      }
  
      api = REACT_APP_URL_AGENCY_APPROVE;
    }
  
    // Llamar a la API correspondiente con los parámetros dados
    await this.props.tempedgeAPI(api, user, types.APPROVE_USER);
    this.setState({loading:false});
    // Manejar la respuesta del usuario
    this.responseUser();
  };
  

  //Denied call to API
  deniedAction = async () => {
    let user = this.state.actualUser;
    user.status = "R";

    //TODO Cambiar la validación del cuando organizationEntity es nulo (nunca es nulo)
    if (this.state.isUser) {
      api = REACT_APP_URL_USER_APPROVE;
    } else {
      api = REACT_APP_URL_AGENCY_APPROVE;
      user.organizationEntity = {};
      user.organizationEntity.status = user?.status;
      this.mapOfficeEntityList(
        user?.organizationEntity?.officeEntityList
          ? user.organizationEntity.officeEntityList
          : [],
        user.status
      );
    }

    await this.props.tempedgeAPI(api, user, types.APPROVE_USER);
    this.responseUser();
  };

  logoImage(logo) {
    let column;
    if (logo === null || logo === undefined || logo === "") {
      column = <React.Fragment />;
    } else {
      column = (
        <React.Fragment>
          <div className="box">
            <img src={`data:/png;base64,${logo}`} alt={`logo.png`} />
          </div>
        </React.Fragment>
      );
    }

    return column;
  }

  mapOfficesList(offices) {
    let office = (
      <ul>
        {offices.map((item, i) => (
          <li key={i} id={`officeId${item}`}>
            <label className="form-check-label">{item.name}</label>
          </li>
        ))}
      </ul>
    );
    return office;
  }


  menuOption(officeName, clientName, orgSelected) {
    let officeList = (
      <PaginatedTable
        title="com.tempedge.msg.label.officelist"
        apiUrl={REACT_APP_URL_OFFICE_FIND_ALL}
        orgId={this.state.orgSelected.orgId}
        filterBy={{ name: officeName }}
        onClickRows={(e) => this.setOfficeValue(e)}
        parameterKey="com.tempedge.msg.menu.officelist"
      />
    );
    let clientList = (
      <PaginatedTable
        title="com.tempedge.msg.label.clientlist"
        apiUrl={REACT_APP_URL_CLIENT_LIST_ALL}
        orgId={this.state.orgSelected.orgId}
        filterBy={{ clientName: clientName }}
        onClickRows={(e) => this.setClientValue(e)}
        parameterKey="com.tempedge.msg.menu.clientlist"
      />
    );
    let officeModal = (
      <ModalSimple
        open={this.state.showOfficesModal}
        onClose={() => this.closeModal()}
        content={officeList}
      />
    );
    let clientModal = (
      <ModalSimple
        open={this.state.showClientsModal}
        onClose={() => this.closeModal()}
        content={clientList}
      />
    );

    let option;
    if (Object.keys(orgSelected).length > 0) {
      if (this.props.getRole.includes("CLIENT")) {
        option = (
          <React.Fragment>
            <ViewUserFields
              tag={"com.tempedge.msg.label.client"}
              field={
                <InputModal
                  name={"client"}
                  value={this.state.clientSelected.clientName}
                  placeholder={clientName}
                  title={"client"}
                  onClick={() => this.openClientsModal()}
                  styleClass={this.state.errorClient}
                />
              }
            />
            {clientModal}
          </React.Fragment>
        );
      } else {
        option = (
          <React.Fragment>
            <ViewUserFields
              tag={"com.tempedge.msg.label.office"}
              field={
                <InputModal
                  name={"office"}
                  value={this.state.officeSelected.officeName}
                  placeholder={officeName}
                  title={"office"}
                  onClick={() => this.openOfficesModal()}
                  styleClass={this.state.errorOffice}
                />
              }
            />
            {officeModal}
          </React.Fragment>
        );
      }
    }

    return option;
  }

  userType() {
    let UserFields;

    if (!this.state.isUser) {
      UserFields = (
        <React.Fragment>
          <ViewUserFields
            tag={"com.tempedge.msg.label.funding"}
            field={
              this.state.actualUser?.organizationEntity?.funding?.name
                ? this.state.actualUser.organizationEntity.funding.name
                : "---"
            }
          />
          <ViewUserFields
            tag={"com.tempedge.msg.label.payrollhours"}
            field={
              this.state.actualUser?.organizationEntity?.clientPayrollDate
                ? MapWeekDays(
                    this.state.actualUser.organizationEntity.clientPayrollDate
                  )
                : "---"
            }
          />
          <ViewUserFields
            tag={"com.tempedge.msg.label.payrollvalidation"}
            field={
              this.state.actualUser?.organizationEntity?.lastPayrollDate
                ? MapWeekDays(
                    this.state.actualUser.organizationEntity.lastPayrollDate
                  )
                : "---"
            }
          />
          <ViewUserFields
            tag={"com.tempedge.msg.label.organization"}
            field={
              this.state.actualUser?.organizationEntity?.organizationName
                ? this.state.actualUser.organizationEntity.organizationName
                : "---"
            }
          />
          <ViewUserFields
            tag={"com.tempedge.msg.label.officelist"}
            field={this.mapOfficesList(
              this.state.actualUser?.organizationEntity?.officeEntityList
                ? this.state.actualUser.organizationEntity.officeEntityList
                : []
            )}
          />
        </React.Fragment>
      );
    } else {
      UserFields = (
        <React.Fragment>
          <div className="col-5">
            <label className="control-label">
              <Translate id={"com.tempedge.msg.label.role"} />
            </label>
          </div>
          <div className="col-5 mb-4">
            {!this.props.getRole.includes("TMP_INTERNAL_EMPLOYEE") ? (
              this.props.getRole
            ) : (
              <DropdownList
                styles="col-7"
                data={this.state.listRoles}
                valueField="roleId"
                textField="name"
                value={{ roleId: this.state?.actualUser?.userRoleId }}
                onChange={(role) => {
                  this.setState({
                    actualUser: {
                      ...this.state.actualUser,
                      userRoleId: role.roleId,
                      userRoleName: role.name,
                    },
                  });
                }}
                name={"role"}
              />
            )}
          </div>
          <ViewUserFields
            tag={"com.tempedge.msg.label.organization"}
            field={
              <InputModal
                name={"organization"}
                value={this.state.actualUser.orgName}
                placeholder={this.state.actualUser.orgName}
                title={"client"}
                onClick={() => this.openOrgsModal()}
                styleClass={this.state.errorOrg}
              />
            }
          />
          {this.menuOption(
            this.state.actualUser?.officeName,
            this.state.actualUser?.clientName,
            this.state.orgSelected
          )}
        </React.Fragment>
      );
    }

    return UserFields;
  }

  render() {
       

    let approveBtns = (
      <div className="row">
        <div className="col-md-6">
          <GrayCommonBtn
            tag={"com.tempedge.msg.label.cancel"}
            onClick={() => this.closeModal()}
          />
        </div>
        <div className="col-md-6">
          <BlueCommonBtn
            tag={"com.tempedge.msg.label.approve"}
            onClick={() => this.ApproveAction()}
          />
        </div>
      </div>
    );

    let approveContent = (
      <ContainerBlue btns={approveBtns}>
        <p>
          <Translate id="com.tempedge.msg.label.confirmmsg" />
        </p>
      </ContainerBlue>
    );

    let deniedBtns = (
      <div className="row">
        <div className="col-md-6">
          <GrayCommonBtn
            tag={"com.tempedge.msg.label.cancel"}
            onClick={() => this.closeModal()}
          />
        </div>

        <div className="col-md-6">
          <BlueCommonBtn
            tag={"com.tempedge.msg.label.denied"}
            onClick={() => this.deniedAction()}
          />
        </div>
      </div>
    );

    let deniedContent = (
      <ContainerBlue btns={deniedBtns}>
        <p>
          <Translate id="com.tempedge.msg.label.confirmmsg" />
        </p>
      </ContainerBlue>
    );

    let approveModal = (
      <Modal
        content={approveContent}
        open={this.state.showApproveModal}
        onClose={() => this.closeModal()}
        modalSize="modal-sm"
      />
    );

    let deniedModal = (
      <Modal
        content={deniedContent}
        open={this.state.showDeniedModal}
        onClose={() => this.closeModal()}
        modalSize="modal-sm"
      />
    );

    let btns = (
      <div className="row">
        <div className="col-md-4">
          <GrayCommonBtn
            tag={"com.tempedge.msg.label.cancel"}
            onClick={() => this.cancelAction()}
          />
        </div>

        <div className="col-md-4">
          <GrayCommonBtn
            tag={"com.tempedge.msg.label.denied"}
            onClick={() => this.openDeniedModal()}
          />
          {deniedModal}
        </div>

        <div className="col-md-4">
          <BlueCommonBtn
            tag={"com.tempedge.msg.label.approve"}
            onClick={() => this.openApproveModal()}
          />
          {approveModal}
        </div>
      </div>
    );

    let orgList = (
      <PaginatedTable
        title="com.tempedge.msg.label.orglist"
        apiUrl={REACT_APP_URL_AGENCY_FIND_ALL}
        filterBy={{ organizationName: this.state.actualUser.orgName }}
        onClickRows={(e) => {
          this.setOrgValue(e);
        }}
        parameterKey="com.tempedge.msg.menu.orglist"
      />
    );

    let orgModal = (
      <ModalSimple
        open={this.state.showOrgsModal}
        onClose={() => this.closeModal()}
        content={orgList}
      />
    );

    if (this.state.loading) return <Loader /> 

    return (
      <ContainerBlue btns={btns}>
        <div className="col-12">
          {this.state.resultBar && (
            <OutcomeBar response={this.state.resultBar} />
          )}
        </div>
        <div className="row user-padding">
          <div className="col-12 col-md-3" align="middle">
            {this.logoImage(
              this.state.actualUser?.organizationEntity?.logo
                ? this.state.actualUser.organizationEntity.logo
                : ""
            )}
          </div>
          <div className="col-12 col-md-9">
            <div className="row">
              <ViewUserFields
                tag={"com.tempedge.msg.label.firstname"}
                field={fullName(
                  this.state.actualUser?.user?.firstName,
                  this.state.actualUser?.user?.middleName,
                  this.state.actualUser?.user?.lastName
                )}
              />
              <ViewUserFields
                tag={"com.tempedge.msg.label.username"}
                field={
                  this.state.actualUser?.user?.username
                    ? this.state.actualUser.user.username
                    : "---"
                }
              />
              <ViewUserFields
                tag={"com.tempedge.msg.label.email"}
                field={
                  this.state.actualUser?.user?.email
                    ? this.state.actualUser.user.email
                    : "---"
                }
              />
              {this.userType()}
              {orgModal}
            </div>
          </div>
        </div>
      </ContainerBlue>
    );
  }
}

PendingUser.propTypes = {
  clearTempedgeStoreProp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    portalUserConfId: state.tempEdge.portalUserConfId
      ? state.tempEdge.portalUserConfId
      : [],
    getRole: state.tempEdge.getRole?.data?.result?.name
      ? state.tempEdge.getRole.data.result.name
      : [],
    approveUser: state.tempEdge.approveUser ? state.tempEdge.approveUser : [],
  };
};

export default withLocalize(
  connect(mapStateToProps, {
    push,
    getList,
    clearTempedgeStoreProp,
    tempedgeAPI,
  })(PendingUser)
);
