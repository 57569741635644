import Sundial from "./Sundial";
import FingerTech from './FingerTech';

function TimeClock({type = 'Sundial'}){
    
    const timeClocks = {
       'Sundial': Sundial,
       'FingerTech':FingerTech,
    }

    const clock =type === 'FingerTech'? timeClocks[type] : type === 'NO'? {}: timeClocks.Sundial
    
    return clock
}


export default TimeClock;