import React from 'react';
import Tile from './Tile';
import Container from '../../../components/common/Container/Container.js';
import ReactTooltip from 'react-tooltip';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { withLocalize } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { validateOrg } from '../../../Redux/actions/tempEdgeActions.js';


class OrgSelectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client:""
    }
    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/organization-select/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  validator=(org)=>{
    validateOrg(org[0]);    
  }

  render() {
    let content = (
      <div style={{ width: '100%', margin: 40 }}>
        {this.props.login?.portalUserList ? this.props.login.portalUserList.length > 1  ?
        
          this.props.login.portalUserList.map((agency, index) => {
            let orgName = agency.organizationEntity.organizationName;
            let org = agency.organizationEntity?.orgId ? agency.organizationEntity.orgId : []
            return (
              <React.Fragment key={org}>
                <span data-tip data-for={`orgName-${index}`}>
                  <Tile agency={agency} key={index} />
                </span>
                <ReactTooltip id={`orgName-${index}`}>
                  <span>{orgName}</span>
                </ReactTooltip>
              </React.Fragment>
            );
          }): this.validator(this.props.login.portalUserList) : []}
      </div>
    );

    return <Container title="com.tempedge.msg.label.selectorg" children={content} />;
  }
}

let mapStateToProps = (state) => {
  return {
    login: state.tempEdge.login?.portalUserList ? state.tempEdge.login : []
  };
};

export default withLocalize(connect(mapStateToProps, { push })(OrgSelectList));
