const types = {
  'pdf' : 'application/pdf',
  'doc' : 'application/msword',
};

export function typeFile(name){
    if(name === null ||  name === undefined || name === '') return 'application/plain';
    const extension = name.split('.')[1];
    return types[extension] || 'application/plain';
}

export async function base64ToFile({file, name}){
    if(file === null || file === undefined) return '';
    const type = typeFile(name);
    const url = `data:${type};base64,${file}`;
    const blob = await fetch(url).then(res => res.blob());
    return new File([blob], name, {type});
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});




