import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import Modal from '../../../Modals/Modal/Modal';
import { addEmployeeHistory, resetEntireStateHistory } from '../../../Redux/actions/payrollPersonHistoryActions';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import ContainerBlue from '../../../components/common/Container/ContainerBlue';
import Loader from '../../../components/common/Loader/Loader';
import OutcomeBar from '../../../components/common/OutcomeBar';
import {
   REACT_APP_URL_ACCOUNT_FIND_PERSON,
   REACT_APP_URL_ACCOUNT_CREATE,
   REACT_APP_URL_ACCOUNT_DELETE,
} from '../../../components/common/Routes/URLs';
import TempEdgeApi from '../../../services/TempEdgeApi';
import { generateUniqueId } from '../../../utils/PayrollUtils';
import EmployeeList from '../../Employee/EmployeeList/EmployeeList';
import EmployeeTable from '../../../components/common/EmployeeTable/EmployeeTable';
import addIcon from '../../../components/common/ClientCode/assets/plus.png';
import deleteIcon from '../../../components/common/ClientCode/assets/delete.png';
import CancelBtn from '../../../components/common/Buttons/CancelBtn';
import SaveBtn from '../../../components/common/Buttons/SaveBtn';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import { onlyNumbers, onlyNumbersandDecimals, onlyAlphanumeric } from '../../../utils/ReGexs';

const AccountEmployee = ({ activeLanguage, history, addTranslationForLanguage }) => {
   const [prevActiveLanguage, setPrevActiveLanguage] = useState(activeLanguage);
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [message, setMessage] = useState(null);
   const [data, setData] = useState([]);
   const [accounts, setAccounts] = useState([]);
   const [employeeId, setEmployeeId] = useState(0);
   const [employeeName, setEmployeeName] = useState('');
   const [modal, setModal] = useState({
      show: false,
      content: null,
      size: null,
   });
   const [showAccountForm, setShowAccountForm] = useState(false);
   const [deletedAccounts, setDeletedAccounts] = useState([]); // Para almacenar las cuentas eliminadas localmente

   const accountTypes = [
      { value: 'savings', label: 'Savings' },
      { value: 'checking', label: 'Checking' },
   ];

   const clearMessage = () => {
      setMessage(null);
   };

   useEffect(() => {
      if (prevActiveLanguage !== activeLanguage) {
         history.push(`/employee/account/${activeLanguage.code}`);
         ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
         setPrevActiveLanguage(activeLanguage);
      }
   }, [activeLanguage, history, addTranslationForLanguage, prevActiveLanguage]);

   useEffect(() => {
      dispatch(resetEntireStateHistory());
   }, [dispatch]);

   useEffect(() => {
      return () => {
         dispatch(resetEntireStateHistory());
         setData([]);
      };
   }, [dispatch]);

   const employees = useSelector((state) => state?.payrollPersonHistoryReducer?.employeesInList);

   const findEmployee = async () => {
      setMessage(null);
      try {
         setLoading(true);
         const { data: res } = await TempEdgeApi.post({
            url: REACT_APP_URL_ACCOUNT_FIND_PERSON,
            payload: {
               orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
               id: employeeId,
               parameterKey: 'com.tempedge.msg.menu.employee',
            },
         });
         if (res.status === 200 && res.code === 'TE00') {
            setData(res.result);
            setShowAccountForm(true);
            if (res.result.length === 0) {
               setAccounts([{ aba: '', accountNumber: '', accountType: '', bankName: '', amt: '' }]);
            } else {
               setAccounts(
                  res.result.map((account) => ({
                     accountId: account.accountId || null,
                     aba: account.aba || '',
                     accountNumber: account.accountNumber || '',
                     accountType: account.accountType || '',
                     bankName: account.bankName || '',
                     amt: account.amt || '',
                  })),
               );
            }
            // Establecer el nombre del empleado seleccionado
            const selectedEmployee = employees.find((employee) => employee.personId === employeeId);
            if (selectedEmployee) {
               setEmployeeName(`${selectedEmployee.firstName} ${selectedEmployee.lastName}`);
            }
         } else {
            setShowAccountForm(true);
         }
      } catch (error) {
         setMessage(error);
         setShowAccountForm(true);
      } finally {
         setLoading(false);
      }
   };

   const handleSubmitAccounts = async (values) => {
      setLoading(true);
      const { accounts } = values;
      const orgId = JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId;

      // Eliminar cuentas primero
      if (deletedAccounts.length > 0) {
         const deleteRequest = {
            orgId,
            accountEntityList: deletedAccounts.map((accountId) => ({ accountId })),
         };

         try {
            const { data: res } = await TempEdgeApi.post({
               url: REACT_APP_URL_ACCOUNT_DELETE,
               payload: deleteRequest,
            });
            if (res.status === 200 && res.code === 'TE00') {
               const remainingAccounts = accounts.filter((account) => !deletedAccounts.includes(account.accountId));
               setAccounts(remainingAccounts);
               setDeletedAccounts([]);
               setMessage(res);

               // Si no hay cuentas restantes, redirigir a la pantalla de búsqueda
               if (remainingAccounts.length === 0) {
                  setData([]);
                  setShowAccountForm(false);
                  setEmployeeName('');
                  dispatch(resetEntireStateHistory()); // Resetea el estado de los empleados
                  setLoading(false);
                  return;
               }
            } else {
               setMessage(res);
            }
         } catch (error) {
            setMessage(new Error(error));
            setLoading(false);
            return;
         }
      }

      // Luego, guardar cuentas nuevas o actualizadas
      if (accounts && accounts.length > 0) {
         const accountEntityList = accounts.map((account) => ({
            ...(account.accountId && { accountId: account.accountId }),
            aba: account.aba,
            accountNumber: account.accountNumber,
            accountType: account.accountType,
            bankName: account.bankName,
            amt: account.amt,
            moduleId: employeeId,
            module: 'com.tempedge.msg.menu.employee',
         }));

         const request = {
            orgId,
            accountEntityList,
         };

         try {
            const { data: res } = await TempEdgeApi.post({
               url: REACT_APP_URL_ACCOUNT_CREATE,
               payload: request,
            });
            if (res.status === 200 && res.code === 'TE00') {
               setMessage(res);
               setAccounts([{ aba: '', accountNumber: '', accountType: '', bankName: '', amt: '' }]); // Restablecer cuentas
               setData([]);
               setShowAccountForm(false);
               dispatch(resetEntireStateHistory()); // Resetea el estado de los empleados
            } else {
               setMessage(res);
            }
         } catch (error) {
            setMessage(new Error(error));
         } finally {
            setLoading(false);
         }
      } else {
         // Redirigir a la pantalla de búsqueda si no hay cuentas por guardar
         setData([]);
         setShowAccountForm(false);
         setMessage(null);
         dispatch(resetEntireStateHistory());
         setLoading(false);
      }
   };

   const handleLocalRemove = (index, accountId, remove) => {
      setDeletedAccounts([...deletedAccounts, accountId]);
      remove(index);
      if (accounts.length === 1) {
         setShowAccountForm(true); // Redirige a la pantalla de "No records found"
      }
   };

   const selectRow = (event) => {
      const personId = parseInt(event[0]);
      setEmployeeId(personId);
      const selectedEmployee = {
         id: generateUniqueId(),
         personId: event[1],
         firstName: event[5],
         lastName: event[4],
         gender: event[7],
         phone: event[8],
         hiredate: event[9],
      };
      dispatch(addEmployeeHistory(selectedEmployee));
      setEmployeeName(`${selectedEmployee.firstName} ${selectedEmployee.lastName}`);
      clearMessage(); // Limpiar el mensaje al seleccionar un nuevo empleado
   };

   const validationSchema = Yup.object().shape({
      accounts: Yup.array().of(
         Yup.object().shape({
            aba: Yup.string().required('required'),
            accountNumber: Yup.string().required('required'),
            accountType: Yup.string().required('required'),
            bankName: Yup.string().required('required'),
         }),
      ),
   });

   const btn = (
      <Row className="mt-4">
         <Col className="text-right">
            <CancelBtn />
         </Col>
         <Col className="text-left">
            <SaveBtn type="submit" />
         </Col>
      </Row>
   );
   if (loading) return <Loader />;
   return (
      <ContainerBlue
         title={!showAccountForm ? 'com.tempedge.msg.title.employeeaccount' : null}
         label={showAccountForm && employeeName ? employeeName : ''}
         btns={data?.length > 0 && !showAccountForm && btn}
      >
         {message && <OutcomeBar response={message} />}

         {data.length <= 0 && !showAccountForm && (
            <div className="search mt-2">
               <Row className="align-items-end">
                  <Col xs={12} lg={3}>
                     <div className="form-group">
                        <p className="text-left label-p">{<Translate id="com.tempedge.msg.label.selectemployees" />}</p>
                        <button
                           type="button"
                           onClick={() => {
                              setModal({
                                 show: true,
                                 content: (
                                    <EmployeeList
                                       multipleRows={false}
                                       isSelected={(personId) => employees?.find((employee) => employee.personId === personId)}
                                       onClickRows={(event) => {
                                          selectRow(event);
                                          setModal({ show: false, content: null, size: null });
                                       }}
                                    />
                                 ),
                                 size: 'modal-lg',
                              });
                           }}
                           className="btn btn-green w-100"
                        >
                           <Translate id="com.tempedge.msg.label.selectemployees" />
                        </button>
                     </div>
                  </Col>
                  <Col xs={12} lg={{ span: 3, offset: 6 }}>
                     <div className="form-group">
                        <button
                           type="button"
                           onClick={findEmployee}
                           className="btn btn-blue w-100"
                           style={{ marginTop: '2.8rem' }}
                           disabled={employees.length === 0}
                        >
                           <Translate id="com.tempedge.msg.label.find" />
                        </button>
                     </div>
                  </Col>
               </Row>
            </div>
         )}
         {employees && employees.length > 0 && (
            <div>
               {data.length <= 0 && !showAccountForm && <EmployeeTable employees={employees} />}
               {showAccountForm && (
                  <Formik
                     initialValues={{ accounts }}
                     validationSchema={validationSchema}
                     onSubmit={handleSubmitAccounts}
                     enableReinitialize
                  >
                     {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                        <Form className="mx-auto">
                           <FieldArray
                              name="accounts"
                              render={({ push, remove }) => (
                                 <div>
                                    {values.accounts.length > 0 ? (
                                       values.accounts.map((account, index) => (
                                          <React.Fragment key={index}>
                                             <Row
                                                className="form-group pl-8 mt-3 mx-auto justify-content-center"
                                                style={{
                                                   paddingLeft: '80px',
                                                   marginRight: 'auto',
                                                   maxWidth: '100%',
                                                }}
                                             >
                                                <Col sm={12} md={6} lg={2}>
                                                   <label className="control-label">
                                                      <Translate id="com.tempedge.msg.label.aba" />
                                                   </label>
                                                   <input
                                                      style={{ fontSize: '12px', textAlign: 'center' }}
                                                      type="text"
                                                      name={`accounts.${index}.aba`}
                                                      value={account.aba}
                                                      placeholder="***"
                                                      className={`form-control tempEdge-input-box ${errors.accounts && touched.accounts ? (errors.accounts[index]?.aba ? 'has-error' : '') : ''}`}
                                                      onChange={(e) => {
                                                         e.target.value = onlyNumbers(e.target.value);
                                                         handleChange(e);
                                                      }}
                                                      onBlur={handleBlur}
                                                   />
                                                </Col>
                                                <Col sm={12} md={6} lg={2}>
                                                   <label className="control-label">
                                                      <Translate id="com.tempedge.msg.label.accountnumber" />
                                                   </label>
                                                   <input
                                                      style={{ fontSize: '12px', textAlign: 'center' }}
                                                      type="text"
                                                      name={`accounts.${index}.accountNumber`}
                                                      value={account.accountNumber}
                                                      placeholder="Account Number"
                                                      className={`form-control tempEdge-input-box ${errors.accounts && touched.accounts ? (errors.accounts[index]?.accountNumber ? 'has-error' : '') : ''}`}
                                                      onChange={(e) => {
                                                         e.target.value = onlyNumbers(e.target.value);
                                                         handleChange(e);
                                                      }}
                                                      onBlur={handleBlur}
                                                   />
                                                </Col>
                                                <Col sm={12} md={6} lg={2}>
                                                   <label className="control-label">
                                                      <Translate id="com.tempedge.msg.label.accountype" />
                                                   </label>
                                                   <DropdownList
                                                      data={accountTypes}
                                                      textField="label"
                                                      valueField="value"
                                                      placeholder="Select"
                                                      className={`form-control ${errors.accounts && touched.accounts ? (errors.accounts[index]?.accountType ? 'has-error' : '') : ''}`}
                                                      value={
                                                         account.accountType
                                                            ? { label: account.accountType, value: account.accountType }
                                                            : null
                                                      }
                                                      onChange={(value) => {
                                                         setFieldValue(`accounts.${index}.accountType`, value.value);
                                                      }}
                                                   />
                                                </Col>
                                                <Col sm={12} md={6} lg={2}>
                                                   <label className="control-label">
                                                      <Translate id="com.tempedge.msg.label.bankname" />
                                                   </label>
                                                   <input
                                                      style={{ fontSize: '12px', textAlign: 'center' }}
                                                      type="text"
                                                      name={`accounts.${index}.bankName`}
                                                      value={account.bankName}
                                                      placeholder="Bank Name"
                                                      className={`form-control tempEdge-input-box ${errors.accounts && touched.accounts ? (errors.accounts[index]?.bankName ? 'has-error' : '') : ''}`}
                                                      onChange={(e) => {
                                                         e.target.value = onlyAlphanumeric(e.target.value);
                                                         handleChange(e);
                                                      }}
                                                      onBlur={handleBlur}
                                                   />
                                                </Col>
                                                <Col sm={12} md={6} lg={2}>
                                                   <label className="control-label">
                                                      <Translate id="com.tempedge.msg.label.amount" />
                                                   </label>
                                                   <input
                                                      style={{ fontSize: '12px', textAlign: 'center' }}
                                                      type="text"
                                                      name={`accounts.${index}.amt`}
                                                      value={account.amt}
                                                      placeholder="0.0"
                                                      className={`form-control tempEdge-input-box ${errors.accounts && touched.accounts ? (errors.accounts[index]?.amt ? 'has-error' : '') : ''}`}
                                                      onChange={(e) => {
                                                         e.target.value = e.target.value.replace(onlyNumbersandDecimals, '');
                                                         handleChange(e);
                                                      }}
                                                      onBlur={handleBlur}
                                                   />
                                                </Col>
                                                <Col sm={12} md={6} lg={2} className="d-flex align-items-end justify-content-end pr-5">
                                                   <span
                                                      className="px-3"
                                                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                                                      title="Remove account"
                                                      onClick={() => handleLocalRemove(index, account.accountId, remove)}
                                                   >
                                                      <img className="delete-icon" src={deleteIcon} alt="deleteIcon" />
                                                   </span>
                                                </Col>
                                             </Row>
                                             <div
                                                style={{
                                                   borderBottom: 'dotted 1px #626262',
                                                   marginBottom: '15px',
                                                }}
                                             ></div>
                                          </React.Fragment>
                                       ))
                                    ) : (
                                       <div className="mt-8 ml-5">
                                          <h2>
                                             <Translate id="com.tempedge.msg.label.noaccountsfound" />
                                          </h2>
                                       </div>
                                    )}
                                    <Row className="form-group">
                                       <Col md={12} className="list-item text-center">
                                          <span
                                             className="center-block pull-right add-fieldArray-btn"
                                             onClick={() => {
                                                push({
                                                   aba: '',
                                                   accountNumber: '',
                                                   accountType: '',
                                                   bankName: '',
                                                   amt: '',
                                                });
                                             }}
                                             disabled={values.accounts.length >= 5}
                                             style={{ cursor: 'pointer' }}
                                          >
                                             <img
                                                style={{
                                                   width: '42px',
                                                   paddingTop: '12px',
                                                   marginRight: '20px',
                                                }}
                                                src={addIcon}
                                                alt="addIcon"
                                             />
                                          </span>
                                       </Col>
                                    </Row>
                                 </div>
                              )}
                           />
                           <Row className="mt-4">
                              <Col className="text-right">
                                 <CancelBtn
                                    onCancel={() => {
                                       dispatch(resetEntireStateHistory());
                                       setData([]);
                                    }}
                                 />
                              </Col>
                              <Col className="text-left">
                                 <SaveBtn type="submit" />
                              </Col>
                           </Row>
                        </Form>
                     )}
                  </Formik>
               )}
            </div>
         )}
         <Modal
            open={modal.show}
            content={modal.content}
            onClose={() => setModal({ show: false, content: null, size: null })}
            modalSize={modal.size ?? 'modal-sm'}
         />
      </ContainerBlue>
   );
};

export default withLocalize(AccountEmployee);
