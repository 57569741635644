import { useEffect, useState } from "react";
import TempEdgeApi from "../../../services/TempEdgeApi";
import { getListClientAll, updateMessage } from "../../../Redux/actions/tempEdgeActions";
import { REACT_APP_URL_CLIENT_LIST } from "../../../components/common/Routes/URLs";

const useFetchClientList = (orgId, dispatch) => {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(true);


  useEffect(() => {
    const fetchClientList = async () => {
      setLoading(true);
      try {
        const { data: companies } = await TempEdgeApi.post({
          url: REACT_APP_URL_CLIENT_LIST,
          payload: {
            orgId,
          },
        });
  
        if (mounted && companies?.status === 200) {
          dispatch(getListClientAll(companies.result));
        }
  
      } catch (error) {
        dispatch(updateMessage(new Error(error)));
      }
    };
  
    fetchClientList();
    setLoading(false);
    return () => {
      setMounted(false);
    }
  }, [orgId, dispatch, mounted]);

  return { loading };
};

export default useFetchClientList;
