import { Translate } from "react-localize-redux";
import { REACT_APP_URL_FIND_PAYROLLCODE } from "../Routes/URLs";

// date format constant
export const DATE_FORMAT = "MM/DD/YYYY";

// date format constant for api calls (MM/DD/YYYY) for a reports configuration
export const DATE_FORMAT_API = "MM/DD/YYYY";

// date format constant for api calls (YYYY/MM/DD) for a reports configuration
export const DATE_FORMAT_API_GENERATE = "YYYY-MM-DD";

// country default EEUU
export const DEFAULT_COUNTRY = 234;

// equals to 27 minutes
export const TIME_OUT_TO_NOTIFY_IN_MILISECONDS = 1000 * 60 * 27;

// equals to 30 minutes
export const REFRESH_TOKEN_INTERVAL_IN_SECONDS = 60 * 10;

// equal to 60 seconds
export const TIME_TO_SHOW_THE_TIMER_IN_SECONDS = 60;

export const MAIN_OFFICE_NAME = "MAIN OFFICE";

// update files for type
export const fileType = [
  { type: "Job application" },
  { type: "Resume" },
  { type: "Job offer - signed contract" },
  { type: "W4" },
  { type: "Signed employee handbook" },
  { type: "Performance reviews" },
  { type: "Training" },
  { type: "Warnings - disciplinary action" },
  { type: "Non-compete" },
  { type: "Resignation letter" },
  { type: "Insurance docs" },
  { type: "Drivers Lisc" },
  { type: "I9" },
  { type: "401k communication - receipt" },
  { type: "Ins communication - receipt " },
  { type: "Background check" },
  { type: "E verify" },
  { type: "DMV checks" },
  { type: "Forklift Certificates - Card" },
  { type: "Drug test results or if completed" },
  { type: "Injury claim" },
];

export const fileTypeClient = [
  { type: "Company Info" },
  { type: "Contracts" },
  { type: "Prior Assignments" },
];

export const globalparameterlist = [
  {
    label: "Payroll Type",
    key: "com.tempedge.global.param.custompayrolltype",
    find: REACT_APP_URL_FIND_PAYROLLCODE,
    save: "/api/globalParams/saveCustomPayrollType",
  },
  {
    label: "Time Off Type",
    key: "com.tempedge.global.param.timeofftype",
    find: REACT_APP_URL_FIND_PAYROLLCODE,
    save: "/api/globalParams/saveTimeOffType",
  },
  {
    label: "Marital Status Type",
    key: "com.tempedge.global.param.maritalstatus",
    find: REACT_APP_URL_FIND_PAYROLLCODE,
    save: "/api/globalParams/saveMaritalStatus",
  },
  {
    label: "Release Notes",
    key: "com.tempedge.global.param.releasenotes",
    find: REACT_APP_URL_FIND_PAYROLLCODE,
    save: "/api/globalParams/saveReleaseNote",
  },
  {
    label: "Dictionary",
    key: "com.tempedge.global.param.dictionary",
    find: REACT_APP_URL_FIND_PAYROLLCODE,
    save: "/api/globalParams/saveDictionary",
  },
];

export const sizePagesList = 30;

// diferent status marital
export const maritalStatus = [
  {
    value: 1,
    label: <Translate id={"com.tempedge.msg.label.maritalstatus.single"} />,
  },
  {
    value: 2,
    label: <Translate id={"com.tempedge.msg.label.maritalstatus.married"} />,
  },
  {
    value: 3,
    label: (
      <Translate id={"com.tempedge.msg.label.maritalstatus.marriedfillsep"} />
    ),
  },
  {
    value: 4,
    label: (
      <Translate id={"com.tempedge.msg.label.maritalstatus.marriedworking"} />
    ),
  },
  {
    value: 5,
    label: (
      <Translate
        id={"com.tempedge.msg.label.maritalstatus.marriedoneworking"}
      />
    ),
  },
  {
    value: 6,
    label: (
      <Translate id={"com.tempedge.msg.label.maritalstatus.headhousehold"} />
    ),
  },
  {
    value: 7,
    label: (
      <Translate id={"com.tempedge.msg.label.maritalstatus.marriedmultiempl"} />
    ),
  },
  {
    value: 8,
    label: <Translate id={"com.tempedge.msg.label.maritalstatus.widow"} />,
  },
  {
    value: 9,
    label: (
      <Translate id={"com.tempedge.msg.label.maritalstatus.marriednoliving"} />
    ),
  },
  {
    value: 10,
    label: (
      <Translate id={"com.tempedge.msg.label.maritalstatus.marriedjointall"} />
    ),
  },
  {
    value: 11,
    label: (
      <Translate id={"com.tempedge.msg.label.maritalstatus.marriedjointhalf"} />
    ),
  },
  {
    value: 12,
    label: (
      <Translate
        id={"com.tempedge.msg.label.maritalstatus.marriedseparateall"}
      />
    ),
  },
  {
    value: 13,
    label: (
      <Translate id={"com.tempedge.msg.label.maritalstatus.marriedjointnon"} />
    ),
  },
  {
    value: 14,
    label: (
      <Translate
        id={"com.tempedge.msg.label.maritalstatus.marriedlivingspouse"}
      />
    ),
  },
  {
    value: 15,
    label: (
      <Translate
        id={"com.tempedge.msg.label.maritalstatus.marriedwitholdsingle"}
      />
    ),
  },
  {
    value: 16,
    label: <Translate id={"com.tempedge.msg.label.maritalstatus.civilunion"} />,
  },
  {
    value: 17,
    label: (
      <Translate
        id={"com.tempedge.msg.label.maritalstatus.civilunionwithhold"}
      />
    ),
  },
];



export const shiftList = [
  { shiftName: <Translate id={"com.tempedge.msg.label.1st"} />, shiftId: 1 },
  { shiftName: <Translate id={"com.tempedge.msg.label.2nd"} />, shiftId: 2 },
  { shiftName: <Translate id={"com.tempedge.msg.label.3rd"} />, shiftId: 3 },
];
