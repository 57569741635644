import React from 'react'

export const SectionContainer = ({ shadow, children }) => {
    return (
        <>
            <section className={`section-global ${shadow ? shadow : ''}`}>
                <div className="container">
                    {children}
                </div>
            </section>
        </>
    )
}
