import { FieldArray } from 'formik';

function Skills(props) {
   return (
      <div className="tab">
         <div className="row">
            <FieldArray
               name={'skills'}
               render={(arrayHelper) => (
                  <>
                     <div className="col-md-6">
                        {typeof props.skills !== 'undefined'
                           ? props.skills
                                .filter((_, index) => index < (props.skills.length - 1) / 2)
                                .map((item) => (
                                   <div key={item.skillId} className="skill-item form-check d-flex align-items-center">
                                      <input
                                         className={'mr-2'}
                                         id={`skillId${item.skillId}`}
                                         name={'skills'}
                                         type="checkbox"
                                         value={item.skillId}
                                         checked={props.formik.values.skills.includes(item.skillId)}
                                         onChange={(event) => {
                                            if (event.target.checked) {
                                               arrayHelper.push(item.skillId);
                                            } else {
                                               const index = props.formik.values.skills.indexOf(item.skillId);
                                               arrayHelper.remove(index);
                                            }
                                         }}
                                      />
                                      <label htmlFor={`skillId${item.skillId}`} className="mb-0" style={{ fontSize: '1.6rem' }}>
                                         {item.skill}
                                      </label>
                                   </div>
                                ))
                           : ''}
                     </div>
                     <div className="col-md-6">
                        {typeof props.skills !== 'undefined'
                           ? props.skills
                                .filter((_, index) => index > (props.skills.length - 1) / 2)
                                .map((item) => (
                                   <div key={item.skillId} className="skill-item form-check d-flex align-items-center">
                                      <input
                                         className={'mr-2'}
                                         id={`skillId${item.skillId}`}
                                         name={'skills'}
                                         type="checkbox"
                                         value={item.skillId}
                                         checked={props.formik.values.skills.includes(item.skillId)}
                                         onChange={(event) => {
                                            if (event.target.checked) {
                                               arrayHelper.push(item.skillId);
                                            } else {
                                               const index = props.formik.values.skills.indexOf(item.skillId);
                                               arrayHelper.remove(index);
                                            }
                                         }}
                                      />
                                      <label htmlFor={`skillId${item.skillId}`} className="mb-0" style={{ fontSize: '1.6rem' }}>
                                         {item.skill}
                                      </label>
                                   </div>
                                ))
                           : ''}
                     </div>
                  </>
               )}
            />
         </div>
      </div>
   );
}

export default Skills;
