import PropTypes from 'prop-types';

/**
 * Calcular la tarifa de pago de una persona, con base en el margen de ganancia del cliente y
 * si es en horario regular o extra
 *
 * @param {Pago de la persona} payRate
 * @param {Margen de ganancia en horario regular / Extra} markup
 * @param {Flag para determinar si el payRate es con base en un horario regular o extra} isOverTime
 */
const CalculateBillRates = (payRate, markup, isOverTime) => {
  let billRate = 0;
  if (isOverTime) {
    billRate = payRate * (1 + (markup / 100)) * 1.5;
  } else {
    billRate = payRate * (1 + (markup / 100));
  }

  return billRate.toFixed(2);
};

CalculateBillRates.propTypes = {
  payRate: PropTypes.number.isRequired,
  markup: PropTypes.number.isRequired,
  isOverTime: PropTypes.number
};

export default CalculateBillRates;
