import { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import UploadFile from './UploadFile';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

class FileUpload extends Component {
   constructor(props) {
      super(props);
      this.addTranslationsForActiveLanguage();
   }

   async addTranslationsForActiveLanguage() {
      await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   }

   async componentDidUpdate(prevProps) {
      const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
      if (hasActiveLanguageChanged) {
         await this.props.push(`/uploadClientCodes/${this.props.activeLanguage.code}`);
         this.addTranslationsForActiveLanguage();
      }
   }

   render() {
      return <UploadFile />;
   }
}

export default withLocalize(connect(null, { push })(FileUpload));
