import React,{Component} from 'react';
import { withLocalize } from 'react-localize-redux';
import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Adjustment from './Adjustment';


class Adjustments extends Component {

   constructor(props){
      super(props);
      //language
      this.addTranslationsForActiveLanguage();
   }

   addTranslationsForActiveLanguage = async () => {
      await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   };

   async componentDidUpdate(prevProps) {
      let hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
      if (hasActiveLanguageChanged) {
         await this.props.push(`/adjustment/new/${this.props.activeLanguage.code}`);
         this.addTranslationsForActiveLanguage();
      }
   }

   render(){
      return <Adjustment/>;
   }

}

export default  withLocalize(
    connect(null,{push})(Adjustments)
);
