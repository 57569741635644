import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Modals/Modal/Modal";
import { resetEntireState as resetAdjustments } from "../../Redux/actions/adjustmentActions";
import {
  addEmployee,
  removeEmployee,
  resetEntireStateCustom,
} from "../../Redux/actions/customPayrollActions";
import { resetEntireState as resetDailyList } from "../../Redux/actions/dailyListActions";
import types from "../../Redux/actions/types";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import Loader from "../../components/common/Loader/Loader";
import OutcomeBar from "../../components/common/OutcomeBar";
import {
  REACT_APP_URL_DELETE_CUSTOM_PAYROLL,
  REACT_APP_URL_FIND_PAYROLLCODE,
  REACT_APP_URL_GET_ACTIVE_WEEK,
  REACT_APP_URL_PAYROLL_FIND_BY_WEEK,
  REACT_APP_URL_SAVE_CUSTOM_PAYROLL,
} from "../../components/common/Routes/URLs";
import TempEdgeApi from "../../services/TempEdgeApi";
import EmployeeList from "../Employee/EmployeeList/EmployeeList";
import CustomPayrollTable from "./CustomPayrollTable";
//import CustomPayrollSameData from "./CustomPayrollSameData";
import TitleMenusDataTables from "../../utils/TitleMenusDataTables";
const dateCustom = "YYYY-MM-DD";
const customParameterKey = "com.tempedge.msg.menu.payroll";


const CustomPayroll = (props) => {

  const orgIdFind = JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId;
  const [loading, setLoading] = useState(false);
  const [dataGlobal, setDataGlobal] = useState([]);
  const [message, setMessage] = useState(null);
  const [selectClient, setSelectClient] = useState(null);
  const [errors, setErrors] = useState({});
  const [weekActive, setWeekActive] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [modal, setModal] = useState({
    show: false,
    content: null,
    size: null,
  });
  const [sameData, setSameData] = useState(false);

  const dispatch = useDispatch();
  const clientsListRedux = useSelector((state) => {
    return state.tempEdge.clientList;
  });

  const clients = useMemo(() => clientsListRedux, [clientsListRedux]);

  const employees = useSelector((state) => {
    return state.customPayrollReducer.employeesInCustomPayroll;
  });
  const customPayrollEntityList = useSelector((state) => {
    return state.customPayrollReducer.payrollEntityList;
  });
  const customPayrollDelete = useSelector((state) => {
    return state.customPayrollReducer.deletedItems;
  });

  const getCustomCode = useCallback(async () => {
    setLoading(true)
    try {
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_FIND_PAYROLLCODE,
        payload: {
          parameterKey: "com.tempedge.global.param.custompayrolltype",
        },
      });
      if (res.status === 200 && res?.result?.data?.content?.length > 0) {
        setDataGlobal(res.result?.data?.content);
      }
    } catch (error) {
      setMessage(error);
    }finally{
      setLoading(false)
    }
  }, []);

  const reset = useCallback(() => {
    dispatch(
      resetEntireStateCustom(),
      setSelectClient(null),
      resetDailyList(),
      resetAdjustments()
    );
    dispatch({
      type: types.CLEAR_PROP,
      payload: "paginatorList",
    });
  }, [dispatch]);

  useEffect(() => {
    getCustomCode();
  }, [getCustomCode]);

  useEffect(() => {
    reset();

    return () => {
      dispatch(resetEntireStateCustom());
      setSelectClient(null);
    };
  }, [reset, dispatch]);


  async function selectRow(event) {
    const isRowSelected =
      !!event.currentTarget.classList.value.includes("table-active");
    const cellContent = [];
    event.currentTarget.querySelectorAll("td").forEach((td) => {
      cellContent.push(td.textContent);
    });
    const personId = parseInt(event.currentTarget.dataset.id);
    if (isRowSelected) {
      dispatch(
        addEmployee({
          personId,
          employeeId: cellContent[0],
          firstName: cellContent[4],
          lastName: cellContent[3],
        })
      );
    } else {
      dispatch(removeEmployee(personId));
    }
  }

  const selectCompany = useCallback(
    async (value) => {
      setMessage(null);
      try {
        const { data: res } = await TempEdgeApi.post({
          url: REACT_APP_URL_GET_ACTIVE_WEEK,
          payload: {
            clientId: value.clientId,
          },
        });
        if (res.status === 200) {
          setWeekActive({
            start: res.result.startDate,
            end: res.result.endDate,
          });
          setErrors({ ...errors, company: null });
        }
      } catch (error) {
        setMessage(new Error(error));
      } finally {
        setLoading(false);
      }
    },
    [errors]
  );

  const validationPayroll = () => {
    setMessage(null);
    for (let employee of employees) {
      if (!employee.confirmation) {
        return false;
      }
    }
    return true;
  };

  const findPayrollWeek = async () => {
    setLoading(true);
    const request = {
      orgId: orgIdFind,
      clientId: selectClient?.clientId?.toString(),
      shift: "",
      departmentId: "",
      positionId: "",
      parameterKey: customParameterKey,
      dateList: moment().format(dateCustom),
      startDate: weekActive.start,
      endDate: weekActive.end,
    };

    try {
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_PAYROLL_FIND_BY_WEEK,
        payload: request,
      });
      if (res.status === 200 && res.code === "TE00") {
        const objetosUnicos = res.result.filter((objeto, index, self) =>
        index === self.findIndex((t) => (
          t.person.personId === objeto.person.personId 
        ))
      );
      objetosUnicos.map((data) => {
          const { person } = data;
          const isSelect=employees.find(
            (employee) => employee.personId === person.personId
          )
          if(!isSelect){
          dispatch(
            addEmployee({
              personId: person.personId,
              employeeId: person.employeeId,
              firstName: person.firstName,
              lastName: person.lastName,
            })
          );}
          return data;
        });
      }else{
        setMessage(res)
      }
    } catch (error) {
      setMessage(new Error(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (validationPayroll()) {
      try {
        const modifiedData = customPayrollEntityList.map((item) => {
          const modifiedItem = { ...item };

          if (
            item.billingCode &&
            (item.billingCode.customPayrollTypeId === null ||
              item.billingCode.customPayrollTypeId === 0)
          ) {
            modifiedItem.billingCode = null;
          }

          if (
            item.payCode &&
            (item.payCode.customPayrollTypeId === null ||
              item.payCode.customPayrollTypeId === null)
          ) {
            modifiedItem.payCode = null;
          }

          return modifiedItem;
        });
        if (customPayrollDelete.length > 0) {
          let errorArray = [];
          for (let i = 0; i < customPayrollDelete.length; i++) {
            try {
              const element = customPayrollDelete[i];
              if (element.customPayrollId) {
                TempEdgeApi.post({
                  url: REACT_APP_URL_DELETE_CUSTOM_PAYROLL,
                  payload: {
                    orgId: orgIdFind,
                    id: element.customPayrollId,
                  },
                });
              }
            } catch (error) {
              errorArray.push(error);
              break; // Detiene el bucle si se produce un error.
            }
          }
          setMessage(errorArray[0]);
        }
        if (message === null && modifiedData.length > 0) {
          const request = {
            dateList: weekActive.end,
            clientId: selectClient.clientId,
            shift: 1, //dato quemado , necesito saber de donde lo puedo tomar
            payrollEntityList: modifiedData,
          };
          setLoading(true);
          const { data: res } = await TempEdgeApi.post({
            url: REACT_APP_URL_SAVE_CUSTOM_PAYROLL,
            payload: request,
          });
          setMessage(res);
          if (res.status === 200 || res.code === "TE00") {
            dispatch(resetEntireStateCustom(), setSelectClient(null));
            setSelectClient(null);
          }
        } else {
          dispatch(resetEntireStateCustom(), setSelectClient(null));
          setSelectClient(null);
        }
      } catch (error) {
        setMessage(new Error(error));
      } finally {
        setLoading(false);
      }
    } else {
      setMessage(new Error("com.tempedge.error.confirmationhours"));
    }
  };

  return (
    <ContainerBlue
      title="com.tempedge.msg.title.deductionsreimbursements"
      label={moment(weekActive.end).format("MM-DD-YYYY")}
    >
      {message && <OutcomeBar response={message} />}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="search row flex mt-2 align-items-end mx-auto">
            <div className="col-4 d-flex justify-content-start">
              <div className="form-group col-9 ">
                <p className="text-left label-p">
                  {<Translate id="com.tempedge.msg.label.company" />}
                </p>
                {clients?.length > 1 && (
                  <DropdownList
                    data={clients}
                    textField={"clientName"}
                    valueField={"clientId"}
                    value={selectClient}
                    isInvalid={errors.company ? true : false}
                    onChange={(value) => {
                      setSelectClient(value);
                      selectCompany(value);
                      dispatch(resetEntireStateCustom());
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-4 d-flex justify-content-start">
              <div className="form-group col-9">
                <p className="text-left label-p">
                  {<Translate id="com.tempedge.msg.label.currentweekworkforce" />}
                </p>
                <button
                  type="button"
                  onClick={() => findPayrollWeek()}
                  className="btn btn-green w-100"
                  disabled={selectClient ? false : true}
                >
                  {props.translate("com.tempedge.msg.label.currentweekworkforce")}
                </button>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-start">
              <div className="form-group col-9 ">
                <p className="text-left label-p">
                  {<Translate id="com.tempedge.msg.label.selectemployees" />}
                </p>
                <button
                  type="button"
                  onClick={() => {
                    setModal({
                      show: true,
                      content: (
                        <EmployeeList
                          multipleRows
                          selectRow={(event) => selectRow(event)}
                          onClickRows={() => {
                            setModal({
                              show: false,
                              content: null,
                              size: null,
                            });
                          }}
                          isSelected={(personId) =>
                            employees.find(
                              (employee) => employee.personId === personId
                            )
                          }
                        />
                      ),
                      size: "modal-lg",
                    });
                  }}
                  className="btn btn-green w-100"
                  disabled={selectClient ? false : true}
                >
                  {props.translate("com.tempedge.msg.label.selectemployees")}
                </button>
              </div>
            </div>
            
          </div>

          {employees.length > 0 && (
            <div className={"time-sheet"}>
              <div className="">
                <div>
                  {/* descomentar este codigo cuando se habilite la opcion de same data custom payroll */}
                  {/* {sameData && (
                    <div className="col-12 ">
                      <div className="">
                        <div className="py-1 green-text font-weight-bold text-uppercase">
                          {props.translate(
                            "com.tempedge.msg.label.samehour"
                          )}
                        </div>
                        <CustomPayrollSameData
                          weekActive={weekActive}
                          dataGlobal={dataGlobal}
                          setSameData={setSameData}
                        />
                      </div>
                    </div>
                  )} */}
                  <div
                    className={`${
                      sameData
                        ? "time-entry__header col-12 mt-4 mx-auto "
                        : "col-12 mx-auto"
                    }`}
                  >
                    <TitleMenusDataTables
                      title={"com.tempedge.msg.menu.custompayroll"}
                      sameData={sameData}
                      employees={employees}
                      setSameData={setSameData}
                      isSame={false}
                    />
                  </div>
                </div>
              </div>
              <CustomPayrollTable
                weekActive={weekActive}
                messageppl={setMessage}
                dataGlobal={dataGlobal}
                client={selectClient}
                customPayrollEntityList={customPayrollEntityList}
                customPayrollDelete={customPayrollDelete}
              />
              <div className="row justify-content-end mt-4">
                <div className="form-group col-12 col-lg-3">
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(resetEntireStateCustom(), setSelectClient(null));
                    }}
                    className="btn btn-gray square-right-side w-100"
                  >
                    {props.translate("com.tempedge.msg.label.cancel")}
                  </button>
                </div>
                <div className="form-group col-12 col-lg-3">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-blue square-left-side w-100"
                  >
                    {props.translate("com.tempedge.msg.label.save")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <Modal
        open={modal.show}
        content={modal.content}
        onClose={() => setModal({ show: false, content: null, size: null })}
        modalSize={modal.size ?? "modal-sm"}
      />
    </ContainerBlue>
  );
};

export default withLocalize(CustomPayroll);
