import { payrollPersonHistoryType } from "../types/payrollPersonHistoryType";


export const addEmployeeHistory = (payload) => ({
   type: payrollPersonHistoryType.ADD_PERSON_PAYROLL_HISTORY,
   payload
});

export const removeEmployeeHistory = (personId) => ({
  type: payrollPersonHistoryType.REMOVE_PERSON_PAYROLL_HISTORY,
  payload: {personId}
});

export const resetEntireStateHistory = () => ({
   type: payrollPersonHistoryType.RESET_ENTIRE_STATE,
});


