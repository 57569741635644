import React,{useMemo} from 'react';

function InfoModal(props) {

  const {value, isModal, onClick} = props; 

  const Modal =  useMemo(() => {
      if(isModal)
        return (
            <div className="input-group-append">
              <button className="btn btn-green" title={"Info"} onClick={onClick} type="button" style={{zIndex: 'unset'}}>
                &#128712;
              </button>
            </div>
        );
      return null;
  },[isModal, onClick]);

  return (
      <div className="row">
        <div className="col-10 info-modal" disabled>
          <p>
            {value ?  value : 'N/A'}
          </p>
        </div>
        {Modal}
      </div>
  );
}

export default InfoModal;