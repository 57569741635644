import React from 'react';
import { Table } from 'react-bootstrap';
import '../dashboard.css';
import { formatToDollars, formatUsNumber } from '../../../utils/ReGexs';

export const TableDashboard = ({ translate, data }) => {
    const renderColumnHeaders = () => (
        <tr>
            {data?.columns?.map((column, index) => (
                <th key={index}>{column.label ? translate(column.label) : ""}</th>
            ))}
        </tr>
    );

    const renderRowData = (row) => (
        <tr>
            <td className='table-first-td-style'>{row.clientName}</td>
            <td>{formatToDollars(row.amount)}</td>
            <td className={row.variationAmount < 0 ? 'positive-variation' : 'negative-variation'}>
                {row.variationAmount} %
            </td>
            <td>{formatUsNumber(row.hours)}</td>
            <td className={row.variationHours < 0 ? 'positive-variation' : 'negative-variation'}>
                {row.variationHours} %
            </td>
            <td>{row.countEmployees}</td>
            <td className={row.variationEmployees < 0 ? 'positive-variation' : 'negative-variation'}>
                {row.variationEmployees} %
            </td>
        </tr>
    );

    return (
        <>
            
            {data?.data !== null && (
                <Table borderless responsive="sm" className='table-variation-style'>
                    <thead className='t-head-style table-header-sticky'>
                        {renderColumnHeaders()}
                    </thead>
                    <tbody>
                        {data?.data?.map((row, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    {[...Array(data?.columns?.length)].map((_, cellIndex) => (
                                        <td key={cellIndex}></td>
                                    ))}
                                </tr>
                                {renderRowData(row)}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            )}
            {(data?.data === undefined || data?.data === null || data?.data.length === 0) && (
                <div className='text-center my-6 py-6'>{translate('com.tempedge.error.noresult')}</div>
            )}
        </>
    );
};
