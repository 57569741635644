import React from 'react'

export const ImageCard = ({ imgSrc, alt }) => {
    return (
        <>
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center img-width">
                <img src={imgSrc} className="img-fluid card-container image-container" alt={alt} />
            </div>
        </>
    )
}
