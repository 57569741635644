import { useEffect, useState } from 'react';
import { DropdownClient } from '../../../components/common/Dropdown/DropdownClient';
import { Col, Row } from 'react-bootstrap';
import useWeekDates from '../../../Hooks/useWeekDates';
import DropdownClientWeekRange from './DropdownClientWeekRange';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { clearFieldInvalid, updateActiveWeek, updateFieldInvalid, updateMessage } from '../../../Redux/actions/attendanceActions';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import useFetchActiveWeek from '../../../Hooks/fetch/useFetchActiveWeek';

const initialRequestJson = {
   dateList: null,
   startDate: null,
   endDate: null,
   clientId: null,
   orgId: null,
   shift: null,
};

const SearchForm = ({
   translate,
   onSearch,
   officeList,
   client,
   setClient,
   officeSelected,
   setOfficeSelected,
   rangeDate,
   setRangeDate,
   customDate,
   setCustomDate,
   shift,
   setShift,
}) => {
   const dispatch = useDispatch();

   const [requestJson, setRequestJson] = useState(initialRequestJson);

   const { weekDates, fetchWeekDates } = useWeekDates();
   const { response } = useFetchActiveWeek({
      clientId: client?.clientId || null,
      dateList: customDate || null,
      startDate: rangeDate?.value === 'THISWEEK' ? weekDates.thisWeekInit : rangeDate?.value === 'LASTWEEK' ? weekDates.lastWeekInit : null,
   });

   const clearForm = useSelector((state) => state.attendance.clearForm);
   const isInvalidClientList = useSelector((state) => state.attendance.companyListInvalid);
   const isPayPeriodInvalid = useSelector((state) => state.attendance.payPeriodInvalid);
   const isCustomDateInvalid = useSelector((state) => state.attendance.customDateInvalid);
   const isSelecteOfficeInvalid = useSelector((state) => state.attendance.officeInvalid);

   const shiftList = [
      { shiftName: translate('com.tempedge.msg.label.all'), shiftId: null },
      { shiftName: translate('com.tempedge.msg.label.1st'), shiftId: 1 },
      { shiftName: translate('com.tempedge.msg.label.2nd'), shiftId: 2 },
      { shiftName: translate('com.tempedge.msg.label.3rd'), shiftId: 3 },
   ];

   useEffect(() => {
      if (clearForm) {
         setClient(null);
         setRangeDate(null);
         setRequestJson(initialRequestJson);
         setCustomDate(null);
         setOfficeSelected(null);
         setShift(null);
      }
   }, [clearForm]);

   useEffect(() => {
      if (client) {
         fetchWeekDates(client);
      }
   }, [client, fetchWeekDates]);

   useEffect(() => {
      if (response) {
         const start = response?.result?.startDate;
         const end = response?.result?.endDate;
   
         const updatedRequestJson = {
            ...requestJson,
            startDate: start,
            endDate: end,
            clientId: client?.clientId,
            orgId:  JSON.parse(localStorage.getItem("agency"))?.organizationEntity
            ?.orgId,
            parameterKey: 'com.tempedge.msg.menu.attendance',
            officeId: officeSelected?.officeId,
            shift: shift?.shiftId ? String(shift.shiftId) : '',
         };
         setRequestJson(updatedRequestJson);
      }
   }, [client, rangeDate, customDate, weekDates, response, officeSelected, shift]);

   const renderCustomDateField = () => (
      <Col className="form-group" lg={3} md={6} sm={12}>
         <p className="text-left label-p">{translate('com.tempedge.msg.label.date')}</p>
         <DatePicker
            onChange={(date) => {
               setCustomDate(date);
               dispatch(updateFieldInvalid('customDate', false));
            }}
            selected={customDate}
            value={customDate}
            className="form-control tempEdge-input-box"
            showMonthDropdown
            showYearDropdown
            maxDate={null}
            placeholderText={'mm/dd/yyyy'}
         />
      </Col>
   );

   const handleSearchClick = () => {
      if (rangeDate === null || (rangeDate.value === 'CUSTOM' && customDate === null)) {
         dispatch(updateFieldInvalid('customDate', true));
         dispatch(updateMessage(new Error('com.tempedge.error.fieldsrequired')));
         return;
      }
      if (!client) {
         dispatch(updateFieldInvalid('companyList', true));
         dispatch(updateMessage(new Error('com.tempedge.error.fieldsrequired')));
         return;
      }
      if (!officeSelected) {
         dispatch(updateFieldInvalid('office', true));
         dispatch(updateMessage(new Error('com.tempedge.error.fieldsrequired')));
         return;
      }

      dispatch(clearFieldInvalid('customDate'));
      dispatch(clearFieldInvalid('companyList'));
      dispatch(clearFieldInvalid('payPeriod'));
      dispatch(clearFieldInvalid('office'));

      dispatch(updateActiveWeek(response?.result?.active));
      if (response?.result?.active === false) {
         dispatch(updateMessage(new Error('com.tempedge.error.weeknotactive')));
      } else {
         dispatch(updateMessage(null));
      }
      onSearch(requestJson);
   };

   return (
      <>
         <Row className="mt-2">
            <Col className="form-group" md={6} lg={3} sm={12}>
               <p className="text-left label-p">{translate('com.tempedge.msg.label.office')}</p>
               <DropdownList
                  data={officeList}
                  value={officeSelected}
                  valueField="officeId"
                  textField="name"
                  onChange={(optionSelected) => {
                     setOfficeSelected(optionSelected);
                  }}
                  isInvalid={isSelecteOfficeInvalid}
               />
            </Col>

            <Col className="form-group" md={6} lg={3} sm={12}>
               <p className="text-left label-p">{translate('com.tempedge.msg.label.company')}</p>
               <DropdownClient
                  translate={translate}
                  onChange={(value) => {
                     setClient(value);
                     setRequestJson(initialRequestJson);
                     setCustomDate(null);
                     dispatch(updateFieldInvalid('companyList', false));
                  }}
                  showDetails={false}
                  isInvalid={isInvalidClientList}
                  reset={clearForm}
                  value={client}
               />
            </Col>
            <Col className="form-group" md={6} lg={3} sm={12}>
               <p className="text-left label-p">{translate('com.tempedge.msg.label.shift')}</p>
               <DropdownList
                  value={shift}
                  data={shiftList}
                  valueField="shiftId"
                  textField="shiftName"
                  onChange={(shiftSelected) => {
                     setShift(shiftSelected);
                     setRequestJson((prevState) => ({
                        ...prevState,
                        shift: shiftSelected?.shiftId ? String(shiftSelected.shiftId) : '',
                     }));
                  }}
               />
            </Col>
         </Row>
         <Row className="justify-content-start">
            <Col className="form-group" md={6} lg={3} sm={12}>
               <p className="text-left label-p">{translate('com.tempedge.msg.label.selectweek')}</p>
               <DropdownClientWeekRange
                  client={client}
                  onChange={(value) => {
                     setCustomDate(null);
                     setRangeDate(value);
                     setRequestJson(initialRequestJson);
                     dispatch(updateFieldInvalid('payPeriod', false));
                  }}
                  isInvalid={isPayPeriodInvalid || isCustomDateInvalid}
                  value={rangeDate}
               />
            </Col>
            {rangeDate?.value === 'CUSTOM' && renderCustomDateField()}

            <Col className="form-group" md={6} lg={3} sm={12}>
               <button
                  type="button"
                  onClick={handleSearchClick}
                  className="btn btn-blue w-100 "
                  style={{ marginTop: '2.8rem' }}
                  disabled={
                     client === null ||
                     client === undefined ||
                     officeSelected === null ||
                     officeSelected === undefined ||
                     rangeDate === null ||
                     rangeDate === undefined
                  }
               >
                  {translate('com.tempedge.msg.label.find')}
               </button>
            </Col>
         </Row>
      </>
   );
};

export default SearchForm;
