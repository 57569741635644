import moment from "moment";
import { Translate } from "react-localize-redux";
import useHeaderDate from "../../PayrollByWeek/useHeaderDate";

const SetTableHeadingResult = (dateStart) => {
  const { findDate } = useHeaderDate(dateStart);

  return (
    <tr>
      <th className="table-header-left text-center w-5">
        <Translate id={"com.tempedge.msg.label.name"} />
      </th>
      <th className="table-header-mid text-center w-5">
        <Translate id={"com.tempedge.msg.label.deptpos"} />
      </th>
      {Object.keys(findDate).map((currentDay, index) => {
        const day = moment()
          .day(findDate[currentDay].day)
          .format("dddd")
          .toLowerCase();
        return (
          <th key={index + 1} className="table-header-mid text-center w-5">
            <Translate id={`com.tempedge.msg.label.${day}`} />
            <br></br>
            {findDate[currentDay].date ? findDate[currentDay].date : ""}
          </th>
        );
      })}
      <th className="table-header-mid text-center w-5">
        {<Translate id={"com.tempedge.msg.label.reghours"} />}
      </th>
      <th className="table-header-mid text-center w-5">
        {<Translate id={"com.tempedge.msg.label.othours"} />}
      </th>
      <th className="table-header-right text-center w-5">
        <Translate id={"com.tempedge.msg.label.attendance"} />
      </th>
    </tr>
  );
};

export default SetTableHeadingResult;

export const TableHeading = (dateStart,editPayrate) => {

  const { findDate } = useHeaderDate(dateStart);

  return (
    <tr>
      <th className="table-header-mid text-center w-10">
        <Translate id={"com.tempedge.msg.label.deptpos"} />
      </th>
      {Object.keys(findDate).map((currentDay, index) => {
        const day = moment()
          .day(findDate[currentDay].day)
          .format("dddd")
          .toLowerCase();
        return (
          <th key={index + 1} className="table-header-mid text-center w-10">
            <Translate id={`com.tempedge.msg.label.${day}`} />
            <br></br>
            {findDate[currentDay].date ? findDate[currentDay].date : ""}
          </th>
        );
      })}
      <th className="table-header-mid text-center w-5">
        {<Translate id={"com.tempedge.msg.label.reghours"} />}
      </th>
      <th className="table-header-mid text-center w-5">
        {<Translate id={"com.tempedge.msg.label.othours"} />}
      </th>
      {editPayrate &&      
        <th className="table-header-right text-center w-5">
          {<Translate id={'com.tempedge.msg.label.options'} /> }
        </th>}
    </tr>
  );
};
