import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import Modal from '../../Modals/Modal/Modal';
import Datepicker from '../../components/common/Datepicker/Datepicker';
import { modalVisibility } from '../../Redux/actions/tempEdgeActions';

import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';

class DateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      btnDisabled: true
    };
    this.baseState = this.state;
  }

  componentDidUpdate(prevProps) {
    const { activeLanguage, addTranslationForLanguage } = this.props;
    const hasActiveLanguageChanged = prevProps.activeLanguage !== activeLanguage;

    if (hasActiveLanguageChanged) {
      push(`/payroll/dailylist/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
    }
  }

  getDate = (date) => {
    if (moment(date).isValid()) {
      this.setState({
        date,
        btnDisabled: false
      });
    }
  };

  componentWillUnmount() {
    this.setState(this.baseState)
  }

  // Set Modal visible or not
  toggleModal = () => {
    this.setState(this.baseState);
    this.props.modalVisibility(false);
  };

  modalContent() {
    const { translate } = this.props;
    return (
      <>
        <h3 className="text-center">{translate('com.tempedge.msg.label.pickadate')}</h3>
        <div className="row text-center mt-4">
          <div className="form-group col-12 col-lg-6 mx-auto">
            <label className="text-left label-p">{translate('com.tempedge.msg.label.date')}</label>
            <Datepicker customClass="form-control tempEdge-input-box" shouldCloseOnSelect onChange={this.getDate} selectedValue={this.state.date} open showPopperArrow={false} />
          </div>
        </div>
      </>
    );
  }

  onClickSaveButton = () => {
    this.setState(this.baseState);
    this.props.onClick(moment(this.state.date).format('YYYY-MM-DD'));
  };

  modalButtons() {
    return (
      <div className="text-center col-12">
        <button type="button" className="btn btn-blue w-25" onClick={this.onClickSaveButton} disabled={this.state.btnDisabled}>
          {this.props.translate('com.tempedge.msg.label.submit')}
        </button>
      </div>
    );
  }

  render() {
    const { showModal } = this.props;
    return <Modal content={this.modalContent()} open={showModal} onClose={this.toggleModal} modalSize="modal-sm" buttons={this.modalButtons()} height="360px" />;
  }
}

const mapStateToProps = (state) => {
  return { showModal: state.tempEdge.showModal };
};

const mapDispatchToProps = {
  modalVisibility
};

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(DateModal));
