import { dailyListTypes } from '../types/dailyListTypes';

const initialState = {
  employeesInTimeSheet: [],
  removedEmployeeList: [],
  saveDailyListResponse: null,
  findListResponse: null,
  deleteDayResponse: null,
  mailTo: '',
  mailCc: '',
  sendEmail: false,
  disabledSaveButton: false
};

export const dailyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case dailyListTypes.ADD_EMPLOYEE_IN_TIMESHEET:
      return {
        ...state,
        employeesInTimeSheet: state.employeesInTimeSheet && state.employeesInTimeSheet.length === 0
          ? [action.payload]
          : [...state.employeesInTimeSheet, action.payload]
      };

    case dailyListTypes.REMOVE_EMPLOYEE_IN_TIMESHEET:
      return {
        ...state,
        employeesInTimeSheet: state.employeesInTimeSheet
          && state.employeesInTimeSheet
            .filter((employee) => employee.personId !== action.payload.personId )
      };

    case dailyListTypes.REMOVE_EMPLOYEE_IN_TIMESHEET_ID:
      return {
        ...state,
        employeesInTimeSheet: state.employeesInTimeSheet
          && state.employeesInTimeSheet
            .filter((employee) => employee.id !== action.payload.id)
      }; 

    case dailyListTypes.ADD_TO_REMOVED_EMPLOYEES_LIST: {
      const stateToReturn = {
        ...state,
        removedEmployeeList: []
      };

      if (state.employeesInTimeSheet && state.employeesInTimeSheet.length > 0) {
        // Si la lista de empleados esta vacia
        if (!state.removedEmployeeList) {
          stateToReturn.removedEmployeeList.push(action.payload.employee);
        } else {
          // Buscamos si el empleado ya se encuentra en la lista de empleados eliminados
          const employeeRemoved = state.removedEmployeeList
            .filter((employee) => employee.id=== action.payload.employee.id);

          // Si no esta en la lista de eliminados
          if (employeeRemoved.length === 0) {
            stateToReturn.removedEmployeeList = [...state.removedEmployeeList, action.payload.employee];
          } else {
            stateToReturn.removedEmployeeList = [...state.removedEmployeeList];
          }
        }
      }

      return stateToReturn;
    }

    case dailyListTypes.LOAD_VALUES_TO_EMPLOYEE_TIMESHEET: {
      const { position, pay, attendance } = action.payload;
      let { employeesInTimeSheet } = state;

      if (employeesInTimeSheet) {
        employeesInTimeSheet = employeesInTimeSheet.map((employee) => {
          const employeeCopy = { ...employee };
          employeeCopy.position = position;
          employeeCopy.pay = pay;
          employeeCopy.attendance = attendance;
          if (position) employeeCopy.isPositionMissing = false;
          if (pay !== '') employeeCopy.isPayMissing = false;
          return employeeCopy;
        });
      }

      return {
        ...state,
        employeesInTimeSheet
      };
    }

    case dailyListTypes.CHANGE_EMPLOYEE_VALUE: {
      const { id, key, value } = action.payload;
      const { employeesInTimeSheet } = state;
      if (employeesInTimeSheet) {
        for (let index = 0; index < employeesInTimeSheet.length; index += 1) {
          const employee = employeesInTimeSheet[index];
          if (employee.id === id) {
            employee[key] = value;
            if (key === 'position') {
              employee.pay = value.pay;
            }
            break;
          }
        }
      }
      return {
        ...state,
        employeesInTimeSheet
      };
    }

    case dailyListTypes.DAILY_LIST_SAVING:
      return {
        ...state,
        saveDailyListResponse: action.payload
      };

    case dailyListTypes.FIND_LIST_BY_DAY:
      return {
        ...state,
        findListResponse: action.payload
      };

    case dailyListTypes.DELETE_DAY:
      return {
        ...state,
        deleteDayResponse: action.payload
      };

    case dailyListTypes.CLEAR_PROP:
      return {
        ...state,
        [action.payload]: []
      };

    case dailyListTypes.CHANGE_MAIL_TO:
      return {
        ...state,
        mailTo: action.payload
      };

    case dailyListTypes.CHANGE_MAIL_CC:
      return {
        ...state,
        mailCc: action.payload
      };

    case dailyListTypes.TOGGLE_SEND_EMAIL:
      return {
        ...state,
        sendEmail: action.payload
      };

    case dailyListTypes.RESET_ENTIRE_STATE:
      return initialState;

    case dailyListTypes.DISABLE_SAVE_BUTTON:
      return {
        ...state,
        disabledSaveButton: action.payload
      };

    default:
      return state;
  }
};
