import * as Yup from "yup";
import {
  DATE_FORMAT,
  DEFAULT_COUNTRY,
} from "../../components/common/Constants/Constants";
import moment from "moment";

const toDay = new Date();

export const FormCandidateSchema = Yup.object().shape({
  information: Yup.object().shape({
    firstName: Yup.string().required("required"),
    lastName: Yup.string().required("required"),
    ssn: Yup.string().required("required"),
    birthDay: Yup.date().test(
      "is-age-valid",
      "Age must be 18 or older",
      function (value) {
        const birthDate = new Date(value);
        const eighteenYearsAgo = toDay;
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        if (birthDate >= eighteenYearsAgo) {
          return true; // Age is 18 or older, so it's valid
        } else {
          return false; // Age is less than 18, so it's invalid
        }
      }
    ),
    gender: Yup.number().required("required"),
  }),
  skills: Yup.array().min(1).required("required"),
  contact: Yup.object().shape({
    phone: Yup.string().required("required"),
    email: Yup.string().email("Invalid email").required("required"),
    address: Yup.string().required("required"),
    city: Yup.string().required("required"),
    zipCode: Yup.string()
      .min(5, "required")
      .max(10, "required")
      .required("required"),
    region: Yup.number().required("required"),
  }),
  misc: Yup.object().shape({
    maritalStatus: Yup.string(),
    idExpiration: Yup.date().required("required"),
    juniorAllowances: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue ? parseInt(originalValue, 10) : null
      )
      .integer("The value must be an integer")
      .typeError("The value must be a number"),
    seniorAllowances: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue ? parseInt(originalValue, 10) : null
      )
      .integer("The value must be an integer")
      .typeError("The value must be a number"),
      multipleJobs: Yup.number().min(1, "required"),
  }),
});


export const transformData = (data) => {
 return ({
  information: {
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    ssn: "",
    birthDay: `${new Date().getFullYear() - 18}-01-01`,
    middleName: "",
    gender: 2,
  },
  contact: {
    phone: data?.phone || "",
    email: data?.email || "",
    country: DEFAULT_COUNTRY,
    address: "",
    address2: "",
    city: "",
    region: "",
    zipCode: "",
  },
  skills: [],
  misc: {
    maritalStatus: "1",
    backgroundTest: 0,
    backgroundTestDate: moment().format(DATE_FORMAT),
    drugTest: 0,
    drugTestDate: moment().format(DATE_FORMAT),
    juniorAllowances: 0,
    seniorAllowances: 0,
    idExpiration: data?.expiryDate ? moment(data?.expiryDate).format(DATE_FORMAT) : moment().format(DATE_FORMAT),
    multipleJobs: 0,
  },
});
};
