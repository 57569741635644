import React, { useMemo, useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import BlueCommonBtn from "../../components/common/Buttons/BlueCommonBtn";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import Loader from "../../components/common/Loader/Loader";
import { Translate, withLocalize } from "react-localize-redux";
import OutcomeBar from "../../components/common/OutcomeBar";
import TempEdgeApi from "../../services/TempEdgeApi";
import { shallowEqual, useSelector } from "react-redux";
import { FirstSchema, initialValues } from "./Schema";
import { normalizeDateSend } from "../../utils/ReGexs";
import moment from "moment";
import {
  REACT_APP_URL_FIND_COLUMN_LIST_REPORT,
  REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE
} from "../../components/common/Routes/URLs";

const Format_Date="YYYY-MM-DD"

const FilterReport = ({
  formData,
  setFormData,
  nextStep,
  message,
  translate,
}) => {
  
  const today = moment();
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [info, setInfo] = useState(null);
  const formikRef = useRef();
  const [data, setData] = useState(null);
  const [messages, setMessages] = useState(message)
  const [weekDates, setWeekDates] = useState({
    thisWeekInit: today.clone().startOf("isoWeek").format(Format_Date),
    thisWeekEnd: today.clone().endOf("isoWeek").format(Format_Date),
    lastWeekInit: today
      .clone()
      .subtract(1, "week")
      .startOf("isoWeek")
      .format(Format_Date),
    lastWeekEnd: today
      .clone()
      .subtract(1, "week")
      .endOf("isoWeek")
      .format(Format_Date),
  });


  const weekActiveChange = async (client, date) => {
    const { data: weekActive } = await TempEdgeApi.post({
      url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
      payload: {
        orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
          ?.orgId,
        clientId: client,
        dateList: normalizeDateSend(date),
      },
    });
    const {
      status = "",
      result: { startDate = "", endDate = "" },
    } = weekActive;

    if (status === 200) {
    }
    const { data: lastWeekActive } = await TempEdgeApi.post({
      url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
      payload: {
        orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
          ?.orgId,
        clientId: client,
        dateList: moment(weekActive.result.startDate)
          .subtract(1, "d")
          .format(Format_Date),
      },
    });
    const {
      status: lastStatus = "",
      result: { startDate: lastStartDate = "", endDate: lastEndDate = "" },
    } = lastWeekActive;

    if (lastStatus && status === 200) {
      setWeekDates({
        thisWeekInit: startDate,
        thisWeekEnd: endDate,
        lastWeekInit: lastStartDate,
        lastWeekEnd: lastEndDate,
      });
    }
  };

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    setInfo(message);
    if (
      formData.company &&
      formikRef.current &&
      !formikRef.current.values.company
    ){
      formikRef.current.setValues({
        company: formData.company,
        reportType: formData.reportType,
      });
    }
  }, [formData, formData.company, message]);

	const clientsListRedux = useSelector(state => {
    return state.tempEdge.clientList;
	});
	const clientsList = useMemo(() => clientsListRedux, [clientsListRedux])  

  const role = useSelector((state) => {
    return state.tempEdge.login !== "" &&
      state.tempEdge.login.portalUserList.length > 0
      ? state.tempEdge.login?.portalUserList[0]?.user?.roles[0]?.description
      : null;
  }, shallowEqual);

  const reportTypes = useMemo(() => {
    if (role === null) return [];

    if (role === "CLIENT") {
      return [
        { key: "payroll", title: translate("com.tempedge.msg.label.payroll") },
        { key: "invoice", title: translate("com.tempedge.msg.label.invoice") },
      ];
    }
    if (role === "CLIENT_PAYROLL") {
      return [
        { key: "payroll", title: translate("com.tempedge.msg.label.payroll") },
      ];
    }

    return [
      { key: "payroll", title: translate("com.tempedge.msg.label.payroll") },
      { key: "invoice", title: translate("com.tempedge.msg.label.invoice") },
    ];
  }, [role, translate]);

  const buildReportRequest = (values) => {
    return {
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      id: values.company.clientId,
      parameterKey: `com.tempedge.msg.report.${values.reportType}`,
    };
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const request = buildReportRequest(values);
      const response = await getReportRequest(request);

      // Check if component is still mounted before updating state
      if (isMounted) {
        if (response.status === 200 && (response.code === "TE00" || response.code === "TE-E08")) {
          infoGroupTitleKey(response.result);
          setData({...values, options: response.result });
          setFormData({
            ...values, 
            options: infoGroupTitleKey(response.result),
            weekDates: weekDates
          });
          nextStep();
        } else {
          setData(values);
          setFormData(values);
        }
      }
    } catch (e) {
      setData(values);
      setMessages(e);
    } finally {
      // Make sure to set loading to false only if component is mounted
      if (isMounted) {
        setLoading(false);
      }
    }
  };

  const infoGroupTitleKey = (data) => {
    const groupedData = {};
    data.forEach(obj => {
      const titleKey = obj.titleKey;
      if (!groupedData[titleKey]) {
        groupedData[titleKey] = [];
      }
      groupedData[titleKey].push(obj);
    });
    const groupTitleKey = {
      "groupedData": groupedData
    };
    return groupTitleKey.groupedData;
  }

  const getReportRequest = async (request) => {
    try {
      const { data: response } = await TempEdgeApi.post({
        url: REACT_APP_URL_FIND_COLUMN_LIST_REPORT,
        payload: request,
      });
      return response;
    } catch (e) {
      return e;
    }
  };

  const btns = useMemo(() => {
    return (
      <div className="prev-next-btns-agency row" style={{ marginTop: 30 }}>
        <div className="col-md-5"></div>
        <div className="col-md-5 offset-md-1">
          <BlueCommonBtn
            tag="com.tempedge.msg.label.next"
            onClick={() => {
              if (formikRef.current) formikRef.current.handleSubmit();
            }}
          />
        </div>
      </div>
    );
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <ContainerBlue title="com.tempedge.msg.title.customreport" btns={btns}>
        {info && <OutcomeBar response={messages} />}
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={data || initialValues}
          validationSchema={FirstSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(formik) => {
            return (
              <>
                <form className="row p-2 justify-content-center">
                  <div className="col-11 col-md-4">
                    <div className="form-group">
                      <label className="text-left label-p">
                        <Translate id="com.tempedge.msg.label.company" />
                      </label>
                      <DropdownList
                        data={clientsList}
                        name={"company"}
                        textField="clientName"
                        valueField="clientId"
                        value={formik.values.company}
                        onChange={(value) => {
                          formik.setFieldValue("company", value);
                          weekActiveChange(
                            value.clientId,
                            moment().format(Format_Date)
                          );
                          setInfo(null)
                        }}
                        isInvalid={
                          formik.errors.company && formik.touched.company
                        }
                      />
                    </div>
                  </div>

                  <div className="col-11 col-md-4">
                    <div className="form-group">
                      <label className="text-left label-p">
                        {translate("com.tempedge.msg.label.reporttype")}
                      </label>
                      <DropdownList
                        data={reportTypes}
                        textField="title"
                        valueField="key"
                        value={{ key: formik.values.reportType }}
                        onChange={({ key }) => {
                          formik.setFieldValue("reportType", key);
                        }}
                      />
                    </div>
                  </div>
              </form>
              </>
            );
          }}
        </Formik>
      </ContainerBlue>
    </>
  );
};

export default withLocalize(FilterReport);