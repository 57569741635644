import React from "react";
import { Translate } from "react-localize-redux";

const DownloadFile = ({
  file,
  fileName,
  label = "com.tempedge.msg.label.download",
}) => {
  const downloadPDF = (pdf) => {
    let tipe = fileName.includes(".pdf")
      ? "application/pdf"
      : fileName.includes(".doc")
      ? "application/msword"
      : "application/plain";
    const linkSource = `data:${tipe};base64,${pdf}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <div className="d-flex flex-column "> 
      <button
        type="button"
        className={`btn btn-green w-100`}
        onClick={() => downloadPDF(file)}
      >
        <Translate id={label} />
      </button>
    </div>
  );
};

export default DownloadFile;
