import React, { useEffect, useState } from "react";
import { withLocalize } from "react-localize-redux";
import TempEdgeApi from "../../../services/TempEdgeApi";
import ClientSearch from "../AdvanceSearch/ClientSearch";
import EmployeeSearch from "../AdvanceSearch/EmployeeSearch";
import {
  REACT_APP_URL_COUNTRY_LIST_ALL,
  REACT_APP_URL_OFFICE_FIND_ALL,
  REACT_APP_URL_PERSON_SALESMAN_LIST,
} from "../Routes/URLs";

const AdvanceSearch = ({ cancelSearch, changeData, parameterKey }) => {
  const [countries, setCountries] = useState([]);
  const [offices, setOffices] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [salesmanList, SalesmanList] = useState([]);

  const getCountry = async () => {
    const { data: countries } = await TempEdgeApi.get({
      url: REACT_APP_URL_COUNTRY_LIST_ALL,
    });
    if (countries?.status === 200) {
      setCountries(countries.result);
      const country = countries.result.filter((country) => {
        return country.countryId === 234;
      })[0];
      setRegionList(country.regionEntityList);
    }
  };

  const getOffices = async () => {
    const { data: offices } = await TempEdgeApi.post({
      url: REACT_APP_URL_OFFICE_FIND_ALL,
      payload: { page: 0, size: 50, filterBy: {} },
    });
    if (offices.status === 200) setOffices(offices.result.data.content);
  };

  const getSalesMan = async () => {
    const { data: res } = await TempEdgeApi.post({
      url: REACT_APP_URL_PERSON_SALESMAN_LIST,
      payload: {
        orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
          ?.orgId,
        page: 0,
      },
    });
    const {
      status = 0,
      result: { content: response = [] },
    } = res;
    if (status === 200) {
      if (response.length > 0) {
        const salesmen = response;
        salesmen.forEach((salesman) => {
          salesman.salesmanName = salesman.firstName + " " + salesman.lastName;
        });
        SalesmanList(salesmen);
      }
    } else {
      SalesmanList([]);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        if (isMounted) {
          await getCountry();
          await getOffices();
          await getSalesMan();
        }
      } catch (error) {}
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return parameterKey.includes("clientlist") ? (
    <ClientSearch
      salesmanList={salesmanList}
      countries={countries}
      offices={offices}
      regionList={regionList}
      setRegionList={setRegionList}
      cancelSearch={cancelSearch}
      changeData={changeData}
      getCountry={getCountry}
    />
  ) : (
    <EmployeeSearch
      countries={countries}
      offices={offices}
      regionList={regionList}
      setRegionList={setRegionList}
      cancelSearch={cancelSearch}
      changeData={changeData}
      getCountry={getCountry}
    />
  );
};

export default withLocalize(AdvanceSearch);
