import React, { Component } from 'react';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { tempedgeAPI } from '../../../Redux/actions/tempEdgeActions';
import PaginatedTable from '../../../components/common/Table/PaginatedTable.js';
import { REACT_APP_URL_CLIENT_LIST_ALL } from '../../../components/common/Routes/URLs.js';

const api_url = REACT_APP_URL_CLIENT_LIST_ALL;

class ClientList extends Component {
   constructor(props) {
      super(props);
      this.state = {
         tablePage: 0,
         filterBy: {},
         data: [],
         error: false,
      };

      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage)
         .then(() => {
            this.setState({ error: false });
         })
         .catch((err) => {
            if (!this.state.error) {
               this.setState({ error: true });
            }
         });
   }

   componentDidUpdate(prevProps, prevState) {
      const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

      if (hasActiveLanguageChanged) {
         this.props.push(`/client/list/${this.props.activeLanguage.code}`);

         ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage).then(() =>
            this.setState({ error: false }),
         );
      }
   }

   render() {
      return (
         <PaginatedTable
            title="com.tempedge.msg.label.clientlist"
            apiUrl={api_url}
            parameterKey="com.tempedge.msg.menu.clientlist"
            filterBy={this.state.filterBy}
         />
      );
   }
}

ClientList.propTypes = {
   //Typechecking With PropTypes, will run on its own, no need to do anything else, separate library since React 16, wasn't the case before on 14 or 15
   //Action, does the Fetch part from the posts API
   tempedgeAPI: PropTypes.func.isRequired, //Action, does the Fetch part from the posts API
};

export default withLocalize(connect(null, { push, tempedgeAPI })(ClientList));
