import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import InputBox from '../../../components/common/InputBox/InputBox.js';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import Validate from '../../Validations/Validations';
import deleteIcon from './assets/delete.png'; // Tell Webpack this JS file uses this image
import addIcon from './assets/plus.png';
import { normalizePhone, normalizeZipCode } from '../../../utils/ReGexs.js';

const $ = window.$;
const selector = formValueSelector('CreateNewAgency');

class WizardCreateNewAgencyFourthPage extends Component {
  constructor(props) {
    super(props);
    this.addTranslationsForActiveLanguage();
  }

  state = { mounted: false, phonelabels: '' ,check:false};

  componentDidMount() {
    this.setState({
      mounted: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/registerAgency/${this.props.activeLanguage.code}`);
      this.addTranslationsForActiveLanguage();
    }
  }

  addTranslationsForActiveLanguage = async () => {
    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);

    let phonelabel = $(ReactDOM.findDOMNode(this.refs.phonelabel)).text();
    

    if (this.state.mounted && phonelabel !== '') {
      this.setState({
        phonelabels: phonelabel
      });
    }
  };

  renderError(formProps) {
    let fieldId = '';

    if (typeof formProps.input !== 'undefined') {
      if (formProps.index != null || typeof formProps.index != 'undefined' || formProps.index !== '') {
        if (formProps.input.name.indexOf('recruitmentofficephonenumbers') !== -1) {
          if (formProps.input.name.indexOf('officeName') !== -1) {
            fieldId = `com.tempedge.error.officeNamerequired`;
          } else if (formProps.input.name.indexOf('address') !== -1) {
            fieldId = `com.tempedge.error.addressrequired`;
          } else if (formProps.input.name.indexOf('city') !== -1) {
            fieldId = `com.tempedge.error.cityrequired`;
          } else if (formProps.input.name.indexOf('zip') !== -1) {
            fieldId = `com.tempedge.error.zipcoderequired`;
          } else if (formProps.input.name.indexOf('phonenumber') !== -1) {
            fieldId = `com.tempedge.error.phonenumberrequired`;
          }
        }
      }

      if (formProps.meta.touched && formProps.meta.error && typeof formProps.meta.error !== 'undefined') {
        return (
          <p style={{ color: '#a94442' }}>
            <Translate id={fieldId} />
          </p>
        );
      }
    }
  }

  renderPhoneNumberInputs = (formProps) => {
    let recruitment_office = formProps.label.split(' ');
  
    const { translate } = this.props;

    if (this.props.activeLanguage.code === 'en') {
      recruitment_office[0] = recruitment_office[0] === 'OfficeName' ? 'Office Name' : '';
    } else if (this.props.activeLanguage.code === 'es') {
      recruitment_office[0] = recruitment_office[0] === 'NombredeOficina' ? 'Nombre de Oficina' : '';
    }

    let block = formProps.fields.map((recruitmentOffice, index) => {
      return (
        <div key={index} className="recruitment-office-row">
          <div className="row pb-4 mb-2 border-bottom">
            <Field
              name={`${recruitmentOffice}.officeName`}
              type="text"
              placeholder={translate('com.tempedge.msg.label.office')}
              index={index}
              label={translate('com.tempedge.msg.label.office')}
              fields={formProps.fields}
              component={this.renderInput}
            />
            <Field
              name={`${recruitmentOffice}.address`}
              type="text"
              placeholder={translate('com.tempedge.msg.label.agencyaddress')}
              index={index}
              label={translate('com.tempedge.msg.label.agencyaddress')}
              fields={formProps.fields}
              component={this.renderInput}
            />
            <Field
              name={`${recruitmentOffice}.city`}
              type="text"
              placeholder={translate('com.tempedge.msg.label.city')}
              index={index}
              label={translate('com.tempedge.msg.label.city')}
              fields={formProps.fields}
              component={this.renderInput}
            />
            <Field
              name={`${recruitmentOffice}.zip`}
              type="text"
              placeholder={translate('com.tempedge.msg.label.agencyzipcode')}
              index={index}
              maxlength={6}
              label={translate('com.tempedge.msg.label.agencyzipcode')}
              fields={formProps.fields}
              component={this.renderInput}
              normalize={normalizeZipCode}
            />
            <Field
              name={`${recruitmentOffice}.phonenumber`}
              type="number"
              placeholder="xxx-xxx-xxxx"
              index={index}
              label={translate('com.tempedge.msg.label.phone')}
              fields={formProps.fields}
              component={this.renderInput}
              normalize={normalizePhone}
            />   
             <div className="col-md-4">
              { <span 
              className="pull-right" 
              title={translate('com.tempedge.msg.label.delete')} 
              onClick={() => formProps.fields.remove(index)}
              style={{paddingRight:'2rem'}}
              >
              <img className="delete-icon-phones" src={deleteIcon} alt="deleteIcon" 
                
                />
                </span> }
            </div>   
          </div>
        </div>
      );
    });

    let addBtn = (
      <div className="row">
        <div className="col-md-12" style={{ padding: 0 }}>
          <span className="center-block pull-right add-fieldArray-btn" onClick={(value) => formProps.fields.push({}) }>
            <img src={addIcon} alt="addIcon" />
          </span>
        </div>
      </div>
    );

    return (
      <>
        <div className="clearfix recruiting-office-phone">
          <Field name="recruitingofficecheckbox" id="recruitingoffice" component="input" type="checkbox" onClick={(value) => (this.setState({check:value.target.checked}))}/>
          <label className="checkbox-inline" style={{ padding: 8, fontSize: 13.5 }}>
            <Translate id="com.tempedge.msg.label.recruitingoffice" />
          </label>
        </div>
        <div>
          {!this.props.checkbox || typeof this.props.checkbox === 'undefined' ? block : ''}
          {!this.props.checkbox || typeof this.props.checkbox === 'undefined' ? addBtn : ''}
        </div>
      </>
    );
  };

  renderInput = (formProps) => {
    let colClass;
    let errorClass = `${formProps.meta.error && formProps.meta.touched ? 'has-error' : ''}`;
    let inputClass = formProps.label === 'Phone' || formProps.label === 'Telefono' ? 'form-control tempEdge-input-box agency-phone-delete' : 'form-control tempEdge-input-box';


    if (formProps.input.name.indexOf('officeName') !== -1) {
      colClass = 'col-md-4';
    } else if (formProps.input.name.indexOf('address') !== -1) {
      colClass = 'col-md-4';
    } else if (formProps.input.name === 'agencyname') {
      colClass = 'col-md-4';
    } else if (formProps.input.name.indexOf('phone') !== -1) {
      colClass = 'col-md-4';
    } else {
      colClass = 'col-md-4';
    }
    
   
    return (
      <div className={colClass}>
        <label className="control-label">{formProps.label}</label>
        <div className={errorClass}>
          <input className={inputClass}
                 onInput={({target}) => {target.value =  target.value.toUpperCase()}}
                 placeholder={formProps.placeholder} {...formProps.input}
                 autoComplete="off"
                 maxLength={formProps.maxlength}
          />
          {this.renderError(formProps)}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="wizard-wrapper">
        <h2 className="text-center page-title-agency">
          <Translate id="com.tempedge.msg.label.newagencyregistration"></Translate>
        </h2>
        <form onSubmit={this.props.handleSubmit} className="panel-body form-horizontal center-block register-form-agency" style={{ paddingBottom: '0px' }}>
          <div className="form-group row row-agency-name">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2">
                  <label className="control-label pull-right agency-label">
                    <Translate id="com.tempedge.msg.label.agencyname"></Translate>
                  </label>
                </div>
                <div className="col-md-6" >
                  <Field name="agencyname" type="text" placeholder="Agency Name" component={InputBox} />
                </div>
              </div>
            </div>
          </div>
          <div className="panel register-form-panel">
            <div className="panel-heading register-header">
              <h2 className="text-center">
                <Translate id="com.tempedge.msg.label.recruitingoffice"></Translate>
              </h2>
            </div>
          </div>
          <div className="register-form-panel-inputs">
            <div className="form-group register-form wizard-register-agency-form row">
              <div className="col-md-12">
                <FieldArray name="recruitmentofficephonenumbers" type="text" placeholder="Phone Number" label={this.state.phonelabels} component={this.renderPhoneNumberInputs} />
              </div>
            </div>
          </div>
          <div className="panel-footer register-footer panel-footer-agency-height-override">
            <div className="prev-next-btns-agency row">
              <div className="col-md-4 offset-md-2">
                <button type="button" className="btn btn-default btn-block register-save-btn previous" onClick={this.props.previousPage}>
                  <Translate id="com.tempedge.msg.label.back" />
                </button>
              </div>
              <div className="col-md-4">
                <button type="submit" className="btn btn-primary btn-block register-save-btn next" disabled={!this.state.check && (this.props.invalid || this.props.pristine)}>
                  <Translate id="com.tempedge.msg.label.next"></Translate>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

WizardCreateNewAgencyFourthPage = reduxForm({
  form: 'CreateNewAgency',
  destroyOnUnmount: false,
  validate: Validate
})(WizardCreateNewAgencyFourthPage);

WizardCreateNewAgencyFourthPage = connect((state) => ({
  checkbox: selector(state, 'recruitingofficecheckbox')
}))(WizardCreateNewAgencyFourthPage);

export default withLocalize(connect(null, { push })(WizardCreateNewAgencyFourthPage));
