export const onlyLetters = (string) => {
  if(!string) return '';
  return string.replace(/[^a-zA-ZÀ-ú ]+/g,'');
}

export const userLetters=(string)=>{
   if(!string)return '';
   return string.replace(/[^0-9a-zA-Z._-]/g, "")
}

export const onlyNumbers = (string) => {
   if(!string) return string;
   return string.replace(/[^\d]/g, '');
}

export const normalizeSSN = (string) => {
   if(!string) return string;
   const onlyNums = string.replace(/[^\d]/g, '');
   if (onlyNums.length <= 3) {
      return onlyNums;
   }
   if (onlyNums.length <= 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
   }
   return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 5) + '-' + onlyNums.slice(5, 9)
}

export const normalizePhone = (string) => {
   if(!string) return String;
   const onlyNums = string.replace(/[^\d]/g, '');
   if(onlyNums.length <= 3)
      return  onlyNums;
   if (onlyNums.length <= 6)
      return "(" + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3);
   return "(" + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10);
}

export const normalizeClientCode = (string) => {
   if(string.length > 30)
      string = string.substr(0,30);

   string = string.replace(/\s/g, '');
   string = string.replace(/[^a-zA-Z0-9]/g, "");

   return string;
}

export const normalizeZipCode = (string) => {
   if(!string) return string;
   return string.replace(/[^\d]/g, '').slice(0, 8);
}

export const normalizeAddress = (string) => {
  if(!string) return string;
  return string.replace(/[^#.a-zA-ZÀ-ú,-\d ]+/g,'');
}

export const limitString = ({value, limit, replace}) => {
   if(value.length > limit)
      return value.substr(0, limit) + replace;
   return value;
}
