export const payrollValidationTypes = {
ADD_PAYROLL_VALIDATION:'ADD_PAYROLL_VALIDATION',
ADD_PAYROLL_ABSENT_VALIDATION:'ADD_PAYROLL_ABSENT_VALIDATION',
CHANGE_VALUES_PAYROLL:'CHANGE_VALUES_PAYROLL',
RESET_ENTIRE_STATE: 'RESET_ENTIRE_STATE',
ADD_HOURS_PAYROLL_VALIDATION:'ADD_HOURS_PAYROLL_VALIDATION',
CHANGE_HOURS_PAYROLL:'CHANGE_HOURS_PAYROLL',
CHANGE_HOURS_PAYROLL_LOCKED:'CHANGE_HOURS_PAYROLL_LOCKED',
REQUEST_LIST_SAVING:'REQUEST_LIST_SAVING',
SAVE_PAYROLL_RESULT_VALIDATION:'SAVE_PAYROLL_RESULT_VALIDATION',
CHANGE_WEEK_ATTENDANCE_VALUE:'CHANGE_WEEK_ATTENDANCE_VALUE',
CHANGE_STATE:'CHANGE_STATE',
CLEAR_PROP:'CLEAR_PROP',
CHANGE_TIME_LOOK:'CHANGE_TIME_LOOK',
FIND_WEEK_ACTIVE: 'FIND_WEEK_ACTIVE',
CHANGE_PAYRATE_VALIDATION: 'CHANGE_PAYRATE_VALIDATION',
CHANGE_WEEK_PAYRATE_VALUE: 'CHANGE_WEEK_PAYRATE_VALUE',
CANCEL_WEEK_PAYRATE_VALUES: 'CANCEL_WEEK_PAYRATE_VALUES'
} 