import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import PaginatedTable from '../../../components/common/Table/PaginatedTable';
import { REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL } from '../../../components/common/Routes/URLs';


const apiUrl = REACT_APP_URL_ORG_DEPARTMENT_FIND_ALL;

class DepartmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: {}
    };
    const { activeLanguage, addTranslationForLanguage } = this.props;

    ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
  }

  componentDidUpdate(prevProps) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      const { activeLanguage, addTranslationForLanguage, push } = this.props;
      push(`/employee/list/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
    }
  }

  render() {
    const { onClickRows, multipleRows } = this.props;
    return <PaginatedTable apiUrl={apiUrl} title="com.tempedge.msg.label.departmentlist" onClickRows={onClickRows} multipleRows={multipleRows} size={10}/>;
  }
}

export default withLocalize(connect(null, { push })(DepartmentList));
