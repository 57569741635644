import React, { Component } from "react";
import { push } from "connected-react-router";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import FilterReport from "./FilterReport";
import CustomReportFileds from "./CustomReportFields";
import LastFiltersReport from "./lastFiltersReport";
import ActiveLanguageAddTranslation from "../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";

class CustomReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        client: "",
      },
      step: 1,
      message: null,
    };
    this.setFormData = this.setFormData.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.reset = this.reset.bind(this);
    //language
    this.addTranslationsForActiveLanguage();
  }

  addTranslationsForActiveLanguage = async () => {
    await ActiveLanguageAddTranslation(
      this.props.activeLanguage,
      this.props.addTranslationForLanguage
    );
  };

  async componentDidUpdate(prevProps) {
    let hasActiveLanguageChanged =
      prevProps.activeLanguage !== this.props.activeLanguage;
    if (hasActiveLanguageChanged) {
      await this.props.push(`/customreport/${this.props.activeLanguage.code}`);
      this.addTranslationsForActiveLanguage();
    }
  }

  reset(message) {
    this.setState({
      formData: {
        client: "",
        company: [],
        weekDay: "CUSTOM",
        startDate: null,
        endDate: null,
      },
      step: 1,
      message: message,
    });
  }

  setFormData(data) {
    this.setState({
      formData: data,
    });
  }

  nextStep() {
    this.setState((state) => ({
      step: state.step + 1,
    }));
  }

  prevStep() {
    this.setState((state) => ({
      step: state.step - 1,
    }));
  }

  render() {
    switch (this.state.step) {
      case 1:
        return (
          <FilterReport
            message={this.state.message}
            formData={this.state.formData}
            setFormData={this.setFormData}
            nextStep={this.nextStep}
          />
        );
      case 2:
        return (
          <CustomReportFileds
            formData={this.state.formData}
            setFormData={this.setFormData}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            reset={this.reset}
          />
        );
      case 3:
        return (
          <LastFiltersReport
            formData={this.state.formData}
            prevStep={this.prevStep}
            reset={this.reset}
          />
        );
      default:
        return null;
    }
  }
}

export default withLocalize(connect(null, { push })(CustomReport));
