import React from 'react';

const SsnIcons = () => (

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="32px" height="32px" viewBox="-14 0 1200.000000 1255.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1355.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1750 13535 c-443 -66 -797 -357 -941 -772 -33 -95 -35 -110 -14
-149 11 -22 15 -65 15 -176 l0 -148 -35 0 -35 0 0 -2880 c0 -1973 3 -2911 11
-2976 26 -233 107 -433 244 -604 177 -221 403 -360 685 -423 l95 -21 4640 -4
c3275 -2 4675 0 4760 8 66 6 154 19 195 29 472 115 825 517 879 1001 9 79 11
840 9 3115 -3 2850 -5 3014 -21 3084 -71 289 -226 531 -452 700 -86 65 -264
153 -365 181 -190 52 87 50 -4930 49 -3703 -1 -4672 -4 -4740 -14z m9511 -201
c310 -59 563 -250 699 -529 27 -55 59 -136 72 -180 l22 -80 3 -1692 3 -1693
-5560 0 -5560 0 0 1510 0 1510 -45 0 c-45 0 -45 0 -45 35 0 35 0 35 45 35 43
0 45 1 45 29 0 28 -1 29 -29 18 -67 -25 -81 9 -81 199 l0 144 40 0 40 0 0
-140 c0 -111 3 -140 14 -140 14 0 26 103 26 223 0 44 3 57 15 57 8 0 15 6 15
13 0 6 16 49 34 94 94 228 284 423 507 520 70 31 195 66 264 76 17 2 2137 5
4711 6 4234 1 4690 -1 4765 -15z m797 -5461 l-3 -1088 -5555 0 -5555 0 -3
1088 -2 1087 5560 0 5560 0 -2 -1087z m2 -1353 c0 -82 -30 -231 -65 -320 -112
-287 -357 -504 -663 -588 l-97 -27 -4685 -3 c-5084 -3 -4781 -6 -4942 49 -349
120 -590 401 -653 764 -8 48 -15 112 -15 141 l0 54 5560 0 5560 0 0 -70z"/>
<path d="M4151 12354 c-161 -43 -298 -156 -369 -305 -22 -46 -45 -111 -51
-143 -15 -83 -13 -278 3 -356 37 -173 151 -319 311 -398 104 -52 123 -54 425
-61 l285 -6 67 -32 c85 -40 151 -106 191 -191 32 -66 32 -69 32 -217 0 -147
-1 -151 -31 -215 -46 -95 -93 -143 -181 -187 l-77 -38 -240 -3 c-267 -4 -320
2 -402 49 -107 60 -177 168 -193 296 -10 77 -24 106 -58 122 -43 20 -82 13
-114 -18 -24 -25 -29 -38 -29 -78 1 -202 136 -416 320 -507 122 -59 187 -68
475 -64 239 4 254 5 330 31 183 61 324 208 379 395 38 131 29 358 -20 491 -24
65 -116 201 -141 209 -9 2 -13 -10 -13 -42 0 -39 -3 -46 -19 -46 -11 0 -21 5
-23 11 -3 8 -10 8 -26 -2 -33 -21 -56 -1 -60 49 -3 35 1 43 27 63 39 29 39 39
1 39 -19 0 -30 5 -30 14 0 14 -38 32 -115 56 -26 8 -141 15 -320 19 l-280 6
-65 31 c-79 38 -142 99 -184 179 l-31 60 0 165 c0 157 1 168 26 220 56 118
161 197 294 221 36 7 145 9 280 7 272 -5 303 -14 406 -117 74 -74 105 -139
116 -244 8 -79 41 -119 96 -119 82 0 112 54 96 170 -20 140 -65 231 -168 332
-83 83 -161 129 -257 154 -86 22 -582 22 -663 0z"/>
<path d="M6173 12355 c-161 -44 -289 -146 -366 -293 -51 -96 -67 -173 -67
-323 0 -159 12 -224 60 -324 65 -135 183 -239 331 -293 72 -25 83 -26 359 -32
l285 -5 65 -31 c153 -73 230 -207 230 -400 0 -154 -34 -255 -114 -338 -49 -50
-107 -83 -181 -102 -71 -19 -481 -19 -553 0 -153 41 -262 168 -282 331 -8 70
-14 83 -40 108 -42 38 -92 37 -131 -2 -25 -25 -29 -37 -29 -84 0 -29 9 -89 20
-133 50 -193 203 -348 406 -411 51 -16 95 -18 329 -18 298 0 334 5 446 61 152
76 262 211 304 374 22 85 17 369 -8 445 -43 132 -159 268 -285 333 -117 61
-189 72 -465 72 -207 0 -240 2 -292 20 -115 40 -221 152 -245 263 -7 30 -10
108 -8 187 3 122 6 141 30 192 39 84 104 149 190 189 l73 34 225 3 c133 2 249
-1 283 -7 112 -20 217 -93 272 -188 26 -43 55 -147 55 -198 0 -18 10 -41 25
-58 36 -38 105 -39 140 -1 22 23 25 37 25 95 0 239 -189 471 -435 534 -35 9
-104 15 -168 15 -93 0 -108 -2 -103 -15 7 -18 -34 -21 -51 -4 -8 8 -13 8 -17
0 -10 -16 -46 -13 -52 4 -8 20 -187 20 -261 0z"/>
<path d="M7764 12355 c-12 -8 -26 -27 -33 -42 -8 -21 -11 -318 -9 -1140 l3
-1113 28 -27 c40 -41 99 -39 138 5 l29 32 0 922 c0 556 4 918 9 912 8 -8 234
-375 421 -684 411 -677 723 -1179 744 -1194 38 -29 91 -26 124 7 l27 27 3
1116 c2 966 0 1118 -12 1142 l-15 27 -1 -27 c0 -26 -3 -28 -40 -28 l-40 0 0
40 c0 29 -4 40 -15 40 -11 0 -15 -11 -15 -40 0 -38 -2 -40 -30 -40 l-30 0 -2
-916 -3 -915 -31 53 c-17 29 -210 346 -429 703 -408 667 -484 791 -612 1002
-41 68 -84 129 -96 138 -27 19 -86 19 -113 0z"/>
<path d="M4983 11139 c-16 -16 -17 -45 -3 -54 13 -8 23 17 18 45 -2 17 -5 19
-15 9z"/>
<path d="M1647 8330 c-34 -27 -46 -84 -24 -121 8 -15 67 -97 132 -183 l116
-155 -127 -167 c-140 -184 -152 -213 -115 -271 24 -37 75 -52 119 -34 18 8 67
64 136 156 59 80 109 145 111 145 3 0 51 -62 108 -137 57 -76 111 -144 120
-151 9 -8 34 -16 55 -19 55 -7 92 26 99 88 5 45 5 46 -125 219 l-130 174 129
169 c111 146 129 175 129 206 0 34 -20 77 -44 93 -13 9 -78 10 -100 2 -9 -3
-66 -73 -127 -155 l-111 -148 -37 47 c-20 26 -71 93 -113 148 -42 56 -84 104
-93 108 -33 12 -83 6 -108 -14z"/>
<path d="M2655 8327 c-35 -29 -43 -91 -18 -129 10 -15 68 -95 130 -177 l112
-150 -126 -167 c-138 -184 -150 -213 -114 -271 15 -23 30 -34 57 -39 62 -12
77 1 224 196 30 40 62 82 71 93 15 20 19 16 123 -121 58 -78 118 -149 131
-158 60 -39 145 11 145 86 0 35 -15 59 -129 210 l-130 172 110 146 c61 81 119
158 130 172 36 46 14 141 -35 154 -43 12 -82 5 -107 -17 -14 -12 -70 -82 -124
-154 -54 -73 -99 -132 -101 -130 -3 3 -57 75 -138 182 -37 50 -77 98 -88 108
-29 25 -91 22 -123 -6z"/>
<path d="M3658 8320 c-27 -27 -30 -36 -26 -73 4 -34 23 -66 99 -166 52 -67
108 -142 126 -166 l32 -43 -130 -172 c-113 -151 -129 -178 -129 -211 0 -52 33
-89 85 -96 54 -7 70 7 194 172 57 74 104 135 106 135 2 0 49 -61 104 -134 56
-74 113 -144 128 -155 62 -49 153 -4 153 77 0 34 -15 59 -129 211 l-130 173
57 77 c31 42 88 116 126 165 54 71 70 98 74 132 4 38 1 47 -26 74 -36 36 -96
42 -130 13 -11 -10 -64 -76 -117 -148 -54 -71 -101 -134 -105 -138 -4 -4 -55
58 -115 138 -59 80 -116 150 -126 155 -37 20 -89 12 -121 -20z"/>
<path d="M5149 8337 c-24 -19 -39 -52 -39 -89 0 -28 23 -64 126 -202 69 -92
123 -173 121 -181 -3 -7 -60 -86 -126 -174 -108 -144 -121 -166 -121 -202 0
-32 7 -47 30 -71 27 -27 36 -30 71 -25 22 3 48 12 57 21 9 8 62 75 117 150 55
74 103 135 106 135 3 1 54 -64 113 -143 70 -94 118 -149 137 -156 45 -19 96
-5 120 33 36 58 24 86 -112 265 -68 90 -124 168 -124 173 0 5 56 84 125 176
116 154 125 170 125 211 0 36 -5 48 -28 68 -30 26 -83 32 -118 14 -11 -6 -68
-76 -128 -156 l-109 -145 -107 143 c-59 79 -117 148 -128 155 -28 16 -87 16
-108 0z"/>
<path d="M6158 8336 c-31 -23 -46 -64 -38 -100 4 -20 59 -102 129 -195 66 -88
121 -164 121 -169 0 -5 -54 -81 -120 -168 -66 -87 -124 -175 -130 -194 -11
-39 8 -81 48 -108 l22 -14 0 66 0 66 40 0 39 0 3 -57 3 -58 110 147 c61 81
113 146 117 145 3 -1 51 -60 105 -132 l98 -130 47 3 c40 3 47 1 53 -17 8 -27
35 -27 35 -1 0 13 7 20 19 20 24 0 36 45 22 82 -5 14 -64 98 -131 187 -77 102
-118 166 -114 173 5 7 62 85 128 172 115 151 121 161 120 204 -1 36 -6 50 -28
69 -29 25 -83 31 -117 13 -11 -6 -68 -76 -128 -156 l-109 -144 -92 122 c-132
175 -145 188 -194 188 -23 0 -49 -7 -58 -14z"/>
<path d="M7697 8330 c-30 -24 -45 -80 -30 -113 8 -17 166 -233 245 -333 6 -8
-34 -69 -118 -180 -139 -186 -151 -214 -115 -271 14 -23 30 -34 57 -39 54 -10
89 14 156 104 118 161 151 202 158 197 4 -2 53 -66 110 -140 56 -75 113 -143
126 -152 59 -38 144 13 144 87 0 33 -16 60 -129 211 l-129 173 129 170 c138
181 147 202 114 265 -17 33 -33 41 -85 41 -47 0 -66 -18 -175 -165 -44 -59
-87 -116 -95 -126 -14 -18 -22 -10 -124 126 -60 80 -117 150 -127 155 -30 17
-85 11 -112 -10z"/>
<path d="M8699 8321 c-31 -32 -38 -75 -18 -113 6 -11 52 -75 103 -142 50 -66
103 -138 118 -158 l27 -37 -130 -173 c-129 -170 -131 -174 -126 -217 6 -54 32
-81 84 -88 53 -7 76 14 198 177 53 71 99 130 101 130 2 0 48 -60 103 -132 118
-159 128 -168 180 -175 35 -5 44 -2 71 25 23 24 30 39 30 71 0 36 -13 57 -125
205 -69 91 -125 172 -125 178 0 7 56 87 125 178 109 144 125 170 125 203 0 70
-75 117 -144 91 -8 -3 -66 -73 -127 -155 -62 -82 -116 -146 -119 -142 -4 5
-55 72 -113 149 -59 78 -114 144 -122 148 -40 15 -87 6 -116 -23z"/>
<path d="M9709 8321 c-22 -23 -29 -39 -29 -69 0 -33 11 -53 73 -133 119 -155
177 -236 177 -248 0 -6 -56 -86 -125 -178 -110 -147 -125 -171 -125 -205 0
-81 91 -127 153 -78 14 11 72 81 127 155 56 75 105 133 108 131 4 -3 52 -65
107 -139 56 -77 113 -143 131 -153 66 -36 144 10 144 86 0 32 -18 60 -130 209
l-129 172 27 37 c15 21 68 92 118 158 51 67 97 131 103 142 19 35 13 78 -15
111 -20 25 -34 31 -64 31 -21 0 -46 -3 -55 -6 -8 -4 -63 -70 -122 -148 -58
-77 -109 -144 -112 -148 -4 -5 -58 59 -119 141 -62 82 -120 152 -128 155 -39
15 -86 6 -115 -23z"/>
<path d="M10726 8324 c-20 -20 -26 -37 -26 -69 0 -38 11 -57 125 -208 69 -92
125 -171 125 -175 0 -5 -52 -77 -115 -161 -134 -178 -135 -179 -135 -226 0
-44 45 -95 84 -95 53 0 76 21 183 164 59 80 110 145 113 146 3 0 54 -64 113
-143 101 -137 134 -167 177 -167 59 0 107 63 96 125 -4 19 -10 35 -14 37 -4 2
-60 74 -125 161 -73 98 -115 162 -110 170 4 6 60 83 126 169 82 109 120 168
124 192 l6 36 -72 0 -71 0 0 31 c0 20 -4 29 -12 27 -7 -3 -61 -67 -119 -143
-58 -77 -110 -140 -115 -142 -5 -2 -54 57 -109 131 -55 73 -112 141 -127 150
-40 25 -91 21 -122 -10z"/>
</g>
</svg>

);

export default SsnIcons;