import React from 'react'
import { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import Modal from '../../Modals/Modal/Modal';

import CustomPayrollForm from './CustomPayrollForm';
import deleteIcon from "../../assets/icons/delete.png";
import { removeEmployee } from '../../Redux/actions/customPayrollActions';

const CustomPayrollTable = ({translate,weekActive, messageppl,dataGlobal,customPayrollEntityList,client,customPayrollDelete}) => {

  const dispatch = useDispatch(); 

 
  const [modal, setModal] = useState({
    show: false,
    content: null,
  });

  const employees = useSelector(state => {
    return state.customPayrollReducer.employeesInCustomPayroll;
  }, shallowEqual); 


  return (

    <div className={"time-sheet__body"}>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="table-header-left text-center w-5">EE #</th>
                <th className="table-header-mid text-center w-15">
                  {translate("com.tempedge.msg.label.lastname")}
                </th>
                <th className="table-header-mid text-center w-15">
                  {translate("com.tempedge.msg.label.firstname")}
                </th>
                <th className="table-header-right text-center w-10">
                  {translate("com.tempedge.msg.label.opt")}
                </th>                            
              </tr>
            </thead>
            <tbody>
              {employees &&
                employees.map((employee,index) => {
                  return (
                    <tr key={index} className={"time-sheet-row text-center"}>
                      <td>{employee.employeeId}</td>
                      <td>{employee.lastName}</td>
                      <td>{employee.firstName}</td>
                      <td className="text-center ">
                        <button
                          className="btn"
                          type="button"
                          onClick={() => {
                            
                            setModal({
                              show: true,
                              content: (
                                <CustomPayrollForm
                                  person={employee}                                  
                                  closeModal={() =>
                                    setModal({ show: false, content: null })
                                  }  
                                  weekActive={weekActive}   
                                  messageppl={messageppl}     
                                  dataGlobal={dataGlobal}    
                                  customPayrollEntityList={customPayrollEntityList}            
                                  client={client}
                                  customPayrollDelete={customPayrollDelete}
                                />                                   
                              ),
                            });
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              color: `${employee.confirmation
                                ? "var(--tempedge-green)"
                                : "#dc2626"
                                }`,                                
                               }}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(removeEmployee(employee.personId));
                          }}
                          className="btn"
                        >
                          <img
                            className="icon-width"
                            src={deleteIcon}
                            alt="Delete icon"
                          />
                        </button>
                      </td>
                    </tr>
                  )
                })
                }
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={modal.show}
        content={modal.content}
        onClose={() => setModal({ show: false, content: null })}
        modalSize={"modal-xl"}
      />
    </div>

  )
}
export default withLocalize(CustomPayrollTable);
