import React from "react";
import { Col, Row } from "react-bootstrap";
import TranslatedLabel from "../../../components/common/TranslatedLabel/TranslatedLabel";
import { Field, useFormikContext } from "formik";
import { normalizePhone, normalizeZipCode } from "../../../utils/ReGexs";
import DropdownList from "../../../components/common/Dropdown/DropdownList";


const Contact = ({ countryList,setRegionList,regionList }) => {
 
  const { setFieldValue, values, errors, touched } = useFormikContext();
  return (
    <>
      <Row>
        <Col  sm={12} md={6} lg={6} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.phone" />
          <Field
            id="contact.phone"
            name="contact.phone"
            type="text"
            className={` tempEdge-input-box form-control ${errors.contact?.phone && (touched.contact?.phone || touched.contact) ? "has-error" : ""}`}
            onChange={(e) => {
              setFieldValue("contact.phone", normalizePhone(e.target.value));
            }}
          />
        </Col>
        <Col  sm={12} md={6} lg={6} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.email" />
          <Field
            id="contact.email"
            name="contact.email"
            type="text"
            className={` tempEdge-input-box form-control uppercase ${errors.contact?.email && (touched.contact?.email || touched.contact) ? "has-error" : ""}`}
          />
        </Col>
        <Col  sm={12} md={6} lg={6} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.country" />
          <Field
            name="contact.country"
            data={countryList}
            valueField="countryId"
            textField="name"
            value={{ countryId: values.contact?.country }}
            className={` tempEdge-input-box form-control ${errors.contact?.country && (touched.contact?.country || touched.contact) ? "has-error" : ""}`}
            component={DropdownList}
            onChange={(value) => {
              setFieldValue("contact.country", value.countryId);
              const country = countryList.filter((country) => {
                return country.countryId === value.countryId;
              })[0];
              setRegionList(country.regionEntityList);
              setFieldValue("contact.region", "");
            }}
            isInvalid={errors.contact?.clientcountry && touched.contact}
          />
        </Col>
        <Col  sm={12} md={8} lg={8} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.address" />
          <Field
            id="contact.address"
            name="contact.address"
            type="text"
            className={` tempEdge-input-box form-control uppercase ${errors.contact?.address && (touched.contact?.address || touched.contact) ? "has-error" : ""}`}
          />
        </Col>
        <Col  sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.agencyaddress2" />
          <Field
            id="contact.address2"
            name="contact.address2"
            type="text"
            maxLength="50"
            className={` tempEdge-input-box form-control uppercase ${errors.contact?.address2 && touched.contact?.address2 ? "has-error" : ""}`}
          />
        </Col>
        <Col  sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.city" />
          <Field
            id="contact.city"
            name="contact.city"
            type="text"
            className={` tempEdge-input-box form-control uppercase ${errors.contact?.city && (touched.contact?.city || touched.contact) ? "has-error" : ""}`}
          />
        </Col>
        <Col  sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.state" />
          <Field
            name="contact.region"
            data={regionList}
            valueField="regionId"
            textField="name"
            value={{ regionId: values.contact?.region }}
            className={` tempEdge-input-box form-control ${errors.contact?.region && (touched.contact?.region || touched.contact) ? "has-error" : ""}`}
            component={DropdownList}
            onChange={(value) => {
              setFieldValue("contact.region", value.regionId);
            }}
            isInvalid={errors.contact?.region && touched.contact}
          />
        </Col>
        <Col  sm={12} md={4} lg={4} className="form-group">
          <TranslatedLabel id="com.tempedge.msg.label.agencyzipcode" />
          <Field
            id="contact.zipCode"
            name="contact.zipCode"
            type="text"
            className={` tempEdge-input-box form-control ${errors.contact?.zipCode && (touched.contact?.zipCode || touched.contact) ? "has-error" : ""}`}
            onChange={(event) => {
              event.target.value = normalizeZipCode(event.target.value);
              setFieldValue("contact.zipCode", event.target.value);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Contact;
