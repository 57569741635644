import React from 'react';
import { withLocalize } from 'react-localize-redux';
import FormEmployee from './FormEmployee';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

class CreateOrUpdateEmployee extends React.Component {
   constructor(props) {
      super(props);
      // language
      this.addTranslationsForActiveLanguage();
   }

   addTranslationsForActiveLanguage = async () => {
      await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   };

   async componentDidUpdate(prevProps) {
      const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
      if (hasActiveLanguageChanged) {
         const path = this.props.location.pathname.split('/');
         await this.props.push(`/employee/${path[2]}/${this.props.activeLanguage.code}`);
         this.addTranslationsForActiveLanguage();
      }
   }

   render() {
      return <FormEmployee />;
   }
}

export default withLocalize(connect(null, { push })(CreateOrUpdateEmployee));
