import { useEffect, useState } from "react";
import { REACT_APP_URL_OFFICE_FIND_ALL } from "../../components/common/Routes/URLs";
import TempEdgeApi from "../../services/TempEdgeApi";

const useFetchOfficeList = () => {
    const [loading, setLoading] = useState(true);
    const [offices, setOffices] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchOfficesList = async () => {
            setLoading(true);
            try {
                const { data: officeList } = await TempEdgeApi.post({
                    url: REACT_APP_URL_OFFICE_FIND_ALL,
                    payload: {
                        filterBy: {},
                    },
                });

                if (isMounted && officeList?.status === 200) {
                    setOffices(
                        officeList?.result?.data?.content
                            ? officeList.result.data.content
                            : []
                    );
                }
            } catch (error) {
                setError(error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchOfficesList();

        return () => {
            isMounted = false;
        };
    }, []);

    return { loading, offices, error };
};

export default useFetchOfficeList;
