import moment from "moment";
import * as Yup from "yup";

export const FirstSchema = Yup.object().shape({
  company: Yup.object().required("Company is required"),
  reportType: Yup.string().required("Report type is required"),
});

export const SecondSchema = Yup.object().shape({
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date().required("End date is required"),
  weekDay: Yup.string().required("week day is required"),
});

const today = moment();
export const initialValues = {
  company: "",
  department: "",
  position: "",
  shift: "",
  reportType: "",
  weekDay: "CUSTOM",
  startDate: today.clone().startOf("isoWeek").format("MM-DD-YYYY"),
  endDate: today.clone().endOf("isoWeek").format("MM-DD-YYYY"),
  filter: {
    reportByDept: false,
    reportByPosition: false,
    separateCustomPayroll: false,
    showTotals:false
  },
};
