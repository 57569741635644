
import { TotalOtHourWeek, TotalRegHourWeek, TotalHourByWeekDay, TotalAttendanceWeek, TotalSumAttendanceByWeek } from '../../utils/helpers/TotalHoursFormat'
import moment from "moment";

export const ExportExcel = (findDate,employeesTimeEntry, translate) => {

    const weekResults = Object.values(employeesTimeEntry).filter(value => (value != null) && (value.totalRegHours > 0 || value.totalRegHours < 0))
    let first = moment().day(findDate.firstDate.day).format("dddd").toLowerCase()
    let second = moment().day(findDate.secondDate.day).format("dddd").toLowerCase()
    let third = moment().day(findDate.thirdDate.day).format("dddd").toLowerCase()
    let fourth = moment().day(findDate.fourthDate.day).format("dddd").toLowerCase()
    let fifth = moment().day(findDate.fifthDate.day).format("dddd").toLowerCase()
    let sixth = moment().day(findDate.sixthDate.day).format("dddd").toLowerCase()
    let seventh = moment().day(findDate.seventhDate.day).format("dddd").toLowerCase()

    const data = weekResults.map((employee) => {
        return {
            [translate('com.tempedge.msg.label.name')]: `${employee.person?.firstName} ${employee.person?.lastName}`,
            [translate('com.tempedge.msg.label.deptpos')]: employee.department !== null ? `${employee.department?.name} ${employee.position?.name}` : employee.position?.name,
            [`${translate(`com.tempedge.msg.label.${first}`)}-${`${findDate.firstDate.date}`}`]:
                employee[findDate.firstDate.day] !== null && (employee[findDate.firstDate.day]?.totalRegHour || employee[findDate.firstDate.day]?.totalOtHour) ?
                    `RG ${employee[findDate.firstDate.day]?.totalRegHour.toFixed(2)} - ${employee[findDate.firstDate.day]?.totalOtHour.toFixed(2)} OT` : null,

            [`${translate(`com.tempedge.msg.label.${second}`)}-${`${findDate.secondDate.date}`}`]:
                employee[findDate.secondDate.day] !== null && (employee[findDate.secondDate.day]?.totalRegHour || employee[findDate.secondDate.day]?.totalOtHour) ?
                    `RG ${employee[findDate.secondDate.day]?.totalRegHour.toFixed(2)} - ${employee[findDate.secondDate.day]?.totalOtHour.toFixed(2)} OT` : null,

            [`${translate(`com.tempedge.msg.label.${third}`)}-${`${findDate.thirdDate.date}`}`]:
                employee[findDate.thirdDate.day] !== null && (employee[findDate.thirdDate.day]?.totalRegHour || employee[findDate.thirdDate.day]?.totalOtHour) ?
                    `RG ${employee[findDate.thirdDate.day]?.totalRegHour.toFixed(2)} - ${employee[findDate.thirdDate.day]?.totalOtHour.toFixed(2)} OT` : null,

            [`${translate(`com.tempedge.msg.label.${fourth}`)}-${`${findDate.fourthDate.date}`}`]:
                employee[findDate.fourthDate.day] !== null && (employee[findDate.fourthDate.day]?.totalRegHour || employee[findDate.fourthDate.day]?.totalOtHour) ?
                    `RG ${employee[findDate.fourthDate.day]?.totalRegHour.toFixed(2)} - ${employee[findDate.fourthDate.day]?.totalOtHour.toFixed(2)} OT` : null,

            [`${translate(`com.tempedge.msg.label.${fifth}`)}-${`${findDate.fifthDate.date}`}`]:
                employee[findDate.fifthDate.day] !== null && (employee[findDate.fifthDate.day]?.totalRegHour || employee[findDate.fifthDate.day]?.totalOtHour) ?
                    `RG ${employee[findDate.fifthDate.day]?.totalRegHour.toFixed(2)} - ${employee[findDate.fifthDate.day]?.totalOtHour.toFixed(2)} OT` : null,

            [`${translate(`com.tempedge.msg.label.${sixth}`)}-${`${findDate.sixthDate.date}`}`]:
                employee[findDate.sixthDate.day] !== null && (employee[findDate.sixthDate.day]?.totalRegHour || employee[findDate.sixthDate.day]?.totalOtHour) ?
                    `RG ${employee[findDate.sixthDate.day]?.totalRegHour.toFixed(2)} - ${employee[findDate.sixthDate.day]?.totalOtHour.toFixed(2)} OT` : null,

            [`${translate(`com.tempedge.msg.label.${seventh}`)}-${`${findDate.seventhDate.date}`}`]:
                employee[findDate.seventhDate.day] !== null && (employee[findDate.seventhDate.day]?.totalRegHour || employee[findDate.seventhDate.day]?.totalOtHour) ?
                    `RG ${employee[findDate.seventhDate.day]?.totalRegHour.toFixed(2)} - ${employee[findDate.seventhDate.day]?.totalOtHour.toFixed(2)} OT` : null,

            [`${translate(`com.tempedge.msg.label.reghours`)}`]: String(`${employee.totalRegHours.toFixed(2)}`),
            [`${translate(`com.tempedge.msg.label.othours`)}`]: String(`${employee.totalOTHours.toFixed(2)}`),
            [`${translate(`com.tempedge.msg.label.attendance`)}`]: String(`${TotalSumAttendanceByWeek(employee, findDate)}`),
        }
    })

    let totals = {
        [translate('com.tempedge.msg.label.name')]: '',
        [translate('com.tempedge.msg.label.deptpos')]: 'Total',
        [`${translate(`com.tempedge.msg.label.${first}`)}-${`${findDate.firstDate.date}`}`]: `${TotalHourByWeekDay(weekResults, findDate.firstDate.day)}`,
        [`${translate(`com.tempedge.msg.label.${second}`)}-${`${findDate.secondDate.date}`}`]: `${TotalHourByWeekDay(weekResults, findDate.secondDate.day)}`,
        [`${translate(`com.tempedge.msg.label.${third}`)}-${`${findDate.thirdDate.date}`}`]: `${TotalHourByWeekDay(weekResults, findDate.thirdDate.day)}`,
        [`${translate(`com.tempedge.msg.label.${fourth}`)}-${`${findDate.fourthDate.date}`}`]: `${TotalHourByWeekDay(weekResults, findDate.fourthDate.day)}`,
        [`${translate(`com.tempedge.msg.label.${fifth}`)}-${`${findDate.fifthDate.date}`}`]: `${TotalHourByWeekDay(weekResults, findDate.fifthDate.day)}`,
        [`${translate(`com.tempedge.msg.label.${sixth}`)}-${`${findDate.sixthDate.date}`}`]: `${TotalHourByWeekDay(weekResults, findDate.sixthDate.day)}`,
        [`${translate(`com.tempedge.msg.label.${seventh}`)}-${`${findDate.seventhDate.date}`}`]: `${TotalHourByWeekDay(weekResults, findDate.seventhDate.day)}`,
        [`${translate(`com.tempedge.msg.label.reghours`)}`]: `${TotalRegHourWeek(weekResults)}`,
        [`${translate(`com.tempedge.msg.label.othours`)}`]: `${TotalOtHourWeek(weekResults)}`,
        [`${translate(`com.tempedge.msg.label.attendance`)}`]:`${TotalAttendanceWeek(weekResults, findDate)}`,
    }
   
    return {data,totals}
}
