import React, { useEffect, useState } from 'react';
import { SimpleAreaChart } from '../Charts/SimpleAreaChart';
import { RadarChartCustom } from '../Charts/RadarChartCustom';
import { CardContainer } from '../Components/CardContainer';
import useRadarChartData from '../Hooks/useRadarChartData';
import useSimpleAreaChartData from '../Hooks/useSimpleAreaChartData';
import Container from '../../../components/common/Container/Container';
import { ProfileInfoCard } from '../ProfileInfoCard';
import { ReleaseNotesCard } from '../ReleaseNotesCard';
import { Col, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import '../dashboard.css';
import { normalizeDateSend } from '../../../utils/ReGexs';
import useDashboardTableData from '../Hooks/useDashboardTableData';
import Loader from '../../../components/common/Loader/Loader';
import { CardTable } from './CardTable';
import moment from 'moment';


export const DashboardAdmin = ({ translate }) => {

  const orgName = JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.organizationName;
  const [selectedDate, setSelectedDate] = useState(new Date(moment().subtract(7, 'days')));
  const [dateFilter, setDateFilter] = useState(moment(selectedDate));

  const { radarChartData, loading: radarChartLoading } = useRadarChartData();
  const { chartData, loading: areaChartLoading } = useSimpleAreaChartData(normalizeDateSend(moment().subtract(7, 'days')));
  const { data: tableData, loading: tableDataLoading } = useDashboardTableData(normalizeDateSend(dateFilter));

  const [dashboardLoading, setDashboardLoading] = useState(true);

  useEffect(() => {
    setDashboardLoading(radarChartLoading || areaChartLoading || tableDataLoading);
  }, [radarChartLoading, areaChartLoading, tableDataLoading])
  
  if (dashboardLoading) {
    return <Loader />
  }

  const onChangeDate = (date) => {
    setDateFilter(normalizeDateSend(date));
    setSelectedDate(date);
  }

  return (
    !dashboardLoading && (<Container>
      <Row className='mx-auto'>
        <Col className='mx-auto' md={8} lg={8} sm={12}>
          <Row>
            <Col md={12} lg={12} sm={12}>
              <CardTable translate={translate} tableData={tableData} onChangeDate={onChangeDate} orgName={orgName} loading={tableDataLoading} selectedDate={selectedDate} />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} sm={12}>
              <CardContainer height={'400px'}>
                <RadarChartCustom translate={translate} data={radarChartData?.result?.data} dataKey={radarChartData?.result?.keyField} title={'com.tempedge.msg.title.todayslogincount'} />
              </CardContainer>
            </Col>
            <Col md={12} lg={6} sm={12}>
              <CardContainer height={'400px'}>
                <SimpleAreaChart data={chartData?.result?.data} title={'com.tempedge.msg.title.lastweekhours'} translate={translate} />
              </CardContainer>
            </Col>
          </Row>
        </Col>
        <Col md={4} lg={4} sm={12}>
          <ProfileInfoCard translate={translate} />
          <ReleaseNotesCard />
        </Col>
      </Row>
    </Container>)
  );
};

