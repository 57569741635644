import React, { Component } from "react";
import { push } from "connected-react-router";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import Confirmation from "./Confirmation";
import FileConfiguration from "./FileConfiguration";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import UpdateFileConf from "./UpdateFileConf";
import UpdateShifts from "./UpdateShifts";

class ConfigReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        client: "",
        timeclock: {
          id: 0,
          name: "NO",
        },
        byEmployeeId: false,
        payrate: false,
        headers: false,
        recurrentPayroll: false,
        fileConfiguration: {},
        reportClientDepts: [],
        reportClientShifts: [],
      },
      step: 1,
      message: null,
    };
    this.setFormData = this.setFormData.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.reset = this.reset.bind(this);
    //language
    this.addTranslationsForActiveLanguage();
  }

  addTranslationsForActiveLanguage = async () => {
    await ActiveLanguageAddTranslation(
      this.props.activeLanguage,
      this.props.addTranslationForLanguage
    );
  };

  async componentDidUpdate(prevProps) {
    let hasActiveLanguageChanged =
      prevProps.activeLanguage !== this.props.activeLanguage;
    if (hasActiveLanguageChanged) {
      await this.props.push(`/report/new/${this.props.activeLanguage.code}`);
      this.addTranslationsForActiveLanguage();
    }
  }

  reset(message) {
    this.setState({
      formData: {
        client: "",
        timeclock: {
          id: 0,
          name: "NO",
        },
        payRate: false,
        byEmployeeId: false,
        recurrentPayroll: false,
        headers: false,
        fileConfiguration: {},
        reportClientDepts: [],
        reportClientShifts: [],
      },
      step: 1,
      message: message,
    });
  }

  setFormData(data) {
    this.setState({
      formData: data,
    });
  }

  nextStep() {
    this.setState((state) => ({
      step: state.step + 1,
    }));
  }

  prevStep() {
    this.setState((state) => ({
      step: state.step - 1,
    }));
  }

  render() {
    switch (this.state.step) {
      case 1:
        return (
          <FileConfiguration
            message={this.state.message}
            formData={this.state.formData}
            setFormData={this.setFormData}
            nextStep={this.nextStep}
          />
        );
      case 2:
        if (this.state.formData.timeclock.id !== 0) {
          return (
            <UpdateFileConf
              formData={this.state.formData}
              setFormDataUpload={this.setFormData}
              prevStep={this.prevStep}
              nextStep={this.nextStep}
            />
          );
        } else {
          return (
            <Confirmation
              formData={this.state.formData}
              prevStep={this.prevStep}
              reset={this.reset}
            />
          );
        }
      case 3:
        if (this.state?.formData?.reportClientShifts?.length > 0) {
          return (
            <UpdateShifts
              formData={this.state.formData}
              setFormData={this.setFormData}
              prevStep={this.prevStep}
              reset={this.reset}
              nextStep={this.nextStep}
            />
          );
        } else {
          return (
            <Confirmation
              formData={this.state.formData}
              prevStep={this.prevStep}
              reset={this.reset}
            />
          );
        }

      case 4:
        return (
          <Confirmation
            formData={this.state.formData}
            prevStep={this.prevStep}
            reset={this.reset}
          />
        );
      default:
        return null;
    }
  }
}

export default withLocalize(connect(null, { push })(ConfigReport));
