import Axios from "axios";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import Stepper from "react-stepper-horizontal";
import { change, initialize, reset } from "redux-form";
import ModalSimple from "../../../Modals/ModalSimple/ModalSimple.js";
import {
  clearTempedgeStoreProp,
  deletePositionCLient,
  getList,
  getListClientAll,
  removeFromDepartmentList,
  saveDepartmentList,
  savePositionsList,
  saveToPositionsList,
  tempedgeAPI,
  updateMessage
} from "../../../Redux/actions/tempEdgeActions";
import types from "../../../Redux/actions/types.js";
import Loader from "../../../components/common/Loader/Loader";
import OutcomeBar from "../../../components/common/OutcomeBar";
import {
  REACT_APP_BASE_URL_TEMPEDGE,
  REACT_APP_URL_CLIENT_DELETE_POSITION,
  REACT_APP_URL_CLIENT_LIST,
  REACT_APP_URL_CLIENT_SAVE,
  REACT_APP_URL_CLIENT_UPDATE,
  REACT_APP_URL_COMMONS_FILE_DELETE,
  REACT_APP_URL_COMMONS_FILE_UPDATE,
  REACT_APP_URL_COUNTRY_LIST_ALL,
  REACT_APP_URL_DELETE_CLIENT_DEPARTMENT,
  REACT_APP_URL_FUNDING_LIST_ALL,
  REACT_APP_URL_PERSON_SALESMAN_LIST
} from "../../../components/common/Routes/URLs.js";
import { mapCountry } from "../../../components/common/TempEdgeUtils/TempEdgeUtils.js";
import TempEdgeApi from "../../../services/TempEdgeApi.js";
import { limitString } from "../../../utils/ReGexs";
import Department from "./Department.js";
import WizardCreateNewClientFirstPage from "./WizardCreateNewClientFirstPage.js";
import WizardCreateNewClientFourthPage from "./WizardCreateNewClientFourthPage.js";
import WizardCreateNewClientSecondPage from "./WizardCreateNewClientSecondPage.js";
import WizardCreateNewClientThirdPage from "./WizardCreateNewClientThirdPage";
import deleteIcon from "./assets/delete.png"; // Tell Webpack this JS file uses this image
import downIcon from "./assets/down.png";
import editIcon from "./assets/edit.png";
import addIcon from "./assets/plus.png";

//Department Modal re-init data
const reInitData = {
  position: "",
  description: "",
  markup: "",
  otmarkup: "",
  payRate: "",
  timeIn: "",
  timeOut: "",
  employeeContact: "",
  contactPhone: "",
};

//Form re-init data
const reInitFormData = {
  departmentname: "",
  position: "",
  description: "",
  markup: "",
  otmarkup: "",
  payRate: "",
  timeIn: "",
  timeOut: "",
  employeeContact: "",
  contactPhone: "",
  company: "",
  salesman: "",
  payrollCycle: "",
  workCompCode: "",
  workCompRate: "",
  companyInitials: "",
  attnTo: "",
  email: "",
  comments: "",
  markupClient: "",
  otMarkupClient: "",
  clientcountry: "",
  clientaddress: "",
  clientaddress2: "",
  clientcity: "",
  clientstate: "",
  clientzipcode: "",
  clientlastName: "",
  clientfirstName: "",
  clientcontactphone: "",
  clientcontactcellphone: "",
  fileUpdate: [],
  fileUpdateDelete: [],

};

class CreateNewClient extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.removeDepartmentList = [];
    this.personId = null;

    this.state = {
      page: 1,
      steps: [{ title: "" }, { title: "" }, { title: "" }, { title: "" }],
      departmentList: "",
      loading: false,
      addDeptBtn: "",
      renderAddBtnDirty: false,
      departmentContent: "",
      showModal: false,
      editClient: false,
      editMode: {
        index: null,
        edit: false,
      },
      FileUpdateList: [],
      FileUpdateDeleteList: [],
      clientId: 0,
      submitted: 0,
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      fileorcodefix: 0,
      message:'',
      isDepartment:true,

    };
    this.baseState = this.state;
  }

 

  componentDidMount = async () => {

    this.setState({
      loading: true,
    });
    this.props?.deletePositionCLient([]);
    if(this.props.message !== '' && this.props.message !== null){
      this.setState((state) => ({
        resultBar: (
          <OutcomeBar
            response={this.props.message}
          />
        ),
        submitted: 0,
      }));
    }    
    this.props.clearTempedgeStoreProp("client");
    this.props.clearTempedgeStoreProp("deptList");
    
    await this.props.getList(
      REACT_APP_URL_COUNTRY_LIST_ALL,
      types.GET_COUNTRY_REGION_LIST
    );
    this.props.getList(REACT_APP_URL_FUNDING_LIST_ALL, types.GET_FUNDING_LIST);
    await this.props.tempedgeAPI(
      REACT_APP_URL_PERSON_SALESMAN_LIST,
      {
        orgId: this.state.orgId,
        page: 0,
      },
      types.GET_SALESMAN_LIST
    );
    let path = this.props.location.pathname.split("/");
    if (path[2] === "update" && typeof this.props.clientId !== "undefined") {
      this.setState({ editClient: true });
      let response = await this.getClientEditMode(this.state.orgId);
      this.setFormEditMode(response);
    }
    this.setState({
      loading: false,
    });
  };

  componentWillUnmount = () => {
    this.resetEntireForm();
    this.props.clearTempedgeStoreProp("client");
    this.props.clearTempedgeStoreProp("salesmanList");
  };

  resetEntireForm = () => {
    this.props.clearTempedgeStoreProp("deptList");
    this.renderClientDepartmentsList({ repaint: false });
    this.state.reduxFormDispatch(updateMessage(""))
    this.props.saveDepartmentList([]);
    this.props.savePositionsList([]);
    this.props?.deletePositionCLient([])    
    this.props.clearTempedgeStoreProp('deletePositionClient')
    this.state.reduxFormDispatch(initialize("deletePositionClient",[]))
    if (this.state.reduxFormDispatch !== undefined)
      this.state.reduxFormDispatch(
        initialize("CreateNewClient", reInitFormData)
      );
    
    this.setState(this.baseState);
  };

  async getClientEditMode(orgId) {
    let baseUrlTempEdge = REACT_APP_BASE_URL_TEMPEDGE;
    let token = localStorage.getItem("access_token");
    let data = {
      orgId,
      id: this.props.clientId,
    };
    const response = await Axios({
      url: baseUrlTempEdge + "/api/client/findById",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data,
      params: {
        access_token: token,
      },
    });

    return response;
  }

  async setFormEditMode(formData) {
    let clientData = formData.data.result;
    this.setState({ clientId: clientData.clientId });
    if (clientData === null || clientData === undefined)
      //validar con algun mensaje
      return;
    this.personId = clientData.contact.personId;
    const person =
      clientData.clientSellers.length > 0
        ? clientData.clientSellers[0].person.personId
        : null;
    let deptList = [];
    deptList = clientData.departments.map((item) => {
      return {
        departmentId: item.departmentId,
        name: item.name,
        positions: item.positions,
      };
    });
    this.props.saveDepartmentList(deptList);
    this.renderClientDepartmentsList({ repaint: false });
    this.state.reduxFormDispatch(
      change("CreateNewClient", "company", clientData.clientName)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "workCompCode", `${clientData.wcCode}`)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "workCompRate", `${clientData.wcRate}`)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "companyInitials", clientData.clientInitials)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "attnTo", clientData.attn)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "markupClient", `${clientData.commonMarkup}`)
    );
    this.state.reduxFormDispatch(
      change(
        "CreateNewClient",
        "otMarkupClient",
        `${clientData.commonOtMarkup}`
      )
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "email", clientData.email)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "salesman", { personId: person })
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientaddress", clientData.address)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientaddress2", clientData.address2)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientcity", clientData.city)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientzipcode", clientData.zipcode)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientfirstName", clientData.contact.firstName)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientlastName", clientData.contact.lastName)
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientcontactphone", clientData.contact.phone)
    );
    this.state.reduxFormDispatch(
      change(
        "CreateNewClient",
        "clientcontactcellphone",
        clientData.contact.cellPhone
      )
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientcountry", {
        countryId: clientData.country,
        name: mapCountry(
          clientData.country,
          "name",
          "countryId",
          this.props.countryRegionList
        ),
      })
    );
    this.state.reduxFormDispatch(
      change("CreateNewClient", "clientstate", { regionId: clientData.region })
    );
    this.state.reduxFormDispatch(
      change(
        "CreateNewClient",
        "comments",
        clientData.notes ? clientData.notes : ""
      )
    );
    this.state.reduxFormDispatch(
      change(
        "CreateNewClient",
        "fileUpdate",
        clientData.fileEntities ? clientData.fileEntities : ""
      )
    );

    let payrollCycle = [
      {
        payrollCycle: "Sun - Sat",
        payrollId: 0,
      },
      {
        payrollCycle: "Mon - Sun",
        payrollId: 1,
      },
      {
        payrollCycle: "Tue - Mon",
        payrollId: 2,
      },
      {
        payrollCycle: "Wed - Tue",
        payrollId: 3,
      },
      {
        payrollCycle: "Thu - Wed",
        payrollId: 4,
      },
      {
        payrollCycle: "Fri - Thu",
        payrollId: 5,
      },
      {
        payrollCycle: "Sat - Fri",
        payrollId: 6,
      },
    ];

    if (clientData.payrollSchedule === 0) {
      this.state.reduxFormDispatch(
        change("CreateNewClient", "payrollCycle", payrollCycle[0])
      );
    } else if (clientData.payrollSchedule === 1) {
      this.state.reduxFormDispatch(
        change("CreateNewClient", "payrollCycle", payrollCycle[1])
      );
    } else if (clientData.payrollSchedule === 2) {
      this.state.reduxFormDispatch(
        change("CreateNewClient", "payrollCycle", payrollCycle[2])
      );
    } else if (clientData.payrollSchedule === 3) {
      this.state.reduxFormDispatch(
        change("CreateNewClient", "payrollCycle", payrollCycle[3])
      );
    } else if (clientData.payrollSchedule === 4) {
      this.state.reduxFormDispatch(
        change("CreateNewClient", "payrollCycle", payrollCycle[4])
      );
    } else if (clientData.payrollSchedule === 5) {
      this.state.reduxFormDispatch(
        change("CreateNewClient", "payrollCycle", payrollCycle[5])
      );
    } else if (clientData.payrollSchedule === 6) {
      this.state.reduxFormDispatch(
        change("CreateNewClient", "payrollCycle", payrollCycle[6])
      );
    }
  }

  UNSAFE_componentWillReceiveProps = async (nextprops) => { 
     
    if (typeof nextprops.client !== "undefined" && this.state.submitted === 1) {
      
      if (nextprops.client.status === 200) {
        if (nextprops.client.data.status === 200) {
          if (nextprops.client.data.code === "TE00") {
            this.setState({
              loading: true,
            });
            if (this.state.editClient) {             
              try {
                if(this.props.deptPostDeleteList.length > 0){
                  for (let index = 0; index < this.props.deptPostDeleteList.length; index++) {                              
                    await TempEdgeApi.post({
                      url: REACT_APP_URL_CLIENT_DELETE_POSITION,
                      payload: {
                        id: this.props.deptPostDeleteList[index],
                      },
                    }).then((res) => {
                      if (res.data?.code !== "TE00") {
                        this.setState((state) => ({
                          fileorcodefix: this.state.fileorcodefix + 1,
                        }));
                      }
                    }).catch((e) => {
                      this.setState((state) => ({
                        fileorcodefix: this.state.fileorcodefix + 1,
                      }));
                    })
                    
                    
                  }
                }
                let clientId = this.state.clientId;
                if (this.state?.FileUpdateDeleteList?.length > 0) {
                  for (
                    let index = 0;
                    index < this.state?.FileUpdateDeleteList?.length;
                    index++
                  ) {
                    let datas = {
                      moduleId: clientId,
                      module: "com.tempedge.msg.menu.client",
                      type: this.state.FileUpdateDeleteList[index]?.type,
                    };
                    if (
                      typeof this.state.FileUpdateDeleteList[index]?.file ===
                      "string"
                    ) {
                      await TempEdgeApi.post({
                        url: REACT_APP_URL_COMMONS_FILE_DELETE,
                        payload: datas,
                      })
                        .then((res) => {
                          if (res.data?.code !== "TE00") {
                            this.setState((state) => ({
                              fileorcodefix: this.state.fileorcodefix + 1,
                            }));
                          }
                        })
                        .catch((e) => {
                          this.setState((state) => ({
                            fileorcodefix: this.state.fileorcodefix + 1,
                          }));
                        });
                    }
                  }
                }
                if (this.state?.FileUpdateList?.length > 0) {
                  for (
                    let index = 0;
                    index < this.state?.FileUpdateList?.length;
                    index++
                  ) {
                    this.setState({
                      loading: true,
                    });
                    if (
                      typeof this.state.FileUpdateList[index].file !== "string" && this.state.FileUpdateList[index].file !== null
                    ) {
                      let datasFile = {
                        orgId: this.state.orgId,
                        moduleId: clientId,
                        module: "com.tempedge.msg.menu.client",
                        extension: this.state.FileUpdateList[index]?.fileName
                          ?.split(".")
                          .pop(),
                        type: this.state.FileUpdateList[index].type,
                      };

                      let requests = new FormData();
                      let stringDatas = JSON.stringify(datasFile);
                      let blobs = new Blob([stringDatas], {
                        type: "application/json",
                      });
                      requests.append("data", blobs);
                      requests.append(
                        "file",
                        this.state.FileUpdateList[index].file
                      );
                      await TempEdgeApi.postMultipart({
                        url: REACT_APP_URL_COMMONS_FILE_UPDATE,
                        payload: requests,
                      })
                        .then((res) => {
                          if (res.data?.code !== "TE00") {
                            this.setState((state) => ({
                              fileorcodefix: this.state.fileorcodefix + 1,
                            }));
                          }
                        })
                        .catch((e) => {
                          this.setState((state) => ({
                            fileorcodefix: this.state.fileorcodefix + 1,
                          }));
                        });
                    }
                  }
                }
                if (this.state.fileorcodefix > 0) {
                  this.setState({
                    loading: false,
                  });
                  this.setState((state) => ({
                    resultBar: (
                      <OutcomeBar
                        response={{
                          status:200,
                          code: "TE-E08",
                          message: "com.tempedge.warn.clientsaveandfileerror",
                        }}
                      />
                    ),
                    submitted: 0,
                  }));                  
                  this.setState(this.baseState);                  
                  this.setState({editClient:true}) 

                } else {
                  this.props.push("/client/list/en");
                }
                
              } catch (e) {
                this.setState((state) => ({
                  resultBar: <OutcomeBar response={e} />,
                  submitted: 0,
                }));
                this.setState({
                  loading: false,
                });
              }
            } else {
              let clientId = nextprops.client?.data?.result?.clientId;
              if (this.state?.FileUpdateList?.length > 0) {
                
                for (
                  let index = 0;
                  index <= this.state.FileUpdateList.length - 1;
                  index++
                ) {
                  let datas = {
                    orgId: this.state.orgId,
                    moduleId: clientId,
                    module: "com.tempedge.msg.menu.client",
                    extension: this.state.FileUpdateList[index]?.fileName
                      .split(".")
                      .pop(),
                    type: this.state.FileUpdateList[index]?.type,
                  };

                  let requests = new FormData();
                  let stringDatas = JSON.stringify(datas);
                  let blobs = new Blob([stringDatas], {
                    type: "application/json",
                  });
                  requests.append("data", blobs);
                  requests.append(
                    "file",
                    this.state.FileUpdateList[index].file
                  );
                  await TempEdgeApi.postMultipart({
                    url: REACT_APP_URL_COMMONS_FILE_UPDATE,
                    payload: requests,
                  })
                    .then((res) => {
                      if (res.data?.code !== "TE00") {
                        this.setState((state) => ({
                          fileorcodefix: this.state.fileorcodefix + 1,
                        }));
                      }
                    })
                    .catch((e) => {
                      this.setState((state) => ({
                        fileorcodefix: this.state.fileorcodefix + 1,
                      }));
                    });

                  continue;
                }
              }
              if (this.state.fileorcodefix > 0) {
                this.setState({
                  loading: false,
                });
                this.setState((state) => ({
                  resultBar: (
                    <OutcomeBar
                      response={{
                        status:200,
                        code: "TE-E08",
                        message: "com.tempedge.warn.clientsaveandfileerror",
                      }}
                    />
                  ),
                  submitted: 0,
                }));
                this.state.reduxFormDispatch(updateMessage({
                  status:200,
                  code: "TE-E08",
                  message: "com.tempedge.warn.clientsaveandfileerror",
                }))
                this.resetEntireForm();
              } else {
                this.setState({
                  loading: false,
                });
                this.setState((state) => ({
                  resultBar: <OutcomeBar response={nextprops.client} />,
                  submitted: 0,
                }));
                this.resetEntireForm();
              }
            }
            const { data: companies } = await TempEdgeApi.post({
              url: REACT_APP_URL_CLIENT_LIST,
              payload: {
                orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
                  ?.orgId,
              },
            });
            if (companies?.status === 200) {
              this.state.reduxFormDispatch(getListClientAll(companies.result));               
            }
          }
        } else if (nextprops.client.data.status === 401) {
          if (nextprops.client.data.code === "TE-E00") {
            this.setState(() => ({
              resultBar: <OutcomeBar response={nextprops.client} />,
              submitted: 0,
            }));
          }
        } else if (nextprops.client.data.status === 405) {
          if (nextprops.client.data.code === "TE-E09") {
            this.setState(() => ({
              resultBar: <OutcomeBar response={nextprops.client} />,
              submitted: 0,
            }));
          }
        } else if (nextprops.client.data.status === 500) {
          if (nextprops.client.data.code === "TE-E00") {
            this.setState(() => ({
              resultBar: <OutcomeBar response={nextprops.client} />,
              submitted: 0,
            }));
          }else if (nextprops.client.data.code === "TE-E07") {
            this.setState(() => ({
              resultBar: <OutcomeBar response={nextprops.client} />,
              submitted: 0,
            }));
          }else{
              this.setState(() => ({
                resultBar: <OutcomeBar response={nextprops.client} />,
                submitted: 0,
              }));   
          }
        } else {         
            this.setState(() => ({
              resultBar: <OutcomeBar response={nextprops.client} />,
              submitted: 0,
            }));          
        }
      } else {
        this.setState(() => ({
          resultBar: <OutcomeBar response={nextprops.client} />,
          submitted: 0,
        }));
        this.setState({
          loading: false,
        });
      }
    }
   
  };

  resetResultBar = () => {
    this.setState({ resultBar: "" });
  };

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  deleteDeparment = async (data) => {
    let baseUrlTempEdge = REACT_APP_BASE_URL_TEMPEDGE;
    let token = localStorage.getItem("access_token");
    const response = await Axios({
      url: baseUrlTempEdge + REACT_APP_URL_DELETE_CLIENT_DEPARTMENT,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data,
      params: {
        access_token: token,
      },
    });

    return response;
  };

  deletePostClient = async (data) => {  
     const {res}=await TempEdgeApi.post({
      url: REACT_APP_URL_CLIENT_DELETE_POSITION,
      payload: data,
    })
    if(!res.status === 200 ){
      this.setState((state) => ({
        resultBar: <OutcomeBar response={res} />,
        submitted: 0,
      }));
    }
      
  }

  onSave = async (response) => {
    this.setState((state) => ({
      fileorcodefix: 0,
    }));

    if (this.state.editClient) {
      for (const item in this.removeDepartmentList) {
        this.deleteDeparment({
          orgId: this.state.orgId,
          departmentId: this.removeDepartmentList[item],
        });
      }
      
      this.removeDepartmentList = [];
      response.clientId = this.props.clientId;
      response.contact.personId = this.personId;
      this.setState(
        () => ({
          submitted: 1,
        }),
        () => {
          this.props.tempedgeAPI(
            REACT_APP_URL_CLIENT_UPDATE,
            response,
            types.CREATE_CLIENT
          );
        }
      );
    } else {
      this.setState(
        () => ({
          submitted: 1,
        }),
        () => {
          this.props.tempedgeAPI(
            REACT_APP_URL_CLIENT_SAVE,
            response,
            types.CREATE_CLIENT
          );
        }
      );
    }
  };

  onSubmit = async (formValues) => {
    this.setState((state) => ({
      fileorcodefix: 0,
    }));

    let phone = formValues.clientcontactphone
      ? formValues.clientcontactphone
      : formValues.clientcontactcellphone;
    const { deptList } = this.props;
    if (deptList?.length > 0) {
      let response = {
        orgId: this.state.orgId,
        address:
          typeof formValues.clientaddress !== "undefined"
            ? formValues.clientaddress
            : "",
        address2: formValues.clientaddress2
          ? formValues.clientaddress2?.toUpperCase()
          : "",
        attn: typeof formValues.attnTo !== "undefined" ? formValues.attnTo : "",
          city: typeof formValues.clientcity !== "undefined"
            ? formValues.clientcity?.trim()
            : "",  
        clientInitials:
          typeof formValues.companyInitials !== "undefined"
            ? formValues.companyInitials
            : "",
        clientName:
          typeof formValues.company !== "undefined" ? formValues.company : "",
        commonMarkup:
          typeof formValues.markupClient !== "undefined"
            ? formValues.markupClient
            : "",
        commonOtMarkup:
          typeof formValues.otMarkupClient !== "undefined"
            ? formValues.otMarkupClient
            : "",
        country:
          typeof formValues.clientcountry !== "undefined"
            ? formValues.clientcountry.countryId
            : "",
        email: typeof formValues.email !== "undefined" ? formValues.email : "",
        notes:
          typeof formValues.comments !== "undefined" ? formValues.comments : "",
        payrollSchedule:
          typeof formValues.payrollCycle !== "undefined"
            ? formValues.payrollCycle.payrollId
            : "",
        phone: typeof phone !== "undefined" ? phone : "",
        region:
          typeof formValues.clientstate !== "undefined"
            ? formValues.clientstate.regionId
            : "",
        wcCode:
          typeof formValues.workCompCode !== "undefined"
            ? formValues.workCompCode
            : "",
        wcRate:
          typeof formValues.workCompRate !== "undefined"
            ? formValues.workCompRate
            : "",
        zipcode:
          typeof formValues.clientzipcode !== "undefined"
            ? formValues.clientzipcode
            : "",
        contact: {
          firstName:
            typeof formValues.clientfirstName !== "undefined"
              ? formValues.clientfirstName
              : "",
          lastName:
            typeof formValues.clientlastName !== "undefined"
              ? formValues.clientlastName
              : "",
          phone: typeof phone !== "undefined" ? phone : "",
          personType: {
            personTypeId: 2,
          },
          cellPhone:
            typeof formValues.clientcontactcellphone !== "undefined"
              ? formValues.clientcontactcellphone
              : "",
        },
        departments: typeof deptList !== "undefined" ? deptList : [],
        clientSellers: [
          {
            person: {
              personId:
                typeof formValues.salesman !== "undefined" &&
                  formValues.salesman.personId !== null &&
                  formValues.salesman.personId
                  ? formValues.salesman.personId
                  : 0,
            },
          },
        ],
      };
      this.setState({ FileUpdateList: formValues.fileUpdate });
      this.setState({ FileUpdateDeleteList: formValues.fileUpdateDelete });
      this.onSave(response);
    } else {
      this.setState({
        resultBar: (
          <OutcomeBar
           personMessage={"com.tempedge.error.client.clientincompleted"}
          />
        ),
        isDepartment:false
      });
    }
  };

  //Set Modal visible or not
  toggleModalOnOff = (destroy = null) => {
    this.setState({
      showModal: !this.state.showModal,
      departmentContent: destroy === null ? this.state.departmentContent : "",
    });
  };

  resetInitData = () => {
    for (let prop in this.props.formValues) {
      if (
        prop !== "departmentname" ||
        prop !== "position" ||
        prop !== "description" ||
        prop !== "markup" ||
        prop !== "otmarkup" ||
        prop !== "payRate" ||
        prop !== "timeIn" ||
        prop !== "timeOut" ||
        prop !== "employeeContact" ||
        prop !== "contactPhone"
      ) {
        reInitData[prop] = this.props.formValues[prop];
      }
    }
  };

  //Close Modal
  onClose = async () => {
    this.toggleModalOnOff(true);
    this.renderClientDepartmentsList({ repaint: true });
    this.props.savePositionsList([]);
    this.resetInitData();
    this.state.reduxFormDispatch(initialize("CreateNewClient", reInitData));
  };

  getDispatch = (dispatch) => {
    this.setState(() => ({
      reduxFormDispatch: dispatch,
    }));
  };

  renderClientDepartmentsList = (flag) => {
    const departmentList = this.props.deptList.map((dept, index) => {
      const key = `departments-${index}`;
      const name = limitString({ value: dept.name, limit: 15, replace: "..." });
  
      const tableRows = dept.positions.map((position, itemIndex) => (
        <tr key={itemIndex}>
          <td>{limitString({ value: position.name, limit: 9, replace: "..." })}</td>
          <td>{position.markUp} %</td>
          <td>{position.otMarkUp} %</td>
        </tr>
      ));
  
      return (
        <div key={key} id={key} className="mt-3">
          <div className="btn-dept">
            <a
              className="up-down-arrow pull-left"
              data-toggle="collapse"
              href={`#departments${index}`}
              role="button"
              aria-expanded="false"
              aria-controls={`departments${index}`}
            >
              <img
                src={downIcon}
                style={{
                  width: 14,
                  height: 11,
                  display: "inline",
                  marginLeft: 19,
                }}
                alt="downIcon"
              />
            </a>
            <span>{limitString({ value: name, limit: 15, replace: "..." })}</span>
            <span className="pull-right">
              <img
                src={editIcon}
                className="client-dpt-btn-edit-delete"
                style={{ display: "inline" }}
                onClick={() => this.departmentModalEdit(index)}
                alt="editIcon"
              />
              <img
                src={deleteIcon}
                className="client-dpt-btn-edit-delete"
                style={{ marginLeft: 17, marginRight: 29, display: "inline" }}
                onClick={() =>
                  this.removeDepartment(index, dept.departmentId ?? null)
                }
                alt="deleteIcon"
              />
            </span>
          </div>
  
          <div className="collapse multi-collapse show" id={`departments${index}`}>
            <div className="card card-body card-department-list">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th><Translate id="com.tempedge.msg.label.position" /></th>
                    <th><Translate id="com.tempedge.msg.label.markup" /></th>
                    <th><Translate id="com.tempedge.msg.label.OtMarkup" /></th>
                  </tr>
                </thead>
                <tbody>{tableRows}</tbody>
              </table>
            </div>
          </div>
        </div>
      );
    });
  
    const addDeptBtn = (
      <span
        style={{ marginTop: "3.2rem" }}
        className="float-button center-block pull-right add-fieldArray-btn"
        onClick={this.addDept}
      >
        <img src={addIcon} alt="addIcon" />
      </span>
    );
  
    const renderAddBtnDirty = this.props.deptList.length > 0;
  
    this.setState(() => ({
      departmentList: flag.repaint ? (renderAddBtnDirty ? departmentList : []) : departmentList,
      addDeptBtn,
      renderAddBtnDirty,
    }));
  
    if (!flag.repaint) {
      this.resetInitData();
      this.props.saveToPositionsList("CLEAR");
    }
  
    return "";
  };
  

  removeDepartment = async (index, id = null) => {
    if (id != null) this.removeDepartmentList.push(id);
    await this.props.removeFromDepartmentList(index);
    this.renderClientDepartmentsList({ repaint: true });
  };

  departmentModalEdit = async (index) => {
    let departmentname = this.props.deptList[index].name;
    let positionList = this.props.deptList[index].positions;
    this.resetInitData();
    this.state.reduxFormDispatch(
      change("CreateNewClient", "departmentname", departmentname)
    );

    await this.setState(
      () => ({
        editMode: {
          index: index,
          edit: true,
        },
      }),
      async () => {
        await this.props.savePositionsList(positionList);
        this.renderDepartmentModal();
      }
    );
  };

  renderAddBtn = () => {
   
    let addDtpBtn = (
      <React.Fragment>
        <div style={{ height: 10 }}></div>
        <p
          className={"department-list-button center-block"}
          onClick={() => this.renderDepartmentModal()}
          style={!this.state.isDepartment ? { border: "1px solid red" } : {}}
        >
          <Translate id="com.tempedge.msg.label.adddepartment" />
        </p>
      </React.Fragment>
    );
  
    return addDtpBtn;
  };

  addDept = async () => {
    await this.props.savePositionsList([]);

    this.setState(
      () => ({
        editMode: {
          index: null,
          edit: false,
        },
      }),
      () => {
        this.renderDepartmentModal();
        this.state.reduxFormDispatch(
          change("CreateNewClient", "departmentname", "")
        );
      }
    );
  };

  renderDepartmentModal = async () => {
    await this.setState(() => ({
      departmentContent: (
        <Department
          editMode={this.state.editMode}
          closePanel={() => this.onClose()}
          renderClientDepartmentsList={this.renderClientDepartmentsList}
          resetInitData={this.resetInitData}
          reInitData={reInitData}
        />
      ),
    }));

    this.toggleModalOnOff(); //Open Modal
  };

  render() {
    let { page, steps } = this.state;
    let modal = (
      <ModalSimple
        content={this.state.departmentContent}
        open={this.state.showModal}
        client={true}
        onClose={() => this.onClose()}
      />
    );
    
    if (this.state.loading) return <Loader />;

    return (
      <div className="wizard-create-agency">
        <Stepper
          steps={steps}
          activeStep={page - 1}
          activeColor="#eb8d34"
          completeColor="#8cb544"
          defaultBarColor="#eb8d34"
          completeBarColor="#8cb544"
          barStyle="solid"
          circleFontSize={16}
        />
        <div className="wizard-wrapper">
          {page === 1 && (
            <WizardCreateNewClientFirstPage
              onSubmit={this.nextPage}
              editClient={this.state.editClient}
              resultBar={this.state.resultBar}
              renderAddBtn={this.renderAddBtn}
              renderAddBtnDirty={this.state.renderAddBtnDirty}
              departmentList={this.state.departmentList}
              addDeptBtn={this.state.addDeptBtn}
              getDispatch={(dispatch) => this.getDispatch(dispatch)}
              renderClientDepartmentsList={this.renderClientDepartmentsList}
              resetResultBar={this.resetResultBar}
              resetEntireForm={this.resetEntireForm}
              {...this.props}
            />
          )}
          {page === 2 && (
            <WizardCreateNewClientSecondPage
              previousPage={this.previousPage}
              onSubmit={this.nextPage}
              editClient={this.state.editClient}
              resultBar={this.state.resultBar}
              renderAddBtn={this.renderAddBtn}
              renderAddBtnDirty={this.state.renderAddBtnDirty}
              departmentList={this.state.departmentList}
              addDeptBtn={this.state.addDeptBtn}
              {...this.props}
            />
          )}
          {page === 3 && (
            <WizardCreateNewClientThirdPage
              previousPage={this.previousPage}
              editClient={this.state.editClient}
              onSubmit={this.nextPage}
              resultBar={this.state.resultBar}
              renderAddBtn={this.renderAddBtn}
              renderAddBtnDirty={this.state.renderAddBtnDirty}
              departmentList={this.state.departmentList}
              addDeptBtn={this.state.addDeptBtn}
              {...this.props}
            />
          )}
          {page === 4 && (
            <WizardCreateNewClientFourthPage
              previousPage={this.previousPage}
              editClient={this.state.editClient}
              onSubmit={this.onSubmit}
              resultBar={this.state.resultBar}
              renderAddBtn={this.renderAddBtn}
              renderAddBtnDirty={this.state.renderAddBtnDirty}
              departmentList={this.state.departmentList}
              addDeptBtn={this.state.addDeptBtn}
              {...this.props}
            />
          )}
          {modal}
        </div>
      </div>
    );
  }
}

CreateNewClient.propTypes = {
  getList: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  saveDepartmentList: PropTypes.func.isRequired,
  savePositionsList: PropTypes.func.isRequired,
  saveToPositionsList: PropTypes.func.isRequired,
  removeFromDepartmentList: PropTypes.func.isRequired,
  tempedgeAPI: PropTypes.func.isRequired,
  clearTempedgeStoreProp: PropTypes.func.isRequired,
};

let mapStateToProps = (state) => {
  let departmentname = "";
  let client = state.tempEdge.client;
  var messageFileError = state.tempEdge.message
  

  if (state.form.CreateNewClient !== undefined) {
      if (state.form.CreateNewClient.values !== undefined) {
        let formState = state.form.CreateNewClient.values;
        departmentname = formState.departmentname;
      }
    }

    return {
      deptList:
        typeof state.tempEdge.deptList !== "undefined"
          ? state.tempEdge.deptList
          : [],
      deptPosList:
        typeof state.tempEdge.deptPosList !== "undefined"
          ? state.tempEdge.deptPosList
          : [],
      deptPostDeleteList:
        typeof state.tempEdge.deletePostClient !== "undefined"
          ? state.tempEdge.deletePostClient
          : [],
      departmentname: departmentname,
      formValues:
        typeof state.form.CreateNewClient !== "undefined"
          ? state.form.CreateNewClient.values
          : "",
      billRate:
        typeof state.tempEdge.billRate !== "undefined"
          ? state.tempEdge.billRate
          : 0,
      otBillRate:
        typeof state.tempEdge.otBillRate !== "undefined"
          ? state.tempEdge.otBillRate
          : 0,
      client: client,
      countryRegionList: state.tempEdge?.country_region_list
        ? state.tempEdge.country_region_list
        : [],
      clientId: state.tempEdge.clientId,
      message:messageFileError
    };
  };


  export default withLocalize(
    connect(mapStateToProps, {
      getList,
      reset,
      change,
      initialize,
      saveDepartmentList,
      savePositionsList,
      saveToPositionsList,
      removeFromDepartmentList,
      tempedgeAPI,
      deletePositionCLient,
      clearTempedgeStoreProp,
      push,
    })(CreateNewClient)
  );
