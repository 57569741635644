import { calculateWeeklyPayrollTotals } from "../../utils/helpers/TotalHoursFormat";
import { payrollValidationTypes } from "../types/payrollValidationTypes";

const initialState = {
  dateStart: "",
  dateEnd: "",
  resultBar: null,
  requestDay: [],
  payrollWeekValidation: [],
  payrollResultWeekValidation: [],
  absentValidation: [],
  changeValueAbsent: [],
};

export const payrollValidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case payrollValidationTypes.ADD_PAYROLL_VALIDATION:
      const { employees, date1, date2 } = action.payload;
      return {
        ...state,
        dateStart: date1,
        dateEnd: date2,
        payrollWeekValidation:calculateWeeklyPayrollTotals(employees,date1),
      };

    case payrollValidationTypes.ADD_PAYROLL_ABSENT_VALIDATION: {
      let newAbsent = action.payload?.map((payroll) => {
        for (const [key, value] of Object.entries(payroll)) {
          if (key !== null) {
            if (value) {
              if (value.totalRegHour === 0 && value.totalOtHour === 0) {
                return payroll;
              }
            }
          }
        }
        return payroll;
      });
      return {
        ...state,
        absentValidation: newAbsent,
      };
    }

    case payrollValidationTypes.CHANGE_VALUES_PAYROLL: {
      const { id, day, key, payrollId } = action.payload;
      let { changeValueAbsent } = state;
      let exist = false;
      if (changeValueAbsent?.length > 0) {
        exist = changeValueAbsent.find(
          (item) =>
            item.id === id &&
            item[day]?.payrollId === payrollId &&
            item.day === day &&
            item.key === key
        );
        if (!exist) changeValueAbsent = [...changeValueAbsent, action.payload];
      } else {
        changeValueAbsent = [action.payload];
      }
      if (exist && changeValueAbsent.length > 0) {
        let newValuesChanges = changeValueAbsent.map((data) => {
          if (
            data.id === id &&
            data.day === day &&
            data[day].payrollId === payrollId &&
            data.key === key
          ) {
            data = action.payload;
            return data;
          }
          return action.payload;
        });
        changeValueAbsent = newValuesChanges;
      }
      return {
        ...state,
        changeValueAbsent,
      };
    }

    /* reducer change páyrate */
    case payrollValidationTypes.CHANGE_PAYRATE_VALIDATION: {
      const { id, employeeCancel } = action.payload;
      let { payrollWeekValidation,dateStart } = state;
      if (payrollWeekValidation) {
        payrollWeekValidation = payrollWeekValidation.map((employee) => {
          if (employee?.id === id) {
            let employeeCopy = { ...employeeCancel };
            return employeeCopy;
          }
          return employee;
        });
      }
      return {
        ...state,
        payrollWeekValidation:calculateWeeklyPayrollTotals(payrollWeekValidation,dateStart),
      };
    }

    case payrollValidationTypes.CHANGE_WEEK_ATTENDANCE_VALUE: {
      const { id, day, key, value, payrollId } = action.payload;
      let { payrollWeekValidation, dateStart } = state;
      if (payrollWeekValidation) {
        payrollWeekValidation = payrollWeekValidation.map((employee) => {
          if (
            employee?.id === id &&
            employee[day]?.payrollId === payrollId &&
            employee[day]
          ) {
            let employeeCopy = { ...employee };
            employeeCopy[day][key] = parseFloat(value);
            if(key === "attendance"){
              employeeCopy[day]['attendance'] = value;
            }
            return employeeCopy;
          }
          return employee;
        });
        payrollWeekValidation = calculateWeeklyPayrollTotals(
          payrollWeekValidation,
          dateStart
        );
      }
      return {
        ...state,
        payrollWeekValidation,
      };
    }

    case payrollValidationTypes.CHANGE_WEEK_PAYRATE_VALUE: {
      const { id, day, key, value, payrollId } = action.payload;
      let { payrollWeekValidation, dateStart } = state;
      if (payrollWeekValidation) {
        payrollWeekValidation = payrollWeekValidation.map((employee) => {
          if (
            employee?.id === id &&
            employee[day]?.payrollId === payrollId &&
            employee[day]
          ) {
            let employeeCopy = { ...employee };
            employeeCopy[day][key] = parseFloat(value);
            return employeeCopy;
          }
          return employee;
        });
        payrollWeekValidation = calculateWeeklyPayrollTotals(
          payrollWeekValidation,
          dateStart
        );
      }
      return {
        ...state,
        payrollWeekValidation,
      };
    }

    // funcion que permite subir el employee que no tiene horas, subirlo con las horas que se le agregaron
    case payrollValidationTypes.ADD_HOURS_PAYROLL_VALIDATION: {
      const { id, positionId } = action.payload;
      let { payrollWeekValidation, changeValueAbsent, dateStart } = state;
      let payroll = [];
      if (payrollWeekValidation) {
        changeValueAbsent?.map((data) => {
          if (data.id === id) {
            payroll.push(data);
          }
          return data;
        });
        payrollWeekValidation = payrollWeekValidation.map((employee) => {
          if (
            employee?.id === id &&
            employee?.position?.positionId === positionId
          ) {
            for (let i = 0; i < payroll.length; i++) {
              if (
                "totalRegHour" === payroll[i].key &&
                employee[payroll[i].day]
              ) {
                employee[payroll[i].day].totalRegHour = payroll[i].key
                  ? parseFloat(payroll[i].value)
                  : 0;
              }
              if (
                "totalOtHour" === payroll[i].key &&
                employee[payroll[i].day]
              ) {
                employee[payroll[i].day].totalOtHour = payroll[i].key
                  ? parseFloat(payroll[i].value)
                  : 0;
              }
            }
            return employee;
          }
          return employee;
        });
        payrollWeekValidation = calculateWeeklyPayrollTotals(
          payrollWeekValidation,
          dateStart
        );
      }
      return {
        ...state,
        payrollWeekValidation,
      };
    }

    case payrollValidationTypes.CHANGE_HOURS_PAYROLL: {
      const { id, day, key, value, payrollId } = action.payload;
      let { payrollWeekValidation, dateStart } = state;
      if (payrollWeekValidation) {
        payrollWeekValidation = payrollWeekValidation?.map((employee) => {
          if (
            employee?.id === id &&
            employee[day]?.payrollId === payrollId &&
            employee[day]
          ) {
            let employeeCopy = { ...employee };
            employeeCopy[day][key] = parseFloat(value);
            return employeeCopy;
          }
          return employee;
        });
      }
      payrollWeekValidation = calculateWeeklyPayrollTotals(
        payrollWeekValidation,
        dateStart
      );
      return {
        ...state,
        payrollWeekValidation,
      };
    }

    case payrollValidationTypes.CANCEL_WEEK_PAYRATE_VALUES: {
      return {
        ...state,
        payrollWeekValidation: action.payload
      }
    }

    case payrollValidationTypes.CHANGE_HOURS_PAYROLL_LOCKED: {
      const { id, day, key, value, payrollId } = action.payload;
      let { payrollWeekValidation, dateStart } = state;
      if (payrollWeekValidation) {
        payrollWeekValidation = payrollWeekValidation?.map((employee) => {
          if (
            employee?.id === id &&
            employee[day]?.payrollId === payrollId &&
            employee[day]
          ) {
            let employeeCopy = { ...employee };
            employeeCopy[day][key] = parseFloat(value);
            return employeeCopy;
          }
          return employee;
        });
      }
      return {
        ...state,
        payrollWeekValidation: calculateWeeklyPayrollTotals(
          payrollWeekValidation,
          dateStart
        ),
      };
    }

    case payrollValidationTypes.REQUEST_LIST_SAVING:
      return {
        ...state,
        requestDay: [action.payload],
      };
    case payrollValidationTypes.SAVE_PAYROLL_RESULT_VALIDATION:
      return {
        ...state,
        payrollResultWeekValidation: action.payload,
      };

    case payrollValidationTypes.CHANGE_STATE: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }

    case payrollValidationTypes.RESET_ENTIRE_STATE:
      return { initialState };

    case payrollValidationTypes.CLEAR_PROP: {
      return {
        ...state,
        payrollWeekValidation: [],
        absentValidation: [],
      };
    }

    default:
      return state;
  }
};
