import { useState, useEffect } from 'react';

const useRecaptchaTimer = (callback, delay = 60000) => {
    const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);

    useEffect(() => {
        let timer;
        
        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setShowRecaptchaV2(true);
                if (callback) {
                    callback(true);
                }
            }, delay); // Tiempo de inactividad
        };

        const events = ['mousemove', 'keypress', 'scroll', 'click'];

        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });

        resetTimer();

        return () => {
            clearTimeout(timer);
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
        };
    }, [callback, delay]);

    return showRecaptchaV2;
};

export default useRecaptchaTimer;
