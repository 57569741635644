import { useEffect, useState } from 'react';
import TempEdgeApi from '../../../services/TempEdgeApi';
import { REACT_APP_URL_FIND_LOGIN_BY_OFFICE } from '../../../components/common/Routes/URLs';

const useRadarChartData = () => {
  const [radarChartData, setRadarChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const orgId = JSON.parse(localStorage.getItem("agency"))?.organizationEntity?.orgId;
      setLoading(true);
      try {
        const { data } = await TempEdgeApi.post({
          url: REACT_APP_URL_FIND_LOGIN_BY_OFFICE,
          payload: {
            orgId,
          },
        });
        if (data?.status === 200) {
          setRadarChartData(data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
    };
  }, []);

  return { radarChartData, loading, error };

};

export default useRadarChartData;
