import moment from "moment";
import React, { useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { useDispatch } from "react-redux";
import Modal from "../../Modals/Modal/Modal";
import {
  addEmployee,
  changeEmployeeValue,
  removeEmployee,
  resetEntireState,
} from "../../Redux/actions/adjustmentActions";
import addIcon from "../../assets/icons/plus.png";
import Datepicker from "../../components/common/Datepicker/Datepicker";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import { inputTypeHours, limitString } from "../../utils/ReGexs";
import deleteIcon from "../../assets/icons/delete.png";
import AdjustmentInfo from "./AdjustmentInfo";

const DATE_FORMAT_FRONTEND = "MM/DD/YYYY";

function AdjustmentTable({ translate, adjustment, departments, weekActive, weekInfo, adjustmentValidations, error, setFindWeekisonecompany, initState, setMessage, deleteEntiti, employees }) {
  const dispatch = useDispatch();

  const [modal, setModal] = useState({
    show: false,
    content: null,
  });


  const onChangeValues = () => {

    let check = true
    for (let employee of employees) {

      if (
        employee.position === null ||
        (employee.totalRegHour === null && employee.totalOtHour === null) ||
        employee.pay === null ||
        employee.position === "" ||
        (employee.totalRegHour === "" && employee.totalOtHour === "") ||
        employee.pay === ""
      ) {
        check = false;
      
        const hasPositionError = !employee.position;
        const hasHoursError = (employee.totalRegHour === 0 && employee.totalOtHour === 0) || (employee.totalRegHour === "" && employee.totalOtHour === "");
      
        dispatch(
          changeEmployeeValue({
            id: employee.id,
            key: "errors",
            value: {
              position: hasPositionError,
              pay: !inputTypeHours(employee.pay) ,
              hours: hasHoursError,
            },
          })
        );
      }
     

      if (
        employee.errors.date ||
        employee.errors.hours ||
        employee.errors.pay ||
        employee.errors.position ||
        employee.errors.paymentDate
      ) {
        check = false;
      }
      validatorHours(employee);
     
      if (!employee.confirmation) {
        error(new Error('com.tempedge.error.confirmationhours'))
        check = false
      }

      if (Number(employee.totalRegHour) === 0 && Number(employee.totalOtHour) === 0) {
        dispatch(
          changeEmployeeValue({
            id: employee.id,
            key: "errors",
            value: {
              ...employee.errors,
              hours: true,
            },
          })
        )
        check = false
      }

      if (Number(employee.pay) === 0 || employee.pay === 'undefinded') {

        dispatch(
          changeEmployeeValue({
            id: employee.id,
            key: "errors",
            value: {
              ...employee.errors,
              pay:true,
            },
          })
        )
        check = false
      }

      if (!employee.date || isNaN(new Date(employee.date))) {
        dispatch(
          changeEmployeeValue({
            id: employee.id,
            key: 'errors',
            value: {
              ...employee.errors,
              date: true,
            },
          })
        );
        check = false;
      }

      if (!employee.paymentDate || isNaN(new Date(employee.paymentDate))) {
        dispatch(
          changeEmployeeValue({
            id: employee.id,
            key: 'errors',
            value: {
              ...employee.errors,
              paymentDate: true,
            },
          })
        );
        check = false;
      }
    }

    if (check) {
      adjustmentValidations(true)
      error(null)
    }

  }

  const validatorHours = (employee) => {

    const rhHours = parseFloat(employee.totalRegHour || 0);
    const otHours = parseFloat(employee.totalOtHour || 0);
  
    const isRhValid = inputTypeHours(rhHours || '0');

    const isOtValid = inputTypeHours(otHours || '0');
  
    const isRhFilled = rhHours !== 0;
    const isOtFilled = otHours !== 0;
  
    const isRhError = !isRhValid && isRhFilled;

    const isOtError = !isOtValid && isOtFilled;
  
    const hasAnyError = isRhError || isOtError || (!isRhFilled && !isOtFilled);
  
    dispatch(
      changeEmployeeValue({
        id: employee.id,
        key: "errors",
        value: {
          ...employee.errors,
          hours: hasAnyError,
          totalRegHourError: isRhError,
          totalOtHourError: isOtError,
        },
      })
    );
  };

  return (
    <div>
      <div className="row mt-3">
        <div className="col-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="table-header-left text-center w-5">EE #</th>
                <th className="table-header-mid text-center w-10">
                  {translate("com.tempedge.msg.label.lastname")}
                </th>
                <th className="table-header-mid text-center w-10">
                  {translate("com.tempedge.msg.label.firstname")}
                </th>
                <th className="table-header-mid text-center w-10">
                  {translate("com.tempedge.msg.label.deptpos")}
                </th>
                <th className="table-header-mid text-center w-10">
                  {translate("com.tempedge.msg.label.pay")}
                </th>
                <th className="table-header-mid text-center w-10">
                  {translate("com.tempedge.reports.columns.colhourspos")}
                </th>
                <th className="table-header-mid text-center w-10">
                  {translate("com.tempedge.msg.label.adjustmentdate")}
                </th>
                <th className="table-header-mid text-center w-10">
                  {translate("com.tempedge.msg.label.paymentdate")}
                </th>
                <th className="table-header-right text-center w-10">
                  {translate("com.tempedge.msg.label.opt")}
                </th>
              </tr>
            </thead>
            <tbody>
              {employees &&
                employees.map((employee, index) => {
                  return (
                    <tr key={index + employee.id} className={"time-sheet-row"}>
                      <td className="" style={{ textAlign: "center" }}>{employee.employeeId}</td>
                      <td className="" style={{ textAlign: "center" }}>{employee.lastName}</td>
                      <td className="" style={{ textAlign: "center" }}>{employee.firstName}</td>
                      <td>
                        <DropdownList
                          data={departments}
                          textField={"positionName"}
                          valueField={"positionId"}
                          required={true}
                          value={limitString({ value: employee.position ? employee.position : '', limit: 15, replace: '...' })}
                          isInvalid={employee.errors.position}
                          onChange={(value) => {
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "position",
                                value,
                              })
                            );
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "errors",
                                value: {
                                  ...employee.errors,
                                  position: false,
                                  pay: false
                                },
                              })
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          style={{ fontSize: "12px", textAlign: 'center' }}
                          type="number"
                          value={employee.pay}
                          placeholder='0.0'
                          maxLength={7}
                          className={`form-control tempEdge-input-box ${employee.errors.pay ? "has-error" : ""
                            }`}
                          onChange={(event) => {
                            let payvalidation =inputTypeHours(event.target.value)
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "pay",
                                value: event.target.value,
                              })
                            );
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "errors",
                                value: {
                                  ...employee.errors,
                                  pay: !payvalidation,
                                },
                              })
                            );
                          }}
                          onBlur={(event) => {
                            let payvalidation = inputTypeHours(event.target.value)
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "pay",
                                value: event.target.value,
                              })
                            )
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "errors",
                                value: {
                                  ...employee.errors,
                                  pay: !payvalidation,
                                },
                              })
                            )
                            if (Number(employee.pay) === 0) {
                              dispatch(
                                changeEmployeeValue({
                                  id: employee.id,
                                  key: "errors",
                                  value: {
                                    ...employee.errors,
                                    pay: true,
                                  },
                                })
                              )

                            }
                          }}

                        />
                      </td>
                      <td className="" style={{ textAlign: "center" }}>
                        <div className="input-group input-group-sm ">
                          <div
                            className="input-group-prepend "
                            style={{ width: "auto" }}
                          >
                            <span
                              className="input-group-text "
                              id="inputGroup-sizing-sm"
                            >
                              RH
                            </span>
                          </div>
                          <input
                            style={{ fontSize: "12px" }}
                            className={`form-control ${employee.errors.totalRegHourError && employee.errors.hours ? "has-error" : ""} ${employee.errors.hours ? "has-error" : ""}`}
                            type="text"
                            step="0.01"
                            placeholder='0.0'
                            maxLength={6}
                            aria-label="totalRegHour"
                            aria-describedby="basic-addon1"
                            value={employee.totalRegHour || 0}
                            onChange={(event) => {
                              let reghourValidation = inputTypeHours(event.target.value)
                              dispatch(
                                changeEmployeeValue({
                                  id: employee.id,
                                  key: "totalRegHour",
                                  value: event.target.value,
                                })
                              );
                              dispatch(
                                changeEmployeeValue({
                                  id: employee.id,
                                  key: "errors",
                                  value: {
                                    ...employee.errors,
                                    totalRegHourError: !reghourValidation,
                                  },
                                })
                              );
                            }}
                            onBlur={() => validatorHours(employee)}
                          />
                          <span> </span>
                          <input
                            style={{ fontSize: "12px" }}
                            className={`form-control ${employee.errors.totalOtHourError && employee.errors.hours ? "has-error" : ""} ${employee.errors.hours ? "has-error" : ""}`}
                            type="text"
                            placeholder='0.0'
                            maxLength={6}
                            aria-label="totalOtHour"
                            aria-describedby="basic-addon2"
                            value={employee.totalOtHour || '0'}
                            onChange={(event) => {
                              let othoursValidation = inputTypeHours(event.target.value)
                              dispatch(
                                changeEmployeeValue({
                                  id: employee.id,
                                  key: "totalOtHour",
                                  value: event.target.value,
                                })
                              );
                              dispatch(
                                changeEmployeeValue({
                                  id: employee.id,
                                  key: "errors",
                                  value: {
                                    ...employee.errors,
                                    totalOtHourError: !othoursValidation,
                                  },
                                })
                              );
                            }}
                            onBlur={() => validatorHours(employee)}
                          />
                          <div className="input-group-append " id="cellWeek">
                            <span className="input-group-text " id="cellWeek">
                              OT
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className={"text-center"}>
                        <Datepicker
                          customClass={`form-control text-center tempEdge-input-box ${employee.errors.date ? "has-error" : ""
                            }`}
                          selectedValue={
                            employee.date ? moment(employee.date).toDate() : moment(adjustment.date).toDate()
                          }
                          maxDate={moment(weekInfo.endDate).toDate()}
                          onChange={(value) => {
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "date",
                                value: moment(value).format(DATE_FORMAT_FRONTEND),
                              })
                            );
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "errors",
                                value: {
                                  ...employee.errors,
                                  date: false,
                                },
                              })
                            );
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <Datepicker
                          customClass={`form-control text-center tempEdge-input-box ${employee.errors.paymentDate ? "has-error" : ""
                            }`}
                          selectedValue={
                            employee.paymentDate ? moment(employee.paymentDate).toDate() : moment(adjustment.paymentDate).toDate()
                          }
                          maxDate={moment(weekActive.end).toDate()}
                          onChange={(value) => {
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "paymentDate",
                                value: moment(value).format(DATE_FORMAT_FRONTEND),
                              })
                            );
                            dispatch(
                              changeEmployeeValue({
                                id: employee.id,
                                key: "errors",
                                value: {
                                  ...employee.errors,
                                  paymentDate: false,
                                },
                              })
                            );
                          }}
                        />
                      </td>
                      <td className="mx-auto"
                        style={{
                          alignItems: 'center',
                          justifyContent: 'spaceBetween'
                        }}>
                        <button
                          className="btn"
                          type="button"
                          onClick={() => {
                            setModal({
                              show: true,
                              content: (
                                <AdjustmentInfo
                                  person={employee}
                                  week={weekInfo}
                                  closeModal={() =>
                                    setModal({ show: false, content: null })
                                  }
                                  weekActive={weekActive}
                                  deleteEntiti={deleteEntiti}
                                  adjustment={adjustment}
                                />
                              ),
                            });
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              width: "2.6rem",
                              marginTop: '3px',
                              color: `${employee.confirmation
                                ? "var(--tempedge-green)"
                                : "#dc2626"
                                }`,
                            }}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(addEmployee({
                              id: employee.personId + '_' + (parseInt(employee.personId) + employees.length + 1),
                              personId: employee.personId,
                              employeeId: employee.employeeId,
                              firstName: employee.firstName,
                              lastName: employee.lastName,
                              position: employee.position,
                              pay: 0,
                              date: moment(adjustment.date).format(DATE_FORMAT_FRONTEND),
                              totalOtHour: 0,
                              totalRegHour: 0,
                              adjustmentEntities: employee.adjustmentEntities,
                              payrollEntityList: employee.payrollEntityList,
                              confirmation: employee.confirmation,
                              paymentDate: moment(employee.paymentDate).format(DATE_FORMAT_FRONTEND),
                              errors: {
                                hours: false,
                                date: false,
                                position: false,
                              }
                            }));
                          }}
                          className="btn"
                        >
                          <img
                            style={{ width: "28px" }}
                            src={addIcon}
                            alt="addIcon"
                          />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(removeEmployee(employee.id));
                          }}
                          className="btn"
                        >
                          <img
                            style={{ width: "18px" }}
                            className="icon-width"
                            src={deleteIcon}
                            alt="Delete icon"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row justify-content-end mt-4">
        <div className="form-group col-12 col-lg-3">
          <button
            type="button"
            onClick={() => {
              dispatch(resetEntireState());
              setFindWeekisonecompany(true);
              initState()
              setMessage(null)
            }}
            className="btn btn-gray square-right-side w-100"
          >
            {<Translate id='com.tempedge.msg.label.cancel' />}
          </button>
        </div>
        <div className="form-group col-12 col-lg-3">
          <button type="button"
            onClick={() => { onChangeValues() }}
            className="btn btn-blue square-left-side w-100"
          >
            {<Translate id='com.tempedge.msg.label.next' />}
          </button>
        </div>
      </div>
      <Modal
        open={modal.show}
        content={modal.content}
        onClose={() => setModal({ show: false, content: null })}
        modalSize={"modal-xl"}
      />
    </div>
  );
}

export default withLocalize(AdjustmentTable);