import { renderToStaticMarkup } from 'react-dom/server';
export default function defaultLanguages () {

    const onMissingTranslation = () => "--";
    
    let defaultLanguage = 'en';

    let languages = [
    { name: 'English', code: 'en' },
    { name: 'Spanish', code: 'es' }
    ];
 
    let options = {
    defaultLanguage: defaultLanguage,
    renderToStaticMarkup: renderToStaticMarkup,
    onMissingTranslation
    };

    return {languages,options};
}