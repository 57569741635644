import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Custompayroll from './Custompayroll';


class Custompayrolls extends Component {

   constructor(props) {
      super(props);
      this.addTranslationsForActiveLanguage();
   }

   addTranslationsForActiveLanguage = async () => {
      await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   };

   async componentDidUpdate(prevProps) {
      let hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
      if (hasActiveLanguageChanged) {
         await this.props.push(`/custompayroll/${this.props.activeLanguage.code}`);
         this.addTranslationsForActiveLanguage();
      }
   }   
   render() {
      return <Custompayroll/>;
   }
}

export default withLocalize(
   connect(null, { push })(Custompayrolls)
);
