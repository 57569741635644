import React, { useState, useCallback, useEffect } from "react";
import { Translate } from "react-localize-redux";
import BlueCommonBtn from "../Buttons/BlueCommonBtn";
import GrayCommonBtn from "../Buttons/GrayCommonBtn";
import TPaginator from "./TPaginator";

const ParametersTable = ({ data, setValidationOk, setData, resetState,changePage }) => {
  const [state, setState] = useState(data);
  const [editCell, setEditCell] = useState("");
  const [editValue, setEditValue] = useState("");
  const [dataBody, setDataBody] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const dataTable = useCallback(() => {
    setState(data);
    setDataBody(data.data.content ? data?.data?.content : data.data);
    dataBody.map((item) => {
      let isEmpty = Object.values(item).some((x) => x === "");
      if (isEmpty) setButtonDisabled(true);
      return null;
    });
  }, [data, dataBody]);

  const changeValue = (value, item, e) => {
    value[item.field] = e.target.value;
    setEditValue(value[item.field]);
  };

  const changeBlue = (value, item, e) => {
    let validValue = e.target.value;
    if (validValue !== "") {
      value[item.field] = validValue;
      setEditValue("");
      setEditCell("");
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  useEffect(() => {
    dataTable();
  }, [dataTable]);

  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            {state?.columns?.map((item, index) => {
              if (state.keyField !== item.field) {
                return (
                  <th
                    key={index}
                    className="table-header-mid text-center w-10"
                    style={{
                      maxWidth: item.maxFieldSize,
                      minWidth: item.minFieldSize,
                    }}
                  >
                    {<Translate id={item.label} />}
                  </th>
                );
              }
              return null;
            })}
          </tr>
        </thead>
        <tbody>
          {dataBody?.map((value, ind) => (
            <tr key={ind}>
              {state?.columns?.map((item, index) => {
                if (
                  state.keyField !== item.field &&
                  !value[state.keyField]?.toString()?.includes("freeId")
                ) {
                  return (
                    <td
                      key={index}
                      className="text-center"
                      style={{
                        maxWidth: item.maxFieldSize,
                        minWidth: item.minFieldSize,
                      }}
                      onDoubleClick={() =>
                        setEditCell(
                          editCell
                            ? { ...editCell }
                            : { select: item.field, id: value[state.keyField] }
                        )
                      }
                    >
                      <p>
                        {editCell.id === value[state.keyField] &&
                        editCell.select === item.field ? (
                          <input
                            style={{ fontSize: "12px" }}
                            type="text"
                            className="form-control tempEdge-input-box"
                            aria-label={value[item.field]}
                            aria-describedby="basic-addon2"
                            value={
                              editValue === "" ? value[item.field] : editValue
                            }
                            required={true}
                            onChange={(e) => {
                              changeValue(value, item, e);
                            }}
                            onBlur={(e) => {
                              changeBlue(value, item, e);
                            }}
                          />
                        ) : (
                          value[item.field]
                        )}
                      </p>
                    </td>
                  );
                }
                if (value[state.keyField]?.toString()?.includes("freeId")) {
                  if (state.keyField !== item.field) {
                    return (
                      <td
                        key={index}
                        className="text-center"
                        style={{
                          maxWidth: item.maxFieldSize,
                          minWidth: item.minFieldSize,
                        }}
                      >
                        <input
                          style={{ fontSize: "12px" }}
                          type="text"
                          className="form-control tempEdge-input-box"
                          aria-label={value[item.field]}
                          aria-describedby="basic-addon2"
                          value={value[item.field]}
                          required={true}
                          onChange={(e) => {
                            changeValue(value, item, e);
                          }}
                          onBlur={(e) => {
                            changeBlue(value, item, e);
                          }}
                        />
                      </td>
                    );
                  }
                }
                return null;
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr colSpan="100%">
            <td
              style={{ border: "none", borderStyle: "none" }}
              colSpan="100%"
              className="mx-auto"
            >
              {<TPaginator changePage={changePage} />}
            </td>
          </tr>
        </tfoot>
      </table>
      {dataBody.length > 0 && (
        <div className="row py-4">
          <div className="col-md-5 offset-md-1">
            <GrayCommonBtn
              tag="com.tempedge.msg.label.cancel"
              onClick={() => {
                setData("");
                resetState();
              }}
            />
          </div>
          <div className="col-md-5">
            <BlueCommonBtn
              tag="com.tempedge.msg.label.save"
              onClick={() => {
                setValidationOk(true);
              }}
              invalid={buttonDisabled}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ParametersTable;
