import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Translate, withLocalize } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../Modals/Modal/Modal";
import {
  addEmployeeHistory,
  resetEntireStateHistory,
} from "../../../Redux/actions/payrollPersonHistoryActions";
import ActiveLanguageAddTranslation from "../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import Loader from "../../../components/common/Loader/Loader";
import OutcomeBar from "../../../components/common/OutcomeBar";
import { REACT_APP_URL_FIND_PAYROLL_BY_PERSON } from "../../../components/common/Routes/URLs";
import TempEdgeApi from "../../../services/TempEdgeApi";
import { addHistory, generateUniqueId } from "../../../utils/PayrollUtils";
import EmployeeList from "../../Employee/EmployeeList/EmployeeList";
import PayrollHistoryTablet from "./PayrollHistoryTablet";
import EmployeeTable from "../../../components/common/EmployeeTable/EmployeeTable";
import { formatUsNumber } from '../../../utils/ReGexs';

const PayrollByPerson = ({ activeLanguage, history, addTranslationForLanguage }) => {
  const [prevActiveLanguage, setPrevActiveLanguage] = useState(activeLanguage);

  useEffect(() => {    
    const hasActiveLanguageChanged = prevActiveLanguage !== activeLanguage;
    if (hasActiveLanguageChanged) {
      history.push(`/payroll/history/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
      setPrevActiveLanguage(activeLanguage);
    }
  }, [activeLanguage, history, addTranslationForLanguage, prevActiveLanguage]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState([]);
  const [dates, setDates] = useState(null);
  const [employeeId, setEmployeeId] = useState(0);
  const [modal, setModal] = useState({
    show: false,
    content: null,
    size: null,
  });
  const [totalRH, setTotalRH] = useState(0);
  const [totalOT, setTotalOT] = useState(0);

  // Reset state when component mounts
  useEffect(() => {
    dispatch(resetEntireStateHistory());
  }, [dispatch]);

  // Reset state when component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetEntireStateHistory());
      setData([]);
    };
  }, [dispatch]);

  const employees = useSelector((state) => {
    return state?.payrollPersonHistoryReducer?.employeesInList;
  });

  const onSubmit = async (value) => {
    setMessage(null);
    const startDateFormatted = moment(dates).format("yyyy-MM-DD");
    const request = {
      dateList: startDateFormatted,
    };

    try {
      setLoading(true);
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_FIND_PAYROLL_BY_PERSON,
        payload: {
          orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
            ?.orgId,
          personId: employeeId,
          ...request,
        },
      });

      if (res.status === 200 && res.code === "TE00") {
        setData(addHistory(res.result));
      }else{
        setMessage(res);
      }
    } catch (error) {
      setMessage(new Error(error));
    } finally {
      setLoading(false);
    }
  };

  async function selectRow(event) {
    const personId = parseInt(event[0]);
    setEmployeeId(personId);
    dispatch(
      addEmployeeHistory({
        id: generateUniqueId(),
        personId: event[1],
        firstName: event[5],
        lastName: event[4],
        gender: event[7],
        phone: event[8],
        hiredate: event[9],
      })
    );
  }

  const renderDateAndToggleFields = () => {
    return (
      <>
        <div className="form-group col-lg-3 col-12">
          <p className="text-left label-p">
            {<Translate id="com.tempedge.msg.label.date" />}
          </p>
          <DatePicker
            onChange={(date) => setDates(date)}
            selected={dates}
            value={dates}
            className="form-control tempEdge-input-box"
            showMonthDropdown
            showYearDropdown
            maxDate={null}
            placeholderText={"mm/dd/yyyy"}
          />
        </div>
      </>
    );
  };

  const btn = (
    <div className="col-md-6 mx-auto">
      <button
        type="button"
        className="btn btn-default btn-block register-save-btn previous"
        onClick={() => {
          dispatch(resetEntireStateHistory());
          setData([]);
        }}
      >
        {<Translate id="com.tempedge.msg.label.cancel" />}
      </button>
    </div>
  );

  useEffect(() => {
    let totalRegHours = 0;
    let totalOTHours = 0;
    data.forEach((item) => {
      item.payrollWeekDTOS.forEach((week) => {
        totalRegHours += week.totalRegHours;
        totalOTHours += week.totalOTHours;
      });
    });

    setTotalOT(totalOTHours);
    setTotalRH(totalRegHours);
  }, [data]);

  if (loading) return <Loader />;
  return (
    <ContainerBlue
      title={"com.tempedge.msg.menu.payrollhistory"}
      btns={data?.length > 0 && btn}
    >
      {message && <OutcomeBar response={message} />}
      {data?.length <= 0 && (
        <div className="search row flex mt-2 align-items-end">
          <div className="form-group col-12 col-lg-3">
            <button
              type="button"
              onClick={() => {
                setModal({
                  show: true,
                  content: (
                    <EmployeeList
                      multipleRows={false}
                      isSelected={(personId) =>
                        employees?.find(
                          (employee) => employee.personId === personId
                        )
                      }
                      onClickRows={(event) => {
                        selectRow(event);
                        setModal({ show: false, content: null, size: null });
                      }}
                    />
                  ),
                  size: "modal-lg",
                });
              }}
              className="btn btn-green w-100"
            >
              {<Translate id="com.tempedge.msg.label.selectemployees" />}
            </button>
          </div>

          {renderDateAndToggleFields()}
          <div className="form-group col-12 col-lg-3 ml-auto">
            <button
              type="button"
              onClick={() => onSubmit(dates)}
              className="btn btn-blue w-100 "
              style={{ marginTop: "2.8rem" }}
              disabled={employees.length === 0 || dates === null}
            >
              {<Translate id="com.tempedge.msg.label.find" />}
            </button>
          </div>
        </div>
      )}
      {employees && employees.length > 0 && (
        <div>
          {data?.length <= 0 && (
            <React.Fragment key={data.length}>
              <p className="green-text font-weight-bold text-uppercase mt-3">
                {<Translate id="com.tempedge.msg.label.employee" />}
              </p>
              <EmployeeTable employees={employees} />
            </React.Fragment>
          )}

          {data?.length > 0 && (
            <>
              <div className="row ">
                <div className="col-8">
                  <p className="green-text font-weight-bold text-uppercase mt-5">
                    {`${employees[0]?.firstName} ${employees[0]?.lastName}`}
                  </p>
                </div>
                <div className="ml-auto px-4">
                <p className="text-uppercase mt-5 px-4">
                <span className="font-weight-bold">
                  <Translate id="com.tempedge.msg.label.totalreghours" />
                  : 
                </span>{" "}
                {formatUsNumber(totalRH)}
                </p>
                  <p className="text-uppercase px-4">
                  <span className="font-weight-bold">
                    <Translate id="com.tempedge.msg.label.totalothours" />
                    : 
                  </span>{" "}
                  {formatUsNumber(totalOT)}
                  </p>
                </div>
              </div>

              {data?.map((item, index) => {
                return (
                  <React.Fragment key={index} >
                    <div className="mt-5 time-entry__body" >
                      <div className="row ">
                        <div className="col-12">
                          <div className="row">
                            <div className="form-group col-12 col-lg-3">
                              <p className=" font-weight-bold text-uppercase">
                                {item?.client}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <PayrollHistoryTablet
                      payrollWeekDtos={item.payrollWeekDTOS}
                      dateStart={dates}
                      setTotalRH={setTotalRH}
                      setTotalOT={setTotalOT}
                      totalRH={totalRH}
                      totalOT={totalOT}
                    />
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
      )}
      <Modal
        open={modal.show}
        content={modal.content}
        onClose={() => setModal({ show: false, content: null, size: null })}
        modalSize={modal.size ?? "modal-sm"}
      />
    </ContainerBlue>
  );
};

export default withLocalize(PayrollByPerson);
