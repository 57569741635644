import React, {useEffect, useState, useCallback } from 'react';
import '../../../assets/styles/components/Switch.css';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';

const Switch = ({ name,checked=false, onChange,children,size = 'md', align = 'start', isInvalid = false,disabled=false}) => {

  const [value, setValue] = useState(checked);

  useEffect(() => {
     setValue(checked);
  },[checked]);

  const handleChange = useCallback(
    (e) => {
      setValue(e?.target?.checked);
      onChange(e?.target?.checked);
    },[onChange]
  );

  return (
    <div className={`d-flex align-items-center justify-content-${align}`}>
        {children && <label className="mr-5" style={{marginBottom:0}}>{children}</label> }
        <div className={`switch switch-${size} ${isInvalid ? 'switch-error' : ''}`}>
          <input name={name} onChange={handleChange} checked={value} disabled={disabled} type="checkbox" className="toggle-switch__checkbox"/>
          <div className={`toggle-switch__knobs `}>
            {value
              ? <p><Translate id='com.tempedge.msg.label.yes'/></p>
              :<p><Translate id='com.tempedge.msg.label.no'/></p>}
          </div>
          <div className="toggle-switch__layer" />
        </div>
    </div>
  );

};

Switch.propTypes = {
    name : PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    onChange: PropTypes.func.isRequired,
    size : PropTypes.oneOf(['sm','md']),
    align: PropTypes.oneOf(['start','end','center','between','around']),
    isInvalid: PropTypes.bool,
}

export default withLocalize(Switch);

