/********* THIS IS THE ROOT REDUCER *********/
import { connectRouter } from 'connected-react-router';
import { localizeReducer } from 'react-localize-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { adjustmentReducer } from "./adjustmentReducer";
import { customPayrollReducer } from "./customPayrollReducer";
import { dailyListReducer } from './dailyListReducer';
import { payrollPersonHistoryReducer } from './payrollPersonHistoryReducer';
import { payrollValidationReducer } from './payrollValidationReducer';
import tempEdgeReducer from './tempEdgeReducer';
import { timeEntryReducer } from './timeEntryReducer';
import attendanceReducer from './attendanceReducer';


const reducer = (history) => combineReducers({
	router: connectRouter(history),
	tempEdge: tempEdgeReducer,
	dailyListReducer,
	adjustmentReducer,
	timeEntryReducer,
	payrollValidationReducer,
	payrollPersonHistory: payrollPersonHistoryReducer,
	payrollPersonHistoryReducer,
	customPayrollReducer,
	localize: localizeReducer,
	form: formReducer,
	attendance: attendanceReducer
});

export default reducer;
/********************************************/
