import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../assets/styles/components/Datepicker.css';
import moment from 'moment';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import ErrorRender from '../ErrorRender/ErrorRender';
import { DATE_FORMAT } from '../Constants/Constants';

class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date()
    };
  }

  componentDidUpdate(prevProps) {
    const { activeLanguage, push, addTranslationForLanguage } = this.props;
    const hasActiveLanguageChanged = prevProps.activeLanguage !== activeLanguage;
    if (hasActiveLanguageChanged) {
      if (activeLanguage.code === 'es') {
        registerLocale(activeLanguage.code, es);
      }
      push(`/payroll/dailylist/${activeLanguage.code}`);
      ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
    }
  }

  handleChange = (date) => {
    if (this.props.onChange) {
      this.setState({
        date: moment(date).isValid() ? date : null
      });
      this.props.onChange(date);
      this.setState({
        date: moment(date).isValid() ? date : null
      });
    } else {
      this.setState({
        date: moment(date).isValid() ? date : null
      });
    }
  };

  render() {
    const { input, meta, activeLanguage, maxDate, customClass, shouldCloseOnSelect, open, showPopperArrow, placeholder,disabled,initDate } = this.props;

    let { selectedValue } = this.props;

    if (!selectedValue && selectedValue !== null) {
      selectedValue = moment(this.state.date).isValid() ? this.state.date : null;
    }

    // Si es redux forms
    if (input) {
      const errorClass = `${meta?.error && meta?.touched ? 'has-error' : ''}`;
      return (
        <div className={errorClass}>
          <DatePicker
            onChange={(date) => input.onChange(moment(date).toDate())}
            selected={moment(input.value).isValid() ? new Date(input.value) : null}
            onBlur={input.onBlur}
            className={customClass}
            showMonthDropdown
            showYearDropdown
            dateFormat={DATE_FORMAT}
            locale={activeLanguage.code}
            maxDate={maxDate || null}
            placeholderText={placeholder || DATE_FORMAT}
            disabled={disabled}
          />
          <ErrorRender {...this.props} />
        </div>
      );
    }
    return (
      <DatePicker
        onChange={(date) => this.handleChange(date)}
        selected={moment(selectedValue).isValid() ? new Date(selectedValue) : null}
        className={customClass}
        showMonthDropdown
        showYearDropdown
        maxDate={maxDate || null}
        locale={activeLanguage?.code}
        shouldCloseOnSelect={!shouldCloseOnSelect}
        open={open}
        minDate={initDate || null}
        showPopperArrow={showPopperArrow}
        placeholderText={placeholder || DATE_FORMAT}
        disabled={disabled}
      />
    );
  }
}

const mapDispatchToProps = {
  push
};

export default withLocalize(connect(null, mapDispatchToProps)(Datepicker));
