import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from '../Redux/reducers/index';
import storageSession from 'redux-persist/lib/storage/session';
import { persistStore, persistReducer } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { routerMiddleware } from 'connected-react-router';
import history from '../history';

let middleware = [thunk, routerMiddleware(history)];

const clearClientListOnLogout = (state) => {
  if (state && state.user && !state.user.isLoggedIn) {
    return {};
  }
  return state;
};

let saveSubsetBlacklistFilter = createBlacklistFilter(
  'form',
  ['tempEdge'],
  ['CreateNewAgency', 'CreateNewUser', 'CreateNewClient', 'NewEmployee']
);

let config = {
  key: 'root',
  storage: storageSession,
  transforms: [saveSubsetBlacklistFilter],
  stateReconciler: (inboundState, originalState) => {
    if (inboundState) return { ...originalState, ...clearClientListOnLogout(inboundState) };
    return originalState;
  },
};

const persistedReducer = persistReducer(config, createRootReducer(history));

let store = createStore(persistedReducer, compose(applyMiddleware(...middleware)));

let persistor = persistStore(store);

export { store, persistor, history };
