import React from "react";
import { Translate } from "react-localize-redux";

const EmployeeTable = ({ employees }) => {
  return (
    <div>
      <div className="row mt-1">
        <div className="col-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="table-header-left text-center w-5">EE #</th>
                <th className="table-header-mid text-center w-15">
                  <Translate id="com.tempedge.msg.label.lastname" />
                </th>
                <th className="table-header-mid text-center w-15">
                  <Translate id="com.tempedge.msg.label.firstname" />
                </th>
                <th className="table-header-mid text-center w-15">
                  <Translate id="com.tempedge.msg.label.gender" />
                </th>
                <th className="table-header-mid text-center w-15">
                  <Translate id="com.tempedge.msg.label.phone" />
                </th>
                <th className="table-header-mid text-center w-15">
                  <Translate id="com.tempedge.msg.label.hiredate" />
                </th>               
                
              </tr>
            </thead>
            <tbody>
              {employees &&
                employees.map((employee, index) => {
                  return (
                    <tr key={index+1+employee.personId } className={"time-sheet-row"}>
                      <td className="" style={{ textAlign: "center" }}>
                        {employee.personId}
                      </td>
                      <td className="" style={{ textAlign: "center" }}>
                        {employee.lastName}
                      </td>
                      <td className="" style={{ textAlign: "center" }}>
                        {employee.firstName}
                      </td>
                      <td className="" style={{ textAlign: "center" }}>
                        {employee.gender}
                      </td>
                      <td className="" style={{ textAlign: "center" }}>
                        {employee.phone}
                      </td>
                      <td className="" style={{ textAlign: "center" }}>
                        {employee.hiredate}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTable;
