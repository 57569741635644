import * as Yup from "yup";

const validateEmail = (message) => {
  return Yup.string().email("Must be a valid email").max(255).required(message);
};

export const Schema = Yup.object().shape({
  account: Yup.object().shape({
    firstName: Yup.string().required("required"),
    lastName: Yup.string().required("required"),
    gender: Yup.number().required("required"),
    email: validateEmail("Email is required"),
    birthDay: Yup.date().required(),
    username: Yup.string().required("required"),
    password: Yup.string().required("Password is required"),
    passwordConfirmation: Yup.string()
      .required("Password Confirmation is Required")
      .oneOf([Yup.ref("password"), null], "Password must match"),
  }),
  user: Yup.object().when(["edit", "status"], {
    is: (edit, status) => !edit || status === "R",
    then: Yup.object().shape({
      role: Yup.object().required("required"),
      orgName: Yup.string().required("required"),
      agencyclient: Yup.string().when("role.name", {
        is: (name) => name.includes("CLIENT"),
        then: Yup.string().required("Client is required"),
      }),
      agencyoffice: Yup.string().when("role.name", {
        is: (name) => !name.includes("CLIENT"),
        then: Yup.string().required("Office is required"),
      }),
    }),
  }),
  portalUserList: Yup.array().when(["edit", "status"], {
    is: (edit, status) => edit && status === "A",
    then: Yup.array().of(
      Yup.object().shape({
        portalUserConfId: Yup.number().required("portalUserId is required"),
        orgName: Yup.string().required("Organization is required"),
        clientId: Yup.number()
          .nullable()
          .when("role.name", {
            is: (name) => name.includes("CLIENT"),
            then: Yup.number().required("Client is required"),
          }),
        officeId: Yup.number()
          .nullable()
          .when("role.name", {
            is: (name) => !name.includes("CLIENT"),
            then: Yup.number().required("Office is required"),
          }),
      })
    ),
  }),
  status: Yup.string().required("status is required"),
});

export const initialValues = {
  edit: false,
  account: {
    username: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: 2,
    email: "",
    birthDay: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
    password: "",
    passwordConfirmation: "",
  },
  user: {
    role: { name: "CLIENT", roleId: 4 },
    orgName: "",
    agencyclient: "",
    agencyoffice: "",
    ssnlastfour: "",
    status: "N",
  },
  token: "",
  portalUserList: [],
  status: "N",
  personId: "",
};
