import { push } from "connected-react-router";
import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import ActiveLanguageAddTranslation from '../ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import TBody from "./TBody.js";

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pageSize: [20, 50, 100],
      timeoutId: null,
    };
    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
    if (hasActiveLanguageChanged) {
      this.props.push(`/employee/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  onChange = (col, field) => {
    const { timeoutId } = this.state;
    clearTimeout(timeoutId);
  
    if (field.trim() === "") {
      // If the input is empty, don't set a new timeout
      this.props.applyFilter(col, field);
    } else {
      const newTimeoutId = setTimeout(() => {
        this.props.applyFilter(col, field);
      }, 1000);
  
      this.setState({
        timeoutId: newTimeoutId,
      });
    }
  };
  

  render() {
    const { onClickRows, selectRows, multipleRows, data,isSelected,singleSelect,filterByFIndInitial } = this.props;
    const tableClassname = onClickRows ? 'table-striped table-hover' : 'table-striped';
    let columns = data.columns;
    let content = data ? data.data : '';
    const columnsToMap = data ? data.columnsToMap : '';
    const keyField = data?.keyField;

    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-9 col-lg-10"></div>
        </div>
        <table className={`table ${tableClassname}`}>
          <thead>
            <tr>
              {columns ? (
                columns.map((col, index) => {
                  return (
                    <th
                      key={index + 1}
                      className={
                        index === 0
                          ? "table-header-left text-center "
                          : index === columns.length - 1
                          ? "table-header-right text-center"
                          : `table-header-mid text-center ${
                              col.hide !== "undefined" && col.hide != null
                                ? `d-none d-${col.hide}-table-cell`
                                : ""
                            }`
                      }
                      style={{ minWidth: col.minFieldSize }}
                    >
                      {<Translate id={col.label} />}{" "}
                      {col.selFilterType === "filter" && (
                        <>
                          <br />
                          <input
                            ref={col.field}
                            onChange={(e) =>{                                         
                              this.onChange(
                                col.field,
                                e.target.value                                
                              )}
                            }
                            style={{ maxWidth: col.filterSize }}
                          />
                        </>
                      )}
                      {col.selFilterType === "number" && (
                        <input
                          ref={col.field}
                          onChange={() =>
                            this.onChange(col.field, this.refs[col.field].value)
                          }
                        />
                      )}
                    </th>
                  );
                })
              ) : (
                <th>
                  <Translate id="com.tempedge.msg.label.nocolumns" />
                </th>
              )}
            </tr>
          </thead>
          {content ? (
            <TBody
              data={
                content.content
                  ? content?.content
                  : content?.content?.data
                  ? content?.content?.data
                  : content
              }
              columns={columns}
              onClickRows={onClickRows}
              multipleRows={multipleRows}
              columnsToMap={columnsToMap}
              keyField={keyField}
              selectRows={selectRows}
              isSelected={isSelected}
              singleSelect={singleSelect}
              filterByFIndInitial={filterByFIndInitial}
            />
          ) : (
            <tbody>
              <tr>
                <td>
                  <Translate id="com.tempedge.msg.label.norecordsfound" />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    );
  }
}

export default withLocalize(connect(null, { push })(Table));
