import React, {useCallback, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {withLocalize, Translate} from "react-localize-redux";
import { Formik } from 'formik';
import UseTranslateCode from '../../Hooks/UseTranslateCode';
import Loader from "../../components/common/Loader/Loader";
import OutcomeBar from "../../components/common/OutcomeBar";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {doLogin} from "../../Redux/actions/tempEdgeActions";
import httpService from "../../utils/services/httpService/httpService";
import { GoogleReCaptchaProvider }from 'react-google-recaptcha-v3';
import { REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_URL_LOGIN, REACT_APP_RECAPTCHA_V2_SITE_KEY }from "../../components/common/Routes/URLs";
import useRecaptcha from '../../Hooks/Recaptcha/useRecaptcha';
import useRecaptchaTimer from '../../Hooks/Recaptcha/useRecaptchaTimer';
import RecaptchaV2 from '../../components/common/Captcha/RecaptchaV2';

const validate = (values) => {
    const errors = {};
    if(!values.username) errors.username = 'The field user is required.';
    if(!values.password) errors.password = 'The field password is required.';
    return errors;
}

function Login({translate, location, activeLanguage }) {

 const [loading, setLoading] = useState(false);
 const [message, setMessage] = useState(null);
 const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);
 const history = useHistory();
 const dispatch = useDispatch();
 const recaptchaTokenV3 = useRecaptcha('login');
 const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
 const [recaptchaV2Verified, setRecaptchaV2Verified] = useState(false);

 UseTranslateCode({location, activeLanguage});

 const state = useSelector(state => state.tempEdge, shallowEqual);
 
 // Uso del hook para manejar el temporizador del reCAPTCHA v2
 const showRecaptchaV2FromTimer = useRecaptchaTimer(() => {
    setShowRecaptchaV2(true);
    setIsSubmitDisabled(true);  // Deshabilitar el botón de submit
});

 useEffect(() => {
    if(state?.message !== null && state?.message?.message?.includes('Network Error')){
        setMessage("com.tempedge.msg.error.networkerror")
    }
    
    if(state.loginError){
        setLoading(false);
        setMessage('com.tempedge.msg.error.badcredentials');
    }
    if(state.loginResponse && state.loginResponse.status >= 400){
        setLoading(false);
        setMessage('com.tempedge.msg.error.badcredentials');
    }
    
 }, [state.loginError, state.loginResponse, state.message]);

 useEffect(() => {
    const fetch = async function() {
        setMessage("")
         try{
             setLoading(true);
             const token = localStorage.getItem('access_token');
             if (token && state.login && Object.keys(state.login).length !== 0) {
                 const response = await httpService.tokenValidation('/oauth/check_token', token);
                 if(response && response.error !== 'invalid_token'){
                     history.push(`/organization-select/en`);
                 }else{
                     setLoading(false);
                 }
             }else{
                setLoading(false);
             }
         }catch(e){
            setLoading(false);
            setMessage('com.tempedge.error.undefined');
         }
    }
    fetch();
 },[state.login, history, state.lang]);

 const handleSubmit = useCallback(async (values) => {
    setMessage("")
     try{
        setLoading(true);
        // Envia el token V3, no importa si la V2 está presente
        values.token = recaptchaTokenV3;
        await dispatch(doLogin(REACT_APP_URL_LOGIN, { ...values, grant_type: 'password' }));
    } catch (e) {
        setLoading(false);
        setMessage('com.tempedge.error.undefined');
    }
}, [dispatch, recaptchaTokenV3]);

const handleRecaptchaVerify = (token) => {
    setRecaptchaV2Verified(true);  // Marcar reCAPTCHA v2 como verificado
    setIsSubmitDisabled(false);    // Habilitar el botón de submit
};

 useEffect(() => {
    if (showRecaptchaV2 && REACT_APP_RECAPTCHA_V2_SITE_KEY) {
        document.getElementById('recaptcha-v2').innerHTML = '';
        window.grecaptcha.render('recaptcha-v2', {
            sitekey: REACT_APP_RECAPTCHA_V2_SITE_KEY,
            callback: handleRecaptchaVerify,
        });
    }
 }, [showRecaptchaV2]);

 if (loading) return <Loader />

  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
          <ContainerBlue title={"com.tempedge.msg.label.sign_in"} width={'57.6rem'}>
              <Formik
                  initialValues={
                    {username: '', password: '', token: ''}
                  }
                  enableReinitialize
                  validate={validate}
                  onSubmit={(values, { setSubmitting }) => {
                      handleSubmit(values);
                      setSubmitting(false);
                  }}
                  autoComplete='nope'
              >
                  {formik => (
                      <>
                          <form className="panel-body" onSubmit={formik.handleSubmit} >
                              {message && <OutcomeBar personMessage={message} /> }
                              <div className="form-group mx-4 mt-5">
                                  <p className="text-left label-p">
                                      <Translate id="com.tempedge.msg.label.username"/>
                                  </p>
                                  <input
                                      name="username"
                                      className={`form-control tempEdge-input-box
                                ${formik.errors.username && formik.touched.username ? 'has-error' : ''}`}
                                      placeholder={translate('com.tempedge.msg.label.username')}
                                      type="text"
                                      onChange={formik.handleChange}                                      
                                  />
                              </div>
                              <div className="form-group mx-4">
                                  <p className="text-left label-p">
                                      <Translate id="com.tempedge.msg.label.password"/>
                                  </p>
                                  <input
                                      name="password"
                                      className={`form-control tempEdge-input-box
                                ${formik.errors.password && formik.touched.password ? 'has-error' : ''}`}
                                      placeholder={translate('com.tempedge.msg.label.password')}
                                      type={'password'}
                                      onChange={formik.handleChange}
                                      autoComplete='new-passwordò'
                                  />
                              </div>
                              <div className="clearfix mx-4 mt-4">
                                  <label className="pull-left checkbox-inline label-p">
                                        <input type={'checkbox'} />
                                        <span style={{ marginLeft: 4 }}>
                                            <Translate id="com.tempedge.msg.label.remember_me"/>
                                        </span>
                                  </label>
                                  
                                  {
                                    state?.message == null &&
                                    <Link to={`/resetpassword/${activeLanguage.code}`} className="pull-right forgot-password">
                                      <Translate id="com.tempedge.msg.label.password_retrieve"/> 
                                    </Link>
                                  }
                                  
                              </div>
                              {showRecaptchaV2FromTimer && REACT_APP_RECAPTCHA_V2_SITE_KEY && (
                                  <div className="form-group mx-4 mt-3 d-flex justify-content-center">
                                      <div
                                          id="recaptcha-v2-container"
                                          style={{
                                              border: recaptchaV2Verified ? 'none' : '1px solid red', // Muestra borde rojo si no está verificado
                                              borderRadius: '4px'
                                          }}
                                      >
                                          <RecaptchaV2 onVerify={handleRecaptchaVerify} />
                                      </div>
                                  </div>
                              )}
                              <div className="form-group mx-4 mt-3">
                                  <button
                                      id="submit-button"
                                      type="submit"
                                      className="btn btn-primary btn-block"
                                      disabled={isSubmitDisabled}  // Botón deshabilitado si es necesario
                                  >
                                      <Translate id="com.tempedge.msg.label.sign_in"/>
                                  </button>
                              </div>
                          </form>
                      </>
                  )}
              </Formik>
          </ContainerBlue>
    </GoogleReCaptchaProvider>
  );
}

export default withLocalize(Login);
