import moment from 'moment';
import { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Modal from '../../../Modals/Modal/Modal';
import Datepicker from '../../../components/common/Datepicker/Datepicker';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import Tooltip from '../../../components/common/Tooltip/index';
import Switch from '../../../components/common/Switch/Switch';
import { normalizeSSN, onlyAlphanumeric, onlyLetters } from '../../../utils/ReGexs';
import DepartmentList from '../../Department/DepartmentList/DepartmentList';

function Information({ formatSSN, ...props }) {
   const [showModal, setShowModal] = useState(false);
   const agency = JSON.parse(localStorage.getItem('agency'));

   return (
      <>
         <div className={'tab'}>
            <div className="form-group row">
               <div className="col-3 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.tempdata" />
                  </label>
                  <Switch
                     name="temporarydata"
                     checked={props.formik.values.information.temporalInfo}
                     isInvalid={props.formik.values.edit && props.formik.values.information.temporalInfo}
                     onChange={(value) => {
                        props.loadTemporalInfo(value);
                        props.formik.setFieldValue('information.temporalInfo', value);
                     }}
                  />
               </div>
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.office" />
                  </label>
                  <DropdownList
                     data={props.offices}
                     valueField={'officeId'}
                     value={props.formik.values.information.office || ''}
                     textField={'name'}
                     isInvalid={
                        props.formik.errors.information &&
                        props.formik.touched.information &&
                        props.formik.errors.information.office &&
                        props.formik.touched.information.office
                     }
                     onChange={(office) => {
                        props.formik.setFieldValue('information.office', office);
                     }}
                  />
               </div>
               <div className="form-group col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.orgdepartment" />
                  </label>
                  <div className="input-group">
                     <input
                        className={`square-right-side form-control tempEdge-input-box 
                                   ${
                                      props.formik.errors.information &&
                                      props.formik.touched.information &&
                                      props.formik.errors.information.department &&
                                      props.formik.touched.information.department
                                         ? 'has-error'
                                         : ''
                                   }`}
                        name={'department'}
                        value={props.formik.values.information.departmentName || ''}
                        placeholder={props.translate('com.tempedge.msg.label.departmentlist')}
                        disabled
                     />
                     <div className="input-group-append input-button-append">
                        <button
                           className="btn btn-green"
                           title={props.translate('com.tempedge.msg.label.viewdepartmentlist')}
                           type="button"
                           onClick={() => {
                              setShowModal(true);
                           }}
                        >
                           +
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            <div className="form-group row">
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.ssn" />
                     <span style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
                        <Tooltip translate={props.translate('com.tempedge.msg.tooltip.ssn')} iconWidth="15px" iconHeight="15px" />
                     </span>
                  </label>
                  <input
                     className={`form-control tempEdge-input-box 
                                    ${
                                       props.formik.errors.information &&
                                       props.formik.touched.information &&
                                       props.formik.errors.information.ssn &&
                                       props.formik.touched.information.ssn
                                          ? 'has-error'
                                          : ''
                                    }`}
                     type={'text'}
                     name={'ssn'}
                     placeholder={props.translate('com.tempedge.msg.label.ssn')}
                     value={props.formik.values.information.ssn || ''}
                     maxLength={50}
                     onChange={(event) => {
                        const ssn = event.target.value;
                        const formattedSSN1 = normalizeSSN(ssn, formatSSN);
                        props.formik.setFieldValue('information.ssn', formattedSSN1);
                     }}
                  />
               </div>
               {((!agency?.organizationEntity?.autoEmployeeId && !props.formik.values.edit) ||
                  (agency?.organizationEntity?.autoEmployeeId && props.formik.values.edit) ||
                  (!agency?.organizationEntity?.autoEmployeeId && props.formik.values.edit)) && (
                  <div className="col-10 col-md-5 col-lg-4">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.employeeid" />
                     </label>
                     {(agency?.organizationEntity?.autoEmployeeId || props.formik.values.edit) === true ? (
                        <label className="control-label">{props.formik.values.information.employeeId}</label>
                     ) : (
                        <input
                           className={`form-control tempEdge-input-box 
                               ${
                                  props.formik.errors.information &&
                                  props.formik.touched.information &&
                                  props.formik.errors.information.employeeId &&
                                  props.formik.touched.information.employeeId
                                     ? 'has-error'
                                     : ''
                               }`}
                           name={'information.employeeId'}
                           placeholder={props.translate('com.tempedge.msg.label.employeeid')}
                           value={props.formik.values.information.employeeId || ''}
                           onChange={(event) => {
                              event.target.value = event.target.value.slice(0, 8);
                              props.formik.setFieldValue('information.employeeId', onlyAlphanumeric(event.target.value));
                           }}
                           maxLength={8}
                        />
                     )}
                  </div>
               )}
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.hiredate" />
                  </label>

                  <Datepicker
                     customClass={`form-control tempEdge-input-box
                                     ${
                                        props.formik.errors.information &&
                                        props.formik.touched.information &&
                                        props.formik.errors.information.hireDate &&
                                        props.formik.touched.information.hireDate
                                           ? 'has-error'
                                           : ''
                                     }`}
                     name="hireDate"
                     selectedValue={new Date(props.formik.values.information.hireDate)}
                     onChange={(date) => {
                        props.formik.setFieldValue('information.hireDate', date);
                     }}
                  />
               </div>
            </div>
            <div className="form-group row">
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.firstname" />
                  </label>
                  <input
                     className={`form-control tempEdge-input-box 
                               ${
                                  props.formik.errors.information &&
                                  props.formik.touched.information &&
                                  props.formik.errors.information.firstName &&
                                  props.formik.touched.information.firstName
                                     ? 'has-error'
                                     : ''
                               }`}
                     type={'text'}
                     name={'firstName'}
                     placeholder={props.translate('com.tempedge.msg.label.firstname')}
                     value={props.formik.values.information.firstName || ''}
                     onChange={(event) => {
                        event.target.value = onlyLetters(event.target.value).toUpperCase();
                        props.formik.setFieldValue('information.firstName', event.target.value);
                     }}
                     maxLength="20"
                  />
               </div>
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.middlename" />
                  </label>
                  <input
                     className="form-control tempEdge-input-box"
                     type={'text'}
                     name={'middleName'}
                     placeholder={props.translate('com.tempedge.msg.label.middlename')}
                     value={props.formik.values.information.middleName || ''}
                     onChange={(event) => {
                        event.target.value = onlyLetters(event.target.value).toUpperCase();
                        props.formik.setFieldValue('information.middleName', event.target.value);
                     }}
                     maxLength="30"
                  />
               </div>
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.lastname" />
                  </label>
                  <input
                     className={`form-control tempEdge-input-box 
                               ${
                                  props.formik.errors.information &&
                                  props.formik.touched.information &&
                                  props.formik.errors.information.lastName &&
                                  props.formik.touched.information.lastName
                                     ? 'has-error'
                                     : ''
                               }`}
                     type={'text'}
                     name={'lastName'}
                     placeholder={props.translate('com.tempedge.msg.label.lastname')}
                     value={props.formik.values.information.lastName || ''}
                     onChange={(event) => {
                        event.target.value = onlyLetters(event.target.value).toUpperCase();
                        props.formik.setFieldValue('information.lastName', event.target.value);
                     }}
                     maxLength={'30'}
                  />
               </div>
            </div>
            <div className="form-group row">
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.birthday" />
                  </label>
                  <Datepicker
                     customClass={`form-control tempEdge-input-box
                                     ${
                                        props.formik.errors.information &&
                                        props.formik.touched.information &&
                                        props.formik.errors.information.birthDay &&
                                        props.formik.touched.information.birthDay
                                           ? 'has-error'
                                           : ''
                                     }`}
                     selectedValue={new Date(props.formik.values.information.birthDay)}
                     onChange={(date) => {
                        props.formik.setFieldValue('information.birthDay', new Date(date));
                     }}
                  />
               </div>
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">{props.translate('com.tempedge.msg.label.age')}</label>
                  <div className="flex ml-2 row ">
                     <label className="control-label">{moment().diff(props.formik.values.information.birthDay, 'years', false)}</label>
                     {moment().diff(props.formik.values.information.birthDay, 'years', false) < 18 && (
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           style={{
                              width: '2.6rem',
                              marginTop: '3px',
                              color: '#dc2626',
                              marginLeft: '2rem',
                           }}
                           fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor"
                        >
                           <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                           />
                        </svg>
                     )}
                  </div>
               </div>
               <div className="col-10 col-md-5 col-lg-4">
                  <label className="control-label">
                     <Translate id="com.tempedge.msg.label.gender" />
                  </label>
                  <DropdownList
                     data={[
                        {
                           value: 0,
                           label: props.translate('com.tempedge.msg.label.gender.male'),
                        },
                        {
                           value: 1,
                           label: props.translate('com.tempedge.msg.label.gender.female'),
                        },
                        { value: 2, label: props.translate('com.tempedge.msg.label.select') },
                     ]}
                     valueField="value"
                     textField="label"
                     value={{ value: props.formik.values.information.gender }}
                     isInvalid={
                        props.formik.errors.information &&
                        props.formik.touched.information &&
                        props.formik.errors.information.gender &&
                        props.formik.touched.information.gender
                     }
                     onChange={(gender) => {
                        props.formik.setFieldValue('information.gender', gender.value);
                     }}
                     name={'gender'}
                  />
               </div>
            </div>
         </div>
         <Modal
            content={
               <DepartmentList
                  onClickRows={(selectDepartment) => {
                     props.formik.setFieldValue('information', {
                        ...props.formik.values.information,
                        departmentName: selectDepartment[2],
                        department: selectDepartment[1],
                     });
                     setShowModal(false);
                  }}
               />
            }
            open={showModal}
            onClose={() => setShowModal(false)}
            modalSize="modal-sm"
         />
      </>
   );
}

export default withLocalize(Information);
