import React from "react";
import { Translate } from "react-localize-redux";
import * as Yup from "yup";

const schema = Yup.object().shape({
  fileConfiguration: Yup.object().shape({
    colNamePos: Yup.string().trim().required(),
    colRatePos: Yup.string().trim().required(), 
    colEmpidPos: Yup.string().trim().required(), 
    colDeptPos: Yup.string().trim().required(), 
    colSectionPos: Yup.string().trim().required(), 
    colHoursPos: Yup.string().trim().required(), 
    colOthoursPos: Yup.string().trim().required(), 
    colTimeinPos: Yup.string().trim().required(), 
    colTimeoutPos: Yup.string().trim().required(),
  }) 
});

function FingerTech({ formik }) {

  function onChange(event) {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z]/g, "");
    value = value.substr(0,3);
    event.target.value = value.toUpperCase();
    formik.handleChange(event);
  }

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
              <Translate id="com.tempedge.reports.columns.colnamepos" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colNamePos ? 'has-error': ''}`}
            name="fileConfiguration.colNamePos"
            autoComplete="off"
            onChange={onChange}
            value={formik.values.fileConfiguration.colNamePos || ""}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.reports.columns.colratepos" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colRatePos ? 'has-error': ''}`}
            name="fileConfiguration.colRatePos"
            autoComplete="off"
            onChange={onChange}
            value={formik.values.fileConfiguration.colRatePos || ""}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.reports.columns.colempidpos" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colEmpidPos ? 'has-error': ''}`}
            name="fileConfiguration.colEmpidPos"
            autoComplete="off"
            onChange={onChange}
            value={formik.values.fileConfiguration.colEmpidPos || ""}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.reports.columns.coldeptpos" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colDeptPos ? 'has-error': ''}`}
            name="fileConfiguration.colDeptPos"
            autoComplete="off"
            onChange={onChange}
            value={formik.values.fileConfiguration.colDeptPos || ""}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.msg.label.position" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colSectionPos ? 'has-error': ''}`}
            name="fileConfiguration.colSectionPos"
            autoComplete="off"
            onChange={onChange}
            value={formik.values.fileConfiguration.colSectionPos || ""}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.reports.columns.colhourspos" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colHoursPos ? 'has-error': ''}`}
            autoComplete="off"
            name="fileConfiguration.colHoursPos"
            onChange={onChange}
            value={formik.values.fileConfiguration.colHoursPos || ""}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.reports.columns.colothourspos" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colOthoursPos ? 'has-error': ''}`}
            name="fileConfiguration.colOthoursPos"
            autoComplete="off"
            onChange={onChange}
            value={formik.values.fileConfiguration.colOthoursPos || ""}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.reports.columns.coltimeinpos" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colTimeinPos ? 'has-error': ''}`}
            onChange={onChange}
            autoComplete="off"
            value={formik.values.fileConfiguration.colTimeinPos || ""}
            name="fileConfiguration.colTimeinPos"
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.reports.columns.coltimeoutpos" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colTimeoutPos ? 'has-error': ''}`}
            onChange={onChange}
            autoComplete="off"
            value={formik.values.fileConfiguration.colTimeoutPos || ""}
            name="fileConfiguration.colTimeoutPos"
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group">
          <label className="text-left label-p">
            <Translate id="com.tempedge.msg.label.shift" />
          </label>
          <input
            type="text"
            className={`form-control tempEdge-input-box ${formik.errors.colShiftPos ? 'has-error': ''}`}
            onChange={onChange}
            autoComplete="off"
            value={formik.values.fileConfiguration.colShiftPos || ""}
            name="fileConfiguration.colShiftPos"
          />
        </div>
      </div>
    </div>
  );
}

const FingerClock = {
  component: FingerTech,
  schema
};

export default FingerClock;

