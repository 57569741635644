import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import Report from "./ReportUpload";
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';


class UploadReport extends Component {

	constructor(props) {
		super(props);
		//language
		this.addTranslationsForActiveLanguage();
	}

	addTranslationsForActiveLanguage = async () => {
		await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
	};

	async componentDidUpdate(prevProps) {
		let hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
		if (hasActiveLanguageChanged) {
			await this.props.push(`/report/upload/${this.props.activeLanguage.code}`);
			this.addTranslationsForActiveLanguage();
		}
	}

	render() {
		return <Report />;
	}

}

export default withLocalize(
	connect(null, { push })(UploadReport)
);
