import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

const AbsentPayrollForDayResult = ({ translate, absentEmployees }) => {
  return (
    <>
    <div className="row">
      <div className="form-check col-12 col-lg-12">
      <p>      
     <Translate id={'com.tempedge.msg.label.peoplewithouthours'}/>
          <span className="badge">{absentEmployees.length}</span>
        </p>
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="table-header-left text-center w-15">
                {translate('com.tempedge.msg.label.lastname')}
              </th>
              <th className="table-header-mid text-center w-15">
                {translate('com.tempedge.msg.label.firstname')}
              </th>
              <th className="table-header-mid text-center w-20">
                {translate('com.tempedge.msg.label.orgdepartment')}
              </th>
              <th className="table-header-mid text-center w-10">
                {translate('com.tempedge.msg.label.position')}
              </th>
            </tr>
          </thead>
          <tbody>
            {absentEmployees.map((employee,index) => (
              <tr key={index+1}>
                <td className="text-center">{employee?.lastName}</td>
                <td className="text-center">{employee?.firstName}</td>
                <td className="text-center">{employee?.position?.departmentName}</td>
                <td className="text-center">{employee?.position?.positionName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>      
    </div>    
    </>
  );
};

const mapStateToProps = ({ timeEntryReducer }) => ({
  absentEmployees: timeEntryReducer.absentEmployees
});



export default withLocalize(connect(mapStateToProps)(AbsentPayrollForDayResult));
