import { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Datepicker from '../../../components/common/Datepicker/Datepicker';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import Switch from '../../../components/common/Switch/Switch';
import { onlyNumbers } from '../../../utils/ReGexs';

function Misc(props) {
   const { maritalStatusFind, countries, formik, setFindMaritalStatus } = props;
   const [regionList, setRegionList] = useState([]);
   const [allowanceJuniorValue, setAllowanceJuniorValue] = useState(null);
   const [allowanceSeniorValue, setAllowanceSeniorValue] = useState(null);
   const [allowanceJunior, setAllowanceJunior] = useState(null);
   const [allowanceSenior, setAllowanceSenior] = useState(null);
   const [allowanceJuniorResult, setAllowanceJuniorResult] = useState(null);
   const [allowanceSeniorResult, setAllowanceSeniorResult] = useState(null);

   const tax = useSelector(
      (state) => ({
         allowanceJunior: state.tempEdge?.login?.parameterEntityList?.taxAllowanceJunior || 0,
         allowanceSenior: state.tempEdge?.login?.parameterEntityList?.taxAllowanceSenior || 0,
      }),
      (prevTax, nextTax) => prevTax.allowanceJunior === nextTax.allowanceJunior && prevTax.allowanceSenior === nextTax.allowanceSenior,
   );

   useEffect(() => {
      setAllowanceJuniorValue(
         props.formik.values.misc.juniorAllowances === null || undefined ? null : props.formik.values.misc?.juniorAllowances,
      );
   }, [props.formik.values.misc.juniorAllowances]);

   useEffect(() => {
      setAllowanceSeniorValue(
         props.formik.values.misc?.seniorAllowances === null || undefined ? null : props.formik.values.misc?.seniorAllowances,
      );
   }, [props.formik.values.misc.seniorAllowances]);

   useEffect(() => {
      const junioreResult = formik.values.misc?.juniorAllowances / tax.allowanceJunior;
      const senioreResult = formik.values.misc?.seniorAllowances / tax.allowanceSenior;
      setAllowanceJuniorResult(junioreResult !== 0 ? junioreResult : junioreResult === null ? null : 0);
      setAllowanceSeniorResult(senioreResult !== 0 ? senioreResult : senioreResult === null ? null : 0);
      setAllowanceJunior(junioreResult % 1 === 0);
      setAllowanceSenior(senioreResult % 1 === 0);
      return () => {
         setAllowanceJuniorValue(null);
         setAllowanceSeniorValue(null);
         setAllowanceJunior(null);
         setAllowanceSenior(null);
         setAllowanceJuniorResult(null);
         setAllowanceSeniorResult(null);
      };
   }, [formik.values.misc.juniorAllowances, formik.values.misc.seniorAllowances, tax]);

   useEffect(() => {
      setFindMaritalStatus(maritalStatusFind.filter((item) => item.value === formik.values?.misc?.maritalStatus?.toString()));
   }, [formik.values.misc.maritalStatus, setFindMaritalStatus, maritalStatusFind]);

   useEffect(() => {
      const country = countries.find((country) => country.countryId === formik.values.contact.country);

      if (country) {
         setRegionList(country.regionEntityList);
      }
   }, [formik.values.contact.country, countries]);

   return (
      <div className="tab">
         <div className="row">
            <div className="col-md-4" style={{ borderRight: '1px solid #d7d7d7' }}>
               {/* maritalStatus */}
               <div className="mr-5 mb-5 ">
                  <div className="tempedge-input-misc">
                     <label className="control-label" style={{ marginBottom: 5 }}>
                        <Translate id="com.tempedge.msg.label.maritalstatus" />
                     </label>
                     <DropdownList
                        name={'materialStatus'}
                        data={maritalStatusFind}
                        textField={'label'}
                        valueField={'value'}
                        value={formik.values?.misc?.maritalStatus ? { value: formik.values?.misc?.maritalStatus?.toString() } : null}
                        isInvalid={
                           props.formik.errors.misc &&
                           props.formik.touched.misc &&
                           props.formik.errors.misc.maritalStatus &&
                           props.formik.touched.misc.maritalStatus
                        }
                        onChange={(status) => {
                           if (status.status === 'A') {
                              props.formik.setFieldValue('misc.maritalStatus', status.value);
                           } else {
                              props.formik.setFieldValue('misc.maritalStatus', null);
                           }
                        }}
                     />
                  </div>
               </div>

               <div className="col-10 mb-5" />
               {/* multiplejobs */}
               <div className="mr-5 mb-5 ">
                  <div className="tempedge-input-misc">
                     <label className="control-label mb-2">
                        <Translate id="com.tempedge.msg.label.multiplejobs" />
                     </label>
                     <DropdownList
                        name={'drugTest'}
                        data={[
                           {
                              value: 0,
                              label: props.translate('com.tempedge.msg.label.select'),
                           },
                           {
                              value: 2,
                              label: props.translate('com.tempedge.msg.label.multiplejobsyes'),
                           },
                           {
                              value: 1,
                              label: props.translate('com.tempedge.msg.label.multiplejobsno'),
                           },
                        ]}
                        valueField="value"
                        textField="label"
                        value={{ value: props.formik.values.misc.multipleJobs }}
                        onChange={(option) => {
                           props.formik.setFieldValue('misc.multipleJobs', option.value);
                        }}
                        isInvalid={
                           props.formik.errors.misc &&
                           props.formik.touched.misc &&
                           props.formik.errors.misc.multipleJobs &&
                           props.formik.touched.misc.multipleJobs
                        }
                     />
                  </div>
               </div>
               {/* allowances number */}
               <div className="mx-auto mr-5">
                  <label className="control-label mb-1">
                     <Translate id="com.tempedge.msg.label.numberofallowances" />
                  </label>
               </div>
               {/* allowances data */}
               <div className="mx-auto mr-5">
                  <div className="allowancesData">
                     <div className="row">
                        <div className="col-12">
                           <label className="control-label mb-3">
                              <Translate id="com.tempedge.msg.label.allowancejr" />
                           </label>
                        </div>
                        <div className="col-9">
                           <input
                              className={`form-control tempEdge-input-box
                    ${!allowanceJunior ? 'has-error' : ''}`}
                              name={'misc.juniorAllowances'}
                              type={'text'}
                              pattern={'[0-9]*'}
                              placeholder="$"
                              maxLength={5}
                              value={allowanceJuniorValue}
                              onBlur={(event) => {
                                 if (event.target.value > 0) {
                                    event.target.value = onlyNumbers(event.target.value);
                                    props.formik.handleBlur(props.formik?.values?.misc?.juniorAllowances);
                                 }
                              }}
                              onChange={(event) => {
                                 event.target.value = onlyNumbers(event.target.value);
                                 formik.handleChange(event);
                                 props.formik.setFieldValue('misc.juniorAllowancesResult', event.target.value / tax.allowanceJunior);
                                 setAllowanceJuniorResult(event.target.value / tax.allowanceJunior);
                              }}
                           />
                        </div>
                        <div className="col-3">
                           <label
                              className={
                                 allowanceJuniorResult % 1 === 0 ? 'control-label text-center' : 'has-error-text control-label text-center'
                              }
                           >
                              {allowanceJuniorResult}
                           </label>
                        </div>
                        <div className="col-12">
                           <label className="control-label" style={{ marginBottom: 5 }}>
                              <Translate id="com.tempedge.msg.label.allowancesr" />
                           </label>
                        </div>
                        <div className="col-9">
                           <input
                              className={`form-control tempEdge-input-box  ${!allowanceSenior ? 'has-error' : ''}`}
                              name={'misc.seniorAllowances'}
                              type={'text'}
                              pattern={'[0-9]*'}
                              placeholder="$"
                              maxLength={5}
                              value={allowanceSeniorValue}
                              onBlur={(event) => {
                                 if (event.target.value > 0) {
                                    event.target.value = onlyNumbers(event.target.value);
                                    props.formik.handleBlur(props.formik?.values?.misc?.seniorAllowances);
                                 }
                              }}
                              onChange={(event) => {
                                 event.target.value = onlyNumbers(event.target.value);
                                 props.formik.handleChange(event);
                                 props.formik.setFieldValue('misc.seniorAllowancesResult', event.target.value / tax.allowanceSenior);
                                 setAllowanceSeniorResult(event.target.value / tax.allowanceSenior);
                              }}
                           />
                        </div>
                        <div className="col-3">
                           <label
                              className={
                                 allowanceSeniorResult % 1 === 0 ? 'control-label text-center' : 'has-error-text control-label text-center'
                              }
                              style={{ marginBottom: 5 }}
                           >
                              {allowanceSeniorResult}
                           </label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <hr style={{ margin: '40px 0 25px 0' }} />
            <div className="col-md-8">
               <div className="row">
                  {/* joblocation */}
                  <div className="col-md-6">
                     <div className="tempedge-input-misc mx-auto mb-3">
                        <label className="control-label mb-2">
                           <Translate id="com.tempedge.msg.label.joblocation" />
                        </label>
                        <DropdownList
                           data={regionList}
                           name={'jobLocation'}
                           textField={'name'}
                           valueField={'regionId'}
                           value={props.formik.values.misc.jobLocation ? { regionId: props.formik.values.misc.jobLocation } : null}
                           isInvalid={
                              props.formik.errors.misc &&
                              props.formik.touched.misc &&
                              props.formik.errors.misc.jobLocation &&
                              props.formik.touched.misc.jobLocation
                           }
                           onChange={({ regionId }) => {
                              props.formik.setFieldValue('misc.jobLocation', regionId);
                           }}
                        />
                     </div>
                  </div>
                  {/* idExpiration */}
                  <div className="col-md-6">
                     <div className="tempedge-input-misc mx-auto mb-3">
                        <label className="control-label" style={{ marginBottom: 5 }}>
                           <Translate id="com.tempedge.msg.label.idexpiration" />
                        </label>
                        <Datepicker
                           customClass={`form-control tempEdge-input-box
                                     ${
                                        props.formik.errors.misc &&
                                        props.formik.touched.misc &&
                                        props.formik.errors.misc.idExpiration &&
                                        props.formik.touched.misc.idExpiration
                                           ? 'has-error'
                                           : ''
                                     }`}
                           selectedValue={props.formik.values.misc.idExpiration}
                           onChange={(date) => {
                              props.formik.setFieldValue('misc.idExpiration', date);
                           }}
                        />
                     </div>
                  </div>
                  <hr className="col-10 border-bottom" />
                  {/* drugtest */}
                  <div className="col-md-6">
                     <div
                        style={{
                           width: '80%',
                           margin: 'auto',
                           marginBottom: 10,
                        }}
                     >
                        <label className="control-label" style={{ marginBottom: 5 }}>
                           <Translate id="com.tempedge.msg.label.drugtest" />
                        </label>
                        <DropdownList
                           name={'drugTest'}
                           data={[
                              {
                                 value: 1,
                                 label: props.translate('com.tempedge.msg.label.yes'),
                              },
                              {
                                 value: 0,
                                 label: props.translate('com.tempedge.msg.label.no'),
                              },
                           ]}
                           valueField="value"
                           textField="label"
                           value={{ value: props.formik.values.misc.drugTest }}
                           onChange={(option) => {
                              props.formik.setFieldValue('misc.drugTest', option.value);
                           }}
                        />
                        {props.formik.values.misc.drugTest === 1 && (
                           <div className={'mt-3'}>
                              <label className="control-label" style={{ marginBottom: 5 }}>
                                 <Translate id="com.tempedge.msg.label.drugtestdate" />
                              </label>
                              <Datepicker
                                 customClass={'form-control tempEdge-input-box'}
                                 name={'drugTestDate'}
                                 selectedValue={props.formik.values.misc.drugTestDate}
                                 onChange={(date) => {
                                    props.formik.setFieldValue('misc.drugTestDate', date);
                                 }}
                              />
                           </div>
                        )}
                     </div>
                  </div>
                  {/* backgroundtest */}
                  <div className="col-md-6">
                     <div className="tempedge-input-misc mx-auto">
                        <label className="control-label" style={{ marginBottom: 5 }}>
                           <Translate id="com.tempedge.msg.label.backgroundtest" />
                        </label>
                        <DropdownList
                           name={'backgroundTest'}
                           data={[
                              {
                                 value: 1,
                                 label: props.translate('com.tempedge.msg.label.yes'),
                              },
                              {
                                 value: 0,
                                 label: props.translate('com.tempedge.msg.label.no'),
                              },
                           ]}
                           valueField="value"
                           textField="label"
                           value={{ value: props.formik.values.misc.backgroundTest }}
                           onChange={(option) => {
                              props.formik.setFieldValue('misc.backgroundTest', option.value);
                           }}
                        />
                        {props.formik.values.misc.backgroundTest === 1 && (
                           <div className={'mt-3'}>
                              <label className="control-label" style={{ marginBottom: 5 }}>
                                 <Translate id="com.tempedge.msg.label.backgroundtestdate" />
                              </label>
                              <Datepicker
                                 customClass={'form-control tempEdge-input-box'}
                                 name={'backgroundTestDate'}
                                 selectedValue={props.formik.values.misc.backgroundTestDate}
                                 onChange={(date) => {
                                    props.formik.setFieldValue('misc.backgroundTestDate', date);
                                 }}
                              />
                           </div>
                        )}
                     </div>
                  </div>
                  <hr className="col-10 border-bottom" />
                  {/* employeeCreateUser */}
                  <div className="col-md-6 ml-auto">
                     <div className="tempedge-input-misc mx-auto d-flex justify-content-between">
                        <label className="control-label" style={{ marginBottom: 5 }}>
                           <Translate id="com.tempedge.msg.label.employeecreateuser" />
                        </label>
                        <Switch
                           align="end"
                           name="createUser"
                           checked={props.formik.values.misc.createUser}
                           onChange={(value) => {
                              props.formik.setFieldValue('misc.createUser', value);
                           }}
                           disabled={props.formik.values.edit && props.formik.values.misc?.createUserEdit}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default withLocalize(Misc);
