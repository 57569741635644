import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeHoursPayrollValidation,
  changeHoursPayrollValidationLocked,
} from "../../../../Redux/actions/payrollValidationActions";
import { roundToTwoDecimals } from "../../../../utils/helpers/TotalHoursFormat";

const CellPayrollValidation = ({ payroll, day }) => {
  const [totalOtHour, setTotalOtHour] = useState("");
  const [totalRegHour, setTotalRegHour] = useState("");
  const [payrollDisable, setPayrollDisable] = useState(true);
  const [lockPayroll, SetLockPayroll] = useState(false);

  useEffect(() => {
    const roundedTotalRegHour = roundToTwoDecimals(payroll[day]?.totalRegHour);
    const roundedTotalOtHour = roundToTwoDecimals(payroll[day]?.totalOtHour);
    setTotalOtHour(roundedTotalOtHour);
    setTotalRegHour(roundedTotalRegHour);
    Object.entries(payroll).forEach(([key, value]) => {
      if (key !== null) {
        if (value?.lockPayroll) {
          SetLockPayroll(true);
        }
      }
    });
  }, [payroll, day]);

  const dispatch = useDispatch();
  const changeTimeEntry = (values, key) => {
    setPayrollDisable(false);
    let data = {
      payrollId: payroll[day].payrollId,
      id: payroll.id,
      key: key,
      value: values ? values : 0,
      day: day,
    };
    const regularExpression = /^-?\d{0,3}(\.\d{0,2})?$/;
    if (regularExpression.test(values)) {
      if (lockPayroll) {
        dispatch(changeHoursPayrollValidationLocked(data));
      } else {
        dispatch(changeHoursPayrollValidation(data));
      }
    }
  };

  const changeDisable = () => {   
    if (payroll[day]?.lockPayroll) {
      setPayrollDisable(true);
    } else {
      setPayrollDisable(false);
    }
  };
 
  if (
    payroll[day] !== null &&
    (payroll[day].totalRegHour !== 0 || payroll[day].totalOtHour !== 0)
  ) {
    return lockPayroll && payroll[day]?.lockPayroll ? (
      <td className="text-center" style={{ fontSize: "12px" }}>
        {`RG ${payroll[day].totalRegHour.toFixed(2)} - ${payroll[
          day
        ].totalOtHour.toFixed(2)} OT`}
      </td>
    ) : (
      <td
        className="editableCell"
        style={{ textAlign: "center" }}        
      >
        <div className="input-group input-group-sm ">
          <div className="input-group-prepend " style={{ width: "auto" }}>
            <span className="input-group-text " id="inputGroup-sizing-sm">
              RG
            </span>
          </div >
          <input
            onClick={() => changeDisable()}
            type="text"
            step="0.01"
            className="form-control"
            placeholder="0.00"            
            aria-label="totalRegHour"
            aria-describedby="basic-addon1"
            value={totalRegHour}
            onBlur={() => changeTimeEntry(totalRegHour, "totalRegHour")}
            onChange={(event) => {
              const inputValue = event.target.value;
              // Regular expression to match the desired format
              const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
              if (regex.test(inputValue) || inputValue === "") {
                setTotalRegHour(inputValue);
              }
            }}
            readOnly={payrollDisable}
          />
          <span> </span>
          <input     
          onClick={() => changeDisable()}       
            type="number"
            step="0.01"
            className="form-control"
            placeholder="0.00"            
            aria-label="totalOtHour"
            aria-describedby="basic-addon2"
            value={totalOtHour}
            onBlur={() => changeTimeEntry(totalOtHour, "totalOtHour")}
            onChange={(event) =>{
              const inputValue = event.target.value;
              // Regular expression to match the desired format
              const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
              if (regex.test(inputValue) || inputValue === "") {
                setTotalOtHour(inputValue);
              }
            }}
            readOnly={payrollDisable}
          />
          <div className="input-group-append " id="cellWeek">
            <span
              className="input-group-text "
              id="cellWeek"
              onClick={() => changeDisable()}
            >
              OT
            </span>
          </div>
        </div>
      </td>
    );
  } else {
    return <td></td>;
  }
};

export default CellPayrollValidation;
