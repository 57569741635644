import React from 'react';
import { Translate } from "react-localize-redux";
import SetTableHeadingAbsent from "./SetTableHeadingAbsent";
import useHeaderDate from "./useHeaderDate";

const AbsentPayrollForWeek = ({ employeesTimeEntry, dateStart }) => {

  const {findDate} = useHeaderDate(dateStart);

  const absent = [];
  employeesTimeEntry.map((payroll) => {    
    for (const [key, value] of Object.entries(payroll)) {
      if (key !== null) {
        if (value !== null) {
          if (value.totalRegHour === 0 && value.totalOtHour === 0) {
            absent.push(payroll)
          }
        }
      }
    }
    return payroll
  })


  if (absent.length > 0) {
    let newAbsent = absent.filter((value, index) => {
      return absent.indexOf(value) === index
    })
    
    return (
      <>
        <div className="form-group col-12 col-lg-6">
          <p>
           <Translate id={'com.tempedge.msg.label.peoplewithouthours'}/>
            <span className="badge">{newAbsent.length}</span>
          </p>
        </div>
        <div className="row">
          <div className="form-check col-12 col-lg-12">
            <table className="table table-striped table-input-group">
              <thead>{SetTableHeadingAbsent(dateStart)}</thead>
              <tbody>
                <>
                  {newAbsent.map((e,index) => {
                    return (
                      <tr key={index+1}>
                        <td>{`${e?.person?.fullName}`}</td>
                        <td>{e?.department !== null ? `${e?.department?.name} ${e?.position?.name}` : e?.position?.name}</td>
                        {Object.keys(findDate).map((currentDay,index) => {
                             return e[findDate[currentDay].day]?.totalRegHour === 0 && e[findDate[currentDay].day]?.totalOtHour === 0 ? 
                             (
                               <td key={index+1} className="text-center"><Translate id={'com.tempedge.msg.label.absent'} /></td>
                             ) : (
                               <td key={index+1}></td>
                             )
                        })}
                      </tr>
                    )
                  })}
                </>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  } else {
    return (
      null
    )
  }

}

export default AbsentPayrollForWeek




