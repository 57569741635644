import { useState, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptcha = (action) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  useEffect(() => {
    const verifyRecaptcha = async () => {
      if (!executeRecaptcha) {
        return;
      }
      try {
        const token = await executeRecaptcha(action);
        setRecaptchaToken(token);
      } catch (error) {
      }
    };

    verifyRecaptcha();
  }, [executeRecaptcha, action]);

  return recaptchaToken;
};

export default useRecaptcha;