import moment from 'moment';
import React, { Component } from 'react';
import Timekeeper from 'react-timekeeper';
import clockIcon from '../../../assets/static/clock.svg';
import '../../../assets/styles/components/Timepicker.css';
import Backdrop from './Backdrop';

class TimepickerReduxForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timepicker: null
    };
  }

  setTime = (newTime) => {
    const {input: { onChange }} = this.props;
    newTime = newTime.toUpperCase();
    onChange(moment(newTime, 'LT').format('LT'));
  };

  closeTimepicker = () => {
    this.setState({
      timepicker: null
    });
  };

  onDoneClick = (newTime) => {
   const {input: { onChange }} = this.props;
    onChange(moment(newTime.formatted12, 'LT').format('LT'));
    this.closeTimepicker();
  };

  openTimepicker = () => {
    const {
      input:{ value, onChange } } = this.props;

    this.setState({
      timepicker: (
        <Backdrop
          open={true}
          content={
            <Timekeeper
              onChange={(newTime) => {
                onChange(moment(newTime.formatted12, 'LT').format('LT'))
              }}
              time={moment(value).isValid() ? value : null}
              switchToMinuteOnHourSelect
              onDoneClick={this.onDoneClick}
            />
          }
          onClose={this.closeTimepicker}
        />
      )
    });
  };

  onInputBlur = () => {
    const {
      input:{ value, onChange } } = this.props;
    const parsedDate = moment(value, 'LT').format('LT');
    if (moment(parsedDate, 'LT').isValid()) {
      onChange(parsedDate);
    } else {
      onChange('');
    }
  };

  handleChange = (event) => {
   const {input: { onChange }} = this.props;
    onChange(event.target.value);
  };

  render() {
    const {
      input:{ value } }= this.props;

    return (
      <div className="input-group mb-3">
        <input type="text" className="form-control tempEdge-input-box square-right-side" value={value} onBlur={this.onInputBlur} onChange={this.handleChange} />
        <div className="input-group-append">
          <button type="button" className="btn btn-green" onClick={this.openTimepicker}>
            <img src={clockIcon} alt="Clock Icon" />
          </button>
          {this.state.timepicker}
        </div>
      </div>
    );
  }
}

export default TimepickerReduxForms;
