import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { REACT_APP_URL_COUNTRY_LIST_ALL } from "../components/common/Routes/URLs";
import TempEdgeApi from "../services/TempEdgeApi";
import { getListCountryAll } from "../Redux/actions/tempEdgeActions";

const useFetchCountryList = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const { data: countries } = await TempEdgeApi.get({
          url: REACT_APP_URL_COUNTRY_LIST_ALL,
        });
        if (countries.status === 200 && isMounted) {
          dispatch(getListCountryAll(countries.result));
          setData(countries.result);
        }
      } catch (error) {
        setData([]);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  return data;
};

export default useFetchCountryList;
