import { Translate, withLocalize } from 'react-localize-redux';

const BlueCommonBtn = (props) => {
  return (
    <div>
      <button type={props.type || "submit"} className="btn btn-primary btn-block register-save-btn"
        disabled={props.invalid || props.pristine}
        onClick={props.onClick}>
        <Translate id={props.tag} />
      </button>
    </div>
  );
}

export default withLocalize(BlueCommonBtn);