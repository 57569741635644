import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ThreeStateSwitch from "../../../components/common/ThreeStateSwitch/ThreeStateSwitch";
import { updateAttendanceList } from "../../../Redux/actions/attendanceActions";

const EditableCell = ({ payroll, day, translate }) => {
  const [attendanceValue, setAttendanceValue] = useState(payroll[day]?.attendance);
  useEffect(() => {
    setAttendanceValue(payroll[day]?.attendance);
  }, [payroll, day]);

  const dispatch = useDispatch();
  const updateAttendanceDay = (values, key) => {
    const data = {
      payrollId: payroll[day].payrollId,
      id: payroll.id,
      key,
      value: values,
      day,
    };

    dispatch(updateAttendanceList(data));
  };

  if (
    payroll[day] && payroll[day] !== null && (payroll[day]?.lockPayroll === null || !payroll[day]?.lockPayroll)
  ) {
    return (
      <td className="editableCell">
        <ThreeStateSwitch
          name={'Switch'}
          size={'md'}
          currentValue={attendanceValue}
          key={payroll.person?.personId}
          onClick={(value) => {
            setAttendanceValue(value);
            updateAttendanceDay(value, "attendance")
          }}
        />
      </td>
    );
  } else {
    return (
      <td className="editableCell">
        {
          attendanceValue === null || attendanceValue === undefined ? '' : attendanceValue
            ? translate('com.tempedge.msg.label.yes')
            : translate('com.tempedge.msg.label.no')
        }
      </td>
    );
  }
};

export default EditableCell;
