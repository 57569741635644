import { Field, useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TranslatedLabel from "../../../components/common/TranslatedLabel/TranslatedLabel";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import { Translate } from "react-localize-redux";
import Datepicker from "../../../components/common/Datepicker/Datepicker";
import { onlyNumbers } from "../../../utils/ReGexs";

const MiscCandidate = ({ maritalStatusFind }) => {
  const { values, errors, touched, setFieldValue, handleBlur, handleChange } =
    useFormikContext();

  const [allowanceJuniorValue, setAllowanceJuniorValue] = useState(
    values.misc.juniorAllowances || ""
  );
  const [allowanceSeniorValue, setAllowanceSeniorValue] = useState(
    values.misc.seniorAllowances || ""
  );
  const [allowanceJunior, setAllowanceJunior] = useState(null);
  const [allowanceSenior, setAllowanceSenior] = useState(null);
  const [allowanceJuniorResult, setAllowanceJuniorResult] = useState(null);
  const [allowanceSeniorResult, setAllowanceSeniorResult] = useState(null);

  const tax = useMemo(
    () => ({
      allowanceJunior: 2000,
      allowanceSenior: 500,
    }),
    []
  );

  useEffect(() => {
    setAllowanceJuniorValue(values.misc.juniorAllowances || "");
  }, [values.misc.juniorAllowances]);

  useEffect(() => {
    setAllowanceSeniorValue(values.misc.seniorAllowances || "");
  }, [values.misc.seniorAllowances]);

  useEffect(() => {
    const junioreResult = values.misc.juniorAllowances / tax.allowanceJunior;
    const senioreResult = values.misc.seniorAllowances / tax.allowanceSenior;
    setAllowanceJuniorResult(junioreResult !== 0 ? junioreResult : 0);
    setAllowanceSeniorResult(senioreResult !== 0 ? senioreResult : 0);
    setAllowanceJunior(junioreResult % 1 === 0);
    setAllowanceSenior(senioreResult % 1 === 0);
  }, [values.misc.juniorAllowances, values.misc.seniorAllowances, tax]);

  return (
    <>
        <Row>
          <Col  sm={12} md={4} lg={4} className="nav-misc form-group">
            <div className="tempedge-input-misc mr-5 mb-5">
              <TranslatedLabel id="com.tempedge.msg.label.maritalstatus" />
              <Field
                id="misc.maritalStatus"
                name={"misc.maritalStatus"}
                data={maritalStatusFind}
                type="text"
                textField="label"
                valueField="value"
                value={{ value: values.misc.maritalStatus || "" }}
                className={`tempEdge-input-box form-control ${
                  errors.misc?.maritalStatus && touched.misc?.maritalStatus
                    ? "has-error"
                    : ""
                }`}
                component={DropdownList}
                onChange={(status) => {
                  setFieldValue(
                    "misc.maritalStatus",
                    status.status === "A" ? status.value : ""
                  );
                }}
                isInvalid={
                  errors.misc?.maritalStatus && touched.misc?.maritalStatus
                }
              />
            </div>

            <div className="tempedge-input-misc mr-5 mb-5">
              <TranslatedLabel id="com.tempedge.msg.label.multiplejobs" />
              <Field
                id="misc.multipleJobs"
                name="misc.multipleJobs"
                data={[
                  {
                    value: 0,
                    label: <Translate id="com.tempedge.msg.label.select" />,
                  },
                  {
                    value: 2,
                    label: (
                      <Translate id="com.tempedge.msg.label.multiplejobsyes" />
                    ),
                  },
                  {
                    value: 1,
                    label: (
                      <Translate id="com.tempedge.msg.label.multiplejobsno" />
                    ),
                  },
                ]}
                value={{ value: values.misc.multipleJobs }}
                valueField="value"
                textField="label"
                className={`tempEdge-input-box form-control ${
                  errors.misc?.multipleJobs && touched.misc?.multipleJobs
                    ? "has-error"
                    : ""
                }`}
                component={DropdownList}
                onChange={(option) => {
                  setFieldValue("misc.multipleJobs", option.value);
                }}
                isInvalid={
                  errors.misc?.multipleJobs && touched.misc
                }
              />
            </div>

            {/* allowances number */}
            <div className="mx-auto mr-5">
              <label className="control-label mb-1">
                <Translate id="com.tempedge.msg.label.numberofallowances" />
              </label>
            </div>
            <div className="allowancesData ">
              <Row>
                <Col sm={9} md={9} lg={9} className="mt-2">
                  <TranslatedLabel id="com.tempedge.msg.label.allowancejr" />
                  <input
                    className={`form-control tempEdge-input-box ${
                      !allowanceJunior ? "has-error" : ""
                    }`}
                    name={"misc.juniorAllowances"}
                    type={"text"}
                    pattern={"[0-9]*"}
                    placeholder="$"
                    maxLength={5}
                    value={allowanceJuniorValue || ""}
                    onBlur={(event) => {
                      const value = onlyNumbers(event.target.value);
                      handleBlur(event);
                      setFieldValue("misc.juniorAllowances", value);
                    }}
                    onChange={(event) => {
                      const value = onlyNumbers(event.target.value);
                      handleChange(event);
                      setAllowanceJuniorResult(value / tax.allowanceJunior);
                    }}
                  />
                </Col>
                <Col sm={3} md={3} lg={3} className="mt-5">
                  <label
                    className={
                      allowanceJuniorResult % 1 === 0
                        ? "control-label text-center"
                        : "has-error-text control-label text-center"
                    }
                  >
                    {allowanceJuniorResult}
                  </label>
                </Col>

                <Col sm={9} md={9} lg={9} className="mt-2">
                  <TranslatedLabel id="com.tempedge.msg.label.allowancesr" />
                  <input
                    className={`form-control tempEdge-input-box ${
                      !allowanceSenior ? "has-error" : ""
                    }`}
                    name={"misc.seniorAllowances"}
                    type={"text"}
                    pattern={"[0-9]*"}
                    placeholder="$"
                    maxLength={5}
                    value={allowanceSeniorValue || ""}
                    onBlur={(event) => {
                      const value = onlyNumbers(event.target.value);
                      handleBlur(event);
                      setFieldValue("misc.seniorAllowances", value);
                    }}
                    onChange={(event) => {
                      const value = onlyNumbers(event.target.value);
                      handleChange(event);
                      setAllowanceSeniorResult(value / tax.allowanceSenior);
                    }}
                  />
                </Col>
                <Col sm={3} md={3} lg={3} className="mt-5">
                  <label
                    className={
                      allowanceSeniorResult % 1 === 0
                        ? "control-label text-center"
                        : "has-error-text control-label text-center"
                    }
                    style={{ marginBottom: 5 }}
                  >
                    {allowanceSeniorResult}
                  </label>
                </Col>
              </Row>
            </div>
          </Col>

          <hr style={{ margin: "40px 0 25px 0" }} className="line-none" />
          <Col  sm={12} md={8} lg={8} className="form-group">
            <Row>
              <Col  sm={12} md={6} lg={6} className="form-group">
                <div className="tempedge-input-misc">
                  <TranslatedLabel id="com.tempedge.msg.label.idexpiration" />
                  <Datepicker
                    customClass={`form-control tempEdge-input-box ${
                      errors.misc?.idExpiration && touched.misc?.idExpiration
                        ? "has-error"
                        : ""
                    }`}
                    selectedValue={values.misc?.idExpiration || ""}
                    onChange={(date) => {
                      setFieldValue("misc.idExpiration", date);
                    }}
                  />
                </div>
              </Col>

              <hr className="col-11 border-bottom line-none " />

              <Col  sm={12} md={6} lg={6} className="form-group mt-2">
                <div className="tempedge-input-misc">
                  <TranslatedLabel id="com.tempedge.msg.label.drugtest" />
                  <Field
                    id="misc.drugtest"
                    name="misc.drugtest"
                    data={[
                      {
                        value: 1,
                        label: <Translate id="com.tempedge.msg.label.yes" />,
                      },
                      {
                        value: 0,
                        label: <Translate id="com.tempedge.msg.label.no" />,
                      },
                    ]}
                    textField={"label"}
                    valueField={"value"}
                    className={`tempEdge-input-box form-control ${
                      errors.misc?.drugtest && touched.misc?.drugtest
                        ? "has-error"
                        : ""
                    }`}
                    component={DropdownList}
                    isInvalid={errors.misc?.drugtest && touched.misc?.drugtest}
                    onChange={(option) => {
                      setFieldValue("misc.drugTest", option.value);
                    }}
                  />
                  {values.misc?.drugTest === 1 && (
                    <div className={"mt-3"}>
                      <label
                        className="control-label"
                        style={{ marginBottom: 5 }}
                      >
                        <Translate id="com.tempedge.msg.label.drugtestdate" />
                      </label>
                      <Datepicker
                        customClass={"form-control tempEdge-input-box"}
                        name={"drugTestDate"}
                        selectedValue={values.misc?.drugTestDate || ""}
                        onChange={(date) => {
                          setFieldValue("misc.drugTestDate", date);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col  sm={12} md={6} lg={6} className="form-group mt-2">
                <div className="tempedge-input-misc mx-auto">
                  <TranslatedLabel id="com.tempedge.msg.label.backgroundtest" />
                  <Field
                    id="misc.backgroundTest"
                    name="misc.backgroundTest"
                    data={[
                      {
                        value: 1,
                        label: <Translate id="com.tempedge.msg.label.yes" />,
                      },
                      {
                        value: 0,
                        label: <Translate id="com.tempedge.msg.label.no" />,
                      },
                    ]}
                    textField={"label"}
                    valueField={"value"}
                    className={`tempEdge-input-box form-control ${
                      errors.misc?.backgroundTest &&
                      touched.misc?.backgroundTest
                        ? "has-error"
                        : ""
                    }`}
                    component={DropdownList}
                    isInvalid={
                      errors.misc?.backgroundTest &&
                      touched.misc?.backgroundTest
                    }
                    onChange={(option) => {
                      setFieldValue("misc.backgroundTest", option.value);
                    }}
                  />
                  {values.misc?.backgroundTest === 1 && (
                    <div className={"mt-3"}>
                      <label
                        className="control-label"
                        style={{ marginBottom: 5 }}
                      >
                        <Translate id="com.tempedge.msg.label.backgroundtestdate" />
                      </label>
                      <Datepicker
                        customClass={"form-control tempEdge-input-box"}
                        name={"backgroundTestDate"}
                        selectedValue={values.misc?.backgroundTestDate || ""}
                        onChange={(date) => {
                          setFieldValue("misc.backgroundTestDate", date);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
    </>
  );
};

export default MiscCandidate;
