import { adjustmentTypes } from "../types/adjustmentTypes";

const initialState = {
  employeesInAdjustment: [],
  deleteAdjustmentEntitis: [],
};

export const adjustmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case adjustmentTypes.ADD_EMPLOYEE_IN_ADJUSTMENT:
      return {
        ...state,
        employeesInAdjustment: [...state.employeesInAdjustment, action.payload],
      };
    case adjustmentTypes.REMOVE_EMPLOYEE_IN_ADJUSTMENT:
      return {
        ...state,
        employeesInAdjustment: state.employeesInAdjustment.filter(
          (employee) => employee.id !== action.payload.personId
        ),
      };
    case adjustmentTypes.CHANGE_EMPLOYEE_VALUE: {
      const { id, key, value } = action.payload;
      let employees = [...state.employeesInAdjustment];
      for (let employee of employees) {
        if (employee.id === id) {
          employee[key] = value;
          if (key === "position") {
            employee.pay = value.pay;
          }
          break;
        }
      }
      return {
        ...state,
        employeesInAdjustment: employees,
      };
    }
    case adjustmentTypes.DELETE_ADJUSTMENT: {
      const deletedAdjustments = [];
      const newEmployeesWithAdjustments = state.employeesInAdjustment.map(
        (employee) => {
          if (
            employee.adjustmentEntities &&
            employee.adjustmentEntities.length > 0
          ) {
            const updatedAdjustmentEntities = employee.adjustmentEntities.filter(
              (adjustment) => {
                if (adjustment.adjustmentId === action.payload) {
                  deletedAdjustments.push(adjustment); // Guardar el ajuste eliminado
                  return false; // No incluir este ajuste en los entities actualizados
                }
                return true; // Incluir los demás ajustes
              }
            );
            return {
              ...employee,
              adjustmentEntities: updatedAdjustmentEntities,
            };
          }
          return employee;
        }
      );

      return {
        ...state,
        employeesInAdjustment: newEmployeesWithAdjustments,
        deleteAdjustmentEntitis: [
          ...state.deleteAdjustmentEntitis,
          ...deletedAdjustments, // Agregar los ajustes eliminados a deleteAdjustmentEntitis
        ],
      };
    }
    case adjustmentTypes.ADDSAMEDATE: {
      const { pay, position, totalOtHour, totalRegHour, paymentDate, date,errors } = action.payload;
    
      const updatedEmployees = state.employeesInAdjustment.map((employee) => ({
        ...employee,
        pay,
        position,
        totalOtHour,
        totalRegHour,
        paymentDate,
        date,
        errors
      }));

      return {
        ...state,
        employeesInAdjustment: updatedEmployees,
      };
    }     
    case adjustmentTypes.RESET_ENTIRE_STATE:
      return initialState;
    default:
      return state;
  }
};
