import React, { useEffect } from 'react';

const RecaptchaV2 = ({ onVerify }) => {

  useEffect(() => {
    // Verificamos si el script de reCAPTCHA ya se ha cargado
    const existingScript = document.querySelector(`script[src="https://www.google.com/recaptcha/api.js"]`);
    
    const loadRecaptcha = () => {
      if (window.grecaptcha) {
        // Verificamos si el reCAPTCHA ya ha sido renderizado en este contenedor
        if (!document.getElementById('recaptcha-v2').hasChildNodes()) {
          window.grecaptcha.render('recaptcha-v2', {
            sitekey: process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY,
            callback: onVerify,
          });
        }
      } else {
        console.error('reCAPTCHA v2 no se pudo cargar.');
      }
    };

    if (!existingScript) {
      // Si no existe el script, lo creamos y cargamos
      const recaptchaScript = document.createElement('script');
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js`;
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      recaptchaScript.onload = loadRecaptcha;

      document.body.appendChild(recaptchaScript);

      return () => {
        // Limpiar el recaptcha cuando el componente se desmonta si es necesario
        if (document.body.contains(recaptchaScript)) {
          document.body.removeChild(recaptchaScript);
        }
      };
    } else {
      // Si el script ya existe, simplemente cargamos el reCAPTCHA
      loadRecaptcha();
    }
  }, [onVerify]);

  return <div id="recaptcha-v2"></div>;
};

export default RecaptchaV2;
