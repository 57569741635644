import { useMemo } from 'react';
import { withLocalize } from 'react-localize-redux';
import { shallowEqual, useSelector } from 'react-redux';
import ContainerBlue from '../../components/common/Container/ContainerBlue';
import CreateOrUpdateUser from '../User/CreateOrUpdateUser';
import UseTranslateCode from '../../Hooks/UseTranslateCode';

function Error({ activeLanguage, location }) {
	UseTranslateCode({ location, activeLanguage });

	const user = useSelector(state => {
		return state.tempEdge.login !== '' && state.tempEdge.login.portalUserList.length > 0
			? state.tempEdge.login.portalUserList[0]
			: null;
	}, shallowEqual);

	const message = useMemo(() => {
		if (user === null || user === undefined) return 'Unauthorized User.';
		const messages = {
			P: 'Your application for new user creation is pending approval.',
			R: 'Your application for new user creation was denied.',
		};
		const defaultMessage = 'Usuario Inactivo, comuniquese con su agencia.';
		return messages[user.status] || defaultMessage;
	}, [user]);

	return (
		<>
			{((user && user.status !== 'R') || user === null) && (
				<ContainerBlue width={'50%'}>
					<div className='p-2 text-center'>{message}</div>
				</ContainerBlue>
			)}
			{user && user.status === 'R' && <CreateOrUpdateUser user={user} />}
		</>
	);
}

export default withLocalize(Error);
