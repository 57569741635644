import moment from 'moment';
import { Translate } from 'react-localize-redux';
import downIcon from '../../../assets/icons/down.png';
import { convertToMarkup, setLongCurrencyFormat } from '../../../utils/ReGexs';

const empty = <br />;
const fomartHour = (hour) => {
   const formatHour = moment(hour, 'h:mm:ss A').format('HH:mm');
   return formatHour;
};

export const PositionDetails = ({ position }) => (
   <div className="col-6 col-md-6">
      <div key={`position-${position.positionId}`} style={{ marginBottom: '2rem' }}>
         <div className="btn-dept">
            <a
               className="up-down-arrow pull-left"
               data-toggle="collapse"
               href={`#position-${position.positionId}`}
               role="button"
               aria-expanded="false"
               aria-controls={`position-${position.positionId}`}
            >
               <img src={downIcon} className="downIcon" alt="Show position" />
            </a>
            <span>{position.name}</span>
         </div>

         <div className="collapse multi-collapse" id={`position-${position.positionId}`}>
            <div className="card card-body">
               <div className="form-group row">
                  <div className="col-12 col-md-12">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.description" />
                     </label>
                     <label className="label-view-data">{position.description || empty}</label>
                  </div>
               </div>
               <div className="form-group row">
                  <div className="col-10 col-md-5 col-lg-4">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.markupClient" />
                     </label>
                     <label className="label-view-data">{position.markUp + '%' || empty}</label>
                  </div>
                  <div className="col-10 col-md-5 col-lg-4">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.otMarkupClient" />
                     </label>
                     <label className="label-view-data">{position.otMarkUp + '%' || empty}</label>
                  </div>
                  <div className="col10 col-md-5 col-lg-4">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.regularSchedule" />
                     </label>
                     <label className="label-view-data">
                        {position.timeIn && position.timeOut ? fomartHour(position.timeIn) + ' - ' + fomartHour(position.timeOut) : empty}
                     </label>
                  </div>
               </div>
               <div className="row">
                  <div className="form-group col-md-4">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.payRate" />
                     </label>
                     <label className="label-view-data">{position.pay || empty}</label>
                  </div>
                  <div className="form-group col-md-4">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.billRate" />
                     </label>
                     <label className="label-view-data">
                        {setLongCurrencyFormat(position.pay * convertToMarkup(position.markUp)) || empty}
                     </label>
                  </div>
                  <div className="form-group col-md-4">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.otBillRate" />
                     </label>
                     <label className="label-view-data">
                        {setLongCurrencyFormat(position.pay * convertToMarkup(position.otMarkUp) * 1.5) || empty}
                     </label>
                  </div>
               </div>
               <div className="form-group row">
                  <div className="col-10 col-md-4">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.employeeContact" />
                     </label>
                     <label className="label-view-data">{position.contactName || empty}</label>
                  </div>
                  <div className="col-10 col-md-8">
                     <label className="control-label">
                        <Translate id="com.tempedge.msg.label.clientcontactphone" />
                     </label>
                     <label className="label-view-data">{position.contactPhone || empty}</label>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
);
