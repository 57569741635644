export const timeEntryTypes = {
  FIND_PAYROLL: 'FIND_PAYROLL',
  ADD_EMPLOYEE_TIME_ENTRY: 'ADD_EMPLOYEE_TIME_ENTRY',
  ADD_EMPLOYEE_WEEK_TIME_ENTRY: 'ADD_EMPLOYEE_WEEK_TIME_ENTRY',
  LOAD_VALUES_TO_EMPLOYEE_SAMETIME: 'LOAD_VALUES_TO_EMPLOYEE_SAMETIME',
  LOAD_VALUES_TO_EMPLOYEE_SAMETIME_WEEK: 'LOAD_VALUES_TO_EMPLOYEE_SAMETIME_WEEK',
  CHANGE_TIME_ENTRY_VALUE: 'CHANGE_TIME_ENTRY_VALUE',
  CHANGE_WEEK_TIME_ENTRY_VALUE: 'CHANGE_WEEK_TIME_ENTRY_VALUE',
  CHANGE_STATE: 'CHANGE_STATE',
  RESET_ENTIRE_STATE: 'RESET_ENTIRE_STATE',
  SET_FIND_BY_WEEK: 'SET_FIND_BY_WEEK',
  CLEAR_PROP: 'CLEAR_PROP',
  MOVE_EMPLOYEE_TO_ABSENTS_TABLE: 'MOVE_EMPLOYEE_TO_ABSENTS_TABLE',
  CHANGE_WEEK_VALIDATION_VALUE:'CHANGE_WEEK_VALIDATION_VALUE',
  CHANGE_WEEK_TIME_ENTRY_VALUE_LOCKED:'CHANGE_WEEK_TIME_ENTRY_VALUE_LOCKED',
  MOVE_EMPLOYEE_TO_ABSENTS_WEEK_TABLE: 'MOVE_EMPLOYEE_TO_ABSENTS_WEEK_TABLE',
  MOVE_EMPLOYEE_TO_TIME_ENTRY_TABLE: 'MOVE_EMPLOYEE_TO_TIME_ENTRY_TABLE',
  TIMEDAY_LIST_SAVING: 'TIMEDAY_LIST_SAVING',
  TIMEWEEK_LIST_SAVING: 'TIMEWEEK_LIST_SAVING',
  REQUEST_LIST_SAVING: 'REQUEST_LIST_SAVING',
  FIND_WEEK_ACTIVE: 'FIND_WEEK_ACTIVE',
  TOTALS_WEEK_EXCEL:'TOTALS_WEEK_EXCEL',
};
