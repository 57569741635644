import React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';

export const NavBarOption = ({ activePage, path, label }) => (
  <li className={activePage === path ? 'active' : ''}>
    <Link to={path}>
      <Translate id={label} />
    </Link>
  </li>
);
