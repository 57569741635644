import axios from 'axios';
import { REACT_APP_BASE_URL_TEMPEDGE } from '../components/common/Routes/URLs';

const TempEdgeApi = {
    post : async ({url,payload = {}}) => {
        const {data} = await axios({
           url : REACT_APP_BASE_URL_TEMPEDGE + url,
           header: {
               'Content-type':  'application/json'
           },
           method : 'POST',
           data: {
               orgId: JSON.parse(window.localStorage.getItem('agency'))?.organizationEntity?.orgId,
               ...payload
           },
           params: {
             access_token: localStorage.getItem('access_token')
           }
        });
       
        return {data};
    },
    postArray : async ({url,payload = {}}) => {
        const {data} = await axios({
           url : REACT_APP_BASE_URL_TEMPEDGE + url,
           header: {
               'Content-type':  'application/json'
           },
           method : 'POST',
           data: [               
               ...payload
           ],
           params: {
             access_token: localStorage.getItem('access_token')
           }
        });
       
        return {data};
    },
    postMultipart: async ({url,payload}) => {
      const {data} = await axios({
          url: REACT_APP_BASE_URL_TEMPEDGE + url,
          header: {
              'Content-type': 'multipart/form-data',
          },
          method: 'POST',
          data: payload,
          params: {
             access_token: localStorage.getItem('access_token')
          }
      });     
     
      return {data};
    },
    postHeaders: async ({url,payload}) => {
      const {data} = await axios({
          url: REACT_APP_BASE_URL_TEMPEDGE + url,    
          header: {
            'Content-type':  'application/json'
        },     
          method: 'POST',
          data: payload,
          params: {
             access_token: localStorage.getItem('access_token')
          }
      });     
     
      return {data};
    },
    get: async ({url, type = 'application/json'}) => {
       const {data} = await axios({
           url: REACT_APP_BASE_URL_TEMPEDGE + url,
           headers: {
               'Content-Type' : type
           },
           params: {
            access_token: localStorage.getItem('access_token')
         }
       });
       
       return {data};
    }
}

export default TempEdgeApi;