import './styleServices.css'

const ServiceCards = ({ data, src }) => {
    return (
        <>
            <div className="service">
                <div className="service-icon mb-8 img-width">
                    <img src={src} className="img-fluid card-container image-container" alt="About" />
                </div>
                <div>
                    <div className='small-text'>{data.name}</div>
                </div>
                <p className="txt-2 mb-8">{data.info}</p>
            </div>
        </>
    )
}

export default ServiceCards