import React, { useMemo, useCallback, useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import BlueCommonBtn from "../../../components/common/Buttons/BlueCommonBtn";
import GrayCommonBtn from "../../../components/common/Buttons/GrayCommonBtn";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import Loader from "../../../components/common/Loader/Loader";
import OutcomeBar from "../../../components/common/OutcomeBar";
import { REACT_APP_URL_SAVE_REPORT_CONFIGURATION } from "../../../components/common/Routes/URLs";
import TempEdgeApi from "../../../services/TempEdgeApi";
import CancelBtn from "../../../components/common/Buttons/CancelBtn";
import { Row, Col } from 'react-bootstrap';
import { shiftList } from "../../../components/common/Constants/Constants";

function Confirmation({ formData, prevStep, reset }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (formdata) => {
      try {
        let request = {};
        if (formdata.timeclock.id === 0) {
          request = {
            reportByFile: formdata.timeclock.id || 0,
            payRate: formdata.payRate || false,
            byEmployeeId: formdata.byEmployeeId || false,
            headers: formdata.headers || false,
            client: { clientId: formdata.client.clientId },
            recurrentPayroll: formdata.recurrentPayroll || false,
            reportClientDepts: [],
            reportClientShifts: [],
          };
        } else {
          request = {
            ...formdata.fileConfiguration,
            recurrentPayroll: formdata.recurrentPayroll || false,
            reportByFile: formdata.timeclock.id || 0,
            payRate: formdata.payRate || false,
            byEmployeeId: formdata.byEmployeeId || false,
            headers: formdata.headers || false,
            client: { clientId: formdata.client.clientId },
            reportClientDepts: formdata.reportClientDepts || [],
            reportClientShifts: formdata.reportClientShifts || [],
          };
        }
        setLoading(true);
        const { data } = await TempEdgeApi.post({
          url: REACT_APP_URL_SAVE_REPORT_CONFIGURATION,
          payload: request,
        });

        if (data.status === 200 && data.code === "TE00") {
          reset(data);
        } else {
          setMessage(data);
        }
      } catch (e) {
        setMessage(e);
      } finally {
        setLoading(false);
      }
    },
    [reset]
  );

  const btns = useMemo(() => {
    return (
      <Row className="mt-4">
        <Col className="col-md-4 text-left">
          <CancelBtn />
        </Col>
        <Col className="col-md-4 text-center">
          <GrayCommonBtn
            tag="com.tempedge.msg.label.back"
            onClick={() => {
              prevStep();
            }}
          />
        </Col>
        <Col className="col-md-4 text-right">
          <BlueCommonBtn
            tag="com.tempedge.msg.label.submit"
            onClick={() => handleSubmit(formData)}
          />
        </Col>
      </Row>
    );
  }, [prevStep, handleSubmit, formData]);

  if (loading) return <Loader />;

  return (
    <ContainerBlue title="com.tempedge.msg.menu.reportconf" btns={btns}>
      {message && <OutcomeBar response={message} />}
      <Row className="p-2 justify-content-center">
        <Col md="auto">
          <div className="green-text font-weight-bold text-uppercase mb-4">
            <Translate id="com.tempedge.msg.label.filters" />
          </div>
          <h3 className="confirmation-page-categories">
            <Translate id="com.tempedge.msg.label.company" />
          </h3>
          <p className="confirmation-page-paragraph">
            {formData.client.clientName}
          </p>
          <h3 className="confirmation-page-categories">
            <Translate id="com.tempedge.msg.label.hastimeclock" />
          </h3>
          <p className="confirmation-page-paragraph">
            {formData.timeclock.name}
          </p>
          <div className="green-text font-weight-bold text-uppercase my-4">
            <Translate id="com.tempedge.msg.label.configurations" />
          </div>

          {formData.timeclock.id !== 0 ? (
            <>
              <h3 className="confirmation-page-categories">
                <Translate id="com.tempedge.msg.label.reportpayrate" />
              </h3>
              <p className="confirmation-page-paragraph">
                {formData.payRate ? (
                  <Translate id="com.tempedge.msg.label.yes" />
                ) : (
                  <Translate id="com.tempedge.msg.label.no" />
                )}
              </p>
              <h3 className="confirmation-page-categories">
                <Translate id="com.tempedge.msg.label.repbyemplid" />
              </h3>
              <p className="confirmation-page-paragraph">
                {formData.byEmployeeId ? (
                  <Translate id="com.tempedge.msg.label.yes" />
                ) : (
                  <Translate id="com.tempedge.msg.label.no" />
                )}
              </p>
              <h3 className="confirmation-page-categories">
                <Translate id="com.tempedge.msg.label.reportheaders" />
              </h3>
              <p className="confirmation-page-paragraph">
                {formData.headers ? (
                  <Translate id="com.tempedge.msg.label.yes" />
                ) : (
                  <Translate id="com.tempedge.msg.label.no" />
                )}
              </p>
            </>
          ) : (
            <>
              <h3 className="confirmation-page-categories">
                <Translate id="com.tempedge.msg.label.reportpayroll" />
              </h3>
              <p className="confirmation-page-paragraph">
                {formData.recurrentPayroll ? (
                  <Translate id="com.tempedge.msg.label.yes" />
                ) : (
                  <Translate id="com.tempedge.msg.label.no" />
                )}
              </p>
            </>
          )}
        </Col>

        <Col sm={12} md={8} lg={8}>
          {formData.fileConfiguration &&
            formData.timeclock.id !== 0 &&
            Object.keys(formData.fileConfiguration).length > 0 && (
              <div>
                <div className="green-text font-weight-bold text-uppercase mb-4">
                  <Translate id="com.tempedge.msg.label.fileConfiguration" />
                </div>
                <Row>
                  {Object.keys(formData.fileConfiguration).map((key) => (
                    <Col md={4} key={key}>
                      <div className="form-group">
                        <h3 className="confirmation-page-categories">
                          {key.toLowerCase() === "colsectionpos" && (
                            <Translate id="com.tempedge.msg.label.position" />
                          )}
                          {key.toLowerCase() !== "colsectionpos" &&
                            key !== "colShiftPos" &&
                            key !== "colNameSepPos" && (
                              <Translate
                                id={`com.tempedge.reports.columns.${key.toLowerCase()}`}
                              />
                            )}
                          {key === "colShiftPos" && (
                            <Translate id="com.tempedge.msg.label.shift" />
                          )}
                          {key === "colNameSepPos" && (
                            <Translate id="com.tempedge.reports.columns.collastnamepos" />
                          )}
                        </h3>
                        <p className="confirmation-page-paragraph">
                          {formData.fileConfiguration[key]}
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col sm={12} md={6} lg={6}>
          {formData.reportClientDepts?.length > 0 &&
            formData.timeclock.id !== 0 && (
              <div className="mt-5">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <div className="d-flex justify-content-center">
                          <Translate id="com.tempedge.msg.label.position" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.reportClientDepts.map((clientDpt, index) => (
                      <tr key={index}>
                        <td style={{ width: "50%" }}>
                          {`${clientDpt.reportDeptDesc}-${clientDpt.reportPosDesc}`}
                        </td>
                        <td style={{ width: "50%" }}>{clientDpt.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
        </Col>
          {formData.reportClientShifts?.length > 0 &&
        <Col sm={6} md={3} lg={3}>
            {formData.timeclock.id !== 0 && (
              <div className="mt-5">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <div className="d-flex justify-content-center">
                          <Translate id="com.tempedge.msg.label.shift" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.reportClientShifts.map((key, index) => {
                      const foundShift = shiftList.find(
                        (item) => item.shiftId === key.shift
                      );
                      const shiftName = foundShift
                        ? foundShift.shiftName
                        : "Select";
                      return (
                        <tr key={index}>
                          <td style={{ width: "50%" }}>
                            {key.reportShiftDesc}
                          </td>
                          <td style={{ width: "50%" }}>{shiftName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>)}
        </Col>
            }
      </Row>
    </ContainerBlue>
  );
}

export default withLocalize(Confirmation);
