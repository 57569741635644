import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import Validate from '../../Validations/Validations';
import OutcomeBar from '../../../components/common/OutcomeBar/index';
import { uploadLogo, setCleanLogo } from '../../../Redux/actions/tempEdgeActions';
import tempEdgeLogo from '../../../assets/static/tempEge-logo-sm.png';
import InputBox from '../../../components/common/InputBox/InputBox';

// 1e+6 Bytes equals to 1 MB
const maxFileSize = 1e+6;

class WizardCreateNewAgencySeventhPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyLogo: tempEdgeLogo,
      fileName: ''
    };
  }

  componentDidMount() {
    const { logo, setCleanLogo } = this.props;
    if (logo && logo.name) {
      this.setState({
        companyLogo: URL.createObjectURL(logo),
        fileName: this.constructor.getFileName(logo)
      });
    } else {
      setCleanLogo();
    }
  }

  componentDidUpdate(prevProps) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/registerAgency/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  static getFileName(file) {
    return file.name.replace(/\\/g, '/').replace(/.*\//, '');
  }

  onChange = (e) => {
    this.hideResultBar();
    const [file] = e.target.files;
    if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
      if (file.size <= maxFileSize) {
        const fileName = this.constructor.getFileName(file);
        this.props.uploadLogo('SAVE_LOGO', file);
        this.setState({
          companyLogo: URL.createObjectURL(file),
          fileName
        })
      } else {
        this.showWarningResultBar(new Error('com.tempedge.error.fileerrorsize', maxFileSize));
      } 
    } else if (file) {
      this.showWarningResultBar(new Error('com.tempedge.warn.fileerror'));
    }
  };

  hideResultBar() {
    this.setState({
      resultBar: ''
    });
  };

  showResultBar(data) {
    this.setState({
      resultBar: <OutcomeBar response={data}/>
    });
  }

  showWarningResultBar(data) {
    this.showResultBar(data);
  }

  renderContainerButtons() {
    return (
      <div className="prev-next-btns-agency row my-5">
        <div className="col-md-4 offset-md-2">
          <button type="button" className="btn btn-default btn-block register-save-btn previous" onClick={this.props.previousPage}>
            <Translate id="com.tempedge.msg.label.back"/>
          </button>
        </div>
        <div className="col-md-4">
          <button type="button" onClick={() => this.props.handleSubmit()} className="btn btn-primary btn-block register-save-btn next" disabled={this.props.invalid || this.props.pristine}>
            <Translate id="com.tempedge.msg.label.next"></Translate>
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { resultBar, fileName } = this.state;
    return (
      <div className="wizard-wrapper">
      <h2 className="text-center page-title-agency mb-4">
        <Translate id="com.tempedge.msg.label.newagencyregistration"></Translate>
      </h2>
      <div className="row" style={{ padding: "0 2.2rem" }}>
          <div className="col-12">{this.props.resultBar}</div>
      </div>
      <form onSubmit={this.props.handleSubmit} className="panel-body form-horizontal center-block register-form-agency" style={{ paddingBottom: '0px' }}>
          <div className="form-group row row-agency-name">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2">
                  <label className="control-label pull-right agency-label">
                    <Translate id="com.tempedge.msg.label.agencyname"></Translate>
                  </label>
                </div>
                <div className="col-md-8" style={{ paddingLeft: 0, paddingRight: 71 }}>
                  <Field name="agencyname" type="text" placeholder="Agency Name" component={InputBox} />
                </div>
              </div>
            </div>
          </div>
          <div className="panel register-form-panel">
            <div className="panel-heading register-header">
              <h2 className="text-center">
                <Translate id="com.tempedge.createorg.title.uploadlogo"></Translate>
              </h2>
            </div>
          </div>
          <div className="register-form-panel-inputs">
            <div className="form-group register-form wizard-register-agency-form row">
            {resultBar}
              <div className="container d-flex justify-content-center">
                <div className="row col-md-6">
                  {/* <div className="col-12">
                    <p>
                      <Translate id="com.tempedge.error.fileerrorsize" data={{ maxFileSize: maxFileSize / 1000000 }} />
                    </p>
                  </div> */}
                  <div className="col-12">
                    <div className="d-flex align-items-center">
                    <label htmlFor="logo" className="w-80 mt-4">
                        <p className="text-center">
                          {fileName}
                        </p>
                        <span className="btn department-list-button w-100">
                          <Translate id="com.tempedge.msg.label.choosefile" />
                          <input id="logo" name="logo" type="file" onChange={(e) => this.onChange(e)} className="d-none" accept=".jpg, .jpeg, .png" />
                        </span>
                      </label>
                      <div className="logo-container d-flex align-items-center w-50" style={{minHeight:'6.5rem'}}>
                        <img src={this.state.companyLogo} alt="Company Logo"  style={{width: '100%', objectFit : 'COVER'}}/>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer register-footer panel-footer-agency-height-override">
            {this.renderContainerButtons()}
          </div>
      </form>
    </div>
    );
  }
}

WizardCreateNewAgencySeventhPage = reduxForm({
  form: 'CreateNewAgency',
  destroyOnUnmount: false,
  validate: Validate
})(WizardCreateNewAgencySeventhPage);

const mapStateToProps = (state) => ({
  logo: state.tempEdge.logo
});

const mapDispatchToProps = {
  push,
  uploadLogo,
  setCleanLogo
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(WizardCreateNewAgencySeventhPage)
);
