import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { Field, change, initialize, reduxForm } from "redux-form";
import {
  deletePositionCLient,
  saveBillRates,
  saveDepartmentList,
} from "../../../Redux/actions/tempEdgeActions";
import types from "../../../Redux/actions/types.js";
import InputBox from "../../../components/common/InputBox/InputBox.js";
import Timepicker from "../../../components/common/NewTimePicker/TimepickerReduxForms";
import OutcomeBar from "../../../components/common/OutcomeBar/index.js";
import { store } from "../../../store/store";
import {
  allowedCharacters,
  convertToMarkup,
  limitString,
  normalizePhone,
} from "../../../utils/ReGexs";
import Validate from "../../Validations/Validations";
import deleteIcon from "./assets/delete.png";
import downIcon from "./assets/down.png";
import editIcon from "./assets/edit.png";
import { onlyDigitsAndDot } from "../../../utils/ReGexs.js";

//Department Modal re-init data
const reInitData = {
  position: "",
  description: "",
  markup: "",
  otmarkup: "",
  payRate: "",
  timeIn: "",
  timeOut: "",
  employeeContact: "",
  contactPhone: "",
  posList: [],
  billRate: 0,
  otBillRate: 0,
  message: null,
  existDeptName: false,
  existPostName: false,
};

class Department extends React.Component {
  state = {
    mounted: false,
    posArray: [],
    editPost: {
      index: -1,
      edit: false,
    },
    deletePost: [],
  };

  initPositionDate = async () => {
    let positionsList = [];

    if (
      typeof this.props.deptPosList !== "undefined" &&
      this.props.deptPosList.length > 0
    ) {
      positionsList = await this.props.deptPosList.map((pos, index) => {
        return pos;
      });
    } else {
      this.props.change("CreateNewClient", "departmentname", "");
    }

    await this.setState(() => ({
      mounted: true,
      posList: positionsList,
    }));

    this.renderPositions();
  };

  componentDidMount = async () => {
    this.initPositionDate();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      !Number.isNaN(nextProps.markup) &&
      !Number.isNaN(nextProps.otmarkup) &&
      !Number.isNaN(nextProps.payRate)
    ) {
      let billRate = this.calculateBillRates(
        this.props.payRate,
        this.props.markup,
        "billRate"
      );
      let otBillRate = this.calculateBillRates(
        this.props.payRate,
        this.props.otmarkup,
        "otBillRate"
      );

      this.props.saveBillRates(billRate, types.SAVE_BILL_RATE);
      this.props.saveBillRates(otBillRate, types.SAVE_OT_BILL_RATE);

      this.setState(() => ({
        billRate: billRate,
        otBillRate: otBillRate,
      }));
    }
  };

  increaseListSize = async () => {
    const parsedTimeOut = moment(this.props.timeOut, "LT").format("HH:mm:ss");
    const parsedTimeIn = moment(this.props.timeIn, "LT").format("HH:mm:ss");

    let newDeptPos = {
      bill: this.state.billRate?.toFixed(2),
      name: this.props.position,
      description: this.props.description,
      pay: this.props.payRate,
      markUp: this.props.markup?.substring(0, this.props.markup.length - 1),
      otMarkUp: this.props.otmarkup?.substring(
        0,
        this.props.otmarkup.length - 1
      ),
      timeIn: moment(parsedTimeIn, "HH:mm:ss").isValid() ? parsedTimeIn : "",
      timeOut: moment(parsedTimeOut, "HH:mm:ss").isValid() ? parsedTimeOut : "",
      contactName: this.props.employeeContact,
      contactPhone: this.props.contactPhone,
    };

    let deptPosList = this.state.posList;
    const positionExists = deptPosList.some(
      (post, index) =>
        post.name === this.props.position &&
        !(this.state.editPost?.edit && index === this.state?.editPost?.index)
    );

    if (this.state.editPost.edit) {
      if (
        deptPosList[this.state.editPost?.index] &&
        deptPosList[this.state.editPost?.index].name !== this.props.position &&
        positionExists
      ) {
        this.setState({ existPostName: true });
      } else {
        newDeptPos.positionId =
          deptPosList[this.state?.editPost?.index].positionId;
        deptPosList[this.state?.editPost?.index] = newDeptPos;
        this.setState(() => ({
          editPost: {
            index: -1,
            edit: false,
          },
        }));
        this.reinitPositionDate();
      }
    } else {
      if (positionExists) {
        this.setState({ existPostName: true });
      } else {
        deptPosList.push(newDeptPos);
        this.reinitPositionDate();
      }
    }
    this.setState(() => ({
      posList: deptPosList,
    }));
  };

  reinitPositionDate = () => {
    let departmentname = this.props.departmentname;
    let reboot = reInitData;
    reboot.departmentname = departmentname;
    let objSize = Object.keys(this.props.formValues).length;
    let counter = 0;

    for (let prop in this.props.formValues) {
      if (
        prop === "position" ||
        prop === "description" ||
        prop === "markup" ||
        prop === "otmarkup" ||
        prop === "payRate" ||
        prop === "timeIn" ||
        prop === "timeOut" ||
        prop === "employeeContact" ||
        prop === "contactPhone"
      ) {
        reboot[prop] = "";
      } else {
        reboot[prop] = this.props.formValues[prop];
      }
      if (counter === objSize - 1) {
        this.props.dispatch(initialize("CreateNewClient", reboot));
        this.renderPositions();
      }
      counter++;
    }
  };

  renderPositions = async () => {
    let deptPosList = this.state.posList;

    let list = await deptPosList.map((position, index) => {
      let key = `positions-${index}`;

      return (
        <div id={key} key={key}>
          <div
            className="btn-dept"
            style={index > 0 ? { marginTop: "1rem" } : { marginTop: 0 }}
          >
            <a
              className="up-down-arrow pull-left"
              data-toggle="collapse"
              href={`#positions${index}`}
              role="button"
              aria-expanded="false"
              aria-controls={`positions${index}`}
            >
              <img
                src={downIcon}
                style={{
                  width: 14,
                  height: 11,
                  display: "inline",
                  marginLeft: 19,
                }}
                alt="downIcon"
              />
            </a>
            <span className="ml-1">
              {limitString({
                value: deptPosList[index].name,
                limit: 9,
                replace: "...",
              })}
            </span>
            <span className="pull-right">
              <img
                src={editIcon}
                className="client-dpt-btn-edit-delete"
                style={{ marginLeft: 17, marginRight: 0, display: "inline" }}
                onClick={() => this.editFromPostList(index)}
                alt="editIcon"
              />

              <img
                src={deleteIcon}
                className="client-dpt-btn-edit-delete"
                style={{ marginLeft: 17, marginRight: 17, display: "inline" }}
                onClick={() => this.removeFromPosList(index)}
                alt="deleteIcon"
              />
            </span>
          </div>

          <div
            className="collapse multi-collapse show"
            id={`positions${index}`}
          >
            <div className="card card-body">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th className="w-50">Pay Rate</th>
                    <td className="w-50">
                      {deptPosList[index] !== undefined
                        ? `$ ${deptPosList[index].pay}`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">Markup</th>
                    <td className="w-50">
                      {deptPosList[index] !== undefined
                        ? `${deptPosList[index].markUp} %`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">OT Markup</th>
                    <td className="w-50">
                      {deptPosList[index] !== undefined
                        ? `${deptPosList[index].otMarkUp} %`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">Employee Contact</th>
                    <td className="w-50">
                      {deptPosList[index] !== undefined
                        ? deptPosList[index].contactName
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">Contact Phone</th>
                    <td className="w-50">
                      {deptPosList[index] !== undefined
                        ? deptPosList[index].contactPhone
                        : ""}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      );
    });

    this.setState(() => ({
      posArray: list,
    }));
  };

  removeFromPosList = async (index) => {
    let deptPosList = this.state.posList;
    let deletePosition = [...this.state.deletePost];

    if (this.props.editMode.edit) {
      deletePosition.push(deptPosList[index].positionId);
    }

    deptPosList.splice(index, 1);

    await this.setState(() => ({
      posList: deptPosList,
      deletePost: deletePosition,
    }));

    this.renderPositions();
  };

  editFromPostList = (index) => {
    let deptPosList = this.state.posList;
    this.setState(() => ({
      editPost: {
        index: index,
        edit: true,
      },
    }));
    if (index >= 0 && index <= this.state.posList.length) {
      const item = deptPosList[index];
      const parsedTimeIn = moment(item.timeIn, "HH:mm:ss").format("LT");
      const parsedTimeOut = moment(item.timeOut, "HH:mm:ss").format("LT");

      let newDeptPos = {
        departmentname: this.props.departmentname,
        position: item.name,
        description: item.description,
        payRate: item.pay,
        markup: item.markUp + "%",
        otmarkup: item.otMarkUp + "%",
        timeIn: moment(parsedTimeIn, "LT").isValid() ? parsedTimeIn : "",
        timeOut: moment(parsedTimeOut, "LT").isValid() ? parsedTimeOut : "",
        employeeContact: item.contactName,
        contactPhone: item.contactPhone,
      };
      this.props.dispatch(initialize("CreateNewClient", newDeptPos));
    }
  };

  renderClientDepts = async () => {
    this.props.resetInitData();
    this.props.dispatch(change("CreateNewClient", this.props.reInitData));
    let departmentname = this.props.departmentname;
    let newPosList = this.state.posList;
    let newDeptList = this.props.deptList;
    const departmentCount = newDeptList.filter(
      (dept) => dept.name === departmentname
    ).length;

    if (this.props.editMode.edit) {
      if (newDeptList[this.props.editMode.index]) {
        const originalName = newDeptList[this.props.editMode.index].name;
        // Verificar si se cambió el nombre y si el nuevo nombre ya existe
        if (originalName !== departmentname && departmentCount > 0) {
          this.setState({
            message: "com.tempedge.error.deptposalreadyexists",
            existDeptName: true,
          });
        } else {
          newDeptList[this.props.editMode.index].name =
            this.props.departmentname;
          newDeptList[this.props.editMode.index].positions = newPosList;
          await this.props.saveDepartmentList(newDeptList);
          this.props.renderClientDepartmentsList({ repaint: true });
          this.closePanel();
        }
      } else {
        if (departmentname && newPosList.length > 0) {
          newDeptList.push({
            name: departmentname,
            positions: newPosList,
          });

          await this.props.saveDepartmentList(newDeptList);
          this.props.renderClientDepartmentsList({ repaint: true });
          this.closePanel();
        } else {
          this.closePanel();
        }
      }
    } else {
      // El bloque de código existente para la creación de un nuevo departamento
      if (departmentCount > 0) {
        this.setState({
          message: "com.tempedge.error.deptposalreadyexists",
          existDeptName: true,
        });
      } else {
        newDeptList.push({
          name: departmentname,
          positions: newPosList,
        });

        await this.props.saveDepartmentList(newDeptList);
        this.props.renderClientDepartmentsList({ repaint: false });
        this.closePanel();
      }
    }
  };

  closePanel = () => {
    this.props.closePanel();
    this.props.resetInitData();
    if (this.state?.editPost?.edit) {
      this.setState({
        editPost: {
          index: -1,
          edit: false,
        },
      });
    }
  };

  calculateBillRates = (payRate, markup, op) => {
    let editmarkup = markup?.substring(0, markup?.length - 1);
    if (op === "otBillRate") {
      return payRate * convertToMarkup(editmarkup) * 1.5;
    } else {
      return payRate * convertToMarkup(editmarkup);
    }
  };

  componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener("resize", this.handleResize);
  }

  // Función para determinar si el botón debe estar deshabilitado
  isButtonDisabled = () => {
    const { departmentname, deptList, deptPosList, editMode } = this.props;
    const { posArray } = this.state;
    return (
      !editMode.edit ? false :
      (departmentname === "" ||
        (deptList.length === 0 && deptPosList.length === 0) ||
        posArray.length === 0)
      
    );
  };

  // Manejador para el evento onClick
  handleButtonClick = () => {
    const { editMode, dispatch } = this.props;
    const { deletePost } = this.state;

    if (!editMode.edit) {
      this.closePanel();
    } else {
      this.renderClientDepts();
    }

    if (deletePost.length > 0) {
      dispatch(deletePositionCLient(deletePost));
    }
  };

  render() {
    let positionsList = this.state.posArray;

    let billRate = this.calculateBillRates(
      this.props.payRate,
      this.props.markup,
      "billRate"
    );
    let otBillRate = this.calculateBillRates(
      this.props.payRate,
      this.props.otmarkup,
      "otBillRate"
    );
    const {
      form: {
        CreateNewClient: {
          syncErrors: { payRate, otmarkup, markup },
        },
      },
    } = store.getState();
    const { position, description } = this.props;
    let addPosBtnDisabled =
      payRate || markup || otmarkup || position === "" || description === ""
        ? true
        : false;
    let addDeptDisabled =
      positionsList.length > 0 && this.props.departmentname !== ""
        ? false
        : true;
    return (
      <Container className="sign-up-wrapper mb-5" style={{ margin: 0 }}>
        <h2
          className="text-center page-title-new-client mt-5"
          style={{ padding: 0, marginBottom: "10px" }}
        >
          <Translate id="com.tempedge.msg.label.addDept"></Translate>
        </h2>
        <div className="form-group row row-agency-name">
          <div className="col-12">
            {this.state.message && (
              <OutcomeBar personMessage={this.state.message} />
            )}
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-8">
                <label className="label-p">
                  <Translate id="com.tempedge.msg.label.deptName"></Translate>
                </label>
                <Field
                  name="departmentname"
                  type="text"
                  category="client"
                  maxlength={90}
                  onInput={({ target }) => {
                    this.setState({ existDeptName: false });
                    return (target.value = target.value.replace(
                      allowedCharacters,
                      ""
                    ));
                  }}
                  customClass={this.state.existDeptName ? "has-error" : ""}
                  component={InputBox}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-horizontal center-block panel-body">
          <div className="row new-client-form">
            <div className="col-lg-8 client-col">
              <div
                className="create-client"
                style={{ paddingBottom: 0, marginBottom: 0 }}
              >
                <div className="new-client-header">
                  <h2>
                    {this.props.translate("com.tempedge.msg.label.deptinfo")}
                  </h2>
                </div>
                <div className="new-clients-contents">
                  <div className="client-contents">
                    <div className="row-client">
                      <div className="col-md-8">
                        <div className="row-client">
                          <div className="form-group col-md-12">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.position"></Translate>
                            </label>
                            <Field
                              name="position"
                              type="text"
                              category="client"
                              maxlength={80}
                              component={InputBox}
                              onInput={({ target }) => {
                                this.setState({ existPostName: false });
                                return (target.value = target.value.replace(
                                  /[^a-zA-ZÀ-ú 0-9 .,-/]+/g,
                                  ""
                                ));
                              }}
                              customClass={
                                this.state.existPostName ? "has-error" : ""
                              }
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.markup"></Translate>
                            </label>
                            <Field
                              name="markup"
                              type="text"
                              category="client"
                              maxlength="6"
                              onInput={({ target }) => {
                                const cleanedValue = onlyDigitsAndDot(
                                  target.value
                                );
                                target.value = cleanedValue + "%";
                              }}
                              component={InputBox}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.OtMarkup"></Translate>
                            </label>
                            <Field
                              name="otmarkup"
                              type="text"
                              category="client"
                              maxlength="6"
                              onInput={({ target }) => {
                                const cleanedValue = onlyDigitsAndDot(
                                  target.value
                                );
                                target.value = cleanedValue + "%";
                              }}
                              component={InputBox}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row-client">
                          <div className="form-group col-md-12 ">
                            <label className="label-p">
                              <Translate id="com.tempedge.msg.label.description"></Translate>
                            </label>
                            <Field
                              name="description"
                              type="textarea"
                              maxlength="120"
                              category="client"
                              component={InputBox}
                              onInput={({ target }) => {
                                target.value = target.value.replace(
                                  /[^a-zA-ZÀ-ú 0-9.,\-/_$!@#]+/g,
                                  ""
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row-client">
                      <div className="col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.payRate"></Translate>
                        </label>
                        <Field
                          name="payRate"
                          type="text"
                          category="client"
                          component={InputBox}
                          maxlength="6"
                          onInput={({ target }) =>
                            (target.value = onlyDigitsAndDot(target.value))
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.billRate"></Translate>
                        </label>
                        <p className="area-client">
                          $ {Number.isNaN(billRate) ? "" : billRate.toFixed(2)}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.otBillRate"></Translate>
                        </label>
                        <p className="area-client">
                          ${" "}
                          {Number.isNaN(otBillRate)
                            ? ""
                            : otBillRate.toFixed(2)}
                        </p>
                      </div>
                    </div>

                    <div className="form-group row-client">
                      <div className="col-md-6">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.regularSchedule"></Translate>
                        </label>
                        <Field
                          name="timeIn"
                          type="text"
                          category="client"
                          component={Timepicker}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="label-p">&nbsp;</label>
                        <Field
                          name="timeOut"
                          type="text"
                          category="person"
                          component={Timepicker}
                        />
                      </div>
                    </div>

                    <div className="form-group row-client">
                      <div className="col-md-6">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.employeeContact"></Translate>
                        </label>
                        <Field
                          name="employeeContact"
                          type="text"
                          category="client"
                          onInput={({ target }) =>
                            (target.value = target.value.replace(
                              /[^a-zA-ZÀ-ú .,-]+/g,
                              ""
                            ))
                          }
                          component={InputBox}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="label-p">
                          <Translate id="com.tempedge.msg.label.contactPhone"></Translate>
                        </label>
                        <Field
                          name="contactPhone"
                          type="text"
                          category="client"
                          normalize={normalizePhone}
                          component={InputBox}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-clients-footer">
                    <div className="prev-next-btns-agency row">
                      {
                        <div className="col-md-4">
                          <button
                            type="button"
                            className="btn btn-default btn-block register-save-btn previous"
                            disabled={this.isButtonDisabled()}
                            onClick={this.handleButtonClick}
                          >
                            <Translate id="com.tempedge.msg.label.back"></Translate>
                          </button>
                        </div>
                      }
                      <div className={"col-md-4"}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "#8cb544",
                            backgroundImage: "none",
                            color: "white",
                          }}
                          className="btn btn-default btn-block register-save-btn"
                          onClick={this.increaseListSize}
                          disabled={addPosBtnDisabled}
                        >
                          <Translate id="com.tempedge.msg.label.addPos"></Translate>
                        </button>
                      </div>
                      {!this.props.editMode.edit ||
                      (this.props.deptList.length === 0 &&
                        this.props.deptPosList.length === 0) ? (
                        <div className="col-md-4">
                          <button
                            type="button"
                            className="btn btn-primary btn-block register-save-btn next"
                            onClick={this.renderClientDepts}
                            disabled={addDeptDisabled}
                          >
                            <Translate id="com.tempedge.msg.label.addDept"></Translate>
                          </button>
                        </div>
                      ) : (
                        <div className="col-md-4">
                          <button
                            className="btn btn-primary btn-block register-save-btn next"
                            onClick={() => {
                              this.initPositionDate();
                              this.reinitPositionDate();
                              this.setState({
                                deletePost: [],
                              });
                            }}
                            disabled={
                              addPosBtnDisabled &&
                              this.state.deletePost.length === 0
                            }
                          >
                            <Translate id="com.tempedge.msg.label.cancel"></Translate>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 dept-col">
              <div
                className="department-list"
                style={{ paddingBottom: 0, marginBottom: 0 }}
              >
                <div className="department-list-header">
                  <h2>
                    <Translate id="com.tempedge.msg.label.positionlist" />
                  </h2>
                </div>

                <div className="position-list-contents">
                  <div>
                    <div style={{ height: 10 }}></div>
                    {positionsList.map((position, index) => {
                      return <div key={index}>{position}</div>;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

Department.propTypes = {
  change: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  saveDepartmentList: PropTypes.func.isRequired,
  saveBillRates: PropTypes.func.isRequired,
};

Department = reduxForm({
  form: "CreateNewClient", //                 <------ form name
  destroyOnUnmount: false, //        <------ preserve form data
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: Validate,
})(Department);

let mapStateToProps = (state) => {
  let departmentname = "";
  let position = "";
  let description = "";
  let payRate = "";
  let markup = "";
  let otmarkup = "";
  let timeIn = "";
  let timeOut = "";
  let employeeContact = "";
  let contactPhone = "";
  let addDeptDisabled = true;
  let addPosDisabled = true;

  if (state.form.CreateNewClient !== undefined) {
    if (state.form.CreateNewClient.values !== undefined) {
      let formState = state.form.CreateNewClient.values;
      departmentname =
        typeof formState.departmentname === "undefined"
          ? ""
          : formState.departmentname;
      position =
        typeof formState.position === "undefined" ? "" : formState.position;
      description =
        typeof formState.description === "undefined"
          ? ""
          : formState.description;
      payRate =
        typeof formState.payRate === "undefined" ? "" : formState.payRate;
      markup = typeof formState.markup === "undefined" ? "" : formState.markup;
      otmarkup =
        typeof formState.otmarkup === "undefined" ? "" : formState.otmarkup;
      timeIn = typeof formState.timeIn === "undefined" ? "" : formState.timeIn;
      timeOut =
        typeof formState.timeOut === "undefined" ? "" : formState.timeOut;
      employeeContact =
        typeof formState.employeeContact === "undefined"
          ? ""
          : formState.employeeContact;
      contactPhone =
        typeof formState.contactPhone === "undefined"
          ? ""
          : formState.contactPhone;
    }
  }

  return {
    deptList:
      state.tempEdge.deptList !== undefined ? state.tempEdge.deptList : [],
    deptPosList: state.tempEdge.deptPosList,
    departmentname: departmentname,
    position: position,
    description: description,
    payRate: payRate,
    markup: markup,
    otmarkup: otmarkup,
    timeIn: timeIn,
    timeOut: timeOut,
    employeeContact: employeeContact,
    contactPhone: contactPhone,
    addDeptDisabled: addDeptDisabled,
    addPosDisabled: addPosDisabled,
    formValues:
      typeof state.form.CreateNewClient !== "undefined"
        ? state.form.CreateNewClient.values
        : "",
  };
};

export default withLocalize(
  connect(mapStateToProps, {
    saveDepartmentList,
    change,
    initialize,
    saveBillRates,
    deletePositionCLient,
  })(Department)
);
