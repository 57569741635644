import React, { useEffect }  from "react";
import { Translate } from "react-localize-redux";
import {shallowEqual, useSelector } from "react-redux";
import history from  '../../history';

function requireAuth(Component) {

  function Authenticate(props){

      const login = useSelector(
        (state) => state.tempEdge.login,
        shallowEqual
      );

      useEffect(() => {
          if( Object.keys(login).length === 0)  history.push(`/auth/en`);
      },[login]);

      if(Object.keys(login).length === 0) {
         return <Translate id="com.tempedge.error.userpasswrong"/>;
      }

      return <Component {...props} />;

  };

  return Authenticate;

}

export default requireAuth;
