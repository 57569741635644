import React from 'react'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

export const MixBarChart = ({ title, data, children, translate }) => {
    return (
        <>
            <h2 className='text-center'> {translate(title)} </h2>
            {children}

            <ResponsiveContainer width="100%" height="80%">
                <BarChart
                    data={data}
                    margin={{
                        top: 30,
                        right: 40,
                        left: -10,
                        bottom: 10,
                    }}
                >
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="countWeek" fill="var(--tempedge-blue)" name={translate('com.tempedge.msg.label.currentweek')} />
                    <Bar dataKey="countPreviousWeek" fill="var(--tempedge-orange)" name={translate('com.tempedge.msg.label.previousweek')} />
                </BarChart>
            </ResponsiveContainer>

        </>
    )
}
