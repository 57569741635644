import { Translate, withLocalize } from 'react-localize-redux';
import React from 'react';

const GrayCommonBtn = (props) => {
  const handleClick = () => {
    props.onClick();
  };

  return (
    <div>
      <button type="button" className="btn btn-default btn-block register-save-btn previous" onClick={handleClick}>
        <Translate id={props.tag} />
      </button>
    </div>
  );
};

export default withLocalize(GrayCommonBtn);

