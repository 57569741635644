import { FieldArray, Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import * as Yup from "yup";

import { useMemo, useRef, useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import BlueCommonBtn from "../../../components/common/Buttons/BlueCommonBtn";
import GrayCommonBtn from "../../../components/common/Buttons/GrayCommonBtn";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import Loader from "../../../components/common/Loader/Loader";
import OutcomeBar from "../../../components/common/OutcomeBar";
import { REACT_APP_URL_GET_DEPARTMENTS_POSITION_LIST_REPORT } from "../../../components/common/Routes/URLs";
import UploadFile from "../../../components/common/UploadFile/UploadFile";
import TempEdgeApi from "../../../services/TempEdgeApi";
import { Col, Row } from "react-bootstrap";

const validateShema = Yup.object().shape({
  reportClientDepts: Yup.array()
    .of(
      Yup.object().shape({
        reportDeptDesc: Yup.string().required("this field is required"),
        department: Yup.object().required("this field is required"),
        position: Yup.object().required("this field is required"),
        name: Yup.string().required("this field is required"),
      })
    )
    .required("this field is required"),
});

const UpdateFileConf = ({
  formData,
  setFormDataUpload,
  prevStep,
  nextStep,
}) => {
  const [validate, setValidate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const formikRef = useRef();

  const positionsClient = useCallback(() => {
    const departments = formData.client.departments;
    const positionAndDepartmentList = [];
    positionAndDepartmentList.push({
      name:"COMMON RATE",
      position: null,
      department: null,
    });
    if (departments) {
      departments.forEach((department) => {
        department?.positions?.forEach((position) => {
          positionAndDepartmentList.push({
            name: `${department?.name} - ${position?.name}`,
            position: position?.positionId,
            department: department?.departmentId,
          });
        });
      });
      
    }
    return positionAndDepartmentList;
  },[formData.client]);


  const updateValidation = useCallback(() => {
    const clientDptsLength = formData.reportClientDepts.length;
    setValidate(clientDptsLength === 0);
  },[formData.reportClientDepts]);

  useEffect(() => {
   
    const updatedClientDepts = formData.reportClientDepts.map((clientDpt) => {
      if (clientDpt?.name?.trim() === "undefined - undefined") {
        return {
          ...clientDpt,
          name: "COMMON RATE",
        };
      }
      return clientDpt;
    });

    formikRef.current.setFieldValue("reportClientDepts", updatedClientDepts);

    positionsClient();
    updateValidation(); 
  }, [formData.reportClientDepts, positionsClient, updateValidation]);

 
  const handleImport = async (file, formdata) => {
    let typeExtension = /[.]/.exec(file.name)
      ? /[^.]+$/.exec(file.name)[0]
      : undefined;

    let requests = new FormData();
    setMessage(null);
    formikRef.current.setFieldValue("reportClientDepts", []);
    try {
      setLoading(true);
      const clientDpts = formdata.reportClientDepts.map((clientDpt) => {
        if (clientDpt?.name?.trim() === "undefined - undefined") {
          return {
            ...clientDpt,
            name: "COMMON RATE",
          };
        }
        return clientDpt;
      });;
      let datas = {
        orgId: JSON.parse(window.localStorage.getItem("agency"))
          ?.organizationEntity?.orgId,
        clientId: formdata.client.clientId,
      };
      let payload = {
        recurrentPayroll: formdata.recurrentPayroll,
        reportByFile: formdata.timeclock.id,
        payRate: formdata.payrate,
        byEmployeeId: formdata.byEmployeeId,
        headers: formdata.headers,
        client: {
          clientId: formdata.client.clientId,
        },
        colDeptPos: formdata.fileConfiguration.colDeptPos,
        colEmpidPos: formdata.fileConfiguration.colEmpidPos
          ? formdata.fileConfiguration.colEmpidPos
          : null,
        colHoursPos: formdata.fileConfiguration.colHoursPos,
        colNamePos: formdata.fileConfiguration.colNamePos,
        colOthoursPos: formdata.fileConfiguration.colOthoursPos,
        colSectionPos: formdata.fileConfiguration.colSectionPos,
        colRatePos: formdata.fileConfiguration.colRatePos,
        colTimeinPos: formdata.fileConfiguration.colTimeinPos,
        colNameSepPos: formdata.fileConfiguration.colNameSepPos
          ? formdata.fileConfiguration.colNameSepPos
          : null,
        colDatePos: formdata.fileConfiguration.colDatePos
          ? formdata.fileConfiguration.colDatePos
          : null,
        colShiftPos: formdata.fileConfiguration.colShiftPos ? formdata.fileConfiguration.colShiftPos : null,
        reportClientShifts: formdata.reportClientShifts ? formdata.reportClientShifts : null,
      };

      let stringDatas = JSON.stringify(datas);
      let blobs = new Blob([stringDatas], { type: "application/json" });
      requests.append("clientEntity", blobs);
      requests.append("report", file);
      requests.append("fileType", typeExtension);
      requests.append("reportType", formdata.timeclock.id);
      blobs = new Blob([JSON.stringify(payload)], { type: "application/json" });
      requests.append("reportConfEntity", blobs);
      const { data } = await TempEdgeApi.postMultipart({
        url: REACT_APP_URL_GET_DEPARTMENTS_POSITION_LIST_REPORT,
        payload: requests,
      });
      if (data.status === 200) {
        let dataRequest = [];
        for (let item of data.result.reportConfDeptPosList) {
          const result = clientDpts.filter(($el) => {
            return (
              $el.reportDeptDesc === item.reportDeptDesc &&
              $el.reportPosDesc === item.reportPosDesc
            );
          });
          if (result.length === 0) {
            dataRequest.push({
              reportDeptDesc: item.reportDeptDesc,
              reportPosDesc: item.reportPosDesc,
            });
          }
        }
        formikRef.current.setFieldValue("reportClientDepts", [
          ...dataRequest,
          ...clientDpts,
        ]);
        formikRef.current.setFieldValue("reportClientShifts", data.result.reportConfShiftList);
      } else {
        setMessage(data);
        const updatedClientDepts = clientDpts.map((clientDpt) => {
          if (clientDpt.name.trim() === "undefined - undefined") {
            return {
              ...clientDpt,
              name: "COMMON RATE",
            };
          }
          return clientDpt;
        });
        formikRef.current.setFieldValue("reportClientDepts", [...updatedClientDepts]);   
        formikRef.current.setFieldValue("reportClientShifts", formData.reportClientShifts);    
      }
    } catch (e) {
      setMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const btns = useMemo(() => {
    return (
      <Row className="prev-next-btns-agency mt-5">
        <Col md={{ span: 5, offset: 1 }}>
          <GrayCommonBtn
            tag="com.tempedge.msg.label.back"
            onClick={() => {
              setFormDataUpload(formikRef.current.values);
              prevStep();
            }}
          />
        </Col>
        <Col md={5}>
          <BlueCommonBtn
            tag="com.tempedge.msg.label.next"
            onClick={() => {
              setFormDataUpload(formikRef.current.values);
              nextStep();
            }}
            invalid={validate}
          />
        </Col>
      </Row>
    );
  }, [setFormDataUpload, prevStep, nextStep, validate]);

  return (
    <ContainerBlue btns={btns} title="com.tempedge.msg.menu.reportconf">
      {message && <OutcomeBar response={message} />}
      <Formik
        innerRef={formikRef}
        initialValues={formData}
        onSubmit={(values) => {}}
        validationSchema={validateShema}
      >
        {(formik) => (
          <form className="row p-2 justify-content-center">
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="col-12 col-md-4">
                  <div className="green-text font-weight-bold text-uppercase mb-4">
                    <Translate id="com.tempedge.msg.label.upload" />
                  </div>
                  <p>
                    <Translate id="com.tempedge.msg.label.fileConfigurationDesc" />
                  </p>
                  <UploadFile
                    title="com.tempedge.msg.label.upload"
                    accept=".xls, .xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onFileSelectSuccess={(file) => {
                      handleImport(file, formData);
                    }}
                    onFileSelectError={(e) => {
                      setMessage(e);
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <div>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th colSpan="2">
                            <div className="d-flex justify-content-center">
                              <Translate id="com.tempedge.msg.label.position" />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray
                          name="reportClientDepts"
                          render={(arrayHelper) => (
                            <>
                              {formik.values.reportClientDepts &&
                                formik.values.reportClientDepts.map(
                                  (clientDpt, index) => {
                                    if (clientDpt.name === undefined) {
                                      setValidate(true);
                                    }
                                    return (
                                      <tr key={index}>
                                        <td
                                          style={{ width: "50%" }}
                                        >{`${clientDpt.reportDeptDesc}-${clientDpt.reportPosDesc}`}</td>
                                        <td style={{ width: "50%" }}>
                                          <DropdownList
                                            data={positionsClient()}
                                            textField="name"
                                            valueField="name"
                                            value={clientDpt}
                                            onChange={(value) => {
                                              if (value.name !== undefined) {
                                                setValidate(false);
                                              }
                                              if(value.name ==="COMMON RATE" || value.name?.trim() === "undefined - undefined"){
                                                arrayHelper.replace(index, {
                                                ...formik.values
                                                  .reportClientDepts[index],
                                                department: null,
                                                position: null,
                                                name: "COMMON RATE",
                                                reportDeptDesc:
                                                  clientDpt.reportDeptDesc,
                                              });
                                              }else{
                                                arrayHelper.replace(index, {
                                                ...formik.values
                                                  .reportClientDepts[index],
                                                department: {
                                                  departmentId:
                                                    value.department,
                                                },
                                                position: {
                                                  positionId: value.position,
                                                },
                                                name: value.name,
                                                reportDeptDesc:
                                                  clientDpt.reportDeptDesc,
                                              });
                                              }                                              
                                              
                                            }}
                                            isInvalid={
                                              clientDpt.name === undefined
                                            }
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </>
                          )}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </form>
        )}
      </Formik>
    </ContainerBlue>
  );
};

export default withLocalize(UpdateFileConf);
