import React, { useEffect, useState } from 'react'
import DropdownList from '../Dropdown/DropdownList';
import useClientList from '../../../Hooks/useClientList';
import InfoModal from '../Modal/InfoModal';
import DepartmentTables from '../DepartmentTables/DepartmentTables';
import Modal from '../../../Modals/Modal/Modal';

export const DropdownClient = ({ translate, onChange, showDetails = true, isInvalid = false, reset, value = null }) => {
    const [content, setContent] = useState("");
    const [modalSize, setModalSize] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [client, setClient] = useState(value);
    const clientList = useClientList();

    const onChangeClientList = (value) => {
        onChange(value);
        setClient(value);
    }

    const toggleDeparmentListModal = (client) => {
        if (client) {
            onChangeClientList(client);
        }
        const departmentTables = <DepartmentTables clientSelected={client} />;
        toggleModal(departmentTables, "modal-md");
    };

    const toggleModal = (contents, modalSizes) => {
        setShowModal(!showModal);
        setContent(contents);
        setModalSize(modalSizes);
    };

    useEffect(() => {
        if (reset) {
          setClient(null);
        }
      }, [reset]);

    if (clientList.length === 1) {
        return (
            <>
                <InfoModal
                    value={clientList[0].clientName}
                    isModal={true}
                    onClick={() => toggleDeparmentListModal(clientList[0])}
                />
                <Modal
                    content={content}
                    open={showModal}
                    onClose={() => toggleModal()}
                    modalSize={modalSize}
                />
            </>
        );
    } else {
        return (
            <>
                <DropdownList
                    className="form-control"
                    value={client}
                    data={clientList}
                    valueField="clientId"
                    textField="clientName"
                    defaultValue={null}
                    onChange={(clientSelected) => onChangeClientList(clientSelected)}
                    isInvalid={isInvalid}
                />
                {
                    showDetails && (
                        <div className="input-group-append">
                            <button
                                type="button"
                                onClick={() => toggleDeparmentListModal(client)}
                                title={translate(
                                    "com.tempedge.msg.label.viewdepartmentandposotionlist"
                                )}
                                className="btn btn-green"
                                disabled={false}
                            >
                                &#128712;
                            </button>
                        </div>
                    )
                }
                <Modal
                    content={content}
                    open={showModal}
                    onClose={() => toggleModal()}
                    modalSize={modalSize}
                />
            </>
        );
    }

}
