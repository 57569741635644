import { useState, useCallback } from 'react';
import moment from 'moment';
import { REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE } from '../components/common/Routes/URLs';
import TempEdgeApi from '../services/TempEdgeApi';

const useWeekDates = () => {
    const [weekDates, setWeekDates] = useState({
        thisWeekInit: '',
        thisWeekEnd: '',
        lastWeekInit: '',
        lastWeekEnd: ''
    });
    const [error, setError] = useState(null);

    const fetchWeekDates = useCallback(async (client, date = moment(new Date()).format("YYYY-MM-DD")) => {
        if (client && date) {
            try {

                const { data: weekActive } = await TempEdgeApi.post(
                    {
                        url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
                        payload: {
                            clientId: client.clientId,
                            dateList: date,
                        }
                    }
                );

                const {
                    status = "",
                    result: { startDate = "", endDate = "" },
                } = weekActive;

                if (status === 200) {
                    const { data: lastWeekActive } = await TempEdgeApi.post(
                        {
                            url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
                            payload: {
                                clientId: client.clientId,
                                dateList: moment(weekActive.result.startDate)
                                    .subtract(1, "d")
                                    .format("YYYY-MM-DD"),
                            }
                        }
                    );

                    const {
                        status: lastStatus = "",
                        result: { startDate: lastStartDate = "", endDate: lastEndDate = "" },
                    } = lastWeekActive;

                    if (lastStatus === 200) {
                        setWeekDates({
                            thisWeekInit: startDate,
                            thisWeekEnd: endDate,
                            lastWeekInit: lastStartDate,
                            lastWeekEnd: lastEndDate,
                        });
                    }
                }
            } catch (error) {
                setError(error);
            }
        }
    }, []);

    return { weekDates, fetchWeekDates, error };
};

export default useWeekDates;
