import {
   REACT_APP_URL_FIND_ATTENDANCE,
   REACT_APP_URL_FIND_ATTENDANCE_REPORT,
   REACT_APP_URL_SAVE_ATTENDANCE,
} from '../../components/common/Routes/URLs';
import TempEdgeApi from '../../services/TempEdgeApi';
import { downloadFile, normalizeDateSendReports } from '../../utils/ReGexs';
import { attendanceTypes } from '../types/attendanceTypes';

export const clearState = () => ({
   type: attendanceTypes.CLEAR_STATE,
});

export const updateClientId = (payload) => ({
   type: attendanceTypes.UPDATE_CLIENT_ID,
   payload,
});

export const updateStartDate = (payload) => ({
   type: attendanceTypes.UPDATE_START_DATE,
   payload,
});

export const fetchAttendanceRequest = () => ({
   type: attendanceTypes.FETCH_ATTENDANCE_REQUEST,
});

export const fetchAttendanceSuccess = (data) => ({
   type: attendanceTypes.FETCH_ATTENDANCE_SUCCESS,
   payload: data,
});

export const fetchAttendanceFailure = () => ({
   type: attendanceTypes.FETCH_ATTENDANCE_FAILURE,
});

export const fetchAttendance = (payload) => {
   return async (dispatch, getState) => {
      dispatch(fetchAttendanceRequest());
      dispatch(clearAttendanceList());
      try {
         const response = await TempEdgeApi.post({
            url: REACT_APP_URL_FIND_ATTENDANCE,
            payload,
         });
         if (response.data.status === 200 && response.data.code === 'TE00') {
            dispatch(fetchAttendanceSuccess(response.data.result));
            dispatch(updateStartDate(payload.startDate));
            dispatch(updateClientId(payload.clientId));
         } else {
            dispatch(fetchAttendanceFailure());
            dispatch(updateMessage(response));
         }
      } catch (error) {
         dispatch(fetchAttendanceFailure());
         dispatch(updateMessage(new Error(error)));
      }
   };
};

export const updateAttendanceList = (payload) => ({
   type: attendanceTypes.UPDATE_ATTENDANCE_LIST,
   payload,
});

export const fetchSaveAttendanceRequest = () => ({
   type: attendanceTypes.FETCH_SAVE_ATTENDANCE_REQUEST,
});

export const fetchSaveAttendanceSuccess = (data) => ({
   type: attendanceTypes.FETCH_SAVE_ATTENDANCE_SUCCESS,
   payload: data,
});

export const fetchSaveAttendanceFailure = () => ({
   type: attendanceTypes.FETCH_SAVE_ATTENDANCE_FAILURE,
});

export const fetchSaveAttendance = (dataFind) => {
   return async (dispatch, getState) => {
      dispatch(fetchSaveAttendanceRequest());
      dispatch(updateMessage(null));

      const state = getState();
      const payload = {
         clientId: state.attendance.clientId,
         attendanceList: state.attendance.saveObject,
         startDate: normalizeDateSendReports(dataFind.startDate),
         endDate: normalizeDateSendReports(dataFind.endDate),
      };

      try {
         const response = await TempEdgeApi.post({
            url: REACT_APP_URL_SAVE_ATTENDANCE,
            payload,
         });

         if (response.data.code === 'TE00' || response.data.code === 'TE-E08') {  // Añadido TE-E08
            if (dataFind.downloadReport) {
               // Pasar getState correctamente a fetchDownloadReportRequest
               const reportResponse = await fetchDownloadReportRequest(dataFind, getState);
               if (reportResponse.error) {
                  // Maneja el error en la descarga del reporte
                  dispatch(fetchSaveAttendanceFailure());
                  dispatch(updateMessage(reportResponse.error));
               } else if (reportResponse.data.status === 200 && (reportResponse.data.code === 'TE00' || reportResponse.data.code === 'TE-E08')) {
                  // Descarga el archivo si la respuesta es exitosa
                  downloadFile(reportResponse.data.result, dataFind?.clientName);
                  dispatch(fetchSaveAttendanceSuccess(response));
                  dispatch(clearAttendanceList());
                  dispatch(updateMessage(response));
               } else {
                  dispatch(fetchSaveAttendanceFailure());
                  dispatch(updateMessage(reportResponse));
               }
            } else {
               dispatch(fetchSaveAttendanceSuccess(response));
               dispatch(clearAttendanceList());
               dispatch(updateMessage(response));
            }
         } else {
            dispatch(fetchSaveAttendanceFailure());
            dispatch(updateMessage(response));
         }
      } catch (error) {
         dispatch(fetchSaveAttendanceFailure());
         dispatch(updateMessage(new Error(error)));
      }
   };
};

const fetchDownloadReportRequest = async (dataFind, getState) => {
   const state = getState();  // Obtén el estado correctamente
   const payload = {
      orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
      startDate: normalizeDateSendReports(dataFind.startDate),
      endDate: normalizeDateSendReports(dataFind.endDate),
      clientId: state.attendance.clientId,  // Usa el estado correctamente
      separatedByDay: false,
      reportByDept: false,
      reportByPosition: false,
      showMarkup: false,
      departmentId: null,
      positionId: null,
      module: "com.tempedge.msg.menu.attendance"
   };

   try {
      const response = await TempEdgeApi.post({
         url: REACT_APP_URL_FIND_ATTENDANCE_REPORT,
         payload,
      });
      return response;
   } catch (error) {
      // Devuelve el error para manejarlo también
      return { error: new Error(error) };
   }
};

export const updateMessage = (message) => ({
   type: attendanceTypes.UPDATE_MESSAGE,
   payload: message,
});

export const clearAttendanceList = () => ({
   type: attendanceTypes.CLEAR_ATTENDANCE_LIST,
});

export const updateFieldInvalid = (field, isInvalid) => ({
   type: attendanceTypes.UPDATE_FIELD_INVALID,
   payload: { field, isInvalid },
});

export const clearFieldInvalid = (field) => ({
   type: attendanceTypes.CLEAR_FIELD_INVALID,
   payload: { field },
});

export const clearSearchForm = () => ({
   type: attendanceTypes.CLEAR_SEARCH_FORM,
});

export const updateActiveWeek = (payload) => ({
   type: attendanceTypes.UPDATE_ACTIVE_WEEK,
   payload,
});
