import React from 'react';
import { FaYoutube as IconYoutube } from "react-icons/fa6";
import ModalVideo from 'react-modal-video';
import '../../../../node_modules/react-modal-video/css/modal-video.css';
import { useState } from 'react';

export const CardVideo = ({title, id}) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <div className="card-container card-video-container">
                <div className='small-text'> {title} </div>
                <div onClick={() => setOpen(true)} className='button-open-modal'>
                    <i className="play-icon-circle">
                        <IconYoutube size={'45'} />
                        <div className='label-video-card'>Watch Video Now! </div>
                    </i>
                </div>

                <ModalVideo youtube={{ mute: 0, autoplay: 0 }} channel='youtube' autoplay isOpen={isOpen} videoId={id} onClose={() => setOpen(false)} />
            </div>
        </>
    )
}
