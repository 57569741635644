import React from "react";
import { normalizeDate } from "../../utils/ReGexs";
import deleteIcon from "../../assets/icons/delete.png";
import { REACT_APP_URL_CANCEL_TIMEOFF } from "../../components/common/Routes/URLs";
import TempEdgeApi from "../../services/TempEdgeApi";
import { Translate } from "react-localize-redux";

const ItemTimeOff = ({
  findTime,
  findDay,
  trackPromise,
  setMessage,
  findWeekTimeOffCallback,
}) => {
  let daySelected = findTime.filter(
    (item) => normalizeDate(item.date) === normalizeDate(findDay.day)
  );

  const statusDays = (data) => {
    switch (data) {
      case "A":
        return <Translate id="com.tempedge.msg.label.approved" />;
      case "P":
        return <Translate id="com.tempedge.msg.label.pending" />;
      case "R":
        return <Translate id="com.tempedge.msg.label.rejected" />;
      default:
        return "N/A";
    }
  };

  const cancelDay = async (day) => {
    try {
      const { data: res } = await TempEdgeApi.post({
        url: REACT_APP_URL_CANCEL_TIMEOFF,
        payload: {
          id: day.timeOffId,
        },
      });
      if (res.status === 200) {
        setMessage(res);
        findWeekTimeOffCallback();
      }
    } catch (error) {
      setMessage(error);
    }

    setTimeout(() => {
      setMessage("");
    }, 1000);
  };

  return (
    <>
      {daySelected.length > 0 && (
        <div className="form-group  mt-4 px-5 container">
          <div className="tempEdge-input-box row">
            <div className="col py-4 ">
              <div className="d-flex flex-row ml-1 align-items-center">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill={daySelected[0]?.status === "A" ? "green" : "red"}
                    className="bi bi-circle-fill "
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                </div>
                <p className="control-label ml-3">
                  {normalizeDate(daySelected[0].date)}
                </p>
              </div>
              <div className="control-label ml-5">
                {daySelected[0]?.timeOffType?.description}
              </div>
            </div>
            <div className="col py-4">
              <div className="d-flex flex-row ml-1 align-items-center">
                <label className="control-label">
                  <Translate id="com.tempedge.msg.label.status" />
                </label>
              </div>
              <p className="control-label">
                {statusDays(daySelected[0].status)}
              </p>
            </div>
            <div className="col d-flex justify-content-around ">
              <button
                type="button"
                className="btn"
                onClick={() => trackPromise(cancelDay(daySelected[0]))}
              >
                <img
                  className="icon-width w-auto"
                  src={deleteIcon}
                  alt="Delete icon"
                />
              </button>
            </div>
          </div>
          
        </div>
      )}
    </>
  );
};

export default ItemTimeOff;
