import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Stepper from 'react-stepper-horizontal';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import httpService from '../../../utils/services/httpService/httpService.js';
import { getList, setCleanLogo } from '../../../Redux/actions/tempEdgeActions';
import types from '../../../Redux/actions/types.js';
import WizardCreateNewAgencyrFirstPage from './WizardCreateNewAgencyFirstPage.js';
import WizardCreateNewAgencySecondPage from './WizardCreateNewAgencySecondPage.js';
import WizardCreateNewAgencyThirdPage from './WizardCreateNewAgencyThirdPage';
import WizardCreateNewAgencyFourthPage from './WizardCreateNewAgencyFourthPage';
import WizardCreateNewAgencyFifthPage from './WizardCreateNewAgencyFifthPage';
import WizardCreateNewAgencySixthPage from './WizardCreateNewAgencySixthPage';
import WizardCreateNewAgencySeventhPage from './WizardCreateNewAgencySeventhPage';
import WizardCreateNewAgencyEighthPage from './WizardCreateNewAgencyEighthPage.js';
import { REACT_APP_URL_AGENCY_SAVE, REACT_APP_URL_COUNTRY_LIST_ALL, REACT_APP_URL_FUNDING_LIST_ALL } from '../../../components/common/Routes/URLs.js';

import Loader from '../../../components/common/Loader/Loader.js';
import { Translate } from 'react-localize-redux';

class CreateNewAgency extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);

    this.state = {
      page: 1,
      steps: [
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" }
      ],
      loading : false
    };
    this.baseState = this.state;
  }

 
   
  componentDidMount = async() => {
    this.setState({
      loading : true
    });
    await this.props.getList(REACT_APP_URL_COUNTRY_LIST_ALL, types.GET_COUNTRY_REGION_LIST);
    await  this.props.getList(REACT_APP_URL_FUNDING_LIST_ALL, types.GET_FUNDING_LIST);
    this.setState({
      loading : false
    });
  }


 resetEntireForm =  () => {
  this.setState(this.baseState);
};

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  onSubmit = async (formValues) => {
    let recruitmentOffices = {};
    let isRecruitmentOfficePhoneNumbersEmpty = !Object.keys(formValues.recruitmentofficephonenumbers[0]).length;

    if (isRecruitmentOfficePhoneNumbersEmpty) {
      recruitmentOffices = null;
    } else {
      recruitmentOffices = formValues.recruitmentofficephonenumbers?.map((recruitmentOffice) => {
        return ({
          address: recruitmentOffice?.officeName,
          city: recruitmentOffice?.city,
          country: formValues?.agencycountry?.countryId,
          name: recruitmentOffice?.officeName,
          phone: recruitmentOffice?.phonenumber,
          zipcode: recruitmentOffice?.zip,
          region: formValues?.agencystate?.regionId
        });
      });
    }

    let phoneList = formValues.agencyphonenumbers?.map((phoneNumber) => {
      return ({
        phone: phoneNumber?.phonenumber,
        ext: phoneNumber?.phoneext
      });
    });

    let salesmanList = formValues.recruitmentofficesalespersons.map((salesman) => {
      return ({
        firstName: salesman?.salespersonfirstname,
        genre: salesman?.gender?.label ? (salesman?.gender?.label === 'Select' ? null : salesman?.gender?.label === 'Male' ? 'M' : 'F') : null,
        lastName: salesman?.salespersonlastname,
        phone: salesman?.salespersonphonenumber
      });
    });


    const request = {
      user: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        username: formValues.username,
        password: (formValues.initialpassword === formValues.confirmpassword) ? formValues.initialpassword : null,
        email: formValues.email
      },
      organizationEntity: {
        address: formValues?.agencyaddress,
        addressType: '',
        address2: formValues?.agencyappartment,
        city: formValues?.agencycity,
        clientPayrollDate: formValues.weekdaysdropdown1?.value,
        country: formValues.agencycountry?.countryId,
        lastPayrollDate: formValues?.weekdaysdropdown2?.value,
        organizationName: formValues?.agencyname,
        region: formValues.agencystate?.regionId,
        zipcode: formValues?.agencyzipcode,
        autoEmployeeId:formValues?.generateEmployeeID?.value,
        funding: {
          fundingId: formValues?.fundingCompanydropdown?.fundingId
        },
        officeEntityList: recruitmentOffices
      },
      phoneList,
      salesmanList,
      
    };
    const formData = new FormData();
    const srtRequest = JSON.stringify(request);
    const jsonFile = new Blob([srtRequest], { type: 'application/json' });

    formData.append('registerAgencyEntity', jsonFile);
    formData.append('logo', this.props.logo);

    httpService.postMultipart(REACT_APP_URL_AGENCY_SAVE, formData)
      .then((response) => {
        if (response?.data?.status === 200) {
          this.props.setCleanLogo();
          this.setState(
            () => ({
              announcementBar: (
                <div className="announcement-bar success">
                  <p>
                    <Translate id="com.tempedge.msg.label.newagency" />
                  </p>
                </div>
              ),
              page: 1
            })
          );
          this.props.reset("CreateNewAgency");    //Reset form fields all to empty
        }else{
            // Validation Failed
            this.setState(() => ({
              announcementBar: (
                <div className="announcement-bar fail">
                  <p>
                    <Translate
                      id={
                        response?.data?.message
                      }
                    />
                  </p>
                </div>
              ),
            }));
        }
      }).catch((err) => {
         // Validation Failed
         this.setState(() => ({
          announcementBar: (
            <div className="announcement-bar fail">
              <p>
                <Translate
                  id="com.tempedge.error.undefine"
                />
              </p>
            </div>
          ),
        }));
      });
  }




  componentWillUnmount() {
    this.props.reset("CreateNewAgency");    //Reset form fields all to empty
    this.props.setCleanLogo();
    this.resetEntireForm();
  }

  render() {
    let { page, steps } = this.state;

    if(this.state.loading)
        return <Loader/>;
    return (
      <div className="wizard-create-agency">
        <Stepper
          steps={steps}
          activeStep={page - 1}
          activeColor="#eb8d34"
          completeColor="#8cb544"
          defaultBarColor="#eb8d34"
          completeBarColor="#8cb544"
          barStyle="solid"
          circleFontSize={16}
        />
        <>
          {page === 1 && <WizardCreateNewAgencyrFirstPage resultBar={this.state.announcementBar} onSubmit={this.nextPage} {...this.props} />}
          {page === 2 && <WizardCreateNewAgencySecondPage resultBar={this.state.announcementBar} previousPage={this.previousPage} onSubmit={this.nextPage} {...this.props} />}
          {page === 3 && <WizardCreateNewAgencyThirdPage resultBar={this.state.announcementBar} previousPage={this.previousPage} onSubmit={this.nextPage} {...this.props} />}
          {page === 4 && <WizardCreateNewAgencyFourthPage resultBar={this.state.announcementBar} previousPage={this.previousPage} onSubmit={this.nextPage} {...this.props} />}
          {page === 5 && <WizardCreateNewAgencyFifthPage resultBar={this.state.announcementBar} previousPage={this.previousPage} onSubmit={this.nextPage} {...this.props} />}
          {page === 6 && <WizardCreateNewAgencySixthPage resultBar={this.state.announcementBar} previousPage={this.previousPage} onSubmit={this.nextPage} {...this.props} />}
          {page === 7 && <WizardCreateNewAgencySeventhPage resultBar={this.state.announcementBar} previousPage={this.previousPage} onSubmit={this.nextPage} {...this.props} />}
          {page === 8 && <WizardCreateNewAgencyEighthPage resultBar={this.state.announcementBar} previousPage={this.previousPage} onSubmit={this.onSubmit} {...this.props} />}
        </>
      </div>
    );
  }
}

CreateNewAgency.propTypes = {
  getList: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    logo: state.tempEdge.logo
  };
};

export default connect(mapStateToProps, { getList, reset, setCleanLogo })(CreateNewAgency);
