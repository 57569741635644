import { Translate } from 'react-localize-redux';
import moment from 'moment';

export const AttendanceTableHeader = ({ findDate }) => {
   return (
      <tr>
         <th className="table-header-left text-center w-10">
            <Translate id={'com.tempedge.msg.label.name'} />
         </th>
         {Object.keys(findDate).map((currentDay, index) => {
            const day = moment().day(findDate[currentDay].day).format('dddd').toLowerCase();
            return (
               <th key={index} className="table-header-mid text-center w-10">
                  <Translate id={`com.tempedge.msg.label.${day}`} />
                  <br></br>
                  {findDate[currentDay].date ? findDate[currentDay].date : ''}
               </th>
            );
         })}
         <th className="table-header-mid text-center w-5">
            <Translate id={'com.tempedge.msg.label.attendance'} />
         </th>
      </tr>
   );
};

export default AttendanceTableHeader;
