import React, { useState, useEffect, useRef,useMemo } from "react";
import { useSelector } from "react-redux";
import UploadFile from "../../../components/common/UploadFile/UploadFile";
import DropdownList from "../../../components/common/Dropdown/DropdownList";
import { withLocalize } from "react-localize-redux";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import Modal from "../../../Modals/Modal/Modal";
import DeparmentTables from "../../../components/common/DepartmentTables/DepartmentTables";
import Loader from "../../../components/common/Loader/Loader";
import InfoModal from "../../../components/common/Modal/InfoModal";
import { Formik } from "formik";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

//services
import OutcomeBar from "../../../components/common/OutcomeBar";
import TempEdgeApi from "../../../services/TempEdgeApi";
import {
  REACT_APP_URL_OFFICE_FIND_ALL,
  REACT_APP_URL_VALIDATE_REPORT,
} from "../../../components/common/Routes/URLs";

const validate = (values) => {
  const errors = {};
  if (!values.company) errors.company = "Required";
  if (!values.file) errors.file = "Required";
  if (!values.office) errors.office = "Required";
  return errors;
};

function WizardUploadFile(props) {
  const { promiseInProgress } = usePromiseTracker();

  
  const [offices, setOffices] = useState([]);
  const [message, setMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const formikRef = useRef();

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  const clientsListRedux = useSelector(state => {
    return state.tempEdge.clientList;
	});
	
	const clientsList = useMemo(() => clientsListRedux, [clientsListRedux])  


  const findDataInitial = async () => {
    try {
      const { data: offices } = await TempEdgeApi.post({
        url: REACT_APP_URL_OFFICE_FIND_ALL,
        payload: { page: 0, size: 50, filterBy: {} },
      });

      if (offices?.status === 200) setOffices(offices.result.data.content);
		} catch (error){
			setMessage(error)
		}	
  };

  useEffect(() => {
     trackPromise(findDataInitial());
  }, []);

	const handleSubmit = async (values) => {
		let typeExtension=(/[.]/.exec(values.file.name)) ? /[^.]+$/.exec(values.file.name)[0] : undefined;
		let formData = new FormData();
		let stringData = JSON.stringify({
			orgId: JSON.parse(localStorage.getItem('agency')).organizationEntity?.orgId,
			clientId: values.company.clientId
		});
		let blob = new Blob([stringData], { type: "application/json" });
		formData.append("clientEntity", blob);
		blob = new Blob([JSON.stringify({
			officeId: values.office.officeId
		})], { type: "application/json" });
		formData.append("report", values.file);
		formData.append("fileType", typeExtension);
		formData.append('officeEntity', blob);
		try {
			const {data} = await TempEdgeApi.postMultipart({
				url: REACT_APP_URL_VALIDATE_REPORT,
				payload: formData
			});
			if (data.status === 200 && data.code === 'TE00') {
				props.confirmation({
					form: values,
					response: data.result
				});
			}else {
				setData(values);
				setMessage(data);
			}
		} catch (e) {
			setMessage(e);
		}
  }

  const btns = (
    <div className="row py-4">
      <div className="col-md-5 offset-md-1">
        <button
          type="button"
          className="btn btn-default btn-block register-save-btn previous"
          onClick={() => {
            if (formikRef.current != null) formikRef.current.resetForm();
            setMessage(null);
          }}
        >
          {props.translate("com.tempedge.msg.label.cancel")}
        </button>
      </div>
      <div className="col-md-5">
        <button
          type="submit"
          className="btn btn-primary btn-block register-save-btn next"
          onClick={() => {
            if (formikRef.current) formikRef.current.handleSubmit();
          }}
        >
          {props.translate("com.tempedge.msg.label.upload")}
        </button>
      </div>
    </div>
  );

  if (promiseInProgress) return <Loader />;

  return (
    <ContainerBlue
      title="com.tempedge.msg.label.upload"
      btns={btns}
      width="40%"
    >
      {message && <OutcomeBar response={message} />}
      <div className="d-flex flex-column align-items-center">
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={
            data || {
              company: clientsList.length === 1 ? clientsList[0] : "",
              office: "",
              file: null,
            }
          }
          validate={validate}
          onSubmit={(values) => {
            trackPromise(handleSubmit(values));
          }}
        >
          {(formik) => (
            <>
              <div className="form-group col-12">
                <label className="text-left label-p">
                  {props.translate("com.tempedge.msg.label.company")}
                </label>
                {clientsList.length > 1 && (
                  <DropdownList
                    className="form-control"
                    isInvalid={formik.errors.company ? true : false}
                    data={clientsList}
                    name="client"
                    textField="clientName"
                    valueField="clientId"
                    value={{ clientId: formik.values.company.clientId }}
                    onChange={(value) => {
                      formik.setFieldValue("company", value);
                    }}
                  />
                )}
                {clientsList.length === 1 && (
                  <InfoModal
                    value={clientsList[0].clientName}
                    isModal={true}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                )}
              </div>
              {clientsList.length >0 && (
                <>
                  <div className="form-group col-12">
                    <label className="text-left label-p">
                      {props.translate("com.tempedge.msg.label.office")}
                    </label>
                    <DropdownList
                      className="form-control"
                      isInvalid={formik.errors.office ? true : false}
                      data={offices}
                      name="office"
                      textField="name"
                      valueField="officeId"
                      value={{ officeId: formik.values.office.officeId }}
                      onChange={(value) => {
                        formik.setFieldValue("office", value);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <UploadFile
                      title="com.tempedge.msg.label.upload"
                      accept=".xls, .xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onFileSelectSuccess={(file) =>
                        formik.setFieldValue("file", file)
                      }
                      showTag={false}
                    />
                    {formik.errors.file && (
                      <div className="alert alert-danger">choose a file</div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </Formik>
      </div>
      <Modal
        open={showModal}
        content={<DeparmentTables clientSelected={clientsList[0]} />}
        onClose={() => setShowModal((showModal) => !showModal)}
        modalSize={"modal-sm"}
      />
    </ContainerBlue>
  );
}

export default withLocalize(WizardUploadFile);
