import React from 'react';
import { Translate } from 'react-localize-redux';

export const ViewUserFields = ({ tag, field }) => {
return(
  <React.Fragment>
    <div className="col-5">
      <label className="control-label">
        <Translate id={tag} />
      </label>
    </div>
    <div className="col-7">
      <label className="label-view-data-horizontal ">
        {field}
      </label>
    </div>
  </React.Fragment>
);
}