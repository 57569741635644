import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import moment from 'moment';
export default class ExcelManager {

    constructor() {
      this.agency = JSON.parse(window.localStorage.getItem('agency'))?.organizationEntity?.organizationName;
    }

    export(data,totals){    
   

        if(data === undefined || data === null || data.length === 0)
           return;
        let ExcelJSWorkbook = new ExcelJS.Workbook();
        let worksheet = ExcelJSWorkbook.addWorksheet('TimeEntry');
        worksheet.columns = this.createHeader(data);
        data.forEach(item => {
          worksheet.addRow(item);
        });        
        worksheet.addRow(totals);     

        this.customStyle(worksheet);
        this.createFooter(worksheet);
        ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
          const now  = moment().format('YYYY_MM_DD_hh_mm_ss');
          const name = this.agency ? `${this.agency}_${now}` : `TimeEntry_${now}`;
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${name}.xlsx`
          );
        });
    }

    createFooter(worksheet) {
      let letter = String.fromCharCode(96 + worksheet.columnCount);
      letter = letter.toUpperCase();
      const row = worksheet.rowCount + 1
      worksheet.mergeCells(`A${row}:${letter}${row}`);
      const cell = worksheet.getCell(`A${row}`);
      cell.alignment = { horizontal : "center", vertical : "middle"}
      cell.height = 32.5;
      cell.value = `Created By TempEdge - ${moment().format('YYYY-MM-DD hh:mm:ss')}`;
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'3eb7e9'}
      };
    }
    
    customStyle(worksheet){
      const row = worksheet.getRow(1);
        row.eachCell((cell, colNumber) => {
          cell.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'3eb7e9'}
          };
          cell.alignment = { horizontal : "center", vertical : "center"}
          cell.font = { name: 'Comic Sans MS', family: 4, size: 12, bold: true };
        });
        worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
          row.height = 32.5;
          row.eachCell({ includeEmpty: true },(cell, colNmber) => {
            if(!cell.value || cell.value === 'null')
              cell.value = '';
            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            }
            cell.alignment = { horizontal : "center", vertical : "middle"}
            if(rowNumber % 2 === 0)
              cell.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'e9ecef'}
              };
          })
        });
    }

    createHeader(data) {
        let header = [];
        if(data === undefined || data === null || data.length === 0)
           return header;
        for(const property in data[0]){
          header.push({
            header: property.toUpperCase(),
            key : property,
            width : property.length + 8,
          });
        }
        return header;
    }
}