import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputBox from '../../../components/common/InputBox/InputBox.js';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { push } from 'connected-react-router';
import Validate from '../../Validations/Validations';
import DropdownList from '../../../components/common/Dropdown/DropdownList.js';

class WizardCreateNewAgencySixthPage extends Component {
  constructor(props) {
    super(props);

    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  }

  state = { mounted: false };

  componentDidMount = async () => {
    this.setState({
      mounted: true
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/registerAgency/${this.props.activeLanguage.code}`);
      ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
    }
  }

  renderWeekdays() {
    const { translate } = this.props;
    return [
      { day: translate('com.tempedge.msg.label.monday'), value: '1' },
      { day: translate('com.tempedge.msg.label.tuesday'), value: '2' },
      { day: translate('com.tempedge.msg.label.wednesday'), value: '3' },
      { day: translate('com.tempedge.msg.label.thursday'), value: '4' },
      { day: translate('com.tempedge.msg.label.friday'), value: '5' },
      { day: translate('com.tempedge.msg.label.saturday'), value: '6' },
      { day: translate('com.tempedge.msg.label.sunday'), value: '0' }
    ];
  }

  renderYesOrNo(){
    const { translate } = this.props;
    return[
      {option:translate('com.tempedge.msg.label.yes'),value:true},
      {option:translate('com.tempedge.msg.label.no'),value:false}
    ]
  }

  render() {
    const { translate } = this.props;
    const weekdays = this.renderWeekdays();
    const employeeID=this.renderYesOrNo();
    return (
      <div className="wizard-wrapper">
        <h2 className="text-center page-title-agency">{translate('com.tempedge.msg.label.newagencyregistration')}</h2>
        <form onSubmit={this.props.handleSubmit} className="panel-body form-horizontal center-block register-form-agency" style={{ paddingBottom: '0px' }}>
          <div className="form-group row row-agency-name">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2">
                  <label className="control-label pull-right" style={{ paddingTop: 8 }}>
                    {translate('com.tempedge.msg.label.agencyname')}
                  </label>
                </div>
                <div className="col-md-8" style={{ paddingLeft: 0, paddingRight: 71 }}>
                  <Field name="agencyname" type="text" placeholder={translate('com.tempedge.msg.label.agencyname')} category="agency" component={InputBox} />
                </div>
              </div>
            </div>
          </div>
          <div className="panel register-form-panel">
            <div className="panel-heading register-header">
              <h2 className="text-center">{translate('com.tempedge.msg.label.information')}</h2>
            </div>
          </div>

          <div className="register-form-panel-inputs">
            <div className="form-group register-form wizard-register-agency-form payroll-hours-validation row pt-5">
              <div className="col-md-6">
                <label className="control-label top-label-agency-form">{translate('com.tempedge.msg.label.payrollhours')}</label>
                <Field name="weekdaysdropdown1" 
                data={weekdays} 
                valueField="value" 
                textField="day" 
                category="agency" 
                component={DropdownList}
                    placeholder={translate('com.tempedge.msg.label.select')} 
                />
              </div>

              <div className="col-md-6">
                <label className="control-label top-label-agency-form">{translate('com.tempedge.msg.label.payrollvalidation')}</label>
                <Field name="weekdaysdropdown2" 
                data={weekdays} 
                valueField="value" 
                textField="day" 
                category="agency" 
                component={DropdownList}
                    placeholder={translate('com.tempedge.msg.label.select')} 
                />
              </div>

              <div className="col-md-6">
                <label className="control-label top-label-agency-form">{translate('com.tempedge.msg.label.fundingcompany')}</label>
                <Field name="fundingCompanydropdown" 
                data={this.props.funding_list} 
                valueField="fundingId" 
                textField="name" 
                category="agency" 
                component={DropdownList}
                    placeholder={translate('com.tempedge.msg.label.select')} 
                />
              </div>

              <div className="col-md-6">
                <label className="control-label top-label-agency-form">{translate('com.tempedge.msg.label.genemplid')}</label>
                <Field name="generateEmployeeID" 
                data={employeeID} 
                valueField="value" 
                textField="option" 
                category="agency" 
                component={DropdownList}
                    placeholder={translate('com.tempedge.msg.label.select')} 
                />
              </div>

            </div>
          </div>

          <div className="panel-footer register-footer panel-footer-agency-height-override">
            <div className="prev-next-btns-agency row">
              <div className="col-md-4 offset-md-2">
                <button type="button" className="btn btn-default btn-block register-save-btn previous" onClick={this.props.previousPage}>
                  {translate('com.tempedge.msg.label.back')}
                </button>
              </div>
              <div className="col-md-4">
                <button type="submit" className="btn btn-primary btn-block register-save-btn next" disabled={this.props.invalid || this.props.submiting || this.props.pristine}>
                  {translate('com.tempedge.msg.label.next')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

WizardCreateNewAgencySixthPage = reduxForm({
  form: 'CreateNewAgency',
  destroyOnUnmount: false,
  validate: Validate
})(WizardCreateNewAgencySixthPage);

let mapStateToProps = (state) => {
  return {
    funding_list: state.tempEdge.funding_list
  };
};

export default withLocalize(connect(mapStateToProps, { push })(WizardCreateNewAgencySixthPage));
