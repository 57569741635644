import {Component} from 'react';
import { withLocalize } from 'react-localize-redux';
import Report from "./Report";
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';


class DownloadReports extends Component {

   constructor(props){
      super(props);
      // language
      this.addTranslationsForActiveLanguage();
   }

   async addTranslationsForActiveLanguage() {
      await ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
   };

   async componentDidUpdate(prevProps) {
      const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
      if (hasActiveLanguageChanged) {
         await this.props.push(`/report/generate/${this.props.activeLanguage.code}`);
         this.addTranslationsForActiveLanguage();
      }
   }

   render(){
      return <Report />;
   }

}

export default  withLocalize(
    connect(null,{push})(DownloadReports)
);