import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import InputBox from '../../../components/common/InputBox/InputBox.js';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation.js';
import moment from 'moment';
import momentLocaliser from 'react-widgets-moment';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { push } from 'connected-react-router';
import Validate from '../../Validations/Validations';
import Datepicker from '../../../components/common/Datepicker/Datepicker.js';
import DropdownList from '../../../components/common/Dropdown/DropdownList.js';

momentLocaliser(moment);

class WizardCreateNewAgencyFirstPage extends Component {
  constructor(props) {
    super(props);
    this.addTranslationsForActiveLanguage();
  }

  state = { mounted: false, genders: [] };

  componentDidMount() {
    this.setState(() => ({
      mounted: true
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.props.push(`/registerAgency/${this.props.activeLanguage.code}`);
      this.addTranslationsForActiveLanguage();
    }
  }

  addTranslationsForActiveLanguage = async () => {
    ActiveLanguageAddTranslation(this.props.activeLanguage, this.props.addTranslationForLanguage);
  };

  render() {
    let signInRoute = `/auth/${this.props.activeLanguage.code}`;
    const { translate } = this.props;

    const genders = [
      { value: 0, label: translate('com.tempedge.msg.label.gender.male') },
      { value: 1, label: translate('com.tempedge.msg.label.gender.female') },
      { value: 2, label: translate("com.tempedge.msg.label.select") }
    ];

    return (
      <div className="wizard-wrapper">
        <h2 className="text-center page-title">{translate('com.tempedge.msg.label.sign_up')}</h2>
        <h3 className="text-center page-subtitle">{translate('com.tempedge.msg.label.sign_up.subtitle')}</h3>
        <div className="row" style={{ padding: "0 2.2rem" }}>
          <div className="col-12">{this.props.resultBar}</div>
        </div>
        <div className="panel register-form-panel">
          <div className="panel-heading register-header">
            <h2 className="text-center">{translate('com.tempedge.msg.label.accountinformation')}</h2>
          </div>
        </div>
        <div className="register-form-panel-inputs">
          <form onSubmit={this.props.handleSubmit} className="panel-body form-horizontal center-block register-form" style={{ paddingBottom: '0px' }}>
            <div className="form-group row">
              <div className="col-md-4">
                <label className="control-label">{translate('com.tempedge.msg.label.firstname')}</label>
                <Field
                  name="firstName"
                  type="text"
                  placeholder={translate('com.tempedge.msg.label.firstname')}
                  category="person"
                  maxlength="20"
                  onInput={({ target }) => (target.value = target.value.replace(/[^a-zA-ZÀ-ú]+/g, ''))}
                  component={InputBox}
                />
              </div>
              <div className="col-md-4">
                <label className="control-label">{translate('com.tempedge.msg.label.middlename')}</label>
                <Field
                  name="middleName"
                  type="text"
                  placeholder={translate('com.tempedge.msg.label.middlename')}
                  onInput={({ target }) => (target.value = target.value.replace(/[^a-zA-ZÀ-ú]+/g, ''))}
                  category="person"
                  maxlength="30"
                  component={InputBox}
                />
              </div>
              <div className="col-md-4">
                <label className="control-label">{translate('com.tempedge.msg.label.lastname')}</label>
                <Field
                  name="lastName"
                  type="text"
                  placeholder={translate('com.tempedge.msg.label.lastname')}
                  category="person"
                  onInput={({ target }) => (target.value = target.value.replace(/[^a-zA-ZÀ-ú]+/g, ''))}
                  maxlength="30"
                  component={InputBox} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-4">
                <label className="control-label">{translate('com.tempedge.msg.label.gender')}</label>
                <Field
                  id="genderDropdown"
                  name="gender"
                  data={genders}
                  requireTranslate
                  valueField="value"
                  textField="label"
                  category="person"
                  component={DropdownList}
                />
              </div>
              <div className="col-md-4">
                <label className="control-label">{translate('com.tempedge.msg.label.email')}</label>
                <Field
                  name="email"
                  type="email"
                  placeholder={translate('com.tempedge.msg.label.email')}
                  category="person"
                  component={InputBox} />
              </div>
              <div className="col-10 col-md-4 col-lg-4">
                <label className="control-label">{translate('com.tempedge.msg.label.birthday')}</label>
                <Field
                  name="birthday_"
                  type="text"
                  category="person"
                  customClass="form-control tempEdge-input-box"
                  maxDate={moment(new Date()).subtract(18, 'years').toDate()}
                  component={Datepicker}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-4">
                <label className="control-label">{translate('com.tempedge.msg.label.username')}</label>
                <Field
                  name="username"
                  type="text"
                  placeholder={translate('com.tempedge.msg.label.username')}
                  category="person"
                  component={InputBox}
                  onInput={({ target }) => (target.value = target.value.toUpperCase())}
                />
              </div>

              <div className="col-md-4">
                <label className="control-label">{translate('com.tempedge.msg.label.password')}</label>
                <Field name="initialpassword" type="password" placeholder={translate('com.tempedge.msg.label.password')} category="person" component={InputBox} />
              </div>

              <div className="col-md-4">
                <label className="control-label">{translate('com.tempedge.msg.label.confirmpassword')}</label>
                <Field name="confirmpassword" type="password" placeholder={translate('com.tempedge.msg.label.confirmpassword')} category="person" component={InputBox} />
              </div>
            </div>
            <div className="form-group prev-next-btns">
              <div className="col-md-6 offset-md-3">
                <button type="submit" className="btn btn-primary btn-block register-btn next" disabled={this.props.invalid || this.props.submiting || this.props.pristine}>
                  {translate('com.tempedge.msg.label.next')}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="panel-footer register-footer">
          <div className="pull-right">
            <span className="no-account-query">{translate('com.tempedge.msg.label.account_exists')}</span>
            <span className="sign-in-link">
              <Link className="create-account" to={signInRoute}>
                {translate('com.tempedge.msg.label.sign_in')}
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

WizardCreateNewAgencyFirstPage = reduxForm({
  form: 'CreateNewAgency', //                 <------ form name
  destroyOnUnmount: false, //        <------ preserve form data
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: Validate
})(WizardCreateNewAgencyFirstPage);

export default withLocalize(connect(null, { push })(WizardCreateNewAgencyFirstPage));
