import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  removeErrorField,
  setErrorField,
} from "../../../Redux/actions/tempEdgeActions";
import { validateEmail } from "../../../utils/ReGexs";

let renderInput = (formProps) => {

  let errorClass =
    formProps.input.name === "email" && formProps.input.value !== ""
      ? `${formProps.meta.error ? "has-error" : ""}`
      : `${formProps.meta.error && formProps.meta.touched ? "has-error" : ""}`;
  let input = null;

  let { value, name } = formProps.input;
  let phoneNumRegX = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/g);
  let timeRegX = new RegExp(
    /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/g
  );

  if (name === "username") {
    value = formProps.input.value;
  } else if (value !== "undefined") {
    if (name === "employeeid") {
      value = value.slice(0, 8);
    } else if (name === "zip") {
      value = value.replace(/[^\d]/g, "").slice(0, 8);
    }
  } else if (timeRegX.test(value)) {
    value = value?.toString();
  } else if (!isNaN(value) && value !== "") {
    value = parseInt(value);
  } else if (phoneNumRegX.test(value)) {
    value = value?.toString();
  } else if (name === "email") {
    if (!validateEmail(value)) {
      errorClass = "has-error";
    }
  }

  if (formProps.input.name === "markupClient" || formProps.input.name === "otMarkupClient") {
    if (parseFloat(value) < 0) {
      value = 0;
    }
  }

  if (formProps.type === "textarea")
    input = (
      <textarea
        className="form-control tempEdge-input-box"
        placeholder={formProps.placeholder}
        {...formProps.input}
        value={typeof value === "string" ? value.toUpperCase() : value}
        autoComplete="new-password"
        maxLength={formProps.maxlength}
        style={{ minHeight: "111px" }}
        onInput={formProps.onInput}
      />
    );
  else if (formProps.type === "password") {
    input = (
      <input
        className="form-control tempEdge-input-box"
        type="password"
        rows="2"
        placeholder={formProps.placeholder}
        {...formProps.input}
        autoComplete="new-password"
      />
    );
  } else {
    if (formProps.active !== "disabled")
      input = (
        <input
          className={`form-control tempEdge-input-box ${formProps.customClass}`}
          type="text"
          maxLength={formProps.maxlength}
          pattern={formProps.pattern}
          placeholder={formProps.placeholder}
          {...formProps.input}
          value={typeof value === "string" ? value.toUpperCase() : value}
          onInput={formProps.onInput}
          autoComplete="new-password"
        />
      );
    else
      input = (
        <input
          className={`form-control tempEdge-input-box ${formProps.customClass}`}
          type="text"
          maxLength={formProps.maxlength}
          placeholder={formProps.placeholder}
          {...formProps.input}
          value={typeof value === "string" ? value.toUpperCase() : value}
          autoComplete="off"
          disabled
        />
      );
  }

  if (formProps.meta.form === "NewEmployee") {
    if (
      formProps.meta.error &&
      formProps.meta.invalid &&
      !formProps.meta.active &&
      formProps.meta.touched &&
      typeof formProps.errorFields !== "undefined"
    ) {
      let found = formProps.errorFields.indexOf(formProps.input.name);
      if (found === -1) {
        formProps.setErrorField(formProps.input.name);
      }
    } else if (
      typeof formProps.meta.error === "undefined" &&
      !formProps.meta.invalid &&
      !formProps.meta.active &&
      formProps.meta.touched &&
      typeof formProps.errorFields !== "undefined"
    ) {
      let found = formProps.errorFields.indexOf(formProps.input.name);
      if (found > -1) {
        formProps.removeErrorField(formProps.input.name);
      }
    }
  }

  return <div className={errorClass}>{input}</div>;
};

let InputBox = (props) => {
  return renderInput(props);
};

InputBox.propTypes = {
  setErrorField: PropTypes.func.isRequired,
  removeErrorField: PropTypes.func.isRequired,
};

let mapStateToProps = (state) => {
  return {
    errorFields: state.tempEdge.errorFields,
  };
};

export default connect(mapStateToProps, { setErrorField, removeErrorField })(
  InputBox
);
