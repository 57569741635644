import { payrollValidationTypes } from "../types/payrollValidationTypes"

export const addPayrollValidation = (payload) => ({
      type: payrollValidationTypes.ADD_PAYROLL_VALIDATION,
      payload
})
export const addAbsentValidation = (payload) => ({
      type: payrollValidationTypes.ADD_PAYROLL_ABSENT_VALIDATION,
      payload
})

export const changeValuesValidation = (payload) => ({
      type: payrollValidationTypes.CHANGE_VALUES_PAYROLL,
      payload
})

export const resetDataAbsent = () => ({
      type: payrollValidationTypes.RESET_ENTIRE_STATE
})

export const addHursValidation = (payload) => ({
      type: payrollValidationTypes.ADD_HOURS_PAYROLL_VALIDATION,
      payload
})

export const changeHoursPayrollValidation = (payload) => ({
      type: payrollValidationTypes.CHANGE_HOURS_PAYROLL,
      payload
})

export const changePayRateValidation = (payload) => ({
      type: payrollValidationTypes.CHANGE_PAYRATE_VALIDATION,
      payload
})

export const changeHoursPayrollValidationLocked = (payload) => ({
      type: payrollValidationTypes.CHANGE_HOURS_PAYROLL_LOCKED,
      payload
})

export const requestDayValidation = (payload) => ({
      type: payrollValidationTypes.REQUEST_LIST_SAVING,
      payload
})

export const changeWeekAttendanceValue = (payload) => ({
      type: payrollValidationTypes.CHANGE_WEEK_ATTENDANCE_VALUE,
      payload
});
export const changeWeekPayRateValue = (payload) => ({
      type: payrollValidationTypes.CHANGE_WEEK_PAYRATE_VALUE,
      payload
});
export const cancelPayRateValidation = (payload) => ({
      type: payrollValidationTypes.CANCEL_WEEK_PAYRATE_VALUES,
      payload
});

export const addResultPayrollValidation = (payload) => ({
      type: payrollValidationTypes.SAVE_PAYROLL_RESULT_VALIDATION,
      payload
});

export const changeState = (payload) => ({
      type: payrollValidationTypes.CHANGE_STATE,
      payload
});
export const changeStates = (payload) => ({
      type: payrollValidationTypes.CHANGE_STATE,
      payload
});

export const clearProp = (payload) => ({
      type: payrollValidationTypes.CLEAR_PROP,
      payload
});


export const changeHoursLock=(payload)=>({
      type:payrollValidationTypes.changeTimeLock,
      payload
})