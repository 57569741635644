export const dailyListTypes = {
  ADD_EMPLOYEE_IN_TIMESHEET: 'ADD_EMPLOYEE_IN_TIMESHEET',
  REMOVE_EMPLOYEE_IN_TIMESHEET: 'REMOVE_EMPLOYEE_IN_TIMESHEET',
  REMOVE_EMPLOYEE_IN_TIMESHEET_ID: 'REMOVE_EMPLOYEE_IN_TIMESHEET_ID',
  LOAD_VALUES_TO_EMPLOYEE_TIMESHEET: 'LOAD_VALUES_TO_EMPLOYEE_TIMESHEET',
  CHANGE_EMPLOYEE_VALUE: 'CHANGE_EMPLOYEE_VALUE',
  DAILY_LIST_SAVING: 'DAILY_LIST_SAVING',
  FIND_LIST_BY_DAY: 'FIND_LIST_BY_DAY',
  DELETE_DAY: 'DELETE_DAY',
  ADD_TO_REMOVED_EMPLOYEES_LIST: 'ADD_TO_REMOVED_EMPLOYEES_LIST',
  CLEAR_PROP: 'CLEAR_PROP',
  CHANGE_MAIL_TO: 'CHANGE_MAIL_TO',
  CHANGE_MAIL_CC: 'CHANGE_MAIL_CC',
  TOGGLE_SEND_EMAIL: 'TOGGLE_SEND_EMAIL',
  RESET_ENTIRE_STATE: 'RESET_ENTIRE_STATE',
  DISABLE_SAVE_BUTTON: 'DISABLE_SAVE_BUTTON'
};
