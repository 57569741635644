let getCountryList = (countryList = []) => {
  let country_list = [];

  countryList.forEach((country) => {
    country_list.push({
      name: country.name,
      countryId: country.countryId
    });
  });

  return {
    countryList,
    country_list
  };
};

let getRegionList = async (countryList, selectedCountry) => {
  let regions = [];
  let regions_list = [];

  await countryList.forEach((country) => {
    if (country.name === selectedCountry) {
      regions = country.regionEntityList;
    }
  });

  regions.forEach((region) => {
    regions_list.push({
      name: region.name,
      regionId: region.regionId,
      shortCode: region.shortCode
    });
  });

  return regions_list;
};

let parseCountryRegion = {
  getCountryList,
  getRegionList
};

export default parseCountryRegion;
