/**
   * Change index of every array element by the key specified
   * @param {Array.Object} array list of objects
   * @param {String} key the key to be indexed
   */

export const keyBy = (array, key) => array.reduce((accumulator, element) => {
  accumulator[element[key]] = element;
  return accumulator;
});

/** 
  * @param {Array} list lista de datos 
  * @param {String} returnField campo a retornar de la lista
  * @param {String} findField campo a buscar de la lista (campo a comparar)
  * @param {*} findValue valor a encontrar (valor de comparación)
  */

export const mapList = (list, returnField, findField, findValue) => {
  if (list) {
    for (let index = 0; index < list.length; index++) {
      if (list[index][findField] === findValue) {
        return list[index][returnField];
      }
    }
  }
};

export const mapCountry = (userCountry, returnField, findField, countryRegionList) => {
  let clientCountry = countryRegionList.filter(country => country.countryId === userCountry);

  if (clientCountry && countryRegionList) {
    return mapList(countryRegionList, returnField, findField, userCountry);
  }
}

export const mapRegion = (userStateId, countryId, returnField, findField, countryRegionList) => {
  let selectedCountry = countryRegionList.filter(country => country.countryId === countryId);

  if (selectedCountry.length > 0) {
    let regions = selectedCountry[0].regionEntityList;

    if (userStateId && regions) {
      return mapList(regions, returnField, findField, userStateId)
    }
  }
}