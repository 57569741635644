import { push } from 'connected-react-router';
import moment from "moment";
import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import Modal from "../../../Modals/Modal/Modal";
import { changeState, clearProp, resetDataAbsent } from "../../../Redux/actions/payrollValidationActions";
import { clearTempedgeStoreProp, tempedgeAPI } from "../../../Redux/actions/tempEdgeActions";
import { resetEntireState } from "../../../Redux/actions/timeEntryActions";
import { timeEntryTypes } from "../../../Redux/types/timeEntryTypes";
import "../../../assets/styles/components/TimeEntry.css";
import ContainerBlue from "../../../components/common/Container/ContainerBlue";
import Loader from "../../../components/common/Loader/Loader";
import OutcomeBar from "../../../components/common/OutcomeBar";
import { REACT_APP_URL_PAYROLL_SAVE_WEEK } from "../../../components/common/Routes/URLs";
import Switch from "../../../components/common/Switch/Switch";
import { store } from "../../../store/store";
import ExcelManager from "../../../utils/helpers/excelManager";
import AbsentPayrollForWeekAttendance from "../PayrollByAttendance/AbsentPayrollForWeekAttendance";
import PayrollByWeekAttendance from "../PayrollByAttendance/PayrollByWeekAttendance";
import SearchForm from "../SearchForm";
import AbsentValidation from "./PayrollByWeekValidation/AbsentValidation";
import PayrollAbsentValidation from "./PayrollByWeekValidation/PayrollAbsentValidation";
import PayrollByWeekValidation from "./PayrollByWeekValidation/PayrollByWeekValidation";
import PayrollResultValidation from "./PayrollByWeekValidation/PayrollResultValidation";


class PayrollValidation extends Component {

  static onError(data) {
    return <OutcomeBar response={data} />;
  }

  static onSuccess(data) {
    return <OutcomeBar response={data} />;
  }
 
  constructor(props) {
    super(props);
    this.state = {
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      timeEntryTable: "",
      resultBar: null,
      dayResul: false,
      isSameHour: false,
      download: false,
      loading: false,
      validator: true,
      endPayrollValidation: false,
      endPayrollValidationOk: false,
      attendanceResult: false,
      payrollTimeEntry: [],
      clientReportConf:0,
      openAttendanceModal: false
    };
    this.base = {
      orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
        ?.orgId,
      timeEntryTable: "",
      dayResul: false,
      isSameHour: false,
      download: false,
      loading: false,
      validator: true,
      endPayrollValidation: false,
      endPayrollValidationOk: false,
      attendanceResult: false,
      payrollTimeEntry: [],
      clientReportConf:0,
      openAttendanceModal: false
    };
    this.addTranslationsForActiveLanguage();
  }

  componentDidMount() {
    this.props.resetEntireState();
    this.props.resetDataAbsent()
  }

 setReportConfg=(item)=>{
    this.setState({clientReportConf:item})
  }

  addTranslationsForActiveLanguage() {
    const { activeLanguage } = this.props;
    const state = store.getState();
    if (!activeLanguage || !state.tempEdge.lang) {
      return;
    }
    if (activeLanguage.code === 'es') {
      this.props.addTranslationForLanguage(state.tempEdge.lang.spanish, activeLanguage.code)
    } else {
      this.props.addTranslationForLanguage(state.tempEdge.lang.english, activeLanguage.code)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;
    if (hasActiveLanguageChanged) {
      this.props.push(`/payroll/payrollvalidation/${this.props.activeLanguage.code}`);
      this.addTranslationsForActiveLanguage();
    }
    if(prevProps.weekday !== this.props.weekday){
    this.setState({ endPayrollValidation: false, endPayrollValidationOk: false,isSameHour:false,dayResul:false, openAttendanceModal: false })
    }
  }

  reset() {
    this.setState(this.base)
    this.props.changeState({ key: "customDate", value: null })
    this.props.changeState({ key: "payrollWeekValidation", value: [] })
    this.props.changeState({ key: "absentValidation", value: [] })
    this.props.changeState({ key: "absentEmployeesWeek", value: [] })
    this.props.changeState({ key: "payrollResultWeekValidation", value: [] })   
    this.props.resetDataAbsent()
    this.setState({ endPayrollValidation: false, endPayrollValidationOk: false,isSameHour:false,dayResul:false, openAttendanceModal: false })
  }

  // este es el mensaje que envia la data el cual le paso por un dispatch de change state , cambia este estado resultbarmessage
  renderResultBar() {
    const { resultBarMessage } = this.props;
    if (resultBarMessage) {
      return this.constructor.onSuccess(resultBarMessage);
      }    
    return null;
  }

  async saveTimeByWeek() {
    const { tempedgeAPI, requestDay, payrollWeekValidation,dateStart } = this.props
    
    const { orgId, endPayrollValidationOk } = this.state;
    this.setState({
      loading: true,
    });
    const requestObject = [];
    requestDay.forEach((data) => {
      requestObject.push({
        dateList: moment(data.dateList, "YYYY-MM-DD", true).format("YYYY-MM-DD"),
        clientId: data.clientId
      });
    });

    let numDay = moment(dateStart).day();            
    let listPayrollEnd = payrollWeekValidation.map((employee) => {
      let j = numDay;
      for (let i = 0; i < 7; i++) {
        let weekDay = moment().day(j).format('ddd').toLowerCase();
        if (employee[weekDay]) {
          if(employee[weekDay].lockPayroll){
            employee[weekDay]=null
          }else{
            if ((employee[weekDay].totalRegHour !== 0 || employee[weekDay].totalOtHour !== 0)) {
              employee[weekDay].timeIn = null
               employee[weekDay].timeOut = null
               employee[weekDay].lockPayroll=endPayrollValidationOk
            }   
            if(employee[weekDay].totalRegHour === 0 && employee[weekDay].totalOtHour === 0)  {
              employee[weekDay].lockPayroll=endPayrollValidationOk
            }   
          }
        }
        j++
        j = j % 7
      }
      return employee
    })
  
    const dateList = requestDay[0]?.dateList
    const employeeSave = listPayrollEnd.map((payroll) => {
      return {...payroll, dateList }
    })

    const request = {
      orgId,
      dateList: requestObject[0]?.dateList ? requestObject[0]?.dateList : "",
      clientId: Number(requestObject[0]?.clientId),
      parameterKey: "com.tempedge.msg.menu.payroll",
      payrollEntityList: employeeSave,
    };

    const response = await tempedgeAPI(
      REACT_APP_URL_PAYROLL_SAVE_WEEK,
      request,
      timeEntryTypes.TIMEWEEK_LIST_SAVING
    )
    if (response) {
      this.showSavingResponseWeek();
      this.setState({
        loading: false,
      });
    }
    this.setState({ isSameHour: false, attendanceResult: false, dayResul: false });
  }

  showSavingResponseWeek() {
    const { clearProp, changeState, saveTimeWeekListResponse, absentEmployeesWeek,totalWeekExcel } = this.props;
    if (saveTimeWeekListResponse && saveTimeWeekListResponse.data) {
      const { code} = saveTimeWeekListResponse.data;
      if (code === "TE00" || code === "TE-E08") {
        if (this.state.download === true) {
          const excel = new ExcelManager();
          excel.export(absentEmployeesWeek,totalWeekExcel);
        }
        changeState({
          key: "resultBarMessage",
          value: saveTimeWeekListResponse.data,
        });
        window.scrollTo(0, 100);
        clearProp();
        this.setState({
          isSameHour: false,
          download: false,
        });
        changeState({key:"weekday", value:''});
        changeState({key:"shift", value:''});
        changeState({key:"client", value:''});
        changeState({key:"department", value:''});
        this.setState({ endPayrollValidation: false, endPayrollValidationOk: false })
      } else {
        changeState({
          key: "resultBarMessage",
          value: saveTimeWeekListResponse.data,
        });
      }
    } else {
      this.constructor.onError(saveTimeWeekListResponse);
    }
  }

  // esta funcion se activa cuando estamos en result y queremos volver a editar
  previousTable() {
    this.setState({ download: false, isSameHour: false, attendanceResult: false, dayResul: false });
    window.scrollTo(0, 100);
  }

  previousTableAttendance() {
    this.setState({ download: false, isSameHour: false, attendanceResult: true, dayResul: false });
    window.scrollTo(0, 100);
  }

  // esta funcion es para que el boton next la ejecute y pueda renderizar el componetne de resultado por dia o semana
  renderResult = () => {
    this.setState({ download: false, isSameHour: false, attendanceResult: false, dayResul: true });
    window.scrollTo(0, 100);
  };

  renderResultAttendance = () => {
    const { changeState } = this.props;
    changeState({
      key: "dayResul",
      value: false,
    });
    this.setState({ download: false, isSameHour: false, attendanceResult: true, dayResul: false });
    window.scrollTo(0, 100);
  };

  clearValuesBottons = () => {
    this.setState({ endPayrollValidation: false, endPayrollValidationOk: false , attendanceResult: false, dayResul: false })
  }

  render() {
    let nameCompany = `${JSON.parse(localStorage.getItem('agency')).user.firstName} ${JSON.parse(localStorage.getItem('agency')).user.lastName}`
    const { dayResul, attendanceResult, endPayrollValidation,endPayrollValidationOk,openAttendanceModal } = this.state
    const {
      isFindByWeek,
      payrollWeekValidation,
      absentValidation,
      translate,
      dateStart,
      tempEdge,
      weekActive
    } = this.props;   
  
    const btn = (
      <>
        {(payrollWeekValidation?.length > 0 || absentValidation?.length > 0) && !dayResul && !attendanceResult &&
          <div className="row py-4" style={{ justifyContent: 'space-evenly' }}>
            <div className="col-md-5" >
              <button
                type="button"
                className="btn btn-default btn-block register-save-btn previous"
                onClick={() => this.reset()}
              >
                {translate("com.tempedge.msg.label.cancel")}
              </button>
            </div>
            <div className="col-md-5 ">
              <button
                type="submit"
                className="btn btn-primary btn-block register-save-btn next"
                onClick={() => this.renderResultAttendance()} 
              >
                {translate("com.tempedge.msg.label.next")}
              </button>
            </div>
          </div>
        }
        {(payrollWeekValidation?.length > 0 || absentValidation?.length > 0) && !dayResul && attendanceResult &&
          <div className="row py-4">
            <div className="col-md-4 ">
              <button
                type="button"
                className="btn btn-default btn-block register-save-btn previous"
                onClick={() => this.reset()}
              >
                {translate("com.tempedge.msg.label.cancel")}
              </button>
            </div>
            <div className="col-md-4 ">
              <button
                type="button"
                className="btn btn-default btn-block register-save-btn previous"
                onClick={() => this.previousTable()} // todo en false para mostrar la tabla de hours validation la primera
              >
                {translate("com.tempedge.msg.label.previous")}
              </button>
            </div>
            <div className="col-md-4">
              <button
                type="submit"
                className="btn btn-primary btn-block register-save-btn next"
                onClick={() => this.renderResult()} //aqui pasa a la siguiente condicion dayresult tru y attendance false y muestra la el weekresult
              >
                {translate("com.tempedge.msg.label.next")}
              </button>
            </div>
          </div>
        }
        {(payrollWeekValidation?.length > 0 || absentValidation?.length > 0) && isFindByWeek && dayResul && !attendanceResult &&
          <div className="row py-4">
            <div className={weekActive?"col-md-4":"col-md-5 offset-md-1"}>
              <button
                type="button"
                className="btn btn-default btn-block register-save-btn previous"
                onClick={() => this.reset()}
              >
                {translate("com.tempedge.msg.label.cancel")}
              </button>
            </div>
            <div className={weekActive?"col-md-4":"col-md-5 "}>
              <button
                type="button"
                className="btn btn-default btn-block register-save-btn previous"
                onClick={() => this.previousTableAttendance()} // tranpsorte en true y dayresult en false
              >
                {translate("com.tempedge.msg.label.previous")}
              </button>
            </div>
           {weekActive && <div className="col-md-4" disabled={ payrollWeekValidation?.length <= 0 ? true:false}>
              <button
                type="submit"
                className="btn btn-primary btn-block register-save-btn save"
                onClick={(e) => {
                  if (!endPayrollValidationOk) {
                    this.setState({ openAttendanceModal: true })
                  } else {
                    this.saveTimeByWeek(e);
                  }
                }}
                disabled={!this.state.isSameHour}
              >
                {translate("com.tempedge.msg.label.save")}
              </button>
            </div>}
          </div>
        }
      </>
    );

    const attendanceModalBtns = (<>
    
      <div className="col">
        <button
          type="button"
          style={{minHeight:'0'}}
          className="btn btn-default btn-block register-save-btn previous"
          onClick={() => this.setState({ openAttendanceModal: false })}
        >
          {translate("com.tempedge.msg.label.cancel")}
        </button>
      </div>
      <div className="col">
        <button
          type="submit"
          style={{minHeight:'0'}}
          className="btn btn-primary btn-block register-save-btn save"
          onClick={(e) => this.saveTimeByWeek(e)}
          disabled={!this.state.isSameHour}
        >
          {translate("com.tempedge.msg.label.accept")}
        </button>
      </div>
      
  </>)

    const bottomsModal = (<>
    
        <div className="col">
          <button
            type="button"
            style={{minHeight:'0'}}
            className="btn btn-default btn-block register-save-btn previous"
            onClick={() => this.setState({ endPayrollValidation: false })}
          >
            {translate("com.tempedge.msg.label.cancel")}
          </button>
        </div>
        <div className="col">
          <button
            type="submit"
            style={{minHeight:'0'}}
            className="btn btn-primary btn-block register-save-btn save"
            onClick={() => this.setState({ endPayrollValidationOk: true, })}
            disabled={!this.state.isSameHour}
          >
            {translate("com.tempedge.msg.label.accept")}
          </button>
        </div>
        
    </>)

   
    if (this.state.loading) return <Loader />
    return (
      <ContainerBlue title="com.tempedge.msg.menu.payrollvalidation" btns={btn}>
        <div className="row ">
          <div className="col-12">{this.renderResultBar()}</div>
        </div>
        <div className="margin-bottom 3rem">
          <SearchForm 
          translate={translate} 
          tempEdge={tempEdge} 
          validator={this.state.validator} 
          setReportConfg={this.setReportConfg}
          clearValues={this.clearValuesBottons}          
          />
        </div>

        {!dayResul && payrollWeekValidation?.length > 0 && !attendanceResult && (
          <>
            <div className="time-entry__body">
              <PayrollByWeekValidation
                payrollWeekValidation={payrollWeekValidation}
                dateStart={dateStart}
                clientReportConf={this.state?.clientReportConf}
              />
            </div>
            {absentValidation.length > 0 && <div className="time-entry__body">
              <PayrollAbsentValidation
                dateStart={dateStart}
                absentValidation={absentValidation}
              />
            </div>}
          </>
        )}
        {
          // aqui va el componente de attendance
          <>
            {!dayResul && attendanceResult && payrollWeekValidation?.length > 0 && (
              <>
                <div className="time-entry__body">
                  <PayrollByWeekAttendance
                    payrollWeekValidation={payrollWeekValidation}
                    dateStart={dateStart}
                    translate={translate}
                  />
                </div>
                <div className="time-entry__body">
                  <AbsentPayrollForWeekAttendance
                    dateStart={dateStart}
                    absentValidation={absentValidation}
                  />
                </div>
              </>
            )}
          </>
        }

        {
          <>
            {payrollWeekValidation?.length > 0 && !attendanceResult && dayResul && (
              <>
                <div className="time-entry__body">
                  <PayrollResultValidation
                    payrollWeekValidation={payrollWeekValidation}
                    dateStart={dateStart}
                    translate={translate}
                    endPayrollValidation={endPayrollValidation}
                    clientReportConf={this.state.clientReportConf}
                  />
                </div>
                <div className="time-entry__body">
                  <AbsentValidation
                    dateStart={dateStart}
                    payrollWeekValidation={payrollWeekValidation}
                  />
                </div>
              </>
            )}
            {weekActive && dayResul && payrollWeekValidation?.length > 0 && isFindByWeek && (
              <div className="row time-entry__body">
                <div className="imput col-5" >
                  <p style={{ textAlign: "left" }}>
                    {translate('com.tempedge.msg.label.clickingconfirm1')} <b>{nameCompany}</b> { translate('com.tempedge.msg.label.clickingconfirm2')}
                  </p>
                </div>
                <div className="imput col-1 ">
                  <Switch
                    name="week"
                    checked={this.state.isSameHour}
                    onChange={(value) => {
                      this.setState({ isSameHour: value });
                    }}
                  />
                </div>
                {this.state.isSameHour && (
                  <>
                    <div className="imput col-2 " >
                      <p>
                        {translate('com.tempedge.msg.label.endpayroll')}
                      </p>
                    </div>
                    <div className="imput col-1 ">
                      <Switch
                        name="week"
                        checked={endPayrollValidation}
                        onChange={(value) => {
                          this.setState({ endPayrollValidation: value });
                          if(endPayrollValidationOk){
                            this.setState({ endPayrollValidationOk: false });
                          }
                        }}
                      />
                    </div>
                    <div className="imput col-2 " >
                      <p>{translate("com.tempedge.msg.label.download")}</p>
                    </div>
                    <div className="imput col-1 ">
                      <Switch
                        name="week"
                        checked={this.state.download}
                        onChange={(value) => {
                          this.setState({ download: value });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="row ">
             {!endPayrollValidationOk &&
                <Modal
                  content={translate('com.tempedge.msg.label.confirmendpayroll')}
                  title={translate('com.tempedge.msg.label.endpayroll')}
                  open={endPayrollValidation}
                  onClose={()=>this.setState({endPayrollValidation:false})}
                  modalSize={'modal-md'}
                  buttons={bottomsModal}
                />
              }
              {
                !endPayrollValidationOk && <Modal
                  content={translate('com.tempedge.msg.label.confirmsavehours')}
                  title={translate('com.tempedge.msg.label.warning')}
                  open={openAttendanceModal}
                  onClose={()=>this.setState({openAttendanceModal:false})}
                  modalSize={'modal-md'}
                  buttons={attendanceModalBtns}
              />

              }
            </div>
          </>
        }
      </ContainerBlue>
    );
  }
}

const mapStateToProps = ({ tempEdge, timeEntryReducer, payrollValidationReducer }) => ({
  tempEdge,
  isFindByWeek: timeEntryReducer.isFindByWeek,
  resultBarMessage: timeEntryReducer.resultBarMessage,
  absentEmployees: timeEntryReducer.absentEmployees,
  saveTimeDayListResponse: timeEntryReducer?.saveTimeDayListResponse,
  saveTimeWeekListResponse: timeEntryReducer?.saveTimeWeekListResponse,
  shift: timeEntryReducer?.shift,
  weekday: timeEntryReducer?.weekday,
  requestDay: payrollValidationReducer?.requestDay,
  dateStart: payrollValidationReducer?.dateStart,
  absentEmployeesWeek: timeEntryReducer?.absentEmployeesWeek,
  payrollWeekValidation: payrollValidationReducer?.payrollWeekValidation,
  payrollResultWeekValidation: payrollValidationReducer?.payrollResultWeekValidation,
  totalWeekExcel:timeEntryReducer?.totalWeekExcel,
  absentValidation: payrollValidationReducer?.absentValidation,
  weekActive: timeEntryReducer?.weekActive
});

const mapDispatchToProps = {
  tempedgeAPI,
  clearTempedgeStoreProp,
  resetEntireState,
  clearProp,
  resetDataAbsent,
  changeState,
  push
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(PayrollValidation)
);
