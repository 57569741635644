
import { attendanceTypes } from "../types/attendanceTypes";

export const initialState = {
    loading: false,
    result: [],
    error: '',
    startDate: null,
    clientId: null,
    message: null,
    companyListInvalid: false,
    payPeriodInvalid: false,
    customDateInvalid: false,
    officeInvalid: false,
    saveObject: null,
    activeWeek: true,
    clearForm: false,
};

const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const attendanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case attendanceTypes.FETCH_ATTENDANCE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case attendanceTypes.FETCH_ATTENDANCE_SUCCESS:
            const filteredResults = action.payload.map(item => {
                const filteredItem = { ...item };
                daysOfWeek.forEach(day => {
                    if ((item[day] === undefined || !item[day] || !item[day]?.person || item[day]?.person == null) || (!item[day]?.dateList || item[day]?.dateList == null)) {
                        delete filteredItem[day];
                    }
                });
                return filteredItem;
            });
            return {
                ...state,
                loading: false,
                result: filteredResults,
                error: '',
                clearForm: false,
            };

        case attendanceTypes.FETCH_ATTENDANCE_FAILURE:
            return {
                ...state,
                loading: false,
                result: [],
            };

        case attendanceTypes.UPDATE_START_DATE:
            return {
                ...state,
                startDate: action.payload,
            };

        case attendanceTypes.CLEAR_STATE:
            return initialState;

        case attendanceTypes.UPDATE_ATTENDANCE_LIST: {
            const { id, day, key, value, payrollId } = action.payload;
            let { result } = state;
            if (result) {
                result = result.map((employee) => {
                    if (
                        employee?.id === id &&
                        employee[day]?.payrollId === payrollId &&
                        employee[day]
                    ) {
                        let employeeCopy = { ...employee };
                        employeeCopy[day][key] = parseFloat(value);
                        if (key === "attendance") {
                            employeeCopy[day]['attendance'] = value;
                        }
                        return employeeCopy;
                    }
                    return employee;
                });
            }
            return {
                ...state,
                result,
            };
        };

        case attendanceTypes.UPDATE_CLIENT_ID:
            return {
                ...state,
                clientId: action.payload
            }

        case attendanceTypes.UPDATE_MESSAGE:
            return {
                ...state,
                message: action.payload
            }

        case attendanceTypes.CLEAR_ATTENDANCE_LIST:
            return {
                ...state,
                result: null
            }

        case attendanceTypes.UPDATE_FIELD_INVALID: {
            const { field, isInvalid } = action.payload;
            return {
                ...state,
                [`${field}Invalid`]: isInvalid,
            };
        }

        case attendanceTypes.CLEAR_FIELD_INVALID: {        
            const { field } = action.payload;
            return {
                ...state,
                [`${field}Invalid`]: false,
            };
        }

        case attendanceTypes.FETCH_SAVE_ATTENDANCE_REQUEST: {
            const { result } = state;
            const newResult = [];

            result?.forEach(person => {
                daysOfWeek.forEach(day => {
                    if (person[day] && person[day].payrollId !== undefined) {
                        newResult.push({
                            payrollId: person[day].payrollId,
                            attendance: person[day].attendance,
                            dateList: person[day].dateList,
                        });
                    }
                });
            });

            return {
                ...state,
                saveObject: newResult,
                loading: true
            }
        }

        case attendanceTypes.UPDATE_ACTIVE_WEEK: {
            return {
                ...state,
                activeWeek: action.payload
            }
        }

        case attendanceTypes.FETCH_SAVE_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                clearForm: true
            };

        case attendanceTypes.FETCH_SAVE_ATTENDANCE_FAILURE:
            return {
                ...state,
                loading: false,
                clearForm: false
            };

        default:
            return state;
    }
};

export default attendanceReducer;
