import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

export const SimpleAreaChart = ({ title, data, translate }) => {
    return (
        <>
            <h1> {translate(title)} </h1>
            <ResponsiveContainer width="100%" height="80%">
                <AreaChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 10,
                        left: -20,
                        bottom: 10,
                    }}                    
                >
                    <CartesianGrid strokeDasharray="1 1"/>
                    <XAxis dataKey="stringRangeDate" hide interval={0} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="sumHours" stroke="var(--tempedge-orange)" fill="var(--tempedge-yellow)" name={translate('com.tempedge.msg.label.totalhours')} />
                </AreaChart>
            </ResponsiveContainer>

        </>

    )
}
