const types = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  GET_COUNTRY_REGION_LIST: "GETCOUNTRYREGIONLIST",
  GET_FUNDING_LIST: "GET_FUNDING_LIST",
  GET_ROLE_LIST: "GET_ROLE_LIST",
  SAVE_FORM_POSITION: "SAVE_FORM_POSITION",
  SAVE_FILTER_LIST: "SAVE_FILTER_LIST",
  SAVE_DEPARTMENTS_LIST: "SAVE_DEPARTMENTS_LIST",
  SAVE_TO_DEPARTMENTS_LIST: "SAVE_TO_DEPARTMENTS_LIST",
  SAVE_POSITIONS_LIST: "SAVE_POSITIONS_LIST",
  SAVE_TO_POSITIONS_LIST: "SAVE_TO_POSITIONS_LIST",
  SAVE_NEW_PASSWORD: "SAVE_NEW_PASSWORD",
  REMOVE_FROM_DEPARTMENTS_LIST: "REMOVE_FROM_DEPARTMENTS_LIST",
  UPTDATE_CLIENT_LIST: "UPDATE_CLIENT_LIST",
  DELETE_CLIENT_CODE: "DELETE_CLIENT_CODE",
  CREATE_CLIENT: "CREATE_CLIENT",
  UPDATE_CLIENTE: "UPDATE_CLIENT",
  VIEW_CLIENT: "VIEW_CLIENT",
  SAVE_BILL_RATE: "SAVE_BILL_RATE",
  SAVE_OT_BILL_RATE: "SAVE_OT_BILL_RATE",
  SAVE_INTERNAL_PAYROLL: "SAVE_INTERNAL_PAYROLL",
  REMOVE_FROM_POSITIONS_LIST: "REMOVE_FROM_POSITIONS_LIST",
  TEMPEDGE_LIST: "TEMPEDGE_LIST",
  SKILLS_LIST: "SKILLS_LIST",
  GET_ORG_DEPARTMENT_LIST: "GET_ORG_DEPARTMENT_LIST",
  GET_OFFICE_LIST: "GET_OFFICE_LIST",
  GET_ACTIVITY_LIST: "GET_ACTIVITY_LIST",
  GET_AGENCY_LIST: "GET_AGENCY_LIST",
  VALIDATE_PERSON: "VALIDATE_PERSON",
  PERSON_SAVE: "PERSON_SAVE",
  PERSON_UPDATE: "PERSON_UPDATE",
  VIEW_PERSON: "VIEW_PERSON",
  CLEAR_PROP: "CLEAR_PROP",
  CLEAR_ERROR_FIELD: "CLEAR_ERROR_FIELD",
  SET_ERROR_FIELD: "SET_ERROR_FIELD",
  REMOVE_ERROR_FIELD: "REMOVE_ERROR_FIELD",
  GET_SALESMAN_LIST: "GET_SALESMAN_LIST",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  SAVE_EMPLOYEE_LIST: "SAVE_EMPLOYEE_LIST",
  MODAL_VISIBILITY: "MODAL_VISIBILITY",
  GET_LANGUAGE: "GET_LANGUAGE",
  SAVE_LOGO: "SAVE_LOGO",
  CLEAN_LOGO: "CLEAN_LOGO",
  ENTITY_ID: "ENTITY_ID",
  LOGIN_ERROR: "LOGIN_ERROR",
  TIMEOUT_MODAL: "TIMEOUT_MODAL",
  VIEW_USER: "VIEW_USER",
  VIEW_USER_ID: "VIEW_USER_ID",
  VIEW_USER_CHANGE_STATUS: "VIEW_USER_CHANGE_STATUS",
  VIEW_PERSON_CHANGE_STATUS: "VIEW_PERSON_CHANGE_STATUS",
  APPROVE_USER: "APPROVE_USER",
  GET_ROLE: "GET_ROLE",
  VIEW_OFFICE: "VIEW_OFFICE",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  GET_CLIENT_LIST: " GET_CLIENT_LIST",
  DELETE_POST_CLIENT: "DELETE_POST_CLIENT",
  GET_COUNTRY_LIST_ALL: "GET_COUNTRY_LIST_ALL",
};

export default types;
