import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeWeekPayRateValue } from "../../../Redux/actions/payrollValidationActions";

const EditableCell = ({ payroll, day ,editPayrate }) => {
  const [payRate, setpayRate] = useState(0);

  useEffect(() => {
    setpayRate(payroll[day]?.payRate.toFixed(2));
  }, [payroll, day]);

  const dispatch = useDispatch();
  const changePayRate = (values, key) => {
    let data = {
      payrollId: payroll[day].payrollId,
      id: payroll.id,
      key: key,
      value: values ? values : 0,
      day: day,
    };
    const regularExpression = /^-?\d{0,3}(\.\d{0,2})?$/;

    if (regularExpression.test(values)) {
      dispatch(changeWeekPayRateValue(data));
    }
  };

  if (payroll[day] !== null) {
    return (
      <td className="editableCell" style={{ textAlign: "center" }}>
        <div className="input-group input-group-sm ">
          <input
            style={{
              fontSize: "13px",
              textAlign: "center",
              borderRadius: "35px",
            }}
            type="text"
            step="0.01"
            className="form-control"
            placeholder="0.00"
            aria-label="payRate"
            aria-describedby="basic-addon1"
            value={payRate || ""}
            onChange={(event) => {
              const inputValue = event.target.value;
              // Regular expression to match the desired format
              const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
              if (regex.test(inputValue) || inputValue === "") {
                setpayRate(inputValue);
              }
            }}
            onBlur={() => changePayRate(payRate, "payRate")}
            disabled={editPayrate}
          />
        </div>
      </td>
    );
  } else {
    return <td></td>;
  }
};

export default EditableCell;
