import React from 'react'
import { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '../../components/common/Switch/Switch';
import { resetEntireState } from '../../Redux/actions/adjustmentActions';
import moment from "moment";
import { DATE_FORMAT } from '../../components/common/Constants/Constants';



const AdjustmentValidation = ({ adjustmentValidations, submit, setFindWeekisonecompany,initState}) => {

  const dispatch = useDispatch();
  let nameCompany = `${JSON.parse(localStorage.getItem('agency')).user.firstName} ${JSON.parse(localStorage.getItem('agency')).user.lastName}`
  const [validation, setValidation] = useState(false)

  const employees = useSelector(state => {
    return state.adjustmentReducer.employeesInAdjustment;
  });

  return (
    <div className={"time-sheet__body"}>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="table-header-left text-center w-5">EE #</th>
                <th className="table-header-mid text-center w-10">
                  {<Translate id="com.tempedge.msg.label.lastname" />}
                </th>
                <th className="table-header-mid text-center w-10">
                  {<Translate id="com.tempedge.msg.label.firstname" />}
                </th>
                <th className="table-header-mid text-center w-15">
                  {<Translate id="com.tempedge.msg.label.deptpos" />}
                </th>
                <th className="table-header-mid text-center w-10">
                  {<Translate id="com.tempedge.msg.label.pay" />}
                </th>
                <th className="table-header-mid text-center w-10">
                  {<Translate id="com.tempedge.reports.columns.colhourspos" />}
                </th>
                <th className="table-header-mid text-center w-10">
                  {<Translate id="com.tempedge.msg.label.adjustmentdate" />}
                </th>
                <th className="table-header-mid text-center w-10">
                  {<Translate id="com.tempedge.msg.label.paymentdate" />}
                </th>
              </tr>
            </thead>
            <tbody>
              {employees &&
                employees.map((employee,index) => {
                  return (
                    <tr key={employee.id + index} className={"time-sheet-row"}>
                      <td className="" style={{ textAlign: "center" }}>{employee.employeeId}</td>
                      <td className="" style={{ textAlign: "center" }}>{employee.lastName}</td>
                      <td className="" style={{ textAlign: "center" }}>{employee.firstName}</td>
                      <td className="" style={{ textAlign: "center" }}>{employee.position?.positionName}</td>
                      <td className="" style={{ textAlign: "center" }}>{employee.pay} </td>
                      <td className="" style={{ textAlign: "center" }}>
                        {employee.totalRegHour || 0.0} <span> - </span>{employee.totalOtHour || 0.0}
                      </td>
                      <td className={"text-center"}>{moment(employee.date).format(DATE_FORMAT)}</td>
                      <td className={"text-center"} style={{ textAlign: "center" }}>
                        {moment(employee.paymentDate).format(DATE_FORMAT)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-4">
        <div className="imput col-6 " >
          <p style={{ textAlign: "left", marginBottom: '5px' }}>
            {<Translate id='com.tempedge.msg.label.clickingconfirm1' />} <b>{nameCompany}</b> {<Translate id='com.tempedge.msg.label.clickingconfirm2' />}
          </p>
        </div>
        <div className="imput col-1 ">
          <Switch
            name="week"
            checked={validation}
            onChange={(value) => {
              setValidation(value)
            }}
          />
        </div>
      </div>
      <div className="row justify-content-end mt-4">
        <div className="form-group col-12 col-lg-3">
          <button
            type="button"
            onClick={() => {
              setFindWeekisonecompany(true);
              dispatch(resetEntireState());
              initState();
            }}
            className="btn btn-gray w-100"
          >
            {<Translate id='com.tempedge.msg.label.cancel' />}
          </button>
        </div>
        <div className="form-group col-12 col-lg-3">
          <button
            type="button"
            onClick={() => {
              adjustmentValidations(false)
            }}
            className="btn btn-gray w-100"
          >
            {<Translate id='com.tempedge.msg.label.back' />}
          </button>
        </div>
        <div className="form-group col-12 col-lg-3">
          <button type="button"
            onClick={() => { trackPromise(submit()) }}
            className="btn btn-blue w-100"
            disabled={!validation}
          >
            {<Translate id='com.tempedge.msg.label.save' />}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AdjustmentValidation