import React, { useCallback, useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import ItemComment from './ItemComment';

const COUNT = 2;

function ListComments(props) {

    const [page, setPage] = useState(0);
    const [currentComments, setCurrentComments] = useState([]);

    const {comments} = props;

    const tags = useCallback(() => {
        const count =  Math.ceil(comments.length / COUNT);
        const tags = [];
        for (let i = 1; i <= count; i++) {
            tags.push(i);
        }
        return tags;
    },[comments]);

    const next = () => {
       const newPage =  page + 1;
       if(newPage >= tags().length) 
          return;
       setPage(newPage);
    }

    const previous = () => {
       const newPage = page-1;
       if(newPage < 0)
          return;
       setPage(newPage);
    }

    useEffect(() => {
       const start = page*COUNT;
       setCurrentComments(comments.slice(start, start + COUNT));
    }, [comments,page]);


    if(comments === undefined || comments === null)
       return <span>there aren't comment</span>;

    return (
        <div className="container">
           <div className="row">
                <div className="col-12">
                    <p className="green-text"><Translate id="com.tempedge.msg.label.comments"/></p>
                    <div className="mt-4">
                        {currentComments && currentComments.map( (comment,index) => {
                             return <ItemComment key={comment.commentsId} comment={comment}/>
                        })}
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item">
                                    <button className="page-link" onClick={previous}>
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{height:'1em'}} viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </li>
                                {tags().map((item) => {
                                    return (
                                            <li className={(item-1) === page ? "page-item active" : "page-item"} key={item}>
                                              <button className="page-link" onClick={() => setPage(item-1)}>{item}</button>
                                           </li>
                                    );
                                })}
                                <li className="page-item">
                                    <button className="page-link" onClick={next}>
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{height:'1em'}} viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
           </div>
        </div>
    ) 
}

export default withLocalize(ListComments);