import {customPayrollTypes} from "../types/customPayrollTypes";

export const addEmployee = (payload) => ({
   type: customPayrollTypes.ADD_EMPLOYEE_IN_CUSTOMPAYROLL,
   payload
});

export const addCustomPayroll = (payload) => ({
   type: customPayrollTypes.ADD_CUSTOM_PAYROLL,
   payload
});
export const addSameDateCustomPayroll = (payload) => ({
   type: customPayrollTypes.ADD_SAME_DATA_CUSTOM_PAYROLL,
   payload
});

export const removeEmployee = (personId) => ({
  type: customPayrollTypes.REMOVE_EMPLOYEE_IN_CUSTOMPAYROLL,
  payload: {personId}
});

export const resetEntireState = () => ({
   type: customPayrollTypes.RESET_ENTIRE_STATE,
});

export const resetEntireStateCustom = () => ({
   type: customPayrollTypes.RESET_ENTIRE_STATE,
});

export const changeEmployeeValue = (payload) => ({
    type: customPayrollTypes.CHANGE_EMPLOYEE_VALUE,
    payload
});

export const removeValuesCustomPayroll = (payload) => ({
    type: customPayrollTypes.REMOVE_VALUE_CUSTOM_PAYROLL,
    payload
});

export const deleteValuesCustomPayroll = (payload) => ({
    type: customPayrollTypes.DELETE_VALUE_CUSTOM_PAYROLL,
    payload
});
export const deleteCustomPayroll = (payload) => ({
    type: customPayrollTypes.DELETE_CUSTOM_PAYROLL,
    payload
});

