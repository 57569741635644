import clockIcon from "../../../assets/static/clock.svg";
import {useCallback, useEffect, useState} from "react";
import moment from "moment";
import Backdrop from "../NewTimePicker/Backdrop";
import Timekeeper from "react-timekeeper";
import '../../../assets/styles/components/Timepicker.css';

function TimePicker ({time, setTime, disabled}) {

    const [value, setValue] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        const parsedDate = moment(time, 'LT').format('LT');
        if (moment(parsedDate, 'LT').isValid()) {
            setValue(parsedDate);
        } else {
            setValue('');
        }
    },[time]);

   const formatTime = (event) => {
       const value = event.target.value;
       const parsedDate = moment(value, 'LT').format('LT');
       if (moment(parsedDate, 'LT').isValid()) {
           setValue(parsedDate);
           setTime(parsedDate)
       } else {
           setValue('');
           setTime('');
       }
   }

   const handleChange = useCallback((event) => {
       const value = event.target.value;
       setValue(value);
   },[]);

   return (
       <div className="input-group mb-3" style={{marginTop:"1rem"}}>
           <input
               type="text"
               disabled={disabled}
               className="form-control tempEdge-input-box square-right-side text-center"
               value={value}
               onChange={(event) => handleChange(event)}
               onBlur={(event) => formatTime(event)}
           />
           <div className="input-group-append">
               <button type="button" disabled={disabled} className="btn btn-green" onClick={() => {
                   setShow(true) 
                   setValue('00:00')}}>
                   <img src={clockIcon} alt="Clock Icon" />
               </button>
               <Backdrop
                  open={show}
                  content={<Timekeeper 
                    onChange={(newTime) => setTime(newTime.formatted12)} 
                    onBlur={(newTime) => setTime(newTime.formatted12)} 
                    time={value} 
                    switchToMinuteOnHourSelect 
                    onDoneClick={() => setShow(false)} 
                    />}
                  onClose={() => setShow(false)}
               />
           </div>
       </div>
   );
}

export default TimePicker;