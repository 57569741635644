import React from 'react';
import '../../../assets/styles/components/Button.css';

export const PrimaryButton = (props) => (
  <Button variant="tempedge-button--state-primary" {...props} />
);

export const DefaultButton = (props) => (
  <Button variant="tempedge-button--state-default" {...props} />
);

export const DangerButton = (props) => (
  <Button variant="tempedge-button--state-danger" {...props} />
);

export const SuccessButton = (props) => (
  <Button variant="tempedge-button--state-success" {...props} />
);

/**
 *
 * @param {String} rightSquareSide use this parameter to convert the right side to square
 * @param {String} leftSquareSide use this parameter to convert the left side to square
 */

function Button({
  variant,
  type = 'button',
  disabled = false,
  children,
  onClick,
  rightSquareSide = '',
  leftSquareSide = ''
}) {
  rightSquareSide = rightSquareSide && 'tempedge-button--square-right-side';
  leftSquareSide = leftSquareSide && 'tempedge-button--square-left-side';
  return (
    <button
      type={type}
      className={`tempedge-button ${variant} ${rightSquareSide} ${leftSquareSide}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default DefaultButton;
