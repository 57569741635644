import moment from "moment";
import React, { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import Modal from "../../../../Modals/Modal/Modal";
import { addAbsentValidation } from "../../../../Redux/actions/payrollValidationActions";
import {
  TotalHourByWeekDay,
  totalHoursByConfig,
  calculateWeeklyTotals,
  TotalOtHourWeeklyPayroll,
  TotalRegHourWeeklyPayroll,
} from "../../../../utils/helpers/TotalHoursFormat";
import edit from "../../../../assets/icons/edit.png";
import PayrollEditPayRate from "../../PayRollEditPayRate/PayrollEditPayRate";
import SetTableHeading from "../../PayrollByWeek/SetTableHeading";
import useHeaderDate from "../../PayrollByWeek/useHeaderDate";
import CellPayrollValidation from "./CellPayrollValidation";
import {
  formatUsNumber,
  formatToDollars,
} from "../../../../../src/utils/ReGexs";

const PayrollByWeekValidation = ({
  payrollWeekValidation,
  dateStart,
  clientReportConf,
}) => {
  const dispatch = useDispatch();
  const { findDate } = useHeaderDate(dateStart);
  const [weekResults, setWeekResults] = useState([]);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(null);
  const [totals, setTotals] = useState({});
  const editPayRate = true;

  useEffect(() => {
    const weekResul = Object.values(payrollWeekValidation).filter(
      (value) =>
        value !== null &&
        (value.employeeTotalRegHours
          !== 0 || value.employeeTotalOTHours !== 0)
    );
    setWeekResults(weekResul);

    const absent = [];
    payrollWeekValidation?.map((payroll) => {
      for (const [key, value] of Object.entries(payroll)) {
        if (key !== null) {
          if (value !== null) {
            if (value.totalRegHour === 0 && value.totalOtHour === 0) {
              absent.push(payroll);
            }
          }
        }
      }
      return null;
    });
    let newAbsent = absent.filter((value, index) => {
      return absent.indexOf(value) === index;
    });
    dispatch(addAbsentValidation(newAbsent));
  }, [payrollWeekValidation, dispatch]);

  useEffect(() => {
    if (payrollWeekValidation.length > 0) {
      const {
        totalRegHours,
        totalOTHours,
        totalBillAmount,
        totalOTBillAmount,
        totalAmount,
      } = calculateWeeklyTotals(payrollWeekValidation);

      // Verificar si los totales han cambiado antes de actualizar el estado
      if (
        totalRegHours !== totals.totalRegHours ||
        totalOTHours !== totals.totalOTHours ||
        totalBillAmount !== totals.totalBillAmount ||
        totalOTBillAmount !== totals.totalOTBillAmount ||
        totalAmount !== totals.totalAmount
      ) {
        setTotals({
          totalRegHours,
          totalOTHours,
          totalBillAmount,
          totalOTBillAmount,
          totalAmount,
        });
      }
    } else {
      // Manejar el caso cuando no hay datos de nómina
      setTotals({
        totalRegHours: 0,
        totalOTHours: 0,
        totalBillAmount: 0,
        totalOTBillAmount: 0,
        totalAmount: 0,
      });
    }
  }, [payrollWeekValidation, totals]);

  const highlightRow = (payroll, index) => {
    const rowId = `${payroll.person.personId}-${index}`;
    setSelectedEmployeeIndex(rowId);
  };

  const [modal, setModal] = useState({
    show: false,
    content: null,
    size: null,
  });

  return (
    <>
      <div className="row">
        <div className="col-4">
          <h2 className="section-title">
            <Translate id={"com.tempedge.msg.label.hoursvalidation"} />
          </h2>
          <div className="badge-container">
            <Translate id={"com.tempedge.msg.label.listsize"} />
            <span className="badge">{weekResults.length}</span>
          </div>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-2">
              <div>
                <Translate id={"com.tempedge.msg.label.reghours"} />:
              </div>
              <div>{formatUsNumber(totals.totalRegHours)}</div>
            </div>
            <div className="col-2">
              <div>
                <Translate id={"com.tempedge.msg.label.othours"} />:
              </div>
              <div>{formatUsNumber(totals.totalOTHours)}</div>
            </div>
            <div className="col-2">
              <div>
                <Translate id={"com.tempedge.msg.label.totalhours"} />:
              </div>
              <div>{formatUsNumber(totals.totalRegHours + totals.totalOTHours)}</div>
            </div>
            <div className="col-2">
              <div>
                <Translate id={"com.tempedge.msg.label.billamount"} />:
              </div>
              <div>{formatToDollars(totals.totalBillAmount)}</div>
            </div>
            <div className="col-2">
              <div>
                <Translate id={"com.tempedge.msg.label.otbillamount"} />:
              </div>
              <div>{formatToDollars(totals.totalOTBillAmount)}</div>
            </div>
            <div className="col-2">
              <div>
                <Translate id={"com.tempedge.msg.label.totalamount"} />:
              </div>
              <div>{formatToDollars(totals.totalAmount)}</div>
            </div>
            <div className="col-6 offset-7">
              <hr className="time-entry__body w-50" />
            </div>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="form-check col-12 col-lg-12">
          <table className="table table-striped table-input-group">
            <thead>{SetTableHeading(dateStart, editPayRate)}</thead>
            <tbody>
              <>
                {weekResults?.map((payroll, index) => {
                  const rowId = `${payroll.person.personId}-${index}`;
                  return (
                    <tr
                      key={index + 1}
                      className={
                        selectedEmployeeIndex === rowId
                          ? "time-sheet-row--selected"
                          : "time-sheet-row"
                      }
                      onClick={() => highlightRow(payroll, index)}
                    >
                      <td>{`${payroll.person?.fullName}`}</td>
                      <td>
                        {payroll.department !== null
                          ? `${payroll.department?.name} - ${payroll.position?.name}`
                          : payroll.position?.name}
                      </td>
                      {Object.keys(findDate).map((currentDay, index) => {
                        const payrollDate =
                          payroll[findDate[currentDay].day]?.dateList;
                        return moment(payrollDate).format("MM/DD/YYYY") ===
                          findDate[currentDay].date ? (
                          <CellPayrollValidation
                            key={index + 1}
                            payroll={payroll}
                            day={findDate[currentDay].day}
                          />
                        ) : (
                          <td key={index + 1}></td>
                        );
                      })}
                      <td className="text-center">
                        {payroll.employeeTotalRegHours
                          ? totalHoursByConfig(
                              payroll.employeeTotalRegHours,
                              clientReportConf
                            )
                          : 0}
                      </td>
                      <td className="text-center">
                        {payroll.employeeTotalOTHours
                          ? formatUsNumber(payroll.employeeTotalOTHours)
                          : 0}
                      </td>
                      <td className="text-center">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            setModal({
                              show: true,
                              content: (
                                <PayrollEditPayRate
                                  payrollWeekValidation={payrollWeekValidation}
                                  dateStart={dateStart}
                                  id={payroll.id}
                                  setModal={setModal}
                                />
                              ),
                              size: "modal-lg",
                            });
                          }}
                        >
                          <img
                            className="icon_edit"
                            src={edit}
                            alt="edit icon"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                <tr className="tableRow text-center">
                  <td
                    colSpan="2"
                    className="table-content text-uppercase text-right"
                  >
                    <Translate id={"com.tempedge.msg.label.total"} />
                  </td>
                  {Object.keys(findDate).map((currentDay, index) => {
                    return (
                      <td key={index + 1} className="table-content">
                        {TotalHourByWeekDay(
                          payrollWeekValidation,
                          findDate[currentDay].day
                        )}
                      </td>
                    );
                  })}
                  <td className="table-content">
                    {TotalRegHourWeeklyPayroll(payrollWeekValidation)}
                  </td>
                  <td className="table-content">
                    {TotalOtHourWeeklyPayroll(payrollWeekValidation)}
                  </td>
                  <td> -- </td>
                </tr>
              </>
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={modal.show}
        content={modal.content}
        onClose={() => setModal({ show: false, content: null, size: null })}
        modalSize={modal.size ?? "modal-sm"}
      />
    </>
  );
};

export default PayrollByWeekValidation;
