import { withLocalize } from 'react-localize-redux';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import { FieldArray } from 'formik';
import deleteIcon from '../../../components/common/ClientCode/assets/delete.png';
import addIcon from '../../../components/common/ClientCode/assets/plus.png';
import { normalizeClientCode } from '../../../utils/ReGexs';

function ClientCode(props) {
   return (
      <div className={'tab'}>
         <FieldArray
            name={'clientCodes'}
            render={(arrayHelpers) => (
               <div>
                  {props.formik.values.clientCodes && props.formik.values.clientCodes.length > 0 ? (
                     props.formik.values.clientCodes.map((client, index) => (
                        <div key={index} className="list-item d-flex align-items-center mb-2 pb-4 border-bottom">
                           <div className="col-md-4">
                              <label className="control-label">{props.translate('com.tempedge.msg.label.company')}</label>
                              <DropdownList
                                 name={`clientCodes.${index}`}
                                 data={props.companies}
                                 textField="clientName"
                                 valueField="clientId"
                                 value={{
                                    clientId: props.formik.values.clientCodes[index]?.client,
                                 }}
                                 onChange={(value) => {
                                    const selected = [];
                                    props.formik.values.clientCodes.forEach((clientCode, index) => {
                                       const item = props.formik.values.clientCodes[index];
                                       if (item.client === value.clientId) selected.push(index);
                                    });
                                    if (selected.length === 0)
                                       arrayHelpers.replace(index, {
                                          client: value.clientId,
                                       });
                                 }}
                              />
                           </div>
                           <div className="col-md-4">
                              <label className="control-label">Code</label>
                              <input
                                 className={'tempEdge-input-box form-control'}
                                 name={`clientCodes.${index}.code`}
                                 type={'text'}
                                 maxLength={32}
                                 value={props.formik.values.clientCodes[index].code ?? ''}
                                 onChange={(event) => {
                                    event.target.value = normalizeClientCode(event.target.value);
                                    props.formik.handleChange(event);
                                 }}
                              />
                           </div>
                           <div className="col-md-4 ">
                              <span
                                 className="pull-right"
                                 title="Remove Code"
                                 onClick={() => {
                                    props.formik.values.clientsCodesDeleted.push(props.formik.values.clientCodes[index]);
                                    arrayHelpers.remove(index);
                                 }}
                                 style={{ paddingRight: '4rem' }}
                              >
                                 <img className="delete-icon-phones" src={deleteIcon} alt="deleteIcon" />
                              </span>
                           </div>
                        </div>
                     ))
                  ) : (
                     <div className="mt-5 ml-5">
                        <h2>{props.translate('com.tempedge.msg.label.emptycode')}</h2>
                     </div>
                  )}
                  <div className="list-item row">
                     <div className="col-md-12">
                        <span
                           style={{ paddingRight: '4.2rem' }}
                           className="center-block pull-right add-fieldArray-btn"
                           onClick={() => {
                              arrayHelpers.push({});
                           }}
                        >
                           <img style={{ width: '42px', paddingTop: '12px' }} src={addIcon} alt="addIcon" />
                        </span>
                     </div>
                  </div>
               </div>
            )}
         />
      </div>
   );
}

export default withLocalize(ClientCode);
