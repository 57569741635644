import { adjustmentTypes } from "../types/adjustmentTypes";

export const addEmployee = (payload) => ({
   type: adjustmentTypes.ADD_EMPLOYEE_IN_ADJUSTMENT,
   payload
});

export const removeEmployee = (personId) => ({
  type: adjustmentTypes.REMOVE_EMPLOYEE_IN_ADJUSTMENT,
  payload: {personId}
});

export const resetEntireState = () => ({
   type: adjustmentTypes.RESET_ENTIRE_STATE,
});

export const resetEntireStateAdjustment = () => ({
   type: adjustmentTypes.RESET_ENTIRE_STATE,
});

export const changeEmployeeValue = (payload) => ({
    type: adjustmentTypes.CHANGE_EMPLOYEE_VALUE,
    payload
});

export const deleteAdjustmentTotal = (payload) => ({
   type: adjustmentTypes.DELETE_ADJUSTMENT,
   payload
});

export const addSameDateAdjustments = (payload) => ({
   type: adjustmentTypes.ADDSAMEDATE,
   payload
});
