import React, { useCallback } from "react"; //hooks
import { useDispatch } from "react-redux";
import { Translate } from "react-localize-redux";
import { changeTimeEntryValue, moveEmployeeToAbsentTable } from '../../../Redux/actions/timeEntryActions';
import DropdownList from '../../../components/common/Dropdown/DropdownList';
import deleteIcon from '../../../assets/icons/delete.png';
import TimePicker from '../../../components/common/TimePicker/TimePicker';
import { useSelector } from "react-redux";
import { formatUsNumber } from "../../../utils/ReGexs";



const PayrollByDay = ({ positionList,positionSelectDay }) => {

  const employeesTimeEntryDay = useSelector(state => state.timeEntryReducer?.employeesTimeEntryDay);

  const calculateTotal = () => {
    let total = 0;
    employeesTimeEntryDay.forEach(employee => {
      // Convierte el resultado de calculateTotalRegHours a número, asumiendo que la función maneja correctamente valores nulos
      const totalRegHours = Number(calculateTotalRegHours(employee.payrollId));
      // Solo sumar si totalRegHours es un número válido, ignorar NaN
      if (!isNaN(totalRegHours)) {
        total += totalRegHours;
      }
    });

    // Si el total después del cálculo es 0, devuelve "0.00" para mantener el formato decimal
    return total === 0 ? "0.00" : formatUsNumber(total);
  };

  const calculateTotalRegHours = (payrollId) => {
    // Si no hay un valor editado, usar el valor de totalRegHour del estado de Redux
    const employee = employeesTimeEntryDay.find(e => e.payrollId === payrollId);
    // Si totalRegHour existe y no es nulo, devolver ese valor, si no, devolver "0"
    return (employee && employee.totalRegHour !== undefined && employee.totalRegHour !== null) ? employee.totalRegHour.toString() : "0.00";
  }; 

  const dispatch = useDispatch();
  let sortedListEmployee = [...employeesTimeEntryDay];
  sortedListEmployee.sort((a, b) => {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  })

  const changeTimeEntry = (data) => {
    dispatch(changeTimeEntryValue(data));
  }

  const setTime = useCallback((value) => {
    dispatch(changeTimeEntryValue(value));
  }, [dispatch]); 


  const removeFromTimeEntryTable = (employee) => {
    dispatch(moveEmployeeToAbsentTable(employee));
  }

  return (
    <>
      <div className="form-group col-12 col-lg-3">
        <p>
          <Translate id={'com.tempedge.msg.label.listsize'} />
          :
          <span className="badge">{employeesTimeEntryDay.length}</span>
        </p>
      </div>
      <div className="row">
        <div className="form-check col-12 col-lg-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="table-header-left text-center w-15">
                  <Translate id={'com.tempedge.msg.label.lastname'} />
                </th>
                <th className="table-header-mid text-center w-15">
                  <Translate id={'com.tempedge.msg.label.firstname'} />
                </th>
                <th className="table-header-mid text-center w-15">
                  <Translate id={'com.tempedge.msg.label.deptpos'} />
                </th>
                <th className="table-header-mid text-center w-10">
                  <Translate id={'com.tempedge.msg.label.timein'} />
                </th>
                <th className="table-header-mid text-center w-5">
                  <Translate id={'com.tempedge.msg.label.lunch'} />
                </th>
                <th className="table-header-mid text-center w-10">
                  <Translate id={'com.tempedge.msg.label.timeout'} />
                </th>
                <th className="table-header-mid text-center w-5">
                  <Translate id={'com.tempedge.msg.label.totalreghours'} />
                </th>
                <th className="table-header-right text-center w-5">
                  <Translate id={'com.tempedge.msg.label.absent'} />
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedListEmployee.map((employee, index) => {
                return (
                  <tr style={{ textAlign: 'center' }} key={index + 1}>
                    <td className="text-center">{employee.lastName}</td>
                    <td className="text-center">{employee.firstName}</td>
                    <td className="text-center">
                      <DropdownList
                        data={positionList}
                        disabled={employee.lockPayroll}
                        valueField="positionId"
                        textField="name"
                        isInvalid={employee.position.positionId=== undefined && positionSelectDay === 'error'}
                        value={employee?.position}
                        onChange={(positionSelected) =>changeTimeEntry(
                          { payrollId:employee.payrollId, key: 'position', value: positionSelected }
                        )}
                      />
                    </td>
                    <td className="text-center">
                      <TimePicker
                        disabled={employee.lockPayroll}
                        time={employee.timeIn || ''}
                        setTime={(time) => {
                          setTime(
                            {payrollId:employee.payrollId, key: 'timeIn', value: time }
                          );

                        }}
                      />
                    </td>
                    <td className="w-6">
                      <input
                        disabled={employee.lockPayroll}
                        type="number"
                        placeholder="0"
                        name="lunch"
                        max="60"
                        maxLength="5" 
                        style={{ minHeight: '4rem' }}
                        className="form-control tempEdge-input-box small text-center"
                        value={employee.lunch || ''}
                        onBlur={(event) => {
                          let regularExpresions = RegExp(/^\d*(\.\d{0,2})?$/)
                          if (regularExpresions.test(event.target.value) && event.target.value <= 60) changeTimeEntry(
                            {payrollId:employee.payrollId, key: 'lunch', value: event.target.value }
                          )
                        }}
                        onChange={(event) => {
                          let regularExpresions = RegExp(/^\d*(\.\d{0,2})?$/)
                          if (regularExpresions.test(event.target.value) && event.target.value <= 60) changeTimeEntry(
                            {payrollId:employee.payrollId, key: 'lunch', value: event.target.value }
                          )
                        }}
                      />
                    </td>
                    <td className="text-center" >
                      <TimePicker
                        disabled={employee.lockPayroll}
                        time={employee.timeOut || ''}
                        setTime={(time) => {
                          setTime(
                            {payrollId:employee.payrollId, key: 'timeOut', value: time }
                          );
                        }}
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="text"
                        placeholder="0"
                        name="totalRegHour"
                        pattern="^-?\d{1,3}([.,]\d{1,2})?$"
                        step="0.01"
                        max='99.99'
                        min='-99.99'
                        maxLength="6"
                        disabled={employee.lockPayroll}
                        className="form-control tempEdge-input-box small text-center"
                        value={employee.totalRegHour || ''}
                        onBlur={(event) => {
                          const value = event.target.value;
                          if (value === '') {
                            changeTimeEntry({payrollId:employee.payrollId, key: 'totalRegHour', value: value });
                          } else {
                            const regularExpressions = RegExp(/^-?\d*(\.\d{0,2})?$/);
                            const numericValue = parseFloat(value);
                            if (regularExpressions.test(value) && numericValue <= 99.99 && numericValue >= -99.99) {
                              changeTimeEntry({payrollId:employee.payrollId, key: 'totalRegHour', value: value });
                            }
                          }
                        }}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value === '' || value === '-') {
                            changeTimeEntry({payrollId:employee.payrollId, key: 'totalRegHour', value: value });
                          } else {
                            const regularExpressions = RegExp(/^-?\d*(\.\d{0,2})?$/);
                            const numericValue = parseFloat(value);
                            if (regularExpressions.test(value) && (numericValue <= 99.99 && numericValue >= -99.99)) {
                              changeTimeEntry({payrollId:employee.payrollId, key: 'totalRegHour', value: value });
                            }
                          }
                        }}                        
                         />
                    </td>
                    <td className="text-center">
                      <button type="button" disabled={employee.lockPayroll} className="btn" onClick={() => removeFromTimeEntryTable(employee)}>
                        <img className="icon-width" src={deleteIcon} alt="Delete icon" />
                      </button>
                    </td>
                  </tr>

                )
              })}
              <tr className="tableRow">
                <td colSpan="6" className="table-content text-uppercase text-right">
                  <Translate id={'com.tempedge.msg.label.total'} /></td>
                <td className="table-content text-center">
                  {calculateTotal()}
                </td>
                <td className='text-center'></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
}

export default PayrollByDay

