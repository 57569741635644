import React,{Suspense, useState} from "react";
import EditableCell from "./EditableCell";
import moment from "moment";
import { Translate } from "react-localize-redux";
import { TotalOtHourWeek, TotalRegHourWeek, TotalHourByWeekDay, totalHoursByConfig } from '../../../utils/helpers/TotalHoursFormat'
import SetTableHeading from "./SetTableHeading";
import useHeaderDate from "./useHeaderDate";
import commentIcon from '../../../assets/static/actionIcons/comment.png';
import CommentModal from "../../../components/common/CommentModal/CommentModal";
import  Modal  from 'react-bootstrap/Modal';
import { formatUsNumber } from "../../../utils/ReGexs";

const PayrollByWeek = ({ employeesTimeEntry, dateStart,clientReportConf }) => {

  const [componentToRender, SetComponentToRender] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(null);
  const commetModal=true;

  const modalComment =(payroll)=>{
    let ComponentToRenders = null;
    ComponentToRenders = React.lazy(() => import('../../../components/common/Comments/Comments'));
    ComponentToRenders=  !ComponentToRenders ? 
    null :setShowModal(true); ComponentToRenders=<CommentModal payroll={payroll} setShowModal={setShowModal}/>

    SetComponentToRender(ComponentToRenders);
  }

  const handleCloseModal=() =>{
    setShowModal(false);
  }

  const { findDate } = useHeaderDate(dateStart);

  const totalHoursTimeEntry=parseFloat(TotalRegHourWeek(employeesTimeEntry)) + parseFloat(TotalOtHourWeek(employeesTimeEntry))


   return (
    <>
      <div className="row">
         <div className="col-12">
         <div className="row">
           <div className="form-group col-2">
             <div>
               <Translate id={'com.tempedge.msg.label.listsize'} />
               <span className="badge">{employeesTimeEntry.length}</span>
             </div>
           </div>
           <div className="col-6"></div>
           <div className="col-4">
             <div className="row">
               <div className="col-4" style={{textAlign:"right"}} >
                 <div className=""><Translate id={'com.tempedge.msg.label.reghours'} />: </div>
                 <div className="">
                   {formatUsNumber(TotalRegHourWeek(employeesTimeEntry))}
                 </div>
               </div>
               <div className="col-4" style={{textAlign:"right"}} >
                 <div className=""><Translate id={'com.tempedge.msg.label.othours'} />: </div>
                 <div className="">
                   {formatUsNumber(TotalOtHourWeek(employeesTimeEntry))}
                 </div>
               </div>
               <div className="col-4" style={{textAlign:"right"}} >
                 <div className=""><Translate id={'com.tempedge.msg.label.total'} />:</div>
                 <div className="">
                   {formatUsNumber(totalHoursTimeEntry)}
                 </div>
               </div>
               <div className="col-4 offset-8">
               <hr className="time-entry__body w-100" />
               </div>
             </div>
           </div>
          </div>
         </div>
  
        <div className="form-check col-12 col-lg-12">
          <table className="table table-striped table-input-group">
            <thead>{SetTableHeading(dateStart,commetModal)}</thead>
            <tbody>
              <>
                {employeesTimeEntry.map((payroll, index) => {
                  const rowId = `${payroll.person.personId}-${index}`;
                  return (
                    <tr 
                      key={index + 1}
                      className={
                        selectedEmployeeIndex === rowId
                          ? "time-sheet-row--selected"
                          : "time-sheet-row"
                      }
                      onClick={() => setSelectedEmployeeIndex(rowId)}
                    >
                      <td>{`${payroll?.person?.fullName}`}</td>
                      <td>{payroll.department !== null ? `${payroll.department?.name}- ${payroll.position?.name}` : payroll.position?.name}</td>
                      {Object.keys(findDate).map((currentDay,index) => {
                         const payrollDate = payroll[findDate[currentDay].day]?.dateList;
                         return moment(payrollDate).format('MM/DD/YYYY') === findDate[currentDay].date ?
                           <EditableCell key={index+1} payroll={payroll} day={findDate[currentDay].day} /> : <td key={index+1}></td>;
                      })}
                      <td className="text-center">{payroll?.totalRegHours ? totalHoursByConfig(payroll.totalRegHours,clientReportConf) :'0.00'}</td>
                      <td className="text-center">{payroll?.totalOTHours ? formatUsNumber(payroll.totalOTHours) :'0.00'}</td>
                      <td className="table-content"><img className="actions-cell__icon" type="button" onClick={()=>modalComment(payroll)} src={commentIcon} style={{marginLeft:'10px'}} alt="Comments icon" /></td>
                    </tr>
                  )
                })}
                <tr className="tableRow text-center">
                  <td colSpan="2" className="table-content text-uppercase text-right">
                    <Translate id={'com.tempedge.msg.label.total'} />
                  </td>
                  {
                    Object.keys(findDate).map((currentDay,index) => {
                      return (
                        <td key={index+1} className="table-content">
                          {TotalHourByWeekDay(employeesTimeEntry, findDate[currentDay].day)}
                        </td>                        
                      )
                    })
                  }
                  <td className="table-content">{TotalRegHourWeek(employeesTimeEntry)}</td>
                  <td className="table-content">{TotalOtHourWeek(employeesTimeEntry)}</td>
                  <td className="table-content"></td>                  
                </tr>
              </>
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={() => handleCloseModal()}>
          <Modal.Header closeButton />
          <div>
            <Suspense fallback={<div>Loading...</div>}>{componentToRender}</Suspense>
          </div>
      </Modal>
    </>
  );
}

export default PayrollByWeek