// Helper function to check if a value is a valid number
export const isValidNumber = (value) => {
  if (value === null) {
    return false;
  }
  const numRegex = /^-?\d{1,3}(\.\d{1,2})?$/;
  return numRegex.test(value.toString());
};
export const isValidNumberPay = (value) => {
  if (value === null) {
    return false;
  }
  const numRegex = /^-?\d{1,4}(\.\d{1,2})?$/;
  return numRegex.test(value.toString());
};

// Validation function for the form fields
export const validate = (values) => {
  const errors = {
    customPayroll: [],
  };

  values.customPayroll.forEach((item, index) => {
    const errorObject = {};
    const {
      payCode,
      billingCode,
      amountPay,
      hoursPay,
      payRate,
      amountBill,
      hoursBill,
      billRate,
    } = item;

    if ((!payCode || !payCode.customPayrollTypeId) && (!billingCode || !billingCode.customPayrollTypeId)) {
      errorObject[`payCode`] = "Either Pay code or Billing code is required";
      errorObject[`billingCode`] = "Either Pay code or Billing code is required";
    }

    if (payCode && payCode.customPayrollTypeId) {
      if (!amountPay && !hoursPay && !payRate) {
        errorObject[`amountPay`] = "Amount pay is required when pay code is present";
        errorObject[`hoursPay`] = "Hours pay is required when pay code is present";
      }
      if (amountPay && payRate) {
        errorObject[`amountPay`] = "Amount Pay and Pay Rate cannot be present simultaneously with Pay code";
        errorObject[`payRate`] = "Amount Pay and Pay Rate cannot be present simultaneously with Pay code";
      }
      
      if (amountPay) {
        if (hoursPay || payRate) {
          errorObject[`hoursPay`] = "Required";
          errorObject[`payRate`] = "Required";
        }
      } else {
        if (hoursPay && !payRate) {
          errorObject[`payRate`] = "Bill Rate is required when Hours Bill is present with Billing code";
        }
        if (!hoursPay && payRate) {
          errorObject[`hoursPay`] = "Hours Bill is required when Bill Rate is present with Billing code";
        }
      }

      if (amountPay && !isValidNumberPay(amountPay)) {
        errorObject[`amountPay`] = "Amount Pay must be a valid number";
      }

      if (hoursPay && !isValidNumber(hoursPay)) {
        errorObject[`hoursPay`] = "Hours Pay must be a valid number";
      }

      if (payRate && !isValidNumber(payRate)) {
        errorObject[`payRate`] = "Pay Rate must be a valid number";
      }
    }

    if (billingCode && billingCode.customPayrollTypeId) {
      if (!amountBill && !hoursBill && !billRate) {
        errorObject[`amountBill`] = "Amount Bill or Hours Bill is required when Billing code is present";
        errorObject[`hoursBill`] = "Amount Bill or Hours Bill is required when Billing code is present";
      }
  
      if (amountBill && billRate) {
        errorObject[`amountBill`] = "Amount Bill and Bill Rate cannot be present simultaneously with Billing code";
        errorObject[`billRate`] = "Amount Bill and Bill Rate cannot be present simultaneously with Billing code";
      }

      if (amountBill) {
        if (hoursBill || billRate) {
          errorObject[`hoursBill`] = "Hours Bill and Bill Rate should be empty if Amount Bill is present with Billing code";
          errorObject[`billRate`] = "Hours Bill and Bill Rate should be empty if Amount Bill is present with Billing code";
        }
      } else {
        if (hoursBill && !billRate) {
          errorObject[`billRate`] = "Bill Rate is required when Hours Bill is present with Billing code";
        }
        if (!hoursBill && billRate) {
          errorObject[`hoursBill`] = "Hours Bill is required when Bill Rate is present with Billing code";
        }
      }    
     

      if (amountBill && !isValidNumberPay(amountBill)) {
        errorObject[`amountBill`] = "Amount Bill must be a valid number";
      }

      if (hoursBill && !isValidNumber(hoursBill)) {
        errorObject[`hoursBill`] = "Hours Bill must be a valid number";
      }

      if (billRate && !isValidNumber(billRate)) {
        errorObject[`billRate`] = "Bill Rate must be a valid number";
      }
    }

    if ((amountPay || hoursPay || payRate) && (!payCode || !payCode.customPayrollTypeId)) {
      errorObject[`payCode`] = "Pay code is required when Amount Pay or Hours Pay or Pay Rate is present";
    }

    if ((amountBill || hoursBill || billRate) && (!billingCode || !billingCode.customPayrollTypeId)) {
      errorObject[`billingCode`] = "Billing code is required when Amount Bill or Hours Bill or Bill Rate is present";
    }

    if (Object.keys(errorObject).length !== 0) {
      errors.customPayroll[index] = errorObject;
    }
  });

  if (errors.customPayroll.length === 0) {
    delete errors.customPayroll;
  }

  return errors;
};


