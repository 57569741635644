import React from 'react'
import { TagNameContainer } from '../components/TagNameContainer';
import { VideoCarousel } from '../components/VideoCarousel';

export const VideosSection = () => {
    return (
        <>
            <section className="section-global bg-shade-1">
                <TagNameContainer tagName={'Check Out Our Tutorials'}/>                
                <div className='carousel-container-outter'>
                    <VideoCarousel showItems={4} />
                </div>
            </section>
        </>
    )
}
